import React, { useState, useEffect } from "react";
import StepOne from "./Wizard/StepOne";
import "./index.css";

const MultiStepForm = () => {
  var user = JSON.parse(localStorage.getItem("user"));
  var user_data = user.data.data;

  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    plan_data: {
      plan_uuid: "",
    },
  });

  useEffect(() => {
    if (user_data.is_password_done === "YES") {
      setCurrentStep(currentStep + 1);
    }

    if (user_data.is_password_done === "YES" && user_data.is_did_done === "YES") {
      setCurrentStep(currentStep + 2);
    }

    if (
      user_data.is_password_done === "YES" &&
      user_data.is_did_done === "YES" &&
      user_data.is_email_config_done === "YES"
    ) {
      setCurrentStep(currentStep + 3);
    }

    // if (
    //   user_data.is_password_done == "YES" &&
    //   user_data.is_did_done == "YES" &&
    //   user_data.is_email_config_done == "YES" &&
    //   user_data.is_e911_done == "YES"
    // ) {

    // }
  }, []);

  const handleOneData = (dataFromOne) => {
    setFormData(dataFromOne);
  };

  const handleTwoData = (dataFromTwo) => {
    setFormData(dataFromTwo);
  };

  const handleThreeData = (dataFromThree) => {
    setFormData(dataFromThree);
  };

  const handleFourData = (dataFromFour) => {
    setFormData(dataFromFour);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <StepOne handleOneData={handleOneData} currentStep={currentStep} />
        );
      case 2:
        return (
          <StepOne currentStep={currentStep} handleTwoData={handleTwoData} />
        );
      case 3:
        return (
          <StepOne
            formData={formData}
            handleThreeData={handleThreeData}
            currentStep={currentStep}
          />
        );
      case 4:
        return (
          <StepOne
            formData={formData}
            handleFourData={handleFourData}
            currentStep={currentStep}
          />
        );
      default:
        return null;
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <div className="row">
      <div className="col-xl-12 col-xxl-12">
        <form
          action=""
          onSubmit={(e) => submitHandler(e)}
          id="step-form-horizontal"
          className="step-form-horizontal"
        >
          <ol className="go2441762052">
            <li
              className={currentStep < 1 ? "go2067797667" : "go767851704"}
              value="0"
            >
              <span>1</span>
            </li>
            <li
              className={currentStep < 2 ? "go2067797667" : "go767851704"}
              value="1"
            >
              <span>2</span>
            </li>
            <li
              className={currentStep < 3 ? "go2067797667" : "go767851704"}
              value="2"
            >
              <span>3</span>
            </li>
            <li
              className={currentStep < 4 ? "go2067797667" : "go767851704"}
              value="3"
            >
              <span>4</span>
            </li>
          </ol>
          <div className="mt-4 mb-4">{renderStep()}</div>

          <div className="next_pre_btn"></div>
        </form>
      </div>
    </div>
  );
};

export default MultiStepForm;
