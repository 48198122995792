import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const addPlan = (planData, modulesData, createdby, token) => {
  return axios
    .post(
      API_URL + "plan/create-plan",
      {
        planData,
        modulesData,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updatePlan = (planData, modulesData, createdby, token) => {
  return axios
    .post(
      API_URL + "plan/update-plan",
      {
        planData,
        modulesData,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getPlans = (
  main_uuid,
  user_type,
  group_per,
  group_uuid,
  search,
  off_set,
  status,
  limits,
  orderby,
  role_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "plan/plan-list",
      {
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        search,
        off_set,
        status,
        limits,
        orderby,
        role_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const deletePlan = (plan_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "plan/plan-delete",
      {
        plan_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getPlanDetail = (plan_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "plan/get-plan-detail",
      {
        plan_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getTab = (createdby, token) => {
  return axios
    .post(
      API_URL + "plan/get-tab",
      {
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getModules = (action, plan_uuid, createdby, token, tab_name) => {
  return axios
    .post(
      API_URL + "plan/get-modules",
      {
        action,
        plan_uuid,
        createdby,
        tab_name,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getModulesPlan = (plan_uuid, tab_name) => {
  return axios
    .post(API_URL + "plan/get-modules-plan", {
      plan_uuid,
      tab_name,
    })
    .then((response) => {
      return response.data;
    });
};

const getAllPlanList = (data) => {
  return axios.post(API_URL + "plan/plan-all-list", data).then((response) => {
    return response.data;
  });
};

const getTabPlan = (data) => {
  return axios.post(API_URL + "plan/get-tab-plan", data).then((response) => {
    return response.data;
  });
};

export default {
  addPlan,
  updatePlan,
  getPlans,
  deletePlan,
  getPlanDetail,
  getTab,
  getModules,
  getModulesPlan,
  getTabPlan,
  getAllPlanList,
};
