import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Table,
  Button,
  ButtonGroup,
  DropdownButton,
  Dropdown,
  Modal,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import PageTitle from "../../layouts/PageTitle";
import Loader from "../../layouts/Loader";
import Pagination from "../../layouts/Pagination";
import {
  getDncList,
  deleteDncList,
  deleteMultipleDncList,
} from "../../actions/dnsList";
import FlashMessage from "../../layouts/FlashMessage";
import CheckModulePermisson from "../../common/CheckModulePermisson";
import PermissionCheck from "../../common/PermissionCheck";
import DncListForm from "./DncListForm";
import BulkUploadForm from "./BulkUploadForm";
import FilterForm from "./FilterForm";
import filter_img from "./../../../images/profile/filter.png";
import { CSVLink } from "react-csv";
import ExportForm from "./ExportForm";

function DncList() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;
  const module_name = "dnc-list";

  let module_per = CheckModulePermisson(module_name);

  let isPermission = true;
  if (module_per === "" || user_type === "admin") {
    isPermission = true;
  } else {
    isPermission = false;
  }

  let listing_per = PermissionCheck(module_name, "listing", "", "", "");
  let add_per = PermissionCheck(module_name, "add", "", "", "");
  let delete_per = PermissionCheck(module_name, "deleteAll", "", "", "");

  const dispatch = useDispatch();
  let navigate = useNavigate();

  if (isPermission === false) {
    navigate("/error-403");
  }

  if (listing_per === "none") {
    navigate("/error-403");
  }

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }

  let orderby = "d.created_at DESC";

  const [limits, setLimits] = useState(10);
  const [dncList, setDncList] = useState([]);
  const [exportDncList, setExportDncList] = useState([]);
  const [totalDncList, setTotalDncList] = useState(0);
  const [dncListUpdate, setDncListUpdate] = useState({
    dnc_list_uuid: "",
    number: "",
    account_name: "",
    description: "",
    action: "add",
  });
  const [dncEvent, setDncEvent] = useState("");
  const [pageCount, setpageCount] = useState(0);
  const [offSet, setOffSet] = useState(0);

  const [IsSearch, setIsSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState({
    area_code: "",
    state_uuid: "",
    account_name: "",
    description: "",
  });

  const [loading, setLoading] = useState(false);
  const [addDncListModal, setAddDncListModal] = useState(false);
  const [bulkUploadModal, setBulkUploadModal] = useState(false);
  const [exportModal, setExportModal] = useState(false);
  const [exportType, setExportType] = useState("");

  const [selectedDncList, setSelectedDncList] = useState({
    selected_dnc_list: [],
    checked: 0,
    select_type: "none",
  });
  //const [filterFormModal, setFilterFormModal] = useState(false);

  const csvRef = useRef();
  const headers = [
    { label: "Number", key: "number" },
    { label: "Account Name", key: "account_name" },
    { label: "Description", key: "description" },
  ];

  const csvReport = {
    data: exportDncList,
    headers: headers,
    filename: "DNC-list.csv",
  };

  const getDnclistData = (type) => {
    setLoading(true);
    dispatch(
      getDncList(
        user_type,
        group_per,
        group_uuid,
        search,
        searchData,
        offSet,
        limits,
        orderby,
        main_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        setDncList(response.data.data);
        setLoading(false);
        const total = response.data.total;
        setTotalDncList(total);
        setpageCount(Math.ceil(total / limits));
        setDncEvent("");

        if (type === "export") {
          confirmAlert({
            title: "",
            message:
              "List has " +
              total +
              " records, Are you sure to export this list?",
            buttons: [
              {
                label: "Yes",
                onClick: () => {
                  setExportDncList(response.data.data);
                },
              },
              {
                label: "No",
              },
            ],
          });

          //csvRef.current.link.click();
        }
        return;
      })
      .catch(() => {
        setLoading(false);
        return;
      });
  };

  if (module_per === "" || user_type === "admin") {
    useEffect(() => {
      if (IsSearch) {
        getDnclistData("");
      }
    }, [dncEvent, limits]);
  }

  useEffect(() => {
    if (exportDncList.length > 0) {
      csvRef.current.link.click();
    }

    let new_data = { ...searchData };
    new_data["area_code"] = "";
    new_data["account_name"] = "";
    new_data["description"] = "";
    new_data["state_uuid"] = "";
    setSearchData(new_data);
  }, [exportDncList]);

  const fndeleteDncList = (dnc_list_uuid) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this list?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteDncListService(dnc_list_uuid),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteDncListService = (dnc_list_uuid) => {
    setLoading(true);
    dispatch(deleteDncList(dnc_list_uuid, createdby, token))
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          notifyInfo(response.message);
          setDncEvent(Math.random);
          return;
        } else if (response.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handlePageClick = async (data) => {
    setOffSet(limits * data.selected);
    setDncEvent(Math.random);
  };

  const chnageLimit = (value) => {
    setLimits(value);
  };

  const closeModel = (value) => {
    setAddDncListModal(value);
  };

  const closeBulkUploadModel = (value) => {
    setBulkUploadModal(value);
  };

  const closeExportModal = (value) => {
    setExportModal(value);
  };

  const handleDncEvent = (value) => {
    setDncEvent(value);
  };

  const UpdateDncList = (data) => {
    let newData = { ...dncListUpdate };

    newData["dnc_list_uuid"] = data.dnc_list_uuid;
    newData["action"] = "update";
    newData["number"] = data.number;
    newData["account_name"] = data.account_name;
    newData["description"] = data.description;
    setDncListUpdate(newData);

    setAddDncListModal(true);
  };

  const SelectAll = (type) => {
    let dnc_list_uuids = [];

    dncList.map(
      (list) => (dnc_list_uuids = [...dnc_list_uuids, list.dnc_list_uuid])
    );

    const newarr = [];

    newarr["selected_dnc_list"] = dnc_list_uuids;
    newarr["checked"] = type === "all" ? totalDncList : dnc_list_uuids.length;
    newarr["select_type"] = type;

    setSelectedDncList(newarr);

    return;
  };

  const DeSelectAll = () => {
    const newarr = [];
    newarr["selected_dnc_list"] = [];
    newarr["checked"] = 0;
    newarr["select_type"] = "none";

    setSelectedDncList(newarr);
  };

  const checkDncList = (e, dnc_uuid) => {
    const newarr = [];

    if (e.target.checked) {
      //checked : add element
      const newdata = [...selectedDncList.selected_dnc_list, dnc_uuid];
      newarr["selected_dnc_list"] = newdata;
      newarr["checked"] = newdata.length;
      newarr["select_type"] = "select";
    } else {
      //uncheck : remove element
      const newdata = selectedDncList.selected_dnc_list.filter(
        (arr) => arr !== dnc_uuid
      );

      newarr["selected_dnc_list"] = newdata;
      newarr["checked"] = newdata.length;
      newarr["select_type"] = "none";
    }

    setSelectedDncList(newarr);
  };

  const deleteMultipleNumber = () => {
    let selectedList = selectedDncList.selected_dnc_list;

    if (selectedList === "") {
      alert("Please select atleast one recoard");
      return false;
    }

    confirmAlert({
      title: "",
      message: "Are you sure to delete this DNC List?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteMultipleNumberService(selectedList),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteMultipleNumberService = (selectedList) => {
    setLoading(true);
    dispatch(deleteMultipleDncList(selectedList, createdby, token))
      .then((response) => {
        if (response.status === 200) {
          notifyInfo(response.message);
          handleDncEvent(Math.random());
          return;
        } else if (response.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const exportList = (type) => {
    setLoading(true);
    if (type === "state") {
      setExportType("state");
      setExportModal(true);
    } else if (type === "area_code") {
      setExportType("area_code");
      setExportModal(true);
    } else if (type === "all") {
      setExportType("all");
      getDnclistData("export");
    } else {
      getDnclistData("export");
      setExportModal(false);
    }
    setLoading(false);
  };

  const setExportData = (value) => {
    if (exportType === "area_code") {
      let newData = { ...searchData };
      newData["area_code"] = value;

      setSearchData(newData);
    }

    if (exportType === "state") {
      let newData = { ...searchData };
      newData["state_uuid"] = value;

      setSearchData(newData);
    }
  };

  const applySearch = (data) => {
    setSearchData(data);
    if (data.type === "search") {
      setIsSearch(true);
    } else {
      setIsSearch(false);
    }
    setDncEvent(Math.random);
    document.body.click();
  };

  const popoverBottom = (
    <Popover id="popover-positioned-bottom" title="Popover bottom">
      <FilterForm applySearch={applySearch} />.
    </Popover>
  );

  const handleSearchChange = (e) => {
    setSearch(e.target.value);

    if (e.target.value.length > 0) {
      setIsSearch(true);
      setDncEvent(Math.random);
    } else {
      setIsSearch(false);
      setDncList([]);
    }
  };

  const breadCurmList = [
    { label: "Calls", link: "/", is_active: "NO" },
    {
      label: "DNC List : Total Amount " + totalDncList,
      link: "",
      is_active: "YES",
    },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
      <FlashMessage />
      <PageTitle breadCurmList={breadCurmList} />

      {isPermission ? (
        <>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">
                    Manage DNC List
                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      placement="bottom"
                      overlay={popoverBottom}
                    >
                      <span className="cursor-pointer ml-3">
                        <img
                          src={filter_img}
                          className="img-fluid rounded-circle"
                          alt="Search"
                        />
                      </span>
                    </OverlayTrigger>
                  </h4>

                  <span>
                    {IsSearch && delete_per !== "none" && (
                      <Button
                        type="button"
                        className="btn btn-danger btn-sm mr-3"
                        onClick={() => deleteMultipleNumber()}
                      >
                        Delete Seleted Numbers
                      </Button>
                    )}

                    {add_per === "yes" ? (
                      <Button
                        type="button"
                        className="btn btn-primary btn-sm mr-3"
                        onClick={() => setAddDncListModal(true)}
                      >
                        Add To List
                      </Button>
                    ) : (
                      <></>
                    )}

                    {IsSearch && (
                      <Button
                        type="button"
                        className="btn btn-primary btn-sm mr-3"
                        onClick={() => exportList("all")}
                      >
                        Export List
                      </Button>
                    )}
                    <CSVLink {...csvReport} ref={csvRef}></CSVLink>
                    {!IsSearch && (
                      <ButtonGroup className="mr-3">
                        <DropdownButton
                          as={ButtonGroup}
                          id="bg-nested-dropdown"
                          size="sm"
                          title="Export List"
                          variant="btn btn-primary"
                        >
                          <Dropdown.Item
                            eventKey="1"
                            href="#"
                            onClick={() => exportList("state")}
                          >
                            Export by State
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="1"
                            href="#"
                            onClick={() => exportList("area_code")}
                          >
                            Export by Area Code
                          </Dropdown.Item>

                          <Dropdown.Item
                            eventKey="1"
                            href="#"
                            onClick={() => exportList("all")}
                          >
                            Export ALL
                          </Dropdown.Item>
                        </DropdownButton>
                      </ButtonGroup>
                    )}

                    {add_per === "yes" ? (
                      <Button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => setBulkUploadModal(true)}
                      >
                        Bulk Import
                      </Button>
                    ) : (
                      <></>
                    )}
                  </span>
                </div>
                <div className="card-body">
                  {IsSearch && (
                    <span>
                      Show
                      <ButtonGroup className="ml-2 mr-2">
                        <DropdownButton
                          as={ButtonGroup}
                          id="bg-nested-dropdown"
                          size="sm"
                          title={limits}
                          variant="custom-dropdown"
                        >
                          <Dropdown.Item
                            eventKey="1"
                            href="#"
                            onClick={() => chnageLimit(10)}
                          >
                            10
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="1"
                            href="#"
                            onClick={() => chnageLimit(25)}
                          >
                            25
                          </Dropdown.Item>

                          <Dropdown.Item
                            eventKey="1"
                            href="#"
                            onClick={() => chnageLimit(50)}
                          >
                            50
                          </Dropdown.Item>

                          <Dropdown.Item
                            eventKey="1"
                            href="#"
                            onClick={() => chnageLimit(100)}
                          >
                            100
                          </Dropdown.Item>
                        </DropdownButton>
                      </ButtonGroup>
                      entries
                    </span>
                  )}

                  <div className="input-group input-success w-25 pull-right">
                    <input
                      className="form-control text-primary"
                      type="search"
                      placeholder="Search Here"
                      aria-label="Search"
                      onChange={(e) => handleSearchChange(e)}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text text-primary">
                        <svg
                          width={20}
                          height={20}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                            fill="#A4A4A4"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>

                  {IsSearch && (
                    <Table responsive className="w-100">
                      <thead>
                        <tr role="row">
                          <th>
                            <DropdownButton
                              title=""
                              id="bg-nested-dropdown"
                              size="sm"
                              variant="btn btn-outline-light"
                            >
                              <Dropdown.Item
                                eventKey="1"
                                href="#"
                                onClick={() => SelectAll("page")}
                              >
                                Select This Page
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="2"
                                href="#"
                                onClick={() => SelectAll("all")}
                              >
                                Select All
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="3"
                                href="#"
                                onClick={() => DeSelectAll()}
                              >
                                DeSelect All
                              </Dropdown.Item>
                            </DropdownButton>
                          </th>
                          <th>Number</th>
                          <th>Account Name</th>
                          <th>Description</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dncList.map((row) => (
                          <tr key={row.dnc_list_uuid}>
                            <td>
                              <div className="form-check mb-2 ml-4">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={selectedDncList.selected_dnc_list.includes(
                                    row.dnc_list_uuid
                                  )}
                                  onChange={(e) =>
                                    checkDncList(e, row.dnc_list_uuid)
                                  }
                                />
                                <label className="form-check-label"></label>
                              </div>
                            </td>
                            <td>{row.number}</td>
                            <td>{row.account_name}</td>
                            <td>{row.description}</td>

                            <td>
                              <Fragment>
                                <div className="d-flex">
                                  {
                                    <Link
                                      //to="/update-ringgroup"
                                      onClick={() => UpdateDncList(row)}
                                      hidden={PermissionCheck(
                                        module_name,
                                        "edit",
                                        row.group_uuid,
                                        row.user_created_by,
                                        row.created_by
                                      )}
                                      className="btn btn-primary shadow btn-xs sharp mr-1"
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </Link>
                                  }
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      fndeleteDncList(row.dnc_list_uuid)
                                    }
                                    hidden={PermissionCheck(
                                      module_name,
                                      "delete",
                                      row.group_uuid,
                                      row.user_created_by,
                                      row.created_by
                                    )}
                                    className="btn btn-danger shadow btn-xs sharp mr-1"
                                  >
                                    <i className="fa fa-trash"></i>
                                  </Link>

                                  <Link
                                    to="#"
                                    // onClick={() =>
                                    //   fndeleteDncList(row.dnc_list_uuid)
                                    // }
                                    // hidden={PermissionCheck(
                                    //   module_name,
                                    //   "delete",
                                    //   row.user_created_by,
                                    //   row.created_by
                                    // )}
                                    className="btn btn-info shadow btn-xs sharp"
                                  >
                                    <i className="fa fa-area-chart"></i>
                                  </Link>
                                </div>
                              </Fragment>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                  {IsSearch && (
                    <Pagination
                      pageCount={pageCount}
                      handlePageNumberClick={handlePageClick}
                    />
                  )}

                  {!IsSearch && (
                    <p className="text-center p-3">
                      Search to get DNC list ...
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Manage DNC List</h4>
              </div>
              <div className="card-body">
                <h3>You have don't access this modules</h3>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal className="fade bd-example-modal-xl" show={addDncListModal}>
        <DncListForm
          closeModel={closeModel}
          handleDncEvent={handleDncEvent}
          dncListUpdate={dncListUpdate}
        />
      </Modal>

      <Modal
        className="fade bd-example-modal-lg"
        show={bulkUploadModal}
        size="lg"
      >
        <BulkUploadForm
          // user={userData}
          closeBulkUploadModel={closeBulkUploadModel}
          handleDncEvent={handleDncEvent}
        />
      </Modal>

      <Modal className="fade bd-example-modal-sm" show={exportModal} size="sm">
        <ExportForm
          closeExportModal={closeExportModal}
          exportType={exportType}
          exportList={exportList}
          setExportData={setExportData}
        />
      </Modal>
    </Fragment>
  );
}

export default DncList;
