import React, { Fragment, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../layouts/PageTitle";
import { getRoleList } from "../../actions/role";
import { createUser } from "../../actions/user";
import Select from "react-select";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import {
  getNumberListDropdown,
  getExtensionListDropdown,
} from "../../actions/comman";
import PermissionCheck from "../../common/PermissionCheck";
import Loader from "../../layouts/Loader";

function CreateUserForm() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;

  let listing_per = PermissionCheck("did", "listing", "", "", "");

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }

  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    role_uuid: "",
    assign_number: "",
    assign_extension: "",
    main_user_uuid: main_uuid,
    createdby: createdby,
  });

  const [assignNumber, setAssignNumber] = useState([]);

  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    role_uuid: "",
  });

  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBlur = (e) => {
    const newUserData = { ...userData };
    let error_data = { ...errors };
    if (e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }

    newUserData[e.target.name] = e.target.value;
    setUserData(newUserData);
  };

  const handleBlurNumber = (data) => {
    setAssignNumber(data);

    let did_uuids = [];
  
    data.map((did) => (did_uuids = [...did_uuids, did.value]));
  

    const newUserData = { ...userData };
    newUserData["assign_number"] = did_uuids;
    setUserData(newUserData);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (!userData.first_name) {
      error_data["first_name"] = "Please Enter first name.";
      setErrors(error_data);
      isError = "true";
    } else if (!/^[A-Za-z]+$/i.test(userData.first_name)) {
      error_data["first_name"] = "Invalid first name.";
      setErrors(error_data);
      isError = "true";
    }

    if (!userData.last_name) {
      error_data["last_name"] = "Please Enter last name.";
      setErrors(error_data);
      isError = "true";
    } else if (!/^[A-Za-z]+$/i.test(userData.last_name)) {
      error_data["last_name"] = "Invalid last name.";
      setErrors(error_data);
      isError = "true";
    }

    if (!userData.email) {
      error_data["email"] = "Please Enter Email.";
      setErrors(error_data);
      isError = "true";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userData.email)
    ) {
      error_data["email"] = "Invalid email address.";
      setErrors(error_data);
      isError = "true";
    }

    if (userData.role_uuid.length === 0) {
      error_data["role_uuid"] = "Role is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (userData.assign_number.length === 0) {
      error_data["assign_number"] = "Number is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (userData.assign_extension.length === 0) {
      error_data["assign_extension"] = "Extension is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create user API

      dispatch(createUser(userData, token))
        .then((res) => {
          if (res.status === 200) {
            notifyInfo(res.message);
            navigate("/users", { replace: true });
          } else if (res.status === 406) {
            error_data["email"] = res.message;
            setErrors(error_data);
            isError = "true";
          } else if (res.status === 500) {
            notifyError("Something went wrong.");
          } else {
            notifyError("Something went wrong.");
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const [roles, setRoles] = useState([]);
  const [numbers, setNumbers] = useState([]);
  const [extensions, setExtensions] = useState([]);

  useEffect(() => {
    dispatch(getRoleList(createdby, token))
      .then((response) => {
        setRoles(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    dispatch(
      getNumberListDropdown(
        "create",
        "",
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        setNumbers(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    dispatch(
      getExtensionListDropdown(
        "create",
        "",
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        setExtensions(response.data);
        return;
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle activeMenu="Users" motherMenu="Role & Permission" />

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Create User</h4>
              <Button
                type="button"
                className="btn btn-default btn-sm"
                onClick={() => navigate("/users", { replace: true })}
              >
                <i className="fa fa-reply" aria-hidden="true"></i> Back
              </Button>
            </div>
            <div className="card-body">
              <form action="" onSubmit={(e) => submitHandler(e)}>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group input-primary">
                      <label className="mb-1">
                        <strong>First Name</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control bg-white"
                        placeholder="First Name"
                        name="first_name"
                        id="first_name"
                        value={userData.first_name}
                        onChange={handleBlur}
                      />
                      <span className="text-danger">{errors.first_name}</span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group input-primary">
                      <label className="mb-1">
                        <strong>Last Name</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control bg-white"
                        placeholder="Last Name"
                        name="last_name"
                        id="last_name"
                        value={userData.last_name}
                        onChange={handleBlur}
                      />
                      <span className="text-danger">{errors.last_name}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group input-primary">
                      <label className="mb-1">
                        <strong>Email</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="textbox"
                        className="form-control bg-white"
                        placeholder="hello@example.com"
                        name="email"
                        value={userData.email}
                        onChange={handleBlur}
                      />
                      <span className="text-danger">{errors.email}</span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group input-primary">
                      <label className="mb-1">
                        <strong>Role</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control bg-white"
                        value={userData.role_uuid}
                        name="role_uuid"
                        onChange={handleBlur}
                      >
                        <option value="">Select Role</option>
                        {roles.map((role) => (
                          <option key={role.role_uuid} value={role.role_uuid}>
                            {role.role_name}
                          </option>
                        ))}
                      </select>
                      <span className="text-danger">{errors.role_uuid}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group input-primary">
                      <label className="mb-1">
                        <strong>Assign Number</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <Select
                        className="form-control bg-white"
                        options={numbers}
                        name="assign_number"
                        placeholder="Select Number"
                        value={assignNumber}
                        onChange={handleBlurNumber}
                        isSearchable={true}
                        isMulti
                      />

                      {/* <select
                        className="form-control"
                        name="assign_number"
                        onChange={handleBlur}
                      >
                        <option value="">Select Number</option>
                        {numbers.map((did) => (
                          <option key={did.uuid} value={did.uuid}>
                            {did.did_number}
                          </option>
                        ))}
                      </select> */}
                      <span className="text-danger">
                        {errors.assign_number}
                      </span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group input-primary">
                      <label className="mb-1">
                        <strong>Extension</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control bg-white"
                        name="assign_extension"
                        onChange={handleBlur}
                      >
                        <option value="">Select Extension</option>
                        {extensions.map((extension) => (
                          <option
                            key={extension.extension_uuid}
                            value={extension.extension_uuid}
                          >
                            {extension.extension}
                          </option>
                        ))}
                      </select>
                      <span className="text-danger">
                        {errors.assign_extension}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row text-right">
                  <div className="col-sm">
                    <Button type="submit" variant="primary" className="btn-sm">
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default CreateUserForm;
