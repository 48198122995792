import React, { Fragment,useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import Loader from "../../../layouts/Loader";
import {cancelCalendarMeeting,reScheduleCalendarMeeting}  from "../../../actions/calender/CalendarMeeting";

function EventView(props) {

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;

  const eventData = props.eventData;
  const dispatch = useDispatch();

  const times = [];
  for (let i = 0; i < 24; i++) {
    times.push(
      `${i % 12 || 12}:00 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:15 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:30 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:45 ${i < 12 ? "AM" : "PM"}`
    );
  }

  const [loading, setLoading] = useState(false);
  const [rescheduleForm, setRescheduleForm] = useState(false);
  const [rescheduleData, setRescheduleData] = useState({
    calendar_meeting_uuid : eventData.calendar_meeting_uuid,
    meeting_date : eventData.meeting_date,
    start_time : eventData.start_time,
    end_time : eventData.end_time,
    createdby : createdby,
  });

  const [errors, setErrors] = useState({
    meeting_date: "",
    start_time: "",
    end_time: "",
  });

  const closeModel = (arg) => {
    props.closeEventModel(arg);
  };

  const fnCancelEvent = () => {
    confirmAlert({
      title: "",
      message: "Are you sure to cancel this event?",
      buttons: [
        {
          label: "Yes",
          onClick: () => cancelEvent(eventData.calendar_meeting_uuid),
        },
        {
          label: "No",
        },
      ],
    });
  }

  const cancelEvent = (calendar_meeting_uuid) => {
    setLoading(true);
      dispatch(cancelCalendarMeeting(calendar_meeting_uuid,createdby,token)).then((response) => {
        if (response.status === 200) {
          closeModel(false);
          props.handleMeetingEvent(Math.random);            
        }
        
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }

  const RescheduleEvent = () => {
    
    setLoading(true);
    let error_data = { ...errors };
    let isError = "false";

    if (rescheduleData.meeting_date.length === 0) {
      error_data["meeting_date"] = "Meeting Date is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (rescheduleData.start_time.length === 0) {
      error_data["start_time"] = "Start time is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (rescheduleData.end_time.length === 0) {
      error_data["end_time"] = "end time is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      
      dispatch(reScheduleCalendarMeeting(rescheduleData,token)).then((response) => {
        if (response.status === 200) {
          closeModel(false);
          props.handleMeetingEvent(Math.random);            
        }
        
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
    }

  }

  const handleBlur = (e) => {
    const newData = { ...rescheduleData };
    let error_data = { ...errors };
    if (e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
       
      newData[e.target.name] = e.target.value;      
      setRescheduleData(newData);
    }
  };


  return (
    <Fragment>
       {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>Event Details </Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      
        <Modal.Body>

          <div className="row">
            <h3 className="text-center">{eventData.meeting_title}</h3>
            <p className="mb-0"><b>Date : </b> {eventData.meeting_date}</p>
            <p className="mb-0"><b>Time : </b> {eventData.start_time} To {eventData.end_time}</p>
            <p className="mb-0"><b>Meeting Type : </b>{eventData.meeting_type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</p>
            {eventData.meeting_type === 'phone_call' && 
              <>
                {eventData.phone_call_type === 'call_to_prospect' && 
                  <p className="mb-0"><b>Call to Prospect on Call Number : </b> {eventData.call_number}</p>
                }
                {eventData.phone_call_type === 'call_to_calender_owner' && 
                  <p className="mb-0"><b>Attendee will call you on Call Number : </b> {eventData.call_number}</p>
                }
              </>
            }

            {eventData.meeting_type === 'online_meeting' && 
              <p className="mb-0"><b>Meeting URL : </b> {eventData.meeting_url}</p>
            }

            {eventData.meeting_type === 'in_person' && 
              <p className="mb-0"><b>Location : </b> {eventData.google_address}</p>
            }            

            <hr></hr>
            <b className="text-center"> Attendee Details</b>
            {eventData.event_type === 'OTHER' && 
              <>
                <p className="mb-0"><b>Attendee Name : </b> {eventData.first_name}  {eventData.last_name}</p>
                <p className="mb-0"><b>Email : </b> {eventData.email}</p>
                <p className="mb-0"><b>Company : </b> {eventData.company}</p>
                <p className="mb-0"><b>Phone : </b> {eventData.phone_number}</p>    
              </>
            }

            {eventData.event_type === 'CONTACT' && eventData.contacts && 
              eventData.contacts.map((row, i) => ( 
                <span key={row.calendar_meeting_contact_uuid}>
                  <p className="mb-0"><b>Attendee Name : </b> {row.first_name}  {row.last_name}</p>
                  <p className="mb-0"><b>Email : </b> {row.email}</p>
                </span>
              ))              
            }
                    
            
          </div>   
          
          {rescheduleForm && 
            <div className="row mt-3">
              <hr></hr>
              <div className="col-sm">
                <div className="form-group input-primary">
                  <label className="mb-1">
                    <strong>Date</strong>
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control bg-white h-75"
                    name="meeting_date"
                    value={rescheduleData.meeting_date}
                    onChange={handleBlur}
                  />
                  <span className="text-danger">{errors.meeting_date}</span>
                </div>
              </div>

              <div className="col-sm">
                <div className="form-group input-primary">
                  <label className="mb-1">
                    <strong>Start Time</strong>
                    <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control bg-white h-75"
                    name="start_time"
                    onChange={handleBlur}
                    value={rescheduleData.start_time}
                  >
                    <option value="">Select Time</option>
                    {times &&
                      times.map((time) => (
                        <option key={time} value={time}>
                          {time}
                        </option>
                      ))}
                  </select>
                  <span className="text-danger">{errors.start_time}</span>
                </div>
              </div>

              <div className="col-sm">
                <div className="form-group input-primary">
                  <label className="mb-1">
                    <strong>End Time</strong>
                    <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control bg-white h-75"
                    name="start_time"
                    onChange={handleBlur}
                    value={rescheduleData.end_time}
                  >
                    <option value="">Select Time</option>
                    {times &&
                      times.map((time) => (
                        <option key={time} value={time}>
                          {time}
                        </option>
                      ))}
                  </select>
                  <span className="text-danger">{errors.end_time}</span>
                </div>
              </div>

              <div className="col-sm">
                <Button
                  onClick={() => RescheduleEvent()}
                  variant="success btn-sm mt-4 mr-1"
                >
                  Save
                </Button>
                <Button
                  onClick={() => setRescheduleForm(false)}
                  variant="danger btn-sm mt-4"
                >
                  Cancel
                </Button>
              </div>

            </div> 
          }
               
          
        </Modal.Body>
        <Modal.Footer>         
          
          {eventData.event_status === 'CANCELLED' && 
            <p className="text-danger">CANCELLED</p>
          }

          {eventData.event_status === 'NO_SHOW' && 
            <p className="text-danger">NO SHOW</p>
          }

          {eventData.event_status === 'COMPLETED' && 
            <p className="text-green">COMPLETED</p>
          }

          {(eventData.event_status === 'NEW' || eventData.event_status === 'RESCHEDULED') && 
            <>
              <Button
                onClick={() => fnCancelEvent()}
                variant="danger btn-sm pull-left"
              >
                Cancel
              </Button>

              {!rescheduleForm && 
                <Button
                  onClick={() => setRescheduleForm(true)}
                  variant="info btn-sm pull-left"
                >
                  Reschedule
                </Button>
              }
            </>
          }
          

          <Button
            onClick={() => closeModel(false)}
            variant="danger light btn-sm"
          >
            Close
          </Button>
          
        </Modal.Footer>
    </Fragment>
  );
}

export default EventView;
