import React, { Fragment} from "react";
import { Modal, Button } from "react-bootstrap";

function ShowNoteForm(props) {
  const closeModel = (arg) => {
    props.closeModel(arg);
  };

  let updateNodeText = props.updateNodeText;

  return (
    <Fragment>
      <Modal.Header>
        <Modal.Title>View Note </Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>

      <Modal.Body>
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <label className="mb-1">
                <strong>{updateNodeText}</strong>
              </label>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => closeModel(false)}
          className="btn-sm"
          variant="danger light"
        >
          Close
        </Button>
      </Modal.Footer>
    </Fragment>
  );
}

export default ShowNoteForm;
