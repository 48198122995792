import React, { Fragment, useState } from "react";
import SideBar from "./SideBar";
import SideBarSuperAdmin from "./SideBarSuperAdmin";
import NavHader from "./NavHader";
import Header from "./Header";
import HeaderSuperAdmin from "./HeaderSuperAdmin";
import SideBarCrm from "./SideBarCrm";
import SideBarAccountManagement from "./SideBarAccountManagement";
import ChatBox from "../ChatBox";
import WebPhone from "../WebPhone";
import FloatBtn from "./FloatBtn";

const JobieNav = ({ title, showProfileSideBar, showProfile, showWebphone }) => {
  const [toggle, setToggle] = useState("");
  const [webphone, setWebphone] = useState(false);

  const onClick = (name) => {
    if (name === "webphone") {
      showWebphone(toggle === name || webphone ? false : true); // jignisha webphone UI
      setWebphone(toggle === name || webphone ? false : true); // jignisha webphone UI

      setToggle(toggle === name || webphone ? "" : name);
    } else {
      setToggle(toggle === name ? "" : name);
    }
  };

  //new code for toggle

  //new code for toggle

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;

  const [smsNofication, setSmsNofication] = useState([]);
  const [smsNoficationCount, setSmsNoficationCount] = useState("");

  const token = user.data.token;
  //const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;

  if (toggle === "notification") {
  }

  if (user_type !== "superadmin") {
    // const getData = async () => {
    //   //new start
    //   const url = API_URL + "get-sms-notification"; // Replace with the actual API endpoint URL
    //   const data = {
    //     main_uuid: main_uuid,
    //     // Add more data properties as needed
    //   };

    //   const options = {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json", // Set the appropriate content type for your API
    //       // You may need to include additional headers depending on the API requirements
    //     },
    //     body: JSON.stringify(data),
    //   };

    //   try {
    //     const response = await fetch(url, options);
    //     if (!response.ok) {
    //       throw new Error("Network response was not ok");
    //     }

    //     // Handle the response data here
    //     const responseData = await response.json();
    //     const data = responseData.data.data;
    //     const total = responseData.data.total;
    //     setSmsNofication(data);
    //     setSmsNoficationCount(total);
    //   } catch (error) {
    //     // Handle any errors that occurred during the fetch
    //     console.error("Error:", error.message);
    //   }
    //   //new end
    // };
  }
  let tab_value = localStorage.getItem("tab_value");

  if (!tab_value) {
    tab_value = "communication";
  }

  if (user_data.role !== "superadmin" && tab_value === "communication") {
    return (
      <Fragment>
        <NavHader />
        <SideBar />
        <Header
          //onNote={() => onClick("chatbox")}
          //onWebphone={() => onClick("webphone")}
          onNotification={() => onClick("notification")}
          onProfile={() => onClick("profile")}
          toggle={toggle}
          title={title}
          //onBox={() => onClick("box")}
          //showProfileSideBar={showProfileSideBar}
          // showProfile={showProfile}
          user_data={user_data}
          smsNofication={smsNofication}
          smsNoficationCount={smsNoficationCount}
          token={token}
        />
        {/* <Search
         
          onProfile={() => onClick("profile")}
          toggle={toggle}
          title={title}
          onBox={() => onClick("box")}
          showProfileSideBar={showProfileSideBar}
          showProfile={showProfile}
          user_data={user_data}
        /> */}
        <ChatBox onClick={() => onClick("chatbox")} toggle={toggle} />
        <WebPhone
          onClick={() => onClick("webphone")}
          toggle={toggle}
          webphone={webphone}
        />
        <FloatBtn onWebphone={() => onClick("webphone")} webphone={webphone} />
      </Fragment>
    );
  } else if (
    user_data.role !== "superadmin" &&
    tab_value === "contact-management"
  ) {
    return (
      <Fragment>
        <NavHader />
        <SideBarCrm />
        <Header
          onNote={() => onClick("chatbox")}
          onWebphone={() => onClick("webphone")}
          onNotification={() => onClick("notification")}
          onProfile={() => onClick("profile")}
          toggle={toggle}
          title={title}
          onBox={() => onClick("box")}
          showProfileSideBar={showProfileSideBar}
          showProfile={showProfile}
          user_data={user_data}
          smsNofication={smsNofication}
          smsNoficationCount={smsNoficationCount}
          token={token}
        />
        {/* <NavProfile /> */}
        <ChatBox onClick={() => onClick("chatbox")} toggle={toggle} />
        <WebPhone onClick={() => onClick("webphone")} toggle={toggle} />
        <FloatBtn onWebphone={() => onClick("webphone")} />
      </Fragment>
    );
  } else if (user_data.role !== "superadmin" && tab_value === "crm") {
    return (
      <Fragment>
        <NavHader />
        <SideBarAccountManagement />
        {/* <NavProfile /> */}
        <Header
          onNote={() => onClick("chatbox")}
          onNotification={() => onClick("notification")}
          onProfile={() => onClick("profile")}
          toggle={toggle}
          title={title}
          onBox={() => onClick("box")}
          showProfileSideBar={showProfileSideBar}
          showProfile={showProfile}
          user_data={user_data}
          smsNofication={smsNofication}
          smsNoficationCount={smsNoficationCount}
          token={token}
        />
        <ChatBox onClick={() => onClick("chatbox")} toggle={toggle} />
        <WebPhone onClick={() => onClick("webphone")} toggle={toggle} />
        <FloatBtn onWebphone={() => onClick("webphone")} />
      </Fragment>
    );
  } else if (user_data.role === "superadmin") {
    return (
      <Fragment>
        <NavHader />
        <SideBarSuperAdmin />
        <HeaderSuperAdmin
          onNotification={() => onClick("notification")}
          onProfile={() => onClick("profile")}
          toggle={toggle}
          title={title}
          onBox={() => onClick("box")}
          user_data={user_data}
        />
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <NavHader />
        <SideBarSuperAdmin />
        <HeaderSuperAdmin
          onNotification={() => onClick("notification")}
          onProfile={() => onClick("profile")}
          toggle={toggle}
          title={title}
          onBox={() => onClick("box")}
          user_data={user_data}
        />
      </Fragment>
    );
  }
};

export default JobieNav;
