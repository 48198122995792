
import React from "react";

import useScript from "../useScript";

const WEBPHONE_JS_URL = process.env.REACT_APP_WEHPHONE_JS_URL;

function Scripts() {
    useScript('https://webrtc.github.io/adapter/adapter-latest.js');

    useScript(WEBPHONE_JS_URL+'sip.js');

    // const timer = setTimeout(() => {
    // 
    // }, 1000);
    // return () => clearTimeout(timer);

    useScript(WEBPHONE_JS_URL+'phone.js');

    

    return (<div></div>);
}

export default Scripts;