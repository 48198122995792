import React, { Fragment, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../../layouts/PageTitle";
import { getMappingFields, mappingFields } from "../../../actions/contacts";
import Loader from "../../../layouts/Loader";

function MappingFields(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;

  const [headerName, setHeaderName] = useState([]);
  //const [fieldsName, setFieldsName] = useState([]);
  
  const params = useLocation();
  const filename = params.state.filename;

  const [contactData, setContactData] = useState({
    contact_type: "",
    bussiness_name: "",
    contact_title: "",
    first_name: "",
    last_name: "",
    email: "",
    work_contact_number: "",
    other_contact_number: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
    address: "",
    address2: "",
  });

  const [allData, setAllData] = useState({
    filename: filename,
    mappingfields: '',
    token: token,
    createdby: createdby,
    main_admin_uuid: createdby,
    user_uuid: createdby,
  });

  const [errors, setErrors] = useState({
    recording_name: "",
    file: "",
  });

  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBlur = (e) => {
    const newRecordingData = { ...contactData };
    let error_data = { ...errors };

    if (
      (e.target.name === "recording_name" || e.target.name === "file") &&
      e.target.value.length === 0
    ) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }

    newRecordingData[e.target.name] = e.target.value;
    setContactData(newRecordingData);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    
    // if(contactData.contact_type.length === 0){
    //   error_data['contact_type'] = "Contact type is required.";
    //   setErrors(error_data);
    //   isError = 'true';
    // }

    if (contactData.email.length === 0) {
      error_data["email"] = "Email is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (contactData.work_contact_number.length === 0) {
      error_data["work_contact_number"] = "Work contact is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
     
      // const newContactData = { ...allData };
      allData["mappingfields"] = contactData;
      setAllData(allData);
      
      setLoading(true);

      //call create insert csv data API
      dispatch(mappingFields(allData))
        .then((data) => {
          navigate("/mapping-fields-confirmation", {
            state: {
              filename: filename,
              mappingfields: contactData,
              data_count: data,
            },
          });
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  

  useEffect(() => {
    setLoading(true);
    dispatch(getMappingFields(createdby, token, filename))
      .then((response) => {
        setHeaderName(response.data.csvheader);
        //setFieldsName(response.data.dbfieldsname);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  // useEffect(() => {
  //   dispatch(getUserList(createdby, token))
  //     .then((response) => {
  //       setUsers(response.data);
  //       return;
  //     })
  //     .catch(() => {
  //       setLoading(false);
  //     });
  // }, []);


  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle activeMenu="Mapping Fields" motherMenu="Contact" />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Mapping Fields</h4>
              <Button
                type="button"
                className="btn btn-default btn-sm"
                onClick={() => navigate("/contacts", { replace: true })}
              >
                <i className="fa fa-reply" aria-hidden="true"></i> Back
              </Button>
            </div>
            <div className="card-body">
              <form action="" onSubmit={(e) => submitHandler(e)}>

              <div className="row">
                    <div className="col-sm">
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>Contact Type</strong>
                        </label>
                        <select
                        className="form-control"
                        name={"contact_type"}
                        onChange={handleBlur}
                      >
                        <option value="">Select CSV Field</option>
                        {headerName &&
                          headerName.map((names, i) => (
                            <option key={names} value={i}>
                              {names}
                            </option>
                          ))}
                      </select>
                        <span className="text-danger">{errors.contact_type}</span>
                      </div>
                    </div>
                    <div className="col-sm">
                      {" "}
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>Business Name</strong>
                        </label>
                      
                        <select
                        className="form-control"
                        name={"bussiness_name"}
                        onChange={handleBlur}
                      >
                        <option value="">Select CSV Field</option>
                        {headerName &&
                          headerName.map((names, i) => (
                            <option key={names} value={i}>
                              {names}
                            </option>
                          ))}
                      </select>
                        <span className="text-danger">
                          {errors.bussiness_name}
                        </span>
                      </div>
                    </div>

                    <div className="col-sm">
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>Contact Title</strong>
                        </label>
                        
                        <select
                        className="form-control"
                        name={"contact_title"}
                        onChange={handleBlur}
                      >
                        <option value="">Select CSV Field</option>
                        {headerName &&
                          headerName.map((names, i) => (
                            <option key={names} value={i}>
                              {names}
                            </option>
                          ))}
                      </select>
                        <span className="text-danger">
                          {errors.contact_title}
                        </span>
                      </div>
                    </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>First Name</strong>
                      </label>
                      <select
                        className="form-control"
                        name={"first_name"}
                        id={"first_name"}
                        onChange={handleBlur}
                      >
                        <option value="">Select CSV Field</option>
                        {headerName &&
                          headerName.map((names, i) => (
                            <option key={names} value={i}>
                              {names}
                            </option>
                          ))}
                      </select>
                      <span className="text-danger">{errors.first_name}</span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Last Name</strong>
                      </label>
                      <select
                        className="form-control"
                        name="last_name"
                        onChange={handleBlur}
                      >
                        <option value="">Select CSV Field</option>
                        {headerName &&
                          headerName.map((names, i) => (
                            <option key={names} value={i}>
                              {names}
                            </option>
                          ))}
                      </select>
                      <span className="text-danger">{errors.last_name}</span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Work Contact Number</strong>
                      </label>
                      <select
                        className="form-control"
                        name={"work_contact_number"}
                        onChange={handleBlur}
                      >
                        <option value="">Select CSV Field</option>
                        {headerName &&
                          headerName.map((names, i) => (
                            <option key={names} value={i}>
                              {names}
                            </option>
                          ))}
                      </select>
                      <span className="text-danger">
                        {errors.work_contact_number}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Other Contact Number</strong>
                      </label>
                      <select
                        className="form-control"
                        name="other_contact_number"
                        onChange={handleBlur}
                      >
                        <option value="">Select CSV Field</option>
                        {headerName &&
                          headerName.map((names, i) => (
                            <option key={names} value={i}>
                              {names}
                            </option>
                          ))}
                      </select>
                      <span className="text-danger">
                        {errors.other_contact_number}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Email</strong>
                      </label>
                      <select
                        className="form-control"
                        name={"email"}
                        onChange={handleBlur}
                      >
                        <option value="">Select CSV Field</option>
                        {headerName &&
                          headerName.map((names, i) => (
                            <option key={names} value={i}>
                              {names}
                            </option>
                          ))}
                      </select>
                      <span className="text-danger">{errors.email}</span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Country</strong>
                      </label>
                      <select
                        className="form-control"
                        name="country"
                        onChange={handleBlur}
                      >
                        <option value="">Select CSV Field</option>
                        {headerName &&
                          headerName.map((names, i) => (
                            <option key={names} value={i}>
                              {names}
                            </option>
                          ))}
                      </select>
                      <span className="text-danger">{errors.country}</span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Address</strong>
                      </label>
                      <select
                        className="form-control"
                        name={"address"}
                        onChange={handleBlur}
                      >
                        <option value="">Select CSV Field</option>
                        {headerName &&
                          headerName.map((names, i) => (
                            <option key={names} value={i}>
                              {names}
                            </option>
                          ))}
                      </select>
                      <span className="text-danger">{errors.address}</span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Address2</strong>
                      </label>
                      <select
                        className="form-control"
                        name="address2"
                        onChange={handleBlur}
                      >
                        <option value="">Select CSV Field</option>
                        {headerName &&
                          headerName.map((names, i) => (
                            <option key={names} value={i}>
                              {names}
                            </option>
                          ))}
                      </select>
                      <span className="text-danger">{errors.address}</span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>State</strong>
                      </label>
                      <select
                        className="form-control"
                        name={"state"}
                        onChange={handleBlur}
                      >
                        <option value="">Select CSV Field</option>
                        {headerName &&
                          headerName.map((names, i) => (
                            <option key={names} value={i}>
                              {names}
                            </option>
                          ))}
                      </select>
                      <span className="text-danger">{errors.state}</span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>City</strong>
                      </label>
                      <select
                        className="form-control"
                        name="city"
                        onChange={handleBlur}
                      >
                        <option value="">Select CSV Field</option>
                        {headerName &&
                          headerName.map((names, i) => (
                            <option key={names} value={i}>
                              {names}
                            </option>
                          ))}
                      </select>
                      <span className="text-danger">{errors.city}</span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Zipcode</strong>
                      </label>
                      <select
                        className="form-control"
                        name={"zipcode"}
                        onChange={handleBlur}
                      >
                        <option value="">Select CSV Field</option>
                        {headerName &&
                          headerName.map((names, i) => (
                            <option key={names} value={i}>
                              {names}
                            </option>
                          ))}
                      </select>
                      <span className="text-danger">{errors.zipcode}</span>
                    </div>
                  </div>
                </div>

                {/* <div className="row">
                  {fieldsName &&
                    fieldsName.map((name) => (
                      <>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="mb-1">
                              <strong>{toTitleCase(name)}</strong>
                            </label>
                            <select
                              className="form-control"
                              name={name}
                              id={name}
                              onChange={handleBlur}
                            >
                              <option value="">Select CSV Field</option>
                              {headerName &&
                                headerName.map((names) => (
                                  <option key={names} value={names}>
                                    {names}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </>
                    ))}
                </div> */}

                <div className="row text-right">
                  <div className="col-sm">
                    <Button type="submit" variant="primary" className="btn-sm">
                      Mapping Field
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default MappingFields;
