import React,{useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import "./style.css";
import { getUserEmailSignature } from "../../../actions/email/emailSignature";
import EmailSignature from "../../emailSignature/emailSignature";

const selfSignature = (props) => {

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;

  const [userSignature, setUserSignature] = useState([]);

  const dispatch = useDispatch();
  
  let signature_type = 'SELF';

  useEffect(() => {
    let user_uuid = user_data.user_uuid
    dispatch(getUserEmailSignature(createdby,user_uuid,token))
      .then((response) => {
        if(response.data[0]){
          setUserSignature(response.data[0]);  
        }            

        return;
      })
      .catch(() => {
        
      });
}, []);


  return (
    <div className="pt-3">
      
      <Modal.Header>
        <Modal.Title>Self Signature</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        
        <EmailSignature 
          signature_type={signature_type}
          userSignature={userSignature}
        />

      </Modal.Body>

      

    </div>
    
  );
};

export default selfSignature;
