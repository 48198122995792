import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const addRole = (rolename, token, createdby, main_admin_uuid) => {
  return axios
    .post(
      API_URL + "permission/create-role",
      {
        rolename,
        createdby,
        main_admin_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getRoles = (
  main_uuid,
  user_type,
  group_per,
  group_uuid,
  search,
  off_set,
  status,
  limits,
  orderby,
  role_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "permission/role-list",
      {
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        search,
        off_set,
        status,
        limits,
        orderby,
        role_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const deleteRoles = (roleuuid, createdby, token) => {
  return axios
    .post(
      API_URL + "permission/role-delete",
      {
        roleuuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getRolePermissions = (roleuuid, createdby, token, tab_name) => {
  return axios
    .post(
      API_URL + "permission/get-role-permission",
      {
        roleuuid,
        createdby,
        tab_name,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getRoleName = (roleuuid, createdby, token) => {
  return axios
    .post(
      API_URL + "permission/get-role-name",
      {
        roleuuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateModulePermission = (
  type,
  action,
  value,
  module_permission_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "permission/update-module-permission",
      {
        type,
        action,
        value,
        module_permission_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getRoleDetail = (roleuuid, createdby, token) => {
  return axios
    .post(
      API_URL + "permission/role-details",
      {
        roleuuid,
        createdby,
        token,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateRole = (rolename, role_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "permission/update-role-name",
      {
        rolename,
        role_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getTab = (role_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "permission/get-tab",
      {
        role_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateTabPermission = (status, slug, role_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "permission/update-tab-permission",
      {
        status,
        slug,
        role_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updatePermission = (
  tabData,
  permissionData,
  role_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "permission/update-permission",
      {
        tabData,
        permissionData,
        role_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const copyRoleAndPermission = (rolename, roleuuid, token, createdby) => {
  return axios
    .post(
      API_URL + "permission/copy-role-and-permission",
      {
        rolename,
        roleuuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getRoleList = (main_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "get-role-list-dp",
      {
        main_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

export default {
  addRole,
  getRoles,
  deleteRoles,
  getRolePermissions,
  getRoleName,
  updateModulePermission,
  getRoleDetail,
  updateRole,
  getTab,
  updateTabPermission,
  updatePermission,
  copyRoleAndPermission,
  getRoleList,
};
