import React, { Fragment, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../layouts/PageTitle";
import { getRoleList } from "../../actions/role";
import { updateUser } from "../../actions/user";
import Select from "react-select";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import {
  getNumberListDropdown,
  getExtensionListDropdown,
} from "../../actions/comman";
import PermissionCheck from "../../common/PermissionCheck";
import { getUserDetail } from "../../actions/user";
import Loader from "../../layouts/Loader";

function UpdateUserForm(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;

  let listing_per = PermissionCheck("did", "listing", "", "", "");

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const params = useLocation();

  let useruuid = params.state.uuid;
  let assign_extension = params.state.assign_extension;

  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    role_uuid: "",
    assign_number: "",
    assign_extension: "",
    main_user_uuid: main_uuid,
    token: token,
    createdby: createdby,
  });

  const [assignNumber, setAssignNumber] = useState([]);

  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    role_uuid: "",
  });

  const [loading, setLoading] = useState(false);

  const handleBlur = (e) => {
    const newUserData = { ...userData };
    let error_data = { ...errors };
    if (e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }
    newUserData[e.target.name] = e.target.value;
    setUserData(newUserData);
  };

  const handleBlurNumber = (data) => {
    setAssignNumber(data);

    let did_uuids = [];
    
    data.map((did) => (did_uuids = [...did_uuids, did.value]));
   
    const newUserData = { ...userData };
    newUserData["assign_number"] = did_uuids;
    setUserData(newUserData);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (userData.first_name.length === 0) {
      error_data["first_name"] = "First name is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (userData.last_name.length === 0) {
      error_data["last_name"] = "Last name is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (userData.email.length === 0) {
      error_data["email"] = "Email is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (userData.role_uuid.length === 0) {
      error_data["role_uuid"] = "Role is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create user API

      const data = { ...userData };
      data["useruuid"] = useruuid;
      data["main_user_uuid"] = main_uuid;
      dispatch(updateUser(data))
        .then((res) => {
          navigate("/users", { replace: true });
          if (res.status === 200) {
            notifyInfo(res.message);
            navigate("/users", { replace: true });
          } else if (res.status === 406) {
            error_data["email"] = res.message;
            setErrors(error_data);
            isError = "true";
          } else if (res.status === 500) {
            notifyError("Something went wrong.");
          } else {
            notifyError("Something went wrong.");
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const [roles, setRoles] = useState([]);
  const [numbers, setNumbers] = useState([]);
  const [extensions, setExtensions] = useState([]);

  useEffect(() => {
    dispatch(getRoleList(createdby, token))
      .then((response) => {
        setRoles(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    dispatch(getUserDetail(useruuid, createdby, token))
      .then((response) => {
        const newarr = { ...response.data.user };
        newarr["token"] = token;
        newarr["createdby"] = createdby;

        setUserData(newarr);
        setAssignNumber(response.data.assign_dids);

        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    dispatch(
      getNumberListDropdown(
        "update",
        useruuid,
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        setNumbers(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    dispatch(
      getExtensionListDropdown(
        "update",
        assign_extension,
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        setExtensions(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle activeMenu="Users" motherMenu="Role & Permission" />

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                Update User - {userData.first_name} {userData.last_name}
              </h4>
              <Button
                type="button"
                className="btn btn-default btn-sm"
                onClick={() => navigate("/users", { replace: true })}
              >
                <i className="fa fa-reply" aria-hidden="true"></i> Back
              </Button>
            </div>
            <div className="card-body">
              <form action="" onSubmit={(e) => submitHandler(e)}>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>First Name</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        name="first_name"
                        id="first_name"
                        value={userData.first_name}
                        onChange={handleBlur}
                      />
                      <span className="text-danger">{errors.first_name}</span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Last Name</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        name="last_name"
                        id="last_name"
                        value={userData.last_name}
                        onChange={handleBlur}
                      />
                      <span className="text-danger">{errors.last_name}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Email</strong>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="hello@example.com"
                        name="email"
                        value={userData.email}
                        onChange={handleBlur}
                      />
                      <span className="text-danger">{errors.email}</span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Role</strong>
                      </label>
                      <select
                        className="form-control"
                        value={userData.role_uuid}
                        name="role_uuid"
                        onChange={handleBlur}
                      >
                        <option value="">Select Role</option>
                        {roles.map((role) => (
                          <option key={role.role_uuid} value={role.role_uuid}>
                            {role.role_name}
                          </option>
                        ))}
                      </select>
                      <span className="text-danger">{errors.role_uuid}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Assign Number</strong>
                      </label>
                      <Select
                        className="form-control"
                        options={numbers}
                        name="assign_number"
                        placeholder="Select Number"
                        value={assignNumber}
                        onChange={handleBlurNumber}
                        isSearchable={true}
                        isMulti
                      />
                      <span className="text-danger">
                        {errors.assign_number}
                      </span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Extension</strong>
                      </label>
                      <select
                        value={userData.assign_extension}
                        className="form-control"
                        name="assign_extension"
                        onChange={handleBlur}
                      >
                        <option value="">Select Extension</option>
                        {extensions.map((extension) => (
                          <option
                            key={extension.extension_uuid}
                            value={extension.extension_uuid}
                          >
                            {extension.extension}
                          </option>
                        ))}
                      </select>
                      <span className="text-danger">
                        {errors.assign_extension}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row text-right">
                  <div className="col-sm">
                    <Button type="submit" variant="primary" className="btn-sm">
                      Update
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default UpdateUserForm;
