import React, { Fragment, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./style.css";

function TimeSlotForm(props) {
  let day = props.day;

  let title = "Operation Time";
  const closeModel = (arg) => {
    props.closeModel(arg);
  };

  const [timeslotData, setTimeslotData] = useState({
    operation_uuid: Math.random(),
    day: "",
    start_time: "",
    end_time: "",
  });

  const [errors, setErrors] = useState({
    start_time: "",
    end_time: "",
  });

  const date = "2023-04-04";

  const handleBlur = (e) => {
    const newTimeslotData = { ...timeslotData };
    let error_data = { ...errors };

    if (e.target.name === "start_time" && e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }

    if (e.target.name === "end_time" && e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      let f_date = timeslotData.start_time;
      let t_date = timeslotData.end_time;

      const date1 = new Date(date + " " + f_date);
      const date2 = new Date(date + " " + t_date);
      if (date1.getTime() > date2.getTime()) {
        error_data[e.target.name] = "End time should be greater then start time.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    newTimeslotData[e.target.name] = e.target.value;
    setTimeslotData(newTimeslotData);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    if (timeslotData.start_time.length === 0) {
      error_data["start_time"] = "Start time is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (timeslotData.end_time.length === 0) {
      error_data["end_time"] = "End time is required.";
      setErrors(error_data);
      isError = "true";
    }

    // We initialize a past date

    let f_date = timeslotData.start_time;
    let t_date = timeslotData.end_time;

    const date1 = new Date(date + " " + f_date);
    const date2 = new Date(date + " " + t_date);
    // Let's see if the first date is equal, more recent or less recent than the second date
    if (date1.getTime() > date2.getTime()) {
      error_data["end_time"] = "End time should be greater then start time.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      
      return;
    } else {
      //call create time slot API
      timeslotData["day"] = day;
      props.onAddDestination(timeslotData);
      closeModel(false);
    }
  };

  return (
    <Fragment>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <form action="" onSubmit={(e) => submitHandler(e)}>
        <Modal.Body>
          <div className="row">
            <div className="col-sm">
              <div className="form-group">
                <label className="mb-1">
                  <strong>From Time</strong>
                </label>
                <input
                  type="time"
                  className="form-control"
                  placeholder="From Time"
                  name="start_time"
                  value={timeslotData.start_time}
                  onChange={handleBlur}
                />
                <span className="text-danger">{errors.start_time}</span>
              </div>
            </div>
            <div className="col-sm">
              <div className="form-group">
                <label className="mb-1">
                  <strong>To Time</strong>
                </label>
                <input
                  type="time"
                  className="form-control"
                  placeholder="To Time"
                  name="end_time"
                  value={timeslotData.end_time}
                  onChange={handleBlur}
                />
                <span className="text-danger">{errors.end_time}</span>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => closeModel(false)} variant="danger light btn-sm">
            Close
          </Button>
          <Button type="submit" name="submit" variant="primary btn-sm">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Fragment>
  );
}

export default TimeSlotForm;
