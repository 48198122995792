import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { getHistorySmsLog } from "../../actions/sms";
import Loader from "../../layouts/Loader";

const Chat = ({ PerfectScrollbar, toggleChatBox, contact }) => {
  //let contact_uuid = contact.contact_uuid;
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [contactsSms, setContactsSms] = useState([]);
  //const [ContactsEvent, setContactsEvent] = useState("");

  useEffect(() => {
    setLoading(true);
    dispatch(
      getHistorySmsLog(contact.contact_uuid, main_uuid, createdby, token)
    )
      .then((response) => {
        setContactsSms(response.data);
        //setContactsEvent("");
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, [contact]);
  return (
    <div className="card mb-sm-3 mb-md-0 contacts_card">
      {loading && <Loader />}
      <PerfectScrollbar
        className={`card-body contacts_body p-0 dz-scroll  ${
          toggleChatBox ? "ps ps--active-y" : ""
        }`}
        id="DZ_W_Contacts_Body1"
        style={{height: 'calc(97vh - 120px)'}}
      >
        <ul className="contacts">
          <li className="name-first-letter">Chat</li>
          {contactsSms.map((sms) => (
              <li key={sms.sms_campaign_log_uuid}>
                <div className="d-flex bd-highlight">
                  <div className="img_cont primary">
                    
                  </div>
                  <div className="user_info">
                    <span>{sms.message_text}</span>
                    <p>{sms.date_time}</p>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </PerfectScrollbar>
      <div className="card-footer"></div>
    </div>
  );
};

export default Chat;
