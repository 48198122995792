import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const getRinggroups = (
  user_type,
  group_per,
  group_uuid,
  search,
  off_set,
  limits,
  orderby,
  main_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "get-ringgroups-list",
      {
        user_type,
        group_per,
        group_uuid,
        search,
        off_set,
        limits,
        orderby,
        createdby,
        main_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const createRinggroup = (data, token) => {
  return axios
    .post(API_URL + "create-ringgroup", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const updateRinggroup = (data, token) => {
  return axios
    .post(API_URL + "update-ringgroup", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getRinggroupDetail = (createdby, token, ring_group_uuid) => {
  return axios
    .post(
      API_URL + "get-ringgroup-details",
      {
        createdby,
        ring_group_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const deleteRingGroup = (ring_group_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "delete-ringgroup",
      {
        ring_group_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const updateRinggroupDestination = (data, token) => {
  return axios
    .post(API_URL + "update-ringgroup-destination", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const deleteRingGroupDestination = (
  ring_group_destination_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "delete-ringgroup-destination",
      {
        ring_group_destination_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getNextRingGroupID = (main_admin_uuid, token, createdby) => {
  return axios
    .post(
      API_URL + "get-next-ringgroup-id",
      {
        main_admin_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getAdminVoicemail = (main_admin_uuid, token, createdby) => {
  return axios
    .post(
      API_URL + "get-admin-voicemail",
      {
        main_admin_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

export default {
  getRinggroups,
  createRinggroup,
  updateRinggroup,
  getRinggroupDetail,
  deleteRingGroup,
  updateRinggroupDestination,
  deleteRingGroupDestination,
  getNextRingGroupID,
  getAdminVoicemail,
};
