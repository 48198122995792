import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const AuthVerify = (props) => {
  let location = useLocation();
  let navigate = useNavigate();

  if (props.isLogout === "Yes") {
    navigate("/logout", { replace: true });
    window.location.reload();
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      const decodedJwt = parseJwt(user.data.token);
      if (decodedJwt.exp * 1000 < Date.now()) {
        navigate("/logout", { replace: true });
        window.location.reload();
      }
    }
  }, [location, props]);

  return <></>;
};

export default AuthVerify;
