import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

//CalendarMeeting


const scheduleMeeting = (create_data) => {
  return axios
    .post(API_URL + "calender/schedule-meeting", create_data)
    .then((response) => {
      return response.data;
    });
};

const getCalendarMeetings = (createdby, token) => {
  return axios
    .post(
      API_URL + "calender/get-calendar-meetings",
      {
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}

const cancelCalendarMeeting = (calendar_meeting_uuid,createdby, token) => {
  return axios
    .post(
      API_URL + "calender/cancel-calendar-meeting",
      {
        calendar_meeting_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}

const reScheduleCalendarMeeting = (data, token) => {
  return axios
    .post(
      API_URL + "calender/reschedule-calendar-meeting",
      data,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}

const createCalendarEvent = (data, token) => {
  return axios
    .post(
      API_URL + "calender/create-calendar-event",
      data,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}

const getEventContacts = (createdby,calendar_meeting_uuid, token) => {
  return axios
    .post(
      API_URL + "calender/get-event-contacts",
      {
        createdby,
        calendar_meeting_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}

const getMeetingDetails = (user_meeting_token,type) => {
  return axios
    .post(
      API_URL + "calender/get-meeting-details",
      {
        user_meeting_token,
        type
      }
    )
    .then((response) => {
      return response.data;
    });
}

export default {
  scheduleMeeting,
  getCalendarMeetings,
  cancelCalendarMeeting,
  reScheduleCalendarMeeting,
  createCalendarEvent,
  getEventContacts,
  getMeetingDetails,
};
