import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const getCdrs = (
  user_type,
  group_per,
  group_uuid,
  search,
  searchData,
  off_set,
  limits,
  orderby,
  main_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "get-cdrs",
      {
        user_type,
        group_per,
        group_uuid,
        search,
        searchData,
        off_set,
        limits,
        orderby,
        createdby,
        main_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};


export default {
  getCdrs,
};
