import React, { Fragment, useState } from "react";
import { Modal, Button, Card, Tab, Nav, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../layouts/PageTitle";
import TimeSlotForm from "./TimeSlotForm";
import TimeSlotListForm from "./TimeSlotListForm";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import { updateTimeCondition } from "../../actions/timeconditions";
import { getDestinationTypeList } from "../../actions/ivr";
import "./style.css";
import Loader from "../../layouts/Loader";

function UpdateTimeconditionForm(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const [timeSlotEvent, setTimeSlotEvent] = useState("");

  const handleTimeSlotEvent = (event) => {
    setTimeSlotEvent(event);
  };

  const timeconditionsday = [];
  const [options, setOptions] = useState([]);
  const [timeconditionData, setTimeconditionData] = useState({
    time_condition_id: props.timeRoutingId,
    time_condition_uuid: "",
    time_condition_name: "",
    route_type: "",
    route_to: "",
    token: token,
    createdby: createdby,
    main_admin_uuid: createdby,
    user_uuid: createdby,
  });

  const [errors, setErrors] = useState({
    time_condition_name: "",
    route_type: "",
    route_to: "",
  });

  const [loading, setLoading] = useState(false);
  const [addTimeSlotModal, setAddTimeSlotModal] = useState(false);

  const closeModel = (value) => {
    setAddTimeSlotModal(value);
  };
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBlur = (e) => {
    const newTimeconditionData = { ...timeconditionData };
    let error_data = { ...errors };

    if (e.target.name === "time_condition_name" && e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }

    if (e.target.name === "route_type" && e.target.value.length !== 0) {
      const main_uuid = user_data.main_uuid;
      newTimeconditionData["route_type"] = e.target.value;
      dispatch(
        getDestinationTypeList(createdby, token, main_uuid, e.target.value)
      )
        .then((response) => {
          setOptions(response.data);
          newTimeconditionData["route_to"] = response.data[0].value;
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    }

    newTimeconditionData[e.target.name] = e.target.value;
    setTimeconditionData(newTimeconditionData);
  };


  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (timeconditionData.time_condition_name.length === 0) {
      error_data["time_condition_name"] = "Time Condition name is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (timeconditionData.route_type.length === 0) {
      error_data["route_type"] = "Route Type is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (timeconditionData.route_to.length === 0) {
      error_data["route_to"] = "Route to is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create user API
      dispatch(updateTimeCondition(timeconditionData, token))
        .then((res) => {
          if (res.status === 200) {
            notifyInfo(res.message);
            // navigate(`/update-time-condition`, {
            //   state: { time_condition_uuid: time_condition_uuid },
            // });
            setLoading(false);
          } else if (res.status === 406) {
            error_data["time_condition_name"] = res.message;
            setErrors(error_data);
            isError = "true";
            setLoading(false);
          } else if (res.status === 500) {
            setLoading(false);
            notifyError("Something went wrong.");
          } else {
            setLoading(false);
            notifyError("Something went wrong.");
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle
        activeMenu="Add Time-Based Routing"
        motherMenu="Time Conditions"
      />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Add Time-Based Routing</h4>
              <Button
                type="button"
                className="btn btn-default btn-sm"
                onClick={() => navigate("/time-conditions", { replace: true })}
              >
                <i className="fa fa-reply" aria-hidden="true"></i> Back
              </Button>
            </div>
            <div className="card-body">
              <form action="" onSubmit={(e) => submitHandler(e)}>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Time Condition Name</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        //placeholder="Enter a name"
                        name="time_condition_name"
                        defaultValue={timeconditionData.time_condition_name}
                        onChange={handleBlur}
                      />
                      <span className="text-danger">
                        {errors.time_condition_name}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Off Hours Route Type</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="route_type"
                        onChange={handleBlur}
                        value={timeconditionData.route_type}
                      >
                        <option value="">Select Route Type</option>
                        <option value="ring-group">Ring Group</option>
                        <option value="extention">Extention</option>
                        <option value="did">Did</option>
                        <option value="voicemail">Voicemail</option>
                      </select>
                      <span className="text-danger">{errors.route_type}</span>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Route To</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="route_to"
                        onChange={handleBlur}
                        value={timeconditionData.route_to}
                      >
                        {options &&
                          options.map((number) => (
                            <option key={number.value} value={number.value}>
                              {number.text}
                            </option>
                          ))}
                      </select>
                      <span className="text-danger">{errors.route_to}</span>
                    </div>
                  </div>
                  <div className="col-sm-1">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong></strong>
                      </label>
                      <Button type="submit" name="submit" variant="primary">
                        Save
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <Card>
                    <Card.Body>
                      <div className="default-tab">
                        <Tab.Container defaultActiveKey="Profile">
                          <Nav as="ul" className="nav-tabs">
                            <Nav.Item as="li">
                              <Nav.Link eventKey="Home">
                                <i className={`la la-clock mr-2`} />
                                Weekly Schedule
                              </Nav.Link>
                            </Nav.Item>

                            <Nav.Item as="li">
                              <Nav.Link eventKey="Profile">
                                <i className={`la la-calendar mr-2`} />
                                Calendar
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                          <Tab.Content className="pt-12">
                            <Tab.Pane eventKey="Home">
                              <div className="card-header">
                                <h4 className="card-title">Weekly Schedule</h4>
                                <Button
                                  type="button"
                                  className="btn btn-primary btn-sm"
                                  onClick={() => setAddTimeSlotModal(true)}
                                >
                                  Add New Time Slot
                                </Button>
                              </div>
                              <div className="card-body">
                                <Table responsive className="w-100">
                                  <thead>
                                    <tr role="row">
                                      <th>Day</th>
                                      <th>Action</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {timeconditionsday &&
                                      timeconditionsday.map((timecondition) => (
                                        <tr
                                          key={
                                            timecondition.time_condition_data_uuid
                                          }
                                        >
                                          <td>
                                            {timecondition.time_condition_day}
                                          </td>
                                          <td>
                                            <TimeSlotListForm
                                              key={timeSlotEvent}
                                              time_condition_data_uuid={
                                                timecondition.time_condition_data_uuid
                                              }
                                              // time_condition_uuid={
                                              //   time_condition_uuid
                                              // }
                                              handleTimeSlotEvent={
                                                handleTimeSlotEvent
                                              }
                                            />
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </Table>
                              </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="Profile">
                              <div className="card-header">
                                <h4 className="card-title">Calendar</h4>
                              </div>

                              <div className="card-body">
                                {/* <CalendarView key={timeSlotEvent} /> */}
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal className="fade" show={addTimeSlotModal}>
        <TimeSlotForm
          closeModel={closeModel}
          timeSlot={""}
          destinationTypeOption={""}
          handleTimeSlotEvent={handleTimeSlotEvent}
        />
      </Modal>
    </Fragment>
  );
}

export default UpdateTimeconditionForm;
