import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Scripts from "../Scripts";
import Draggable from 'react-draggable';
import Tooltip from "rc-tooltip";
import PermissionCheck from "../../common/PermissionCheck";
import DispositionForm from "./DispositionForm";
import {
  getWebphoneContact,
} from "../../actions/contacts";
import Dropdown from 'react-bootstrap/Dropdown';
import Extensions from './Extensions';



const WEBPHONE_JS_URL = process.env.REACT_APP_WEHPHONE_JS_URL;

const DialPad = ({ extension, password,domainName, loadScript, dialpadStatus, handalWebphoneClick, setSelectedContact,contactDetail, isDialpadShow, dialNumber, ChangeExtensionListView, isExtensionListShow }) => {

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;
  const module_name = "contacts";
  let listing_per = PermissionCheck(module_name, "listing", "", "", "");

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }

  const dispatch = useDispatch();

  const [dailToggle, setDailToggle] = useState("");
  const [dailpadPosition, setDailpadPosition] = useState({ x: 0, y: 0 });
  const [dialpadStyle, setDialpadStyle] = useState({position: ''});
  const [key, setKey] = useState(0);
  const [dispositionModal, setDispositionModal] = useState(false);
  const [transferModal, setTransferModal] = useState(false);
  

  //call variables
  const [isIncomingCall, setIsIncomingCall] = useState(false);
  const [isCallRunning, setIsCallRunning] = useState(false);
  const [isOutgoingCall, setIsOutgoingCall] = useState(false);
  const [DialedNumber, setDialedNumber] = useState('');
  const [HangupCause, setHangupCause] = useState('');

  useEffect(() => {
    setDialedNumber(dialNumber);
  }, [dialNumber]);

  const dailNumber = (event,number) => {
    event.preventDefault();
    setDialedNumber(number)
 }

  window.JSchangeDialpadStatus = (webphone_val,caller) => {
    if (webphone_val !=='') {
      handalWebphoneClick(webphone_val);
    }

    if (caller !== '') {
      checkContact(caller);
      setIsIncomingCall(true);
    }
    
  }

  window.JSchangeCallRunning = (val) => {
    setIsCallRunning(true);
    
      setIsIncomingCall(false);  
      setIsOutgoingCall(false); 
  }

  window.JSchangeHangupCall = (val,cause) => {

    let call_status = isCallRunning;
    setIsCallRunning(false);
   
    setIsIncomingCall(false);
    setIsOutgoingCall(false);

    if(call_status){
        //call disposition popup function
        setDispositionModal(true);
        setHangupCause(cause);
    }   
    
  }

  window.JSchangeOutboundUpdate = (val,caller) => {
    //changeDialpadStatus(dialpad_val);
    if (caller !== '') {
      checkContact(caller);
    }
    setDialedNumber(caller);
    setIsOutgoingCall(val);
    setIsIncomingCall(false);
    
  }

  window.JSsetDialNumber = (val) => {
   
    setDialedNumber(val);
    
  }

  const closeDispositionModal = () => {
    setDispositionModal(false);
  }

  const toggalDialpad = () => {

    let status = '';
    if (dailToggle  === 'dialpad') {
      status = '';
    }else{
      status = 'dialpad';
    }
    
    changeDialpadStatus(status);

  }

  const changeDialpadStatus = (val) => {
      setDailToggle(val);
      dialpadStatus(val);
      
    if(val === ''){  

        setDailpadPosition({ x: 0, y: 0 });
        setKey(key + 1);
      
      setDialpadStyle({position: ''});
    }
  }

  const handleDrag = (e, ui) => {

     const { x, y } = dailpadPosition;
     setDailpadPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
      setDialpadStyle({position: 'absolute'});
      dialpadStatus('');

  };

  const { position } = dailpadPosition;
  //const { EXTposition } = extensionListPosition;
  const [extensionListPosition, setExtensionListPosition] = useState({ x: 0, y: 0 });
  const [extensionListToggal, setExtensionListToggal] = useState('');
  const [extensionListkey, setExtensionListKey] = useState(100);

  const handleExtensionDrag = (e, ui) => {
    const { x, y } = extensionListPosition;
    setExtensionListPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
    //setExtensionListStyle({position: 'absolute'});
    ChangeExtensionListView('NO');
  }

  const toggalExtensionList = () => {

    if (extensionListToggal === 'showlist') {
      setExtensionListToggal('');
      ChangeExtensionListView('NO');
    }else{
      setExtensionListToggal('showlist');
      ChangeExtensionListView('YES');
    }

    setExtensionListPosition({ x: 0, y: 0 });
    setExtensionListKey(extensionListkey + 1);
  }

  const checkContact = async (value) => {
    // Make an HTTP request to the server to fetch suggestions based on the input value
    const data = {
        limits: 1,
        off_set: 0,
        orderby: "c.created_at DESC",
        group_per: group_per,
        group_uuid: group_uuid,
        user_type: user_type,
        token: token,
        createdby: createdby,
        main_uuid: main_uuid,
        user_uuid: createdby,
        search : value
    }
       
    dispatch(getWebphoneContact(data))
    .then((response) => {
        setSelectedContact(response.data.data);
        return;
    })
    .catch(() => {
       
    });
  };

  const transferCall = () => {
    document.getElementById("btn_transfer").click();
    setTransferModal(false);
  }

   return (
      <div className="UserCallDetail">

        <div className="dialUser">
          <span id="phone_extension" className="d-none"></span> 
            <div className="row">
              {/* <div className="col-sm-2 mt-2">
                <span className="call-txt">Call: </span>
              </div> */}

              <div className="col-sm-8">
              <span className="call-txt">Ext: </span> {extension} 
                {/* <Dropdown>
                  <Dropdown.Toggle variant="" id="dropdown-basic">
                    <span className="usertxt">{contactDetail.first_name} {contactDetail.last_name}</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#" onClick={(e) => dailNumber(e,contactDetail.work_contact_number)}>{contactDetail.work_contact_number}</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={(e) => dailNumber(e,contactDetail.other_contact_number)}>{contactDetail.other_contact_number}</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
              </div>

              <div className="col-sm-2 pr-0">
                <span className="pull-right mt-2 h5" id="div-ext">
                  <Tooltip
                    placement="top"
                    overlay={<span><span id="phone_extension">Ext: {extension}</span></span>}
                  >
                    <i className="fa fa-circle" aria-hidden="true"></i>
                  </Tooltip>
                </span>
              </div>

              <div className="col-sm-2">
                <Dropdown>
                  <Dropdown.Toggle variant="" id="dropdown-basic1">
                    <i className="fa fa-cog"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

            </div>
        </div>

        {/* <div className="dialUser">

          <span className="call-txt">Call: </span> 
          <span className="dropdown"> 
            <Dropdown>
              <Dropdown.Toggle variant="" id="dropdown-basic">
                <span className="usertxt">{contactDetail.first_name} {contactDetail.last_name}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
					
          </span>
            
             <span id="phone_extension" className="d-none"></span> <span className="ml-2 mb-0 fs--1 d-none" id="reg_status">Unregister</span>
            
            <span className="pull-right mr-4 h5" id="div-ext">
              <Tooltip
                placement="top"
                overlay={<span><span id="phone_extension">Ext: {extension}</span><span id="reg_status"></span></span>}
              >
                <i className="fa fa-circle" aria-hidden="true"></i>
              </Tooltip>
            </span>
            
            

            <div className="dropdown togmenu">
              <Dropdown>
                <Dropdown.Toggle variant="" id="dropdown-basic1">
                  <i className="fa fa-cog"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

            </div>
        </div> */}
        
          
          {/* <div className="media d-flex text-center ml-5">
            <div className="avatar ml-2 mt-3">
                  <div className="" id="div-ext">
                     <i className="fa fa-circle"></i>{" "}
                     <span id="phone_extension" className="text-white"></span>
                  </div>
            </div>
            <div className="media-body mt-3">
                  <h5 className="mb-0 fs--1" id="reg_status">Unregister</h5>
            </div>
         </div> */}

         <div className="media text-center ml-2" style={{display : (isIncomingCall || isCallRunning)? " " : "none"}}>
            
            <div className="media-body text-green">
                  {contactDetail.first_name} {contactDetail.last_name}
                  <span className={"ml-2 mb-0 fs--1 " + ((isIncomingCall || isCallRunning) ? '' : 'd-none')} id="reg_status">Unregister</span>
            </div>
         </div>
       
        <div className="tab-content mt-2">
          <div className="col-xl-4 col-lg-12 col-xxl-4 col-sm-12"></div>
          <div className="col-xl-3 col-lg-12 col-xxl-3 col-sm-12"></div>
          <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
            <div className="">
              <div className="text-center ai-icon  text-primary" style={{ position: 'relative'}}>
                
                <Draggable
                   handle=".handle"
                   defaultPosition={position}
                   position={null}
                   grid={[1, 1]}
                  onDrag={handleDrag}  
                  key={key} // Adding the key here                
                
                >
                <div className={`bgl-success handle ${dailToggle === "dialpad" ? "d-block" : "d-none"}`}  style={dialpadStyle}>

                  <div className="col-md-12">
                    <button
                      type="button"
                      className="badge badge-circle badge-danger pull-right"
                      onClick={() => changeDialpadStatus('')}
                    >
                      <span>×</span>
                    </button>
                    <input type="text" className="form-control input-default" style={{height: '45px'}} value={DialedNumber} onKeyUp={(e) => setDialedNumber(e.target.value)} onChange={(e) => setDialedNumber(e.target.value)}/>
                    
                  </div>

                  <div className="col-md-12">
                    <button type="button"
                      className="btn my-2 btn light btn-rounded mx-2 btn-primary border border-primary"
                      id="one"
                    >
                      1
                    </button>
                    <button
                      type="button"
                      className="btn my-2 btn light btn-rounded mx-2 btn-primary border border-primary"
                      id="two"
                    >
                      2
                    </button>
                    <button
                      type="button"
                      className="btn my-2 btn light btn-rounded mx-2 btn-primary border border-primary"
                      id="three"
                    >
                      3
                    </button>
                  </div>

                  <div className="col-md-12">
                    <button
                      type="button"
                      className="btn my-2 btn light btn-rounded mx-2 btn-primary border border-primary"
                      id="four"
                    >
                      4
                    </button>
                    <button
                      type="button"
                      className="btn my-2 btn light btn-rounded mx-2 btn-primary border border-primary"
                      id="five"
                    >
                      5
                    </button>
                    <button
                      type="button"
                      className="btn my-2 btn light  btn-rounded mx-2 btn-primary border border-primary"
                      id="six"
                    >
                      6
                    </button>
                  </div>

                  <div className="col-md-12">
                    <button
                      type="button"
                      className="btn my-2 btn light btn-rounded mx-2 btn-primary border border-primary"
                      id="seven"
                    >
                      7
                    </button>
                    <button
                      type="button"
                      className="btn my-2 btn light btn-rounded mx-2 btn-primary border border-primary"
                      id="eight"
                    >
                      8
                    </button>
                    <button
                      type="button"
                      className="btn my-2 btn light btn-rounded mx-2 btn-primary border border-primary"
                      id="nine"
                    >
                      9
                    </button>
                  </div>

                  <div className="col-md-12">
                    <button
                      type="button"
                      className="btn my-2 btn light btn-rounded mx-2 btn-primary border border-primary"
                      id="pound"
                    >
                      #
                    </button>
                    <button
                      type="button"
                      className="btn my-2 btn light btn-rounded mx-2 btn-primary border border-primary"
                      id="zero"
                    >
                      0
                    </button>
                    <button
                      type="button"
                      className="btn my-2 btn light btn-rounded mx-2 btn-primary border border-primary"
                      id="star"
                    >
                      *
                    </button>
                  </div>

                  <button
                    type="button"
                    className="btn my-2 btn-primary btn-sm w-75 "
                    id="dial"
                  >
                    <i className="fa fa-phone"></i> Dial
                  </button>
                </div>
                </Draggable>

                <Extensions isExtensionListShow={isExtensionListShow} ChangeExtensionListView={ChangeExtensionListView} extensionListToggal={extensionListToggal}
                  extensionListkey={extensionListkey} extensionListPosition={extensionListPosition} handleExtensionDrag={handleExtensionDrag} dailNumber={dailNumber} isDialpadShow={isDialpadShow} toggalExtensionList={toggalExtensionList}/>
               

                {/* <div className="media text-center ml-5" style={{display : (isIncomingCall || isCallRunning || isOutgoingCall)? "none" : ""}}>
            
                    <div className="media-body">
                        <input type="text" className="form-control input-default" style={{height: '45px',width:'210px'}} value={DialedNumber} onKeyUp={(e) => setDialedNumber(e.target.value)} onChange={(e) => setDialedNumber(e.target.value)} />
                    </div>
                </div> */}

                <div className="mb-2 dial-search" style={{display : (isDialpadShow === 'YES' || isIncomingCall || isCallRunning || isOutgoingCall)? "none" : ""}}>
										<input type="text" className="form-control" placeholder="Enter number to dial" value={DialedNumber} onKeyUp={(e) => setDialedNumber(e.target.value)} onChange={(e) => setDialedNumber(e.target.value)}/>
								</div>

                <div className="text-center mt-2" style={{display : (isIncomingCall || isCallRunning || isOutgoingCall)? " " : "none"}}>
                    <div className="col-md-12">

                      <span className="mr-1 text-white h4"><i className="fa fa-volume-up"></i></span>
                      <input type="range" min="1" max="100" defaultValue="50" id="volumeBar" />
                     
                    </div>
                </div>

                {/* setting buttons start */}

                <div className="CallUser clearfix text-center" >
                  <button type="button" style={{display : (isIncomingCall || isOutgoingCall)? "none" : ""}} title="Contacts" onClick={() => toggalExtensionList()}>
                    <i className="fa fa-address-book-o" aria-hidden="true"></i>
                  </button>
                  <button type="button" onClick={() => toggalDialpad()} title="DialPad" style={{display : (isIncomingCall || isOutgoingCall) ? "none" : ""}}>
                    <i className="fa fa-th" aria-hidden="true"></i>
                    

                    {/* <svg fill="#1e5df1" width="64px" height="64px" viewBox="-8 -8 36.00 36.00" 
                        xmlns="http://www.w3.org/2000/svg" 
                        stroke="#1e5df1" 
                        strokeWidth="0.0002" transform="rotate(0)">
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                            <path d="M5 4a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm5 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm5 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM5 9a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm5 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm5 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM5 14a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm5 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm5-6a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"></path>
                        </g>
                    </svg> */}

                  </button>
                  <button type="button" style={{display : (isIncomingCall || isOutgoingCall || isCallRunning)? "none" : ""}}>
                    <i className="fa fa-tripadvisor" aria-hidden="true"></i>
                  </button>
                  <button type="button" id="dial_2"  title="Call" className="active " style={{display : (isOutgoingCall || isCallRunning)? "none" : ""}}>
                    <i className="fa fa-phone" aria-hidden="true"></i>
                  </button>

                  <button type="button" className="hangup" style={{display : (isIncomingCall || isOutgoingCall || isCallRunning)? " " : "none"}} title="HangUp Call" id="hangup">
                    <i className="fa fa-phone"></i> 
                  </button>

                  <button type="button" style={{display : (isCallRunning)? " " : "none"}} id="transfer" onClick={() => setTransferModal(true)} title="Transfer Call">
                    <i className="fa fa-exchange"></i> 
                  </button>


                  <button type="button" style={{display : (isIncomingCall)? " " : "none"}} id="voicemail">
                    <i className="fa fa-paper-plane"></i> 
                  </button>
                                    
                </div>

                <div className="CallUser clearfix text-center" style={{display : (isCallRunning)? " " : "none"}}>
                  <button type="button" id="mic_mute" title="MUTE">
                    <i className="fa fa-microphone"></i>
                  </button>

                  <button type="button">
                    <i className="fa fa-ban"></i>
                  </button>

                  <button type="button" title="HOLD/UNHOLD CALL" id="hold_call">
                    <i className="fa fa-pause-circle-o"></i>
                  </button>

                  <button type="button"  >
                    <i className="fa fa-car"></i>
                  </button>


                    {/* <div className="col-md-12" style={{display:'none'}}>
                      <div className="progress mb-2 mt-2">
                          <div
                            className="progress-bar bg-info progress-animated"
                            id="inboundRingVolumeChart"
                            style={{ width: "50%", height: "6px" }}
                            role="progressbar"
                          ></div>
                      </div>
                    </div> */}
                </div>


                <div className="text-center text-primary mb-2">
                    <div className="col-md-12">
                      
                      {/* <a
                      href="javascript:;"
                      className="btn btn-sm btn-light btn-rounded mr-2 mt-2"
                      title="Contacts"
                      style={{display : (isIncomingCall || isOutgoingCall)? "none" : ""}}
                      >
                      <i className="fa fa-address-book"></i>
                      </a>
                      <a
                      href="javascript:;"
                      className="btn btn-sm btn-light btn-rounded mr-2 mt-2"
                      title="DialPad"
                      onClick={() => toggalDialpad()}
                      style={{display : (isIncomingCall || isOutgoingCall) ? "none" : ""}}
                      >
                      <i className="fa fa-th"></i>
                      </a> */}
                      {/* <a
                      href="javascript:;"
                      className="btn btn-sm btn-light btn-rounded mr-2 mt-2"
                      title="VOLUME UP"
                      style={{display : (isIncomingCall || isOutgoingCall || isCallRunning)? "none" : ""}}
                      >
                      <i className="fa fa-tripadvisor"></i>
                      </a> */}
                      {/* <a
                      href="javascript:;"
                      className="btn btn-sm btn-success btn-rounded mt-2"
                      title="Call"
                      id="dial_2"
                      style={{display : (isOutgoingCall || isCallRunning)? "none" : ""}}
                      >
                      <i className="fa fa-phone"></i>
                      </a> */}

                      {/* <a
                        href="javascript:;"
                        className="btn btn-sm btn-danger btn-rounded ml-2 mt-2 "
                        id="hangup"
                        style={{display : (isIncomingCall || isOutgoingCall || isCallRunning)? " " : "none"}}
                      >
                        <i className="fa fa-phone"></i> 
                      </a>

                      <a
                        href="javascript:;"
                        className="btn btn-sm btn-light btn-rounded ml-2 mt-2 "
                        id="transfer"
                        style={{display : (isIncomingCall || isCallRunning)? " " : "none"}}
                      >
                        <i className="fa fa-exchange"></i> 
                      </a>

                      <a
                        href="javascript:;"
                        className="btn btn-sm btn-light btn-rounded ml-2 mt-2 "
                        id="voicemail"
                        style={{display : (isIncomingCall)? " " : "none"}}
                      >
                        <i className="fa fa-paper-plane"></i> 
                      </a> */}

                    </div>

                </div>
                {/* setting buttons end */}

              </div>
              
              
            </div>
          </div>
          <div className="">

            <audio
              autoPlay
              style={{ width: "0px", height: "0px" }}
              id="audio"
            >
            </audio>

            <input type="hidden" id="user_extension" value={extension} />
            <input type="hidden" id="extension_password" value={password} />
            <input type="hidden" id="user_domain_name" value={domainName} />
            <input type="hidden" id="webphone_js_url" value={WEBPHONE_JS_URL} />
            <input type="hidden" id="digits" value={DialedNumber}/>
          </div>
          {loadScript ? <Scripts /> : ""}
          <Modal className="fade" show={dispositionModal}>
            <DispositionForm
              closeDispositionModal={closeDispositionModal}
              HangupCause={HangupCause}
            />
          </Modal>

          <Modal className="fade" show={transferModal}>
            <Modal.Body>
                <label className="mb-1">
                  <strong>Enter Number to transfer call</strong>
                </label>
              <input type="text" className="form-control" name="transfer_target" id="transfer_target"/>
              <button onClick={transferCall} className="btn btn-sm btn-primary">Submit</button>
            </Modal.Body>
            
          </Modal>

          <button id='btn_transfer'  className="d-none">Transfer</button>

          {/* <Modal className="fade" show={videoModal}>
            <Modal.Header>
              <Modal.Title>Video Call </Modal.Title>
              <Button variant="" className="close" onClick={() => setVideoModal(false)}>
                <span>&times;</span>
              </Button>
            </Modal.Header>

            <Modal.Body>
              <video id="remoteView" autoPlay={true} _hidden="true"></video>
              <video id="selfView" autoPlay={true} muted={true} _hidden="true"></video>
              
            </Modal.Body>
            
          </Modal> */}
      
        </div>
      </div>
   );
};

export default DialPad;
