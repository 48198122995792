import { SET_MESSAGE, API_FAIL } from "./types";

import internalChatService from "../services/internalChat.service";

export const getContactUserList =
  (main_uuid, createdby, token) => (dispatch) => {
    return internalChatService
      .getContactUserList(main_uuid, createdby, token)
      .then(
        (data) => {
          if (
            data.status === 406 ||
            data.status === 500 ||
            data.status === 200
          ) {
            return data;
          } else {
            dispatch({
              type: API_FAIL,
            });

            dispatch({
              type: SET_MESSAGE,
              payload: data.message,
            });

            return Promise.reject();
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });

          return Promise.reject();
        }
      );
  };

export const getInternalChatLog =
  (to_uuid, from_uuid, group_uuid, tab, main_uuid, createdby, token) =>
  (dispatch) => {
    
    return internalChatService
      .getInternalChatLog(
        to_uuid,
        from_uuid,
        group_uuid,
        tab,
        main_uuid,
        createdby,
        token
      )
      .then(
        (data) => {
          if (
            data.status === 406 ||
            data.status === 500 ||
            data.status === 200
          ) {
            return data;
          } else {
            dispatch({
              type: API_FAIL,
            });

            dispatch({
              type: SET_MESSAGE,
              payload: data.message,
            });

            return Promise.reject();
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });

          return Promise.reject();
        }
      );
  };

export const sendInternalMessage = (data, token) => (dispatch) => {
  return internalChatService.sendInternalMessage(data, token).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getGroupList = (main_uuid, createdby, token) => (dispatch) => {
  return internalChatService.getGroupList(main_uuid, createdby, token).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getGroupUserList = (main_uuid, createdby, token) => (dispatch) => {
  return internalChatService.getGroupUserList(main_uuid, createdby, token).then(
    (data) => {
      if (data.status === 406) {
        return data;
      } else if (data.status !== 200) {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      } else {
        return data;
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getGroupUserDetails =
  (group_uuid, main_uuid, createdby, token) => (dispatch) => {
    return internalChatService
      .getGroupUserDetails(group_uuid, main_uuid, createdby, token)
      .then(
        (data) => {
          if (
            data.status === 406 ||
            data.status === 500 ||
            data.status === 200
          ) {
            return data;
          } else {
            dispatch({
              type: API_FAIL,
            });

            dispatch({
              type: SET_MESSAGE,
              payload: data.message,
            });

            return Promise.reject();
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });

          return Promise.reject();
        }
      );
  };

export const deleteGroupMember =
  (user_uuid, group_uuid, group_delete, createdby, token) => (dispatch) => {
    return internalChatService
      .deleteGroupMember(user_uuid, group_uuid, group_delete, createdby, token)
      .then(
        (data) => {
          if (
            data.status === 406 ||
            data.status === 500 ||
            data.status === 200
          ) {
            return data;
          } else {
            dispatch({
              type: API_FAIL,
            });

            dispatch({
              type: SET_MESSAGE,
              payload: data.message,
            });

            return Promise.reject();
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });

          return Promise.reject();
        }
      );
  };

export const getGroupDetails = (group_uuid, createdby, token) => (dispatch) => {
  return internalChatService.getGroupDetails(group_uuid, createdby, token).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const sendChatFile = (data, token) => (dispatch) => {
  return internalChatService.sendChatFile(data, token).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getMessageNotification =
  (main_uuid, createdby, token) => (dispatch) => {
    return internalChatService
      .getMessageNotification(main_uuid, createdby, token)
      .then(
        (data) => {
          if (
            data.status === 406 ||
            data.status === 500 ||
            data.status === 200
          ) {
            return data;
          } else {
            dispatch({
              type: API_FAIL,
            });

            dispatch({
              type: SET_MESSAGE,
              payload: data.message,
            });

            return Promise.reject();
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });

          return Promise.reject();
        }
      );
  };
