import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { notifyInfo, notifyError } from "../../Toastr/Toastr";
import { getTimezone } from "../../../actions/comman";
import { updateAdminPersonalInfo } from "../../../actions/auth";
import { getUserDetail } from "../../../actions/user";
import Tooltip from "rc-tooltip";
import EasyEdit, { Types } from "react-easy-edit";
import profile from "../../../../images/profile/demo_profile.png";
import "./style.css";
import Loader from "../../../layouts/Loader";

const AdminProfile = (props) => {
  const dispatch = useDispatch();
  const PROFILE_URL = process.env.REACT_APP_SERVER_PROFILE_URL;
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;

  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [userEvent, setUserEvent] = useState("");
  const [timeZones, setTimeZones] = useState([]);

  const [errors, setErrors] = useState({
    company_email: "",
    company_phone_number: "",
    company_address: "",
    company_address2: "",
    city: "",
    state: "",
    postal_code: "",
    phone_number: "",
    time_zone: "",
  });

  useEffect(() => {
    dispatch(getUserDetail(createdby, createdby, token))
      .then((response) => {
        setUserData(response.data.user);
        return;
      })
      .catch(() => {
        //setLoading(false);
      });
  }, [userEvent]);

  const handleBlur = (e) => {
    const newGroupData = { ...userData };
    let error_data = { ...errors };
    if (e.target.value.length === 0) {
      error_data[e.target.name] = "Time zone is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }

    newGroupData[e.target.name] = e.target.value;
    setUserData(newGroupData);
  };

  useEffect(() => {
    dispatch(getTimezone(main_uuid, createdby, token))
      .then((response) => {
        let arr = response.data.data.map((obj, i) => {
          return { label: obj.timezone_name, value: obj.time_zone_uuid };
        });
        setTimeZones(arr);
        return;
      })
      .catch(() => {
        //setLoading(false);
      });
  }, []);

  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);
 
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (e) => {
    const MAX_FILE_SIZE = 5120; // 5MB
    if (
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg" ||
      e.target.files[0].type === "image/jpeg"
    ) {
      const fileSizeKiloBytes = e.target.files[0].size / 1024;

      if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        // setErrorMsg("File size is greater than maximum limit");
        notifyError("File size not allow greater then 5MB.");
        return;
      }
      save(e.target.files[0], "file");
    } else {
      notifyError("File allow only waw and mp3.");
      return;
    }
    // const fileUploaded = event.target.files[0];
    // handleFile(fileUploaded);
  };

  const save = (value, attribute) => {
    let formData = new FormData();
    setLoading(true);

    if (attribute === "file") {
      formData.append("file", value);
      formData.append("fileName", value.name);

      formData.append("profile_pic", userData.profile_image);
      formData.append("phone", userData.phone);
      formData.append("time_zone", userData.time_zone);
      formData.append("createdby", createdby);
      formData.append("main_uuid", main_uuid);
      formData.append("user_uuid", createdby);
      if (attribute === "username") {
        formData.delete("first_name");
        formData.delete("last_name");
        formData.append("first_name", value.first_name);
        formData.append("last_name", value.last_name);
      } else {
        formData.delete(attribute);
        formData.delete("first_name");
        formData.delete("last_name");
        formData.append("first_name", userData.first_name);
        formData.append("last_name", userData.last_name);
        formData.append(attribute, value);
      }

      
    } else {
      formData.append("file", "");
      formData.append("fileName", "");
      formData.append("first_name", userData.first_name);
      formData.append("last_name", userData.last_name);
      formData.append("profile_pic", userData.profile_image);
      formData.append("phone", userData.phone);
      formData.append("time_zone", userData.time_zone);
      formData.append("createdby", createdby);
      formData.append("main_uuid", main_uuid);
      formData.append("user_uuid", createdby);

      if (attribute === "username") {
        formData.delete("first_name");
        formData.delete("last_name");
        formData.append("first_name", value.first_name);
        formData.append("last_name", value.last_name);
      } else {
        formData.delete(attribute);
        formData.delete("first_name");
        formData.delete("last_name");
        formData.append("first_name", userData.first_name);
        formData.append("last_name", userData.last_name);
        formData.append(attribute, value);
      }

    }

    dispatch(updateAdminPersonalInfo(formData, token))
      .then((res) => {
        if (res.status === 200) {
          notifyInfo(res.message);
          setLoading(false);
          setUserEvent(Math.random);
        } else if (res.status === 500) {
          setLoading(false);
          notifyError("Something went wrong.");
        } else {
          setLoading(false);
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {});
  };

  let profile_src = "";
  if (userData.profile_image !== "" && userData.profile_image !== null) {
    profile_src = PROFILE_URL + userData.profile_image;
  } else {
    profile_src = profile;
  }

  const EditName = (props) => {
    const [nameData, setNameData] = useState({
      first_name: userData.first_name,
      last_name: userData.last_name,
    });

    function inputonChange(e) {
      let newArr = { ...nameData };
      newArr[e.target.name] = e.target.value;
      setNameData(newArr);
      props.setParentValue(newArr);
    }

    return (
      <div>
        <div className="row">
          <div className="col-6">
            <div className="form-group ">
              <label>First Name</label>

              <input
                type="text"
                className="form-control"
                placeholder="Enter First Name"
                name="first_name"
                onChange={inputonChange}
                value={nameData.first_name}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label>Last Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enater Last Name"
                name="last_name"
                onChange={inputonChange}
                value={nameData.last_name}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const DisplayName = (props) => {
    return (
      <div>
        <div className="row ml-1">
          <label>
            {userData.first_name} {userData.last_name}
          </label>
        </div>
      </div>
    );
  };

  const DisplayTimezone = (props) => {
    return (
      <div>
        <div className="row">
          <label>{props.timezone_name}</label>
        </div>
      </div>
    );
  };

  const EditTimezone = (props) => {

    return (
      <div>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <select
                className="form-control"
                value={props.time_zone}
                name="time_zone"
                onChange={handleBlur}
              >
                <option value="">Select Time Zone</option>
                {timeZones.map((timeZone) => (
                  <option key={timeZone.value} value={timeZone.value}>
                    {timeZone.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="pt-3">
      {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>Personal Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-3">
            <div className="profile-photo">
              <img
                src={profile_src}
                className="img-fluid rounded-circle img_height"
                alt="profile"
              />
            </div>
            <div className="mt-2">
              <Tooltip placement="top" overlay={<span>Upload New File</span>}>
                <button
                  className="button-upload btn btn-sm btn-info ml-4 position-relative"
                  onClick={(e) => hiddenFileInput.current.click()}
                >
                  Profile photo
                </button>
              </Tooltip>

              <input
                type="file"
                onChange={(e) => handleChange(e)}
                ref={hiddenFileInput}
                name="profile_upload"
                //ref={el => (hiddenFileInput = el)}
                id="profile_upload"
                accept="image/png, image/gif, image/jpeg"
                style={{ display: "none" }} // Make the file input element invisible
              />
            </div>
          </div>

          <div className="col-4">
            <div className="col-12">
              <div className="form-group">
                <label className="">
                  <strong>Admin Name</strong>
                  <span className="text-danger">*</span>
                </label>
                <EasyEdit
                  type={Types.TEXTAREA}
                  onSave={(value) => {
                    save(value, "username");
                  }}
                  editComponent={
                    <EditName
                      first_name={userData.first_name}
                      last_name={userData.last_name}
                    />
                  }
                  displayComponent={
                    <DisplayName
                      first_name={userData.first_name}
                      last_name={userData.last_name}
                    />
                  }
                  saveButtonLabel={
                    <i className="fa fa-check" aria-hidden="true"></i>
                  }
                  saveButtonStyle="label-primary easy-edit-button rounded-lg"
                  cancelButtonLabel={
                    <i className="fa fa-times" aria-hidden="true"></i>
                  }
                  cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                  onValidate={(value) => {
                    if (value) {
                      if (value.first_name && value.last_name) {
                        if (/^[a-z][a-z\s]*$/i.test(value.first_name)) {
                          if (/^[a-z][a-z\s]*$/i.test(value.last_name)) {
                            return true;
                          } else {
                            return false;
                          }
                        } else {
                          return false;
                        }
                      } else {
                        return false;
                      }
                    }
                  }}
                  attributes={{
                    name: "username",
                    className: "form-control bg-white h-75",
                  }}
                  placeholder="Admin Name"
                  value={userData.first_name}
                />

                <span className="text-danger">{errors.first_name}</span>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label className="mb-1">
                  <strong>Phone Number</strong>
                  <span className="text-danger">*</span>
                </label>

                <EasyEdit
                  type={Types.TEXT}
                  onSave={(value) => {
                    save(value, "phone");
                  }}
                  saveButtonLabel={
                    <i className="fa fa-check" aria-hidden="true"></i>
                  }
                  saveButtonStyle="label-primary easy-edit-button rounded-lg"
                  cancelButtonLabel={
                    <i className="fa fa-times" aria-hidden="true"></i>
                  }
                  cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                  attributes={{
                    name: "phone",
                    className: "form-control bg-white h-75",
                  }}
                  onValidate={(value) => {
                    if (!/^[0-9]{7,11}$/i.test(value)) {
                      return false;
                    } else {
                      return true;
                    }
                  }}
                  placeholder="Phone Number"
                  value={userData.phone}
                />
                <span className="text-danger">{errors.phone}</span>
              </div>
            </div>
          </div>

          <div className="col-5">
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group">
                  <strong>Email</strong>
                  <span className="text-danger">*</span>
                  <p>{userData.email}</p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <strong>Time Zone</strong>
                  <span className="text-danger">*</span>
                  <EasyEdit
                    type={Types.TEXTAREA}
                    onSave={(value) => {
                      save(value, "time_zone");
                    }}
                    editComponent={
                      <EditTimezone time_zone={userData.time_zone} />
                    }
                    displayComponent={
                      <DisplayTimezone
                        time_zone={userData.time_zone}
                        timezone_name={userData.timezone_name}
                      />
                    }
                    saveButtonLabel={
                      <i className="fa fa-check" aria-hidden="true"></i>
                    }
                    saveButtonStyle="label-primary easy-edit-button rounded-lg"
                    cancelButtonLabel={
                      <i className="fa fa-times" aria-hidden="true"></i>
                    }
                    cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                    attributes={{
                      name: "time_zone",
                      className: "form-control bg-white h-75",
                    }}
                    onValidate={(value) => {
                      return value ? true : false;
                    }}
                    placeholder="Select Time Zone"
                    value={userData.time_zone}
                  />
                  <span className="text-danger">{errors.time_zone}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </div>
  );
};

export default AdminProfile;
