import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const createCallCampaign = (data) => {
  return axios
    .post(API_URL + "create-call-campaign  ", data, {
      headers: {
        Authorization: data.token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getCallCampaignList = (
  main_uuid,
  user_type,
  group_per,
  group_uuid,
  search,
  off_set,
  status,
  limits,
  orderby,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "call-campaign-list",
      {
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        search,
        off_set,
        status,
        limits,
        orderby,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const deleteCallCampaign = (call_campaign_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "delete-call-campaign",
      {
        call_campaign_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateCallCampaign = (data) => {
  return axios
    .post(API_URL + "update-call-campaign", data, {
      headers: {
        Authorization: data.token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getCallCampaignDetail = (call_camp_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "get-call-campaign-details",
      {
        call_camp_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getContactGroupList = (main_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "get-contact-group-list-dropdoen",
      {
        main_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getCallBlastAudioList = (main_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "get-call-blast-audio-list",
      {
        main_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getAgentAndNumberList = (main_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "get-agen-and-number-list",
      {
        main_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getCampaignContacts = (
  main_uuid,
  user_type,
  group_per,
  group_uuid,
  call_campaign_uuid,
  off_set,
  limits,
  orderby,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "get-campaign-contact",
      {
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        call_campaign_uuid,
        off_set,
        limits,
        orderby,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getPowerDailCampaignData = (
  main_uuid,
  user_type,
  group_per,
  group_uuid,
  call_campaign_uuid,
  off_set,
  limits,
  orderby,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "get-powerdial-campaign-contact",
      {
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        call_campaign_uuid,
        off_set,
        limits,
        orderby,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getDialToContact = (main_uuid, call_campaign_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "get-dial-to-contact",
      {
        main_uuid,
        call_campaign_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const startPowerdialCall = (
  main_uuid,
  call_campaign_uuid,
  createdby,
  contact_phone_number,
  token
) => {
  return axios
    .post(
      API_URL + "start-powerdial-call",
      {
        main_uuid,
        call_campaign_uuid,
        createdby,
        contact_phone_number,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

export default {
  createCallCampaign,
  getCallCampaignList,
  deleteCallCampaign,
  updateCallCampaign,
  getCallCampaignDetail,
  getCallBlastAudioList,
  getContactGroupList,
  getAgentAndNumberList,
  getCampaignContacts,
  getPowerDailCampaignData,
  getDialToContact,
  startPowerdialCall,
};
