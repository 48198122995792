import React, { Fragment, useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import {
  createSmsTemplate,
  getContactFieldsList,
} from "../../actions/smstemplate";
import PageTitle from "../../layouts/PageTitle";
import Loader from "../../layouts/Loader";

function CreateSmsTempForm(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const textboxRef = useRef(null);

  const [smsTemplateData, setSmsTemplateData] = useState({
    name: "",
    message: "",
    message_type: "sms",
    camp_sms_type: "text",
    file: "",
    token: token,
    main_admin_uuid: main_uuid,
    createdby: createdby,
    user_uuid: createdby,
  });

  const [fieldsData, setFieldsData] = useState([]);
  const [messageVal, setMessageVal] = useState("");
  const [selectedOption, setSelectedOption] = useState("sms");
  const [textCount, setTextCount] = useState(0);
  const [smsCount, setSmsCount] = useState(0);
  const [file, setFile] = useState();

  const [errors, setErrors] = useState({
    name: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBlur = (e) => {
    const newGroupData = { ...smsTemplateData };
    let error_data = { ...errors };
    if (e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }

    if (e.target.name === "message") {
      setMessageVal(e.target.value);
    }

    // const MIN_FILE_SIZE = 1024 // 1MB
    const MAX_FILE_SIZE = 2048; // 5MB

    if (e.target.name === "file") {
      const fileSizeKiloBytes = e.target.files[0].size / 1024;

      if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        // setErrorMsg("File size is greater than maximum limit");
        error_data[e.target.name] = "File size not allow greater then 2MB.";
        setErrors(error_data);
        return;
      }

      setFile(e.target.files[0]);
    }

    newGroupData[e.target.name] = e.target.value;
    setSmsTemplateData(newGroupData);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (smsTemplateData.name.length === 0) {
      error_data["name"] = "Name is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (smsTemplateData.message_type.length === 0) {
      error_data["message_type"] = "Message Type is required.";
      setErrors(error_data);
      isError = "true";
    } else {
      error_data["message_type"] = "";
    }

    if (selectedOption === "mms" && file === undefined) {
      error_data["file"] = "Recording file is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (smsTemplateData.message.length === 0) {
      error_data["message"] = "Message is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create user API
      let file_name = "";
      if (selectedOption === "sms") {
        file_name = "";
      } else {
        if (file === undefined) {
          file_name = "";
        } else {
          file_name = file.name;
        }
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("file_name", file_name);
      formData.append("name", smsTemplateData.name);
      formData.append("camp_sms_type", smsTemplateData.camp_sms_type);
      formData.append("message_type", selectedOption);
      formData.append("message", smsTemplateData.message);
      formData.append("createdby", smsTemplateData.createdby);
      formData.append("main_admin_uuid", smsTemplateData.main_admin_uuid);
      formData.append("user_uuid", smsTemplateData.user_uuid);
      
      dispatch(createSmsTemplate(formData, smsTemplateData.token))
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            notifyInfo(res.message);
            navigate("/sms-template", { replace: true });
          } else if (res.status === 406) {
            error_data["name"] = res.message;
            setErrors(error_data);
            isError = "true";
          } else if (res.status === 500) {
            notifyError("Something went wrong.");
          } else {
            notifyError("Something went wrong.");
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getContactFieldsList(createdby, token))
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setFieldsData(response.data);
        }

        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  function handleSelect(data) {
    const textboxValue = textboxRef.current.value;
    let msg_val = textboxValue + " {" + data.target.value + "}";
    setMessageVal(msg_val);
    const newGroupData1 = { ...smsTemplateData };
    newGroupData1["message"] = msg_val;
    setSmsTemplateData(newGroupData1);
  }

  function handleSelectType(data) {
    setSelectedOption(data.target.value);
    const newGroupData1 = { ...smsTemplateData };
    newGroupData1["message_type"] = data.target.value;
    setSmsTemplateData(newGroupData1);
  }

  function toTitleCase(str) {
    const titleCase = str
      .toLowerCase()
      .split("_")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");

    return titleCase;
  }

  const countChar = (e) => {
    let len = e.target.value.length;
    let str = e.target.value;

    let parts = [];
    let partSize = 160;

    while (str) {
      if (str.length < partSize) {
        parts.push(str);
        break;
      } else {
        parts.push(str.substr(0, partSize));
        str = str.substr(partSize);
      }
    }

    setTextCount(len);
    setSmsCount(parts.length);
  };

  const breadCurmList = [
    { label: "SMS", link: "/", is_active: "NO" },
    { label: "SMS Template", link: "/sms-template", is_active: "NO" },
    { label: "Create SMS Template", link: "", is_active: "YES" },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Create SMS Template</h4>
              <Button
                type="button"
                className="btn btn-default btn-sm"
                onClick={() => navigate("/sms-template", { replace: true })}
              >
                <i className="fa fa-reply" aria-hidden="true"></i> Back
              </Button>
            </div>
            <div className="card-body">
              <form action="" onSubmit={(e) => submitHandler(e)}>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Name</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        name="name"
                        id="name"
                        onChange={handleBlur}
                      />
                      <span className="text-danger">{errors.name}</span>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Variable</strong>
                      </label>
                      <select
                        className="form-control"
                        name="variable"
                        onChange={handleSelect}
                      >
                        <option value="">Select Variable</option>
                        {fieldsData.map((user) => (
                          <option key={user} value={user}>
                            {toTitleCase(user)}
                          </option>
                        ))}
                      </select>
                      <span className="text-danger">
                        {errors.assign_number}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Message Type</strong>
                      </label>
                      <select
                        className="form-control"
                        name="message_type"
                        defaultValue={"sms"}
                        onChange={handleSelectType}
                      >
                        <option value="">Select Message Type</option>
                        <option value="sms">SMS</option>
                        <option value="mms">MMS</option>
                      </select>
                      <span className="text-danger">{errors.message_type}</span>
                    </div>
                  </div>
                  <div
                    className="col-6"
                    style={{
                      display: selectedOption === "mms" ? "block" : "none",
                    }}
                  >
                    <div className="form-group mt-2">
                      <label
                        className="mb-1"
                        title="File size shoud be less then 2 MB."
                      >
                        <strong>File Upload</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name="file"
                        onChange={handleBlur}
                      />
                      <span className="text-danger">{errors.file}</span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Message </strong>
                        <span className="text-danger">*</span>
                      </label>
                      <span className="pull-right">
                        {textCount}/{smsCount}
                      </span>
                      <textarea
                        className="form-control"
                        placeholder="Message"
                        name="message"
                        ref={textboxRef}
                        value={messageVal}
                        onChange={handleBlur}
                        onKeyUp={countChar}
                        // onChange={e => setMessageVal(e.target.value)}
                      ></textarea>
                      <span className="text-danger">{errors.message}</span>
                    </div>
                  </div>
                </div>
                <div className="row text-right">
                  <div className="col-sm">
                    <Button type="submit" variant="primary" className="btn-sm">
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default CreateSmsTempForm;
