import React, { Fragment, useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import PageTitle from "../../../layouts/PageTitle";
import Loader from "../../../layouts/Loader";
import CustomFieldForm from "../../../components/CRM/contacts/CustomFieldForm";
import UpdateCustomFieldForm from "../../../components/CRM/contacts/UpdateCustomFieldForm";
import CheckModulePermisson from "../../../common/CheckModulePermisson";
import { deleteCustomField } from "../../../actions/customfield";
import { getContactFormLayout,setCustomFieldPosition,getCustomContactFields,updateCustomFieldName, updateContactFormSection, getBulkUpdateFields, deleteBulkUpdateField } from "../../../actions/customContactLayout";
import TabForm from "./TabForm";
import Section from "./Section";
import SectionForm from "./SectionForm";
import BulkUpdateFieldForm from "./BulkUpdateFieldForm";
import Tooltip from "rc-tooltip";
import { confirmAlert } from "react-confirm-alert";
import EasyEdit, { Types } from 'react-easy-edit';
import "./style.css";

function CustomForm() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;
  const module_name = "custom-form";

  let module_per = CheckModulePermisson(module_name);

  let isPermission = true;
  if (module_per === "" || user_type === "admin") {
    isPermission = true;
  } else {
    isPermission = false;
  }
 
  const [formData, setFormData] = useState([]);
  const [formDataEvent, setFormDataEvent] = useState("");
  const [addCustomFieldModal, setAddCustomFieldModal] = useState(false);
  const [selectedCustomfield, setSelectedCustomfield] = useState('');
  const [updateCustomFieldModal, setUpdateCustomFieldModal] = useState(false);
  const [customFieldEvent, setCustomFieldEvent] = useState('');
  
  const [loading, setLoading] = useState(false);
  const [customFields, setCustomFields] = useState([]);

  const [addTabModal, setAddTabModal] = useState(false);
  const [addSectionModal, setAddSectionModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState({
    tab_uuid: "",
    section_uuid: "",
    sec_type: "",
  });
  const [selectedSection, setSelectedSection] = useState('');
  const [prevSectionOrder, setPrevSectionOrder] = useState('');

  const [addBulkUpdateFieldModal, setAddBulkUpdateFieldModal] = useState(false);
  const [bulkUpdateFieldEvent, setBulkUpdateFieldEvent] = useState('');
  const [bulkUpdateFields, setBulkUpdateFields] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {

    setLoading(true);
    dispatch(
      getContactFormLayout(
        main_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        setFormData(response.data);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, [formDataEvent]);
 
  useEffect(() => {

    setLoading(true);
    dispatch(
      getCustomContactFields(
        main_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        setCustomFields(response.data);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, [customFieldEvent]);

  useEffect(() => {

    setLoading(true);
    dispatch(
      getBulkUpdateFields(
        main_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        setBulkUpdateFields(response.data);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, [bulkUpdateFieldEvent]);

  const addNewSection = (prev_sec_order) => {
    //console.log(prev_sec_order);
    setPrevSectionOrder(prev_sec_order);
    setAddSectionModal(true);
  }

  const closeModel = (value) => {
    setAddCustomFieldModal(value);
  };

  const handleUpdateFieldModel = (value,custom_field_uuid = '') => {
    
    if(value){
      setSelectedCustomfield(custom_field_uuid);
    }
    setUpdateCustomFieldModal(value);
  };

  const handleCustomFieldEvent = (event) => {
    setCustomFieldEvent(event);
  };

  const handleAddTabModal = (value) => {
    //console.log(value);
    setSelectedSection(value)
    setAddTabModal(true);
  }

  const handleTabModal = (value) => {
    setAddTabModal(value);
  }

  const handleFormEvent = (event) => {
    setFormDataEvent(event);
  };

  const handleSectionModal = (value) => {
    setAddSectionModal(value);
  }

  const FndeleteCustomField = (e,custom_field_uuid) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this field?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteCustomFieldService(custom_field_uuid)
        },
        {
          label: "No"
        }
      ]
    });
  }

  const deleteCustomFieldService = (custom_field_uuid) => {
    setLoading(true);
    dispatch(deleteCustomField(custom_field_uuid,createdby,token))
        .then((response) => {                
          setCustomFieldEvent(Math.random);
          setLoading(false);
          return;
        })
        .catch(() => {
            setLoading(false);
        });        
  }

  const handleDragStart = (e, custom_field_uuid) => {
    e.dataTransfer.setData("custom_field_uuid", custom_field_uuid);
  };

  const handleDrop = (e,tab,sec_type) => {
    e.preventDefault();
    let custom_field_uuid = e.dataTransfer.getData("custom_field_uuid");
    setLoading(true);
    
    let data = {
      tab_uuid : tab.contact_form_tab_uuid,
      section_uuid : tab.contact_form_section_uuid,
      custom_field_uuid : custom_field_uuid,
      section_type : sec_type,
      main_user_uuid : main_uuid,
      createdby : createdby,
    }
    dispatch(setCustomFieldPosition(data, token))
      .then((response) => {

        if (response.status === 200) {
          //notifyInfo(response.message);
          handleFormEvent(Math.random);
          handleCustomFieldEvent(Math.random);
          setLoading(false);
          return;
        } else if (response.status === 500) {
          //notifyError("Something went wrong.");
        } else {
          //notifyError("Something went wrong.");
        }
        setLoading(false);
        
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleSectionDragStart = (e,sectionIndex) => {
    //e.dataTransfer.setData("section_uuid", section_uuid);
    e.dataTransfer.setData("SourcesectionIndex", sectionIndex);
  }

  const handleSectionDrop = (sectionIndex, e) => {
    let SourcesectionIndex = e.dataTransfer.getData("SourcesectionIndex");
    
    const newSections = [...formData];
    const [movedSection] = newSections.splice(SourcesectionIndex, 1);
    newSections.splice(sectionIndex, 0, movedSection);

    let order = 1;
    newSections.map(function(sec){
      let data = {
        contact_form_section_uuid : sec.contact_form_section_uuid,
        section_order : order,
        main_user_uuid : main_uuid,
        createdby : createdby,
      }
      dispatch(updateContactFormSection(data, token))
        .then((response) => {
          
        })
        .catch(() => {
          //setLoading(false);
        });

        order = parseInt(order) + 1;
        return true;
    });

    setFormData(newSections);
  }

  const save = (value,custom_field_uuid,attribute) => {
        
    let data = {};
    data['custom_field_uuid'] = custom_field_uuid;
    data['createdby'] = createdby;
    data['main_admin_uuid'] = main_uuid;
    data[attribute] = value;
      
    dispatch(updateCustomFieldName(data,token))
    .then((res) => {
      if (res.status === 200) {
        //notifyInfo(res.message);
        handleCustomFieldEvent(Math.random);
      } else if (res.status === 500) {
        //notifyError("Something went wrong.");
      } else {
        //notifyError("Something went wrong.");
      }
    })
    .catch(() => {
      
    });

};

  const handleAddField = (tab,section_type) => {
    let tab_data = {
      tab_uuid: tab.contact_form_tab_uuid,
      section_uuid: tab.contact_form_section_uuid,
      sec_type: section_type,
    };
    setSelectedTab(tab_data);
    setAddCustomFieldModal(true);
  }

  const closeBulkUpdateModel = (value) => {
    setAddBulkUpdateFieldModal(value);
  };

  const handleBulkUpdateFieldEvent = (event) => {
    setBulkUpdateFieldEvent(event);
  };

  const FndeleteBulkUpdateField = (e,contact_bulk_update_field_uuid) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this field?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteBulkUpdateFieldService(contact_bulk_update_field_uuid)
        },
        {
          label: "No"
        }
      ]
    });
  }

  const deleteBulkUpdateFieldService = (contact_bulk_update_field_uuid) => {
    setLoading(true);
    dispatch(deleteBulkUpdateField(contact_bulk_update_field_uuid,createdby,token))
        .then((response) => {                
          setBulkUpdateFieldEvent(Math.random);
          setLoading(false);
          return;
        })
        .catch(() => {
            setLoading(false);
        });     
  }

  const breadCurmList = [
    { label: "Home", link: "/", is_active: "NO" },
    { label: "Contacts", link: "/contacts", is_active: "NO" },
    { label: "Custom Contact Form", link: "", is_active: "YES" },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
      
      <PageTitle breadCurmList={breadCurmList} />

      {isPermission ? (
        <>
        <div className="row">
            <div className="col-lg-3 col-md-3 col-3">
              <div className="h4 mt-5  text-primary">
                Custom Contact Field 
                <Tooltip
                  placement="top"
                  overlay={<span>Add New Field</span>}
                >
                  <button onClick={() => setAddCustomFieldModal(true)} className="btn btn-sm btn-primary pull-right">
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </button>

                  

                </Tooltip>

                {/* <span className=" pull-right">
                  <i className="fa fa-plus-square" aria-hidden="true"></i>
                </span>
                 */}
                
              </div>
              <div className="basic-list-group mt-5 custom_form">
                  <ul className="list-group">
                      {customFields.map((val) => (
                          <li className="list-group-item cursor-drag" key={val.custom_field_uuid} draggable onDragStart={(e) => handleDragStart(e, val.custom_field_uuid)}>
                              {/* {val.field_name}  */}
                              <EasyEdit
                                    type={Types.TEXT}
                                    onSave={value => {
                                        save(value,val.custom_field_uuid,'field_name')
                                    }}
                                    //onCancel={cancel}
                                    saveButtonLabel={<i className="fa fa-check" aria-hidden="true"></i>}
                                    saveButtonStyle = 'label-primary easy-edit-button rounded-lg'
                                    cancelButtonLabel={<i className="fa fa-times" aria-hidden="true"></i>}
                                    cancelButtonStyle= 'label-danger easy-edit-button rounded-lg'
                                    attributes={{ name: 'field_name', className : 'form-control h-75 pull-left'}}
                                    viewAttributes={{className: 'w-75 pull-left cursor-drag'}}
                                    onValidate={(value) => {                          
                                        return value ? true : false;
                                    }}
                                    validationMessage="Field name is required!"
                                    placeholder="None"
                                    value={val.field_name}
                                />
                              <button onClick={(e) => FndeleteCustomField(e,val.custom_field_uuid)} className="btn btn-sm btn-danger pt-1 pb-1 pl-2 pr-2 pull-right"><i className="fa fa-trash"></i></button>
                              <button onClick={(e) => handleUpdateFieldModel(true,val.custom_field_uuid) } className="btn btn-sm btn-primary pt-1 pb-1 pl-2 pr-2 pull-right mr-1"><i className="fa fa-pencil"></i></button>
                              
                          </li>
                      ))}

                  </ul>
              </div>

              <div className="h4 mt-5  text-primary">
                Bulk Update Contact Field 
                <Tooltip
                  placement="top"
                  overlay={<span>Add New Field</span>}
                >
                  <button onClick={() => setAddBulkUpdateFieldModal(true)} className="btn btn-sm btn-primary pull-right">
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </button>
                </Tooltip>
              </div>

              <div className="basic-list-group mt-4 custom_form">
                <ul className="list-group">
                    {bulkUpdateFields.map((val) => (
                        <li className="list-group-item cursor-drag" key={val.contact_bulk_update_field_uuid} >
                            { val.form_field_type === 'MAIN_FORM' && val.contact_field.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                            { val.form_field_type === 'CUSTOM' && val.field_name}       
                            <button onClick={(e) => FndeleteBulkUpdateField(e,val.contact_bulk_update_field_uuid)} className="btn btn-sm btn-danger pt-1 pb-1 pl-2 pr-2 pull-right"><i className="fa fa-trash"></i></button>                       
                        </li>
                    ))}

                </ul>
              </div>

            </div>
            <div className="col-lg-9 col-md-9 col-9">

              {formData.map((row,sectionIndex) => (
                  <span key={row.contact_form_section_uuid} 
                  >
                    {/* <span onDragStart={(e) => handleSectionDragStart(e, sectionIndex)} draggable onDrop={(e) => handleSectionDrop(sectionIndex, e)}
                    onDragOver={handleDragOver}> click to drag </span> */}
                    <Section formData={row} handleAddTab={handleAddTabModal} handleFormEvent={handleFormEvent} handleDrop={handleDrop} handleDragOver={handleDragOver} handleCustomFieldEvent={handleCustomFieldEvent} handleDragStart={handleDragStart} handleSectionDragStart={handleSectionDragStart} handleSectionDrop={handleSectionDrop} sectionIndex={sectionIndex} handleAddField={handleAddField} />

                    <div className="row" >
                      <div className="col-md-12 text-center">
                        <Button onClick={() => addNewSection(row.section_order)} variant="btn btn-sm text-info"><u>Add New Section</u></Button>
                      </div>
                    </div>
                  </span>
              ))}

              {/* <!-- add field Modal --> */}
              <Modal className="fade" size="lg" show={addCustomFieldModal}>
                  <CustomFieldForm closeModel={closeModel} handleCustomFieldEvent={handleCustomFieldEvent} handleFormEvent={handleFormEvent} selectedTab={selectedTab}/>
              </Modal>

               {/* <!-- update field Modal --> */}
               <Modal className="fade" size="lg" show={updateCustomFieldModal}>
                  <UpdateCustomFieldForm closeUpdateFieldModel={handleUpdateFieldModel} handleCustomFieldEvent={handleCustomFieldEvent} handleFormEvent={handleFormEvent} selectedTab={selectedTab} selectedCustomfield={selectedCustomfield} />
              </Modal>

              {/* <!-- add tab Modal --> */}
              <Modal className="fade" show={addTabModal}>
                  <TabForm closeTabModel={handleTabModal} handleFormEvent={handleFormEvent} selectedSection={selectedSection} />
              </Modal>

              {/* <!-- add section Modal --> */}
              <Modal className="fade" show={addSectionModal}>
                  <SectionForm closeSectionModel={handleSectionModal} handleFormEvent={handleFormEvent} prevSectionOrder={prevSectionOrder} />
              </Modal>

              {/* <!-- add bulk update field Modal --> */}
              <Modal className="fade" size="lg" show={addBulkUpdateFieldModal}>
                  <BulkUpdateFieldForm closeBulkUpdateModel={closeBulkUpdateModel} handleBulkUpdateFieldEvent={handleBulkUpdateFieldEvent} />
              </Modal>

            </div>
          </div> 
          {/* Over view completed */}

          {/* <div className="row">
            <div className="col-lg-3 col-md-3 col-3">
              &nbsp;
            </div>
            <div className="col-lg-9 col-md-9 col-9">

            <Tab.Container defaultActiveKey="sec_2">
              <Nav as='ul' className='nav-tabs ringtabs mt-4'>
                      
                  <Nav.Item as='li' key="1">
                    <Nav.Link eventKey="sec_2">
                        <i className={`la la-tty mr-2`} />
                        Section 2
                        <button className="ml-2 btn btn-xxs btn-outline-danger btn-rounded pull-right">-</button>
                    </Nav.Link>
                  </Nav.Item>  

                  <Nav.Item as='li' key="2" >
                    <Tooltip
                      placement="top"
                      overlay={<span>Add New Tab</span>}
                    >
                      <Button
                        type="button"
                        className="btn btn-primary btn-sm ml-2 mt-1"
                      >
                        +
                      </Button>
                    </Tooltip>
                  </Nav.Item>          
              </Nav>
          
              <Tab.Content className='ringgroup'>
                  
                  <Tab.Pane eventKey="sec_2" key="1" className="pt-4">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-6">
                          <div className="card">
                              <div className="card-body">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">Assign To</li>
                                </ul>
                                  

                              </div>
                          </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-6">
                          <div className="card">
                              <div className="card-body">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">Other Contact Number</li>
                                </ul>
                                  

                              </div>
                          </div>
                      </div>
                      
                    </div>

                    <div className="row">
                      <div className="col-md-12 text-center">
                        <Button onClick={() => addNewSection(1)} variant="btn btn-sm text-info"><u>Add New Section</u></Button>
                      </div>
                    </div>
                  </Tab.Pane>

                </Tab.Content>

            </Tab.Container>

            {/* <Tab.Container defaultActiveKey="sec_3">
              <Nav as='ul' className='nav-tabs ringtabs mt-4'>
                      
                  <Nav.Item as='li' key="1">
                    <Nav.Link eventKey="sec_3">
                        <i className={`la la-tty mr-2`} />
                        Section 3
                    </Nav.Link>
                  </Nav.Item>          
              </Nav>
          
              <Tab.Content className='ringgroup'>
                  
                  <Tab.Pane eventKey="sec_3" key="1" className="pt-4">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-6">
                          <div className="card">
                              <div className="card-body">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">Assign To</li>
                                    <li className="list-group-item">Other Contact Number</li>
                                    <li className="list-group-item">Bussiness Name</li>
                                    <li className="list-group-item">City</li>
                                </ul>
                                  

                              </div>
                          </div>
                      </div>
                      
                    </div>

                    <div className="row">
                      <div className="col-md-12 text-center">
                        <Button onClick={() => addNewSection(1)} variant="btn btn-sm text-info"><u>Add New Section</u></Button>
                      </div>
                    </div>
                  </Tab.Pane>

                </Tab.Content>

            </Tab.Container> */}

           {/* </div>
          </div> */}
          
        </>
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Custom Contact Form</h4>
              </div>
              <div className="card-body">
                <h3>You have don't access this modules</h3>
              </div>
            </div>
          </div>
        </div>
      )}
      
    </Fragment>
  );
}

export default CustomForm;
