import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL; 

const getCustomFieldList = (
  search,
  off_set,
  limits,
  orderby,
  main_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "get-custom-field-list",
      {
        search,
        off_set,
        limits,
        orderby,
        createdby,
        main_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const createCustomField = (data,token) => {
  return axios
    .post(API_URL + "create-custom-field", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const updateCustomField = (data,token) => {
  return axios
    .post(API_URL + "update-custom-field", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getCutomFieldDetail = (createdby,token,custom_field_uuid) => {
  
  return axios
    .post(API_URL + "get-custom-field-details", {
        createdby,
        custom_field_uuid
      },{
          headers: {
          'Authorization': token
          }
      })
    .then((response) => {
      
      return response.data;
  });
};


const deleteCustomField = (custom_field_uuid,createdby,token) => {
  
  return axios
    .post(API_URL + "delete-custom-field", {
      custom_field_uuid,
          createdby
      },{
          headers: {
          'Authorization': token
          }
      })
    .then((response) => {     
      return response.data;
  });
};

const getDependentFields = (createdby,token,custom_field_uuid,custom_field_value) => {
  
  return axios
    .post(API_URL + "get-dependent-field", {
        createdby,
        custom_field_uuid,
        custom_field_value
      },{
          headers: {
          'Authorization': token
          }
      })
    .then((response) => {
      
      return response.data;
  });
};


export default {
  getCustomFieldList,
  createCustomField,
  updateCustomField,
  getCutomFieldDetail,
  deleteCustomField,
  getDependentFields,
};
