
import React,{Fragment,useState} from "react";
import { Modal } from "react-bootstrap";
import { MoveEmail } from "../../../actions/email/emails";
import { useDispatch } from "react-redux";
import Loader from "../../../layouts/Loader";
import { notifyInfo, notifyError } from "../../Toastr/Toastr";

function MoveToFolder(props) {

    const user = JSON.parse(localStorage.getItem("user"));
    const user_data = user.data.data;
    const token = user.data.token;
    const createdby = user_data.user_uuid;
    const main_uuid = user_data.main_uuid;

    const folders = props.folders;
    const activeFolderPath = props.activeFolderPath;
    const uid = props.uid;

    const [loading, setLoading] = useState(false);
    const [folder, setFolder] = useState('');
    const [error, seterror] = useState('');

    const dispatch = useDispatch();

    const closeModel = (value) => {
        props.handleMoveToFolderModel(value);
    }

    const handleBlur = (e) => {

        if (e.target.value.length === 0) {            
            seterror('Folder is required.');
        } else {
            seterror('');
        }

        setFolder(e.target.value);
    }

    const submitHandler = (e) =>{
        e.preventDefault();

        setLoading(true);

        if (folder.length === 0) {            
            seterror('Folder is required.');
            setLoading(false);
            return;
        } else {
            seterror('');

            dispatch(MoveEmail(main_uuid, createdby,activeFolderPath,folder,uid, token))
            .then((response) => {

                if (response.status === 200) {
                    props.handleMoveFolder();
                    closeModel(false);
                    notifyInfo(response.message);
                } else {
                    notifyError(response.message);
                }
                
                setLoading(false);
                return;
            })
            .catch(() => {
                setLoading(false);
            });
        }
    }

    return (
        <Fragment>
            {loading && <Loader />}
            <Modal.Body>
                <form action="" onSubmit={(e) => submitHandler(e)}>
                    <div className="row mt-4">
                    <div className="col-12">
                        <div className="form-group input-primary">
                        
                        <select
                            className="form-control bg-white"
                            name="field_type"
                            onChange={handleBlur}
                        >
                            <option value="">Select Folder</option>
                            {folders.map((folder, index) => (   
                                <option key={index} value={folder.path}>{folder.path}</option>
                            ))}                        

                        </select>

                        <span className="text-danger">{error}</span>
                        </div>
                    </div>
                    
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <button type="submit" className="btn btn-sm btn-primary">Move</button>

                            <button onClick={() => closeModel(false)} className="btn btn-sm btn-danger light ml-2"> Close</button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Fragment>
    );
}

export default MoveToFolder;