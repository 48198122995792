import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  Button,
  ButtonGroup,
  DropdownButton,
  Dropdown,
  Accordion,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import PageTitle from "../../layouts/PageTitle";
import Pagination from "../../layouts/Pagination";
import { getCdrs } from "../../actions/cdr";
import FlashMessage from "../../layouts/FlashMessage";
import CheckModulePermisson from "../../common/CheckModulePermisson";
import PermissionCheck from "../../common/PermissionCheck";
import filter_img from "./../../../images/profile/filter.png";
import FilterForm from "./FilterForm";
import Loader from "../../layouts/Loader";

function Cdr() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;
  const module_name = "call-detail-report";

  let module_per = CheckModulePermisson(module_name);

  let isPermission = true;
  if (module_per === "" || user_type === "admin") {
    isPermission = true;
  } else {
    isPermission = false;
  }

  let listing_per = PermissionCheck(module_name, "listing", "", "", "");

  const dispatch = useDispatch();
  let navigate = useNavigate();

  if (!isPermission) {
    navigate("/error-403");
  }

  if (listing_per === "none") {
    navigate("/error-403");
  }

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }

  let search = "";
  //let limits = 5;
  let orderby = "start_stamp DESC";

  const [limits, setLimits] = useState(10);
  const [cdrs, setCdrs] = useState([]);
  const [cdrEvent, setCdrEvent] = useState("");
  const [pageCount, setpageCount] = useState(0);
  const [offSet, setOffSet] = useState(0);
  const [searchData, setSearchData] = useState({
    caller_id_name: "",
    caller_id_number: "",
    destination_number: "",
    direction: "",
  });

  const [loading, setLoading] = useState(false);
  const [activeDefault, setActiveDefault] = useState(0);

  if (module_per === "" || user_type === "admin") {
    useEffect(() => {
      setLoading(false);
      dispatch(
        getCdrs(
          user_type,
          group_per,
          group_uuid,
          search,
          searchData,
          offSet,
          limits,
          orderby,
          main_uuid,
          createdby,
          token
        )
      )
        .then((response) => {
          console.log(response.data);
          setCdrs(response.data.data);
          const total = response.data.total;

          setpageCount(Math.ceil(total / limits));
          setCdrEvent("");
          setSearchData(searchData); //remove this line, when actual cdr is inserted
          setLoading(false);
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    }, [cdrEvent, limits]);
  }

  const handlePageClick = async (data) => {
    setOffSet(limits * data.selected);
    setCdrEvent(Math.random);
  };


  const chnageLimit = (value) => {
    setLimits(value);
  };

  const breadCurmList = [
    { label: "Calls", link: "/", is_active: "NO" },
    { label: "CDRs : Total Amount " + cdrs.length, link: "", is_active: "YES" },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
      <FlashMessage />
      <PageTitle breadCurmList={breadCurmList} />

      {isPermission ? (
        <>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <Accordion
                  className="accordion cursor-pointer"
                  defaultActiveKey="0"
                >
                  <div className="accordion__item p-0" key="1">
                    <div className="card-header">
                      <h4 className="card-title">
                        Manage CDRs
                        <Accordion.Toggle
                          as="span"
                          eventKey="1"
                          className={`ml-3 ${
                            activeDefault === 1 ? "" : "collapsed"
                          }`}
                          onClick={() =>
                            setActiveDefault(activeDefault === 1 ? -1 : 1)
                          }
                        >
                          <span className="accordion__header--text">
                            <img
                              src={filter_img}
                              className="img-fluid rounded-circle"
                              alt="Search"
                            />
                          </span>
                        </Accordion.Toggle>
                      </h4>

                      <Button
                        type="button"
                        className="btn btn-primary btn-sm"
                        // onClick={() =>
                        //   navigate("/add-ringgroup", { replace: true })
                        // }
                      >
                        Export
                      </Button>
                    </div>
                    <div className="card-body">
                      Show
                      <ButtonGroup className="ml-2 mr-2">
                        <DropdownButton
                          as={ButtonGroup}
                          id="bg-nested-dropdown"
                          size="sm"
                          title={limits}
                          variant="custom-dropdown"
                        >
                          <Dropdown.Item
                            eventKey="1"
                            href="#"
                            onClick={() => chnageLimit(10)}
                          >
                            10
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="1"
                            href="#"
                            onClick={() => chnageLimit(25)}
                          >
                            25
                          </Dropdown.Item>

                          <Dropdown.Item
                            eventKey="1"
                            href="#"
                            onClick={() => chnageLimit(50)}
                          >
                            50
                          </Dropdown.Item>

                          <Dropdown.Item
                            eventKey="1"
                            href="#"
                            onClick={() => chnageLimit(100)}
                          >
                            100
                          </Dropdown.Item>
                        </DropdownButton>
                      </ButtonGroup>
                      entries
                      <Accordion.Collapse eventKey="1">
                        <div className="accordion__body--text">
                          <FilterForm />
                        </div>
                      </Accordion.Collapse>
                      <Table responsive className="w-100">
                        <thead>
                          <tr role="row">
                            <th>Origination</th>
                            <th>Destination</th>
                            <th>Date/Time</th>
                            <th>Duration</th>
                            <th>Status</th>
                            <th>Desposition</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cdrs.map((cdr) => (
                            <tr key={cdr.xml_cdr_uuid}></tr>
                          ))}
                        </tbody>
                      </Table>
                      <Pagination
                        pageCount={pageCount}
                        handlePageNumberClick={handlePageClick}
                      />
                    </div>
                  </div>
                </Accordion>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Manage Cdrs</h4>
              </div>
              <div className="card-body">
                <h3>You have don't access this modules</h3>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Cdr;
