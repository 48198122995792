import React, { Fragment, useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { getDayTimeslots } from "../../../actions/calender/UserMeetingAvailability";
import {getMeetingModes } from "../../../actions/calender/UserMeetingEvent";
import {getMeetingEvent}  from "../../../actions/calender/UserMeetingEvent";
import {scheduleMeeting}  from "../../../actions/calender/CalendarMeeting";
import {createRoom} from "../../../actions/calender/Daily";
import { getTimezone } from "../../../actions/comman";
import Loader from "../../../layouts/Loader";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from "moment";
import { notifyError,notifyInfo } from "../../Toastr/Toastr";
import logo_img from "../../../../images/Zongo.jpg";

function ScheduleMeeting() {

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const PROFILE_URL = process.env.REACT_APP_SERVER_PROFILE_URL;

  const user_meeting_event_uuid = searchParams.get('t');
 
  const [loading, setLoading] = useState(false);
  const [isLinkDeleted, setIsLinkDeleted] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);
  const [meetingEventData, setMeetingEventData] = useState([]);
  const [meetingModes, setMeetingModes] = useState([]);

  const [isTimeSelected, setIsTimeSelected] = useState(false);
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [userTimeZone, setUserTimeZone] = useState();

  const [meetingData, setMeetingData] = useState({
    date : new Date(),
    time_slot_uuid : '',
    meeting_title : '',
    meeting_duration : '',
    start_time : '',
    end_time : '',
    first_name : '',
    last_name : '',
    company : '',
    phone_number : '',
    email : '',
    meeting_type : '',
    phone_call_type : '',
    call_number : '',
    online_meeting_type : '',
    meeting_url : '',
    google_address : '',
  });

  const [errors, setErrors] = useState({
    email : '',
    call_number : '',
    first_name : '',
    last_name : '',
    time_zone : '',
  });

  useEffect(() => {
 
    setLoading(true);
    dispatch(getMeetingEvent(user_meeting_event_uuid)).then((response) => {
      if (response.status === 200) {
        setMeetingEventData(response.data);
      }
      if (response.status === 404) {
        setIsLinkDeleted(true);
      }      
      setLoading(false);
      return;
    })
    .catch(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if(meetingEventData){
      dispatch(getTimezone())
      .then((response) => {
        let arr = response.data.data.map((obj, i) => {
          return { label: obj.timezone_name, value: obj.timezone_value };
        });
        setTimeZoneList(arr);

        const offset = new Date().getTimezoneOffset();

        if(offset){
          const absoluteOffset = Math.abs(offset);
          const hours = String(Math.floor(absoluteOffset / 60));
          const minutes = String(absoluteOffset % 60).padStart(2, '0');
          const sign = offset > 0 ? '-' : '+';

          setUserTimeZone('GMT'+sign+hours+':'+minutes);
        }else{
          let error = {...errors};
          error['time_zone'] = 'Please select timezone.';
          setErrors(error);
        }        

        return;
      })
      .catch(() => {
        
      });
    }    
  }, [meetingEventData]);

  const parseTime = (timeStr) => {
    const [time, modifier] = timeStr.split(' ');
    let [hours, minutes] = time.split(':').map(Number);
  
    if (modifier === 'PM' && hours !== 12) {
      hours += 12;
    } else if (modifier === 'AM' && hours === 12) {
      hours = 0;
    }
  
    return { hours, minutes };
  };
  
  const formatTime = (hours, minutes,newDate) => {
    const h = hours % 12 === 0 ? 12 : hours % 12;
    const m = minutes.toString().padStart(2, '0');
    const modifier = hours >= 12 ? 'PM' : 'AM';

    const formatedTime = `${h}:${m} ${modifier}`;

    const convetedTime = convertTimezone(newDate,formatedTime,meetingEventData.company_info.timezone_value,userTimeZone); 
  
    return convetedTime;
  };

  const handleDateChange = (newDate) => {

    if(userTimeZone.length === 0){
      let error = {...errors};
      error['time_zone'] = 'Please select timezone.';
      setErrors(error);
    }
    setLoading(true);
    const dayNumber = moment(newDate).day();
    
    const day = moment().day(dayNumber).format('ddd').toLowerCase();

    newDate = moment(newDate).format('YYYY-MM-DD');
    let new_data = {...meetingData}
    new_data['date'] = newDate;

    setMeetingData(new_data);

    dispatch(
      getDayTimeslots(
        meetingEventData.user_uuid,
        day
      )
    )
      .then((response) => {

        let allSlots = [];

        response.data.forEach((slot) => {
          const { start_time, end_time, user_meeting_availability_uuid } = slot;
          const meeting_interval = meetingEventData.meeting_duration;

          const start = parseTime(start_time);
          const end = parseTime(end_time);
        
          let current = new Date();
          current.setHours(start.hours, start.minutes, 0, 0);
        
          const endAt = new Date();
          endAt.setHours(end.hours, end.minutes, 0, 0);
        
          while (current < endAt) {
            const next = new Date(current.getTime() + meeting_interval * 60000);
            if (next <= endAt) {
              let timeSlot = {
                user_meeting_availability_uuid : user_meeting_availability_uuid,
                start_time : formatTime(current.getHours(), current.getMinutes(),newDate),
                end_time : formatTime(next.getHours(), next.getMinutes(),newDate),
              }
              allSlots.push(timeSlot);
            }
            current = next;
          }
  
        });

        setTimeSlots(allSlots);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });

  };

  const selectTime = (e,slot) => {
    e.preventDefault();

    setLoading(true);
    dispatch(getMeetingModes(user_meeting_event_uuid)).then((response) => {
      if (response.status === 200) {
        setMeetingModes(response.data);
      }  
      setLoading(false);
      return;
    })
    .catch(() => {
      setLoading(false);
    });

    let new_data = {...meetingData}
    new_data['time_slot_uuid'] = slot.user_meeting_availability_uuid;
    new_data['start_time'] = slot.start_time;
    new_data['end_time'] = slot.end_time;
    new_data['meeting_duration'] = meetingEventData.meeting_duration;
    new_data['meeting_title'] = meetingEventData.event_title;
    new_data['user_uuid'] = meetingEventData.user_uuid;
    new_data['main_admin_uuid'] = meetingEventData.main_admin_uuid;

    setMeetingData(new_data);
    setIsTimeSelected(true);
  }

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateNumber = (number) => {
    const re = /^[0-9]{10,12}$/;
    return re.test(String(number));
  };

  const submitHandler = (e) =>{
    e.preventDefault();

    setLoading(true);

    let error_data = { ...errors };
    let isError = "false";

    if (meetingData.first_name.length === 0) {
      error_data["first_name"] = "Field is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (meetingData.last_name.length === 0) {
      error_data["last_name"] = "Field is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (meetingData.email.length === 0) {
      error_data["email"] = "Field is required.";
      setErrors(error_data);
      isError = "true";
    }

    if(meetingData.meeting_type === 'phone_call'){

      if(meetingData.phone_call_type === 'call_to_prospect'){
        if(!meetingData.call_number){
          error_data['phone_number'] = 'Field is required.';
          setErrors(error_data);
          isError = "true";
        }

        if(meetingData.call_number && meetingData.call_number.length === 0){
          error_data['phone_number'] = 'Field is required.';
          setErrors(error_data);
          isError = "true";
        }else if (!validateNumber(meetingData.call_number)) {
          error_data['phone_number'] = 'Enter valid phone number.';
          setErrors(error_data);
          isError = "true";
        }
        
      }
    }

    if (!validateEmail(meetingData.email)) {
      error_data['email'] = 'Enter valid email id.';
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      let data = {...meetingData}
      data['user_timezone'] = userTimeZone;
      data['user_start_time'] = data.start_time;
      data['user_end_time'] = data.end_time;

      const convetedStartTime = convertTimezone(data.date,data.start_time,userTimeZone,meetingEventData.company_info.timezone_value); 
      const convetedEndTime = convertTimezone(data.date,data.end_time,userTimeZone,meetingEventData.company_info.timezone_value); 

      data['start_time'] = convetedStartTime;
      data['end_time'] = convetedEndTime;

      if(meetingData.meeting_type === 'online_meeting' && meetingData.online_meeting_type === 'internal'){
        let room_data = {};
        room_data['name'] = createRandomString(8);
        room_data['privacy'] = 'private';

        data['room_name'] = room_data.name;

        dispatch(createRoom(room_data)).then((response) => {
        
          if(response.data.id){

            data['meeting_url'] = response.data.url;
            SaveMeetingDetails(data);
          }else{
            notifyError('Someting went wrong please try again.');
          }
          return;
        })
        .catch(() => {
          setLoading(false);
        });

      }else{
        SaveMeetingDetails(data);
      }

      
    }
  }
  
  const handleBlur = (e) => {

    let error_data = { ...errors };

    if(e.target.name !== 'phone_number' && e.target.name !== 'company' && e.target.value.length === 0){
      error_data[e.target.name] = 'Field is required.';
      setErrors(error_data);
    }else{
      error_data[e.target.name] = '';
      setErrors(error_data);
    }

    if (e.target.name === 'email' && !validateEmail(e.target.value)) {
      error_data['email'] = 'Enter valid email id.';
      setErrors(error_data);
    }

    let new_data = {...meetingData}
    new_data[e.target.name] = e.target.value;

    if(e.target.name === 'meeting_type'){
      let meeting_data = meetingModes.filter(row => row.meeting_type === e.target.value);

      new_data['phone_call_type'] = meeting_data[0].phone_call_type;
      new_data['call_number'] = meeting_data[0].call_number;
      new_data['online_meeting_type'] = meeting_data[0].online_meeting_type;
      new_data['meeting_url'] = meeting_data[0].meeting_url;
      new_data['google_address'] = meeting_data[0].google_address;
    }

    if (e.target.name === 'phone_number') {
      if(meetingData.meeting_type === 'phone_call' && meetingData.phone_call_type === 'call_to_prospect'){
        if(e.target.value.length === 0){
          error_data[e.target.name] = 'Field is required.';
          setErrors(error_data);
        }else{

          if (!validateNumber(e.target.value)) {
            error_data[e.target.name] = 'Enter valid phone number.';
            setErrors(error_data);
          }else{
            error_data[e.target.name] = '';
            setErrors(error_data);
          }
          
        }

        new_data['call_number'] = e.target.value;
      }      
    }

    setMeetingData(new_data);

  }

  const SaveMeetingDetails = (data) => {
    dispatch(scheduleMeeting(data)).then((response) => {
      if (response.status === 200) {
        notifyInfo('Event Created!');
      }else{
        notifyError('Event not created, Please try again after sometime.');
      }  
      setLoading(false);
      return;
    })
    .catch(() => {
      setLoading(false);
    });

  }

  const backToCalender = (e) => {
    e.preventDefault();
    setIsTimeSelected(false);
  }

  const convertTimezone = (date,time,fromTimezone,toTimeZone) => {

    let dateTime = date+' '+time;

    fromTimezone = fromTimezone.replace('GMT', '').trim();
    const [from_sign, from_hour, from_minute] = fromTimezone.match(/([+-])(\d+):(\d+)/).slice(1, 4);
    fromTimezone = `${from_sign}${from_hour.padStart(2, '0')}:${from_minute}`;

    const utc = moment(dateTime, 'YYYY-MM-DD h:mm A').utcOffset(fromTimezone).utc().format('YYYY-MM-DD h:mm A');

    toTimeZone = toTimeZone.replace('GMT', '').trim();
    const [to_sign, to_hour, to_minute] = fromTimezone.match(/([+-])(\d+):(\d+)/).slice(1, 4);
    toTimeZone = `${to_sign}${to_hour.padStart(2, '0')}:${to_minute}`;

    const convetedTime = moment.utc(utc, 'YYYY-MM-DD h:mm A').utcOffset(toTimeZone).format('h:mm A');

    return convetedTime;

  }

  const changeTimezone = (e) => {
    if(e.target.value.length > 0){     

      let allSlots = [];

      timeSlots.forEach((slot) => {

        let from_dateTime = convertTimezone(meetingData.date,slot.start_time,userTimeZone,e.target.value); 
        let to_dateTime = convertTimezone(meetingData.date,slot.end_time,userTimeZone,e.target.value); 

        let timeSlot = {
          user_meeting_availability_uuid : slot.user_meeting_availability_uuid,
          start_time : from_dateTime,
          end_time : to_dateTime,
        }
        allSlots.push(timeSlot);

      })

      setTimeSlots(allSlots);
      setUserTimeZone(e.target.value);
    }
  }

  function createRandomString(length) {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  }
  

  return (
    <Fragment>
      {loading && <Loader />}
      <div className="authincation h-100 p-meddle">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-9">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    {!isLinkDeleted &&
                    <div className="auth-form">
                      {meetingEventData.company_info && 
                        <img
                          src={PROFILE_URL+meetingEventData.company_info.company_logo}
                          className="mx-auto d-block mb-3"
                          alt="Company Logo"
                          height="60"
                        />
                      }

                      {!meetingEventData.company_info && 
                        <img
                          src={logo_img}
                          className="mx-auto d-block mb-3"
                          alt="Company Logo"
                          height="60"
                        />
                      }
                      <h4 className="text-center mb-4">Schedule A Meeting</h4>
                      
                      <h3 className="text-center"> {meetingEventData.event_title} </h3>
                      <h5 className="text-center mb-4"> Duration {meetingEventData.meeting_duration} Min. </h5>
                      {!isTimeSelected && 
                        <div className="row">
                          <div className="col-md-6">
                            <Calendar
                              onChange={handleDateChange}
                              value={meetingData.date}
                              minDate={new Date()}
                            />

                            <div className="form-group input-primary mt-3">
                              <label className="mb-1">
                                <strong>Phone Number</strong>
                              </label>

                              <select className="form-control bg-white h-75" name="time_zone" value={userTimeZone} onChange={changeTimezone}>
                                <option>Select TimeZone</option>
                                {timeZoneList.map((timeZone,i) => (
                                  <option key={i} value={timeZone.value}>
                                    {timeZone.label}
                                  </option>
                                ))}
                              </select>
                            </div>

                          </div>

                          <div className="col-md-6">
                            { timeSlots && 
                                timeSlots.map((slot, i) => ( 
                                  <button type="button" className="btn btn-outline-light btn-sm mr-1 mb-1" key={i} onClick={(e) => selectTime(e,slot)}>{slot.start_time} To {slot.end_time}</button>
                                ))
                            }
                          </div>                        
                        </div>
                      }
                      
                      {isTimeSelected && 
                        <Fragment>

                        <div className="row">
                          <div className="col-md-6">
                            <p><b>Meeting Date : </b> {moment(meetingData.date).format('M-d-Y')}</p>
                            
                            <p><b>Meeting Mode : </b> {meetingData.meeting_type && meetingData.meeting_type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} </p>
                          </div>
                          
                          <div className="col-md-6">
                            <p><b>Meeting time : </b> {meetingData.start_time} To {meetingData.end_time}</p>   

                            {meetingData.meeting_type === 'phone_call' && 
                              <p><b>Phone Call Type : </b> {meetingData.phone_call_type && meetingData.phone_call_type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} </p>
                            }

                            {meetingData.meeting_type === 'phone_call' &&  meetingData.phone_call_type === 'call_to_calender_owner' &&
                              <p><b>Phone Number : </b> {meetingData.call_number} </p>
                            }

                            {meetingData.meeting_type === 'online_meeting' && meetingData.online_meeting_type === 'internal' &&
                              <p><b>Meeting URL : </b> Url will be sent on schedule  </p>
                            }

                            {meetingData.meeting_type === 'online_meeting' && meetingData.online_meeting_type === 'external' &&
                              <p><b>Meeting URL : </b> {meetingData.meeting_url} </p>
                            }

                            {meetingData.meeting_type === 'in_person' && 
                              <p><b>Address : </b> {meetingData.google_address} </p>
                            }            
                          </div>
                        </div>

                        <form action="" onSubmit={(e) => submitHandler(e)}>
                          <div className="row">
                            <div className="col-md-6">

                              <div className="form-group input-primary mb-3">
                                <label className="mb-1">
                                  <strong>Meeting Mode</strong>
                                  <span className="text-danger">*</span>
                                </label>

                                <select className="form-control bg-white" name="meeting_type" value={meetingData.meeting_type} onChange={handleBlur}>
                                  <option value="">Select</option>
                                    {meetingModes &&
                                        meetingModes.map((mode) => (
                                            <option value={mode.meeting_type} key={mode.user_meeting_mode_uuid}> {mode.meeting_type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} </option>                               
                                        )
                                    )}
                                </select>
                                <span className="text-danger">{errors.meeting_type}</span>
                              </div>                              

                              {/* {meetingData.meeting_type === 'phone_call' && 
                                meetingData.phone_call_type === 'call_to_prospect' && 
                                <div className="form-group input-primary mb-3">
                                  <label className="mb-1">
                                    <strong>Meeting Phone Number</strong>
                                    <span className="text-danger">*</span>
                                  </label>

                                  <input
                                    type="text"
                                    className="form-control bg-white"
                                    name="call_number"
                                    defaultValue={meetingData.call_number}
                                    onChange={handleBlur}
                                  />
                                  <span className="text-danger">{errors.call_number}</span>
                                </div>
                              }  */}
                              
                              <div className="form-group input-primary mb-3">
                                <label className="mb-1">
                                  <strong>First Name</strong>
                                  <span className="text-danger">*</span>
                                </label>

                                <input
                                  type="text"
                                  className="form-control bg-white"
                                  name="first_name"
                                  defaultValue={meetingData.first_name}
                                  onChange={handleBlur}
                                />
                                <span className="text-danger">{errors.first_name}</span>
                              </div>

                              <div className="form-group input-primary mb-3">
                                <label className="mb-1">
                                  <strong>Email</strong>
                                  <span className="text-danger">*</span>
                                </label>

                                <input
                                  type="text"
                                  className="form-control bg-white"
                                  name="email"
                                  defaultValue={meetingData.email}
                                  onChange={handleBlur}
                                />
                                <span className="text-danger">{errors.email}</span>
                              </div>


                              <p className="fixed-bottom position-absolute text-center"><a href="#!" className="text-info" onClick={backToCalender}>Select another time slot</a> </p>
                            </div>

                            <div className="col-md-6">

                              <div className="form-group input-primary mb-3">
                                <label className="mb-1">
                                  <strong>Company Name</strong>
                                </label>

                                <input
                                  type="text"
                                  className="form-control bg-white"
                                  name="company"
                                  defaultValue={meetingData.company}
                                  onChange={handleBlur}
                                />
                                <span className="text-danger">{errors.company}</span>
                              </div>                              

                              <div className="form-group input-primary mb-3">
                                <label className="mb-1">
                                  <strong>Last Name</strong>
                                  <span className="text-danger">*</span>
                                </label>

                                <input
                                  type="text"
                                  className="form-control bg-white"
                                  name="last_name"
                                  defaultValue={meetingData.last_name}
                                  onChange={handleBlur}
                                />
                                <span className="text-danger">{errors.last_name}</span>
                              </div>

                              <div className="form-group input-primary mb-3">
                                <label className="mb-1">
                                  <strong>Phone Number</strong>
                                  {meetingData.meeting_type === 'phone_call' && 
                                    meetingData.phone_call_type === 'call_to_prospect' && 
                                    <span className="text-danger">*</span>
                                  }
                                </label>

                                <input
                                  type="text"
                                  className="form-control bg-white"
                                  name="phone_number"
                                  defaultValue={meetingData.phone_number}
                                  onChange={handleBlur}
                                />
                                <span className="text-danger">{errors.phone_number}</span>
                              </div>

                              

                              <Button type="submit" variant="primary btn-sm mr-2">
                                Submit
                              </Button>
                            
                            </div>
                          </div>
                        </form>

                        </Fragment>
                      }
                      
                      
                    </div>
                     }

                     {isLinkDeleted && 
                        <p className="text-red text-center mt-3">URL Expired!</p>
                     }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
          
    </Fragment>
  );
}

export default ScheduleMeeting;
