import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL; 

const getContactFormLayout = (
  main_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "get-contact-form-layout",
      {
        createdby,
        main_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getCustomContactFields = (
  main_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "get-custom-contact-field",
      {
        main_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const createTab = (data,token) => {
  return axios
    .post(API_URL + "create-tab", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const createSection = (data,token) => {
  return axios
    .post(API_URL + "create-section", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const deleteTab = (data,token) => {
  return axios
    .post(API_URL + "delete-tab", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const setCustomFieldPosition = (data,token) => {
  return axios
    .post(API_URL + "set-custom-field-position", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const updateCustomFieldName = (data,token) => {
  return axios
    .post(API_URL + "update-custom-field-name", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const updateContactFormSection = (data,token) => {
  return axios
    .post(API_URL + "update-contact-form-section", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const getCustomContactFormFields = (createdby,token) => {
  return axios
    .post(API_URL + "get-custom-contact-form-fields", {createdby}, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const createBulkUpdateField = (data,token) => {
  return axios
    .post(API_URL + "create-bulk-update-field", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const getBulkUpdateFields = (main_uuid,createdby,token) => {
  return axios
    .post(API_URL + "get-bulk-update-fields", {main_uuid,createdby}, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const deleteBulkUpdateField = (contact_bulk_update_field_uuid,createdby,token) => {
  return axios
    .post(API_URL + "delete-bulk-update-field", {contact_bulk_update_field_uuid,createdby}, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      return response.data;
    });
};

export default {
  getContactFormLayout,
  createTab,
  createSection,
  deleteTab,
  setCustomFieldPosition,
  getCustomContactFields,
  updateCustomFieldName,
  updateContactFormSection,
  getCustomContactFormFields,
  createBulkUpdateField,
  getBulkUpdateFields,
  deleteBulkUpdateField
};
