import React, { Fragment, useState, useEffect } from "react";
import { Table, Modal, Button } from "react-bootstrap";
import PageTitle from "../../layouts/PageTitle";
import { Link } from "react-router-dom";
import RoleForm from "./RoleForm";
import UpdateRoleForm from "./UpdateRoleForm";
import { useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { getRoles, deleteRoles } from "../../actions/role";
import CheckModulePermisson from "../../common/CheckModulePermisson";
import PermissionCheck from "../../common/PermissionCheck";
import { notifyInfo } from "../Toastr/Toastr";
import Loader from "../../layouts/Loader";

function Role() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;
  const module_name = "roles permissions";

  let module_per = CheckModulePermisson(module_name);

  let isPermission = true;
  if (module_per === "" || user_type === "admin") {
    isPermission = true;
  } else {
    isPermission = false;
  }

  let listing_per = PermissionCheck(module_name, "listing", "", "", "");
  let add_per = PermissionCheck(module_name, "add", "", "", "");

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }

  const [addRoleModal, setAddRoleModal] = useState(false);
  const [updateRoleModal, setUpdateRoleModal] = useState(false);
  const [RoleEvent, setRoleEvent] = useState("");
  const [roleId, setRoleId] = useState();
  const [roleTotal, setRoleTotal] = useState();

  const handleRoleEvent = (event) => {
    setRoleEvent(event);
  };

  const closeModel = (value) => {
    setAddRoleModal(value);
    setUpdateRoleModal(value);
  };

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  let search = "";
  let off_set = 0;
  let status = "ACTIVE";
  let limits = 10;
  let orderby = "role_name ASC";
  let role_uuid = "";

  const [roles, setRoles] = useState([]);
  if (module_per === "" || user_type === "admin") {
    useEffect(() => {
      dispatch(
        getRoles(
          main_uuid,
          user_type,
          group_per,
          group_uuid,
          search,
          off_set,
          status,
          limits,
          orderby,
          role_uuid,
          createdby,
          token
        )
      )
        .then((response) => {
          setRoles(response.data.data);
          setRoleTotal(response.data.total);
          setRoleEvent("");
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    }, [RoleEvent]);

 
  }

  const deleteRole = (uuid) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this role?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRoleService(uuid),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteRoleService = (uuid) => {
    setLoading(true);
    dispatch(deleteRoles(uuid, createdby, token))
      .then((response) => {
        notifyInfo(response.message);
        setLoading(false);
        setRoleEvent("delete");
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateRole = (role_uuid) => {
    setUpdateRoleModal(true);
    setRoleId(role_uuid)
  }

  const breadCurmList = [
    { label: "Roles", link: "/", is_active: "NO" },
    { label: "Roles & Permissions", link: "/roles", is_active: "NO" },
    {
      label: "Total Amount : " + roleTotal,
      link: "",
      is_active: "YES",
    },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />
      {isPermission ? (
        <>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Manage Roles</h4>
                  {add_per === "yes" ? (
                    <Button
                      type="button"
                      className="btn btn-sm btn-primary"
                      onClick={() => setAddRoleModal(true)}
                    >
                      Add Role
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="card-body">
                  <Table responsive className="w-100">
                    <thead>
                      <tr role="row">
                        <th>Role Name</th>
                        {listing_per === "group" || user_type === "admin" ? (
                          <th>Created By</th>
                        ) : (
                          <></>
                        )}
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {roles.map((role) => (
                        <tr key={role.uuid}>
                          <td>
                            <Link
                              to="/permissions"
                              state={{ role_uuid: role.uuid }}
                              className="text-success"
                            >
                              {role.role_name}
                            </Link>
                          </td>
                          {listing_per === "group" ||
                          listing_per === "all" ||
                          user_type === "admin" ? (
                            <td>
                              {role.first_name} {role.last_name}
                            </td>
                          ) : (
                            <></>
                          )}
                          <td>
                            <Fragment>
                              <div className="d-flex">
                                {role.role_type !== "default" ? (
                                  <Link
                                    onClick={() => updateRole(role.uuid)}
                                    state={{ role_uuid: role.uuid }}
                                    hidden={PermissionCheck(
                                      module_name,
                                      "edit",
                                      role.group_uuid,
                                      role.user_created_by,
                                      role.created_by
                                    )}
                                    className="btn btn-primary shadow btn-xs sharp mr-1"
                                  >
                                    <i className="fa fa-pencil"></i>
                                  </Link>
                                ) : (
                                  <></>
                                )}

                                <Link
                                  to="#"
                                  onClick={() => deleteRole(role.uuid)}
                                  hidden={PermissionCheck(
                                    module_name,
                                    "delete",
                                    role.group_uuid,
                                    role.user_created_by,
                                    role.created_by
                                  )}
                                  className="btn btn-danger shadow btn-xs sharp"
                                >
                                  <i className="fa fa-trash"></i>
                                </Link>
                              </div>
                            </Fragment>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- add role Modal --> */}
          <Modal className="fade" show={addRoleModal}>
            <RoleForm
              closeModel={closeModel}
              handleRoleEvent={handleRoleEvent}
            />
          </Modal>
          <Modal className="fade" show={updateRoleModal}>
            <UpdateRoleForm
              closeModel={closeModel}
              role_id={roleId}
              handleRoleEvent={handleRoleEvent}
            />
          </Modal>
        </>
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Manage Roles</h4>
              </div>
              <div className="card-body">
                <h3>You have don't access this modules</h3>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Role;
