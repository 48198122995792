import React, { Fragment, useState} from "react";
import { Modal, Button } from "react-bootstrap";

function ContactSearchForm(props) {
  const closeModel = (arg) => {
    props.closeModel(arg);
  };

  // const saveSearchForm = () => {
  //   props.saveSearchForm();
  //   closeModel(false);
  // }

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const [contactData, setContactData] = useState({
    is_search : true,
    contact_type: props.contactSearchData.contact_type,
    bussiness_name: props.contactSearchData.bussiness_name,
    contact_title: props.contactSearchData.contact_title,
    first_name: props.contactSearchData.first_name,
    last_name: props.contactSearchData.last_name,
    email: props.contactSearchData.email,
    work_contact_number: props.contactSearchData.work_contact_number,
    other_contact_number: props.contactSearchData.other_contact_number,
    country: props.contactSearchData.country,
    state: props.contactSearchData.state,
    city: props.contactSearchData.city,
    zipcode: props.contactSearchData.zipcode,
    address: props.contactSearchData.address,
    address2: props.contactSearchData.address2,
    token: token,
    createdby: createdby,
    main_uuid: main_uuid,
    user_uuid: createdby,
  });


  const handleBlur = (e) => {
    const newContactData = { ...contactData };

    
    newContactData[e.target.name] = e.target.value;
    setContactData(newContactData);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    props.rowDataPass(contactData);
    closeModel(false);
  };

  return (
    <Fragment>
      <Modal.Header>
        <Modal.Title>Contact Search</Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <form action="" onSubmit={(e) => submitHandler(e)}>
        <Modal.Body>
          <div className="row">
            <div className="col-sm">
              <div className="form-group">
                <label className="mb-1">
                  <strong>Contact Type</strong>
                </label>
                <select
                  className="form-control"
                  name="contact_type"
                  onChange={handleBlur}
                  value={contactData.contact_type}
                >
                  <option value="Individual">Individual</option>
                  <option value="Business">Business</option>
                </select>
                
              </div>
            </div>
            <div className="col-sm">
              {" "}
              <div className="form-group">
                <label className="mb-1">
                  <strong>Business Name</strong>
                </label>
                <input
                  type="text"
                  className="form-control"
                  //placeholder="Business Name"
                  name="bussiness_name"
                  onChange={handleBlur}
                  value={contactData.bussiness_name}
                />
              </div>
            </div>

            <div className="col-sm">
              <div className="form-group">
                <label className="mb-1">
                  <strong>Contact Title</strong>
                </label>
                <input
                  type="text"
                  className="form-control"
                  //placeholder="Contact Title"
                  name="contact_title"
                  onChange={handleBlur}
                  value={contactData.contact_title}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <div className="form-group">
                <label className="mb-1">
                  <strong>First Name</strong>
                </label>
                <input
                  type="text"
                  className="form-control"
                  //placeholder="First Name"
                  name="first_name"
                  onChange={handleBlur}
                  value={contactData.first_name}
                />
              </div>
            </div>

            <div className="col-sm">
              <div className="form-group">
                <label className="mb-1">
                  <strong>Last Name</strong>
                </label>
                <input
                  type="text"
                  className="form-control"
                  //placeholder="Last Name"
                  name="last_name"
                  onChange={handleBlur}
                  value={contactData.last_name}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm">
              <div className="form-group">
                <label className="mb-1">
                  <strong>Work Contact Number</strong>
                </label>
                <input
                  type="text"
                  className="form-control"
                  //placeholder="Work Contact Number"
                  name="work_contact_number"
                  onChange={handleBlur}
                  value={contactData.work_contact_number}
                />
              </div>
            </div>

            <div className="col-sm">
              <div className="form-group">
                <label className="mb-1">
                  <strong>Other Contact Number</strong>
                </label>
                <input
                  type="text"
                  className="form-control"
                  //placeholder="Other Contact Number"
                  name="other_contact_number"
                  onChange={handleBlur}
                  value={contactData.other_contact_number}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm">
              <div className="form-group">
                <label className="mb-1">
                  <strong>Email</strong>
                </label>
                <input
                  type="text"
                  className="form-control"
                  //placeholder="Email"
                  name="email"
                  onChange={handleBlur}
                  value={contactData.email}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm">
              <div className="form-group">
                <label className="mb-1">
                  <strong>Address</strong>
                </label>
                <input
                  type="text"
                  className="form-control"
                  //placeholder="Address"
                  name="address"
                  onChange={handleBlur}
                  value={contactData.address}
                />
              </div>
            </div>

            <div className="col-sm">
              <div className="form-group">
                <label className="mb-1">
                  <strong>Address2</strong>
                </label>
                <input
                  type="text"
                  className="form-control"
                  //placeholder="Address2"
                  name="address2"
                  onChange={handleBlur}
                  value={contactData.address2}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm">
              <div className="form-group">
                <label className="mb-1">
                  <strong>Country</strong>
                </label>
                <input
                  type="text"
                  className="form-control"
                  //placeholder="Country"
                  name="country"
                  onChange={handleBlur}
                  value={contactData.country}
                />
              </div>
            </div>

            <div className="col-sm">
              <div className="form-group">
                <label className="mb-1">
                  <strong>State</strong>
                </label>
                <input
                  type="text"
                  className="form-control"
                  //placeholder="State"
                  name="state"
                  onChange={handleBlur}
                  value={contactData.state}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              {" "}
              <div className="form-group">
                <label className="mb-1">
                  <strong>City</strong>
                </label>
                <input
                  type="text"
                  className="form-control"
                  //placeholder="City"
                  name="city"
                  onChange={handleBlur}
                  value={contactData.city}
                />
              </div>
            </div>

            <div className="col-sm">
              <div className="form-group">
                <label className="mb-1">
                  <strong>Zipcode</strong>
                </label>
                <input
                  type="text"
                  className="form-control"
                  //placeholder="Zipcode"
                  name="zipcode"
                  onChange={handleBlur}
                  value={contactData.zipcode}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => closeModel(false)} variant="danger light btn-sm">
            Close
          </Button>
          {/* <Button type="button" value="save_search" variant="info btn-sm" onClick={() => saveSearchForm()}>
            Save Search
          </Button> */}
          <Button type="submit" value = "search" variant="primary btn-sm">
            Search
          </Button>
        </Modal.Footer>
      </form>
    </Fragment>
  );
}

export default ContactSearchForm;
