import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import { getDestinationTypeList } from "../../actions/comman";
import { updateIvrOptions } from "../../actions/ivr";
import "./style.css";
import EasyEdit, { Types } from "react-easy-edit";
import Loader from "../../layouts/Loader";

function KeypadComponent(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;

  let ivrData = props.ivrData;
  let ivrMenuOption = props.ivrData.ivr_menu_options;

  const dispatch = useDispatch();

  const [selectedKeyData, setSelectedKeyData] = useState({
    ivr_menu_option_action_type: "",
    ivr_menu_option_action_uuid: "",
  });

  const [selectedKey, setSelectedKey] = useState(1);
  const [ivrMenuOptionKyes, setIvrMenuOptionKyes] = useState([]);
  const [loading, setLoading] = useState(false);

  const keys = [1, 2, 3, 4, 5, 6, 7, 8, 9, "#", 0, "*"];

  const [errors, setErrors] = useState({
    ivr_menu_option_action_type: "",
    ivr_menu_option_action_uuid: "",
  });

  const [options, setOptions] = useState([]);

  // let number_array = new Array(10).fill(1).map((_, i) => {
  //   return { label: i + 1, value: i + 1 };
  // });

  useEffect(() => {
    if (ivrMenuOption !== "") {
      handleKeyClick(selectedKey);

      let keys = ivrMenuOption.map((option) => {
        return parseInt(option.ivr_menu_option_digits, 10);
      });

      setIvrMenuOptionKyes(keys);
    }
  }, [ivrMenuOption]);

  const save = (value, attribute) => {
    let data = { ...selectedKeyData };
    data["token"] = token;
    data["createdby"] = createdby;
    data["main_admin_uuid"] = ivrData.main_admin_uuid;
    data["user_uuid"] = createdby;
    data["ivr_menu_option_action_type"] = value.ivr_menu_option_action_type;
    data["ivr_menu_option_action_uuid"] = value.ivr_menu_option_action_uuid;

    let error_data = { ...errors };
    let isError = "false";
    setLoading(true);

    if (
      selectedKeyData.ivr_menu_option_action_type === null ||
      selectedKeyData.ivr_menu_option_action_type.length === 0
    ) {
      error_data["ivr_menu_option_action_type"] =
        "Destination type is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (
      selectedKeyData.ivr_menu_option_action_uuid === null ||
      selectedKeyData.ivr_menu_option_action_uuid.length === 0
    ) {
      error_data["ivr_menu_option_action_uuid"] = "Destination is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      return false;
    } else {
      //console.log('test test test');
      dispatch(updateIvrOptions(data))
        .then((res) => {
          if (res.status === 200) {
            notifyInfo(res.message);
            setLoading(false);
            props.handleIvrEvent(Math.random);
          } else if (res.status === 500) {
            setLoading(false);
            notifyError("Something went wrong.");
          } else {
            setLoading(false);
            notifyError("Something went wrong.");
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const handleKeyClick = (key) => {
    setSelectedKey(key);

    let arr = [];

    ivrMenuOption.forEach((option) => {
      if (option.ivr_menu_option_digits === key) {
        arr = option;
      }
    });

    if (arr.length !== 0) {
      arr.ivr_menu_option_digits = key;
      setSelectedKeyData(arr);
    } else {
      let arr = {
        ivr_menu_option_action_type: "",
        ivr_menu_option_action_uuid: null,
        ivr_menu_option_destination: "",
        ivr_menu_option_digits: key,
        ivr_menu_option_uuid: null,
        ivr_menu_uuid: ivrData.ivr_menu_uuid,
        main_admin_uuid: ivrData.main_admin_uuid,
        user_uuid: ivrData.user_uuid,
      };
      setSelectedKeyData(arr);
    }
  };

  const CustomDisplay = (props) => {
    if (
      selectedKeyData.ivr_menu_option_destination !== "" &&
      selectedKeyData.ivr_menu_option_action_type !== ""
    ) {
      return (
        <p>
          {selectedKeyData.ivr_menu_option_action_type} -
          {selectedKeyData.ivr_menu_option_destination}
        </p>
      );
    } else {
      return <p>Click To Edit</p>;
    }
  };

  const CustomEdit = (props) => {
    //const [options, setOptions] = useState([]);

    const [savedData, setSavedData] = useState({
      ivr_menu_option_action_type: selectedKeyData.ivr_menu_option_action_type,
      ivr_menu_option_action_uuid: selectedKeyData.ivr_menu_option_action_uuid,
    });

    function inputonChange(e) {
      let new_data = { ...savedData };
      let data = { ...selectedKeyData };

      let error_data = { ...errors };
      let isError = "false";

      if (e.target.name === "ivr_menu_option_action_type") {
        if (e.target.value.length === 0) {
          error_data["ivr_menu_option_action_type"] =
            "Destination type is required.";
          isError = "true";
        } else {
          error_data["ivr_menu_option_action_type"] = "";
          isError = "false";
          new_data[e.target.name] = e.target.value;
          data[e.target.name] = e.target.value;
          getoptions(e.target.value);
        }
      }

      if (e.target.name === "ivr_menu_option_action_uuid") {
        if (e.target.value.length === 0) {
          error_data["ivr_menu_option_action_uuid"] =
            "Destination is required.";
          isError = "true";
        } else {
          error_data["ivr_menu_option_action_uuid"] = "";
          isError = "false";
          new_data[e.target.name] = e.target.value;
          data[e.target.name] = e.target.value;
        }
      }

      setErrors(error_data);
      setSavedData(new_data);
      setSelectedKeyData(data);

      if (isError === "true") {
      } else {
        props.setParentValue(new_data);
      }
    }

    const getoptions = (type) => {
      const main_uuid = user_data.main_uuid;

      dispatch(getDestinationTypeList(createdby, token, main_uuid, type))
        .then((response) => {
          setOptions(response.data);
          return;
        })
        .catch(() => {
          //setLoading(false);
        });
    };

    return (
      <div>
        <div className="row">
          <div className="col-6">
            <div className="form-group ">
              <label>Destination Type</label>
              <span className="text-danger">*</span>
              <select
                className="form-control"
                name="ivr_menu_option_action_type"
                id="ivr_menu_option_action_type"
                onChange={(e) => inputonChange(e)}
                defaultValue={selectedKeyData.ivr_menu_option_action_type}
              >
                <option value="">Select Destination Type</option>
                <option value="ivr">Auto-Attendant</option>
                <option value="extention">Extension</option>
                <option value="ring-group">Ring Groups</option>
                <option value="did">DID</option>
                <option value="voicemail">Voicemail</option>
                <option value="time_condition">Time-based Routing</option>
              </select>
              <span className="text-danger">
                {errors.ivr_menu_option_action_type}
              </span>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label>Destination</label>
              <span className="text-danger">*</span>
              <select
                className="form-control"
                name="ivr_menu_option_action_uuid"
                id="ivr_menu_option_action_uuid"
                onChange={(e) => inputonChange(e)}
                defaultValue={selectedKeyData.ivr_menu_option_action_uuid}
              >
                <option value="">Select Destination</option>

                {options &&
                  options.map((number) =>
                    number.value !== ivrData.ivr_menu_uuid ? (
                      <option key={number.value} value={number.value}>
                        {number.text}
                      </option>
                    ) : null
                  )}
              </select>
              <span className="text-danger">
                {errors.ivr_menu_option_action_uuid}
              </span>
            </div>
          </div>
        </div>

        {/* <div className="row">
              <div className="col-6">
                <div className="form-group ">
                  <label>Max Attempts</label>
                  <select
                    className="form-control"
                    name="max_attempt"
                    id="max_attempt"
                    onChange={(e) => inputonChange(e)}
                    defaultValue={selectedKeyData.max_attempt}
                  >
                    <option value="">Select</option>
                    {number_array &&
                      number_array.map((number) =>
                        (
                          <option key={number.value} value={number.value}>
                            {number.label}
                          </option>
                        ) 
                    )}
                  </select>
                </div>
              </div>
            </div> */}
      </div>
    );
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <div className="row">
        <div className="col-lg-6 col-md-6 col-6">
          <div className="card">
            <div className="card-body">
              <div
                className="col-md-12"
                style={{ textAlign: "-webkit-center" }}
              >
                <div
                  className="col-md-6"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                  }}
                >
                  {keys.map((key, index) => (
                    <button
                      type="button"
                      className={`btn my-2 btn btn-rounded mx-2 border ${
                        selectedKey === key
                          ? "btn-primary border-primary"
                          : ivrMenuOptionKyes.includes(key)
                          ? "btn-primary border-primary light"
                          : "btn-dark border-dark"
                      }`}
                      key={index}
                      onClick={() => handleKeyClick(key)}
                    >
                      {key}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-6">
          <div className="card">
            <div className="card-body text-center">
              <button
                type="button"
                className="btn my-2 btn btn-rounded mx-2 btn-primary border border-primary"
              >
                {selectedKey}
              </button>

              <EasyEdit
                type={Types.TEXT}
                onSave={(value) => {
                  save(value, "ivr_options");
                }}
                editComponent={<CustomEdit />}
                displayComponent={<CustomDisplay />}
                saveButtonLabel={
                  <i className="fa fa-check" aria-hidden="true"></i>
                }
                saveButtonStyle="label-primary easy-edit-button rounded-lg"
                cancelButtonLabel={
                  <i className="fa fa-times" aria-hidden="true"></i>
                }
                cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                attributes={{
                  name: "route_and_destination",
                  className: "form-control h-75",
                }}
                viewAttributes={{ className: "fs-20" }}
                onValidate={(value) => {
                  //console.log(value);
                  if (value) {
                    if (
                      value.ivr_menu_option_action_type &&
                      value.ivr_menu_option_action_uuid
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return false;
                  }
                  //return value ? true : false;
                }}
                // placeholder="Route and Destination"
                value={ivrData.ivr_menu_uuid}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default KeypadComponent;
