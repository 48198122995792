import React, { Fragment, useState, useEffect } from "react";
import { Tab, Nav } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../layouts/PageTitle";
import GeneralFields from "./GeneralFields";
import AdvanceFields from "./AdvanceFields";
import "./style.css";
import { getRinggroupDetail } from "../../actions/ringgroups";
import {
  getDestinationTypeList,
  getDestinationList,
} from "../../actions/comman";
import Loader from "../../layouts/Loader";

function ViewRingGroup() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_admin_uuid;

  const params = useLocation();
  const ring_group_uuid = params.state.ring_group_uuid;
  const activeTab = params.state.activeTab;

  const dispatch = useDispatch();

  const [ringGroupEvent, setRingGroupEvent] = useState("");
  const [destinationOptions, setDestinationOptions] = useState([]);

  const [loading, setLoading] = useState(false);
  const [extensions, setExtensions] = useState([]);

  const [ringgroupData, setRinggroupData] = useState({
    ring_group_name: "",
    // ring_group_extension: "",
    ring_group_greeting: "",
    ring_group_strategy: "",
    ring_group_timeout_action: "",
    ring_group_music_on_hold: "",
    ring_group_enabled: "",
    ring_group_description: "",
    ring_group_destinations: "",
    ring_group_fail_destination_str: "",
    createdby: createdby,
    main_admin_uuid: createdby,
    user_uuid: createdby,
  });

  useEffect(() => {
    setLoading(true);
    dispatch(getRinggroupDetail(createdby, token, ring_group_uuid))
      .then((response) => {
        const newarr = { ...response.data.ring_group_data[0] };
        newarr["createdby"] = createdby;
        newarr["main_admin_uuid"] = createdby;
        newarr["user_uuid"] = createdby;
        newarr["ring_group_fail_destination_str"] =
          response.data.ring_group_data[0].ring_group_fail_destination_type +
          "_" +
          response.data.ring_group_data[0].ring_group_fail_destination;
        newarr["ring_group_destinations"] =
          response.data.ringgroup_destination_data;

        setRinggroupData(newarr);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, [ringGroupEvent]);

  useEffect(() => {
    setLoading(true);
    dispatch(getDestinationTypeList(createdby, token, main_uuid, "extention"))
      .then((response) => {
        setExtensions(response.data);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(getDestinationList(createdby, token, main_uuid, ""))
      .then((response) => {
        let groupedOptions = [];
        response.data.forEach((option) => {
          if (!groupedOptions[option.optgroup])
            groupedOptions[option.optgroup] = [];
          groupedOptions[option.optgroup].push({
            value: option.value,
            text: option.text,
          });
        });

        setDestinationOptions(groupedOptions);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const changeRingGroupEvent = (value) => {
    setRingGroupEvent(value);
  };

  const breadCurmList = [
    { label: "Calls", link: "/", is_active: "NO" },
    { label: "Ring Group", link: "/ring-groups", is_active: "NO" },
    { label: ringgroupData.ring_group_extension, link: "", is_active: "YES" },
  ];

  return (
    <Fragment>
      <PageTitle breadCurmList={breadCurmList} />
      {loading && <Loader />}

      <Tab.Container defaultActiveKey={activeTab}>
        <Nav as="ul" className="nav-tabs ringtabs mt-4">
          <Nav.Item as="li" key="1">
            <Nav.Link eventKey="general">
              <i className={`la la-tty mr-2`} />
              General
            </Nav.Link>
          </Nav.Item>

          <Nav.Item as="li" key="2">
            <Nav.Link eventKey="advanced_options">
              <i className={`la la-envelope mr-2`} />
              Advanced Options
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content className="ringgroup">
          <Tab.Pane eventKey="general" key="1" className="pt-4">
            <GeneralFields
              ringgroupData={ringgroupData}
              extensions={extensions}
              changeRingGroupEvent={changeRingGroupEvent}
              destinationOptions={destinationOptions}
            ></GeneralFields>
          </Tab.Pane>
          <Tab.Pane eventKey="advanced_options" key="2" className="pt-4">
            <AdvanceFields
              ringgroupData={ringgroupData}
              changeRingGroupEvent={changeRingGroupEvent}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Fragment>
  );
}

export default ViewRingGroup;
