import React, { Fragment, useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import { useDispatch } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css";
import Notes from "./Notes";
import Sms from "./Sms";
import Activites from "./Activites";
import Emails from "./Emails";
import Tasks from "./Tasks";
import { notifyError, notifyInfo } from "../Toastr/Toastr";
import { updateContact, updateContactGroups } from "../../actions/contacts";
import SectionOverview from "./SectionOverview";
import ContactCustomField from "./ContactCustomField";
import Loader from "../../layouts/Loader";

function ContactDetail({ contactDetail, formData, handleContactEvent }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const [loading, setLoading] = useState(false);
  

  const [activeActivityTab, setActiveActivityTab] = useState("activity_all");  

  const dispatch = useDispatch();

  let dialContact = contactDetail;

  const save = (value, attribute, field_type = "") => {
    setLoading(true);

    if (attribute === "contact_group") {
      let arr = {
        contact_uuid: contactDetail.contact_uuid,
        groups: value,
        main_user_uuid: main_uuid,
        createdby: createdby,
      };

      dispatch(updateContactGroups(arr, token))
        .then((res) => {
          if (res.status === 200) {
            notifyInfo(res.message);
            handleContactEvent(Math.random);
          } else if (res.status === 406) {
            notifyError(res.message);
          } else if (res.status === 500) {
            notifyError("Something went wrong.");
          } else {
            notifyError("Something went wrong.");
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });

      return false;
    }

    let contactData = { ...contactDetail };
    contactData["token"] = token;
    contactData["createdby"] = createdby;
    contactData["main_user_uuid"] = main_uuid;
    contactData["user_uuid"] = createdby;
    contactData["assign_user"] = contactDetail.assign_to_uuid;

    if (attribute === "first_name") {
      let val = value.split(" ");

      contactData["first_name"] = val[0];
      contactData["last_name"] = val[1];
    } else {
      contactData["custom_field_data"] = {};
      contactData["dependent_field_data"] = {};

      if (field_type === "custom_field") {
        Object.keys(contactDetail["custom_field_data"]).map((field_uuid) => {
          contactData["custom_field_data"][field_uuid] =
            contactDetail["custom_field_data"][field_uuid]["field_value"];
            return true;
        });
        //return false;
        contactData["custom_field_data"][attribute] = value;
        contactData["custom_field"] = "YES";
      } else if (field_type === "dependent_field") {
        Object.keys(contactDetail["dependent_field_data"]).map((field_uuid) => {
          if (field_uuid.length > 0 || field_uuid === null) {
            contactData["dependent_field_data"][field_uuid] =
              contactDetail["dependent_field_data"][field_uuid];
          }
          return true;
        });

        let arr = {
          value: value,
          main_field_uuid: attribute.main_field_uuid,
          contact_custom_dependent_field_uuid:
            attribute.contact_custom_dependent_field_uuid,
        };

        contactData["dependent_field_data"][
          attribute.contact_custom_dependent_field_uuid
        ] = arr;
        contactData["dependent_field"] = "YES";
      } else {
        contactData[attribute] = value;
      }
    }

    dispatch(updateContact(contactData))
      .then((res) => {
        if (res.status === 200) {
          notifyInfo(res.message);
          handleContactEvent(Math.random);
        } else if (res.status === 406) {
          notifyError(res.message);
        } else if (res.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
       {loading && <Loader />}
      <>
        {formData.map((row) => (
          <span key={row.contact_form_section_uuid} className="contact-form">
            <Tab.Container
              defaultActiveKey={
                row.section_type === "overview"
                  ? "overview"
                  : row.tabs.length > 0
                  ? row.tabs[0].contact_form_tab_uuid
                  : ""
              }
            >
              <Nav as="ul" className="nav-tabs ringtabs mt-4">
                {row.section_type === "overview" && (
                  <>
                    <Nav.Item as="li" key="1">
                      <Nav.Link eventKey="overview">
                        {row.section_title}
                      </Nav.Link>
                    </Nav.Item>
                  </>
                )}

                {row.tabs.map((tab) => (
                  <Nav.Item as="li" key={tab.contact_form_tab_uuid}>
                    <Nav.Link eventKey={tab.contact_form_tab_uuid}>
                      {tab.tab_name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>

              <Tab.Content className="pt-12 ringgroup">
                {row.section_type === "overview" && (
                  <Tab.Pane eventKey="overview" key="1" className="pt-4">
                    <SectionOverview dialContact={dialContact} save={save} />
                  </Tab.Pane>
                )}

                {row.tabs.map((tab) => (
                  <Tab.Pane
                    eventKey={tab.contact_form_tab_uuid}
                    key={tab.contact_form_tab_uuid}
                    className="pt-4"
                  >
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-6">
                        <div className="card">
                          <div className="card-body">
                            {tab.sec_one_fileds.map((field) => (
                              <span key={field.custom_field_uuid}>
                                <ContactCustomField
                                  field={field}
                                  contactFiledData={
                                    dialContact.custom_field_data
                                  }
                                  save={save}
                                />
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-6">
                        <div className="card">
                          <div className="card-body">
                            {tab.sec_two_fileds.map((field) => (
                              <span key={field.custom_field_uuid}>
                                <ContactCustomField
                                  field={field}
                                  contactFiledData={
                                    dialContact.custom_field_data
                                  }
                                  save={save}
                                />
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </span>
        ))}

        <div className="card-header border-0 pb-0">
          <h5 className="card-title">Activities</h5>
        </div>

        <div className="card-body">
          <Tab.Container defaultActiveKey={activeActivityTab}>
            <Nav as="ul" className="nav-tabs ringtabs">
              <Nav.Item as="li">
                <Nav.Link
                  id="activity_all"
                  eventKey="activity_all"
                  onClick={() => setActiveActivityTab("activity_all")}
                >
                  All
                </Nav.Link>
              </Nav.Item>

              <Nav.Item as="li">
                <Nav.Link
                  id="activity_notes"
                  eventKey="activity_notes"
                  onClick={() => setActiveActivityTab("activity_notes")}
                >
                  Notes
                </Nav.Link>
              </Nav.Item>

              <Nav.Item as="li">
                <Nav.Link
                  id="activity_emails"
                  eventKey="activity_emails"
                  onClick={() => setActiveActivityTab("activity_emails")}
                >
                  Emails
                </Nav.Link>
              </Nav.Item>

              <Nav.Item as="li">
                <Nav.Link
                  id="activity_calls"
                  eventKey="activity_calls"
                  onClick={() => setActiveActivityTab("activity_calls")}
                >
                  Calls
                </Nav.Link>
              </Nav.Item>

              <Nav.Item as="li">
                <Nav.Link
                  id="activity_sms"
                  eventKey="activity_sms"
                  onClick={() => setActiveActivityTab("activity_sms")}
                >
                  SMS
                </Nav.Link>
              </Nav.Item>

              <Nav.Item as="li">
                <Nav.Link
                  id="activity_tasks"
                  eventKey="activity_tasks"
                  onClick={() => setActiveActivityTab("activity_tasks")}
                >
                  Tasks
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content className="pt-12">
              <Tab.Pane eventKey="activity_all">
                <Activites contact={dialContact} />
              </Tab.Pane>

              <Tab.Pane eventKey="activity_notes">
                <Notes contact={dialContact} />
              </Tab.Pane>

              <Tab.Pane eventKey="activity_emails">
                <Emails contact={dialContact} />
              </Tab.Pane>

              <Tab.Pane eventKey="activity_calls">Calls</Tab.Pane>

              <Tab.Pane eventKey="activity_sms">
                <Sms contact={dialContact} />
              </Tab.Pane>
              <Tab.Pane eventKey="activity_tasks">
                <Tasks contact={dialContact} />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </>
    </Fragment>
  );
}

export default ContactDetail;
