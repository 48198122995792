import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Table,
  Button,
  ButtonGroup,
  DropdownButton,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import PageTitle from "../../layouts/PageTitle";
import Pagination from "../../layouts/Pagination";
import {
  getExtensions,
  deleteExtension,
  copyExtensions,
} from "../../actions/extensions";
import { checkModuleAssign } from "../../actions/comman";
import CheckModulePermisson from "../../common/CheckModulePermisson";
import PermissionCheck from "../../common/PermissionCheck";
import { confirmAlert } from "react-confirm-alert";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import Loader from "../../layouts/Loader";

function Extension() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;
  const module_name = "extensions";

  let module_per = CheckModulePermisson(module_name);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  let isPermission = true;
  if (module_per === "" || user_type === "admin") {
    isPermission = true;
  } else {
    isPermission = false;
  }

  let listing_per = PermissionCheck(module_name, "listing", "", "", "");
  let add_per = PermissionCheck(module_name, "add", "", "", "");

  if (listing_per === "none") {
    navigate("/error-403");
  }

  let group_per = listing_per;
  let group_uuid = "";
  if (
    listing_per === "group" &&
    user_data.group_id !== "" &&
    user_data.group_id !== null
  ) {
    group_uuid = user_data.group_id;
  }

  const [limits, setLimits] = useState(10);
  const [extensions, setExtensions] = useState([]);
  const [extensionTotal, setExtensionTotal] = useState([]);
  const [extensionEvent, setExtensionEvent] = useState("");
  const [pageCount, setpageCount] = useState(0);
  const [offSet, setOffSet] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteConfirmData, setDeleteConfirmData] = useState([]);

  let search = "";
  let orderby = "e.extension ASC";

  if (module_per === "" || user_type === "admin") {
    useEffect(() => {
      setLoading(true);
      dispatch(
        getExtensions(
          user_type,
          group_per,
          group_uuid,
          user_data.role,
          listing_per,
          user_data.role_uuid,
          search,
          offSet,
          limits,
          orderby,
          main_uuid,
          createdby,
          token
        )
      )
        .then((response) => {
          setExtensions(response.data.data);
          setExtensionTotal(response.data.total);
          const total = response.data.total;
          setpageCount(Math.ceil(total / limits));
          setExtensionEvent("");
          setLoading(false);
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    }, [extensionEvent, limits]);
  }

  const handlePageClick = async (data) => {
    setOffSet(limits * data.selected);
    setExtensionEvent("update");
  };

  const deleteExt = (uuid) => {
    dispatch(
      checkModuleAssign("extenions", uuid, "destination", createdby, token)
    )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            setDeleteConfirmData(response.data);
            setDeleteModal(true);
          } else {
            confirmAlert({
              title: "",
              message: "Are you sure to delete this Extension?",
              buttons: [
                {
                  label: "Yes",
                  onClick: () => deleteExtensionService(uuid),
                },
                {
                  label: "No",
                },
              ],
            });
          }

          return;
        } else if (response.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const deleteExtensionService = (uuid) => {
    setLoading(true);
    dispatch(deleteExtension(uuid, createdby, token))
      .then((response) => {
        if (response.status === 200) {
          notifyInfo(response.message);
          setExtensionEvent("delete");
          setLoading(false);
          return;
        } else if (response.status === 500) {
          setLoading(false);
          notifyError("Something went wrong.");
        } else {
          setLoading(false);
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const copyExt = (uuid) => {
    confirmAlert({
      title: "",
      message: "Are you sure to copy this Extension?",
      buttons: [
        {
          label: "Yes",
          onClick: () => copyExtensionService(uuid),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const copyExtensionService = (uuid) => {
    setLoading(true);
    dispatch(copyExtensions(uuid, createdby, main_uuid, token))
      .then((response) => {
        if (response.status === 200) {
          notifyInfo(response.message);
          setLoading(false);
          navigate("/update-extension", {
            replace: true,
            state: { extension_uuid: response.data, activeTab: "general" },
          });
          return;
        } else if (response.status === 500) {
          setLoading(false);
          notifyError("Something went wrong.");
        } else {
          setLoading(false);
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getDestination = (
    did_action_type,
    ivr_menu_name,
    ring_group_extension,
    extension,
    time_condition_name,
    voicemail_id,
    did_number,
    external_phone_number
  ) => {
    if (did_action_type === "ivr") {
      return "Auto Attendent - " + ivr_menu_name;
    } else if (did_action_type === "extention") {
      return "Extention - " + extension;
    } else if (did_action_type === "ring-group") {
      return "Ring Group - " + ring_group_extension;
    } else if (did_action_type === "did") {
      return "Inbound Number - " + did_number;
    } else if (did_action_type === "voicemail") {
      return "Voicemail - " + voicemail_id;
    } else if (did_action_type === "time_condition") {
      return "Time Condition - " + time_condition_name;
    } else if (did_action_type === "external_phone_number") {
      return external_phone_number;
    } else {
      return "None";
    }
  };

  const chnageLimit = (value) => {
    setLimits(value);
  };

  const breadCurmList = [
    { label: "Calls", link: "/", is_active: "NO" },
    { label: "Extensions", link: "/extensions", is_active: "NO" },
    { label: "Total Amount : " + extensionTotal, link: "", is_active: "YES" },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />
      {isPermission ? (
        <>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Manage Extensions</h4>
                  {add_per === "yes" ? (
                    <Button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={() =>
                        navigate("/add-extension", { replace: true })
                      }
                    >
                      Add New Extension
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="card-body">
                  Show
                  <ButtonGroup className="ml-2 mr-2">
                    <DropdownButton
                      as={ButtonGroup}
                      id="bg-nested-dropdown"
                      size="sm"
                      title={limits}
                      variant="custom-dropdown"
                    >
                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(10)}
                      >
                        10
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(25)}
                      >
                        25
                      </Dropdown.Item>

                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(50)}
                      >
                        50
                      </Dropdown.Item>

                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(100)}
                      >
                        100
                      </Dropdown.Item>
                    </DropdownButton>
                  </ButtonGroup>
                  entries
                  <Table responsive className="w-100">
                    <thead>
                      <tr role="row">
                        <th>Extension</th>
                        <th>User</th>
                        <th>Forwarded</th>
                        <th>VM Enabled</th>
                        <th>Call Waiting</th>
                        <th>Status</th>
                        {listing_per === "group" || user_type === "admin" ? (
                          <th>Created By</th>
                        ) : (
                          <></>
                        )}
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {extensions.map((extension) => (
                        <tr key={extension.extension_uuid}>
                          <td>
                            <Link
                              to="/update-extension"
                              state={{
                                extension_uuid: extension.extension_uuid,
                                activeTab: "general",
                              }}
                              className="text text-info"
                            >
                              {extension.extension}
                            </Link>
                          </td>
                          <td>{extension.username}</td>
                          <td>
                            {getDestination(
                              extension.forward_route_type,
                              extension.ivr_menu_name,
                              extension.ring_group_extension,
                              extension.extension,
                              extension.time_condition_name,
                              extension.voicemail_id,
                              extension.did_number,
                              extension.external_phone_number
                            )}
                          </td>
                          <td>{extension.voicemail_enabled}</td>
                          <td>{extension.call_waiting_enabled}</td>
                          <td>
                            {extension.dnd_enabled === "YES" ? (
                              <>{"DND"}</>
                            ) : (
                              <>{extension.extension_status}</>
                            )}
                          </td>
                          {listing_per === "group" ||
                          listing_per === "all" ||
                          user_type === "admin" ? (
                            <td>
                              {extension.first_name} {extension.last_name}
                            </td>
                          ) : (
                            <></>
                          )}
                          <td>
                            <Fragment>
                              <div className="d-flex">
                                <Link
                                  to="/update-extension"
                                  state={{
                                    extension_uuid: extension.extension_uuid,
                                    activeTab: "general",
                                  }}
                                  hidden={PermissionCheck(
                                    module_name,
                                    "edit",
                                    extension.group_uuid,
                                    extension.user_created_by,
                                    extension.created_by
                                  )}
                                  className="btn btn-primary shadow btn-xs sharp mr-1"
                                >
                                  <i className="fa fa-pencil"></i>
                                </Link>

                                {add_per === "yes" ? (
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      copyExt(extension.extension_uuid)
                                    }
                                    className="btn btn-info shadow btn-xs sharp mr-1"
                                  >
                                    <i className="fa fa-copy"></i>
                                  </Link>
                                ) : (
                                  <></>
                                )}

                                <Link
                                  to="#"
                                  onClick={() =>
                                    deleteExt(extension.extension_uuid)
                                  }
                                  hidden={PermissionCheck(
                                    module_name,
                                    "delete",
                                    extension.group_uuid,
                                    extension.user_created_by,
                                    extension.created_by
                                  )}
                                  className="btn btn-danger shadow btn-xs sharp"
                                >
                                  <i className="fa fa-trash"></i>
                                </Link>
                              </div>
                            </Fragment>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Pagination
                    pageCount={pageCount}
                    handlePageNumberClick={handlePageClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Manage Extensions</h4>
              </div>
              <div className="card-body">
                <h3>You have don't access this modules</h3>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal className="fade bd-example-modal-lg" show={deleteModal} size="md">
        <Modal.Body>
          <p>
            This extension is assign to following module. Please, remove from
            that to delete.
          </p>
          {deleteConfirmData.map((row) => (
            <div key={Math.random}>
              <span>
                <b>{row.module}</b>: {row.value}
              </span>
            </div>
          ))}

          <Button
            onClick={() => setDeleteModal(false)}
            variant="primary light btn-sm pull-right"
          >
            Close
          </Button>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}

export default Extension;
