import React, { Component } from "react";

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";

// import drump from "../../../images/card/drump.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);

    // $("#menu").metisMenu({
    //   toggle: false
    // });
  }
  componentWillUnmount() {
    // this.mm("dispose");
    // console.log(this.mm);
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBarSuperAdmin extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");

    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }

    btn.addEventListener("click", toggleFunc);
  }

  render() {
    /// Path
    var path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    // var user = JSON.parse(localStorage.getItem("user"));
    // var user_data = user.data.data;
    // var isSuperAdminLoggedIn = user_data.role === "superadmin" ? "" : "hidden";
    // var isAdminLoggedIn = user_data.role === "admin" ? "" : "hidden";
    // var role_permission = user_data.role_permission;

    /// Active menu
    // let deshBoard = ["dashboard", "/"];
    // let userSetting = ["roles", "users"];
    // let applications = [
    //   "extensions",
    //   "did",
    //   "ring-groups",
    //   "recordings",
    //   "ivr",
    // ];
    // let crm = ["contacts"];
    let plan = ["plans"];

    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${path === "" ? "mm-active" : ""}`}>
              <Link className={`${path === "" ? "mm-active" : ""}`} to="/">
                <i className="flaticon-381-networking"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>
            <li className={`${plan.includes(path) ? "mm-active" : ""}`}>
              <Link
                className={`${plan.includes(path) ? "mm-active" : ""}`}
                to="/plans"
              >
                <i className="flaticon-381-controls-3"></i>
                <span className="nav-text">Plans</span>
              </Link>
            </li>

            <li>
              <Link className="ai-icon" to="logout" aria-expanded="false">
                <i className="fa fa-sign-out"></i>
                <span className="nav-text">Logout</span>
              </Link>
            </li>
          </MM>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBarSuperAdmin;
