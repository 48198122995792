import React, { Fragment } from "react";
import "./style.css";
import Tooltip from "rc-tooltip";

function GeneralFieldsForm(props) {

  let ivrData = props.ivrData;
  let recordings = props.recordings;
  let destination = props.destination;
  let errors = props.errors;

  let timeout_array = new Array(60).fill(0).map((_, i) => {
    return { label: i, value: i };
  });
  let number_array = new Array(10).fill(1).map((_, i) => {
    return { label: i + 1, value: i + 1 };
  });


  return (
    <Fragment>
      <form action="">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-4">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm">
                    <div className="form-group">
                      <label className="w-100">
                        Auto-Attendant ID
                        <span className="text-danger">*</span>
                        <Tooltip
                          placement="top"
                          overlay={<span>Auto-Attendant ID</span>}
                        >
                          <span className="ti-info-alt ml-1"></span>
                        </Tooltip>
                      </label>
                      <p>{ivrData.ivr_menu_extension}</p>
                    </div>
                  </div>

                  <div className="col-sm">
                    <div className="form-group input-primary">
                      <label className="w-100">
                        Auto-Attendant Name
                        <span className="text-danger">*</span>
                        <Tooltip
                          placement="top"
                          overlay={<span>Auto-Attendant Name</span>}
                        >
                          <span className="ti-info-alt ml-1"></span>
                        </Tooltip>
                      </label>

                      <input
                        type="text"
                        className="form-control bg-white"
                        //placeholder="Enter a name"
                        name="ivr_menu_name"
                        onChange={props.handleBlur}
                        value={ivrData.ivr_menu_name}
                      />
                      <span className="text-danger">
                        {errors.ivr_menu_name}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm">
                    <div className="form-group input-primary">
                      <label className="w-100">
                        Welcome Message
                        <span className="text-danger">*</span>
                        <Tooltip
                          placement="top"
                          overlay={<span>Welcome Message</span>}
                        >
                          <span className="ti-info-alt ml-1"></span>
                        </Tooltip>
                      </label>

                      <select
                        className="form-control bg-white"
                        name="ivr_menu_welcome_message"
                        onChange={props.handleBlur}
                      >
                        <option value="">Select the Greeting</option>
                        {recordings &&
                          recordings.map((recording) => (
                            <option
                              key={recording.value}
                              value={recording.value}
                            >
                              {recording.label}
                            </option>
                          ))}
                      </select>
                      <span className="text-danger">
                        {errors.ivr_menu_welcome_message}
                      </span>
                    </div>
                  </div>

                  <div className="col-sm">
                    <div className="form-group customToggle">
                      <label className="w-100">
                        Enable Direct Dial
                        <Tooltip
                          placement="top"
                          overlay={<span>Enable Direct Dial</span>}
                        >
                          <span className="ti-info-alt  ml-1"></span>
                        </Tooltip>
                      </label>

                      <p>
                        {ivrData.ivr_menu_direct_dial === "TRUE" ? "YES" : "NO"}
                        <span className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="ivr_menu_direct_dial"
                            id="ivr_menu_direct_dial"
                            checked={
                              ivrData.ivr_menu_direct_dial === "TRUE"
                                ? "checked"
                                : ""
                            }
                            onChange={(e) => props.handleBlur(e)}
                          />
                          <label
                            className="custom-control-label cursor-pointer"
                            htmlFor="ivr_menu_direct_dial"
                          ></label>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-4">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm">
                    <div className="form-group input-primary">
                      <label className="w-100">
                        Selection Timeout
                        <Tooltip
                          placement="top"
                          overlay={<span>Selection Timeout</span>}
                        >
                          <span className="ti-info-alt  ml-1"></span>
                        </Tooltip>
                      </label>

                      <select
                        name="ivr_menu_timeout"
                        className="form-control bg-white"
                        onChange={props.handleBlur}
                        defaultValue={ivrData.ivr_menu_timeout}
                      >
                        {timeout_array.map((obj) => {
                          return (
                            <option key={obj.value} value={obj.value}>
                              {obj.label}
                            </option>
                          );
                        })}
                      </select>
                      <span className="text-danger">
                        {errors.ivr_menu_timeout}
                      </span>
                    </div>
                  </div>

                  <div className="col-sm">
                    <div className="form-group input-primary">
                      <label className="w-100">
                        Timeout Action
                        <Tooltip
                          placement="top"
                          overlay={<span>Timeout Action</span>}
                        >
                          <span className="ti-info-alt ml-1"></span>
                        </Tooltip>
                      </label>

                      <select
                        name="ivr_menu_exit_action"
                        className="form-control bg-white"
                        onChange={props.handleBlur}
                        defaultValue={ivrData.ivr_menu_exit_action}
                      >
                        {Object.keys(destination).map((group, index) => {
                          return (
                            <optgroup key={index} label={group}>
                              {destination[group].map((option) => {
                                return (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.text}
                                  </option>
                                );
                              })}
                            </optgroup>
                          );
                        })}
                      </select>
                      <span className="text-danger">
                        {errors.ivr_menu_exit_action}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group input-primary">
                      <label className="w-100">
                        Caller ID Name Prefix
                        <Tooltip
                          placement="top"
                          overlay={<span>Caller ID Name Prefix</span>}
                        >
                          <span className="ti-info-alt  ml-1"></span>
                        </Tooltip>
                      </label>

                      <input
                        type="text"
                        className="form-control bg-white"
                        //placeholder="Enter Caller ID Name Prefix"
                        name="ivr_menu_cid_prefix"
                        onChange={props.handleBlur}
                      />

                      <span className="text-danger">
                        {errors.ivr_menu_cid_prefix}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-4">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm">
                    <div className="form-group">
                      <label className="w-100">
                        Max Attempts
                        <Tooltip
                          placement="top"
                          overlay={<span>Max Attempts</span>}
                        >
                          <span className="ti-info-alt ml-1"></span>
                        </Tooltip>
                      </label>

                      <select
                        name="ivr_menu_max_attempt"
                        className="form-control bg-white"
                        onChange={props.handleBlur}
                        defaultValue={ivrData.ivr_menu_max_attempt}
                      >
                        {number_array.map((obj) => {
                          return (
                            <option key={obj.value} value={obj.value}>
                              {obj.label}
                            </option>
                          );
                        })}
                      </select>
                      <span className="text-danger">
                        {errors.ivr_menu_max_attempt}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm">
                    <div className="form-group">
                      <label className="w-100">
                        Invalid Destination
                        <Tooltip
                          placement="top"
                          overlay={<span>Invalid Destination</span>}
                        >
                          <span className="ti-info-alt ml-1"></span>
                        </Tooltip>
                      </label>

                      <select
                        name="ivr_menu_invalid_destination"
                        className="form-control bg-white"
                        onChange={props.handleBlur}
                        defaultValue={
                          ivrData.ivr_menu_invalid_destination_type +
                          "_" +
                          ivrData.ivr_menu_invalid_destination
                        }
                      >
                        {Object.keys(destination).map((group, index) => {
                          return (
                            <optgroup key={index} label={group}>
                              {destination[group].map((option) => {
                                return (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.text}
                                  </option>
                                );
                              })}
                            </optgroup>
                          );
                        })}
                      </select>
                      <span className="text-danger">
                        {errors.ivr_menu_invalid_destination}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-lg-6 col-md-6 col-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group">
                                <label className="w-100">Short Geeting
                                    <Tooltip
                                        placement="top"
                                        overlay={<span>Short Geeting</span>}
                                    >
                                        <span className="ti-info-alt ml-1"></span>
                                    
                                    </Tooltip>
                                </label> 

                                <select
                                    className="form-control"
                                    name="ivr_menu_greet_short"
                                    onChange={props.handleBlur}
                                >
                                    <option value="">Select the Greeting</option>
                                    {recordings &&
                                    recordings.map((recording) => (
                                        <option
                                        key={recording.recording_uuid}
                                        value={recording.recording_uuid}
                                        >
                                        {recording.recording_name} -{" "}
                                        {recording.recording_filename}
                                        </option>
                                    ))}
                                </select>
                                <span className="text-danger">
                                    {errors.ivr_menu_greet_short}
                                </span>
                               

                            </div>
                            
                        </div>
                    </div>
                </div> */}

          <div className="col-lg-12 col-md-12 col-12">
            <button
              type="button"
              className="btn btn-sm btn-primary pull-right"
              onClick={(e) => props.submitHandler(e)}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </Fragment>
  );
}

export default GeneralFieldsForm;
