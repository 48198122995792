import React, { useState } from "react";
import Cart from "./Cart";
import useScript from "../../useScript";
import { transactionCaller } from "../../actions/auth";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { notifyInfo } from "../../components/Toastr/Toastr";
import Loader from "../../layouts/Loader";

const StepFour = (props) => {

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useScript("https://jstest.authorize.net/v3/AcceptUI.js");

  window.responseHandler = (response) => {
    // console.log(response);

    if (response.messages.resultCode === "Error") {
      var i = 0;
      while (i < response.messages.message.length) {
        console.log(
          response.messages.message[i].code +
            ": " +
            response.messages.message[i].text
        );
        i = i + 1;
      }
    } else {
      createTransact(response.opaqueData);
    }
  };

  function createTransact(dataObj) {
    setLoading(true);
    // Set Amount for demo purposes if not set by callers form
    let myAmt = document.getElementById("amount").value;
    if (!myAmt) {
      myAmt = Math.floor(Math.random() * 100 + 1);
    }

    let data = {
      amount: myAmt,
      dataDesc: dataObj.dataDescriptor,
      dataValue: dataObj.dataValue,
    };

    dispatch(transactionCaller(data, props.formData))
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          notifyInfo(res.message);
          navigate("/login", { replace: true });
        } else if (res.status === 406) {
          setLoading(false);
          // error_data["extension"] = res.message;
          // setErrors(error_data);
          // isError = "true";
        } else if (res.status === 500) {
          setLoading(false);
          // notifyError("Something went wrong.");
        } else {
          setLoading(false);
          // notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        // setLoading(false);
      });
  }


  return (
    <section>
      {loading && <Loader />}
      <div className="row">
        <div className="col-sm-8 mt-3">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Payment Info </h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        type="hidden"
                        name="amount"
                        id="amount"
                        value={props.formData.cart_data.grand_total}
                      />
                      <button
                        type="button"
                        className="AcceptUI btn btn-primary btn-lg btn-block"
                        data-billingAddressOptions='{"show":true, "required":true}'
                        data-apiLoginID="58JgWje4e"
                        data-clientKey="6C6XS99RNu9me88V858t6K64VxwFHZcf4mZ8xGc8uzeHCEhUBhKr9K54zFwQqbk6"
                        data-acceptUIFormBtnTxt="Submit"
                        data-acceptUIFormHeaderTxt="Card Information"
                        data-paymentOptions='{"showCreditCard": true, "showBankAccount": true}'
                        data-responseHandler="responseHandler"
                      >
                        Continue to pay
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4 mt-3">
          <Cart
            formData={props.formData}
            handleCartData={props.handleCartData}
          />
        </div>
      </div>
    </section>
  );
};

export default StepFour;
