import React, { Fragment, useState, useEffect,useRef } from "react";
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { getMeetingDetails } from "../../../actions/calender/CalendarMeeting";
import {createAccessToken} from "../../../actions/calender/Daily";
import { notifyError,notifyInfo } from "../../Toastr/Toastr";
import Loader from "../../../layouts/Loader";
import DailyIframe from '@daily-co/daily-js';

function MeetingScreen(props) {
  

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;

  const [searchParams] = useSearchParams();
  const calendar_meeting_uuid = searchParams.get('t');

  const videoCallRef = useRef(null);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [meetingData, setMeetingData] = useState(false);
  const [isLinkExpired, setIsLinkExpired] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(getMeetingDetails(calendar_meeting_uuid,'OWNER')).then((response) => {
      if (response.status === 200) {
        setMeetingData(response.data);

        let meeting_url = response.data.meeting_url;

        const room_name = meeting_url.replace('https://zongo.daily.co/', '');

        let tokenReq =  {"properties":{"room_name":room_name}}

        dispatch(createAccessToken(tokenReq)).then((tokenResponse) => {
        
          if(tokenResponse.data.token){

            if (!videoCallRef.current) return;

            const callFrame = DailyIframe.createFrame(videoCallRef.current, {
              showLeaveButton: true,
            });        
    
            callFrame.join({ url: meeting_url,token: tokenResponse.data.token });
            setLoading(false);

            return () => {
              callFrame.destroy();
            };

          }else{
            notifyError('Someting went wrong please try again.');
            setLoading(false);
          }
          return;
        })
        .catch(() => {
          setLoading(false);
        });

        
      }
      if (response.status === 404) {
        setIsLinkExpired(true);
      }      
      setLoading(false);
      return;
    })
    .catch(() => {
      setLoading(false);
    });

  }, []);

  return (
    <Fragment>
      {loading && <Loader />}

      {isLinkExpired && 
        <p className="text-red text-center mt-3">URL Expired!</p>
      }

      {!isLinkExpired && 
        <div ref={videoCallRef} style={{ width: '100%', height: '700px' }}></div>
      }

    </Fragment>
  );
}

export default MeetingScreen;
