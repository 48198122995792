import { SET_MESSAGE, API_FAIL } from "../types";

import UserMeetingEventService from "../../services/calender/UserMeetingEvent.service";

export const getEventList =
  (
    createdby,
    token
  ) =>
  (dispatch) => {
    return UserMeetingEventService.getEventList(
      createdby,
      token
    ).then(
      (data) => {
        if (
          data.status === 406 ||
          data.status === 500 ||
          data.status === 200
        ) {
          return data;
        } else {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

   export const updateUserMeetingEvent = (update_data, token) => (dispatch) => {
    
    return UserMeetingEventService.updateUserMeetingEvent(update_data, token).then(
      (data) => {
        if (data.status === 406) {
          return data;
        } else if (data.status !== 200) {
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        } else {
          return data;
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const craeteUserMeetingEvent = (create_data,token) => (dispatch) => {
    return UserMeetingEventService.craeteUserMeetingEvent(create_data,token).then(
      (data) => {
        if (data.status === 406) {
          return data;
        } else if (data.status !== 200) {
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        } else {
          return data;
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

 

  export const deleteUserMeetingEvent = (user_meeting_event_uuid, createdby, token) => (dispatch) => {
    return UserMeetingEventService.deleteUserMeetingEvent(user_meeting_event_uuid, createdby, token).then(
      (data) => {
        if (data.status === 406) {
          return data;
        } else if (data.status !== 200) {
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        } else {
          return data;
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  
  export const getMeetingEvent = (user_meeting_availability_uuid) => (dispatch) => {
    return UserMeetingEventService.getMeetingEvent(user_meeting_availability_uuid).then(
      (data) => {
        if (data.status === 406) {
          return data;
        } else if (data.status !== 200) {
          return data;
        } else {
          return data;
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const getMeetingModes = (user_meeting_event_uuid) => (dispatch) => {
    return UserMeetingEventService.getMeetingModes(user_meeting_event_uuid).then(
      (data) => {
        if (data.status === 406) {
          return data;
        } else if (data.status !== 200) {
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        } else {
          return data;
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };