
import React from "react";
import ReactPaginate from "react-paginate";

function Pagination(props) {
    
    return <ReactPaginate
        previousLabel={"<<"}
        nextLabel={">>"}
        breakLabel={"..."}
        pageCount={props.pageCount}
        marginPagesDisplayed={2}
        //pageRangeDisplayed={3}
        onPageChange={props.handlePageNumberClick}
        containerClassName={"pagination justify-content-center"}
        pageClassName={"page-item cursor-pointer"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item cursor-pointer"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item cursor-pointer"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item cursor-pointer"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
    />
}

export default Pagination;