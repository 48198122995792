import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Table, ButtonGroup, DropdownButton, Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css";
import PageTitle from "../../layouts/PageTitle";
import { getSmsLog } from "../../actions/sms";
import CheckModulePermisson from "../../common/CheckModulePermisson";
import PermissionCheck from "../../common/PermissionCheck";
import Loader from "../../layouts/Loader";
import Pagination from "../../layouts/Pagination";

function Sms(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;
  const module_name = "sms-log";

  let module_per = CheckModulePermisson(module_name);

  let isPermission = true;
  if (module_per === "" || user_type === "admin") {
    isPermission = true;
  } else {
    isPermission = false;
  }

  let listing_per = PermissionCheck(module_name, "listing", "", "", "");

  const dispatch = useDispatch();
  let navigate = useNavigate();

  if (!isPermission) {
    navigate("/error-403");
  }

  if (listing_per === "none") {
    navigate("/error-403");
  }

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }
  const [smsLogEvent, setSmsLogEvent] = useState("");

  const [limits, setLimits] = useState(10);
  const [loading, setLoading] = useState(false);
  const [smsCampaigns, setSmsCampaigns] = useState([]);
  const [totalRecords, setTotalRecords] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [offSet, setOffSet] = useState(0);

  let search = "";
  let status = "ACTIVE";

  let orderby = "sl.date_time ASC";

  if (module_per === "" || user_type === "admin") {
    useEffect(() => {
      setLoading(true);
      dispatch(
        getSmsLog(
          main_uuid,
          user_type,
          group_per,
          group_uuid,
          search,
          offSet,
          status,
          limits,
          orderby,
          createdby,
          token
        )
      )
        .then((response) => {
          setSmsCampaigns(response.data.data);
          setTotalRecords(response.data.total);
          let total = response.data.total
           setpageCount(Math.ceil(total / limits));

          setSmsLogEvent("");
          setLoading(false);
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    }, [smsLogEvent]);
  }

  const handlePageClick = async (data) => {
    setOffSet(limits * data.selected);
    setSmsLogEvent(Math.random());
  };

  const chnageLimit = (value) => {
    setLimits(value);
  };

  const breadCurmList = [
    { label: "SMS", link: "/", is_active: "NO" },
    { label: "SMS Log", link: "/sms-logs", is_active: "NO" },
    { label: "Total Amount : " + totalRecords, link: "", is_active: "YES" },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />
      {isPermission ? (
        <>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Manage SMS Log</h4>
                </div>

                <div className="card-body">
                  Show
                  <ButtonGroup className="ml-2 mr-2">
                    <DropdownButton
                      as={ButtonGroup}
                      id="bg-nested-dropdown"
                      size="sm"
                      title={limits}
                      variant="custom-dropdown"
                    >
                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(10)}
                      >
                        10
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(25)}
                      >
                        25
                      </Dropdown.Item>

                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(50)}
                      >
                        50
                      </Dropdown.Item>

                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(100)}
                      >
                        100
                      </Dropdown.Item>
                    </DropdownButton>
                  </ButtonGroup>
                  entries
                  <Table responsive className="w-100">
                    <thead>
                      <tr role="row">
                        <th>Timestamp</th>
                        <th>Direction</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Body</th>
                        <th>Contact Name</th>
                        <th>Status</th>
                        {/* {listing_per === "group" || user_type === "admin" ? (
                          <th>Created By</th>
                        ) : (
                          <></>
                        )} */}
                      </tr>
                    </thead>

                    <tbody>
                      {smsCampaigns.map((smsCapm) => (
                        <tr key={smsCapm.sms_campaign_log_uuid}>
                          <td>{smsCapm.date_time}</td>
                          <td>{smsCapm.direction}</td>
                          <td>{smsCapm.from_number}</td>
                          <td>{smsCapm.to_number}</td>
                          <td>{smsCapm.message_text}</td>
                          <td>{smsCapm.contact_name}</td>
                          <td>{smsCapm.status}</td>
                          {/* {listing_per === "group" ||
                          listing_per === "all" ||
                          user_type === "admin" ? (
                            <td>{smsCapm.created_by_name}</td>
                          ) : (
                            <span></span>
                          )} */}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Pagination
                    pageCount={pageCount}
                    handlePageNumberClick={handlePageClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Manage Sms Log</h4>
              </div>
              <div className="card-body">
                <h3>You have don't access this modules</h3>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Sms;
