import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const getContactUserList = (main_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "internal-chat/get-user-list",
      {
        main_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getInternalChatLog = (
  to_uuid,
  from_uuid,
  group_uuid,
  tab,
  main_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "internal-chat/get-internal-chat-log",
      { to_uuid, from_uuid, group_uuid, tab, main_uuid, createdby },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const sendInternalMessage = (data, token) => {
  return axios
    .post(API_URL + "internal-chat/send-message", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const getGroupList = (main_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "internal-chat/get-group-list",
      { main_uuid, createdby },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getGroupUserList = (main_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "internal-chat/get-group-user-list",
      {
        main_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getGroupUserDetails = (group_uuid, main_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "internal-chat/get-group-user-details",
      { group_uuid, main_uuid, createdby },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const deleteGroupMember = (
  user_uuid,
  group_uuid,
  group_delete,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "internal-chat/delete-group-member",
      { user_uuid, group_uuid, group_delete, createdby },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getGroupDetails = (group_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "internal-chat/get-group-details",
      { group_uuid, createdby },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const sendChatFile = (data, token) => {
  return axios
    .post(API_URL + "internal-chat/send-chat-file", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const getMessageNotification = (main_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "internal-chat/get-message-notification",
      {
        main_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

export default {
  getContactUserList,
  getInternalChatLog,
  sendInternalMessage,
  getGroupList,
  getGroupUserList,
  getGroupUserDetails,
  deleteGroupMember,
  getGroupDetails,
  sendChatFile,
  getMessageNotification,
};
