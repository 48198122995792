import React, { useState } from "react";
import Cart from "./Cart";

const StepTwo = (props) => {
  
  const [totalUsers, setTotalUsers] = useState(1);

  const handleBlur = (e) => {
    const newformdata = { ...props.formData };
    const errorsformdata = { ...props.errors };

    if (e.target.name === "first_name") {
      if (e.target.value.length === 0) {
        errorsformdata.user_data.first_name = "First Name is required.";
      } else {
        if (/^[a-z][a-z\s]*$/i.test(e.target.value)) {
          errorsformdata.user_data.first_name = "";
        } else {
          errorsformdata.user_data.first_name = "First Name is invalid.";
        }
      }
      newformdata.user_data.first_name = e.target.value;
    }

    if (e.target.name === "last_name") {
      if (e.target.value.length === 0) {
        errorsformdata.user_data.last_name = "Last Name is required.";
      } else {
        if (/^[a-z][a-z\s]*$/i.test(e.target.value)) {
          errorsformdata.user_data.last_name = "";
        } else {
          errorsformdata.user_data.last_name = "First Name is invalid.";
        }
      }
      newformdata.user_data.last_name = e.target.value;
    }
 
    if (e.target.name === "email") {
      if (e.target.value.length === 0) {
        errorsformdata.user_data.email = "Email is required.";
      } else {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
          errorsformdata.user_data.email = "Email is invalid.";
        } else {
          errorsformdata.user_data.email = "";
        }
      }
      newformdata.user_data.email = e.target.value;
    }

    if (e.target.name === "phone") {
      if (e.target.value.length === 0) {
        errorsformdata.user_data.phone = "Phone Number is required.";
      } else {
        if (/^\d{9,11}$/.test(e.target.value)) {
          errorsformdata.user_data.phone = "";
        } else {
          errorsformdata.user_data.phone =
            "Phone number invalid(9 to 11 digits allow).";
        }
      }

      newformdata.user_data.phone = e.target.value;
    }

    if (e.target.name === "job_title") {
      if (e.target.value.length === 0) {
        errorsformdata.user_data.job_title = "Job Title is required.";
      } else {
        errorsformdata.user_data.job_title = "";
      }
      newformdata.user_data.job_title = e.target.value;
    }

    if (e.target.name === "department") {
      if (e.target.value.length === 0) {
        errorsformdata.user_data.department = "Department is required.";
      } else {
        errorsformdata.user_data.department = "";
      }
      newformdata.user_data.department = e.target.value;
    }

    props.handleTwoData(newformdata);
    props.handleTwoError(errorsformdata);
  };

  const addUsers = (counts) => {
    // let total_users = totalUsers;
    const newformdata = { ...props.formData };
    let total_users = props.formData.plan_data.total_users;
    total_users = parseInt(total_users) + parseInt(1);
    newformdata.plan_data.total_users = total_users;
    let sub_total =
      parseFloat(props.formData.plan_data.final_price) * total_users;
    let total = parseFloat(props.formData.plan_data.plan_price) * total_users;
    newformdata.cart_data.total = total.toFixed(2);
    newformdata.cart_data.sub_total = sub_total.toFixed(2);
    newformdata.cart_data.grand_total = sub_total.toFixed(2);
    props.handleTwoData(newformdata);
    setTotalUsers(total_users);
  };

  const removeUsers = (counts) => {
    // let total_users = totalUsers;
    const newformdata = { ...props.formData };
    let total_users = props.formData.plan_data.total_users;
    total_users = parseInt(total_users) - parseInt(1);
    newformdata.plan_data.total_users = total_users;
    let sub_total =
      parseFloat(props.formData.plan_data.final_price) * total_users;
    let total = parseFloat(props.formData.plan_data.plan_price) * total_users;
    newformdata.cart_data.total = total.toFixed(2);
    newformdata.cart_data.sub_total = sub_total.toFixed(2);
    newformdata.cart_data.grand_total = sub_total.toFixed(2);
    props.handleTwoData(newformdata);
    setTotalUsers(total_users);
  };

  return (
    <section>
      <div className="row">
        <div className="col-sm-8 mt-3">
          <div className="card-body">
            <h4 className="card-title">How many users do you need?</h4>

            <button
              type="button"
              className="badge badge-rounded badge-lg badge-outline-dark mr-2"
              disabled={totalUsers <= 1 ? true : false}
              onClick={() => removeUsers(totalUsers)}
            >
              -
            </button>

            <button
              type="button"
              className="badge badge-lg badge-rounded badge-outline-light mr-2 pl-4 pr-4"
            >
              {totalUsers}
            </button>

            <button
              type="button"
              className="badge badge-rounded badge-lg badge-outline-dark mr-2"
              onClick={() => addUsers(totalUsers)}
            >
              +
            </button>
          </div>
          <hr />
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Contact Information </h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="text-label">First Name</label>
                      <span className="text-danger">*</span>
                      <input
                        type="text"
                        name="first_name"
                        className="form-control"
                        placeholder=""
                        onChange={handleBlur}
                        value={props.formData.user_data.first_name}
                      />
                      <span className="text-danger">
                        {props.errors.user_data.first_name}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="text-label">Last Name</label>
                      <span className="text-danger">*</span>
                      <input
                        type="text"
                        className="form-control"
                        name="last_name"
                        id="last_name"
                        placeholder=""
                        onChange={handleBlur}
                        value={props.formData.user_data.last_name}
                      />
                      <span className="text-danger">
                        {props.errors.user_data.last_name}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="text-label">Email</label>
                      <span className="text-danger">*</span>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder=""
                        onChange={handleBlur}
                        value={props.formData.user_data.email}
                      />
                      <span className="text-danger">
                        {props.errors.user_data.email}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="text-label">Phone Number</label>
                      <span className="text-danger">*</span>
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        id="phone"
                        placeholder=""
                        onChange={handleBlur}
                        value={props.formData.user_data.phone}
                      />
                      <span className="text-danger">
                        {props.errors.user_data.phone}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="text-label">Job Title </label>
                      <span className="text-danger">*</span>
                      <input
                        type="text"
                        className="form-control"
                        name="job_title"
                        id="job_title"
                        placeholder=""
                        onChange={handleBlur}
                        value={props.formData.user_data.job_title}
                      />
                      <span className="text-danger">
                        {props.errors.user_data.job_title}
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="text-label">Department</label>
                      <span className="text-danger">*</span>
                      <select
                        className="form-control"
                        name="department"
                        onChange={handleBlur}
                        value={props.formData.user_data.department}
                      >
                        <option value="">Select Department Type</option>
                        <option value="Executive">Executive</option>
                        <option value="Sales">Sales</option>
                        <option value="Marketing">Marketing</option>
                        <option value="HR">HR</option>
                        <option value="Development">Development</option>
                        <option value="Support">Support</option>
                        <option value="Other">Other</option>
                      </select>
                      <span className="text-danger">
                        {props.errors.user_data.department}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4 mt-3">
          <Cart
            formData={props.formData}
            handleCartData={props.handleCartData}
          />
        </div>
      </div>
    </section>
  );
};

export default StepTwo;
