import React, { Component } from "react";

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";
import PermissionsCheck from "./../../common/CheckModulePermisson";


class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);

  }
  componentWillUnmount() {
    // this.mm("dispose");
    // console.log(this.mm);
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");

    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }

    btn.addEventListener("click", toggleFunc);
  }

  render() {
    /// Path
    var path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    // function checkPermission(slug) {

    //   if (user_data.role != "superadmin" && user_data.role != "admin") {
    //     var data = role_permission.filter((item) => item.slug === slug);

    //     if (data != "") {
    //       if (data[0].status === "enable") {
    //         return "";
    //       } else {
    //         return "hidden";
    //       }
    //     }
    //   } else {
    //     return "";
    //   }
    // }

    /// Active menu
    //let deshBoard = ["dashboard", "/"];
    let userSetting = ["roles", "users", "view-user"];
    let extensions = ["extensions", "update-extension", "add-extension"];
    let ringGroups = ["ring-groups", "view-ringgroup", "add-ringgroup"];
    let ivrs = ["ivr", "update-ivr", "add-ivr"];
    let businessHours = ["business-hours", "update-business-hours"];
    let recordings = ["recordings", "upload-recording", "update-moh-form"];
    let blockedNumbers = ["blocked-numbers-list"];
    let dncList = ["dnc-list"];
    let callCampaings = [
      "call-campaign",
      "create-call-campaign",
      "update-call-campaign",
      "power-dial-screen",
      "power-dial",
    ];
    let numbers = ["inbound-numbers", "update-number"];
    let cdr = ["cdr"];

    let applications = [
      "dashboard",
      "/",
      "extensions",
      "update-extension",
      "add-extension",
      "did",
      "ring-groups",
      "view-ringgroup",
      "add-ringgroup",
      "recordings",
      "upload-recording",
      "update-moh-form",
      "ivr",
      "update-ivr",
      "add-ivr",
      "call-campaign",
      "create-call-campaign",
      "update-call-campaign",
      "power-dial-screen",
      "power-dial",
      "business-hours",
      "update-business-hours",
      "dnc-list",
      "users",
      "view-user",
      "inbound-numbers",
      "update-number",
      "blocked-numbers-list",
      "cdr",
    ];

    let contacts = [
      "contacts",
      "import-contact",
      "mapping-fields",
      "add-contact",
      "custom-fields",
      "view-contact",
      "update-contact",
      "bulk-update-contact",
      "bulk-assign-contact",
    ];
    let contactGroups = ["contact-group", "update-contact-group"];
    let contactForms = ["contact-custom-form"];
    let crm = [
      "contacts",
      "import-contact",
      "mapping-fields",
      "add-contact",
      "custom-fields",
      "view-contact",
      "update-contact",
      "bulk-update-contact",
      "bulk-assign-contact",
      "contact-group",
      "update-contact-group",
      "contact-custom-form",
    ];

    let smsTemplates = [
      "sms-template",
      "create-sms-template",
      "update-sms-template",
    ];

    let smsCampaigns = [
      "sms-campaign",
      "create-sms-campaign",
      "update-sms-campaign",
    ];
    let smsLogs = ["sms-logs"];
    let smsChat = ["chat-sms"];

    let sms = [
      "sms-campaign",
      "create-sms-campaign",
      "update-sms-campaign",
      "sms-template",
      "create-sms-template",
      "update-sms-template",
      "sms-logs",
      "chat-sms",
    ];
    //let call = ["call-campaign"];

    let inbox = ["email-inbox"];

    let emailTemplate = ["email-template",
    "create-email-template",
    "update-email-template",];

    let emails = ["email-inbox","email-template",
    "create-email-template",
    "update-email-template","email-campaign","create-email-campaign","update-email-campaign","email-campaign-report"];

    let emailCampaign = ["email-campaign","create-email-campaign","update-email-campaign","email-campaign-report"];

    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll deznav-scroll ps--active-x">
          <MM className="metismenu" id="menu">
            {/* <li className={`${path === "" ? "mm-active" : ""}`}>
              <Link className={`${path === "" ? "mm-active" : ""}`} to="/">
                <i className="flaticon-381-networking"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
            </li> */}


            <li
              className={`${applications.includes(path) ? "mm-active" : ""}`}
              hidden={""}
            >
              <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
                <i className="fa fa-phone"></i>
                <span className="nav-text">Calls</span>
              </Link>
              <ul aria-expanded="false">
                <li hidden={PermissionsCheck("call-center-dashboard")}>
                  <Link to="/call-center-dashboard">
                    Call Center Dashboard *
                  </Link>
                </li>
                <li hidden={PermissionsCheck("live-agents")}>
                  <Link to="/live-agent">Live Agents *</Link>
                </li>
                <li hidden={PermissionsCheck("users")}>
                  <Link
                    to="/users"
                    className={`${
                      userSetting.includes(path) ? "mm-active" : ""
                    }`}
                  >
                    Users
                  </Link>
                </li>
                <li hidden={PermissionsCheck("extensions")}>
                  <Link
                    to="extensions"
                    className={`${
                      extensions.includes(path) ? "mm-active" : ""
                    }`}
                  >
                    Extensions
                  </Link>
                </li>
                <li hidden={PermissionsCheck("ring group")}>
                  <Link
                    to="ring-groups"
                    className={`${
                      ringGroups.includes(path) ? "mm-active" : ""
                    }`}
                  >
                    Ring Groups
                  </Link>
                </li>
                <li hidden={PermissionsCheck("did")}>
                  <Link
                    to="inbound-numbers"
                    className={`${numbers.includes(path) ? "mm-active" : ""}`}
                  >
                    Inbound Numbers
                  </Link>
                </li>
                <li hidden={PermissionsCheck("ivr")}>
                  <Link
                    to="ivr"
                    className={`${ivrs.includes(path) ? "mm-active" : ""}`}
                  >
                    Auto-Attendant
                  </Link>
                </li>
                <li hidden={PermissionsCheck("time conditions")}>
                  <Link
                    to="business-hours"
                    className={`${
                      businessHours.includes(path) ? "mm-active" : ""
                    }`}
                  >
                    Time-Based Routing
                  </Link>
                </li>

                <li hidden={PermissionsCheck("audio files")}>
                  <Link
                    to="recordings"
                    className={`${
                      recordings.includes(path) ? "mm-active" : ""
                    }`}
                  >
                    Audio Files
                  </Link>
                </li>
                <li hidden={PermissionsCheck("blocked-numbers")}>
                  <Link
                    to="blocked-numbers-list"
                    className={`${
                      blockedNumbers.includes(path) ? "mm-active" : ""
                    }`}
                  >
                    Blocked Numbers
                  </Link>
                </li>
                <li hidden={PermissionsCheck("dnc-list")}>
                  <Link
                    to="dnc-list"
                    className={`${dncList.includes(path) ? "mm-active" : ""}`}
                  >
                    DNC List
                  </Link>
                </li>
                <li hidden={PermissionsCheck("call-campaign")}>
                  <Link
                    to="call-campaign"
                    className={`${
                      callCampaings.includes(path) ? "mm-active" : ""
                    }`}
                  >
                    Call Campaign
                  </Link>
                </li>
                <li
                  hidden={PermissionsCheck("call-detail-report")}
                  className={`${cdr.includes(path) ? "mm-active" : ""}`}
                >
                  <Link to="cdr">Call Detail Report</Link>
                </li>
              </ul>
            </li>

            <li className={`${sms.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
                <i className="fa fa-comments-o"></i>
                <span className="nav-text">SMS</span>
              </Link>
              <ul aria-expanded="false">
                <li hidden={PermissionsCheck("sms-template")}>
                  <Link
                    to="sms-template"
                    className={`${
                      smsTemplates.includes(path) ? "mm-active" : ""
                    }`}
                  >
                    SMS Template
                  </Link>
                </li>
                <li hidden={PermissionsCheck("sms-campaign")}>
                  <Link
                    to="sms-campaign"
                    className={`${
                      smsCampaigns.includes(path) ? "mm-active" : ""
                    }`}
                  >
                    SMS Campaign
                  </Link>
                </li>
                <li hidden={PermissionsCheck("sms-log")}>
                  <Link
                    to="sms-logs"
                    className={`${smsLogs.includes(path) ? "mm-active" : ""}`}
                  >
                    SMS Logs
                  </Link>
                </li>
                <li hidden={PermissionsCheck("chat-sms")}>
                  <Link
                    to="chat-sms"
                    className={`${smsChat.includes(path) ? "mm-active" : ""}`}
                  >
                    Chat SMS
                  </Link>
                </li>
              </ul>
            </li>

            <li className={`${emails.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
                <i className="fa fa-envelope"></i>
                <span className="nav-text">Email</span>
              </Link>
              <ul aria-expanded="false">

                <li hidden={PermissionsCheck("email")}>
                  <Link
                    to="/email-inbox"
                    className={`${inbox.includes(path) ? "mm-active" : ""}`}
                  >
                    Inbox
                  </Link>
                </li>

                <li hidden={PermissionsCheck("email")}>
                  
                  <Link
                    to="email-template"
                    className={`${
                      emailTemplate.includes(path) ? "mm-active" : ""
                    }`}
                  >
                    Email Template
                  </Link>
                </li>

                <li hidden={PermissionsCheck("email")}>
                  
                  <Link
                    to="email-campaign"
                    className={`${
                      emailCampaign.includes(path) ? "mm-active" : ""
                    }`}
                  >
                    Email Campaigns
                  </Link>
                </li>

                {/* <li hidden={PermissionsCheck("email")}>
                  
                  <Link
                    to="email-campaign-report"
                    className={`${
                      emailCampaign.includes(path) ? "mm-active" : ""
                    }`}
                  >
                    Email Campaign Reports
                  </Link>
                </li> */}

                
              </ul>
            </li>

            {/* <li
              hidden={PermissionsCheck("email")}
              className={`${emails.includes(path) ? "mm-active" : ""}`}
            >
              <Link
                className={`${emails.includes(path) ? "mm-active" : ""}`}
                to="/email-inbox"
              >
                <i className="fa fa-envelope"></i>
                <span className="nav-text">Emails *</span>
              </Link>
            </li> */}

            <li className={`${crm.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
                <i className="fa fa-address-book"></i>
                <span className="nav-text">Contacts</span>
              </Link>
              <ul aria-expanded="false">
                <li hidden={PermissionsCheck("contacts")}>
                  <Link
                    to="contacts"
                    className={`${contacts.includes(path) ? "mm-active" : ""}`}
                  >
                    Contacts
                  </Link>
                </li>
                <li hidden={PermissionsCheck("contact-groups")}>
                  <Link
                    to="contact-group"
                    className={`${
                      contactGroups.includes(path) ? "mm-active" : ""
                    }`}
                  >
                    Contacts Groups
                  </Link>
                </li>
                <li hidden={PermissionsCheck("custom-form")}>
                  <Link
                    to="contact-custom-form"
                    className={`${
                      contactForms.includes(path) ? "mm-active" : ""
                    }`}
                  >
                    Custom Form
                  </Link>
                </li>
                {/* <li hidden={PermissionsCheck("contact-custom-form")}>
                  <Link
                    to="sample-form"
                    className={`${
                      contactForms.includes(path) ? "mm-active" : ""
                    }`}
                  >
                    Sample Form
                  </Link>
                </li> */}
              </ul>
            </li>
            
            <li>
              <Link className="ai-icon" to="logout" aria-expanded="false">
                <i className="fa fa-sign-out"></i>
                <span className="nav-text">Logout</span>
              </Link>
            </li>
          </MM>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
