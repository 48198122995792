import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import PageTitle from "../../layouts/PageTitle";
import FlashMessage from "../../layouts/FlashMessage";
import CheckModulePermisson from "../../common/CheckModulePermisson";
import PermissionCheck from "../../common/PermissionCheck";
import General from "./General";
import Settings from "./Settings";

function BlockedNumbers() {
  const [totalBlockNumbers, setTotalBlockNumbers] = useState([]);

  const activeTab = "general";

  const setTotalBN = (tn) => {
    setTotalBlockNumbers(tn);
  };

  let navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const user_type = user_data.role;
  const module_name = "blocked-numbers";

  let module_per = CheckModulePermisson(module_name);

  let isPermission = true;
  if (module_per === "" || user_type === "admin") {
    isPermission = true;
  } else {
    isPermission = false;
  }

  let listing_per = PermissionCheck(module_name, "listing", "", "", "");

  console.log(isPermission);

  if (isPermission === false) {
    navigate("/error-403");
  }

  if (listing_per === "none") {
    navigate("/error-403");
  }

  const breadCurmList = [
    { label: "Calls", link: "/", is_active: "NO" },
    {
      label: "Blocked Numbers",
      link: "/blocked-numbers-list",
      is_active: "NO",
    },
    {
      label: "Total Amount : " + totalBlockNumbers,
      link: "",
      is_active: "YES",
    },
  ];

  return (
    <Fragment>
      <FlashMessage />
      <PageTitle breadCurmList={breadCurmList} />

      <Tab.Container defaultActiveKey={activeTab}>
        <Nav as="ul" className="nav-tabs mt-4">
          <Nav.Item as="li" key="1">
            <Nav.Link eventKey="general">
              <i className={`la la-tty mr-2`} />
              General
            </Nav.Link>
          </Nav.Item>

          <Nav.Item as="li" key="2">
            <Nav.Link eventKey="settings">
              <i className={`la la-voicemail mr-2`} />
              Settings
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content className="ringgroup bnpage">
          <Tab.Pane eventKey="general" key="1" className="">
            <General setTotalBN={setTotalBN}></General>
          </Tab.Pane>
          <Tab.Pane eventKey="settings" key="2" className="">
            <Settings />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Fragment>
  );
}

export default BlockedNumbers;
