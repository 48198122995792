import axios from "axios";

const ROOM_ENDPOINT = process.env.REACT_APP_DAILY_ROOM_ENDPOINT;
const TOKEN_ENDPOINT = process.env.REACT_APP_DAILY_TOKEN_ENDPOINT;
const DAILY_API_KEY = process.env.REACT_APP_DAILY_API_KEY;

//Daily

const createRoom = (data) => {
  return axios
    .post(
      ROOM_ENDPOINT,
      data,
      {
        headers: {
          Authorization: `Bearer ${DAILY_API_KEY}`,
        },
      }
    )
    .then((response) => {
      return response;
    });
}

const createAccessToken = (data) => {
  return axios
    .post(
      TOKEN_ENDPOINT,
      data,
      {
        headers: {
          Authorization: `Bearer ${DAILY_API_KEY}`,
        },
      }
    )
    .then((response) => {
      return response;
    });
}


export default {
  createRoom,
  createAccessToken,
};
