import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Button,
  Col,
  Row,
  Modal,
  Card,
  ListGroup,
  Tab,
  Nav,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  getContactUserList,
  getInternalChatLog,
  sendInternalMessage,
  getGroupUserDetails,
  getGroupList,
  sendChatFile,
} from "../../actions/internalChat";
import { updateGroupName } from "../../actions/internalchatgroup";
import "./style.css";
import moment from "moment";
import "react-perfect-scrollbar/dist/css/styles.css";
import Loader from "../../layouts/Loader";
import AddGroupForm from "../internalChatGroup/AddGroupForm";
import UpdateGroupForm from "../internalChatGroup/UpdateGroupForm";
import GroupMemberDelete from "../internalChat/groupMemberDelete";
import EmojiPicker from "emoji-picker-react";
import EasyEdit, { Types } from "react-easy-edit";
import FilePreview from "./FilePreview";

function internalChat(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  let domain_name = user_data.domain_name;

  const chat_file_url = process.env.REACT_APP_SERVER_CHAT_URL;
  const common_file_url = process.env.REACT_APP_SERVER_COMMON_URL;
  let file_path = chat_file_url + domain_name;

  const dispatch = useDispatch();

  const [contactList, setContactList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [interChatEvent, setInterChatEvent] = useState("");
  const [groupEvent, setgGroupEvent] = useState("");
  const [userEvent, setgUserEvent] = useState("");
  const fromUuid = createdby;
  const [toUuid, setToUuid] = useState("");
  const [memberUuid, setMemberUuid] = useState("");
  const [groupDelete, setGroupDelete] = useState("");
  const [groupUserData, setGroupUserData] = useState([]);
  const [groupUuid, setGroupUuid] = useState("");
  const [groupData, setGroupData] = useState("");
  const [tab, setTab] = useState("user");

  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState("");
  const [socket, setSocket] = useState(null);
  
  const scrollRef = useRef(null);

  const [errors, setErrors] = useState({
    input: "",
    file: "",
  });

  const [messageData, setMessageData] = useState({
    input: "",
  });

  const [file, setFile] = useState(null);
  const hiddenFileInput = useRef([]);

  const myRef = useRef(null);

  const [showPicker, setShowPicker] = useState(false);

  const WEB_SOCKET_URL = process.env.REACT_APP_SERVER_WEB_SOCKET_URL;
  // const socket = new WebSocket(WEB_SOCKET_URL);

  const closeModel = (value) => {
    props.closeModel(value);
  };

  const [basicModal, setGroupModal] = useState(false);
  const [updateGroupModal, setUpdateGroupModal] = useState(false);
  const [groupDeleteModal, setGroupDeleteModal] = useState(false);

  const closeGroupModel = (value) => {
    setGroupModal(value);
  };

  const closeGroupDeleteModel = (value) => {
    setGroupDeleteModal(value);
  };

  const closeUpdateGroupModel = (value) => {
    setUpdateGroupModal(value);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getContactUserList(main_uuid, createdby, token))
      .then((response) => {
        if (response.data !== "") {
          if (toUuid === "") {
            setToUuid(response.data[0].user_uuid);
            setInterChatEvent(Math.random);
          }
          setContactList(response.data);
        }
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, [userEvent]);

  useEffect(() => {
    setLoading(true);
    dispatch(getGroupList(main_uuid, createdby, token))
      .then((response) => {
        if (response.data !== "") {
          setGroupList(response.data);

          if (tab === "group") {
            if (response.data !== "") {
              if (groupUuid === "") {
                setGroupUuid(response.data[0].internal_chat_group_uuid);
                getGroupMemberListFun(
                  response.data[0].internal_chat_group_uuid,
                  main_uuid
                );
              }
            } else {
              getGroupMemberListFun("", main_uuid);
            }
          }
        } else {
          setGroupList([]);
          setGroupUserData([]);
        }
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, [groupEvent]);

  useEffect(() => {
    // setLoading(true);

    if (toUuid !== "") {
      getChatLog(toUuid, fromUuid, groupUuid, tab);
      setgGroupEvent(Math.random);
      setgUserEvent(Math.random);
      props.handleUserEvent(Math.random);
    }
    // setLoading(false);
  }, [interChatEvent]);

  useEffect(() => {
    // Establishing a WebSocket connection
    const ws = new WebSocket(WEB_SOCKET_URL);

    // Set up event listeners
    ws.onopen = () => {
      console.log("WebSocket connection opened");
    };

    ws.onmessage = (event) => {
      const dataTest = JSON.parse(event.data);

      // let message = "";
      if (dataTest.type === "message" || dataTest.type === "file") {
        
        setInterChatEvent(Math.random);
      }
      setgGroupEvent(Math.random);
      setgUserEvent(Math.random);

      // const message = JSON.parse(event.data);
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed");
    };

    setSocket(ws);

    // Cleanup the WebSocket connection when the component unmounts
    return () => {
      ws.close();
    };
  }, []);

  const addGroupHandler = () => {
    setgGroupEvent(Math.random);
  };

  const getChatLog = (to_uuid, from_uuid, group_uuid, tab_name) => {
    dispatch(
      getInternalChatLog(
        to_uuid,
        from_uuid,
        group_uuid,
        tab_name,
        main_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        setMessages(response.data);
        // setInterChatEvent(Math.random);
        if (scrollRef.current) {
          scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
        if (tab_name === "group") {
          getGroupMemberListFun(group_uuid, main_uuid);
        }

        return;
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getGroupMemberListFun = (group_uuid, main_uuid) => {
    dispatch(getGroupUserDetails(group_uuid, main_uuid, createdby, token))
      .then((response) => {
        if (response.data !== "") {
          setGroupUserData(response.data.groupUserData);
          setGroupData(response.data.groupData);
        } else {
          setGroupUserData([]);
        }
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const sendMessage = () => {
    setLoading(true);
    let error_data = { ...errors };
    let isError = "false";

    if (input === "" && file === null) {
      error_data["input"] = "Please enter any message or select file.";
      setErrors(error_data);
      isError = "true";
    } else {
      error_data["input"] = "";
      setErrors(error_data);
    }

    if (isError === "true") {
      setLoading(false);
      return;
    }

    if (file) {
      const fileData = {
        type: "file",
        file_type: "", // You can replace this with the actual username
        data: "",
        fileName: "",
      };

      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify(fileData));
      }

      const formData = new FormData();

      formData.append("created_at", "");
      formData.append("direction", "");
      formData.append("status", "outbound");
      formData.append("deliver_status", "");
      formData.append("message", input);
      formData.append("from_uuid", fromUuid);
      formData.append("to_uuid", toUuid);
      formData.append("tab", tab);
      formData.append("group_uuid", groupUuid);
      formData.append("createdby", createdby);
      formData.append("main_uuid", main_uuid);
      formData.append("user_uuid", createdby);
      formData.append("domain_name", user_data.domain_name);
      // formData.append("file", file);

      for (let i = 0; i < file.length; i++) {
        formData.append("file", file[i]);
      }

      dispatch(sendChatFile(formData, token))
        .then((response) => {
          setInterChatEvent(Math.random);
          setLoading(false);
          return;
        })
        .catch(() => {
          setLoading(false);
        });
      setFile(null);
    } else {
      const msg = {
        internal_chat_uuid: "",
        created_at: "",
        direction: "",
        status: "outbound",
        deliver_status: "",
        message: input,
        from_uuid: fromUuid,
        to_uuid: toUuid,
        tab: tab,
        group_uuid: groupUuid,
        main_uuid: main_uuid,
        createdby: createdby,
      };

      if (socket.readyState === WebSocket.OPEN) {
        let datad = {
          type: "message",
          data: msg,
        };
        socket.send(JSON.stringify(datad));

        sendMessageFun(msg);
        setLoading(false);
      } else {
        // Queue a retry
        setTimeout(() => {
          handleSend(msg);
        }, 1000);
      }

      // sendMessageFun(msg);
    }
  };

  const handleSend = (msg) => {
    if (socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(msg));
      sendMessageFun(msg);
      setInput("");
    } else if (socket.readyState === WebSocket.CONNECTING) {
      // Wait for the open event, maybe do something with promises
      // depending on your use case. I believe in you developer!
      socket.addEventListener("open", () => handleSend(msg));
    } else {
      // etc.
    }
  };

  const getChatLogBtn = (e) => {
    if (tab === "user") {
      setToUuid(e.target.id);
      getChatLog(e.target.id, fromUuid, groupUuid, tab);
    } else {
      setGroupUuid(e.target.id);
      getChatLog(e.target.id, fromUuid, e.target.id, tab);
    }
    setgGroupEvent(Math.random);
    setgUserEvent(Math.random);
    props.handleUserEvent(Math.random);
  };

  const sendMessageFun = (msg) => {
    setInput("");

    dispatch(sendInternalMessage(msg, token))
      .then((response) => {
        setInterChatEvent(Math.random);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  };

  //file send
  const handleFileChange = (event) => {
    const selectedFile = event.target.files;
    // setFile1(URL.createObjectURL(event.target.files));
    setFile(selectedFile);
  };

  const removeFile = (files) => {
    if (files === "") {
      setFile(null);
    } else {
      setFile(files);
    }
  };

  //file send

  const handleReply = (e) => {
    
    setInput(e.target.value);
    // let msg_val = textboxValue + " " + data.target.value;
    // let msg_val = textboxValue + " " + e.target.value;
  };

  //Emogis
  const handleEmojiPickup = (emo) => {
    const newGroupData = { ...messageData };

    newGroupData["input"] = newGroupData.input + emo.emoji;
    setMessageData(newGroupData);

    const textboxValue = myRef.current.value;
    let text = textboxValue + emo.emoji;
    setInput(text);
  };
  //Emogis

  const clickTabChange = (tabs) => {
    let internal_chat_group_uuid = "";
    if (groupList !== "") {
      internal_chat_group_uuid = groupList[0].internal_chat_group_uuid;
    } else {
      internal_chat_group_uuid = groupUuid;
    }
    if (tabs === "user") {
      setGroupUuid("");
    } else {
      if (groupList !== "") {
        setGroupUuid(groupList[0].internal_chat_group_uuid);
      } else {
        setGroupUuid("");
      }
    }

    setTab(tabs);

    getChatLog(toUuid, fromUuid, internal_chat_group_uuid, tabs);
  };

  const fnDeleteGroupMember = (uuid) => {
    setGroupDelete("member");
    setMemberUuid(uuid);
    setGroupDeleteModal(true);
  };

  const deleteGroupHandler = (event) => {
    setgGroupEvent(Math.random);
  };

  const fnDeleteGroup = (uuid) => {
    setGroupDelete("all");
    setGroupDeleteModal(true);
  };

  const tabData = [
    {
      name: "Users",
      icon: "user",
      tab: "user",
    },
    {
      name: "Groups",
      icon: "users",
      tab: "group",
    },
  ];

  const save = (value, attribute) => {
    dispatch(updateGroupName(value, groupUuid, main_uuid, createdby, token))
      .then((response) => {
        setgGroupEvent(Math.random);
        setgUserEvent(Math.random);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  function getExtension(filename, ori_name) {
    let string_arr = filename.split(",");

    let arr_len = string_arr.length;
    if (arr_len === 1) {
      let file_ext = filename.split(".").pop();

      let file_name = "";
      if (file_ext === "pdf") {
        file_name = common_file_url + "/pdf_demo.png";
      } else if (file_ext === "doc" || file_ext === "docx") {
        file_name = common_file_url + "/doc_demo.png";
      } else if (file_ext === "csv") {
        file_name = common_file_url + "/csv_demo.png";
      } else if (
        file_ext === "jpeg" ||
        file_ext === "jpg" ||
        file_ext === "png" ||
        file_ext === "gif" ||
        file_ext === "tiff" ||
        file_ext === "psd" ||
        file_ext === "esp" ||
        file_ext === "ai" ||
        file_ext === "add" ||
        file_ext === "raw"
      ) {
        file_name = filename;
        file_name = file_path + "/" + file_name;
      } else {
        file_name = common_file_url + "/others_file.png";
      }

      return (
        <>
          <a href={file_path + "/" + filename} target="_blank" rel="noopener noreferrer">
            <img
              src={file_name}
              alt="PDF Icon"
              className="pdf-icon"
              width="100"
              height="80"
            />
            <p> {ori_name}</p>
          </a>
        </>
      );
    } else {
      let ori_name_arr = ori_name.split(",");

      // Merge arrays into an object
      const mergedObject = Object.fromEntries(
        string_arr.map((key, index) => [key, ori_name_arr[index]])
      );

      const listItems = [];

      const objectArray = Object.entries(mergedObject);

      objectArray.forEach(([file, value]) => {
        let file_ext = file.split(".").pop();

        let file_name = "";
        if (file_ext === "pdf") {
          file_name = common_file_url + "/pdf_demo.png";
        } else if (file_ext === "doc" || file_ext === "docx") {
          file_name = common_file_url + "/doc_demo.png";
        } else if (file_ext === "csv") {
          file_name = common_file_url + "/csv_demo.png";
        } else if (
          file_ext === "jpeg" ||
          file_ext === "jpg" ||
          file_ext === "png" ||
          file_ext === "gif" ||
          file_ext === "tiff" ||
          file_ext === "psd" ||
          file_ext === "esp" ||
          file_ext === "ai" ||
          file_ext === "add" ||
          file_ext === "raw"
        ) {
          file_name = file;
          file_name = file_path + "/" + file;
        } else {
          file_name = common_file_url + "/others_file.png";
        }

        listItems.push(
          <div className="send_img">
            <a href={file_path + "/" + file} target="_blank" rel="noopener noreferrer">
              <img
                src={file_name}
                alt="PDF Icon"
                className="pdf-icon mt-2"
                width="150"
                height="80"
              />
              <p> {value}</p>
            </a>
          </div>
        );
      });

      return listItems;
    }
  }

  return (
    <Fragment>
      {loading && <Loader />}

      <Modal.Header className="interchat_header">
        <Modal.Title>Internal Chat</Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>

      <Modal.Body>
        {loading && <Loader />}

        <div className="card-header">
          <h2 className="card-title">
            {user_data.first_name + " - " + user_data.last_name}
          </h2>

          <Button
            variant="primary"
            className="btn btn-sm btn-primary mr-2"
            onClick={() => setGroupModal(true)}
          >
            Create Group
          </Button>
        </div>
        <div className="animated fadeIn">
          <Row>
            <Col lg={3}>
              <Card>
                <Card.Body>
                  {/* <!-- Nav tabs --> */}
                  <div className="custom-tab-1">
                    <Tab.Container defaultActiveKey={tab}>
                      <Nav as="ul" className="nav-tabs">
                        {tabData.map((data, i) => (
                          <Nav.Item as="li" key={i}>
                            <Nav.Link
                              eventKey={data.tab}
                              onClick={() => clickTabChange(data.tab)}
                            >
                              <i className={`la la-${data.icon} mr-2`} />
                              {data.name}
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                      <Tab.Content className="pt-4">
                        <ListGroup as="ul">
                          {tab === "user" ? (
                            <>
                              {contactList &&
                                contactList.map((list, i) => (
                                  <Fragment key={i}>
                                    {list.user_uuid === toUuid ? (
                                      <ListGroup.Item
                                        as="li"
                                        className="badge badge-primary light mb-2"
                                        value={list.user_uuid}
                                        id={list.user_uuid}
                                        onClick={getChatLogBtn}
                                      >
                                        {list.first_name +
                                          " " +
                                          list.last_name +
                                          " - " +
                                          list.extension}
                                        {list.message_count > 0 ? (
                                          <span
                                            href=""
                                            className="pull-right badge badge_clr badge-circle"
                                          >
                                            {list.message_count}
                                          </span>
                                        ) : (
                                          <></>
                                        )}
                                      </ListGroup.Item>
                                    ) : (
                                      <ListGroup.Item
                                        as="li"
                                        className="badge badge-dark light mb-2"
                                        value={list.user_uuid}
                                        id={list.user_uuid}
                                        onClick={getChatLogBtn}
                                      >
                                        {list.first_name +
                                          " " +
                                          list.last_name +
                                          " - " +
                                          list.extension}
                                        {list.message_count > 0 ? (
                                          <span
                                            href=""
                                            className="pull-right badge badge_clr badge-circle"
                                          >
                                            {list.message_count}
                                          </span>
                                        ) : (
                                          <></>
                                        )}
                                      </ListGroup.Item>
                                    )}
                                  </Fragment>
                                ))}
                            </>
                          ) : (
                            <>
                              {groupList &&
                                groupList.map((list1, i) => (
                                  <Fragment key={i}>
                                    {list1.internal_chat_group_uuid ===
                                    groupUuid ? (
                                      <ListGroup.Item
                                        as="li"
                                        className="badge badge-primary light mb-2"
                                        value={list1.internal_chat_group_uuid}
                                        id={list1.internal_chat_group_uuid}
                                        onClick={getChatLogBtn}
                                      >
                                        {list1.name}
                                        {list1.message_count > 0 ? (
                                          <span
                                            href=""
                                            className="pull-right badge badge_clr badge-circle"
                                          >
                                            {list1.message_count}
                                          </span>
                                        ) : (
                                          <></>
                                        )}
                                      </ListGroup.Item>
                                    ) : (
                                      <ListGroup.Item
                                        as="li"
                                        className="badge badge-dark light mb-2"
                                        value={list1.internal_chat_group_uuid}
                                        id={list1.internal_chat_group_uuid}
                                        onClick={getChatLogBtn}
                                      >
                                        {list1.name}
                                        {list1.message_count > 0 ? (
                                          <span
                                            href=""
                                            className="pull-right badge badge_clr badge-circle"
                                          >
                                            {list1.message_count}
                                          </span>
                                        ) : (
                                          <></>
                                        )}
                                      </ListGroup.Item>
                                    )}
                                  </Fragment>
                                ))}
                            </>
                          )}
                        </ListGroup>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={9}>
              <div className="card">
                <div className="card-header pb-0 border-bottom-0"></div>

                <div className="card-body">
                  <div className="row mt-2">
                    {tab === "group" && groupUserData ? (
                      <div className="tags-container">
                        <span className="mr-2"> Group member :</span>
                        {groupUserData &&
                          groupUserData.map((chat, index) => (
                            <span className="badge bgl-info mr-1" key={index}>
                              <span className="text-dark">
                                {" "}
                                {chat.username}
                              </span>
                              <button
                                type="button"
                                onClick={() =>
                                  fnDeleteGroupMember(chat.user_uuid)
                                }
                                className="close ml-2 chat_btn_clr"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </span>
                          ))}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="row mt-2">
                    <div className="col-sm-3">
                      <span className="card-title">Conversations</span>
                      {tab === "group" && groupUserData ? ":" : <></>}
                    </div>
                    <div className="col-sm-7">
                      {tab === "group" && groupUserData ? (
                        <EasyEdit
                          type={Types.TEXT}
                          onSave={(value) => {
                            save(value, "name");
                          }}
                          //onCancel={cancel}
                          saveButtonLabel={
                            <i className="fa fa-check" aria-hidden="true"></i>
                          }
                          saveButtonStyle="label-primary easy-edit-button rounded-lg"
                          cancelButtonLabel={
                            <i className="fa fa-times" aria-hidden="true"></i>
                          }
                          cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                          attributes={{
                            name: "name",
                            className: "form-control h-75",
                          }}
                          //viewAttributes={{className: 'fs-20'}}
                          onValidate={(value) => {
                            return value ? true : false;
                          }}
                          validationMessage="Name is required."
                          placeholder="Group Name"
                          value={groupData.name}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    {tab === "group" && groupList !== "" ? (
                      <div className="col-sm-2">
                        <Button
                          variant="primary"
                          className="btn btn-sm btn-primary mr-2"
                          onClick={() => setUpdateGroupModal(true)}
                        >
                          <i className="fa fa-plus"></i>
                        </Button>
                        <Button
                          className="btn btn-sm btn-danger"
                          onClick={() => fnDeleteGroup(groupUuid)}
                        >
                          <i className="fa fa-trash"></i>
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="demo-app-calendar" id="mycalendartest">
                    {/* <PerfectScrollbar
                      containerRef={(ref) => (scrollRef.current = ref)}
                      style={{ height: "350px" }}
                      className={`card-body msg_card_body dz-scroll  `}
                      id="DZ_W_Contacts_Body3"
                    > */}
                      <div
                      // style={{
                      //   height: "500px",
                      //   width: "100%",
                      // }}
                      className="scroll-container"
                      >
                        {messages.map((chat, index) => (
                          <div key={index}>
                            <div
                              className={
                                chat.from_uuid !== fromUuid
                                  ? "d-flex justify-content-start mb-4"
                                  : "d-flex justify-content-end mb-4"
                              }
                            >
                              <div className="img_cont_msg"></div>
                              <div
                                className={
                                  chat.from_uuid !== fromUuid
                                    ? "ic_cotainer"
                                    : "ic_cotainer_send"
                                }
                              >
                                <span
                                  className={
                                    chat.from_uuid !== fromUuid
                                      ? "msg_time_msg"
                                      : "msg_time_send_msg"
                                  }
                                >
                                  {chat.message}
                                </span>

                                <span
                                  className={
                                    chat.from_uuid !== fromUuid
                                      ? "msg_time_file"
                                      : "msg_time_send_file"
                                  }
                                >
                                  {chat.file_data !== null ? (
                                    getExtension(
                                      chat.file_data,
                                      chat.original_file_name
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </span>
                                <span
                                  className={
                                    chat.from_uuid !== fromUuid
                                      ? "ic_time"
                                      : "chat_time_send"
                                  }
                                >
                                  {moment(chat.created_at).format(
                                    "DD/MM/YYYY h:mm A"
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    {/* </PerfectScrollbar> */}

                    <div className="card-footer type_msg">
                      {file && (
                        <FilePreview files={file} removeFile={removeFile} />
                      )}
                      <div className="row">
                        <div className="col-10">
                          <div className="input-group">
                            <textarea
                              className="form-control reply_text"
                              placeholder="Type your message..."
                              name="message"
                              ref={myRef}
                              onChange={handleReply}
                              value={input}
                            ></textarea>

                            <div>
                              {showPicker && (
                                <EmojiPicker onEmojiClick={handleEmojiPickup} />
                              )}

                              <div className="">
                                {/* <button
                              className="btn btn-sm btn-rounded btn-outline-success ml-2 position-relative emoji_file_btn"
                              onClick={(e) => handleClick(e)}
                            >
                              <i
                                className="fa fa-paperclip"
                                aria-hidden="true"
                              ></i>
                            </button> */}
                              </div>
                            </div>
                            <div className="input-group-append reply_text ">
                              <button
                                onClick={sendMessage}
                                className="btn btn-primary interchat_send_btn"
                              >
                                <i className="fa fa-location-arrow arrow_icon"></i>
                              </button>
                              {/* <img
                            className="emoji-icon emoji_file_btn"
                            src={common_file_url + "smile_emoji.png"}
                            onClick={() => setShowPicker((val) => !val)}
                          /> */}
                            </div>
                          </div>
                        </div>
                        <div className="col-2">
                          <i
                            className="fa fa-smile-o mr-1 emojis_icon"
                            onClick={() => setShowPicker((val) => !val)}
                            aria-hidden="true"
                          ></i>

                          <input
                            type="file"
                            onChange={handleFileChange}
                            ref={(el) => (hiddenFileInput.current = el)}
                            style={{ display: "none" }}
                            multiple
                          />
                          <i
                            className="fa fa-paperclip paper_icon ml-2"
                            aria-hidden="true"
                            onClick={(e) => handleClick(e)}
                          ></i>
                        </div>
                      </div>
                      {/* <div className="ic_header_left">
                        <div className="search_bar">
                          <div className="p-0 m-0 show">
                            <form onSubmit={(e) => e.preventDefault()}>
                              <input
                                className="form-control reply_text"
                                type="search"
                                placeholder="Search Here"
                                aria-label="Search"
                              />
                            </form>
                          </div>
                          <span
                            className="search_icon p-3 c-pointer"
                            data-toggle="dropdown"
                          >
                            <i
                              className="fa fa-smile-o mr-1 emojis_icon"
                              onClick={() => setShowPicker((val) => !val)}
                              aria-hidden="true"
                            ></i>
                            <input
                              type="file"
                              onChange={handleFileChange}
                              ref={(el) => (hiddenFileInput.current = el)}
                              style={{ display: "none" }}
                              multiple
                            />
                            <i
                              className="fa fa-paperclip"
                              aria-hidden="true"
                              onClick={(e) => handleClick(e)}
                            ></i>
                          </span>
                        </div>
                      </div> */}

                      <span className="text-danger">{errors.input}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => closeModel(false)} variant="danger light btn-sm">
          Close
        </Button>
      </Modal.Footer>

      <Modal className="fade bd-example-modal-lg" show={basicModal} size="lg">
        <AddGroupForm
          closeModel={closeGroupModel}
          handleGroupEvent={addGroupHandler}
        />
      </Modal>

      <Modal className="fade bd-example-modal" show={updateGroupModal}>
        <UpdateGroupForm
          closeModel={closeUpdateGroupModel}
          groupUuid={groupUuid}
          handleGroupEvent={addGroupHandler}
        />
      </Modal>

      <Modal
        className="fade bd-example-modal-sm"
        show={groupDeleteModal}
        size="sm"
      >
        <GroupMemberDelete
          closeModel={closeGroupDeleteModel}
          memberUuid={memberUuid}
          groupUuid={groupUuid}
          groupDelete={groupDelete}
          handleGroupDeleteEvent={deleteGroupHandler}
        />
      </Modal>
    </Fragment>
  );
}

export default internalChat;
