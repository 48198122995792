import React, { Fragment, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getDestinationTypeList } from "../../actions/ivr";
import "./style.css";
import moment from "moment";
import Loader from "../../layouts/Loader";

function TimeSlotForm(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;

  let [timeslotData, setTimeslotData] = useState({
    id: "",
    time_condition_data_uuid: props.time_condition_data_uuid,
    time_condition_uuid: props.time_condition_uuid,
    time_slot_name: "",
    from_time: "",
    to_time: "",
    route_to: "",
    route_type: "",
    schedule_type: "weekly",
    token: token,
    createdby: createdby,
    main_admin_uuid: createdby,
    user_uuid: createdby,
  });

  const [errors, setErrors] = useState({
    time_slot_name: "",
    from_time: "",
    to_time: "",
    route_to: "",
    route_type: "",
  });

  const [destinationType, setDestinationType] = useState(
    props.destinationTypeOption
  );

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const date = "2023-04-04";

  const handleBlur = (e) => {
    const newTimeslotData = { ...timeslotData };
    let error_data = { ...errors };

    let from_time = "";
    let to_time = "";

    if (e.target.name === "from_time") {
      to_time = timeslotData.to_time;
      from_time = timeConvert24(e.target.value);
    }

    if (e.target.name === "to_time") {
      from_time = timeslotData.from_time;
      to_time = timeConvert24(e.target.value);
    }

    if (e.target.name === "time_slot_name" && e.target.value.length === 0) {
      error_data[e.target.name] = "Name is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }

    if (
      e.target.name === "time_condition_data_uuid" &&
      e.target.value.length === 0
    ) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }

    let f_date = from_time;
    let t_date = to_time;

    if (e.target.name === "from_time" && from_time.length === 0) {
      error_data[e.target.name] = "From time is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }

    if (e.target.name === "to_time" && to_time.length === 0) {
      error_data[e.target.name] = "To time is required.";
      setErrors(error_data);
    }

    if (e.target.name === "to_time" && to_time.length > 0) {
      const date1 = new Date(date + " " + f_date);
      const date2 = new Date(date + " " + t_date);
      if (date1.getTime() > date2.getTime()) {
        error_data[e.target.name] = "To time should be greater then from time.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "route_type" && e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      // error_data[e.target.name] = "";
      // setErrors(error_data);
    }

    if (e.target.name === "route_type" && e.target.value.length !== 0) {
      const main_uuid = user_data.main_uuid;

      dispatch(
        getDestinationTypeList(createdby, token, main_uuid, e.target.value)
      )
        .then((response) => {
          setDestinationType(response.data);
          newTimeslotData["route_to"] = response.data[0].value;
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    }

    if (e.target.name === "route_to" && e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      // error_data[e.target.name] = "";
      // setErrors(error_data);
    }

    newTimeslotData[e.target.name] = e.target.value;
    setTimeslotData(newTimeslotData);
  };

  const saveTimeBlock = () => {
    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (timeslotData.from_time.length === 0) {
      error_data["from_time"] = "From time is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (timeslotData.time_slot_name.length === 0) {
      error_data["time_slot_name"] = "Name is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (timeslotData.to_time.length === 0) {
      error_data["to_time"] = "To time is required.";
      setErrors(error_data);
      isError = "true";
    }

    // We initialize a past date

    let f_date = timeslotData.from_time;
    let t_date = timeslotData.to_time;

    const date1 = new Date(date + " " + f_date);
    const date2 = new Date(date + " " + t_date);
    // Let's see if the first date is equal, more recent or less recent than the second date
    if (date1.getTime() > date2.getTime()) {
      error_data["to_time"] = "To time should be greater then from time.";
      setErrors(error_data);
      isError = "true";
    }

    if (timeslotData.route_type.length === 0) {
      error_data["route_type"] = "Route type is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (timeslotData.route_to.length === 0) {
      error_data["route_to"] = "Route to is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {

      //call create time slot API
      props.createAvailTimeSlot(timeslotData);
      setLoading(false);
    }
  };

  let options = destinationType;

  const times = [];
  for (let i = 0; i < 24; i++) {
    times.push(
      `${i % 12 || 12}:00 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:15 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:30 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:45 ${i < 12 ? "AM" : "PM"}`
    );
  }

  function timeConvert24(time) {
    return moment(time, ["h:mm A"]).format("HH:mm");
  }

  return (
    <Fragment>
      {loading && <Loader />}
        <div className="form-row"> 
          <div className="col mt-2 mt-sm-0">
            <div className="form-group input-primary">
              <label className="mb-1">
                <strong>Name</strong>
                <span className="text-danger">*</span>
              </label>

              <input
                type="text"
                className="form-control bg-white time_field_h"
                placeholder="Enter a name"
                name="time_slot_name"
                defaultValue={timeslotData.time_slot_name}
                onChange={handleBlur}
              />

              <span className="text-danger">{errors.time_slot_name}</span>
            </div>
          </div>

          <div className="col mt-2 mt-sm-0">
            <div className="form-group input-primary">
              <label className="mb-1">
                <strong>From Time</strong>
                <span className="text-danger">*</span>
              </label>

              <select
                className="form-control bg-white time_field_h pb-0 pt-0"
                name="from_time"
                onChange={handleBlur}
                value={timeslotData.from_time}
              >
                <option value="">Select From Time</option>
                {times &&
                  times.map((number) => (
                    <option key={number} value={number}>
                      {number}
                    </option>
                  ))}
              </select>

              <span className="text-danger">{errors.from_time}</span>
            </div>
          </div>

          <div className="col mt-2 mt-sm-0">
            <div className="form-group input-primary">
              <label className="mb-1">
                <strong>To Time</strong>
                <span className="text-danger">*</span>
              </label>

              <select
                className="form-control bg-white time_field_h pb-0 pt-0"
                name="to_time"
                onChange={handleBlur}
                value={timeslotData.to_time}
              >
                <option value="">Select To Time</option>
                {times &&
                  times.map((number) => (
                    <option key={number} value={number}>
                      {number}
                    </option>
                  ))}
              </select>

              <span className="text-danger">{errors.to_time}</span>
            </div>
          </div>

          <div className="col mt-2 mt-sm-0">
            <div className="form-group input-primary">
              <label>
                <strong>Route Type</strong>
              </label>
              <span className="text-danger">*</span>
              <select
                className="form-control bg-white time_field_h pb-0 pt-0"
                name="route_type"
                onChange={handleBlur}
                value={timeslotData.route_type}
              >
                <option value="">Select Route Type</option>
                <option value="ring-group">Ring Group</option>
                <option value="extention">Extention</option>
                <option value="did">Did</option>
                <option value="voicemail">Voicemail</option>
              </select>
              <span className="text-danger">{errors.route_type}</span>
            </div>
          </div>

          <div className="col mt-2 mt-sm-0">
            <div className="form-group input-primary">
              <label>
                <strong>Route To</strong>
              </label>
              <span className="text-danger">*</span>
              <select
                className="form-control bg-white time_field_h pb-0 pt-0"
                name="route_to"
                onChange={handleBlur}
                value={timeslotData.route_to}
              >
                <option value="">Select Route To</option>
                {options &&
                  options.map((number) => (
                    <option key={number.value} value={number.value}>
                      {number.text}
                    </option>
                  ))}
              </select>
              <span className="text-danger">{errors.route_to}</span>
            </div>
          </div>

          <div className="mt-4 text-center p-1">
            <Button
              type="button"
              // name="submit"
              onClick={saveTimeBlock}
              variant="primary btn-sm mt-1"
            >
              Save
            </Button>
          </div>

        </div>
    </Fragment>
  );
}

export default TimeSlotForm;
