import React from "react";


const History = ({
  toggleTab,
  PerfectScrollbar,
  toggleChatBox,
  isDialpadShow,
  contact,
}) => {
  //let contact_uuid = contact.contact_uuid;
  // const user = JSON.parse(localStorage.getItem("user"));
  // const user_data = user.data.data;


  return (
    <div
      className={`tab-pane fade  ${
        toggleTab === "history" ? "active show" : ""
      }`}
      id="history"
      role="tabpanel"
    >
      <div className="card mb-sm-3 mb-md-0 contacts_card">
        <PerfectScrollbar
          className={`card-body  p-0 dz-scroll  ${
            toggleChatBox ? "ps ps--active-y" : ""
          }${isDialpadShow === "YES" ? "contacts_body_new" : "contacts_body"}`}
          id="DZ_W_Contacts_Body1"
          //style={{height: isDialpadShow == 'YES' ? '155px' : '570px'}}
        >
          {/* <ul className="contacts">
            <li className="name-first-letter">Call History</li>
          </ul> */}

          <div className="callHistory clearfix">
              <ul>
                <li className="clearfix">
                  <div className="float-left w-50">
                    <span>11:15 Am Sept' 2023</span>
                    <span><i className="fa fa-arrow-right greenColor"></i>Incoming</span>
                  </div>
                  <div className="float-right w-50 text-right">
                    <span>Interested</span>
                    <span className="time-mins">02:30 mins</span>
                  </div>
                </li>
                <li className="clearfix">
                  <div className="float-left w-50">
                    <span>11:15 Am Sept' 2023</span>
                    <span><i className="fa fa-arrow-left redColor"></i>Outgoing</span>
                  </div>
                  <div className="float-right w-50 text-right">
                    <span>Interested</span>
                    <span className="time-mins">02:30 mins</span>
                  </div>
                </li>
                <li className="clearfix">
                  <div className="float-left w-50">
                    <span>11:15 Am Sept' 2023</span>
                    <span><i className="fa fa-arrow-right greenColor"></i>Incoming</span>
                  </div>
                  <div className="float-right w-50 text-right">
                    <span>Interested</span>
                    <span className="time-mins">02:30 mins</span>
                  </div>
                </li>
                <li className="clearfix">
                  <div className="float-left w-50">
                    <span>11:15 Am Sept' 2023</span>
                    <span><i className="fa fa-arrow-left redColor"></i>Outgoing</span>
                  </div>
                  <div className="float-right w-50 text-right">
                    <span>Interested</span>
                    <span className="time-mins">02:30 mins</span>
                  </div>
                </li>
              </ul>
            </div>

          {/* <div className="card-footer"></div> */}
        </PerfectScrollbar>
      </div>
    </div>
  );
};

export default History;
