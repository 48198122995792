import React, { Fragment, useState, useEffect } from "react";
import { Button, Table, DropdownButton, Dropdown,ButtonGroup,Modal } from "react-bootstrap";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";

import {
  updateContactGroup,
  getContactGroupDetail,
  removeGroupContact,
  moveGroupContact,
} from "../../../actions/contactgroup";
import { notifyInfo, notifyError } from "../../Toastr/Toastr";
import PageTitle from "../../../layouts/PageTitle";
import Pagination from "../../../layouts/Pagination";
import Loader from "../../../layouts/Loader";
import MoveContactForm from "./MoveContactForm";
import AddContactForm from "./AddContactForm";


function UpdateContactGroupForm(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const params = useLocation();
  const contact_group_uuid = params.state.contact_group_uuid;

  const [pageCount, setpageCount] = useState(0);
  const [offSet, setOffSet] = useState(0);
  const [totalContacts, setTotalContacts] = useState(0);
  const [limits, setLimits] = useState(10);
  const [moveContactModel, setMoveContactModel] = useState(false);
  const [addContactModel, setAddContactModel] = useState(false);

  const [selectedContacts, setSelectedContacts] = useState({
    selectd_contacts: [],
    checked: 0,
    select_type: "none",
  });

  const [groupContacts, setGroupContacts] = useState([]);
  const [groupContactEvent, setGroupContactEvent] = useState("");
  const [groupData, setGroupData] = useState({
    uuid: "",
    group_name: "",
    contacts: groupContacts,
    token: token,
    createdby: createdby,
  });

  const [errors, setErrors] = useState({
    group_name: "",
  });

  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBlur = (e) => {
    const newGroupData = { ...groupData };
    let error_data = { ...errors };
    if (e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }
    newGroupData[e.target.name] = e.target.value;
    setGroupData(newGroupData);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getContactGroupDetail(createdby, token, contact_group_uuid, limits, offSet))
      .then((response) => {
        const newarr = { ...groupData };

        newarr["uuid"] = response.data.data[0].uuid;
        newarr["group_name"] = response.data.data[0].group_name;
        setGroupData(newarr);

        const total = response.data.total;
        setTotalContacts(total);
        setpageCount(Math.ceil(total / limits));

        setGroupContacts(response.data.data);
        setLoading(false);

        if (selectedContacts.select_type === "all") {
          SelectAll("all");
        }

        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, [groupContactEvent,limits]);

  const handlePageClick = async (data) => {

    setOffSet(limits * data.selected);
    setGroupContactEvent(Math.random);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (groupData.group_name.length === 0) {
      error_data["group_name"] = "Group Name is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create user API
      const data = { ...groupData };
      setLoading(true);
      dispatch(updateContactGroup(data))
        .then((res) => {
          if (res.status === 200) {
            notifyInfo(res.message);
            navigate("/contact-group", { replace: true });
          } else if (res.status === 406) {
            error_data["name"] = res.message;
            setErrors(error_data);
            isError = "true";
          } else if (res.status === 500) {
            notifyError("Something went wrong.");
          } else {
            notifyError("Something went wrong.");
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const fnDeleteContact = (uuid) => {
    confirmAlert({
      title: "",
      message: "Are you sure to remove this contact?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteContactService(uuid),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteContactService = (uuid) => {
    setLoading(true);
    dispatch(removeGroupContact(uuid, createdby, token))
      .then((response) => {
        if (response.status === 200) {
          notifyInfo(response.message);
          setGroupContactEvent(Math.random);
          return;
        } else if (response.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const SelectAll = (type) => {
    let contact_uuids = [];

      groupContacts.map(
        (contact) => (contact_uuids = [...contact_uuids, contact.contact_uuid])
      );

    const newarr = [];

    newarr["selectd_contacts"] = contact_uuids;
    newarr["checked"] = type === "all" ? totalContacts : contact_uuids.length;
    newarr["select_type"] = type;

    setSelectedContacts(newarr);

    return;
  };

  const DeSelectAll = () => {
    const newarr = [];
    newarr["selectd_contacts"] = [];
    newarr["checked"] = 0;
    newarr["select_type"] = "none";

    setSelectedContacts(newarr);
  };

  const checkContact = (e, contact_uuid) => {
    const newarr = [];

    if (e.target.checked) {
      //checked : add element
      const newdata = [...selectedContacts.selectd_contacts, contact_uuid];
      newarr["selectd_contacts"] = newdata;
      newarr["checked"] = newdata.length;
    } else {
      //uncheck : remove element
      const newdata = selectedContacts.selectd_contacts.filter(
        (arr) => arr !== contact_uuid
      );

      newarr["selectd_contacts"] = newdata;
      newarr["checked"] = newdata.length;
    }

    newarr["select_type"] = "none";

    setSelectedContacts(newarr);
  };

  const chnageLimit = (value) => {
    setLimits(value);
  }

  const handleMoveContactForm = (value) => {
    setMoveContactModel(value);
  }

  const fnMoveContact = (arg) => {
    let data = {
      old_group_uuid : groupData.uuid,
      new_group_uuid : arg.group_uuid,
      main_uuid : main_uuid,
      createdby : createdby,
      contacts : selectedContacts.selectd_contacts,
    }

    setLoading(true);
    dispatch(moveGroupContact(data, token))
      .then((response) => {
        if (response.status === 200) {
          notifyInfo(response.message);
          setGroupContactEvent(Math.random);
          return;
        } else if (response.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });

  }

  const handleAddContactForm = (value) => {
    setAddContactModel(value);
  }

  const handleGroupEvent = (value) => {
    setGroupContactEvent(value);
  }

  

  const breadCurmList = [
    { label: "Contact Group", link: "/contact-group", is_active: "NO" },
    { label: "Update Contact Group : "+groupData.group_name, link: "", is_active: "YES" },
  ];

  return (
    <Fragment>
      <PageTitle breadCurmList={breadCurmList} />
      {loading && <Loader />}

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                Update Group - {groupData.group_name}
              </h4>
              <Button
                type="button"
                className="btn btn-default btn-sm"
                onClick={() => navigate("/contact-group", { replace: true })}
              >
                <i className="fa fa-reply" aria-hidden="true"></i> Back
              </Button>
            </div>
            <div className="card-body">
              <form action="" onSubmit={(e) => submitHandler(e)}>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group input-primary">
                      <label className="mb-1">
                        <strong>Name</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control bg-white"
                        placeholder="Name"
                        name="group_name"
                        id="group_name"
                        value={groupData.group_name}
                        onChange={handleBlur}
                      />
                      <span className="text-danger">{errors.group_name}</span>
                    </div>
                  </div>
                </div>

                <div className="row text-right">
                  <div className="col-sm">
                    <Button type="submit" variant="primary" className="btn-sm">
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </div>

            <div className="card-header">
              <h4 className="card-title">Group Contacts 
                {selectedContacts.checked > 0 && (
                  <Button type="button" onClick={() => handleMoveContactForm(true)} variant="info" className="btn-sm ml-2">
                      Move Contact
                  </Button>
                )}  

                  <Button type="button" onClick={() => handleAddContactForm(true)} variant="success" className="btn-sm ml-2">
                      Add Contact To group
                  </Button>
              </h4>              
            </div>

            <div className="card-body">
            Show
              <ButtonGroup className="ml-2 mr-2">
                <DropdownButton
                  as={ButtonGroup}
                  id="bg-nested-dropdown"
                  size="sm"
                  title={limits}
                  variant="custom-dropdown"
                >
                  <Dropdown.Item
                    eventKey="1"
                    href="#"
                    onClick={() => chnageLimit(10)}
                  >
                    10
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="1"
                    href="#"
                    onClick={() => chnageLimit(25)}
                  >
                    25
                  </Dropdown.Item>

                  <Dropdown.Item
                    eventKey="1"
                    href="#"
                    onClick={() => chnageLimit(50)}
                  >
                    50
                  </Dropdown.Item>

                  <Dropdown.Item
                    eventKey="1"
                    href="#"
                    onClick={() => chnageLimit(100)}
                  >
                    100
                  </Dropdown.Item>
                  
                </DropdownButton>
              </ButtonGroup>
              entries
              <Table responsive className="w-100">
                <thead>
                  <tr role="row">
                    <th>
                      <DropdownButton
                        title=""
                        id="bg-nested-dropdown"
                        size="sm"
                        variant="btn btn-outline-light"
                      >
                        <Dropdown.Item
                          eventKey="1"
                          href="#"
                          onClick={() => SelectAll("page")}
                        >
                          Select This Page
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="2"
                          href="#"
                          onClick={() => SelectAll("all")}
                        >
                          Select All
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="3"
                          href="#"
                          onClick={() => DeSelectAll()}
                        >
                          DeSelect All
                        </Dropdown.Item>
                      </DropdownButton>
                    </th>
                    <th>Type</th>
                    <th>Bussiness Name</th>
                    <th>Title</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Work Contact</th>
                    
                  </tr>
                </thead>
                <tbody>
                  {groupContacts.map((contact) => (
                    <tr key={contact.contact_uuid}>
                      <td>
                        <div className="form-check mb-2">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={selectedContacts.selectd_contacts.includes(
                              contact.contact_uuid
                            )}
                            onChange={(e) =>
                              checkContact(e, contact.contact_uuid)
                            }
                          />
                          <label className="form-check-label"></label>
                        </div>
                      </td>
                      <td>{contact.contact_type}</td>
                      <td>{contact.bussiness_name}</td>
                      <td>{contact.contact_title}</td>
                      <td>
                        <Link
                          to="/view-contact"
                          state={{
                            contact_uuid: contact.contact_uuid,
                            group_uuid: contact.group_uuid,
                            user_created_by: contact.user_created_by,
                          }}
                          className="text-success"
                        >
                          {contact.first_name} {contact.last_name}
                        </Link>
                      </td>
                      <td>{contact.email}</td>
                      <td>{contact.work_contact_number}</td>
                      
                      <td>
                          <Button
                            to="#"
                            className="btn btn-danger shadow btn-xs sharp"
                            onClick={() => fnDeleteContact(contact.contact_group_detail_uuid)}
                            // hidden={PermissionCheck(
                            //   module_name,
                            //   "delete",
                            //   group.uuid,
                            //   group.user_created_by,
                            //   group.created_by
                            // )}
                          >
                            <i className="fa fa-trash"></i>
                          </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <Pagination
                pageCount={pageCount}
                handlePageNumberClick={handlePageClick}
              />

            </div>
          </div>
        </div>
      </div>

      <Modal className="fade" show={moveContactModel}>
        <MoveContactForm
          closeModel={handleMoveContactForm}
          moveContact={fnMoveContact}
        />
      </Modal>

      <Modal className="fade" show={addContactModel} size="xl" >
        <AddContactForm
          closeAddModel={handleAddContactForm}
          selected_group_uuid={groupData.uuid}
          handleGroupEvent={handleGroupEvent}
        />
      </Modal>

    </Fragment>
  );
}

export default UpdateContactGroupForm;
