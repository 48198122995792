import React, { Fragment, useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import PageTitle from "../../layouts/PageTitle";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import UsersRole from "../user/UsersRole";
import UpdateRoleForm from "./UpdateRoleForm";
import CopyRoleForm from "./CopyRoleForm";
import { getRoleName } from "../../actions/role";
import Accordion from "./UiAccordion";
import Loader from "../../layouts/Loader";

function Permissions() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [addRoleModal, setAddRoleModal] = useState(false);
  const [copyRoleModal, setCopyRoleModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [roleData, setRoleData] = useState([]);
 

  const handleRoleEvent = (event) => {
    //setRoleEvent(event);
  };

  const closeModel = (value) => {
    setAddRoleModal(value);
  };

  const closeCopyModel = (value) => {
    setCopyRoleModal(value);
  };

  const params = useLocation();
  const role_uuid = params.state.role_uuid;

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  useEffect(() => {
    dispatch(getRoleName(role_uuid, createdby, token))
      .then((response) => {
        setRoleData(response.data[0]);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const breadCurmList = [
    { label: "Roles", link: "/", is_active: "NO" },
    { label: "Permissions", link: "/roles", is_active: "NO" },
    {
      label: " " + roleData.role_name,
      link: "",
      is_active: "YES",
    },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                Manage Permission - {roleData.role_name}
              </h4>
              <div>
                <Button
                  type="button"
                  className="btn btn-sm btn-success mr-2"
                  onClick={() => setCopyRoleModal(true)}
                >
                  Copy Role & Permission
                </Button>
                <Button
                  type="button"
                  role_uuid={role_uuid}
                  role_name={roleData.role_name}
                  className="btn btn-sm btn-primary mr-2"
                  onClick={() => setAddRoleModal(true)}
                >
                  Edit
                </Button>
                <Button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() => navigate("/roles", { replace: true })}
                >
                  Back
                </Button>
              </div>
            </div>
            <div className="card-body">
              <Accordion role_uuid={role_uuid} role_name={roleData.role_name} />
            </div>
          </div>
        </div>
      </div>

      <UsersRole role_uuid={role_uuid} />
      <Modal className="fade" show={addRoleModal}>
        <UpdateRoleForm
          closeModel={closeModel}
          handleRoleEvent={handleRoleEvent}
        />
      </Modal>
      <Modal className="fade" show={copyRoleModal}>
        <CopyRoleForm
          role_uuid={role_uuid}
          main_uuid={main_uuid}
          closeModel={closeCopyModel}
          handleRoleEvent={handleRoleEvent}
        />
      </Modal>
    </Fragment>
  );
}

export default Permissions;
