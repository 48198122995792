import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modal,Button,Dropdown, Table } from "react-bootstrap";
import { notifyInfo, notifyError } from "../../Toastr/Toastr";
import { getCompanySignatureList,updateEmailSignature,deleteEmailSignature, assignSignatureToUser } from "../../../actions/email/emailSignature";
import { getUsers } from "../../../actions/user";
import { confirmAlert } from "react-confirm-alert";
import EasyEdit, { Types } from "react-easy-edit";

import "./style.css";
import Loader from "../../../layouts/Loader";
import {EditorState,convertToRaw, ContentState, Modifier} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import CreateEmailSignature from "../../emailSignature/CreateEmailSignature";
import CheckModulePermisson from "../../../common/CheckModulePermisson";
import PermissionCheck from "../../../common/PermissionCheck";
import Pagination from "../../../layouts/Pagination";

const companySignature = (props) => {
  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;
  const module_name = "users";
  let signature_type = 'COMPANY';

  let module_per = CheckModulePermisson(module_name);

  // let isPermission = true;
  // if (module_per === "" || user_type === "admin") {
  //   isPermission = true;
  // } else {
  //   isPermission = false;
  // }

  let listing_per = PermissionCheck(module_name, "listing", "", "", "");

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }

  const [loading, setLoading] = useState(false);
  const [companySignatureData, setCompanySignatureData] = useState([]);
  const [userSignatureData, setUserSignatureData] = useState([]);
  const [signatureEvent, setSignatureEvent] = useState('');
  const [createSignatureModal, setCreateSignatureModal] = useState(false);
  const [activeSignature, setActiveSignature] = useState('');

  const limits = 10;
  const [pageCount, setpageCount] = useState(0);
  const [offSet, setOffSet] = useState(0);
  const [userTotal, setUserTotal] = useState("");
  const [users, setUsers] = useState([]);
  const [UserEvent, setUserEvent] = useState("");

  const userVariables = [
    {lable: 'First Name', value : "user_first_name"},
    {lable: 'Last Name', value : "user_last_name"},
    {lable: 'Email', value : "user_email"},
    {lable: 'Role Name', value : "role_name"},
    {lable: 'User Phone', value : "phone"},
    {lable: 'Job Title', value : "user_job_title"},
    {lable: 'Department', value : "department"},
    {lable: 'Company Name', value : "company_name"},
    {lable: 'Industry', value : "industry"},
    {lable: 'Company Dddress', value : "company_address"},
    {lable: 'Company Email', value : "company_email"},
    {lable: 'Company Phone', value : "company_phone_number"},
    {lable: 'Company Logo', value : "company_logo"},
  ];

  const [editorState, setEditorState] = useState(
    EditorState.createEmpty()
  );

  let search = '';
  const searchData = {
    user_name: "",
    email: "",
    role: "",
    status: "",
  };

  let status = "ACTIVE";
  let orderby = "first_name ASC";

  if (module_per === "" || user_type === "admin") {
    useEffect(() => {
      setLoading(true);
      dispatch(
        getUsers(
          main_uuid,
          user_type,
          group_per,
          group_uuid,
          search,
          searchData,
          offSet,
          status,
          limits,
          orderby,
          createdby,
          token
        )
      )
        .then((response) => {
          setUsers(response.data.data);
          const total = response.data.total;
          setUserTotal(total);
          setpageCount(Math.ceil(total / limits));
          setLoading(false);
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    }, [UserEvent]);
  }
  
  useEffect(() => {
    dispatch(getCompanySignatureList(main_uuid,createdby,signature_type,token))
      .then((response) => {
        setCompanySignatureData(response.data.company_signature);
        setUserSignatureData(response.data.user_signature);

        if(response.data.company_signature[0]){
          
          if(!activeSignature){
            setActiveSignature(response.data.company_signature[0]);      
          }        

          if(response.data.company_signature[0].signature_text !== ''){
            const blocksFromHTML = htmlToDraft(response.data.company_signature[0].signature_text)
            const contentState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            )

            setEditorState(EditorState.createWithContent(contentState));
          }          

        }
        

        return;
      })
      .catch(() => {
        setLoading(false);
      });
}, [signatureEvent]);

  const selectSignature = (e,signature) => {
    e.preventDefault();

    if(signature.signature_text){
      const blocksFromHTML = htmlToDraft(signature.signature_text)
      const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
      )

      setEditorState(EditorState.createWithContent(contentState));
    }else{
      setEditorState(EditorState.createEmpty());
    }      

    setActiveSignature(signature);
  }

  const ShowCreateForm = (e) => {
    e.preventDefault();
    handleCreateSignatureModal(true);
  }

  const handleCreateSignatureModal = (value) => {
    setCreateSignatureModal(value);
  }

  const handleSignatureEvent = (value) => {
    setSignatureEvent(value);
  }

  const setVariable = (e,variable) => {
    e.preventDefault();

    let variable_txt = '{'+variable+'}';

    const newContentState = Modifier.insertText(
      editorState.getCurrentContent(), // get ContentState from EditorState
      editorState.getSelection(),
      variable_txt
    );

    setEditorState(EditorState.createWithContent(newContentState))

  }


  const saveSignature = (value = '') => {
   //e.preventDefault();
   let signature_name = activeSignature.signature_name;
    if(value){
      signature_name = value;
    }

    let signature_text = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    let signatureData = {
      signature_text : signature_text,
      signature_name : signature_name,
      email_signature_uuid : activeSignature.email_signature_uuid,
      createdby : createdby,
    }

    //console.log(signatureData);

    dispatch(updateEmailSignature(signatureData,token))
    .then((response) => {

        if (response.status === 200) {
            notifyInfo(response.message);
            setSignatureEvent(Math.random);
        }  else if (response.status === 500) {
            notifyError("Something went wrong! Try again in some time");
        } else {
            notifyError("Something went wrong! Try again in some time");
        }
        setLoading(false);        
        return;
    })
    .catch(() => {
        setLoading(false);
    });
  }

  const fnDeleteSignature = (e,email_signature_uuid) => {
    e.preventDefault();

    confirmAlert({
      title: "",
      message:
        "Are you sure to delete this signature?",
      buttons: [
        {
          label: "Yes",
          onClick: () => DeleteSignature(email_signature_uuid),
        },
        {
          label: "No",
        },
      ],
    });

  }

  const DeleteSignature = (email_signature_uuid) => {
    let data = {
      email_signature_uuid : email_signature_uuid,
      createdby : createdby
    }

    setLoading(true);
    
    dispatch(deleteEmailSignature(data,token))
    .then((response) => {

      if (response.status === 200) {
        notifyInfo(response.message);
        setSignatureEvent(Math.random);
      }  else if (response.status === 500) {
          notifyError("Something went wrong! Try again in some time");
      } else {
          notifyError("Something went wrong! Try again in some time");
      }
      
      setLoading(false);
      return;
    })
    .catch(() => {
      setLoading(false);
    });
  }

  const AssignSignatureName = (props) => {
      let signature_uuid = props.email_signature_uuid;

      let data = companySignatureData.filter(obj => obj.email_signature_uuid === signature_uuid);

      if(data[0]){
        return data[0].signature_name;
      }else{
        let data2 = userSignatureData.filter(obj => obj.email_signature_uuid === signature_uuid);

        if(data2[0]){
          return data2[0].signature_name;
        }else{
          return 'Assign Signature';
        }
      }

      
  }

  const assignUserSignature = (user_uuid,signature_uuid) => {
    confirmAlert({
      title: "",
      message:
        "Are you sure to change signature?",
      buttons: [
        {
          label: "Yes",
          onClick: () => fnassignUserSignature(user_uuid,signature_uuid),
        },
        {
          label: "No",
        },
      ],
    });
  }

  const fnassignUserSignature = (user_uuid,signature_uuid) => {

    setLoading(true);

    let data = {
      user_uuid : user_uuid,
      signature_uuid : signature_uuid,
      createdby : createdby,
    }

    dispatch(assignSignatureToUser(data,token))
    .then((response) => {
      //console.log(response.data);
      notifyInfo(response.message); 
      setUserEvent(Math.random);  

      setLoading(false);
      return;
    })
    .catch(() => {
      setLoading(false);
    });

  }
  
  const handlePageClick = async (data) => {
    setOffSet(limits * data.selected);
    setUserEvent(Math.random);
  };

  return (
    <div className="pt-3">
      {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>Company Signature</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">

          <div className="col-md-4"> 

            <div className="basic-list-group">
              <div className="list-group">
                {companySignatureData && 
                  companySignatureData.map((signature) => (       
                    <a href="#!" key={signature.email_signature_uuid} className={`${
                      activeSignature.email_signature_uuid === signature.email_signature_uuid
                        ? "list-group-item list-group-item-action active"
                        : "list-group-item list-group-item-action"
                      }`} 
                      
                      onClick={(e)=>selectSignature(e,signature)}
                    >
                        {signature.signature_name} {signature.is_default}

                        {/* {signature.is_default != "YES" && */}
                          <button type="button" className="btn btn-danger btn-xxs pull-right" onClick={(e) => fnDeleteSignature(e,signature.email_signature_uuid)}><i className="fa fa-trash"></i></button>
                        {/* } */}
                    </a> 
                        
                  ))
                }
                                        
              </div>
            </div>

            <div className="text-center">
              <Button type="button" onClick={ShowCreateForm} className="btn-sm mt-2">Create New</Button>
            </div>
            

          </div>

          <div className="col-md-8">
              <div className="row">
                <div className="col-md-6"> 
                  {/* <p><b>{activeSignature.signature_name} </b></p> */}

                  <EasyEdit
                    type={Types.TEXT}
                    onSave={(value) => {
                      saveSignature(value);
                    }}
                    saveButtonLabel={
                      <i className="fa fa-check" aria-hidden="true"></i>
                    }
                    saveButtonStyle="label-primary easy-edit-button rounded-lg"
                    cancelButtonLabel={
                      <i className="fa fa-times" aria-hidden="true"></i>
                    }
                    cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                    viewAttributes ={{
                      className: "p-2 ml-2 fw-bold",
                    }}
                    attributes={{
                      name: "signature_name",
                      className: "form-control bg-white h-75",
                    }}
                    onValidate={value => {return value !== null}}
                    placeholder="Signature Name"
                    value={activeSignature.signature_name}
                  />
                     
                </div>
                <div className="col-md-6 text-right"> 
                  <Dropdown>
                    <Dropdown.Toggle
                      aria-expanded="false"
                      data-toggle="dropdown"
                      className="btn btn btn-outline-success btn-xs mb-2"
                      as="button"
                    >
                      Add Variables
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu">

                      {userVariables && 
                        userVariables.map((variable,index) => (    
                          <Dropdown.Item
                            to="#"
                            key={index}
                            onClick={(e)=>setVariable(e,variable.value)}
                            className="dropdown-item"
                          >
                            {variable.lable}
                            
                          </Dropdown.Item>
                        ))
                      }
                    
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
           
            <div className="form-group input-primary">
                <div className="border border-primary rounded p-2" >
                    <Editor
                        editorStyle={{ minHeight: '300px' }}
                        editorState={editorState}
                        onEditorStateChange={editorState => setEditorState(editorState)}
                        //wrapperClassName="demo-wrapper"
                        //editorClassName="demo-editor"
                    />
                </div>

                {/* <span className="text-danger">
                      {errors.body_text}
                </span> */}

                <Button type="button" onClick={() => props.saveSignature('')} variant="primary btn-sm pull-right mt-2">Save</Button>
            </div>

          </div>
          
        </div>

        {/* Start User listing */}
        <div className="text-right mt-3">
          <p className="mb-0">count : {userTotal}</p>
        </div>
        <Table responsive className="w-100">
          <thead>
            <tr role="row">
              <th>Name</th>
              <th>Extension</th>
              <th>Role</th>
              <th>Email</th>  
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {users.map((user) => (
              <tr key={user.uuid}>
                <td>
                    {user.first_name} {user.last_name}
                </td>

                <td>
                  {user.extension !== null ? user.extension : ""}
                </td>
                <td>{user.role_name}</td>
                <td>{user.email}</td>               
                
                <td>
                  <Dropdown>
                    <Dropdown.Toggle
                      aria-expanded="false"
                      data-toggle="dropdown"
                      className="btn btn btn-outline-info btn-xs mb-2"
                      as="button"
                    >
                      <AssignSignatureName email_signature_uuid={user.email_signature_uuid} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu">

                      {companySignatureData && 
                        companySignatureData.map((signature) => (    
                          <Dropdown.Item
                            to="#"
                            key={signature.email_signature_uuid}
                            onClick={(e)=>assignUserSignature(user.uuid,signature.email_signature_uuid)}
                            className="dropdown-item"
                          >
                            {signature.signature_name}
                            
                          </Dropdown.Item>
                        ))
                      }
                    
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          pageCount={pageCount}
          handlePageNumberClick={handlePageClick}
        />
      </Modal.Body>

      {/* <!-- create signature modal --> */}
      <Modal className="fade" show={createSignatureModal} size='md'>
        <CreateEmailSignature handleCreateSignatureModal={handleCreateSignatureModal} handleSignatureEvent={handleSignatureEvent} type={signature_type} />
      </Modal>

    </div>
    
  );
};

export default companySignature;
