import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import "./index.css";
import { Card, Row, Button, Col, Modal } from "react-bootstrap";
import { onboardingAddDids } from "../../../actions/did";
import { notifyInfo, notifyError } from "../../../components/Toastr/Toastr";
import Loader from "../../../layouts/Loader";

const TermAndConditions = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;

  const ddidData = {
    dids: "",
    createdby: createdby,
    main_admin_uuid: createdby,
    user_uuid: createdby,
  };

  const closeModel = (arg) => {
    props.closeModel(arg);
  };

  const [loading, setLoading] = useState(false);

  let didsData = props.didsData;
  let grand_total = props.grand_total;
  let type = props.type;

  function removeDuplicates(arr) {
    return [...new Set(arr)];
  }

  let number = [];
  let city = [];
  let area_code = [];
  let j = 0;

  didsData.forEach((did_data, i) => {
    number[i] = did_data.phone_number.substring(2, 12);

    area_code[i] = did_data.phone_number.substring(2, 5);

    if (did_data.phone_number_type === "local") {
      Object.values(did_data.region_information).map((k) => {
        if (k.region_type === "location") {
          city[j] = k.region_name;
          j = j + 1;
        }
        return true;
      });
    }
  });

  number = number.toString();
  city = city.toString();
  area_code = removeDuplicates(area_code);
  area_code = area_code.toString();
  city = removeDuplicates(city);

  const dispatch = useDispatch();

  function buyNumber(data) {
    setLoading(true);
    ddidData["dids"] = data;
    ddidData["grand_total"] = grand_total;
    ddidData["type"] = type;

    dispatch(onboardingAddDids(ddidData, token))
      .then((res) => {
        if (res.status === 200) {
          notifyInfo(res.message);
          closeModel(false);
          setLoading(false);
          if (type === "onboarding") {
            user.data.data.is_did_done = "YES";
            localStorage.setItem("user", JSON.stringify(user));
            setLoading(false);
            window.location = "onboarding";
            // navigate("/onboarding", { replace: true });
          } else {
            setLoading(false);
            window.location = "inbound-numbers";
            // navigate("/inbound-numbers", { replace: true });
          }
        } else if (res.status === 500) {
          setLoading(false);
          notifyError("Something went wrong.");
        } else {
          setLoading(false);
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }

  return (
    <Fragment>
      {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>Term & Conditions</Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col xl="12">
            <Card className="">
              <Card.Header className="d-block card-header bgl-primary">
                <Card.Title>Phone Number Details</Card.Title>
              </Card.Header>
              <Card.Body className="card-body">
                <div className="row">
                  <div className="col-12">
                    <div className="">
                      <label className="mr-2">
                        <strong>Phone number type: </strong>
                      </label>
                      GEOGRAPHIC
                    </div>
                    <div className="">
                      <label className="mr-2">
                        <strong>Country: </strong>
                      </label>
                      U.S.A
                    </div>
                    <div className="">
                      <label className="mr-2">
                        <strong>City: </strong>
                      </label>
                      {city}
                    </div>
                    <div className="">
                      <label className="mr-2">
                        <strong>Country Code: </strong>
                      </label>
                      1
                    </div>
                    <div className="">
                      <label className="mr-2">
                        <strong>Area Code: </strong>
                      </label>
                      {area_code}
                    </div>
                    <div className="">
                      <label className="mr-2">
                        <strong>Setup Fee: </strong>
                      </label>
                      $3.00 per number
                    </div>
                    <div className="">
                      <label className="mr-2">
                        <strong>Monthly Price: </strong>
                      </label>
                      $5.00 per number
                    </div>
                    <div className="">
                      <label className="mr-2">
                        <strong>Billing schedule: </strong>
                      </label>
                      15th of the calendar month (GMT Timezone)
                    </div>
                    <div className="">
                      <label className="mr-2">
                        <strong>Phone Numbers: </strong>
                      </label>
                      {number}
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl="12">
            <Card className="">
              <Card.Header className="d-block card-header bgl-primary">
                <Card.Title>Important Notes for U.S.A</Card.Title>
              </Card.Header>
              <Card.Body className="card-body">
                <div className="row">
                  <div className="col-12">
                    <p>
                      * Toll Free phone numbers are reachable from the US
                      (mainland only.)
                    </p>
                    <p>
                      * Toll Free phone numbers are reachable from fix, mobile
                      and payphones.
                    </p>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl="12">
            <Card className="">
              <Card.Header className="d-block card-header bgl-primary">
                <Card.Title>Important Notice</Card.Title>
              </Card.Header>
              <Card.Body className="card-body">
                <div className="row">
                  <div className="col-12">
                    <p>Phone Number is offered on a fully pre-paid basis.</p>

                    <p>
                      <b>
                        IMPORTANT! The minimum service term for a phone number
                        is 3 months. When the order is placed you will be
                        charged the setup fee and the monthly subscription for 3
                        months.
                      </b>
                    </p>

                    <p>Phone number subscription payment is non-refundable.</p>
                    <p>
                      Phone Number subscriptions are automatically renewed and
                      amount equal to MONTHLY PRICE will be deducted from your
                      prepaid account balance.
                    </p>
                    <p>
                      When subscription expires it will be auto-extended by 1
                      month and $5.00 per DID will be charged to the card on
                      file for your account.
                    </p>

                    <p>
                      Please refer to{" "}
                      <a
                        className="text-info mr-1"
                        href="https://voice.anveo.com/legal.asp"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of use
                      </a>
                      for more details.
                    </p>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => closeModel(false)}
          className="btn-sm"
          variant="danger light"
        >
          Close
        </Button>
        <button
          type="button"
          onClick={() => buyNumber(didsData)}
          className="btn btn-sm btn-primary mr-2"
        >
          PLACE ORDER
        </button>
      </Modal.Footer>
    </Fragment>
  );
};

export default TermAndConditions;
