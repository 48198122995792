

function CheckModulePermisson(slug) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  var role_permission = user_data.role_permission;

  if (user_data.role !== "superadmin" && user_data.role !== "admin") {
    var data = role_permission.filter((item) => item.slug === slug);
    if (Array.isArray(data) && data.length > 0) {
      if (data[0].status === "enable") {
        return "";
      } else {
        return "hidden";
      }
    }
  } else {
    if (user_data.role === "superadmin") {
      return "hidden";
    } else {
      return "";
    }
  }
}

export default CheckModulePermisson;
