import React, { useState } from "react";
import { useDispatch } from "react-redux";
import StepOne from "./Wizard/StepOne";
import StepTwo from "./Wizard/StepTwo";
import StepThree from "./Wizard/StepThree";
import StepFour from "./Wizard/StepFour";
import "./index.css";
import { register, checkUserEmail } from "../actions/auth";
import { notifyInfo } from "../components/Toastr/Toastr";
import Loader from "../layouts/Loader";

const MultiStepForm = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const [emailExits, setEmailExits] = useState("");

  const [formData, setFormData] = useState({
    plan_data: {
      plan_uuid: "",
      plan_name: "",
      plan_price: "",
      final_price: "",
      type: "quarterly",
      total_users: 1,
      description: "",
    },
    cart_data: {
      sub_total: "",
      grand_total: "",
      total: "",
      total_user: "",
      discount: "",
      months: "",
    },
    company_data: {
      company_name: "",
      industry: "",
      company_type: "",
      company_address: "",
      locality: "",
      city: "",
      state: "",
      postal_code: "",
    },
    user_data: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      job_title: "",
      department: "",
    },
    credit_card_data: {
      first_name: "",
      last_name: "",
      card_number: "",
      expiration_date: "",
      CVV_number: "",
      billing_address: "",
      billing_cycle: "",
    },
  });

  const [errors, setErrors] = useState({
    plan_data: {
      plan_uuid: "",
    },
    company_data: {
      company_name: "",
      industry: "",
      company_type: "",
      company_address: "",
    },
    user_data: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      job_title: "",
      department: "",
    },
    credit_card_data: {
      first_name: "",
      last_name: "",
      card_number: "",
      expiration_date: "",
      CVV_number: "",
      billing_address: "",
      billing_cycle: "",
    },
  });

  const dispatch = useDispatch();

  const [selectPlan, setSelectPlan] = useState("");
  const [loading, setLoading] = useState(false);

  const handleNextStep = () => {
    //let is_email_check = 0;
    setLoading(true);
    if (currentStep === 1) {
      let error_data = { ...errors };
      if (formData.plan_data.plan_uuid === "") {
        error_data.plan_data.plan_uuid =
          "No plan selected, click on buy now to select a plan, on the desired plan card";
        setErrors(error_data);
      } else {
        error_data.plan_data.plan_uuid = "";
        setErrors(error_data);
        setCurrentStep(currentStep + 1);
      }
      setLoading(false);
    } else if (currentStep === 2) {
      let error_data = { ...errors };
      let isError = "false";
      
      if (formData.user_data.email === "") {
        error_data.user_data.email = "Please select email";
        setErrors(error_data);
        isError = "true";
        setLoading(false);
      } else {
        if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
            formData.user_data.email
          )
        ) {
          error_data.user_data.email = "Invalid email address.";
          setErrors(error_data);
          isError = "true";
        } else {
          //is_email_check = 1;
          error_data.user_data.email = "";
          setErrors(error_data);
          isError = "false";
        }
      }

      if (formData.user_data.first_name === "") {
        error_data.user_data.first_name = "Please Enter First Name";
        setErrors(error_data);
        isError = "true";
        setLoading(false);
      } else {
        // if (!/^[A-Za-z]+$/i.test(error_data.user_data.first_name)) {
        //   error_data.user_data.first_name = "Invalid last name.";
        //   setErrors(error_data);
        //   isError = "true";
        // } else {
        //   error_data.user_data.first_name = "";
        //   setErrors(error_data);
        // }
        error_data.user_data.first_name = "";
        setErrors(error_data);
      }

      if (formData.user_data.last_name === "") {
        error_data.user_data.last_name = "Please Enter Last Name";
        setErrors(error_data);
        isError = "true";
        setLoading(false);
      } else {
        // if (!/^[A-Za-z]+$/i.test(error_data.user_data.last_name)) {
        //   error_data.user_data.last_name = "Invalid last name.";
        //   setErrors(error_data);
        //   isError = "true";
        // } else {
        //   error_data.user_data.last_name = "";
        //   setErrors(error_data);
        // }
        error_data.user_data.last_name = "";
        setErrors(error_data);
      }

      if (formData.user_data.phone === "") {
        error_data.user_data.phone = "Please Enter Phone Number";
        setErrors(error_data);
        isError = "true";
        setLoading(false);
      } else {
        if (!/^[0-9\s]*$/i.test(formData.user_data.phone)) {
          error_data.user_data.phone = "Invalid Phone Number.";
          setErrors(error_data);
          isError = "true";
        } else {
          error_data.user_data.phone = "";
          setErrors(error_data);
        }
      }

      if (formData.user_data.job_title === "") {
        error_data.user_data.job_title = "Please Enter Job Title";
        setErrors(error_data);
        isError = "true";
        setLoading(false);
      } else {
        error_data.user_data.job_title = "";
        setErrors(error_data);
      }

      if (formData.user_data.department === "") {
        error_data.user_data.department = "Please Select Separtment";
        setErrors(error_data);
        isError = "true";
        setLoading(false);
      } else {
        error_data.user_data.department = "";
        setErrors(error_data);
      }

      if (isError === "true") {
        setLoading(false);
        return;
      } else {
        //call check user email API
        dispatch(checkUserEmail(formData.user_data))
          .then((res) => {
            if (res.status === 200) {
              setLoading(false);
              setCurrentStep(currentStep + 1);
            } else if (res.status === 203) {
              //is_email_check = 0;
              error_data.user_data.email = res.message;
              setEmailExits(res.message);
              setLoading(false);
              
              return;
            } else if (res.status === 500) {
              setLoading(false);
             
            }
          })
          .catch(() => {
            setLoading(false);
            
          });
      }
    } else if (currentStep === 3) {
      let error_data = { ...errors };
      let isError = "false";

      if (formData.company_data.company_name === "") {
        error_data.company_data.company_name = "Please Enter Company Name";
        setErrors(error_data);
        isError = "true";
        setLoading(false);
      } else {
        error_data.company_data.company_name = "";
        setErrors(error_data);
      }

      if (formData.company_data.industry === "") {
        error_data.company_data.industry = "Please Enter Industry";
        setErrors(error_data);
        isError = "true";
        setLoading(false);
      } else {
        error_data.company_data.industry = "";
        setErrors(error_data);
      }

      if (formData.company_data.company_type === "") {
        error_data.company_data.company_type = "Please Select Company Type";
        setErrors(error_data);
        isError = "true";
        setLoading(false);
      } else {
        error_data.company_data.company_type = "";
        setErrors(error_data);
      }

      if (formData.company_data.company_address === "") {
        error_data.company_data.company_address =
          "Please Enter Company Address";
        setErrors(error_data);
        isError = "true";
        setLoading(false);
      } else {
        error_data.company_data.company_address = "";
        setErrors(error_data);
      }

      if (formData.company_data.locality === "") {
        error_data.company_data.locality = "Please Enter Locality";
        setErrors(error_data);
        isError = "true";
        setLoading(false);
      } else {
        error_data.company_data.locality = "";
        setErrors(error_data);
      }

      if (formData.company_data.city === "") {
        error_data.company_data.city = "Please Enter City";
        setErrors(error_data);
        isError = "true";
        setLoading(false);
      } else {
        error_data.company_data.city = "";
        setErrors(error_data);
      }

      if (formData.company_data.state === "") {
        error_data.company_data.state = "Please Enter State";
        setErrors(error_data);
        isError = "true";
        setLoading(false);
      } else {
        error_data.company_data.state = "";
        setErrors(error_data);
      }

      if (formData.company_data.postal_code === "") {
        error_data.company_data.postal_code = "Please Enter Postal Code";
        setErrors(error_data);
        isError = "true";
        setLoading(false);
      } else {
        error_data.company_data.postal_code = "";
        setErrors(error_data);
      }

      if (isError === "true") {
        setLoading(false);
        return;
      } else {
        setLoading(false);
        setCurrentStep(currentStep + 1);
      }
    } else {
    }
  };

  const handlePrevStep = () => {
    if (currentStep === 2) {
      setSelectPlan(selectPlan);
    }
    setCurrentStep(currentStep - 1);
  };

  // const handleFormChange = (step, field, value) => {
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     [step]: {
  //       ...prevFormData[step],
  //       [field]: value,
  //     },
  //   }));
  // };

  const handleOneData = (dataFromOne) => {
    setFormData(dataFromOne);
  };

  const handleTwoData = (dataFromTwo) => {
    setFormData(dataFromTwo);
  };

  const handleTwoError = (dataFromTwo) => {
    setErrors(dataFromTwo);
  };

  const handleThreeData = (dataFromThree) => {
    setFormData(dataFromThree);
  };

  const handleFourData = (dataFromFour) => {
    setFormData(dataFromFour);
  };

  const handleCartData = (dataCart) => {
    setFormData(dataCart);
  };

  const planData = (plan_uuid) => {
    setCurrentStep(currentStep + 1);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <StepOne
            planData={planData}
            planId={selectPlan}
            formData={formData}
            errors={errors}
            handleOneData={handleOneData}
          />
        );
      case 2:
        return (
          <StepTwo
            formData={formData}
            errors={errors}
            emailExits={emailExits}
            handleTwoData={handleTwoData}
            handleCartData={handleCartData}
            handleTwoError={handleTwoError}
          />
        );
      case 3:
        return (
          <StepThree
            formData={formData}
            errors={errors}
            handleThreeData={handleThreeData}
            handleCartData={handleCartData}
          />
        );
      case 4:
        return (
          <StepFour
            formData={formData}
            errors={errors}
            handleFourData={handleFourData}
            handleCartData={handleCartData}
          />
        );
      default:
        return null;
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (formData.credit_card_data.first_name === "") {
      error_data.credit_card_data.first_name = "Please enter first name";
      setErrors(error_data);
      isError = "true";
    } else {
      error_data.credit_card_data.first_name = "";
      setErrors(error_data);
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call register user API
      dispatch(register(formData))
        .then((res) => {
          if (res.status === 200) {
            notifyInfo(res.message);
            // navigate("/extensions", { replace: true });
          } else if (res.status === 406) {
            error_data["extension"] = res.message;
            setErrors(error_data);
            isError = "true";
          } else if (res.status === 500) {
            // notifyError("Something went wrong.");
          } else {
            // notifyError("Something went wrong.");
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="row">
      {loading && <Loader />}
      <div className="col-xl-12 col-xxl-12">
        <form
          action=""
          onSubmit={(e) => submitHandler(e)}
          id="step-form-horizontal"
          className="step-form-horizontal"
        >
          <ol className="go2441762052">
            <li
              className={currentStep < 1 ? "go2067797667" : "go767851704"}
              value="0"
            >
              <span>1</span>
            </li>
            <li
              className={currentStep < 2 ? "go2067797667" : "go767851704"}
              value="1"
            >
              <span>2</span>
            </li>
            <li
              className={currentStep < 3 ? "go2067797667" : "go767851704"}
              value="2"
            >
              <span>3</span>
            </li>
            <li
              className={currentStep < 4 ? "go2067797667" : "go767851704"}
              value="3"
            >
              <span>4</span>
            </li>
          </ol>
          {renderStep()}
          <span className="text-danger">{errors.plan_data.plan_uuid}</span>
          <div className="next_pre_btn">
            {currentStep > 1 && (
              <button
                className="prevStyle"
                type="button"
                onClick={handlePrevStep}
              >
                Previous
              </button>
            )}
            {currentStep < 4 && (
              <button
                className="nextStyle"
                type="button"
                onClick={handleNextStep}
              >
                Next
              </button>
            )}
            {/* {currentStep === 4 && (
              <button className="btn btn-sm btn-primary" type="submit">
                Submit
              </button>
            )} */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default MultiStepForm;
