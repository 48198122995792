import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  Button,
  ButtonGroup,
  DropdownButton,
  Dropdown,
  Modal,
} from "react-bootstrap";

import { useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import Pagination from "../../layouts/Pagination";
import {
  getBlockedNumberList,
  deleteBlockedNumber,
  deleteMultipleBlockedNumber,
  getExportBlockNumbers,
} from "../../actions/blockednumbers";
import BlockedNumberForm from "./BlockedNumberForm";
import NumberBulkUploadForm from "./NumberBulkUploadForm";
import FlashMessage from "../../layouts/FlashMessage";
import CheckModulePermisson from "../../common/CheckModulePermisson";
import PermissionCheck from "../../common/PermissionCheck";
import { CSVLink } from "react-csv";
import Loader from "../../layouts/Loader";

function General(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;
  const module_name = "blocked-numbers";

  let module_per = CheckModulePermisson(module_name);

  let isPermission = true;
  if (module_per === "" || user_type === "admin") {
    isPermission = true;
  } else {
    isPermission = false;
  }

  let listing_per = PermissionCheck(module_name, "listing", "", "", "");
  let add_per = PermissionCheck(module_name, "add", "", "", "");
  const dispatch = useDispatch();

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }

  let orderby = "created_at ASC";

  const csvRef = useRef();
  const headers = [
    { label: "Number", key: "number" },
    { label: "Description", key: "description" },
  ];

  const [limits, setLimits] = useState(10);
  const [blockedNumber, setBlockedNumber] = useState([]);
  const [blockedNumberUpdate, setBlockedNumberUpdate] = useState("");
  const [blockedNumberEvent, setBlockedNumberEvent] = useState("");
  const [pageCount, setpageCount] = useState(0);
  const [offSet, setOffSet] = useState(0);
  const [addBlockedNumberModal, setAddBlockedNumberModal] = useState(false);
  const [bulkUploadModal, setBulkUploadModal] = useState(false);
  const [search, setSearch] = useState("");

  const [selectedBlockNumbers, setSelectedBlockNumbers] = useState({
    selectd_blocked_number: [],
    checked: 0,
    select_type: "none",
  });

  const [csvContacts, setCsvContacts] = useState([]);

  const [totalContacts, setTotalContacts] = useState(0);

  const [loading, setLoading] = useState(false);

  if (module_per === "" || user_type === "admin") {
    useEffect(() => {
      setLoading(true);
      dispatch(
        getBlockedNumberList(
          user_type,
          group_per,
          group_uuid,
          search,
          offSet,
          limits,
          orderby,
          main_uuid,
          createdby,
          token
        )
      )
        .then((response) => {
          setBlockedNumber(response.data.data);
          const total = response.data.total;
          props.setTotalBN(total);
          setTotalContacts(total);
          setpageCount(Math.ceil(total / limits));
          setBlockedNumberEvent("");
          setLoading(false);
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    }, [blockedNumberEvent, limits]);
  }

  const csvReport = {
    data: csvContacts,
    headers: headers,
    filename: "blocked_numbers.csv",
  };

  useEffect(() => {
    if (csvContacts.length > 0) {
      csvRef.current.link.click();
    }
  }, [csvContacts]);

  const closeModel = (value) => {
    setAddBlockedNumberModal(value);
  };

  const closeNumberUploadModel = (value) => {
    setBulkUploadModal(value);
  };

  const deleteNumber = (number_uuid) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this block number?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteNumberService(number_uuid),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteNumberService = (number_uuid) => {
    setLoading(true);
    dispatch(deleteBlockedNumber(number_uuid, createdby, token))
      .then((response) => {
        if (response.status === 200) {
          notifyInfo(response.message);
          setBlockedNumberEvent(Math.random());
          setLoading(false);
          return;
        } else if (response.status === 500) {
          setLoading(false);
          notifyError("Something went wrong.");
        } else {
          setLoading(false);
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const addUpdateNumber = (number_uuid, number, description) => {
    let newBlockedNumber = { ...blockedNumberUpdate };

    newBlockedNumber["blocked_number_uuid"] = number_uuid;
    newBlockedNumber["number"] = number;
    newBlockedNumber["description"] = description;
    setBlockedNumberUpdate(newBlockedNumber);

    setAddBlockedNumberModal(true);
  };

  const deleteMultipleNumber = (selectedBlockNum) => {
    if (selectedBlockNum === "") {
      alert("Please first select blocked numbers");
      return false;
    }

    confirmAlert({
      title: "",
      message: "Are you sure to delete this block numbers?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteMultipleNumberService(selectedBlockNum),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteMultipleNumberService = (selectedBlockNum) => {
    setLoading(true);
    dispatch(deleteMultipleBlockedNumber(selectedBlockNum, createdby, token))
      .then((response) => {
        if (response.status === 200) {
          notifyInfo(response.message);
          setBlockedNumberEvent(Math.random());
          setLoading(false);
          return;
        } else if (response.status === 500) {
          setLoading(false);
          notifyError("Something went wrong.");
        } else {
          setLoading(false);
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handlePageClick = async (data) => {
    setOffSet(limits * data.selected);
    setBlockedNumberEvent(Math.random);
  };

  const changeLimit = (value) => {
    setLimits(value);
  };

  const handleNumberEvent = (value) => {
    setBlockedNumberEvent(value);
  };

  const checkNumber = (e, number_uuid) => {
    const newarr = [];

    if (e.target.checked) {
      //checked : add element
      const newdata = [
        ...selectedBlockNumbers.selectd_blocked_number,
        number_uuid,
      ];
      newarr["selectd_blocked_number"] = newdata;
      newarr["checked"] = newdata.length;
      newarr["select_type"] = "select";
    } else {
      //uncheck : remove element
      const newdata = selectedBlockNumbers.selectd_blocked_number.filter(
        (arr) => arr !== number_uuid
      );

      newarr["selectd_blocked_number"] = newdata;
      newarr["checked"] = newdata.length;
      newarr["select_type"] = "none";
    }

    setSelectedBlockNumbers(newarr);
  };

  const ExportCsv = (selectedBlockNum) => {
    const selected_contacts = selectedBlockNum.selectd_blocked_number;
    setLoading(true);
    
    if (selectedBlockNumbers.select_type === "none") {
      let newdata = [];
      let i = 0;
      blockedNumber.forEach(async function (data) {
        newdata[i] = data.blocked_number_uuid;
        i++;
      });
      dispatch(getExportBlockNumbers(newdata, createdby, token))
        .then((response) => {
          setCsvContacts(response.data.data);
          setLoading(false);
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    } else {

      dispatch(getExportBlockNumbers(selected_contacts, createdby, token))
        .then((response) => {
          setCsvContacts(response.data.data);
          setLoading(false);
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const SelectAll = (type) => {
    let blocked_number_uuids = [];

    blockedNumber.map(
      (number) =>
        (blocked_number_uuids = [
          ...blocked_number_uuids,
          number.blocked_number_uuid,
        ])
    );

    const newarr = [];

    newarr["selectd_blocked_number"] = blocked_number_uuids;
    newarr["checked"] =
      type === "all" ? totalContacts : blocked_number_uuids.length;
    newarr["select_type"] = type;

    setSelectedBlockNumbers(newarr);

    return;
  };

  const DeSelectAll = () => {
    const newarr = [];
    newarr["selectd_blocked_number"] = [];
    newarr["checked"] = 0;
    newarr["select_type"] = "none";

    setSelectedBlockNumbers(newarr);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setBlockedNumberEvent(Math.random);
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <FlashMessage />

      {isPermission ? (
        <>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Blocked Numbers List</h4>

                  <div>
                    <Button
                      type="button"
                      className="btn btn-sm btn-danger mr-2"
                      onClick={() =>
                        deleteMultipleNumber(
                          selectedBlockNumbers.selectd_blocked_number
                        )
                      }
                    >
                      Delete Seleted Numbers
                    </Button>

                    <Button
                      type="button"
                      className="btn btn-info btn-sm mr-2"
                      onClick={() => ExportCsv(selectedBlockNumbers)}
                    >
                      Export List
                    </Button>
                    {add_per === "yes" ? (
                      <Button
                        type="button"
                        className="btn btn-primary btn-sm mr-2"
                        onClick={() => addUpdateNumber("", "", "")}
                      >
                        Add To List
                      </Button>
                    ) : (
                      <></>
                    )}
                    {add_per === "yes" ? (
                      <Button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => setBulkUploadModal(true)}
                      >
                        Bulk Import
                      </Button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="card-body">
                  Show
                  <ButtonGroup className="ml-2 mr-2">
                    <DropdownButton
                      as={ButtonGroup}
                      id="bg-nested-dropdown"
                      size="sm"
                      title={limits}
                      variant="custom-dropdown"
                    >
                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => changeLimit(10)}
                      >
                        10
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => changeLimit(25)}
                      >
                        25
                      </Dropdown.Item>

                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => changeLimit(50)}
                      >
                        50
                      </Dropdown.Item>

                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => changeLimit(100)}
                      >
                        100
                      </Dropdown.Item>
                    </DropdownButton>
                  </ButtonGroup>
                  entries
                  <div
                    className="input-group input-success pull-right"
                    style={{ width: "35%" }}
                  >
                    <input
                      className="form-control text-primary"
                      type="search"
                      placeholder="Search Here"
                      aria-label="Search"
                      onChange={(e) => handleSearchChange(e)}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text text-primary">
                        <svg
                          width={20}
                          height={20}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                            fill="#A4A4A4"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <Table responsive className="w-100">
                    <thead>
                      <tr role="row">
                        <th>
                          <th>
                            <DropdownButton
                              title=""
                              id="bg-nested-dropdown"
                              size="sm"
                              variant="btn btn-outline-light"
                            >
                              <Dropdown.Item
                                eventKey="1"
                                href="#"
                                onClick={() => SelectAll("page")}
                              >
                                Select This Page
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="2"
                                href="#"
                                onClick={() => SelectAll("all")}
                              >
                                Select All
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="3"
                                href="#"
                                onClick={() => DeSelectAll()}
                              >
                                DeSelect All
                              </Dropdown.Item>
                            </DropdownButton>
                          </th>
                        </th>
                        <th>Number</th>
                        <th>Description</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <CSVLink {...csvReport} ref={csvRef}></CSVLink>
                    <tbody>
                      {blockedNumber.map((row) => (
                        <tr key={row.blocked_number_uuid}>
                          <td>
                            <div className="form-check mb-2 ml-4">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={selectedBlockNumbers.selectd_blocked_number.includes(
                                  row.blocked_number_uuid
                                )}
                                onChange={(e) =>
                                  checkNumber(e, row.blocked_number_uuid)
                                }
                              />
                              <label className="form-check-label"></label>
                            </div>
                          </td>
                          <td>{row.number}</td>
                          <td>{row.description}</td>

                          <td>
                            <Fragment>
                              <div className="d-flex">
                                {
                                  <Link
                                    state={{
                                      row: row.blocked_number_uuid,
                                    }}
                                    onClick={() =>
                                      addUpdateNumber(
                                        row.blocked_number_uuid,
                                        row.number,
                                        row.description
                                      )
                                    }
                                    hidden={PermissionCheck(
                                      module_name,
                                      "edit",
                                      row.group_uuid,
                                      row.user_created_by,
                                      row.created_by
                                    )}
                                    className="btn btn-primary shadow btn-xs sharp mr-1"
                                  >
                                    <i className="fa fa-pencil"></i>
                                  </Link>
                                }
                                <Link
                                  to="#"
                                  onClick={() =>
                                    deleteNumber(row.blocked_number_uuid)
                                  }
                                  hidden={PermissionCheck(
                                    module_name,
                                    "delete",
                                    row.group_uuid,
                                    row.user_created_by,
                                    row.created_by
                                  )}
                                  className="btn btn-danger shadow btn-xs sharp mr-1"
                                >
                                  <i className="fa fa-trash"></i>
                                </Link>

                                <Link
                                  to="#"
                                  // onClick={() =>
                                  //   deleteNumber(row.blocked_number_uuid)
                                  // }
                                  hidden={PermissionCheck(
                                    module_name,
                                    "delete",
                                    row.group_uuid,
                                    row.user_created_by,
                                    row.created_by
                                  )}
                                  className="btn btn-info shadow btn-xs sharp"
                                >
                                  <i className="fa fa-area-chart"></i>
                                </Link>
                              </div>
                            </Fragment>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Pagination
                    pageCount={pageCount}
                    handlePageNumberClick={handlePageClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Manage Blocked Numbers</h4>
              </div>
              <div className="card-body">
                <h3>You have don't access this modules</h3>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal className="fade bd-example-modal-xl" show={addBlockedNumberModal}>
        <BlockedNumberForm
          closeModel={closeModel}
          handleNumberEvent={handleNumberEvent}
          blockedNumberUpdate={blockedNumberUpdate}
        />
      </Modal>

      <Modal
        className="fade bd-example-modal-lg"
        show={bulkUploadModal}
        size="lg"
      >
        <NumberBulkUploadForm
          closeNumberUploadModel={closeNumberUploadModel}
          handleNumberEvent={handleNumberEvent}
        />
      </Modal>
    </Fragment>
  );
}

export default General;
