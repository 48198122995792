
import React from "react";
// import { Modal,Button, } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";

// import { createCustomField } from "../../../actions/customfield";
// import Loader from "../../../layouts/Loader";


function AddDependentFieldForm(props) {

    let dependentField = props.dependentField;
    let input_index = props.input_index;

    const handleDependentField = (e,input_index,dependentField_index) =>{
        props.handleDependentField(e,input_index,dependentField_index);
    }

    const removeDependentField = (e,input_index,dependentField_index) => {
        props.removeDependentField(e,input_index,dependentField_index);
    }

    const handleDependentOptionChange = (dependentField_index,input_index,option_index,e) =>{
        props.handleDependentOptionChange(dependentField_index,input_index,option_index,e);
    }

    const removeDependentInputOption = (dependentField_index,input_index,option_index) =>{
        props.removeDependentInputOption(dependentField_index,input_index,option_index);
    }

    const addDependentInputOptionField = (input_index,dependentField_index) =>{
        props.addDependentInputOptionField(input_index,dependentField_index);
    }

    return (
        <div key={dependentField.index} id={dependentField.index} >
                                                    
            <div className="row">
            
                <div className="col-md-5 input-info">  
                    <i className="fa fa-level-up mr-2" aria-hidden="true" style={{transform: "rotate(90deg)"}}></i>                                              
                    <label className="mb-1">
                        <strong>Field Type</strong>
                    </label>
                    
                    <select
                        className="form-control bg-white"
                        name="dependent_field_type"
                        value={dependentField.dependent_field_type}
                        onChange={(e) => handleDependentField(e,input_index,dependentField.index)}
                    >
                        <option value="">Select Type</option>
                        <option value="TEXT">TEXT</option>
                        <option value="DROPDOWN">DROPDOWN</option>
                        <option value="TEXTAREA">TEXTAREA</option>
                        <option value="RADIO">RADIO</option>
                        <option value="CHECKBOX">CHECKBOX</option>
                        <option value="DATE">DATE</option>

                    </select>
                    {/* <span className="text-danger">{errors.dependent_field_type}</span> */}
                </div>

                <div className="col-md-6 input-info">
                    <label className="mb-1">
                        <strong>Field Name</strong>
                    </label>
                    <input
                        type="text"
                        className="form-control bg-white"
                        placeholder="Field Name"
                        name="dependent_field_name"
                        value={dependentField.dependent_field_name}
                        onChange={(e) => handleDependentField(e,input_index,dependentField.index)}
                    />
                    {/* <span className="text-danger">{errors.dependent_field_name}</span> */}
                    {/*  */}
                </div>

                <div className="col-md-1">
                    {(props.dependentFields.length!==1)? <button type="button" onClick={(e) => removeDependentField(e,input_index,dependentField.index)} className="btn btn-sm btn-outline-danger mt-4" >x</button>:''}
                </div>

            </div>
            

            {dependentField.field_options.map((option) => {                            
                return (
                    <div className="row mt-1"  id={option.index} key={option.index}>
                        <div className="col-md-9 input-info">
                            <input
                                name='field_options'
                                placeholder='Option Value'
                                className="form-control bg-white"
                                value={option.field_options}
                                //defaultValue={input.field_options}
                                onChange={(e) => handleDependentOptionChange(dependentField.index,input_index,option.index,e)}
                            /> 
                        </div> 

                        <div className="col-md-2">
                            {(dependentField.field_options.length!==1)? <button type="button" className="btn btn-sm btn-outline-danger" onClick={() => removeDependentInputOption(dependentField.index,input_index,option.index)}>x</button>:''}
                        </div>
                    </div>
                )}
            )}

            <div className="row mb-2">
                <div className="col-sm">

                
                {(dependentField.field_options.length > 0)? <button type="button" className="btn btn-sm btn-info mt-1 pull-right" onClick={() => addDependentInputOptionField(input_index,dependentField.index)}>+</button>:''}

                </div>
            </div>
                                
        </div>
    );
}


export default AddDependentFieldForm;


// <div key={input.index} id={input.index} className="border-bottom">
                                                
// <div className="row">
                                                
// <div className="col-md-5 form-group input-primary">  
//     <i className="fa fa-level-up mr-2" aria-hidden="true" style={{transform: "rotate(90deg)"}}></i>                                              
//     <label className="mb-1">
//         <strong>Field Type</strong>
//     </label>
    
//     <select
//         className="form-control bg-white"
//         name="dependent_field_type"
//         onChange={(e) => handleDependentField(e,input.index)}
//     >
//         <option value="">Select Type</option>
//         <option value="TEXT">TEXT</option>
//         <option value="DROPDOWN">DROPDOWN</option>
//         <option value="TEXTAREA">TEXTAREA</option>
//         <option value="RADIO">RADIO</option>
//         <option value="CHECKBOX">CHECKBOX</option>
//         <option value="DATE">DATE</option>

//     </select>
//     <span className="text-danger">{errors.dependent_field_type}</span>
// </div>

// <div className="col-md-7 form-group input-primary">
//     <label className="mb-1">
//         <strong>Field Name</strong>
//     </label>
//     <input
//         type="text"
//         className="form-control bg-white"
//         placeholder="Field Name"
//         name="dependent_field_name"
//         onChange={(e) => handleDependentField(e,input.index)}
//     />
//     <span className="text-danger">{errors.dependent_field_name}</span>
//     {/* <button className="btn btn-sm text-primary pull-right" onClick={addMoreDependentField(input.index)}>Add More Field</button> */}
// </div>



// </div>



// {dependentFields[input.index].field_options.map((option) => {                            
// return (
//     <div className="row"  id={option.index} key={option.index}>
//         <div className="col-md-9">
//             <input
//                 name='field_options'
//                 placeholder='Option Value'
//                 className="form-control bg-white"
//                 //defaultValue={input.field_options}
//                 onChange={(e) => handleDependentOptionChange(input.index,option.index,e)}
//             /> 
//         </div> 

//         <div className="col-md-2">
//             {(dependentFields[input.index].field_options.length!==1)? <button type="button" className="btn btn-sm btn-outline-danger" onClick={() => removeDependentInputOption(input.index,option.index)}>x</button>:''}
//         </div>
//     </div>
// )}
// )}

// <div className="row mb-2">
// <div className="col-sm">


// {(dependentFields[input.index].field_options.length > 0)? <button type="button" className="btn btn-sm btn-info mt-1 pull-right" onClick={() => addDependentInputOptionField(input.index)}>+</button>:''}

// </div>
// </div>
                
// </div>