import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const createSmsCampaign = (data, token) => {
  return axios
    .post(API_URL + "create-sms-campaign  ", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getSmsCampaignList = (
  main_uuid,
  user_type,
  group_per,
  group_uuid,
  search,
  off_set,
  status,
  limits,
  orderby,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "sms-campaign-list",
      {
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        search,
        off_set,
        status,
        limits,
        orderby,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const deleteSmsCampaign = (sms_camp_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "delete-sms-campaign",
      {
        sms_camp_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

// const updateSmsCampaign = (smsCampData) => {
//   console.log(smsCampData.camp_data.token,"===smsCampData.token==fate");
//   return axios
//     .post(
//       API_URL + "update-sms-campaign",
//       { smsCampData },
//       {
//         headers: {
//           Authorization: smsCampData.camp_data.token,
//         },
//       }
//     )
//     .then((response) => {
//       return response.data;
//     });
// };

const updateSmsCampaign = (data,token) => {
  return axios
    .post(API_URL + "update-sms-campaign", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getSmsCampaignDetail = (sms_camp_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "get-sms-campaign-details",
      {
        sms_camp_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getSmsTemplateList = (message_type, main_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "get-sms-templatelist",
      {
        message_type,
        main_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getContactGroupList = (main_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "get-contact-group-list-dropdoen",
      {
        main_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

export default {
  createSmsCampaign,
  getSmsCampaignList,
  deleteSmsCampaign,
  updateSmsCampaign,
  getSmsCampaignDetail,
  getSmsTemplateList,
  getContactGroupList,
};
