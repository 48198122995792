import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Table,
  Modal,
  Button,
  ButtonGroup,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import PageTitle from "../../../layouts/PageTitle";
import Pagination from "../../../layouts/Pagination";
import {
  getContactList,
  deleteAllContact,
  saveContactSearch,
  getSelectedContacts,
} from "../../../actions/contacts";
import CheckModulePermisson from "../../../common/CheckModulePermisson";
import PermissionCheck from "../../../common/PermissionCheck";
import ContactSearchForm from "./ContactSearchForm";
import SaveSearchForm from "./SaveSearchForm";
import { CSVLink } from "react-csv";
import { notifyInfo, notifyError } from "../../Toastr/Toastr";
import Loader from "../../../layouts/Loader";


function Contacts() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;
  const module_name = "contacts";

  let module_per = CheckModulePermisson(module_name);

  const csvRef = useRef();
  const headers = [
    { label: "Contact Type", key: "contact_type" },
    { label: "Bussiness Name", key: "bussiness_name" },
    { label: "Contact Title", key: "contact_title" },
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Work Contact Number", key: "work_contact_number" },
    { label: "Other Contact", key: "other_contact_number" },
    { label: "Country", key: "country" },
    { label: "State", key: "state" },
    { label: "City", key: "city" },
    { label: "Zipcode", key: "zipcode" },
    { label: "Address", key: "address" },
    { label: "Address2", key: "address2" },
  ];

  let isPermission = true;
  if (module_per === "" || user_type === "admin") {
    isPermission = true;
  } else {
    isPermission = false;
  }

  let listing_per = PermissionCheck(module_name, "listing", "", "", "");
  let add_per = PermissionCheck(module_name, "add", "", "", "");

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }

  //let limits = 10;
  let orderby = "c.created_at DESC";

  const [addContactSearchModal, setContactSearchModal] = useState(false);
  const [saveContactSearchModel, setSaveContactSearchModel] = useState(false);
  const [csvContacts, setCsvContacts] = useState([]);

  const [contacts, setContacts] = useState([]);
  const [contactEvent, setContactEvent] = useState("");
  const [pageCount, setpageCount] = useState(0);
  const [offSet, setOffSet] = useState(0);
  const [totalContacts, setTotalContacts] = useState(0);
  const [limits, setLimits] = useState(10);

  const [selectedContacts, setSelectedContacts] = useState({
    selectd_contacts: [],
    checked: 0,
    select_type: "none",
  });

  const [contactSearchData, setContactSearchData] = useState({
    is_search: false,
    limits: limits,
    off_set: offSet,
    orderby: orderby,
    group_per: group_per,
    group_uuid: group_uuid,
    user_type: user_type,
    contact_type: "",
    bussiness_name: "",
    contact_title: "",
    first_name: "",
    last_name: "",
    email: "",
    work_contact_number: "",
    other_contact_number: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
    address: "",
    address2: "",
    createdby: createdby,
    main_uuid: main_uuid,
    user_uuid: createdby,
  });

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  if (module_per === "" || user_type === "admin") {
    useEffect(() => {
      setLoading(true);
      const newarr = { ...contactSearchData };
      newarr["off_set"] = offSet;

      dispatch(getContactList(newarr,token))
        .then((response) => {
          setContacts(response.data.data);
          const total = response.data.total;
          setTotalContacts(total);

          setpageCount(Math.ceil(total / limits));
          setContactEvent("");

          if (selectedContacts.select_type === "all") {
            SelectAll("all");
          }
          setLoading(false);
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    }, [contactEvent,limits]);
  }

  useEffect(() => {
    setLoading(true);
    if (csvContacts.length > 0) {
      csvRef.current.link.click();
    }
    setLoading(false);
  }, [csvContacts]);

  const handlePageClick = async (data) => {

    setOffSet(limits * data.selected);
    setContactEvent(Math.random);
  };

  const fnDeleteContact = () => {
    const selected_count = selectedContacts.checked;

    confirmAlert({
      title: "",
      message:
        "Are you sure to delete selected " + selected_count + " contact?",
      buttons: [
        {
          label: "Yes",
          onClick: () => fndeleteAllContact(),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const fndeleteAllContact = () => {
    setLoading(true);
    const selected_contacts = selectedContacts.selectd_contacts;
    dispatch(deleteAllContact(selected_contacts, createdby, token))
      .then((response) => {
        if (response.status === 200) {
          notifyInfo(response.message);
          setContactEvent("delete");
          return;
        } else if (response.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  // const deleteContactService = (uuid) => {
  //   dispatch(deleteContact(uuid, createdby, token))
  //     .then((response) => {
  //       setContactEvent("delete");
  //       return;
  //     })
  //     .catch(() => {
  //       setLoading(false);
  //     });
  // };

  const closeModel = (value) => {
    setContactSearchModal(value);
  };

  const closeSaveSearchModel = (value) => {
    setSaveContactSearchModel(value);
  };

  const rowDataPass = (searchData) => {
    const newarr = { ...searchData };

    newarr["limits"] = limits;
    newarr["off_set"] = offSet;
    newarr["orderby"] = orderby;
    newarr["group_per"] = group_per;
    newarr["group_uuid"] = group_uuid;
    newarr["user_type"] = user_type;
    //newarr["is_search"] = true;

    setContactSearchData(newarr);
    setContactEvent(Math.random());
  };

  const saveSearchForm = () => {
    setSaveContactSearchModel(true);
    closeModel(false);
  };

  const fnsaveContactSearch = (group_data) => {
    setLoading(true);
    let contact_uuids = [];

    if(selectedContacts.selectd_contacts.length > 0){
      contact_uuids = selectedContacts.selectd_contacts;
    }else{
      contacts.map(
        (contact) => (contact_uuids = [...contact_uuids, contact.contact_uuid])
      );
    }

    const newarr = { ...contactSearchData };

    newarr["contacts"] = contact_uuids;
    newarr["group_name"] = group_data.group_name;
    newarr["group_uuid"] = group_data.group_uuid;
    newarr["type"] = group_data.type;

    //console.log(newarr);

    dispatch(saveContactSearch(newarr,token))
      .then((response) => {
        if (response.status === 200) {
          notifyInfo(response.message);
          setContactEvent("delete");
          
        } else if (response.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const csvReport = {
    data: csvContacts,
    headers: headers,
    filename: "Contacts.csv",
  };

  const SelectAll = (type) => {
    let contact_uuids = [];
    // if (type === "all") {
    //   let contact_uuids = selectedContacts.selectd_contacts;
    // }

    //{
      contacts.map(
        (contact) => (contact_uuids = [...contact_uuids, contact.contact_uuid])
      );
    //}

    const newarr = [];

    newarr["selectd_contacts"] = contact_uuids;
    newarr["checked"] = type === "all" ? totalContacts : contact_uuids.length;
    newarr["select_type"] = type;

    setSelectedContacts(newarr);

    return;
  };

  const DeSelectAll = () => {
    const newarr = [];
    newarr["selectd_contacts"] = [];
    newarr["checked"] = 0;
    newarr["select_type"] = "none";

    setSelectedContacts(newarr);
  };

  const checkContact = (e, contact_uuid) => {
    const newarr = [];

    if (e.target.checked) {
      //checked : add element
      const newdata = [...selectedContacts.selectd_contacts, contact_uuid];
      newarr["selectd_contacts"] = newdata;
      newarr["checked"] = newdata.length;
    } else {
      //uncheck : remove element
      const newdata = selectedContacts.selectd_contacts.filter(
        (arr) => arr !== contact_uuid
      );

      newarr["selectd_contacts"] = newdata;
      newarr["checked"] = newdata.length;
    }

    newarr["select_type"] = "none";

    setSelectedContacts(newarr);
  };

  const ExportCsv = () => {
    setLoading(true);
    const selected_contacts = selectedContacts.selectd_contacts;
    dispatch(getSelectedContacts(selected_contacts, createdby, token))
      .then((response) => {
        //setContactEvent("delete");

        setCsvContacts(response.data.data);
        setLoading(false);
        return;
        
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const resetSearch = () => {
    //let data = [];

    const data = {
      is_search: false,
      contact_type: "",
      bussiness_name: "",
      contact_title: "",
      first_name: "",
      last_name: "",
      email: "",
      work_contact_number: "",
      other_contact_number: "",
      country: "",
      state: "",
      city: "",
      zipcode: "",
      address: "",
      address2: "",
      token: token,
      createdby: createdby,
      main_uuid: main_uuid,
      user_uuid: createdby,
    };

    rowDataPass(data);
  };

  const chnageLimit = (value) => {
    setLimits(value);
  }

  const breadCurmList = [
    { label: "Home", link: "/", is_active: "NO" },
    { label: "Contacts : Total Amount "+totalContacts, link: "", is_active: "YES" },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
      <div className="row">
        <div className="col-6">
          <PageTitle breadCurmList={breadCurmList} />
        </div>
        <div className="col-6">
          {/* <Button type="button" value="save_search" variant="info btn-sm" onClick={() => saveSearchForm()}>
            Save Search
          </Button> */}
          <span
            role="button"
            style={{ cursor: "pointer" }}
            className="badge badge-info light pull-right mr-2"
            onClick={() => setContactSearchModal(true)}
          >
            Advance Search
          </span>

          {contactSearchData.is_search ? (
            <span
              role="button"
              style={{ cursor: "pointer" }}
              className="badge badge-success light pull-right mr-2"
              onClick={() => saveSearchForm()}
            >
              Save Search
            </span>
          ) : (
            ""
          )}

          {contactSearchData.is_search ? (
            <span
              role="button"
              style={{ cursor: "pointer" }}
              className="badge badge-info light pull-right mr-2"
              onClick={() => resetSearch()}
            >
              Reset Search
            </span>
          ) : (
            ""
          )}
        </div>
      </div>

      {isPermission ? (
        <>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title w-50">
                    Contacts
                    {selectedContacts.checked > 0 ? (
                      <ButtonGroup className="ml-2">
                        <DropdownButton
                          as={ButtonGroup}
                          title="Select Action"
                          id="bg-nested-dropdown"
                          size="sm"
                          variant="btn btn-outline-success"
                        >
                          <Link
                            className="dropdown-item"
                            to="/bulk-assign-contact"
                            state={{
                              contacts: selectedContacts.selectd_contacts,
                            }}
                          >
                            Reassign
                          </Link>

                          <Dropdown.Item
                            eventKey="5"
                            href="#"
                            onClick={() => saveSearchForm()}
                          >
                            Add To Group
                          </Dropdown.Item>

                          <Link
                            className="dropdown-item"
                            to="/bulk-update-contact"
                            state={{
                              contacts: selectedContacts.selectd_contacts,
                            }}
                          >
                            Mass Edit
                          </Link>

                          <Dropdown.Item eventKey="3" href="#">
                            Send Email
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="4" href="#">
                            Send SMS
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="5"
                            href="#"
                            onClick={() => fnDeleteContact()}
                          >
                            Delete
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="6"
                            href="#"
                            onClick={() => ExportCsv()}
                          >
                            Export CSV
                          </Dropdown.Item>
                          <CSVLink {...csvReport} ref={csvRef}></CSVLink>
                        </DropdownButton>
                      </ButtonGroup>
                    ) : (
                      ""
                    )}
                    <small className="ml-2">
                      {selectedContacts.checked > 0
                        ? selectedContacts.checked + " Selected"
                        : ""}
                    </small>
                  </h4>

                  <div>
                    <Button
                      type="button"
                      className="btn btn-sm btn-primary mr-2"
                      onClick={() =>
                        navigate("/import-contact", { replace: true })
                      }
                    >
                      Import Contact
                    </Button>
                    {add_per === "yes" ? (
                      <Button
                        type="button"
                        className="btn btn-sm btn-primary mr-2"
                        onClick={() =>
                          navigate("/add-contact", { replace: true })
                        }
                      >
                        Add New Contact
                      </Button>
                    ) : (
                      <></>
                    )}
                    <Button
                      type="button"
                      className="btn btn-sm btn-primary"
                      onClick={() =>
                        navigate("/custom-fields", { replace: true })
                      }
                    >
                      Custom Fields
                    </Button>
                  </div>
                </div>
                <div className="card-body">
                  Show
                    <ButtonGroup className="ml-2 mr-2">
                      <DropdownButton
                        as={ButtonGroup}
                        id="bg-nested-dropdown"
                        size="sm"
                        title={limits}
                        variant="custom-dropdown"
                      >
                        <Dropdown.Item
                          eventKey="1"
                          href="#"
                          onClick={() => chnageLimit(10)}
                        >
                          10
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="1"
                          href="#"
                          onClick={() => chnageLimit(25)}
                        >
                          25
                        </Dropdown.Item>

                        <Dropdown.Item
                          eventKey="1"
                          href="#"
                          onClick={() => chnageLimit(50)}
                        >
                          50
                        </Dropdown.Item>

                        <Dropdown.Item
                          eventKey="1"
                          href="#"
                          onClick={() => chnageLimit(100)}
                        >
                          100
                        </Dropdown.Item>
                        
                      </DropdownButton>
                    </ButtonGroup>
                    entries
                  <Table responsive className="w-100">
                    <thead>
                      <tr role="row">
                        <th>
                          <DropdownButton
                            title=""
                            id="bg-nested-dropdown"
                            size="sm"
                            variant="btn btn-outline-light"
                          >
                            <Dropdown.Item
                              eventKey="1"
                              href="#"
                              onClick={() => SelectAll("page")}
                            >
                              Select This Page
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="2"
                              href="#"
                              onClick={() => SelectAll("all")}
                            >
                              Select All
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="3"
                              href="#"
                              onClick={() => DeSelectAll()}
                            >
                              DeSelect All
                            </Dropdown.Item>
                          </DropdownButton>
                        </th>
                        <th>Type</th>
                        <th>Bussiness Name</th>
                        <th>Title</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Work Contact</th>
                        {listing_per === "group" || user_type === "admin" ? (
                          <th>Created By</th>
                        ) : (
                          <></>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {contacts.map((contact) => (
                        <tr key={contact.contact_uuid}>
                          <td>
                            <div className="form-check mb-2">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={selectedContacts.selectd_contacts.includes(
                                  contact.contact_uuid
                                )}
                                onChange={(e) =>
                                  checkContact(e, contact.contact_uuid)
                                }
                              />
                              <label className="form-check-label"></label>
                            </div>
                          </td>
                          <td>{contact.contact_type}</td>
                          <td>{contact.bussiness_name}</td>
                          <td>{contact.contact_title}</td>
                          <td>
                            <Link
                              to="/view-contact"
                              state={{
                                contact_uuid: contact.contact_uuid,
                                group_uuid: contact.group_uuid,
                                user_created_by: contact.user_created_by,
                              }}
                              className="text-success"
                            >
                              {contact.first_name} {contact.last_name}
                            </Link>
                          </td>
                          <td>{contact.email}</td>
                          <td>{contact.work_contact_number}</td>
                          {listing_per === "group" ||
                          listing_per === "all" ||
                          user_type === "admin" ? (
                            <td>
                              {contact.u_fname} {contact.u_lname}
                            </td>
                          ) : (
                            <></>
                          )}
                          <td>
                            {/* <Fragment>
                              <div className="d-flex">
                                {
                                  <Link
                                    to="/view-contact"
                                    state={{
                                      contact_uuid: contact.contact_uuid,
                                    }}
                                    hidden={PermissionCheck(
                                      module_name,
                                      "edit",
                                      contact.group_uuid,
                                      contact.user_created_by,
                                      contact.created_by
                                    )}
                                    className="btn btn-primary shadow btn-xs sharp mr-1"
                                  >
                                    <i className="fa fa-pencil"></i>
                                  </Link>
                                }
                                <Link
                                  to="#"
                                  onClick={() =>
                                    fnDeleteContact(contact.contact_uuid)
                                  }
                                  hidden={PermissionCheck(
                                    module_name,
                                    "delete",
                                    contact.group_uuid,
                                    contact.user_created_by,
                                    contact.created_by
                                  )}
                                  className="btn btn-danger shadow btn-xs sharp"
                                >
                                  <i className="fa fa-trash"></i>
                                </Link>
                              </div>
                            </Fragment> */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <Pagination
                    pageCount={pageCount}
                    handlePageNumberClick={handlePageClick}
                  />
                </div>
              </div>
            </div>
          </div>
          <Modal
            className="fade bd-example-modal-lg"
            show={addContactSearchModal}
            size="lg"
          >
            <ContactSearchForm
              closeModel={closeModel}
              rowDataPass={rowDataPass}
              contactSearchData={contactSearchData}
              //saveSearchForm={saveSearchForm}
            />
          </Modal>

          <Modal className="fade" show={saveContactSearchModel}>
            <SaveSearchForm
              closeSaveSearchModel={closeSaveSearchModel}
              saveSearch={fnsaveContactSearch}
            />
          </Modal>
        </>
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Manage Contacts</h4>
              </div>
              <div className="card-body">
                <h3>You don't have access to this modules</h3>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Contacts;
