import React, { Fragment, useState, useEffect } from "react";
import { Tab, Nav, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../layouts/PageTitle";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import { createExtension, getNextExtensionID } from "../../actions/extensions";
import Tooltip from "rc-tooltip";
import Loader from "../../layouts/Loader";

function ExtensionForm(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const [extensionData, setExtensionData] = useState({
    extension: "",
    extension_password: "",
    voicemail_enabled: "NO",
    call_waiting_enabled: "YES",
    call_waiting_timeout: "20",
    dnd_enabled: "",
    createdby: createdby,
    main_admin_uuid: main_uuid,
    user_uuid: createdby,
  });

  const [errors, setErrors] = useState({
    extension: "",
    extension_password: "",
  });

  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBlur = (e) => {
    const newExtensionData = { ...extensionData };
    let error_data = { ...errors };

    if (
      (e.target.name === "extension" || e.target.name === "extension_password") &&
      e.target.value.length === 0
    ) {
      if (e.target.name === "extension_password") {
        error_data[e.target.name] = "Please enter extension password";
      } else {
        error_data[e.target.name] = e.target.name + " is required.";
      }
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }
    newExtensionData[e.target.name] = e.target.value;
    setExtensionData(newExtensionData);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (!extensionData.extension) {
      error_data["extension"] = "Please enter extension.";
      setErrors(error_data);
      isError = "true";
    } else if (!/[0-9]/i.test(extensionData.extension)) {
      error_data["extension"] = "Invalid extension.";
      setErrors(error_data);
      isError = "true";
    }

    if (!extensionData.extension_password) {
      error_data["extension_password"] = "Please enter extension password.";
      setErrors(error_data);
      isError = "true";
    }

    // if (!/^[0-9\s]*$/i.test(extensionData.effective_caller_id_number)) {
    //   error_data["effective_caller_id_number"] =
    //     "Invalid effective caller id number.";
    //   setErrors(error_data);
    //   isError = "true";
    // }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create user API
      dispatch(createExtension(extensionData, token))
        .then((res) => {
          if (res.status === 200) {
            notifyInfo(res.message);

            let extension_uuid = res.data;
            navigate("/update-extension", {
              replace: true,
              state: { extension_uuid: extension_uuid, activeTab: "voicemail" },
            }); //data
          } else if (res.status === 406) {
            error_data["extension"] = res.message;
            setErrors(error_data);
            isError = "true";
          } else if (res.status === 500) {
            notifyError("Something went wrong.");
          } else {
            notifyError("Something went wrong.");
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    dispatch(getNextExtensionID(createdby, main_uuid, token))
      .then((response) => {
        const newExtensionData = { ...extensionData };
        newExtensionData["extension"] = response.data.extension;
        setExtensionData(newExtensionData);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const [extPassType, setExtPassType] = useState("password");

  const toggleExtPass = () => {
    if (extPassType === "password") {
      setExtPassType("text");
      return;
    }
    setExtPassType("password");
  };

  const handleChange = (e, default_val) => {
    let value = "";
    if (default_val === "YES") {
      value = "NO";
    } else {
      value = "YES";
    }

    let data = { ...extensionData };
    data[e.target.name] = value;
    setExtensionData(data);
  };

  const show_alert = (e) => {
    e.preventDefault();
    alert("save general information.");
  };

  const breadCurmList = [
    { label: "Calls", link: "/", is_active: "NO" },
    { label: "Extensions", link: "/extensions", is_active: "NO" },
    { label: "Create New Extension", link: "", is_active: "YES" },
  ];

  let number_array = new Array(30).fill(1).map((_, i) => {
    return { label: i + 1, value: i + 1 };
  });

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />
      <Tab.Container defaultActiveKey="general">
        <Nav as="ul" className="nav-tabs extensiontabs mt-4">
          <Nav.Item as="li" key="1">
            <Nav.Link eventKey="general">
              <i className={`la la-tty mr-2`} />
              General
            </Nav.Link>
          </Nav.Item>

          <Nav.Item as="li" key="2">
            <Nav.Link onClick={(e) => show_alert(e)}>
              <i className={`la la-voicemail mr-2`} />
              Voicemail
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li" key="3">
            <Nav.Link onClick={(e) => show_alert(e)}>
              <i className={`la la-share-square mr-2`} />
              Forwarding
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content className="extensionpage">
          <Tab.Pane eventKey="general" key="1" className="pt-4">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-6">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">
                      Extension
                      <Tooltip placement="top" overlay={<span>Extension</span>}>
                        <span className="ti-info-alt ml-1"></span>
                      </Tooltip>
                    </h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="ml-3 form-group input-primary">
                          <label className="w-100">
                            Extension
                            <Tooltip
                              placement="top"
                              overlay={<span>Extension</span>}
                            >
                              <span className="ti-info-alt ml-1"></span>
                            </Tooltip>
                          </label>
                          <p>{extensionData.extension}</p>
                        </div>
                      </div>
                      <div className="col-lg-5 col-md-5 col-5">
                        <div className="form-group input-primary">
                          <label className="w-100">
                            Password
                            <span className="text-danger">*</span>
                            <Tooltip
                              placement="top"
                              overlay={<span>Password</span>}
                            >
                              <span className="ti-info-alt ml-1"></span>
                            </Tooltip>
                          </label>

                          <input
                            type={extPassType}
                            className="form-control bg-white"
                            //placeholder="Extension Password"
                            name="extension_password"
                            onChange={handleBlur}
                          />
                          <button
                            type="button"
                            className="btn btn-outline-warning pass_in"
                            onClick={toggleExtPass}
                          >
                            {extPassType === "password" ? (
                              <i className="fa fa-eye-slash"></i>
                            ) : (
                              <i className="fa fa-eye"></i>
                            )}
                          </button>
                          <span className="text-danger">
                            {errors.extension_password}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-6">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">
                      Call Waiting
                      <Tooltip
                        placement="top"
                        overlay={<span>Call Waiting</span>}
                      >
                        <span className="ti-info-alt ml-1"></span>
                      </Tooltip>
                    </h4>

                    <span className="custom-control custom-switch float-right">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="call_waiting_enabled"
                        name="call_waiting_enabled"
                        checked={
                          extensionData.call_waiting_enabled === "YES"
                            ? "checked"
                            : ""
                        }
                        onChange={(e) =>
                          handleChange(e, extensionData.call_waiting_enabled)
                        }
                      />
                      <label
                        className="custom-control-label check_box_pointer"
                        htmlFor="call_waiting_enabled"
                      ></label>
                    </span>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="ml-3 form-group input-primary">
                          <label className="w-100">
                            Waiting Timeout
                            <Tooltip
                              placement="top"
                              overlay={<span>Waiting Timeout</span>}
                            >
                              <span className="ti-info-alt ml-1"></span>
                            </Tooltip>
                          </label>
                          <select
                            className="form-control bg-white"
                            name="call_waiting_timeout"
                            onChange={handleBlur}
                            defaultValue={extensionData.call_waiting_timeout}
                          >
                            {number_array.map((user) => (
                              <option key={user.label} value={user.value}>
                                {user.value}
                              </option>
                            ))}
                          </select>

                          <span className="text-danger">
                            {errors.call_waiting_timeout}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-6 col-6">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">
                      Do Not Disturb (DND)
                      <Tooltip
                        placement="top"
                        overlay={<span>Do Not Disturb (DND)</span>}
                      >
                        <span className="ti-info-alt ml-1"></span>
                      </Tooltip>
                    </h4>
                    <span className="custom-control custom-switch float-right">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="dnd_enabled"
                        name="dnd_enabled"
                        checked={
                          extensionData.dnd_enabled === "YES" ? "checked" : ""
                        }
                        onChange={(e) =>
                          handleChange(e, extensionData.dnd_enabled)
                        }
                      />
                      <label
                        className="custom-control-label check_box_pointer"
                        htmlFor="dnd_enabled"
                      ></label>
                    </span>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="ml-3 form-group input-primary">
                        <div className="col-lg-12 col-md-12 col-12">
                          <label className="w-100">
                            Calls will go to voicemail when DND is on
                          </label>
                          <p>
                            {extensionData.dnd_enabled === "YES"
                              ? "Enabled"
                              : "Disabled"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row text-right">
              <div className="col-sm">
                <Button
                  type="submit"
                  className="btn btn-sm btn-primary"
                  onClick={(e) => submitHandler(e)}
                >
                  Save
                </Button>
              </div>
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Fragment>
  );
}

export default ExtensionForm;
