import React, { Fragment,useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, } from "react-bootstrap";
import { useDispatch } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css";

import PageTitle from "../../../layouts/PageTitle";
import FieldSection from "./FieldSection";

import { getContactSetting } from "../../../actions/contacts";

function FieldSettings() {

    const [contactSettings, setContactSettings] = useState([]);
    const [contactFormSection, setContactFormSection] = useState([]);
    const [contactFieldOrder, setContactFieldOrder] = useState([]);

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const user = JSON.parse(localStorage.getItem("user"));
    const user_data = user.data.data;
    const token = user.data.token;
    const createdby = user_data.user_uuid;

    useEffect(() => {

        dispatch(getContactSetting(createdby,token))
            .then((response) => {
               
                setContactSettings(response.data.form_setting[0]);
                setContactFormSection(response.data.form_section);
                setContactFieldOrder(response.data.form_field);
               
                return;
            })
            .catch(() => {
                //setLoading(false);
            });        

    }, []);
   
    const submitHandler = (e) => {
        e.preventDefault();
    }

    const handleBlur = (e) => {
        console.log(e);
    }

    const ShowAddsectionForm = () => {

    }

    return(
        <Fragment>
            <PageTitle activeMenu="Contact Settings" motherMenu="Home" />
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Contact Form Setting</h4>
                            <div>
                                <Button type="button" className="btn btn-sm btn-default mr-2" onClick={() => navigate("/contacts", { replace: true })}>
                                    <i className="fa fa-reply" aria-hidden="true"></i> Back To Contacts
                                </Button>
                            </div>
                        </div>
                        <div className="card-body">
                            <form action="" onSubmit={(e) => submitHandler(e)}>
                                <div className="row">
                                    <div className="col-sm">
                                        <div className="form-group">
                                            <label className="mb-2">
                                            <strong>Form type</strong>
                                            </label>
                                            <div className="form-group mb-0">
                                                <label className="radio-inline mr-3">
                                                    <input type="radio"  name="form_type" value="simple" checked={contactSettings.form_type === "simple"} onChange={handleBlur}/> Simple
                                                </label>
                                                <label className="radio-inline mr-3">
                                                    <input type="radio" name="form_type" value="tab" checked={contactSettings.form_type === "tab"} onChange={handleBlur}/> Tab 
                                                </label>
                                                <label className="radio-inline mr-3">
                                                    <input type="radio" name="form_type" value="accordian" checked={contactSettings.form_type === "accordian"} onChange={handleBlur}/> Accordian 
                                                </label>
                                            </div>
                                            {/* <span className="text-danger">{errors.form_type}</span> */}
                                        </div>
                                    </div>

                                    <div className="col-sm">
                                        <div className="form-group">
                                            <label className="mb-1">
                                            <strong>No. of Sections</strong>
                                            </label>
                                            <div className="form-row">
                                                <div className="col-sm-8">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="No. of Sections"
                                                        name="no_of_section"
                                                        defaultValue={contactSettings.no_of_section}
                                                        onChange={handleBlur}
                                                        readOnly
                                                    />
                                                    {/* <span className="text-danger">{errors.no_of_section}</span> */}
                                                </div>

                                                <div className="col-sm-4">
                                                    <button type="button" className="btn btn-sm btn-success" onClick={ShowAddsectionForm}><i className="fa fa-plus"></i></button>
                                                </div>
                                            </div>
                                                                                       
                                            
                                        </div>
                                        
                                    </div>

                                </div>

                                <FieldSection 
                                    contactFormSection={contactFormSection} 
                                    contactFieldOrder={contactFieldOrder}
                                />
                            </form>             

                        </div>                        
                    </div>
                </div>
            </div>
            
           {/* <Modal className="fade" show={addCustomFieldModal}>
                <CustomFieldForm closeModel={closeModel} handleCustomFieldEvent={handleCustomFieldEvent}/>
            </Modal> */}
        </Fragment>        
    );

}

export default FieldSettings;