import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const login = (email, password) => {
  return axios
    .post(API_URL + "login", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.status === 200) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.clear();
};

const forgotPassword = (email) => {
  return axios
    .post(API_URL + "forgot-password", {
      email,
    })
    .then((response) => {
      return response.data;
    });
};

const register = (data) => {
  return axios.post(API_URL + "user-registration", data).then((response) => {
    // if (response.data.status == '200') {

    // }
    return response.data;
  });
};

const checkUserEmail = (data) => {
  return axios.post(API_URL + "check-user-email", data).then((response) => {
    return response.data;
  });
};

const checkToken = (token) => {
  return axios
    .post(API_URL + "check-user-token", { token })
    .then((response) => {
      return response.data;
    });
};

const createPassword = (passData, token) => {
  return axios
    .post(API_URL + "create-new-password", passData, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const createNewLink = (emailData, token) => {
  return axios
    .post(API_URL + "create-new-link", emailData, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const transactionCaller = (data, formData) => {
  return axios
    .post(API_URL + "transaction-caller", {
      data,
      formData,
    })
    .then((response) => {
      return response.data;
    });
};

const registrationStatus = (token, user_uuid, createdby) => {
  return axios
    .post(
      API_URL + "check-onboarding-Status",
      {
        user_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateAdminPersonalInfo = (data, token) => {
  console.log(data, "===data===11");
  return axios
    .post(API_URL + "update-admin-personal-info", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const updateCompanyInfo = (data, token) => {
  return axios
    .post(API_URL + "update-admin-company-info", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getE911Details = (user_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "get-e911-details",
      { user_uuid, createdby },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const updateE911Data = (data, token) => {
  return axios
    .post(API_URL + "update-e911-data", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getProfileImage = (user_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "get-profile-image",
      { user_uuid, createdby },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

export default {
  login,
  logout,
  forgotPassword,
  register,
  checkUserEmail,
  checkToken,
  createPassword,
  createNewLink,
  transactionCaller,
  registrationStatus,
  updateAdminPersonalInfo,
  updateCompanyInfo,
  getE911Details,
  updateE911Data,
  getProfileImage,
};
