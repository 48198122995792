import React, { Fragment, useState, useEffect} from "react";
import { Modal, Button,Table,DropdownButton,
  Dropdown, } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Loader from "../../../layouts/Loader";
import {
  getContactList,
  saveContactSearch,
} from "../../../actions/contacts";
import PermissionCheck from "../../../common/PermissionCheck";
import Pagination from "../../../layouts/Pagination";
import { notifyInfo, notifyError } from "../../Toastr/Toastr";
import Tooltip from "rc-tooltip";

function AddContactForm(props) {

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;
  const module_name = "contacts";

  let orderby = "c.created_at DESC";

  let listing_per = PermissionCheck(module_name, "listing", "", "", "");

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }
  
  const closeModel = (arg) => {
    props.closeAddModel(arg);
  };

  let selected_group_uuid = props.selected_group_uuid

  const [loading, setLoading] = useState(false);
  const [contactEvent, setContactEvent] = useState("");
  const [contacts, setContacts] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [offSet, setOffSet] = useState(0);
  const [totalContacts, setTotalContacts] = useState(0);
  const limits = 10;
  const [search, setSearch] = useState("");

  const [contactSearchData, setContactSearchData] = useState({
    is_search: false,
    limits: limits,
    off_set: offSet,
    orderby: orderby,
    group_per: group_per,
    group_uuid: group_uuid,
    user_type: user_type,
    contact_type: "",
    bussiness_name: "",
    contact_title: "",
    first_name: "",
    last_name: "",
    email: "",
    work_contact_number: "",
    other_contact_number: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
    address: "",
    address2: "",
    search: search,
    createdby: createdby,
    main_uuid: main_uuid,
    user_uuid: createdby,
  });

  const dispatch = useDispatch();

  const [selectedContacts, setSelectedContacts] = useState({
    selectd_contacts: [],
    checked: 0,
    select_type: "none",
  });

  useEffect(() => {
    setLoading(true);

    const newarr = { ...contactSearchData };
      newarr["off_set"] = offSet;

    dispatch(getContactList(newarr, token))
      .then((response) => {
        setContacts(response.data.data);
          const total = response.data.total;
          setTotalContacts(total);

          setpageCount(Math.ceil(total / limits));
          setContactEvent("");

          if (selectedContacts.select_type === "all") {
            SelectAll("all");
          }
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, [contactEvent]);

  const SelectAll = (type) => {
    let contact_uuids = [];

      contacts.map(
        (contact) => (contact_uuids = [...contact_uuids, contact.contact_uuid])
      );

    const newarr = [];

    newarr["selectd_contacts"] = contact_uuids;
    newarr["checked"] = type === "all" ? totalContacts : contact_uuids.length;
    newarr["select_type"] = type;

    setSelectedContacts(newarr);

    return;
  };

  const DeSelectAll = () => {
    const newarr = [];
    newarr["selectd_contacts"] = [];
    newarr["checked"] = 0;
    newarr["select_type"] = "none";

    setSelectedContacts(newarr);
  };

  const checkContact = (e, contact_uuid) => {
    const newarr = [];

    if (e.target.checked) {
      //checked : add element
      const newdata = [...selectedContacts.selectd_contacts, contact_uuid];
      newarr["selectd_contacts"] = newdata;
      newarr["checked"] = newdata.length;
    } else {
      //uncheck : remove element
      const newdata = selectedContacts.selectd_contacts.filter(
        (arr) => arr !== contact_uuid
      );

      newarr["selectd_contacts"] = newdata;
      newarr["checked"] = newdata.length;
    }

    newarr["select_type"] = "none";

    setSelectedContacts(newarr);
  };

  const handlePageClick = async (data) => {

    setOffSet(limits * data.selected);
    setContactEvent(Math.random);
  };

  
  const submitHandler = (e) => {
    e.preventDefault();

    setLoading(true);
    let contact_uuids = [];

    if(selectedContacts.selectd_contacts.length > 0){
      contact_uuids = selectedContacts.selectd_contacts;
    }else{
      contacts.map(
        (contact) => (contact_uuids = [...contact_uuids, contact.contact_uuid])
      );
    }

    const newarr = { ...contactSearchData };

    newarr["contacts"] = contact_uuids;
    newarr["group_name"] = '';
    newarr["group_uuid"] = selected_group_uuid;
    newarr["type"] = 'update';

    dispatch(saveContactSearch(newarr,token))
      .then((response) => {
        
        if (response.status === 200) {
          notifyInfo(response.message);
          closeModel(false);
          props.handleGroupEvent(Math.random);
          
        } else if (response.status === 500) {
          notifyError("Something went wrong.");
          
        } else {
          notifyError("Something went wrong.");
          
        }
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  
  };


  const handleSearchChange = (e) => {
    setSearch(e.target.value);

  };

  const handleSearch = (e) => {
    const newarr = { ...contactSearchData };

    newarr["search"] = search;
    setContactSearchData(newarr);

    setContactEvent(Math.random);
  }

  const Groups = (props) => {
    let contact_group_names = props.contact_group_names;

    if (contact_group_names && contact_group_names.length > 0) {
      return (
        <div>
          {contact_group_names.map((group, index) => {
            return (
              <div key={index}>
                {group}
              </div>
            );
          })}
        </div>
      );
    } else {
      return "None";
    }
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>Select Contacts To add
          

        </Modal.Title>
        <div className="input-group input-success w-50 pull-right mr-5">
                    <input
                      className="form-control text-primary"
                      type="search"
                      placeholder="Search Here"
                      aria-label="Search"
                      onChange={(e) => handleSearchChange(e)}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text text-primary" style={{cursor:"pointer"}} onClick={(e) => handleSearch(e)}>
                        <svg
                          width={20}
                          height={20}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                            fill="#A4A4A4"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>

        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <form action="" onSubmit={(e) => submitHandler(e)}>
        <Modal.Body>
        <Table responsive className="w-100">
                    <thead>
                      <tr role="row">
                        <th>
                          <DropdownButton
                            title=""
                            id="bg-nested-dropdown"
                            size="sm"
                            variant="btn btn-outline-light"
                          >
                            <Dropdown.Item
                              eventKey="1"
                              href="#"
                              onClick={() => SelectAll("page")}
                            >
                              Select This Page
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="2"
                              href="#"
                              onClick={() => SelectAll("all")}
                            >
                              Select All
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="3"
                              href="#"
                              onClick={() => DeSelectAll()}
                            >
                              DeSelect All
                            </Dropdown.Item>
                          </DropdownButton>
                        </th>
                        <th>Type</th>
                        <th>Bussiness Name</th>
                        <th>Title</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Work Contact</th>
                        <th>Groups</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contacts.map((contact) => (
                        <tr key={contact.contact_uuid}>
                          <td>
                            <div className="form-check mb-2">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={selectedContacts.selectd_contacts.includes(
                                  contact.contact_uuid
                                )}
                                onChange={(e) =>
                                  checkContact(e, contact.contact_uuid)
                                }
                              />
                              <label className="form-check-label"></label>
                            </div>
                          </td>
                          <td>{contact.contact_type}</td>
                          <td>{contact.bussiness_name}</td>
                          <td>{contact.contact_title}</td>
                          <td>
                              {contact.first_name} {contact.last_name}
                          </td>
                          <td>{contact.email}</td>
                          <td>{contact.work_contact_number}</td>
                          <td>
                            <Tooltip
                              placement="top"
                              overlay={
                                <Groups
                                  contact_group_names={contact.contact_group_name}
                                  
                                />
                              }
                            >
                              <span className="text-blue font-weight-bold">
                                {contact.contact_group_name ? contact.contact_group_name.length : 0}
                              </span>
                            </Tooltip>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <Pagination
                    pageCount={pageCount}
                    handlePageNumberClick={handlePageClick}
                  />
         
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => closeModel(false)} variant="danger light btn-sm">
            Close
          </Button>
          {selectedContacts.checked > 0 && (
            <Button type="submit" value = "save" variant="primary btn-sm">
              Add
            </Button> 
          )}
        </Modal.Footer>
      </form>
    </Fragment>
  );
}

export default AddContactForm;
