import React, { Fragment, useState } from "react";
import "./style.css";
import { Button, Modal } from "react-bootstrap";
import { RangeDatePicker } from "@y0c/react-datepicker";
// import { getAreaCodeList } from "./../../actions/comman";

function FilterForm(props) {

  const [searchData, setSearchData] = useState({
    call_date: "",
    did:"",
    caller_id_name: "",
    caller_id_number: "",
    destination_number: "",
    direction: "",
  });

  const handleChange = (e) => {
    let new_data = { ...searchData };
    new_data[e.target.name] = e.target.value;
    setSearchData(new_data);
    new_data["type"] = "search";
    return;
  };

  const fnSearch = () => {
 
      //props.closeModel(false);
     // props.filterInboundNumber(didData);
    
    //props.applySearch(searchData); 
    //return;  
  };

  const fnResetFilter = () => {
    let new_data = { ...searchData };
    new_data["caller_id_name"] = "";
    new_data["caller_id_number"] = "";
    new_data["destination_number"] = "";
    new_data['direction'] = "";
    new_data["type"] = "reset";
    setSearchData(new_data);

    //props.applySearch(new_data);   
    return;
  };

  return (
    <Fragment>
      <Modal.Body>
        <div className="row">         
          <div className="col-xl-6 col-xxl-6 col-lg-6">
            <div className="form-group input-primary">
              <label>Call Date</label>
              {/* <input
                type="text"
                className="form-control bg-white"
                placeholder=""
                name="call_date"
                value={searchData.call_date}
                onChange={handleChange}
              /> */}
              <RangeDatePicker
                  startText="Start"
                  endText="End"
                  startPlaceholder="Start Date"
                  endPlaceholder="End Date"
              />
            </div>
          </div>
        </div>
        <div className="row">      
          <div className="col-xl-6 col-xxl-6 col-lg-6">
            <div className="form-group input-primary">
              <label>DID</label>
              <input
                type="text"
                className="form-control bg-white"
                placeholder=""
                name="did"
                value={searchData.did}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="col-xl-6 col-xxl-6 col-lg-6">
            <label>&nbsp;</label>
            <div className="form-group">
              
              <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        value=""
                        defaultChecked
                    />
                    Not
                  </label>
              </div>
              <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                        type="radio"
                        className="form-check-input"
                        value=""
                    />
                    Begins with
                  </label>
              </div>
              <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                        type="radio"
                        className="form-check-input"
                        value=""
                    />
                    Contains
                  </label>
              </div>
              <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                        type="radio"
                        className="form-check-input"
                        value=""
                    />
                    Ends with
                  </label>
              </div>
              <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                        type="radio"
                        className="form-check-input"
                        value=""
                    />
                    Exactly
                  </label>
              </div>
              
            </div>
          </div>

        </div>
        
        <div className="row">         
          <div className="col-xl-6 col-xxl-6 col-lg-6">
            <div className="form-group input-primary">
              <label>Caller ID Name</label>
              <input
                type="text"
                className="form-control bg-white"
                placeholder=""
                name="caller_id_name"
                value={searchData.caller_id_name}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="col-xl-6 col-xxl-6 col-lg-6">
            <label>&nbsp;</label>
            <div className="form-group">
              
              <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        value=""
                        defaultChecked
                    />
                    Not
                  </label>
              </div>
              <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                        type="radio"
                        className="form-check-input"
                        value=""
                    />
                    Begins with
                  </label>
              </div>
              <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                        type="radio"
                        className="form-check-input"
                        value=""
                    />
                    Contains
                  </label>
              </div>
              <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                        type="radio"
                        className="form-check-input"
                        value=""
                    />
                    Ends with
                  </label>
              </div>
              <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                        type="radio"
                        className="form-check-input"
                        value=""
                    />
                    Exactly
                  </label>
              </div>
              
            </div>
          </div>

        </div>

        <div className="row">  
          <div className="col-xl-6 col-xxl-6 col-lg-6">
            <div className="form-group input-primary">
              <label>Caller ID Number</label>
              <input
                type="text"
                className="form-control bg-white"
                placeholder=""
                name="caller_id_number"
                value={searchData.caller_id_number}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-xl-6 col-xxl-6 col-lg-6">
            <label>&nbsp;</label>
            <div className="form-group">
              
              <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        value=""
                        defaultChecked
                    />
                    Not
                  </label>
              </div>
              <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                        type="radio"
                        className="form-check-input"
                        value=""
                    />
                    Begins with
                  </label>
              </div>
              <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                        type="radio"
                        className="form-check-input"
                        value=""
                    />
                    Contains
                  </label>
              </div>
              <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                        type="radio"
                        className="form-check-input"
                        value=""
                    />
                    Ends with
                  </label>
              </div>
              <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                        type="radio"
                        className="form-check-input"
                        value=""
                    />
                    Exactly
                  </label>
              </div>
              
            </div>
          </div>
        </div>

        <div className="row">         
          <div className="col-xl-6 col-xxl-6 col-lg-6">
            <div className="form-group input-primary">
              <label>Destination</label>
              <input
                type="text"
                className="form-control bg-white"
                placeholder=""
                name="destination_number"
                value={searchData.destination_number}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="col-xl-6 col-xxl-6 col-lg-6">
            <label>&nbsp;</label>
            <div className="form-group">
              
              <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        value=""
                        defaultChecked
                    />
                    Not
                  </label>
              </div>
              <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                        type="radio"
                        className="form-check-input"
                        value=""
                    />
                    Begins with
                  </label>
              </div>
              <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                        type="radio"
                        className="form-check-input"
                        value=""
                    />
                    Contains
                  </label>
              </div>
              <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                        type="radio"
                        className="form-check-input"
                        value=""
                    />
                    Ends with
                  </label>
              </div>
              <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                        type="radio"
                        className="form-check-input"
                        value=""
                    />
                    Exactly
                  </label>
              </div>
              
            </div>
          </div>

        </div>
        <div className="row">  
          <div className="col-xl-6 col-xxl-6 col-lg-6">
            <div className="form-group input-primary">
              <label>Direction</label>
              <input
                type="text"
                className="form-control bg-white"
                placeholder=""
                name="direction"
                value={searchData.direction}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="col-xl-6 col-xxl-6 col-lg-6">
            <label>&nbsp;</label>
            <div className="form-group">
              
              <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        value=""
                        defaultChecked
                    />
                    Not
                  </label>
              </div>
              <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                        type="radio"
                        className="form-check-input"
                        value=""
                    />
                    Begins with
                  </label>
              </div>
              <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                        type="radio"
                        className="form-check-input"
                        value=""
                    />
                    Contains
                  </label>
              </div>
              <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                        type="radio"
                        className="form-check-input"
                        value=""
                    />
                    Ends with
                  </label>
              </div>
              <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                        type="radio"
                        className="form-check-input"
                        value=""
                    />
                    Exactly
                  </label>
              </div>
              
            </div>
          </div>

        </div>
        
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger light btn-sm" onClick={() => fnResetFilter()}>
          Reset
        </Button>
        <Button
          variant=""
          type="button"
          onClick={() => fnSearch("")}
          className="btn btn-sm btn-primary"
        >
          Apply Search
        </Button>
      </Modal.Footer>
    </Fragment>
  );
}

export default FilterForm;
