import React, { Fragment, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from "../../layouts/Loader";
import { updateGroup, getGroupDetail } from "../../actions/group";
import { getUserListDropDown } from "../../actions/user";
import { getDropDownUserList } from "../../actions/comman";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import Select from "react-select";
import PageTitle from "../../layouts/PageTitle";

function UpdateGroupForm(props) {

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;

  const [groupUserData, setGroupUserData] = useState([]);
  const [groupOwnerData, setGroupOwnerData] = useState();

  const params = useLocation();
  const group_uuid = params.state.group_uuid;

  const [groupData, setGroupData] = useState({
    groupuuid: "",
    name: "",
    assign_user_data: "",
    group_owner: "",
    token: token,
    createdby: createdby,
  });

  const [errors, setErrors] = useState({
    name: "",
    assign_user_data: "",
    group_owner: "",
  });

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersAll, setUsersAll] = useState([]);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBlur = (e) => {
    const newGroupData = { ...groupData };
    let error_data = { ...errors };
    if (e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }
    newGroupData[e.target.name] = e.target.value;
    setGroupData(newGroupData);
  };

  function handleSelect(data) {
    //setSelectedOptions(data);
    groupData["assign_user_data"] = data;
    setGroupUserData(data);
    setGroupData(groupData);
  }

  function handleSelect1(data1) {
    //setSelectedOptions1(data1);
    groupData["group_owner"] = data1;

    setGroupOwnerData(data1);
    setGroupData(groupData);
  }

  useEffect(() => {
    dispatch(getUserListDropDown(createdby, token))
      .then((response) => {
        setUsers(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    dispatch(getGroupDetail(createdby, token, group_uuid))
      .then((response) => {
        setGroupUserData(response.data.groupUserData);
        setGroupOwnerData(response.data.groupOwnerData);

        const newarr = { ...groupData };

        newarr["groupuuid"] = response.data.groupData.uuid;
        newarr["name"] = response.data.groupData.name;
        newarr["assign_user_data"] = response.data.groupUserData;
        newarr["group_owner"] = response.data.groupOwnerData[0];
        setGroupData(newarr);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    dispatch(getDropDownUserList(createdby, token, main_uuid, user_type))
      .then((response) => {
        setUsersAll(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (groupData.name.length === 0) {
      error_data["name"] = "Name is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (groupData.assign_user_data.length === 0) {
      error_data["assign_user_data"] = "Please select atleat one User.";
      setErrors(error_data);
      isError = "true";
    }

    if (groupData.group_owner.length === 0) {
      error_data["group_owner"] = "Please select group owner.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create user API
      const data = { ...groupData };

      dispatch(updateGroup(data))
        .then((res) => {
          if (res.status === 200) {
            notifyInfo(res.message);
            setLoading(false);
            navigate("/groups", { replace: true });
          } else if (res.status === 406) {
            error_data["name"] = res.message;
            setErrors(error_data);
            isError = "true";
          } else if (res.status === 500) {
            notifyError("Something went wrong.");
          } else {
            notifyError("Something went wrong.");
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const breadCurmList = [
    { label: "Roles & Permissions", link: "/roles", is_active: "NO" },
    { label: "Groups", link: "/groups", is_active: "NO" },
    { label: "Update Group : " + groupData.name, link: "", is_active: "YES" },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Update Group - {groupData.name}</h4>
              <Button
                type="button"
                className="btn btn-default btn-sm"
                onClick={() => navigate("/groups", { replace: true })}
              >
                <i className="fa fa-reply" aria-hidden="true"></i> Back
              </Button>
            </div>
            <div className="card-body">
              <form action="" onSubmit={(e) => submitHandler(e)}>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Name</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        name="name"
                        value={groupData.name}
                        onChange={handleBlur}
                      />
                      <span className="text-danger">{errors.name}</span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Group Owner</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <Select
                        className="form-control"
                        options={usersAll}
                        name="group_owner"
                        placeholder="Select Group Owner"
                        value={groupOwnerData}
                        onChange={handleSelect1}
                        isSearchable={true}
                      />
                      <span className="text-danger">{errors.group_owner}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Assign Users</strong>
                        <span className="text-danger">*</span>
                      </label>

                      <Select
                        className="form-control"
                        options={users}
                        name="assign_user_data"
                        placeholder="Select User"
                        value={groupUserData}
                        onChange={handleSelect}
                        isSearchable={true}
                        isMulti
                      />

                      <span className="text-danger">
                        {errors.assign_user_data}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row text-right">
                  <div className="col-sm">
                    <Button type="submit" variant="primary" className="btn-sm">
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default UpdateGroupForm;
