import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const getcontactGroupList = (
  user_type,
  group_per,
  group_uuid,
  search,
  off_set,
  limits,
  orderby,
  main_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "get-contact-group-list",
      {
        user_type,
        group_per,
        group_uuid,
        search,
        off_set,
        limits,
        orderby,
        createdby,
        main_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateContactGroup = (data) => {
  return axios
    .post(API_URL + "update-contact-group", data, {
      headers: {
        Authorization: data.token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};


const getContactGroupDetail = (createdby, token, contact_group_uuid,limits, offSet) => {
  return axios
    .post(
      API_URL + "get-contact-group-detail",
      {
        createdby,
        contact_group_uuid,
        limits, 
        offSet,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const deletecontactGroup = (uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "delete-contact-group",
      {
        uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const removeGroupContact = (contact_group_detail_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "remove-group-contact",
      {
        contact_group_detail_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const moveGroupContact = (data, token) => {
  return axios
    .post(
      API_URL + "move-group-contact", data,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};


export default {
  getcontactGroupList,
  updateContactGroup,
  getContactGroupDetail,
  deletecontactGroup,
  removeGroupContact,
  moveGroupContact,
};
