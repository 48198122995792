import React, { Fragment,useState, useEffect } from "react";
import { Modal,Button, } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from "../../layouts/Loader";
import { getRoleList } from "../../actions/role";
import { insertUserCsvData } from "../../actions/user";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";

function UserInviteForm(props) {
    
    const closeModel = (arg) => {
        props.closeInviteModel(arg);
    }

    const user = JSON.parse(localStorage.getItem("user"));
    const user_data = user.data.data;
    const token = user.data.token;
    const createdby = user_data.user_uuid;
    const main_uuid = user_data.main_uuid;
      
    const [loading, setLoading] = useState(false);

    let navigate = useNavigate();   
    const dispatch = useDispatch();
    
    const [currentStep, setCurrentStep] = useState(1);
    const [fileName, setFileName] = useState("");
    const [roles, setRoles] = useState([]);

    const [fieldsName, setFieldsName] = useState({
        first_name: "",
        last_name: "",
        email: "",
        job_title: "",
        role_uuid : "",
    });

    const [errors, setErrors] = useState({
        first_name: "",
        last_name: "",
        email: "",
        job_title: "",
        role_uuid : "",
    });

    const allData = {
        filename: fileName,
        mappingfields: '',
        token: token,
        createdby: createdby,
        main_admin_uuid: main_uuid,
        user_uuid: createdby,
    };

    useEffect(() => {
        setLoading(true);
        dispatch(getRoleList(createdby, token))
          .then((response) => {
            setRoles(response.data);
            setLoading(false);
            return;
          })
          .catch(() => {
            setLoading(false);
          });
    }, []);

    const loadNextPage = (val) => {
        setCurrentStep(val);
    }

    const handelFileName = (val) => {
        setFileName(val);
    }

    const handleBlur = (e) => {
        //console.log(e.target.value);
        let error_data = { ...errors };

        if (e.target.name !== 'job_title' && e.target.value.length === 0) {
            if(e.target.name === 'role_uuid'){
                error_data[e.target.name] = "Role is required ";
            }else{
                error_data[e.target.name] = "Select Field for "+e.target.name;
            }            
            setErrors(error_data);
        } else {
            error_data[e.target.name] = "";
            setErrors(error_data);
        }

        const newData = { ...fieldsName };
        newData[e.target.name] = e.target.value;
        setFieldsName(newData);
    }

    const submitHandler = (e) => {
        e.preventDefault();

        let error_data = { ...errors };
        let isError = "false";
        setLoading(true);

        if (fieldsName.first_name.length === 0) {
            error_data["first_name"] = "Select Field for first name";
            setErrors(error_data);
            isError = "true";
        }

        if (fieldsName.last_name.length === 0) {
            error_data["last_name"] = "Select Field for last name";
            setErrors(error_data);
            isError = "true";
        }

        if (fieldsName.email.length === 0) {
            error_data["email"] = "Select Field for email";
            setErrors(error_data);
            isError = "true";
        }

        if (fieldsName.role_uuid.length === 0) {
            error_data["role_uuid"] = "Role is required ";
            setErrors(error_data);
            isError = "true";
        }

        if (isError === "true") {
            setLoading(false);
            return;
        } else {

            let data = {...allData}
            data['mappingfields'] = fieldsName;
            data['filename'] = fileName;
            //call create insert csv data API
            dispatch(insertUserCsvData(data))
              .then((response) => {
                console.log(response);
                if (response.status === 200) {
                  notifyInfo(response.message);
                  closeModel(false);
                  setLoading(false);
                  props.handleUserEvent(Math.random);
                  navigate("/users", { replace: true });
                  
                } else if (response.status === 500) {
                  notifyError("Something went wrong.");
                } else {
                  notifyError("Something went wrong.");
                }
                setLoading(false);
              })
              .catch(() => {
                setLoading(false);
              });
        }
    }

    const renderStep = () => {
        switch (currentStep) {
          case 1:
            return (
              <StepOne
                loadNextPage={loadNextPage}
                handelFileName={handelFileName}
                // handleBuyAndConfirmData={handleBuyAndConfirmData}
              />
            );
          case 2:
            return (
              <StepTwo
                fileName={fileName}
                roles={roles}
                handleBlur={handleBlur}
                errors={errors}
                fieldsName={fieldsName}
                //type={props.type}
                // handleSelectMoreNumber={handleSelectMoreNumber}
                // handleRemovedNumber={handleRemovedNumber}
              />
            );
    
          default:
            return null;
        }
    };


    return (
        <Fragment>
            {loading && <Loader />}
            <Modal.Header>
                    <Modal.Title>Invite User</Modal.Title>
                    <Button
                        variant=""
                        className="close"
                        onClick={() => closeModel(false)}
                    >
                        <span>&times;</span>
                    </Button>
                </Modal.Header>
                {/* <form action="" onSubmit={(e) => submitHandler(e)}> */}
                    <Modal.Body>
                        <form
                            onSubmit={(e) => e.preventDefault()}
                            id="step-form-horizontal"
                            className="step-form-horizontal"
                        >

                            <div className="row">
                                <div className="col-xl-3 col-xxl-3"></div>
                                <div className="col-xl-6 col-xxl-6">
                                    <ol className="go2441762052">
                                        <li
                                        className={
                                            currentStep < 1 ? "go2067797667" : "go767851704 step_2"
                                        }
                                        value="0"
                                        >
                                        <span>1</span>
                                        <p className="text-left">Upload CSV</p>
                                        </li>
                                        <li
                                        className={currentStep < 2 ? "go2067797667" : "go767851704"}
                                        value="1"
                                        >
                                        <span>2</span>
                                        <p className="text-left">Map Fields and Invite</p>
                                        </li>
                                    </ol>
                                </div>
                            </div>

                            <div className="">{renderStep()}</div>

                        
                     </form>

                    
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => closeModel(false)}
                            variant="danger light btn-sm"
                        >
                            Close
                        </Button>
                        {currentStep === 2 && <Button type="button" onClick={(e) => submitHandler(e)} variant="primary btn-sm">Save</Button> }
                        
                    </Modal.Footer>
                {/* </form> */}
        </Fragment>
    );

}

export default UserInviteForm;