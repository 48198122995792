import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import "./style.css";
import { Button, Modal } from "react-bootstrap";
import { getAreaCodeList } from "./../../actions/comman";
import { getStates, getAreaCode } from "./../../actions/number";
import Loader from "../../layouts/Loader";

function FilterForm(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;

  const [prefixData, setPrefixData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [areaCodeEvent, setAreaCodeEvent] = useState("");

  const [loading, setLoading] = useState(false);

  const [searchData, setSearchData] = useState({
    area_code: "",
    account_name: "",
    description: "",
    state_uuid : "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(getAreaCodeList(createdby, token))
      .then((response) => {
        setPrefixData(response.data.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [areaCodeEvent]);

  useEffect(() => {
    setLoading(true);
    dispatch(getStates(createdby, token))
      .then((response) => {
        setStateData(response.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const handleChange = (e) => {
    let new_data = { ...searchData };
    new_data[e.target.name] = e.target.value;
    setSearchData(new_data);

    if (e.target.name === 'state_uuid' && e.target.value.length > 0) {
      setLoading(true);
      dispatch(getAreaCode(createdby, token, e.target.value))
      .then((response) => {
        setPrefixData(response.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    }

    if (e.target.name === 'state_uuid' && e.target.value.length === 0) {
      setAreaCodeEvent(Math.random);
    }
    new_data["type"] = "search";
    return;
  };

  const fnSearch = () => {
 
      //props.closeModel(false);
     // props.filterInboundNumber(didData);
    
    props.applySearch(searchData); 
    return;  
  };

  const fnResetFilter = () => {
    let new_data = { ...searchData };
    new_data["area_code"] = "";
    new_data["account_name"] = "";
    new_data["description"] = "";
    new_data['state_uuid'] = "";
    new_data["type"] = "reset";
    setSearchData(new_data);

    props.applySearch(new_data);   
    return;
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>Filter</Modal.Title>
        
      </Modal.Header>
      <Modal.Body>
        <div className="row">
         
          <div className="col-xl-6 col-xxl-6 col-lg-6">
            <div className="form-group input-primary">
              <label>State</label>
              <select
                className="form-control bg-white"
                name="state_uuid"
                value={searchData.state_uuid}
                onChange={(e) => handleChange(e)}
              >
                <option value="">Select State</option>
                {stateData.map((data) => (
                  <option value={data.uuid} key={data.uuid}>
                    {data.state_name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-xl-6 col-xxl-6 col-lg-6">
            <div className="form-group input-primary">
              <label>Area Code</label>
              <select
                className="form-control bg-white"
                name="area_code"
                value={searchData.area_code}
                onChange={(e) => handleChange(e)}
              >
                <option value="">Select Area Code</option>
                {prefixData.map((number) => (
                  <option value={number.area_code} key={number.area_code}>
                    {number.area_code}
                  </option>
                ))}
              </select>
            </div>
          </div>

        </div>
        <div className="row">

          <div className="col-xl-6 col-xxl-6 col-lg-6">
            <div className="form-group input-primary">
              <label>Account Name</label>
              <input
                type="text"
                className="form-control bg-white"
                placeholder="Account Name Search"
                name="account_name"
                value={searchData.account_name}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="col-xl-6 col-xxl-6 col-lg-6">
            <div className="form-group input-primary">
              <label>Description contains word</label>
              <input
                type="text"
                className="form-control bg-white"
                placeholder="Enter a description"
                name="description"
                value={searchData.description}
                onChange={handleChange}
              />
            </div>
          </div>

        </div>
        
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger light btn-sm" onClick={() => fnResetFilter()}>
          Reset
        </Button>
        <Button
          variant=""
          type="button"
          onClick={() => fnSearch("")}
          className="btn btn-sm btn-primary"
        >
          Apply Search
        </Button>
      </Modal.Footer>
    </Fragment>
  );
}

export default FilterForm;
