import React, { Fragment, useState, useEffect } from "react";
import { Button, Table, Tab, Nav, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  createCallCampaign,
  getContactGroupList,
  getCallBlastAudioList,
  getAgentAndNumberList,
} from "../../actions/callcampaign";
import { getDispositionList } from "../../actions/comman";
import PageTitle from "../../layouts/PageTitle";
import Select from "react-select";
import "./style.css";
import TimeSlotForm from "./TimeSlotForm";
import CreatableSelect from "react-select/creatable";
import UpdateTimeSlotForm from "./UpdateTimeSlotForm";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import Loader from "../../layouts/Loader";

function CreateCallTempForm(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const [campaignType, setCampaignType] = useState("voice_broadcast");
  const [callCampaignData, setCallCampaignData] = useState({
    campaign_name: "",
    campaign_type: campaignType,
    audio_file: "",
    is_recurring: "",
    contact_group_uuid: "",
    disposition_data: "",
    start_date: "",
    end_date: "",
    recurring_type: "",
    recurring_at: "",
    campaign_status: "",
    timezone: "",
    from_number: "",
    operation_time: "",
    wrap_up_time: "",
    call_camp_from_number: "",
    token: token,
    main_uuid: main_uuid,
    createdby: createdby,
  });
  const [errors, setErrors] = useState({
    name: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [contactGroupList, setContactGroupList] = useState([]);
  //const [timeZoneData, setTimeZoneData] = useState([]);
  const [dispositionData, setDispositionData] = useState([]);
  const [audioData, setAudioData] = useState([]);
  const [dispositionVal, setDispositionVal] = useState([]);
  const [rows, setRows] = useState([]);
  const [recuringCheck, setRecuringCheck] = useState("checked");
  const [addTimeSlotModal, setAddTimeSlotModal] = useState(false);
  const [daySel, setDaySel] = useState("monday");
  const [operationUuid, setOperationUuid] = useState("");
  const [updateData, setUpdateData] = useState([]);
  const [updateTimeSlotModal, setUpdateTimeSlotModal] = useState(false);
  const [agentAndNumberData, setAgentAndNumberData] = useState([]);

  const [mondayData, setMondayData] = useState([
    {
      operation_time_uuid: 1,
      day: "monday",
      start_time: "09:00",
      end_time: "17:00",
    },
  ]);

  const [tuesdayData, setTuesdayData] = useState([
    {
      operation_time_uuid: 2,
      day: "tuesday",
      start_time: "09:00",
      end_time: "17:00",
    },
  ]);

  const [wednesdayData, setWednesdayData] = useState([
    {
      operation_time_uuid: 3,
      day: "wednesday",
      start_time: "09:00",
      end_time: "17:00",
    },
  ]);

  const [thursdayData, setThursdayData] = useState([
    {
      operation_time_uuid: 4,
      day: "thursday",
      start_time: "09:00",
      end_time: "17:00",
    },
  ]);

  const [fridayData, setFridayData] = useState([
    {
      operation_time_uuid: 5,
      day: "friday",
      start_time: "09:00",
      end_time: "17:00",
    },
  ]);

  const [saturdayData, setSaturdayData] = useState([]);
  const [sundayData, setSundayData] = useState([]);

  const [callCampOpData, setCallCampOpData] = useState([
    {
      operation_time_uuid: 1,
      day: "monday",
      start_time: "09:00",
      end_time: "17:00",
    },
    {
      operation_time_uuid: 2,
      day: "tuesday",
      start_time: "09:00",
      end_time: "17:00",
    },
    {
      operation_time_uuid: 3,
      day: "wednesday",
      start_time: "09:00",
      end_time: "17:00",
    },
    {
      operation_time_uuid: 4,
      day: "thursday",
      start_time: "09:00",
      end_time: "17:00",
    },
    {
      operation_time_uuid: 5,
      day: "friday",
      start_time: "09:00",
      end_time: "17:00",
    },
  ]);

  const addDestinationHandler = (data) => {
    let monData = [];
    let tuesData = [];
    let wenData = [];
    let thursData = [];
    let friData = [];
    let satData = [];
    let sunData = [];

    setCallCampOpData((callCampOpData) => [...callCampOpData, data]);
    const newTimeOpedata = [...callCampOpData];
    newTimeOpedata.push(data);

    newTimeOpedata.forEach((emp, index) => {
      if (emp.day === "monday") {
        monData.push(emp);
      } else if (emp.day === "tuesday") {
        tuesData.push(emp);
      } else if (emp.day === "wednesday") {
        wenData.push(emp);
      } else if (emp.day === "thursday") {
        thursData.push(emp);
      } else if (emp.day === "friday") {
        friData.push(emp);
      } else if (emp.day === "saturday") {
        satData.push(emp);
      } else if (emp.day === "sunday") {
        sunData.push(emp);
      }
    });

    setMondayData(monData);
    setTuesdayData(tuesData);
    setWednesdayData(wenData);
    setThursdayData(thursData);
    setFridayData(friData);
    setSaturdayData(satData);
    setSundayData(sunData);

    const newCampaignData1 = { ...callCampaignData };
    newCampaignData1["operation_time"] = newTimeOpedata;
    setCallCampaignData(newCampaignData1);
  };

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const closeModel = (value) => {
    setAddTimeSlotModal(value);
    setUpdateTimeSlotModal(value);
  };

  const handleBlur = (e) => {
    const newCampaignData = { ...callCampaignData };
    let error_data = { ...errors };
    if (e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }

    if (e.target.name === "campaign_type") {
      newCampaignData[e.target.name] = e.target.value;
      setCampaignType(e.target.value);
    }

    newCampaignData[e.target.name] = e.target.value;
    setCallCampaignData(newCampaignData);
  };

  let select_day = "";
  const handleBlur1 = React.useCallback(
    (e) => {
      select_day = e.currentTarget.id;
      setDaySel(select_day);
    },
    [""]
  );

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (callCampaignData.campaign_name.length === 0) {
      error_data["campaign_name"] = "Campaign Name is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (callCampaignData.campaign_type.length === 0) {
      error_data["campaign_type"] = "Campaign Type is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (callCampaignData.call_camp_from_number.length === 0) {
      error_data["call_camp_from_number"] = "From number Type is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (callCampaignData.contact_group_uuid.length === 0) {
      error_data["contact_group_uuid"] = "Please select atleast one lead list.";
      setErrors(error_data);
      isError = "true";
    } else {
      error_data["contact_group_uuid"] = "";
    }

    if (callCampaignData.disposition_data.length === 0) {
      error_data["disposition_data"] = "Please select atleast one disposition.";
      setErrors(error_data);
      isError = "true";
    } else {
      error_data["disposition_data"] = "";
    }

    if (
      callCampaignData.start_date.length === 0 &&
      campaignType === "voice_broadcast"
    ) {
      error_data["start_date"] = "Start Date Time is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (
      callCampaignData.end_date.length === 0 &&
      campaignType === "voice_broadcast"
    ) {
      error_data["end_date"] = "End Date Time is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (
      (callCampaignData.is_recurring.length !== 0 ||
        callCampaignData.is_recurring === "yes") &&
      callCampaignData.recurring_type.length === 0 &&
      campaignType === "voice_broadcast"
    ) {
      error_data["recurring_type"] = "Please select recurring type.";
      setErrors(error_data);
      isError = "true";
    }

    if (
      (callCampaignData.is_recurring.length !== 0 ||
        callCampaignData.is_recurring === "yes") &&
      callCampaignData.recurring_at.length === 0 &&
      campaignType === "voice_broadcast"
    ) {
      error_data["recurring_at"] = "Please select recurring at.";
      setErrors(error_data);
      isError = "true";
    }

    if (callCampOpData.length === 0 && campaignType === "voice_broadcast") {
      error_data["operation_time"] = "Please atleast add one operation time.";
      setErrors(error_data);
      isError = "true";
    }

    if (
      callCampaignData.wrap_up_time.length === 0 &&
      campaignType === "power_dial"
    ) {
      error_data["wrap_up_time"] = "Wrap Up Time Date Time is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (
      callCampaignData.audio_file.length === 0 &&
      campaignType === "voice_broadcast"
    ) {
      error_data["audio_file"] = "Please atleast add one file.";
      setErrors(error_data);
      isError = "true";
    }

    // if (callCampaignData.timezone.length === 0) {
    //   error_data["timezone"] = "Please select timezone.";
    //   setErrors(error_data);
    //   isError = "true";
    // }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create call API

      const data = { ...callCampaignData };
      data["disposition_data"] = dispositionVal;
      data["operation_time"] = callCampOpData;
      dispatch(createCallCampaign(data))
        .then((res) => {
          if (res.status === 200) {
            notifyInfo(res.message);
            setLoading(false);
            navigate("/call-campaign", { replace: true });
          } else if (res.status === 406) {
            error_data["campaign_name"] = res.message;
            setErrors(error_data);
            isError = "true";
          } else if (res.status === 500) {
            notifyError("Something went wrong.");
          } else {
            notifyError("Something went wrong.");
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    dispatch(getContactGroupList(main_uuid, createdby, token))
      .then((response) => {
        setContactGroupList(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);


  useEffect(() => {
    dispatch(getDispositionList(createdby, main_uuid, createdby, token))
      .then((response) => {
        setDispositionData(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    dispatch(getCallBlastAudioList(main_uuid, createdby, token))
      .then((response) => {
        setAudioData(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    dispatch(getAgentAndNumberList(main_uuid, createdby, token))
      .then((response) => {
        setAgentAndNumberData(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const UpdateDestinationHandler = (data) => {
    let select_day = data.day;
    let myArray = [];

    let myArray1 = [];
    myArray1 = callCampOpData;
    const i = myArray1.findIndex(
      (x) => x.operation_time_uuid === operationUuid
    );
    myArray1[i] = data;
    setCallCampOpData(myArray1);

    if (select_day === "monday") {
      myArray = mondayData;
      const i = myArray.findIndex(
        (x) => x.operation_time_uuid === operationUuid
      );
      myArray[i] = data;
      setMondayData(myArray);
    }

    if (select_day === "tuesday") {
      myArray = tuesdayData;
      const i = myArray.findIndex(
        (x) => x.operation_time_uuid === operationUuid
      );
      myArray[i] = data;
      setTuesdayData(myArray);
    }

    if (select_day === "wednesday") {
      myArray = wednesdayData;
      const i = myArray.findIndex(
        (x) => x.operation_time_uuid === operationUuid
      );
      myArray[i] = data;
      setWednesdayData(myArray);
    }

    if (select_day === "thursday") {
      myArray = thursdayData;
      const i = myArray.findIndex(
        (x) => x.operation_time_uuid === operationUuid
      );
      myArray[i] = data;
      setThursdayData(myArray);
    }

    if (select_day === "friday") {
      myArray = fridayData;
      const i = myArray.findIndex(
        (x) => x.operation_time_uuid === operationUuid
      );
      myArray[i] = data;
      setFridayData(myArray);
    }

    if (select_day === "saturday") {
      myArray = saturdayData;
      const i = myArray.findIndex(
        (x) => x.operation_time_uuid === operationUuid
      );
      myArray[i] = data;
      setSaturdayData(myArray);
    }

    if (select_day === "sunday") {
      myArray = sundayData;
      const i = myArray.findIndex(
        (x) => x.operation_time_uuid === operationUuid
      );
      myArray[i] = data;
      setSundayData(myArray);
    }

    const data_camp = { ...callCampaignData };
    data_camp["operation_time"] = callCampOpData;
    setCallCampaignData(data_camp);
  };

  function handleSelectDisposition(data) {
    const newCampaignData1 = { ...callCampaignData };
    newCampaignData1["disposition_data"] = data;
    setCallCampaignData(newCampaignData1);
    setDispositionVal(data);
  }

  function handleSelectLeadList(data) {
    const newCampaignData1 = { ...callCampaignData };
    newCampaignData1["contact_group_uuid"] = data;
    setCallCampaignData(newCampaignData1);
    setRows(data);
  }

  function handleSelectCheck(data) {
    let rec_chk_val = data.target.value;
    let check_val = "";
    if (rec_chk_val !== "" && rec_chk_val === "checked") {
      setRecuringCheck("");
      check_val = "yes";
    } else {
      setRecuringCheck("checked");
      check_val = "no";
    }

    const newCampaignData = { ...callCampaignData };
    newCampaignData["is_recurring"] = check_val;
    setCallCampaignData(newCampaignData);
  }

  function tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }

  const handleUpdate = (index, operation_uuid, e) => {
    setOperationUuid(operation_uuid);
    setUpdateData(index);
    setUpdateTimeSlotModal(index);
  };

  const handleDelete = (index, del_sel_day, e) => {
    setCallCampOpData([
      ...callCampOpData.filter((item) => item.operation_time_uuid !== index),
    ]);

    if (del_sel_day === "monday") {
      return setMondayData([
        ...mondayData.filter((item) => item.operation_time_uuid !== index),
      ]);
    } else if (del_sel_day === "tuesday") {
      return setTuesdayData([
        ...tuesdayData.filter((item) => item.operation_time_uuid !== index),
      ]);
    } else if (del_sel_day === "wednesday") {
      return setWednesdayData([
        ...wednesdayData.filter((item) => item.operation_time_uuid !== index),
      ]);
    } else if (del_sel_day === "thursday") {
      return setThursdayData([
        ...thursdayData.filter((item) => item.operation_time_uuid !== index),
      ]);
    } else if (del_sel_day === "friday") {
      return setFridayData([
        ...fridayData.filter((item) => item.operation_time_uuid !== index),
      ]);
    } else if (del_sel_day === "saturday") {
      return setSaturdayData([
        ...saturdayData.filter((item) => item.operation_time_uuid !== index),
      ]);
    } else if (del_sel_day === "sunday") {
      return setSundayData([
        ...sundayData.filter((item) => item.operation_time_uuid !== index),
      ]);
    }
  };

  var seconds = [],
    i = 4,
    len = 30;
  while (++i <= len) seconds.push(i);

  var recurringTimes = [],
    j = 0,
    lenn = 12;
  while (++j <= lenn) recurringTimes.push(j);

  const breadCurmList = [
    { label: "Calls", link: "/", is_active: "NO" },
    {
      label: "Call Campaign",
      link: "/call-campaign",
      is_active: "NO",
    },
    { label: "Create Call Campaign ", link: "", is_active: "YES" },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Create Call Campaign</h4>
              <Button
                type="button"
                className="btn btn-default btn-sm"
                onClick={() => navigate("/call-campaign", { replace: true })}
              >
                <i className="fa fa-reply" aria-hidden="true"></i> Back
              </Button>
            </div>
            <div className="card-body">
              <form action="" onSubmit={(e) => submitHandler(e)}>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Campaign Name</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Campaign Name"
                        name="campaign_name"
                        id="campaign_name"
                        onChange={handleBlur}
                      />
                      <span className="text-danger">
                        {errors.campaign_name}
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>From Number</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="call_camp_from_number"
                        onChange={handleBlur}
                      >
                        <option value="">Select From Number</option>
                        {agentAndNumberData.map((number) => (
                          <option key={number.uuid} value={number.uuid}>
                            {number.first_name !== null &&
                            number.last_name !== null
                              ? number.first_name + " " + number.last_name
                              : number.did_number}
                          </option>
                        ))}
                      </select>
                      <span className="text-danger">
                        {errors.call_camp_from_number}
                      </span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Campaign Type</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="campaign_type"
                        onChange={handleBlur}
                        defaultValue={campaignType}
                      >
                        <option value="">Select Campaign Type</option>
                        <option value="voice_broadcast">Voice Broadcast</option>
                        <option value="power_dial">Power Dial</option>
                      </select>
                      <span className="text-danger">
                        {errors.campaign_type}
                      </span>
                    </div>
                  </div>
                  <div
                    className="col-6"
                    style={{
                      display:
                        campaignType === "voice_broadcast" ? "block" : "none",
                    }}
                  >
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Audio File</strong>
                        <span className="text-danger">*</span>
                      </label>

                      <select
                        className="form-control"
                        name="audio_file"
                        onChange={handleBlur}
                      >
                        <option value="">Select Audio File</option>
                        {audioData.map((file) => (
                          <option
                            key={file.recording_uuid}
                            value={file.recording_uuid}
                          >
                            {file.recording_name}
                          </option>
                        ))}
                      </select>
                      <span className="text-danger">{errors.audio_file}</span>
                    </div>
                  </div>
                  <div
                    className={
                      "col-" + (campaignType === "power_dial" ? "6" : "6")
                    }
                    style={{
                      display: campaignType === "power_dial" ? "block" : "none",
                    }}
                  >
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Wrap Up Time</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="wrap_up_time"
                        id="wrap_up_time"
                        onChange={handleBlur}
                      >
                        <option value="">Select Wrap Up Time</option>
                        {seconds.map((i) => (
                          <option value={i} key={i}>
                            {i}
                          </option>
                        ))}
                      </select>
                      <span className="text-danger">{errors.wrap_up_time}</span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <div className="form-group mt-2">
                      <label className="mb-1">
                        <strong> Add Lead List</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <Select
                        className="form-control"
                        options={contactGroupList}
                        name="contact_group_uuid"
                        placeholder="Select List"
                        onChange={handleSelectLeadList}
                        isSearchable={true}
                        tags={true}
                        isMulti
                      />
                      <span className="text-danger">
                        {errors.contact_group_uuid}
                      </span>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="card-body">
                      <label className="mb-1">
                        <strong> Lead List</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <Table responsive className="w-100">
                        <thead>
                          <tr role="row">
                            <td>Name</td>
                            <td>No. of contacts</td>
                          </tr>
                        </thead>
                        <tbody>
                          {rows.map((row, index) => (
                            <tr key={index}>
                              <td>{row.label}</td>
                              <td>{row.count}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <div className="form-group mt-2">
                      <label className="mb-1">
                        <strong> Add Disposition</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <CreatableSelect
                        className="form-control"
                        name="disposition_data"
                        isMulti
                        onChange={handleSelectDisposition}
                        options={dispositionData}
                      />

                      <span className="text-danger">
                        {errors.disposition_data}
                      </span>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="card-body">
                      <label className="mb-1">
                        <strong> Disposition List</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <Table responsive className="w-100">
                        <thead>
                          <tr role="row">
                            <td>Name</td>
                            <td></td>
                          </tr>
                        </thead>
                        <tbody>
                          {dispositionVal.map((dis, index) => (
                            <tr key={index}>
                              <td>{dis.value}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display:
                      campaignType === "voice_broadcast" ? "block" : "none",
                  }}
                >
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="form-group col-xl-12 p-0">
                        <label className="mb-1">
                          <strong>Start Date</strong>
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="date"
                          name="start_date"
                          onChange={handleBlur}
                          className="form-control"
                        />
                        <span className="text-danger">{errors.start_date}</span>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="form-group col-xl-12 p-0">
                        <label className="mb-1">
                          <strong>End Date</strong>
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="date"
                          name="end_date"
                          onChange={handleBlur}
                          className="form-control"
                        />
                        <span className="text-danger">{errors.end_date}</span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-3">
                      <div className="form-group">
                        <label className="w-100 mb-1">
                          <strong>Recurring</strong>
                        </label>
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="is_recurring"
                            onChange={handleSelectCheck}
                            value={recuringCheck}
                          />
                          <span className="slider round"> </span>
                        </label>
                      </div>
                    </div>
                    <div
                      className="col-xl-3"
                      style={{
                        display: recuringCheck === "" ? "block" : "none",
                      }}
                    >
                      <div className="form-group">
                        <label className="w-100 mb-1">
                          <strong></strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          readOnly
                          value="Every "
                        />
                      </div>
                    </div>

                    <div
                      className="col-xl-3"
                      style={{
                        display: recuringCheck === "" ? "block" : "none",
                      }}
                    >
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>Recurring At</strong>
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control"
                          name="recurring_at"
                          onChange={handleBlur}
                        >
                          <option value="">Select Recurring At</option>
                          {recurringTimes.map((k) => (
                            <option value="{k}" key={k}>
                              {k}
                            </option>
                          ))}
                        </select>
                        <span className="text-danger">
                          {errors.recurring_at}
                        </span>
                      </div>
                    </div>
                    <div
                      className="col-xl-3"
                      style={{
                        display: recuringCheck === "" ? "block" : "none",
                      }}
                    >
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>Recurring Type</strong>
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control"
                          name="recurring_type"
                          onChange={handleBlur}
                        >
                          <option value="">Select Recurring Type</option>
                          <option value="day">Day</option>
                          <option value="week">Week</option>
                          <option value="month">Month</option>
                          <option value="year">Year</option>
                        </select>
                        <span className="text-danger">
                          {errors.recurring_type}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="card-body">
                    <label className="mb-1">
                      <strong> Campaign Operation Time</strong>
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="default-tab m-3 w-100">
                    <Tab.Container defaultActiveKey="monday">
                      <Nav as="ul" className="nav-tabs">
                        <Nav.Item as="li">
                          <Nav.Link
                            id="sunday"
                            eventKey="sunday"
                            onClick={handleBlur1}
                          >
                            <i className={`la la-clock mr-2`} />
                            Sun
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                          <Nav.Link
                            id="monday"
                            eventKey="monday"
                            onClick={handleBlur1}
                          >
                            <i className={`la la-clock mr-2`} />
                            Mon
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                          <Nav.Link
                            id="tuesday"
                            eventKey="tuesday"
                            onClick={handleBlur1}
                          >
                            <i className={`la la-clock mr-2`} />
                            Tue
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                          <Nav.Link
                            id="wednesday"
                            eventKey="wednesday"
                            onClick={handleBlur1}
                          >
                            <i className={`la la-clock mr-2`} />
                            Wed
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                          <Nav.Link
                            id="thursday"
                            eventKey="thursday"
                            onClick={handleBlur1}
                          >
                            <i className={`la la-clock mr-2`} />
                            Thurs
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                          <Nav.Link
                            id="friday"
                            eventKey="friday"
                            onClick={handleBlur1}
                          >
                            <i className={`la la-clock mr-2`} />
                            Fri
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                          <Nav.Link
                            id="saturday"
                            eventKey="saturday"
                            onClick={handleBlur1}
                          >
                            <i className={`la la-clock mr-2`} />
                            sat
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>

                      <Tab.Content className="pt-12">
                        <Tab.Pane eventKey="sunday">
                          <div className="row mt-4">
                            <div className="col-12">
                              <h4 className="card-title">Sunday</h4>

                              <Table responsive className="w-100">
                                <thead>
                                  {sundayData.map((name) => (
                                    <tr
                                      role="row"
                                      key={name.operation_time_uuid}
                                    >
                                      <td>
                                        {tConvert(name.start_time)} To{" "}
                                        {tConvert(name.end_time)}
                                      </td>
                                      <td>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-danger light pull-right mr-2"
                                          onClick={(e) =>
                                            handleDelete(
                                              name.operation_time_uuid,
                                              name.day,
                                              e
                                            )
                                          }
                                        >
                                          Delete
                                        </span>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-info light pull-right mr-2"
                                          onClick={(e) =>
                                            handleUpdate(
                                              name,
                                              name.operation_time_uuid,
                                              e
                                            )
                                          }
                                        >
                                          Edit
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </thead>
                              </Table>
                              {/* <tbody> */}
                              <div
                                className="col-sm-6 pull-left"
                                title="Add New Time"
                              >
                                <span
                                  role="button"
                                  style={{ cursor: "pointer" }}
                                  className="badge badge-primary light mr-2"
                                  onClick={() => setAddTimeSlotModal(true)}
                                >
                                  <i
                                    className="fa fa-plus-circle mr-2"
                                    aria-hidden="true"
                                  />
                                  Add New Time
                                </span>
                              </div>
                              {/* </tbody> */}
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="monday">
                          <div className="row mt-4">
                            <div className="col-12">
                              <h4 className="card-title">Monday</h4>

                              <Table responsive className="w-100">
                                <thead>
                                  {mondayData.map((name) => (
                                    <tr
                                      role="row"
                                      key={name.operation_time_uuid}
                                    >
                                      <td>
                                        {tConvert(name.start_time)} To{" "}
                                        {tConvert(name.end_time)}
                                      </td>
                                      <td>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-danger light pull-right mr-2"
                                          onClick={(e) =>
                                            handleDelete(
                                              name.operation_time_uuid,
                                              name.day,
                                              e
                                            )
                                          }
                                        >
                                          Delete
                                        </span>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-info light pull-right mr-2"
                                          onClick={(e) =>
                                            handleUpdate(
                                              name,
                                              name.operation_time_uuid,
                                              e
                                            )
                                          }
                                        >
                                          Edit
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </thead>
                              </Table>
                              {/* <tbody> */}
                              <div
                                className="col-sm-6 pull-left"
                                title="Add New Time"
                              >
                                <span
                                  role="button"
                                  style={{ cursor: "pointer" }}
                                  className="badge badge-primary light mr-2"
                                  onClick={() => setAddTimeSlotModal(true)}
                                >
                                  <i
                                    className="fa fa-plus-circle mr-2"
                                    aria-hidden="true"
                                  />
                                  Add New Time
                                </span>
                              </div>
                              {/* </tbody> */}
                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="tuesday">
                          <div className="row mt-4">
                            <div className="col-12">
                              <h4 className="card-title">Tuesday</h4>

                              <Table responsive className="w-100">
                                <thead>
                                  {tuesdayData.map((name) => (
                                    <tr
                                      role="row"
                                      key={name.operation_time_uuid}
                                    >
                                      <td>
                                        {tConvert(name.start_time)} To{" "}
                                        {tConvert(name.end_time)}
                                      </td>
                                      <td>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-danger light pull-right mr-2"
                                          onClick={(e) =>
                                            handleDelete(
                                              name.operation_time_uuid,
                                              name.day,
                                              e
                                            )
                                          }
                                        >
                                          Delete
                                        </span>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-info light pull-right mr-2"
                                          onClick={(e) =>
                                            handleUpdate(
                                              name,
                                              name.operation_time_uuid,
                                              e
                                            )
                                          }
                                        >
                                          Edit
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </thead>
                              </Table>
                              {/* <tbody> */}
                              <div
                                className="col-sm-6 pull-left"
                                title="Add New Time"
                              >
                                <span
                                  role="button"
                                  style={{ cursor: "pointer" }}
                                  className="badge badge-primary light mr-2"
                                  onClick={() => setAddTimeSlotModal(true)}
                                >
                                  <i
                                    className="fa fa-plus-circle mr-2"
                                    aria-hidden="true"
                                  />
                                  Add New Time
                                </span>
                              </div>
                              {/* </tbody> */}
                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="wednesday">
                          <div className="row mt-4">
                            <div className="col-12">
                              <h4 className="card-title">Wednesday</h4>

                              <Table responsive className="w-100">
                                <thead>
                                  {wednesdayData.map((name) => (
                                    <tr
                                      role="row"
                                      key={name.operation_time_uuid}
                                    >
                                      <td>
                                        {tConvert(name.start_time)} To{" "}
                                        {tConvert(name.end_time)}
                                      </td>
                                      <td>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-danger light pull-right mr-2"
                                          onClick={(e) =>
                                            handleDelete(
                                              name.operation_time_uuid,
                                              name.day,
                                              e
                                            )
                                          }
                                        >
                                          Delete
                                        </span>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-info light pull-right mr-2"
                                          onClick={(e) =>
                                            handleUpdate(
                                              name,
                                              name.operation_time_uuid,
                                              e
                                            )
                                          }
                                        >
                                          Edit
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </thead>
                                <tbody></tbody>
                              </Table>
                              <div
                                className="col-sm-6 pull-left"
                                title="Add New Time"
                              >
                                <span
                                  role="button"
                                  style={{ cursor: "pointer" }}
                                  className="badge badge-primary light mr-2"
                                  onClick={() => setAddTimeSlotModal(true)}
                                >
                                  <i
                                    className="fa fa-plus-circle mr-2"
                                    aria-hidden="true"
                                  />
                                  Add New Time
                                </span>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="thursday">
                          <div className="row mt-4">
                            <div className="col-12">
                              <h4 className="card-title">Thursday</h4>

                              <Table responsive className="w-100">
                                <thead>
                                  {thursdayData.map((name) => (
                                    <tr
                                      role="row"
                                      key={name.operation_time_uuid}
                                    >
                                      <td>
                                        {tConvert(name.start_time)} To{" "}
                                        {tConvert(name.end_time)}
                                      </td>
                                      <td>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-danger light pull-right mr-2"
                                          onClick={(e) =>
                                            handleDelete(
                                              name.operation_time_uuid,
                                              name.day,
                                              e
                                            )
                                          }
                                        >
                                          Delete
                                        </span>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-info light pull-right mr-2"
                                          onClick={(e) =>
                                            handleUpdate(
                                              name,
                                              name.operation_time_uuid,
                                              e
                                            )
                                          }
                                        >
                                          Edit
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </thead>
                                <tbody></tbody>
                              </Table>
                              <div
                                className="col-sm-6 pull-left"
                                title="Add New Time"
                              >
                                <span
                                  role="button"
                                  style={{ cursor: "pointer" }}
                                  className="badge badge-primary light mr-2"
                                  onClick={() => setAddTimeSlotModal(true)}
                                >
                                  <i
                                    className="fa fa-plus-circle mr-2"
                                    aria-hidden="true"
                                  />
                                  Add New Time
                                </span>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="friday">
                          <div className="row mt-4">
                            <div className="col-12">
                              <h4 className="card-title">Friday</h4>

                              <Table responsive className="w-100">
                                <thead>
                                  {fridayData.map((name) => (
                                    <tr
                                      role="row"
                                      key={name.operation_time_uuid}
                                    >
                                      <td>
                                        {tConvert(name.start_time)} To{" "}
                                        {tConvert(name.end_time)}
                                      </td>
                                      <td>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-danger light pull-right mr-2"
                                          onClick={(e) =>
                                            handleDelete(
                                              name.operation_time_uuid,
                                              name.day,
                                              e
                                            )
                                          }
                                        >
                                          Delete
                                        </span>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-info light pull-right mr-2"
                                          onClick={(e) =>
                                            handleUpdate(
                                              name,
                                              name.operation_time_uuid,
                                              e
                                            )
                                          }
                                        >
                                          Edit
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </thead>
                                <tbody></tbody>
                              </Table>
                              <div
                                className="col-sm-6 pull-left"
                                title="Add New Time"
                              >
                                <span
                                  role="button"
                                  style={{ cursor: "pointer" }}
                                  className="badge badge-primary light mr-2"
                                  onClick={() => setAddTimeSlotModal(true)}
                                >
                                  <i
                                    className="fa fa-plus-circle mr-2"
                                    aria-hidden="true"
                                  />
                                  Add New Time
                                </span>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="saturday">
                          <div className="row mt-4">
                            <div className="col-12">
                              <h4 className="card-title">Saturday</h4>

                              <Table responsive className="w-100">
                                <thead>
                                  {saturdayData.map((name) => (
                                    <tr
                                      role="row"
                                      key={name.operation_time_uuid}
                                    >
                                      <td>
                                        {tConvert(name.start_time)} To{" "}
                                        {tConvert(name.end_time)}
                                      </td>
                                      <td>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-danger light pull-right mr-2"
                                          onClick={(e) =>
                                            handleDelete(
                                              name.operation_time_uuid,
                                              name.day,
                                              e
                                            )
                                          }
                                        >
                                          Delete
                                        </span>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-info light pull-right mr-2"
                                          onClick={(e) =>
                                            handleUpdate(
                                              name,
                                              name.operation_time_uuid,
                                              e
                                            )
                                          }
                                        >
                                          Edit
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </thead>
                                <tbody></tbody>
                              </Table>
                              <div
                                className="col-sm-6 pull-left"
                                title="Add New Time"
                              >
                                <span
                                  role="button"
                                  style={{ cursor: "pointer" }}
                                  className="badge badge-primary light mr-2"
                                  onClick={() => setAddTimeSlotModal(true)}
                                >
                                  <i
                                    className="fa fa-plus-circle mr-2"
                                    aria-hidden="true"
                                  />
                                  Add New Time
                                </span>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>

                        <span className="text-danger">
                          {errors.operation_time}
                        </span>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 text-right">
                    <Button type="submit" variant="primary" className="btn-sm">
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal className="fade" show={addTimeSlotModal}>
        <TimeSlotForm
          closeModel={closeModel}
          day={daySel}
          onAddDestination={addDestinationHandler}
        />
      </Modal>
      <Modal className="fade" show={updateTimeSlotModal}>
        <UpdateTimeSlotForm
          closeModel={closeModel}
          day={daySel}
          updateData={updateData}
          onUpdateDestination={UpdateDestinationHandler}
        />
      </Modal>
    </Fragment>
  );
}

export default CreateCallTempForm;
