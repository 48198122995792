import { SET_MESSAGE, API_FAIL, API_SUCCESS } from "./types";

import UserService from "../services/user.service";

export const updateProfile =
  (first_name, last_name, uuid, token) => (dispatch) => {
    return UserService.updateProfile(first_name, last_name, uuid, token).then(
      (data) => {
        if (data.status === 406) {
          return data;
        } else if (data.status !== 200) {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        } else {
          return data;
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const updatePassword =
  (oldPasssword, newPassword, uuid, token, type = "") =>
  (dispatch) => {
    return UserService.changePassword(
      oldPasssword,
      newPassword,
      uuid,
      token,
      type
    ).then(
      (data) => {
        if (data.status === 406) {
          return data;
        }
        if (data.status === 203) {
          return data;
        } else if (data.status !== 200) {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        } else {
          return data;
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const createUser = (userData,token) => (dispatch) => {
  return UserService.createUser(userData,token).then(
    (data) => {
      if (data.status === 406) {
        return data;
      } else if (data.status !== 200) {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      } else {
        return data;
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const updateUser = (userData, token) => (dispatch) => {
  return UserService.updateUser(userData, token).then(
    (data) => {
      if (data.status === 406) {
        return data;
      } else if (data.status !== 200) {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      } else {
        return data;
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const updateUserRole = (userData, token) => (dispatch) => {
  return UserService.updateUserRole(userData, token).then(
    (data) => {
      if (data.status === 406) {
        return data;
      } else if (data.status !== 200) {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      } else {
        return data;
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getUsers =
  (
    main_uuid,
    user_type,
    group_per,
    group_uuid,
    search,
    searchData,
    off_set,
    status,
    limits,
    orderby,
    createdby,
    token
  ) =>
  (dispatch) => {
    return UserService.getUsers(
      main_uuid,
      user_type,
      group_per,
      group_uuid,
      search,
      searchData,
      off_set,
      status,
      limits,
      orderby,
      createdby,
      token
    ).then(
      (data) => {
        if (data.status === 406) {
          return data;
        } else if (data.status !== 200) {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        } else {
          return data;
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const getUsersRole =
  (search, off_set, status, limits, orderby, role_uuid, createdby, token) =>
  (dispatch) => {
    return UserService.getUsersRole(
      search,
      off_set,
      status,
      limits,
      orderby,
      role_uuid,
      createdby,
      token
    ).then(
      (data) => {
        if (data.status === 406) {
          return data;
        } else if (data.status !== 200) {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        } else {
          return data;
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const getUserList = (createdby, token) => (dispatch) => {
  return UserService.getUserList(createdby).then(
    (data) => {
      if (data.status === 406) {
        return data;
      } else if (data.status !== 200) {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      } else {
        return data;
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getUserListDropDown = (createdby, token) => (dispatch) => {
  return UserService.getUserListDropDown(createdby, token).then(
    (data) => {
      if (data.status === 406) {
        return data;
      } else if (data.status !== 200) {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      } else {
        return data;
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

//to get single user data for update user
export const getUserDetail = (useruuid, createdby, token) => (dispatch) => {
  return UserService.getUserDetail(useruuid, createdby, token).then(
    (data) => {
      if (data.status === 406) {
        return data;
      } else if (data.status !== 200) {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      } else {
        return data;
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const importUserCsv = (fileData, token) => (dispatch) => {
  return UserService.importUserCsv(fileData, token).then(
    (data) => {
      if (data.status !== 200) {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }

      dispatch({
        type: API_SUCCESS,
        payload: { user: data },
      });

      return data;
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getUserMappingFields =
  (createdby, token, filename) => (dispatch) => {
    return UserService.getUserMappingFields(createdby, token, filename).then(
      (data) => {
        if (data.status !== 200) {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }

        dispatch({
          type: API_SUCCESS,
          payload: { user: data },
        });

        return data;
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const insertUserCsvData = (userData) => (dispatch) => {
  return UserService.insertUserCsvData(userData).then(
    (data) => {
      if (data.status !== 200) {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }

      dispatch({
        type: API_SUCCESS,
        payload: { user: data },
      });

      return data;
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const saveE911Data = (userData,token) => (dispatch) => {
  return UserService.saveE911Data(userData,token).then(
    (data) => {
      if (data.status === 406) {
        return data;
      } else if (data.status !== 200) {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      } else {
        return data;
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const updateDIDNumber = (userData,token) => (dispatch) => {
  return UserService.updateDIDNumber(userData,token).then(
    (data) => {
      if (data.status === 406) {
        return data;
      } else if (data.status !== 200) {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      } else {
        return data;
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const updateUserStatus = (userData, token) => (dispatch) => {
  return UserService.updateUserStatus(userData, token).then(
    (data) => {
      if (data.status === 406) {
        return data;
      } else if (data.status !== 200) {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      } else {
        return data;
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const updateUserGroup = (userData,token) => (dispatch) => {
  return UserService.updateUserGroup(userData,token).then(
    (data) => {
      if (data.status === 406) {
        return data;
      } else if (data.status !== 200) {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      } else {
        return data;
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const userDelete = (userData,token) => (dispatch) => {
  return UserService.userDelete(userData,token).then(
    (data) => {
      if (data.status === 406) {
        return data;
      } else if (data.status !== 200) {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      } else {
        return data;
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const updateReInvite = (userData, token) => (dispatch) => {
  return UserService.updateReInvite(userData, token).then(
    (data) => {
      if (data.status === 406) {
        return data;
      } else if (data.status !== 200) {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      } else {
        return data;
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};