import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const createEmailTemplate = (data, token) => {
  return axios
    .post(API_URL + "create-email-template  ", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const getEmailTemplateList = (
  main_uuid,
  user_type,
  group_per,
  group_uuid,
  search,
  off_set,
  status,
  limits,
  orderby,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "email-template-list",
      {
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        search,
        off_set,
        status,
        limits,
        orderby,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const deleteEmailTemplate = (email_temp_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "delete-email-template",
      {
        email_temp_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateEmailTemplate = (data, token) => {
  
  return axios
    .post(API_URL + "update-email-template  ", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getEmailTemplateDetail = (createdby, token, email_temp_uuid) => {
  return axios
    .post(
      API_URL + "get-email-template-details",
      {
        createdby,
        email_temp_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getContactFieldsList = (createdby, token) => {
  return axios
    .post(
      API_URL + "get-insert-personalization-list",
      {
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const emailTemplateDropdown = (createdby,main_uuid, token) => {
  return axios
    .post(
      API_URL + "email-template-dropdown",
      {
        createdby,
        main_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getEmailTemplateImages = (createdby, token) => {
  return axios
    .post(
      API_URL + "get-email-template-images",
      {
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

export default {
  createEmailTemplate,
  getEmailTemplateList,
  deleteEmailTemplate,
  updateEmailTemplate,
  getEmailTemplateDetail,
  getContactFieldsList,
  emailTemplateDropdown,
  getEmailTemplateImages,
};
