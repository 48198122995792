import React, { Fragment, useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import { sendSms } from "../../actions/chatSms";
import { getSmsTemplateList } from "../../actions/smscampaign";
import { getSmsTemplateDetail } from "../../actions/smstemplate";
import Loader from "../../layouts/Loader";

function SendSms(props) {
  const closeModel = (arg) => {
    props.closeModel(arg);
  };

  let fromNumber = props.fromNumber;
  let toNumber = props.toNumber;
  let assignNumberData = props.assignNumberData;
  let contactListAll = props.contactListAll;

  const [loading, setLoading] = useState(false);
  const [textCount, setTextCount] = useState(0);
  const [smsCount, setSmsCount] = useState(0);

  const [smsTemplateData, setSmsTemplateData] = useState([]);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const [smsData, setSmsData] = useState({
    from_number: fromNumber.from_number,
    to_number: toNumber,
    message: "",
    token: token,
    createdby: createdby,
    main_admin_uuid: createdby,
    user_uuid: createdby,
  });

  const [errors, setErrors] = useState({
    role_name: "",
  });

 

  useEffect(() => {
    dispatch(getSmsTemplateList("sms", main_uuid, createdby, token))
      .then((response) => {
        setSmsTemplateData(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  function handleSelect(data) {
    let sms_temp_uuid = data.target.value;

    dispatch(getSmsTemplateDetail(createdby, token, sms_temp_uuid))
      .then((response) => {
        const newCampaignData = { ...smsData };
        newCampaignData["sms_template_id"] = sms_temp_uuid;
        newCampaignData["message"] = response.data.message;
        setSmsData(newCampaignData);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }

  const handleBlur = (e) => {
    const newSmsdata = { ...smsData };
    let error_data = { ...errors };

    if (e.target.name === "from_number" && e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }
    newSmsdata[e.target.name] = e.target.value;
    setSmsData(newSmsdata);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (smsData.from_number.length === 0) {
      error_data["from_number"] = "From number name is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (smsData.to_number.length === 0) {
      error_data["to_number"] = "To number name is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (smsData.message.length === 0) {
      error_data["message"] = "Message is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      dispatch(
        sendSms(
          smsData.from_number,
          smsData.to_number,
          smsData.message,
          main_uuid,
          createdby,
          token
        )
      )
        .then((res) => {
          if (res.status === 200) {
            closeModel(false);
            notifyInfo(res.message);
            props.handleSendSmsEvent(smsData.from_number);
            setLoading(false);
            navigate("/chat-sms");
          } else if (res.status === 406) {
            error_data["role_name"] = res.message;
            setErrors(error_data);
            isError = "true";
          } else if (res.status === 500) {
            notifyError("Something went wrong.");
          } else {
            notifyError("Something went wrong.");
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const countChar = (e) => {
    let len = e.target.value.length;
    let str = e.target.value;

    let parts = [];
    let partSize = 160;

    while (str) {
      if (str.length < partSize) {
        parts.push(str);
        break;
      } else {
        parts.push(str.substr(0, partSize));
        str = str.substr(partSize);
      }
    }

    setTextCount(len);
    setSmsCount(parts.length);
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>Send SMS {smsData.role_name}</Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <form action="" onSubmit={(e) => submitHandler(e)}>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label className="mb-1">
                  <strong>From Number</strong>
                  <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  name="from_number"
                  value={smsData.from_number}
                  onChange={handleBlur}
                >
                  <option value="">Select From Number</option>
                  {assignNumberData.map((number) => (
                    <option key={number.uuid} value={number.did_number}>
                      {number.did_number}
                    </option>
                  ))}
                </select>
                <span className="text-danger">{errors.from_number}</span>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="mb-1">
                  <strong>To Number</strong>
                  <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  name="to_number"
                  value={smsData.to_number}
                  onChange={handleBlur}
                >
                  <option value="">Select To Number</option>
                  {contactListAll.map((number) => (
                    <option
                      key={number.contact_uuid}
                      value={number.contact_uuid}
                    >
                      {number.contact_name}
                    </option>
                  ))}
                </select>
                <span className="text-danger">{errors.to_number}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label className="mb-1">
                  <strong> SMS Template</strong>
                </label>
                <select
                  className="form-control"
                  name="sms_temp"
                  onChange={handleSelect}
                >
                  <option value="">Select SMS Template</option>
                  {smsTemplateData.map((user) => (
                    <option key={user.sms_temp_uuid} value={user.sms_temp_uuid}>
                      {user.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label className="mb-1">
                  <strong>Message </strong>
                  <span className="text-danger">*</span>
                </label>
                <span className="pull-right">
                  {textCount}/{smsCount}
                </span>
                <textarea
                  className="form-control"
                  placeholder="Message"
                  name="message"
                  value={smsData.message}
                  onChange={handleBlur}
                  onKeyUp={countChar}
                  // onChange={e => setMessageVal(e.target.value)}
                ></textarea>
                <span className="text-danger">{errors.message}</span>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => closeModel(false)}
            className="btn-sm"
            variant="danger light"
          >
            Close
          </Button>
          <Button type="submit" className="btn-sm" variant="primary">
            Send
          </Button>
        </Modal.Footer>
      </form>
    </Fragment>
  );
}

export default SendSms;
