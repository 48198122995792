import React, { Fragment, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../../layouts/PageTitle";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { getContactDetail } from "../../../actions/contacts";
import { deleteContact } from "../../../actions/contacts";
import PermissionCheck from "../../../common/PermissionCheck";
import ContactDetail from "../../contactComman/ContactDetail";
import { getContactFormLayout } from "../../../actions/customContactLayout";
import Loader from "../../../layouts/Loader";

function ContactView() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const module_name = "contacts";

  const params = useLocation();
  let contact_uuid = "";
  let group_uuid1 = "";
  let user_created_by = "";
  
  if (params.state !== null) {
    contact_uuid = params.state.contact_uuid;
    group_uuid1 = params.state.group_uuid;
    user_created_by = params.state.user_created_by;
  }

  const [contactData, setContactData] = useState({
    contact_uuid: contact_uuid,
    contact_type: "Individual",
    bussiness_name: "",
    contact_title: "",
    first_name: "",
    last_name: "",
    email: "",
    work_contact_number: "",
    other_contact_number: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
    address: "",
    address2: "",
    custom_field_data : {},
    dependent_field : "NO",
    dependent_field_data: {},
    token: token,
    createdby: createdby,
    main_user_uuid: createdby,
    user_uuid: createdby,
  });


  const [contactEvent, setContactEvent] = useState("");
  const [formData, setFormData] = useState([]);
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true)
    dispatch(getContactDetail(createdby, token, contact_uuid))
      .then((response) => {
        const newarr = { ...response.data[0] };
        newarr["token"] = token;
        newarr["createdby"] = createdby;
          
        let custom_field_data = [];
        let dependent_field_data = [];

        response.data[0].custom_field_data.map((field) => {
          
          custom_field_data[field.custom_field_uuid] = {
            custom_field_value_uuid : field.custom_field_value_uuid,
            field_value : field.field_value,
            contact_dependent_filed_value_uuid : field.contact_dependent_filed_value_uuid,
            dependent_value : field.dependent_value,
          };

          dependent_field_data[field.contact_custom_dependent_field_uuid] = {
            contact_custom_dependent_field_uuid : field.contact_custom_dependent_field_uuid,
            main_field_uuid : field.custom_field_uuid,
            value : field.dependent_value,
          }; 

          return true;
        })

        newarr["custom_field_data"] = custom_field_data;
        newarr["dependent_field_data"] = dependent_field_data;

        setContactData(newarr);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, [contactEvent]);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getContactFormLayout(
        main_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        //console.log(response);
        setFormData(response.data);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const fnDeleteContact = (uuid) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this contact?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteContactService(uuid),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteContactService = (uuid) => {
    setLoading(true);
    dispatch(deleteContact(uuid, createdby, token))
      .then((response) => {
        navigate("/contacts", { replace: true });
        // setContactEvent("delete");
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  };

const handleContactEvent = (val) => {
  setContactEvent(val);
}

  const breadCurmList = [
    { label: "Contacts", link: "/contacts", is_active: "NO" },
    { label: "Contact Detail", link: "", is_active: "YES" },
    { label: contactData.first_name+' '+contactData.last_name, link: "", is_active: "YES" },
  ];

  return (
    <Fragment>
      <>
      {loading && <Loader />}
      <div className="row">
        <div className="col-md-6">
          <PageTitle breadCurmList={breadCurmList} /> 
        </div>

        <div className="col-md-6 text-right pr-5">
          <Link
            to="#"
            onClick={() => fnDeleteContact(contactData.contact_uuid)}
            className="btn btn-sm btn-danger mr-2"
            hidden={PermissionCheck(
              module_name,
              "delete",
              group_uuid1,
              user_created_by,
              createdby
            )}
          >
            <i className="fa fa-trash mr-2"></i>
            Delete
          </Link>

          <Link
            to="/update-contact"
            state={{
              contact_uuid: contactData.contact_uuid,
            }}
            className="btn btn-sm btn-primary mr-2"
            hidden={PermissionCheck(
              module_name,
              "delete",
              group_uuid1,
              user_created_by,
              createdby
            )}
          >
            <i className="fa fa-pencil mr-2" aria-hidden="true"></i>
            Edit
          </Link>

          <Button
            type="button"
            className="btn btn-default btn-sm"
            onClick={() => navigate("/contacts", { replace: true })}
          >
            <i className="fa fa-reply" aria-hidden="true"></i> Back
          </Button>
        </div>
      </div>
      

      
        
        {/* <div className="card">
          <div className="card-header">
            <h4 className="card-title">View Contact</h4>
           
          </div>

          <div className="card-body"> */}
            {/* //contact detail going to load */}
            <ContactDetail contactDetail={contactData} formData={formData} handleContactEvent={handleContactEvent} />
          {/* </div>

          
        </div> */}
      </>
    </Fragment>
  );
}

export default ContactView;
