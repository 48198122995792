
import React,{Fragment,useState} from "react";
import { Modal } from "react-bootstrap";
import { UpdateFolder } from "../../../actions/email/emails";
import { useDispatch } from "react-redux";
import Loader from "../../../layouts/Loader";
import { notifyInfo, notifyError } from "../../Toastr/Toastr";

function UpdateFolderForm(props) {

    const user = JSON.parse(localStorage.getItem("user"));
    const user_data = user.data.data;
    const token = user.data.token;
    const createdby = user_data.user_uuid;
    const main_uuid = user_data.main_uuid;

    const old_folder_name = props.updateFolderName;

    const [loading, setLoading] = useState(false);
    const [folderName, setFolderName] = useState(old_folder_name);
    const [error, seterror] = useState('');

    const dispatch = useDispatch();

    const closeModel = (e,value) => {
        props.handleUpdateFolderModal(e,value,'');
    }

    const handleBlur = (e) => {

        if (e.target.value.length === 0) {            
            seterror('Folder name is required.');
        } else {
            seterror('');
        }

        setFolderName(e.target.value);
    }

    const submitHandler = (e) =>{
        e.preventDefault();

        setLoading(true);

        if (folderName.length === 0) {            
            seterror('Folder name is required.');
            setLoading(false);
            return;
        } else {
            seterror('');

            dispatch(UpdateFolder(main_uuid, createdby,folderName,old_folder_name, token))
            .then((response) => {

                if (response.data.status === "SUCCESS") {
                    props.handleCreateFolder(response.data.path);
                    notifyInfo(response.message);
                } else {
                    notifyError(response.message);
                }
                
                setLoading(false);
                return;
            })
            .catch(() => {
                setLoading(false);
            });
        }      

    }

    return (
        <Fragment>
            {loading && <Loader />}
            <Modal.Body>
                <form action="" onSubmit={(e) => submitHandler(e)}>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group input-primary">
                            
                            <input
                                type="text"
                                className="form-control bg-white h-75"
                                placeholder="Folder Name"
                                name="name"
                                id="name"
                                value={folderName}
                                onChange={handleBlur}
                            />
                            <span className="text-danger">{error}</span>
                            </div>
                        </div>
                    
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <button type="submit" className="btn btn-sm btn-primary">Update</button>

                            <button onClick={(e) => closeModel(e,false)} className="btn btn-sm btn-danger light ml-2"> Close</button>
                        </div>
                    </div>

                </form>
            </Modal.Body>
        </Fragment>
    );
}

export default UpdateFolderForm;