import React, { Fragment } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

function SectionOverview(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const createdby = user_data.user_uuid;

  const contactData = props.contactData;
  const errors = props.errors;
  let users = props.users;
  let personaOptions = props.personaOptions;
  let tagsOptions = props.tagsOptions;
  let selectedTags = props.contactData.selectedTags;

  const handleBlur = (e) => {
    props.handleBlur(e);
  }

  const handleSelectPersona = (e) => {
    props.handleSelectPersona(e);
  }

  const handleSelectTags = (e) => {
    props.handleSelectTags(e);
  }
  

  return (
    <Fragment>
      <div className="row">
        
        <div className="col-lg-6 col-md-6 col-6">
            <div className="card">
                <div className="card-body">
                  
                <div className="form-group input-primary">
                  <label className="mb-1">
                    <strong>Contact Title</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control bg-white"
                    //placeholder="Contact Title"
                    name="contact_title"
                    value={contactData.contact_title}
                    onChange={handleBlur}
                  />
                  <span className="text-danger">
                    {errors.contact_title}
                  </span>
                </div>                             
                
                {
                    contactData.contact_type === "Individual"
                          ? 
                          <div className="form-group input-primary">
                            <label className="mb-1">
                              <strong>Job Title</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control bg-white"
                              name="job_title"
                              value={contactData.job_title}
                              onChange={handleBlur}
                            />
                            <span className="text-danger">
                              {errors.job_title}
                            </span>
                          </div>
                          : ""
                }
                
                {
                  contactData.contact_type === "Business"
                        ? 
                        <div className="form-group input-primary">
                          <label className="mb-1">
                          <strong>Business Name</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control bg-white"
                            ////placeholder="Business Name"
                            name="bussiness_name"
                            value={contactData.bussiness_name}
                            onChange={handleBlur}
                          />
                          <span className="text-danger">
                            {errors.bussiness_name}
                          </span>
                        </div>
                        : ""
                }
                
                  <div className="form-group input-primary">
                    <label className="mb-1">
                      <strong>First Name</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control bg-white"
                      //placeholder="First Name"
                      name="first_name"
                      value={contactData.first_name}
                      onChange={handleBlur}
                    />
                    <span className="text-danger">{errors.first_name}</span>
                  </div>

                  <div className="form-group input-primary">
                    <label className="mb-1">
                      <strong>Email</strong>
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control bg-white"
                      //placeholder="Email"
                      name="email"
                      value={contactData.email}
                      onChange={handleBlur}
                    />
                    <span className="text-danger">{errors.email}</span>
                  </div>

                  <div className="form-group input-primary">
                    <label className="mb-1">
                      <strong>Work Contact Number</strong>
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control bg-white"
                      //placeholder="Work Contact Number"
                      name="work_contact_number"
                      value={contactData.work_contact_number}
                      onChange={handleBlur}
                    />
                    <span className="text-danger">
                      {errors.work_contact_number}
                    </span>
                  </div>

                  <div className="form-group input-primary">
                    <label className="mb-1">
                      <strong>Address</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control bg-white"
                      //placeholder="Address"
                      name="address"
                      value={contactData.address}
                      onChange={handleBlur}
                    />
                    <span className="text-danger">{errors.address}</span>
                  </div>

                  <div className="form-group input-primary">
                    <label className="mb-1">
                      <strong>Country</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control bg-white"
                      //placeholder="Country"
                      name="country"
                      value={contactData.country}
                      onChange={handleBlur}
                    />
                    <span className="text-danger">{errors.country}</span>
                  </div>

                  <div className="form-group input-primary">
                    <label className="mb-1">
                      <strong>City</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control bg-white"
                      //placeholder="City"
                      name="city"
                      value={contactData.city}
                      onChange={handleBlur}
                    />
                    <span className="text-danger">{errors.city}</span>
                  </div>

                  <div className="form-group input-primary">
                    <label className="mb-1">
                      <strong>FaceBook URL</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control bg-white"
                      //placeholder="FaceBook URL"
                      name="facebook_url"
                      value={contactData.facebook_url}
                      onChange={handleBlur}
                    />
                    <span className="text-danger">{errors.facebook_url}</span>
                  </div>

                  <div className="form-group input-primary">
                    <label className="mb-1">
                      <strong>InstaGram URL</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control bg-white"
                      //placeholder="InstaGram URL"
                      name="instagram_url"
                      value={contactData.instagram_url}
                      onChange={handleBlur}
                    />
                    <span className="text-danger">{errors.instagram_url}</span>
                  </div>

                  <div className="form-group input-primary">
                      <label className="mb-1">
                        <strong>Persona</strong>
                      </label>

                      <Select
                        className="form-control bg-white"
                        options={personaOptions}
                        name="persona"
                        placeholder="Select Persona"
                        onChange={handleSelectPersona}
                        value={contactData.selectedPersona}
                        isSearchable={true}
                      />

                      {/* <CreatableSelect
                        className="form-control bg-white"
                        name="persona"
                        isClearable
                        onChange={handleSelectPersona}
                        options={personaOptions}
                        value={selectedPersona}
                      /> */}
                      <span className="text-danger">{errors.persona}</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-lg-6 col-md-6 col-6">
            <div className="card">
                <div className="card-body">

                <div className="form-group input-primary">
                  <label className="mb-1">
                    <strong>Contact Type</strong>
                    <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control bg-white"
                    name="contact_type"
                    onChange={props.handleBlur}
                    value={contactData.contact_type}
                  >
                    <option value="Individual">Individual</option>
                    <option value="Business">Business</option>
                  </select>
                  <span className="text-danger">{props.errors.contact_type}</span>
                </div>

                {
                  contactData.contact_type === "Individual"
                        ? 
                        <div className="form-group input-primary">
                          <label className="mb-1">
                            <strong>Job Industry</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control bg-white"
                            name="job_industry"
                            value={contactData.job_industry}
                            onChange={props.handleBlur}
                          />
                          <span className="text-danger">
                            {props.errors.job_industry}
                          </span>
                        </div>
                        : ""
                  }
                  
                  {
                    contactData.contact_type === "Business"
                        ? 
                        <div className="form-group input-primary">
                          <label className="mb-1">
                            <strong>WebSite</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control bg-white"
                            name="bussiness_site"
                            value={contactData.bussiness_site}
                            onChange={props.handleBlur}
                          />
                          <span className="text-danger">
                            {props.errors.bussiness_site}
                          </span>
                        </div>
                        : ""
                  }

                  <div className="form-group input-primary">
                    <label className="mb-1">
                      <strong>Last Name</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control bg-white"
                      //placeholder="Last Name"
                      name="last_name"
                      value={contactData.last_name}
                      onChange={handleBlur}
                    />
                    <span className="text-danger">{errors.last_name}</span>
                  </div>

                  <div className="form-group input-primary">
                    <label className="mb-1">
                      <strong>Assign User</strong>
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control bg-white"
                      name="assign_user"
                      onChange={handleBlur}
                      value={contactData.assign_user}
                    >
                      <option value="">Select User</option>
                      {users.map((user) => (
                        <option key={user.uuid} value={user.uuid}>
                          {user.user_name}
                        </option>
                      ))}
                      <option key={createdby} value={createdby}>
                        Assign To Me
                      </option>
                    </select>
                    <span className="text-danger">
                      {errors.assign_number}
                    </span>
                  </div>

                  <div className="form-group input-primary">
                    <label className="mb-1">
                      <strong>Other Contact Number</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control bg-white"
                      //placeholder="Other Contact Number"
                      name="other_contact_number"
                      value={contactData.other_contact_number}
                      onChange={handleBlur}
                    />
                    <span className="text-danger">
                      {errors.other_contact_number}
                    </span>
                  </div>

                  <div className="form-group input-primary">
                    <label className="mb-1">
                      <strong>Address2</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control bg-white"
                      //placeholder="Address2"
                      name="address2"
                      value={contactData.address2}
                      onChange={handleBlur}
                    />
                    <span className="text-danger">{errors.address2}</span>
                  </div>

                  <div className="form-group input-primary">
                    <label className="mb-1">
                      <strong>State</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control bg-white"
                      //placeholder="State"
                      name="state"
                      value={contactData.state}
                      onChange={handleBlur}
                    />
                    <span className="text-danger">{errors.state}</span>
                  </div>

                  <div className="form-group input-primary">
                    <label className="mb-1">
                      <strong>Zipcode</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control bg-white"
                      //placeholder="Zipcode"
                      name="zipcode"
                      value={contactData.zipcode}
                      onChange={handleBlur}
                    />
                    <span className="text-danger">{errors.zipcode}</span>
                  </div>

                  <div className="form-group input-primary">
                    <label className="mb-1">
                      <strong>LinkedIn URL</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control bg-white"
                      //placeholder="LinkedIn URL"
                      name="linked_in_url"
                      value={contactData.linked_in_url}
                      onChange={handleBlur}
                    />
                    <span className="text-danger">{errors.linked_in_url}</span>
                  </div>

                  <div className="form-group input-primary">
                    <label className="mb-1">
                      <strong>Twitter URL</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control bg-white"
                      //placeholder="Twitter URL"
                      name="twitter_url"
                      value={contactData.twitter_url}
                      onChange={handleBlur}
                    />
                    <span className="text-danger">{errors.twitter_url}</span>
                  </div>

                  <div className="form-group input-primary">
                    <label className="mb-1">
                      <strong>Tags</strong>
                    </label>

                    <CreatableSelect
                      className="form-control bg-white"
                      name="tags"
                      isMulti
                      onChange={handleSelectTags}
                      options={tagsOptions}
                      value={selectedTags}
                      
                    />
                    <span className="text-danger">{errors.tags}</span>
                  </div>

                </div>
            </div>
        </div>
      </div>          
                    

    </Fragment>
  );
}

export default SectionOverview;
