import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  Table,
  Modal,
  Button,
  ButtonGroup,
  DropdownButton,
  Dropdown,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import PageTitle from "../../layouts/PageTitle";
import Pagination from "../../layouts/Pagination";
import { getDid } from "../../actions/did";
import CheckModulePermisson from "../../common/CheckModulePermisson";
import PermissionCheck from "../../common/PermissionCheck";
import BusinessNumberForm from "../../onboarding/Wizard/Numbers";
import { confirmAlert } from "react-confirm-alert";
import RouteUpdate from "./RouteUpdate";
import FilterForm from "./FilterForm";
import DeleteDid from "./DeleteDid";
import "./style.css";
import Loader from "../../layouts/Loader";
import filter_img from "./../../../images/profile/filter.png";

function Did() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;
  const module_name = "did";

  let module_per = CheckModulePermisson(module_name);
  const closeModel = (value) => {
    setBusinessNumberModal(value);
  };

  const closeFilterFormModel = (value) => {
    setFilterFormModal(value);
  };

  const closeRouteUpdateModel = (value) => {
    setRouteUpdateModal(value);
  };

  const closeDeleteDidModel = (value) => {
    setDeleteDidModal(value);
  };

  const [businessNumberModal, setBusinessNumberModal] = useState(false);
  const [routeUpdateModal, setRouteUpdateModal] = useState(false);
  const [filterFormModal, setFilterFormModal] = useState(false);
  const [deleteDidModal, setDeleteDidModal] = useState(false);

  const [didData, setDidData] = useState({
    did_uuid: "",
    route_name: "",
    did_number: "",
    createdby: createdby,
    main_uuid: user_data.main_uuid,
  });

  let isPermission = true;
  if (module_per === "" || user_type === "admin") {
    isPermission = true;
  } else {
    isPermission = false;
  }

  let listing_per = PermissionCheck(module_name, "listing", "", "", "");
  let add_per = PermissionCheck(module_name, "add", "", "", "");

  const dispatch = useDispatch();
  let navigate = useNavigate();

  if (listing_per === "none") {
    navigate("/error-403");
  }

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }

  const [limits, setLimits] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [did, setDid] = useState([]);
  const [didEvent, setDidEvent] = useState("");
  const [search, setSearch] = useState("");
  const [pageCount, setpageCount] = useState(0);
  const [offSet, setOffSet] = useState(0);

  const [filter, setFilter] = useState({
    destination_type: "",
    prefix: "",
    description: "",
  });

  const handleDidEvent = (event) => {
    setDidEvent(event);
  };

  const [loading, setLoading] = useState(false);

  let orderby = "did_number ASC";

  if (module_per === "" || user_type === "admin") {
    useEffect(() => {
      setLoading(true);
      dispatch(
        getDid(
          group_per,
          group_uuid,
          user_type,
          search,
          filter,
          offSet,
          limits,
          orderby,
          main_uuid,
          createdby,
          token
        )
      )
        .then((response) => {
          setDid(response.data.data);
          const total = response.data.total;
          setpageCount(Math.ceil(total / limits));
          setTotalRecords(total);
          setLoading(false);
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    }, [didEvent, limits]);
  }

  const handlePageClick = async (data) => {
    // let currentPage = data.selected + 1;

    setOffSet(limits * data.selected);
    setDidEvent("update");
  };

  const fnDeleteGroup = (uuid, did_number) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this Inbound Number?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteGroupService(uuid, did_number),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteGroupService = (uuid, did_number) => {
    let new_data = { ...didData };
    new_data["did_uuid"] = uuid;
    new_data["did_number"] = did_number;

    setDidData(new_data);
    setDeleteDidModal(true);
  };

  const updateRoute = (uuid, did_number, did_action_type, did_action_uuid) => {
    let new_data = { ...didData };
    new_data["did_uuid"] = uuid;
    new_data["did_number"] = did_number;
    new_data["route_name"] = did_action_type + "_" + did_action_uuid;

    setDidData(new_data);
    setRouteUpdateModal(true);
  };

  const filterInboundNumber = (data) => {
    setFilter(data);
    setDidEvent(Math.random);
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
    setDidEvent(Math.random);
  };

  const getDestination = (
    did_action_type,
    ivr_menu_name,
    ring_group_extension,
    extension,
    time_condition_name,
    voicemail_id,
    did_number
  ) => {
    if (did_action_type === "ivr") {
      return "Auto Attendent - " + ivr_menu_name;
    } else if (did_action_type === "extension") {
      return "Extention - " + extension;
    } else if (did_action_type === "ringgroup") {
      return "Ring Group - " + ring_group_extension;
    } else if (did_action_type === "did") {
      return "Inbound Number - " + did_number;
    } else if (did_action_type === "voicemail") {
      return "Voicemail - " + voicemail_id;
    } else if (did_action_type === "time_condition") {
      return "Time Condition - " + time_condition_name;
    }
  };

  const chnageLimit = (value) => {
    setLimits(value);
  };

  const applySearch = (data) => {
    setFilter(data);

    setDidEvent(Math.random);
    document.body.click();
  };

  const popoverBottom = (
    <Popover id="popover-positioned-bottom" title="Popover bottom">
      <FilterForm applySearch={applySearch} searchData={filter} />.
    </Popover>
  );

  const breadCurmList = [
    { label: "Calls", link: "/", is_active: "NO" },
    {
      label: "Inbound Numbers: Total Amount " + totalRecords,
      link: "/",
      is_active: "YES",
    },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />

      {isPermission ? (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <p className="card-title">
                  Inbound Numbers List
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="bottom"
                    overlay={popoverBottom}
                  >
                    <span className="cursor-pointer ml-3">
                      <img
                        src={filter_img}
                        className="img-fluid rounded-circle"
                        alt="Search"
                      />
                    </span>
                  </OverlayTrigger>
                  {/* <span className="ml-3" onClick={() => filterFormBtn(true)}>
                    <img
                      src={profile}
                      className="img-fluid rounded-circle"
                      alt="profile"
                    />
                  </span> */}
                </p>
                <div className="input-group input-success search_box">
                  <input
                    className="form-control text-primary"
                    type="search"
                    placeholder="Search Here"
                    aria-label="Search"
                    onChange={(e) => handleChange(e)}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text text-primary">
                      <svg
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                          fill="#A4A4A4"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div className="card-body">
                Show
                <ButtonGroup className="ml-2 mr-2">
                  <DropdownButton
                    as={ButtonGroup}
                    id="bg-nested-dropdown"
                    size="sm"
                    title={limits}
                    variant="custom-dropdown"
                  >
                    <Dropdown.Item
                      eventKey="1"
                      href="#"
                      onClick={() => chnageLimit(10)}
                    >
                      10
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="1"
                      href="#"
                      onClick={() => chnageLimit(25)}
                    >
                      25
                    </Dropdown.Item>

                    <Dropdown.Item
                      eventKey="1"
                      href="#"
                      onClick={() => chnageLimit(50)}
                    >
                      50
                    </Dropdown.Item>

                    <Dropdown.Item
                      eventKey="1"
                      href="#"
                      onClick={() => chnageLimit(100)}
                    >
                      100
                    </Dropdown.Item>
                  </DropdownButton>
                </ButtonGroup>
                entries
                <Table responsive className="w-100">
                  <thead>
                    <tr role="row">
                      <th>Number</th>
                      <th>Description</th>
                      <th>Destination</th>
                      {/* {listing_per === "group" || user_type === "admin" ? (
                          <th>Created By</th>
                        ) : (
                          <></>
                        )} */}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {did.map((did) => (
                      <tr key={did.uuid}>
                        <td>
                          <Link
                            to="/update-number"
                            state={{
                              did_uuid: did.uuid,
                            }}
                            hidden={PermissionCheck(
                              module_name,
                              "edit",
                              did.group_uuid,
                              did.user_created_by,
                              did.created_by
                            )}
                            className="text-blue"
                          >
                            {did.did_number}
                          </Link>
                        </td>
                        <td>{did.description}</td>
                        <td>
                          {getDestination(
                            did.did_action_type,
                            did.ivr_menu_name,
                            did.ring_group_extension,
                            did.extension,
                            did.time_condition_name,
                            did.voicemail_id,
                            did.did_number
                          )}
                        </td>

                        {/* {listing_per === "group" ||
                        listing_per === "all" ||
                        user_type === "admin" ? (
                          <td>
                            {did.first_name} {did.last_name}
                          </td>
                        ) : (
                          <></>
                        )} */}
                        <td>
                          <Fragment>
                            <div className="d-flex">
                              <Link
                                onClick={() =>
                                  updateRoute(
                                    did.uuid,
                                    did.did_number,
                                    did.did_action_type,
                                    did.did_action_uuid
                                  )
                                }
                                className="btn btn-info shadow btn-xs sharp mr-1"
                              >
                                <i
                                  className="fa fa-caret-down"
                                  aria-hidden="true"
                                ></i>
                              </Link>
                              {
                                <Link
                                  to="/update-number"
                                  state={{
                                    did_uuid: did.uuid,
                                  }}
                                  hidden={PermissionCheck(
                                    module_name,
                                    "edit",
                                    did.group_uuid,
                                    did.user_created_by,
                                    did.created_by
                                  )}
                                  className="btn btn-primary shadow btn-xs sharp mr-1"
                                >
                                  <i className="fa fa-pencil"></i>
                                </Link>
                              }

                              <Button
                                to="#"
                                className="btn btn-danger shadow btn-xs sharp"
                                onClick={() =>
                                  fnDeleteGroup(did.uuid, did.did_number)
                                }
                                hidden={PermissionCheck(
                                  module_name,
                                  "delete",
                                  did.group_uuid,
                                  did.user_created_by,
                                  did.created_by
                                )}
                              >
                                <i className="fa fa-trash"></i>
                              </Button>
                            </div>
                          </Fragment>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination
                  pageCount={pageCount}
                  handlePageNumberClick={handlePageClick}
                />
              </div>
              <div className="card-header">
                {add_per === "yes" ? (
                  <Button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => setBusinessNumberModal(true)}
                  >
                    Add New Phone Numbers
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Manage Number</h4>
              </div>
              <div className="card-body">
                <h3>You have don't access this modules</h3>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        className="fade bd-example-modal-xl"
        show={businessNumberModal}
        size="xl"
      >
        <BusinessNumberForm
          closeModel={closeModel}
          handleSendSmsEvent={""}
          type={"normal"}
        />
      </Modal>

      <Modal className="fade" show={routeUpdateModal}>
        <RouteUpdate
          closeModel={closeRouteUpdateModel}
          handleDidListEvent={handleDidEvent}
          didData={didData}
        />
      </Modal>

      <Modal className="fade" show={filterFormModal}>
        <FilterForm
          closeModel={closeFilterFormModel}
          handleDidListEvent={handleDidEvent}
          filterInboundNumber={filterInboundNumber}
        />
      </Modal>

      <Modal className="fade" show={deleteDidModal}>
        <DeleteDid
          closeModel={closeDeleteDidModel}
          handleDidListEvent={handleDidEvent}
          didData={didData}
        />
      </Modal>
    </Fragment>
  );
}

export default Did;
