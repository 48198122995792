import React, { Fragment, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import BusinessNumberForm from "./Numbers";
import EmailConfigForm from "./EmailConfigForm";
import BrandingAndConfigurationform from "./BrandingAndConfiguration";
import EndUsersForm from "./EndUsers";
import "./index.css";
import Tooltip from "rc-tooltip";
import { getTimezone, getCompanyAndE911Details } from "../../actions/comman";

const OnboardingList = (props) => {
  
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  let companyData = user.data.companyData;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  let stepData = props.stepData;

  const dispatch = useDispatch();
  const [timeZones, setTimeZones] = useState([]);

  const [brandingData, setBrandingData] = useState();
  const [brandingEvent, setBrandingEvent] = useState("");

  const closeModel = (value) => {
    setBusinessNumberModal(value);
  };

  useEffect(() => {
    dispatch(getTimezone(main_uuid, createdby, token))
      .then((response) => {
        let arr = response.data.data.map((obj, i) => {
          return { label: obj.timezone_name, value: obj.time_zone_uuid };
        });
        setTimeZones(arr);
        return;
      })
      .catch(() => {
        //setLoading(false);
      });
  }, []);

  useEffect(() => {
    dispatch(getCompanyAndE911Details(createdby, main_uuid, createdby, token))
      .then((response) => {
        setBrandingData(response.data[0]);
        return;
      })
      .catch(() => {
        //setLoading(false);
      });
  }, [brandingEvent]);

  const closeECModel = (value) => {
    setEmailConfigModal(value);
  };

  const closeBACModel = (value) => {
    setBrandingAndConfigurationModal(value);
  };

  const brandingEventHandle = (value) => {
    setBrandingEvent(value);
  };

  const closeEUModel = (value) => {
    setEndUsersModal(value);
  };

  let currentStep = props.currentStep;

  let step_two_color = "widget-stat card bg-success";
  let step_third_color = "widget-stat card bg-success";
  let step_fouth_color = "widget-stat card bg-success";

  if (currentStep >= 2) {
    step_two_color = "widget-stat card bg-primary";
  }

  if (currentStep >= 3) {
    step_third_color = "widget-stat card bg-primary";
  }

  if (currentStep >= 4) {
    step_fouth_color = "widget-stat card bg-primary";
  }

  const [businessNumberModal, setBusinessNumberModal] = useState(false);
  const [emailConfigModal, setEmailConfigModal] = useState(false);
  const [brandingAndConfigurationModal, setBrandingAndConfigurationModal] =
    useState(false);
  const [endUsersModal, setEndUsersModal] = useState(false);

  return (
    <Fragment>
      <section className="pricewrap">
        <div className="row">
          <div className="col-lg-12 col-xxl-12">
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-sm-3 col-xxl-3">
                {currentStep > 1 ? (
                  <div
                    className="widget-stat card bg-primary link_onboarding"
                    onClick={() => {
                      "";
                    }}
                  >
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="mr-3">
                          <i className="flaticon-381-promotion" />
                        </span>
                        <div className="media-body text-white text-right">
                          <Tooltip
                            placement="top"
                            overlay={<span>Setup Done </span>}
                          >
                            <p className="mb-1">Set up your business number</p>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="widget-stat card bg-primary link_onboarding"
                    onClick={() => setBusinessNumberModal(true)}
                  >
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="mr-3">
                          <i className="flaticon-381-promotion" />
                        </span>
                        <div className="media-body text-white text-right">
                          <p className="mb-1">Set up your busuiness number</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-xl-3 col-lg-3 col-sm-3 col-xxl-">
                {currentStep > 2 ? (
                  <div
                    className="widget-stat card bg-primary link_onboarding"
                    onClick={() => {
                      "";
                    }}
                  >
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="mr-3">
                          <i className="fa fa-envelope" />
                        </span>
                        <div className="media-body text-white text-right">
                          <Tooltip
                            placement="top"
                            overlay={
                              <span>Email Configuration Setup Done </span>
                            }
                          >
                            <p className="mb-1">Email Configuration</p>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={step_two_color}>
                    <div
                      className="card-body p-4 link_onboarding"
                      onClick={() => setEmailConfigModal(true)}
                    >
                      <div className="media">
                        <span className="mr-3">
                          <i className="fa fa-envelope" />
                        </span>
                        <div className="media-body text-white text-right">
                          <p className="mb-1">Email Configuration</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-xl-3 col-lg-3 col-sm-3 col-xxl-3">
                {currentStep > 3 ? (
                  <div
                    className="widget-stat card bg-primary link_onboarding"
                    onClick={() => {
                      "";
                    }}
                  >
                    <div className="card-body p-4" onClick={() => ""}>
                      <div className="media">
                        <span className="mr-3">
                          <i className="flaticon-381-diamond" />
                        </span>
                        <div className="media-body text-white text-right">
                          <Tooltip
                            placement="top"
                            overlay={
                              <span>
                                Branding and Configuration Setup Done{" "}
                              </span>
                            }
                          >
                            <p className="mb-1">Branding and Configuration</p>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={step_third_color}>
                    <div
                      className="card-body p-4 link_onboarding"
                      onClick={() => setBrandingAndConfigurationModal(true)}
                    >
                      <div className="media">
                        <span className="mr-3">
                          <i className="flaticon-381-diamond" />
                        </span>
                        <div className="media-body text-white text-right">
                          <p className="mb-1"> Branding and Configuration</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-xl-3 col-lg-3 col-sm-3 col-xxl-3">
                {currentStep > 4 ? (
                  <div className="widget-stat card bg-success link_onboarding">
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="mr-3">
                          <i className="fa fa-address-card" />
                        </span>
                        <div className="media-body text-white text-right">
                          <p className="mb-1"> End users</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={step_fouth_color}>
                    <div
                      className="card-body p-4 link_onboarding"
                      onClick={() => setEndUsersModal(true)}
                    >
                      <div className="media">
                        <span className="mr-3">
                          <i className="fa fa-address-card" />
                        </span>
                        <div className="media-body text-white text-right">
                          <p className="mb-1"> End users</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        className="fade bd-example-modal-xl"
        show={businessNumberModal}
        size="xl"
      >
        <BusinessNumberForm
          closeModel={closeModel}
          handleSendSmsEvent={""}
          type={"onboarding"}
        />
      </Modal>
      {stepData.is_did_done === "YES" && (
        <Modal
          className="fade bd-example-modal-lg"
          show={emailConfigModal}
          size="lg"
        >
          <EmailConfigForm
            closeECModel={closeECModel}
            user_data={user_data}
            token={token}
            handleEmailConfigEvent={""}
          />
        </Modal>
      )}

      {stepData.is_did_done === "YES" &&
        stepData.is_email_config_done === "YES" && (
          <Modal
            className="fade bd-example-modal-lg branding_model"
            show={brandingAndConfigurationModal}
            size="lg"
          >
            <BrandingAndConfigurationform
              closeBACModel={closeBACModel}
              user_data={user_data}
              companyData={companyData}
              brandingData={brandingData}
              timeZones={timeZones}
              token={token}
              brandingEventHandle={brandingEventHandle}
              handleThreeData={props.handleThreeData}
            />
          </Modal>
        )}
      {stepData.is_did_done === "YES" &&
        stepData.is_did_done === "YES" &&
        stepData.is_branding_done === "YES" && (
          <Modal
            className="fade bd-example-modal-lg"
            show={endUsersModal}
            size="lg"
          >
            <EndUsersForm
              closeEUModel={closeEUModel}
              user_data={user_data}
              token={token}
              handleEmailConfigEvent={""}
            />
          </Modal>
        )}
    </Fragment>
  );
};

export default OnboardingList;
