import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

//UserMeetingEvent

const getEventList = (createdby, token) => {
  return axios
    .post(
      API_URL + "calender/user-meeting-events",
      {
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateUserMeetingEvent = (update_data, token) => {
 
  return axios
    .post(
      API_URL + "calender/update-user-meeting-event",
      update_data,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const craeteUserMeetingEvent = (create_data, token) => {
  return axios
    .post(API_URL + "calender/create-user-meeting-event", create_data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};


const deleteUserMeetingEvent = (user_meeting_event_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "calender/delete-user-meeting-event",
      {
        user_meeting_event_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getMeetingEvent = (user_meeting_event_uuid) => {
  return axios
    .post(
      API_URL + "calender/get-meeting-event-detail",
      {
        user_meeting_event_uuid
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getMeetingModes = (user_meeting_event_uuid) => {
  return axios
    .post(
      API_URL + "calender/get-meeting-modes",
      {
        user_meeting_event_uuid
      }
    )
    .then((response) => {
      return response.data;
    });
};

export default {
  getEventList,
  updateUserMeetingEvent,
  craeteUserMeetingEvent,
  deleteUserMeetingEvent,
  getMeetingEvent,
  getMeetingModes
};
