// FilePreview.js
import React from "react";
import "./style.css";

const FilePreview = ({ files, removeFile }) => {


  const removeFileBtn = (id) => {
    removeFile(Object.values(files).filter((file) => file.name !== id));
  };

  return Array.from(files).map((file1, i) => {
    return (
      <>
        <div key={i} className="file-preview">
          <p> {file1.name}</p>
          <button
            type="button"
            className="btn btn-sm btn-danger btn btn-primary"
            onClick={() => removeFileBtn(file1.name)}
          >
            <i className="fa fa-close"></i>
          </button>
        </div>
      </>
    );
  });
};

export default FilePreview;
