import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Card, Modal, Button } from "react-bootstrap";
import { notifyInfo, notifyError } from "../../components/Toastr/Toastr";
import { useNavigate } from "react-router-dom";
import { saveE911Data } from "../../actions/user";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { GoogleApiWrapper } from "google-maps-react";
import { getNumberListDropdown } from "../../actions/comman";
import PermissionCheck from "../../common/PermissionCheck";
import Loader from "../../layouts/Loader";
const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

function EndUsers(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;
  let listing_per = PermissionCheck("did", "listing", "", "", "");

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }

  const closeModel = (arg) => {
    props.closeEUModel(arg);
  };

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [numbers, setNumbers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [manuallyCheck, setManuallyCheck] = useState("checked");
  const [address, setAddress] = useState("");

  const [userEndData, setUserEndData] = useState({
    caller_id_name: "",
    caller_id_number: "",
    address_1: "",
    address_2: "",
    house_number: "",
    street_name: "",
    city: "",
    state: "",
    zip: "",
    user_type: "admin",
    main_admin_uuid: user_data.main_uuid,
    createdby: user_data.user_uuid,
    user_uuid: user_data.user_uuid,
    token: token,
  });

  const [errors, setErrors] = useState({
    caller_id_name: "",
    caller_id_number: "",
    address_1: "",
    address_2: "",
    house_number: "",
    street_name: "",
    city: "",
    state: "",
    zip: "",
    smtp_encryption: "",
  });

  const handleBlur = (e) => {
    const newGroupData = { ...userEndData };
    let error_data = { ...errors };

    if (e.target.name === "caller_id_name") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Caller ID name is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "caller_id_number") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Caller ID number_uuid is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "street_name") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Street Name is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "house_number") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Suite/Apt/House number is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "city") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "City is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "state") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "State is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "zip") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Zip is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    newGroupData[e.target.name] = e.target.value;
    setUserEndData(newGroupData);
  };

  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      
      let error_data = { ...errors };

      let add_data = results[0].address_components;

      const newformdata = { ...userEndData };

      newformdata.address_1 = results[0].formatted_address;

      if (newformdata.address_1.length === 0) {
        error_data["address_1"] = "Address is required.";
        setErrors(error_data);
      } else {
        error_data["address_1"] = "";
        setErrors(error_data);
      }

      setAddress(results[0].formatted_address);
      add_data.forEach((add, index) => {
        if (
          add.types.find((element) => element === "subpremise") === "subpremise"
        ) {
          newformdata.house_number = add.long_name;
        }

        if (add.types.find((element) => element === "premise") === "premise") {
          newformdata.street_name = add.long_name;
        }

        if (add.types.find((element) => element === "route") === "route") {
          newformdata.street_name = add.long_name;
        }

        if (
          add.types.find((element) => element === "postal_code") === "postal_code"
        ) {
          newformdata.zip = add.long_name;
        }

        if (
          add.types.find(
            (element) => element === "administrative_area_level_1"
          ) === "administrative_area_level_1"
        ) {
          newformdata.state = add.long_name;
        }
        if (
          add.types.find(
            (element) => element === "administrative_area_level_2"
          ) === "administrative_area_level_2"
        ) {
          newformdata.city = add.long_name;
        } else {
          if (
            add.types.find(
              (element) => element === "administrative_area_level_3"
            ) === "administrative_area_level_3"
          ) {
            newformdata.city = add.long_name;
          }
        }
        setUserEndData(newformdata);
      });
    } catch (error) {
      console.error("Error selecting address:", error);
    }
  };

  function handleSelectCheck(data) {
    let rec_chk_val = data.target.value;

    if (rec_chk_val !== "" && rec_chk_val === "checked") {
      setManuallyCheck("");
    } else {
      setManuallyCheck("checked");
    }
  }

  useEffect(() => {
    dispatch(
      getNumberListDropdown(
        "update",
        createdby,
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        setNumbers(response.data);
        return;
      })
      .catch(() => {
        //setLoading(false);
      });
  }, []);


  const updateEndUsers = (e) => {
    let error_data = { ...errors };
    let isError = "false";
    setLoading(true);

    if (userEndData.caller_id_name === "") {
      error_data["caller_id_name"] = "Caller ID Name is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (userEndData.caller_id_number === "") {
      error_data["caller_id_number"] = "Caller ID Number is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (userEndData.address_1 === "") {
      error_data["address_1"] = "Address is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (userEndData.house_number === "") {
      error_data["house_number"] = "Suite/Apt  is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (userEndData.street_name === "") {
      error_data["street_name"] = "Street name  is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (userEndData.city === "") {
      error_data["city"] = "City is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (userEndData.state === "") {
      error_data["state"] = "State is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (userEndData.zip === "") {
      error_data["zip"] = "Zio code is required.";
      setErrors(error_data);
      isError = "true";
    } else {
      if (!/^[0-9]{5,6}$/i.test(userEndData.zip)) {
        error_data["zip"] = "Invalid zip.";
        setErrors(error_data);
        isError = "true";
      }
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call save E911 Data API
      dispatch(saveE911Data(userEndData, token))
        .then((res) => {
          if (res.status === 200) {
            notifyInfo(res.message);
            closeModel(false);
            user.data.data.is_user_done = "YES";
            localStorage.setItem("user", JSON.stringify(user));
            setLoading(false);
            navigate("/", { replace: true });
          } else if (res.status === 406) {
            error_data["name"] = res.message;
            setErrors(error_data);
            isError = "true";
          } else if (res.status === 500) {
            setLoading(false);
             notifyError("Something went wrong.");
          } else {
            setLoading(false);
             notifyError("Something went wrong.");
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>E911 Callar ID Number and Address</Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xl="12">
            <Card className="bgl-primary">
              <Card.Body className="card-body">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Caller ID Name</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Caller ID Name"
                        name="caller_id_name"
                        id="caller_id_name"
                        onChange={handleBlur}
                      />
                      <span className="text-danger">
                        {errors.caller_id_name}
                      </span>
                    </div>
                  </div>

                  <div className="col-6">
                    {/* <div className="form-group">
                      <label className="mb-1">
                        <strong>Caller ID Number</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Caller ID Number"
                        name="caller_id_number"
                        id="caller_id_number"
                        onChange={handleBlur}
                      />
                      <span className="text-danger">
                        {errors.caller_id_number}
                      </span>
                    </div> */}
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Caller ID Number</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="caller_id_number"
                        onChange={handleBlur}
                      >
                        <option value="">Select Caller ID Number</option>
                        {numbers.map((num) => (
                          <option key={num.value} value={num.value}>
                            {num.label}
                          </option>
                        ))}
                      </select>

                      <span className="text-danger">
                        {errors.caller_id_number}
                      </span>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl="12">
            <Card className="bgl-primary">
              <Card.Header className="d-block card-header">
                <Card.Title>Address</Card.Title>
              </Card.Header>
              <Card.Body className="card-body">
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label className="text-label">
                        Addess
                        <span className="text-danger mr-4">*</span>
                        <span className="ml-4">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="check1"
                            name="is_recurring"
                            onChange={handleSelectCheck}
                            value={manuallyCheck}
                          />
                          <label className="form-check-label" htmlFor="check1">
                            Enter manually
                          </label>
                        </span>
                      </label>
                      {manuallyCheck === "checked" ? (
                        <PlacesAutocomplete
                          value={address}
                          onChange={(value) => setAddress(value)}
                          onSelect={handleSelect}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div>
                              <input
                                {...getInputProps({
                                  placeholder: "Search Places...",
                                  className: "form-control",
                                })}
                                list="data"
                              />
                              {/* <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              <datalist id="data">
                                {suggestions.map((suggestion) => (
                                  <option
                                    {...getSuggestionItemProps(suggestion)}
                                    key={suggestion.placeId}
                                    value={suggestion.description}
                                  />
                                ))}
                              </datalist>
                            </div> */}
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                <ul className="list-group">
                                  {suggestions.map((suggestion) => (
                                    <li
                                      className=""
                                      {...getSuggestionItemProps(suggestion)}
                                      key={suggestion.placeId}
                                    >
                                      {suggestion.description}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      ) : (
                        <div className="form-group">
                          <textarea
                            rows={2}
                            className="form-control"
                            name="address_1"
                            placeholder="address"
                            onChange={handleBlur}
                            value={userEndData.address_1}
                          />
                        </div>
                      )}
                      <span className="text-danger">{errors.address_1}</span>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Street Name</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Street Name"
                        name="street_name"
                        id="street_name"
                        onChange={handleBlur}
                        value={userEndData.street_name}
                      />
                      <span className="text-danger">{errors.street_name}</span>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Suite/Apt</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Suite/Apt Number"
                        name="house_number"
                        id="house_number"
                        onChange={handleBlur}
                        value={userEndData.house_number}
                      />
                      <span className="text-danger">{errors.house_number}</span>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>City</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="City"
                        name="city"
                        id="city"
                        onChange={handleBlur}
                        value={userEndData.city}
                      />
                      <span className="text-danger">{errors.city}</span>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>State</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="State"
                        name="state"
                        id="state"
                        onChange={handleBlur}
                        value={userEndData.state}
                      />
                      <span className="text-danger">{errors.state}</span>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Zip</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Zip code"
                        name="zip"
                        id="zip"
                        onChange={handleBlur}
                        value={userEndData.zip}
                      />
                      <span className="text-danger">{errors.zip}</span>
                    </div>
                  </div>
                  {/* <div className="col-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Address Line One"
                              name="address_1"
                              id="address_1"
                              onChange={handleBlur}
                            />

                            <span className="text-danger">
                              {errors.address_1}
                            </span>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Address Line Two"
                              name="address_2"
                              id="address_2"
                              onChange={handleBlur}
                            />
                            <span className="text-danger">
                              {errors.address_2}
                            </span>
                          </div>
                        </div> */}

                  {/* <div className="col-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="City"
                              name="city"
                              id="city"
                              onChange={handleBlur}
                            />
                            <span className="text-danger">{errors.city}</span>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="State"
                              name="state"
                              id="state"
                              onChange={handleBlur}
                            />
                            <span className="text-danger">{errors.state}</span>
                          </div>
                        </div> */}
                  {/* <div className="col-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Zip code"
                              name="zip"
                              id="zip"
                              onChange={handleBlur}
                            />
                            <span className="text-danger">{errors.zip}</span>
                          </div>
                        </div> */}
                </div>
                <div className="row text-right">
                  <div className="col-sm">
                    {/* <Button
                        type="submit"
                        variant="primary"
                        className="btn-sm"
                      >
                        Save
                      </Button> */}
                    <Button
                      type="button"
                      variant="primary"
                      className="btn-sm mr-2"
                      onClick={(e) => updateEndUsers()}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Modal.Body>
    </Fragment>
  );
}

// export default EndUsers;

export default GoogleApiWrapper({
  apiKey: API_KEY,
})(EndUsers);
