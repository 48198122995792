import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Card, Modal, Button } from "react-bootstrap";
import { saveE911Data } from "../../actions/user";
import { notifyInfo } from "../../components/Toastr/Toastr";
import { useNavigate } from "react-router-dom";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { GoogleApiWrapper } from "google-maps-react";
import Loader from "../../layouts/Loader";
const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

function E911Form(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;

  const closeModel = (arg) => {
    props.closeE911Model(arg);
  };

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [e911Data, setE911Data] = useState({
    caller_id_name: "",
    address_1: "",
    address_2: "",
    house_number: "",
    street_name: "",
    city: "",
    state: "",
    zip: "",
    user_type: "sub_user",
    main_admin_uuid: user_data.main_uuid,
    createdby: user_data.user_uuid,
    user_uuid: user_data.user_uuid,
  });

  const [address, setAddress] = useState(e911Data.address_1);
  const [manuallyCheck, setManuallyCheck] = useState("checked");

  const [errors, setErrors] = useState({
    caller_id_name: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    zip: "",
  });

  const handleBlur = (e) => {
    const newData = { ...e911Data };
    let error_data = { ...errors };

    if (e.target.name === "address_1") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Address is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "caller_id_name") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Caller ID name is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "street_name") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Street Name is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "house_number") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Suite/Apt/House number is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "city") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "City is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "state") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "State is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "zip") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Zip is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    newData[e.target.name] = e.target.value;
    setE911Data(newData);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";
    setLoading(true);
    if (e911Data.caller_id_name === "") {
      error_data["caller_id_name"] = "Caller Id Name is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (e911Data.address_1 === "") {
      error_data["address_1"] = "Address is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (e911Data.house_number === "") {
      error_data["house_number"] = "Suite/Apt  is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (e911Data.street_name === "") {
      error_data["street_name"] = "Street name  is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (e911Data.city === "") {
      error_data["city"] = "City is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (e911Data.state === "") {
      error_data["state"] = "State is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (e911Data.zip === "") {
      error_data["zip"] = "Zip is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call save data API
      dispatch(saveE911Data(e911Data, token))
        .then((res) => {
          if (res.status === 200) {
            notifyInfo(res.message);
            closeModel(false);
            user.data.data.is_e911_done = "YES";
            localStorage.setItem("user", JSON.stringify(user));
            navigate("/user-onboarding", { replace: true });
            window.location.reload();
          } else if (res.status === 406) {
            error_data["name"] = res.message;
            setErrors(error_data);
            isError = "true";
          } else if (res.status === 500) {
            // notifyError("Something went wrong.");
          } else {
            // notifyError("Something went wrong.");
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      
      let add_data = results[0].address_components;

      const newformdata = { ...e911Data };

      newformdata.address_1 = results[0].formatted_address;
      setAddress(results[0].formatted_address);
      add_data.forEach((add, index) => {
        if (
          add.types.find((element) => element === "subpremise") === "subpremise"
        ) {
          newformdata.house_number = add.long_name;
        }

        if (add.types.find((element) => element === "premise") === "premise") {
          newformdata.street_name = add.long_name;
        }

        if (add.types.find((element) => element === "route") === "route") {
          newformdata.street_name = add.long_name;
        }

        if (
          add.types.find((element) => element === "postal_code") === "postal_code"
        ) {
          newformdata.zip = add.long_name;
        }

        if (
          add.types.find(
            (element) => element === "administrative_area_level_1"
          ) === "administrative_area_level_1"
        ) {
          newformdata.state = add.long_name;
        }
        if (
          add.types.find(
            (element) => element === "administrative_area_level_2"
          ) === "administrative_area_level_2"
        ) {
          newformdata.city = add.long_name;
        } else {
          if (
            add.types.find(
              (element) => element === "administrative_area_level_3"
            ) === "administrative_area_level_3"
          ) {
            newformdata.city = add.long_name;
          }
        }
        setE911Data(newformdata);
      });
    } catch (error) {
      console.error("Error selecting address:", error);
    }
  };

  function handleSelectCheck(data) {
    let rec_chk_val = data.target.value;

    if (rec_chk_val !== "" && rec_chk_val === "checked") {
      setManuallyCheck("");
    } else {
      setManuallyCheck("checked");
    }
  }

  return (
    <Fragment>
      {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>E911 Caller ID and Address</Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <form action="" onSubmit={(e) => submitHandler(e)}>
          <Row>
            <Col xl="12">
              <Card className="bgl-primary">
                <Card.Body className="card-body">
                  <div className="row">
                    <div className="col-sm">
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>Caller ID Name</strong>
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Caller ID Name"
                          name="caller_id_name"
                          id="caller_id_name"
                          onChange={handleBlur}
                        />
                        <span className="text-danger">
                          {errors.caller_id_name}
                        </span>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col xl="12">
              <Card className="bgl-primary">
                <Card.Header className="d-block card-header">
                  <Card.Title>Address</Card.Title>
                </Card.Header>
                <Card.Body className="card-body">
                  <div className="row">
                    <div className="col-sm">
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label className="text-label">
                              Addess
                              <span className="text-danger mr-4">*</span>
                              <span className="ml-4">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="check1"
                                  name="is_recurring"
                                  onChange={handleSelectCheck}
                                  value={manuallyCheck}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="check1"
                                >
                                  Enter manually
                                </label>
                              </span>
                            </label>

                            {manuallyCheck === "checked" ? (
                              <PlacesAutocomplete
                                value={address}
                                onChange={(value) => setAddress(value)}
                                onSelect={handleSelect}
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps,
                                  loading,
                                }) => (
                                  <div>
                                    <input
                                      {...getInputProps({
                                        placeholder: "Search Places...",
                                        className: "form-control",
                                      })}
                                      list="data"
                                    />

                                    <div className="autocomplete-dropdown-container">
                                      {loading && <div>Loading...</div>}
                                      {suggestions.map((suggestion) => (
                                        <div
                                          className="list"
                                          {...getSuggestionItemProps(
                                            suggestion
                                          )}
                                          key={suggestion.placeId}
                                        >
                                          {suggestion.description}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>
                            ) : (
                              <div className="form-group">
                                <textarea
                                  rows={2}
                                  className="form-control"
                                  name="address_1"
                                  placeholder="address"
                                  onChange={handleBlur}
                                  value={e911Data.address_1}
                                />
                              </div>
                            )}
                            <span className="text-danger">
                              {errors.address_1}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <label className="mb-1">
                              <strong>Suite/Apt</strong>
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Suite/Apt Number"
                              name="house_number"
                              id="house_number"
                              onChange={handleBlur}
                              value={e911Data.house_number}
                            />
                            <span className="text-danger">
                              {errors.house_number}
                            </span>
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="form-group">
                            <label className="mb-1">
                              <strong>Street Name</strong>
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Street Name"
                              name="street_name"
                              id="street_name"
                              onChange={handleBlur}
                              value={e911Data.street_name}
                            />
                            <span className="text-danger">
                              {errors.street_name}
                            </span>
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="form-group">
                            <label className="mb-1">
                              <strong>City</strong>
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="City"
                              name="city"
                              id="city"
                              onChange={handleBlur}
                              value={e911Data.city}
                            />
                            <span className="text-danger">{errors.city}</span>
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="form-group">
                            <label className="mb-1">
                              <strong>State</strong>
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="State"
                              name="state"
                              id="state"
                              onChange={handleBlur}
                              value={e911Data.state}
                            />
                            <span className="text-danger">{errors.state}</span>
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="form-group">
                            <label className="mb-1">
                              <strong>Zip</strong>
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Zip code"
                              name="zip"
                              id="zip"
                              onChange={handleBlur}
                              value={e911Data.zip}
                            />
                            <span className="text-danger">{errors.zip}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <div className="row text-right">
            <div className="col-sm">
              <Button type="submit" variant="primary" className="btn-sm">
                Save
              </Button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Fragment>
  );
}

export default GoogleApiWrapper({
  apiKey: API_KEY,
})(E911Form);
