import React, { Fragment, useState, useEffect } from "react";
import { Tab, Nav } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../layouts/PageTitle";
import GeneralFields from "./GeneralFields";
import KeypadComponent from "./KeypadComponent";
// import AdvanceFields from "./AdvancedFields";
import "./style.css";
// import { notifyInfo, notifyError } from "../Toastr/Toastr";
import { getIvrDetail, getDestinationList } from "../../actions/ivr";
import { getRecordingList } from "../../actions/recording";
import Loader from "../../layouts/Loader";

function UpdateIvrForm() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;

  const params = useLocation();
  const ivr_menu_uuid = params.state.ivr_menu_uuid;
  const activeTab = params.state.activeTab;

  const [ivrData, setIvrData] = useState({
    ivr_menu_name: "",
    //ivr_menu_extension: "",
    ivr_menu_welcome_message: "",
    //ivr_menu_greet_short: "",
    ivr_menu_timeout: "",
    ivr_menu_exit_action: "",
    ivr_menu_direct_dial: "FALSE",
    ivr_menu_cid_prefix: "",
    ivr_menu_invalid_sound: "",
    ivr_menu_exit_sound: "",
    ivr_menu_pin_number: "",
    ivr_menu_enabled: "TRUE",
    ivr_menu_options: "",
    token: token,
    createdby: createdby,
    main_admin_uuid: createdby,
    user_uuid: createdby,
  });

  const [loading, setLoading] = useState(false);
  const [recordings, setRecordings] = useState([
    {
      label: "Select ",
      value: "",
    },
    {
      label: "Add New File",
      value: "file_new_upload",
    },
  ]);
  const [destination, setDestinations] = useState([]);
  const [ivrEvent, setIvrEvent] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRecordingList(createdby, token, "ivr"))
      .then((response) => {
        //setRecordings(response.data.data);

        if (response.data.data !== "") {
          let arrayObj = {};
          let i = 0;
          arrayObj = response.data.data.map((item) => {
            i = i + 1;
            return {
              label: item.recording_name,
              value: item.recording_uuid,
            };
          });

          let ddd = {
            label: "Add New File",
            value: "file_new_upload",
          };
          arrayObj[i] = ddd;

          setRecordings(arrayObj);
        }

        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const main_uuid = user_data.main_uuid;

    dispatch(getDestinationList(createdby, token, main_uuid))
      .then((response) => {
        let groupedOptions = [];
        response.data.forEach((option) => {
          if (!groupedOptions[option.optgroup])
            groupedOptions[option.optgroup] = [];
          groupedOptions[option.optgroup].push({
            value: option.value,
            text: option.text,
          });
        });

        setDestinations(groupedOptions);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    dispatch(getIvrDetail(createdby, token, ivr_menu_uuid))
      .then((response) => {
        const newarr = { ...response.data.ivr_data[0] };
        newarr["token"] = token;
        newarr["createdby"] = createdby;
        newarr["main_admin_uuid"] = createdby;
        newarr["user_uuid"] = createdby;
        newarr["ivr_menu_options"] = response.data.ivr_option_data;

        setIvrData(newarr);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, [ivrEvent]);

  const handleIvrEvent = (val) => {
    setIvrEvent(val);
  };

  const breadCurmList = [
    { label: "Auto-Attendant", link: "/ivr", is_active: "NO" },
    { label: "Update Auto-Attendant", link: "", is_active: "YES" },
    { label: ivrData.ivr_menu_extension, link: "", is_active: "YES" },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />

      <Tab.Container defaultActiveKey={activeTab}>
        <Nav as="ul" className="nav-tabs ringtabs mt-4">
          <Nav.Item as="li" key="1">
            <Nav.Link eventKey="general">
              <i className={`la la-tty mr-2`} />
              General
            </Nav.Link>
          </Nav.Item>

          <Nav.Item as="li" key="2">
            <Nav.Link eventKey="keypad_options">
              <i className={`fa fa-th mr-2`} />
              Keypad Options
            </Nav.Link>
          </Nav.Item>

          {/* <Nav.Item as='li' key="3">
                <Nav.Link eventKey="advanced_options">
                    <i className={`la la-envelope mr-2`} />
                    Advanced Options
                </Nav.Link>
              </Nav.Item> */}
        </Nav>

        <Tab.Content className="ringgroup">
          <Tab.Pane eventKey="general" key="1" className="pt-4">
            <GeneralFields
              ivrData={ivrData}
              recordings={recordings}
              destination={destination}
              handleIvrEvent={handleIvrEvent}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="keypad_options" key="2" className="pt-4">
            <KeypadComponent
              ivrData={ivrData}
              handleIvrEvent={handleIvrEvent}
            />
          </Tab.Pane>
          {/* <Tab.Pane eventKey="advanced_options" key="3" className="pt-4">
                  
                  <AdvanceFields ivrData={ivrData} recordings={recordings} handleIvrEvent={handleIvrEvent} />

              </Tab.Pane> */}
        </Tab.Content>
      </Tab.Container>
    </Fragment>
  );
}

export default UpdateIvrForm;
