import React, { Fragment, useState, useEffect, useRef } from "react";
import { Button, Table, Tab, Nav, Modal } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  updateSmsCampaign,
  getSmsTemplateList,
  getContactGroupList,
  getSmsCampaignDetail,
} from "../../actions/smscampaign";
import { getUserAssignNumberList } from "../../actions/comman";
import Select from "react-select";
import TimeSlotForm from "./TimeSlotForm";
import UpdateTimeSlotForm from "./UpdateTimeSlotForm";
import PageTitle from "../../layouts/PageTitle";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import Loader from "../../layouts/Loader";

function UpdateSmsTemplateForm(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const textboxRef = useRef(null);
  const [updateData, setUpdateData] = useState([]);
  const [updateTimeSlotModal, setUpdateTimeSlotModal] = useState(false);

  const closeModel = (value) => {
    setAddTimeSlotModal(value);
    setUpdateTimeSlotModal(value);
  };

  const params = useLocation();
  const sms_camp_uuid = params.state.sms_camp_uuid;

  const [smsCampData, setSmsCampData] = useState({
    contact_group_uuid: "",
    operation_time: "",
    camp_data: {
      name: "",
      message_body: "",
      is_recurring: "",
      contact_group_uuid: "",
      start_date: "",
      end_date: "",
      recurring_type: "",
      recurring_at: "",
      campaign_status: "",
      timezone: "",
      from_number: "",
      operation_time: "",
      camp_message_type: "",
      sms_template_id: "",
    },
    sms_camp_uuid: sms_camp_uuid,
    main_uuid: main_uuid,
    token: token,
    createdby: createdby,
  });

  const [errors, setErrors] = useState({
    name: "",
    assign_user_data: "",
    group_owner: "",
  });

  const [loading, setLoading] = useState(false);
  const [messageVal, setMessageVal] = useState("");
  const [smsTemplateData, setSmsTemplateData] = useState([]);
  const [contactGroupList, setContactGroupList] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [rows, setRows] = useState([]);
  const [recuringCheck, setRecuringCheck] = useState("");
  const [addTimeSlotModal, setAddTimeSlotModal] = useState(false);

  const [daySel, setDaySel] = useState("monday");
  const [smsCampGroupData, setSmsCampGroupData] = useState([]);
  const [smsCampOpData, setSmsCampOpData] = useState([]);
  const [assignNumberData, setAssignNumberData] = useState([]);

  const [mondayData, setMondayData] = useState([]);
  const [tuesdayData, setTuesdayData] = useState([]);
  const [wednesdayData, setWednesdayData] = useState([]);
  const [thursdayData, setThursdayData] = useState([]);
  const [fridayData, setFridayData] = useState([]);
  const [saturdayData, setSaturdayData] = useState([]);
  const [sundayData, setSundayData] = useState([]);
  const [recuringVal, setRecuringVal] = useState("");
  const [operationUuid, setOperationUuid] = useState("");
  const [selectedMsgOption, setSelectedMsgOption] = useState("");
  const [file, setFile] = useState();

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const addDestinationHandler = (data) => {
    let monData = [];
    let tuesData = [];
    let wenData = [];
    let thursData = [];
    let friData = [];
    let satData = [];
    let sunData = [];

    const newTimeOpedata = [...smsCampOpData, data];

    newTimeOpedata.forEach((emp) => {
      if (emp.day === "monday") {
        monData.push(emp);
      } else if (emp.day === "tuesday") {
        tuesData.push(emp);
      } else if (emp.day === "wednesday") {
        wenData.push(emp);
      } else if (emp.day === "thursday") {
        thursData.push(emp);
      } else if (emp.day === "friday") {
        friData.push(emp);
      } else if (emp.day === "saturday") {
        satData.push(emp);
      } else if (emp.day === "sunday") {
        sunData.push(emp);
      }
    });

    let newMon = { ...mondayData };
    newMon = monData;
    setMondayData(newMon);
    setTuesdayData(tuesData);
    setWednesdayData(wenData);
    setThursdayData(thursData);
    setFridayData(friData);
    setSaturdayData(satData);
    setSundayData(sunData);

    setSmsCampOpData(newTimeOpedata);
  };

  const handleBlur = (e) => {
    const newGroupData = { ...smsCampData };
    let error_data = { ...errors };
    if (e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }

    if (e.target.name === "message_body") {
      setMessageVal(e.target.value);
    }

    if (e.target.name === "recurring_type") {
      let rec_val = e.target.value;
      // rec_val = +rec_val;
      setRecuringVal(rec_val);
    }

    if (e.target.name === "recurring_at") {
      let rec_val1 = e.target.value;
      rec_val1 = "Every " + rec_val1 + " " + recuringVal;

      setRecuringVal(rec_val1);
    }

    // const MIN_FILE_SIZE = 1024 // 1MB
    const MAX_FILE_SIZE = 2048; // 5MB

    if (e.target.name === "file") {
      const fileSizeKiloBytes = e.target.files[0].size / 1024;

      if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        // setErrorMsg("File size is greater than maximum limit");
        error_data[e.target.name] = "File size not allow greater then 2MB.";
        setErrors(error_data);
        return;
      }

      setFile(e.target.files[0]);
    }

    newGroupData["camp_data"][e.target.name] = e.target.value;
    setSmsCampData(newGroupData);
  };

  let select_day = "";
  const handleBlur1 = React.useCallback(
    (e) => {
      select_day = e.currentTarget.id;
      setDaySel(select_day);
    },
    [""]
  );

  const UpdateDestinationHandler = (data) => {
    let select_day = data.day;
    let myArray = [];
    let myArray1 = [];

    if (select_day === "monday") {
      myArray = mondayData;
      const i = myArray.findIndex((x) => x.operation_uuid === operationUuid);
      myArray[i] = data;
      setMondayData(myArray);
    }

    if (select_day === "tuesday") {
      myArray = tuesdayData;
      const i = myArray.findIndex((x) => x.operation_uuid === operationUuid);
      myArray[i] = data;
      setTuesdayData(myArray);
    }

    if (select_day === "wednesday") {
      myArray = wednesdayData;
      const i = myArray.findIndex((x) => x.operation_uuid === operationUuid);
      myArray[i] = data;
      setWednesdayData(myArray);
    }

    if (select_day === "thursday") {
      myArray = thursdayData;
      const i = myArray.findIndex((x) => x.operation_uuid === operationUuid);
      myArray[i] = data;
      setThursdayData(myArray);
    }

    if (select_day === "friday") {
      myArray = fridayData;
      const i = myArray.findIndex((x) => x.operation_uuid === operationUuid);
      myArray[i] = data;
      setFridayData(myArray);
    }

    if (select_day === "saturday") {
      myArray = saturdayData;
      const i = myArray.findIndex((x) => x.operation_uuid === operationUuid);
      myArray[i] = data;
      setSaturdayData(myArray);
    }

    if (select_day === "sunday") {
      myArray = sundayData;
      const i = myArray.findIndex((x) => x.operation_uuid === operationUuid);
      myArray[i] = data;
      setSundayData(myArray);
    }

    myArray1 = smsCampOpData;
    const j = myArray1.findIndex((y) => y.operation_uuid === operationUuid);
    myArray1[j] = data;

    setSmsCampOpData(myArray1);
  };

  useEffect(() => {
    dispatch(getSmsCampaignDetail(sms_camp_uuid, createdby, token))
      .then((response) => {
        const newCampaignData1 = { ...smsCampData };
        newCampaignData1["camp_data"] = response.data.campaign_data;
        newCampaignData1["contact_group_uuid"] =
          response.data.campaign_contact_group;
        newCampaignData1["operation_time"] =
          response.data.campaign_operation_time;
        setSmsCampData(newCampaignData1);
        setSmsCampGroupData(response.data.campaign_contact_group);
        setSmsCampOpData(response.data.campaign_operation_time);
        setRows(response.data.campaign_contact_group);
        setMessageVal(response.data.campaign_data.message_body);
        setSelectedOption(response.data.campaign_data.camp_sms_type);
        setSelectedMsgOption(response.data.campaign_data.camp_message_type);
        let op_data = response.data.campaign_operation_time;

        let monData = [];
        let tuesData = [];
        let wenData = [];
        let thursData = [];
        let friData = [];
        let satData = [];
        let sunData = [];
        op_data.forEach((emp) => {
          if (emp.day === "monday") {
            monData.push(emp);
          } else if (emp.day === "tuesday") {
            tuesData.push(emp);
          } else if (emp.day === "wednesday") {
            wenData.push(emp);
          } else if (emp.day === "thursday") {
            thursData.push(emp);
          } else if (emp.day === "friday") {
            friData.push(emp);
          } else if (emp.day === "saturday") {
            satData.push(emp);
          } else if (emp.day === "sunday") {
            sunData.push(emp);
          }
        });

        setMondayData(monData);
        setTuesdayData(tuesData);
        setWednesdayData(wenData);
        setThursdayData(thursData);
        setFridayData(friData);
        setSaturdayData(satData);
        setSundayData(sunData);
        // const newGroupData1 = { ...recuringCheck };
        dispatch(
          getSmsTemplateList(
            response.data.campaign_data.camp_message_type,
            main_uuid,
            createdby,
            token
          )
        )
          .then((response) => {
            setSmsTemplateData(response.data);
            return;
          })
          .catch(() => {
            setLoading(false);
          });

        if (response.data.campaign_data.is_recurring === "yes") {
        
          setRecuringCheck("checked");
        } else {
          setRecuringCheck("");
        }
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    dispatch(getContactGroupList(main_uuid, createdby, token))
      .then((response) => {
        setContactGroupList(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    dispatch(getUserAssignNumberList(createdby, main_uuid, createdby, token))
      .then((response) => {
        setAssignNumberData(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    let error_data = { ...errors };
    let isError = "false";
    // console.log(smsCampData, "==smsCampData===");
    setLoading(true);
    if (smsCampData.camp_data.name.length === 0) {
      error_data["name"] = "Campaign Name is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (smsCampData.camp_data.message_body.length === 0) {
      error_data["message_body"] = "Message is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (smsCampData.contact_group_uuid.length === 0) {
      error_data["contact_group_uuid"] = "Please select atleast one lead list.";
      setErrors(error_data);
      isError = "true";
    }

    if (smsCampData.camp_data.start_date.length === 0) {
      error_data["start_date"] = "Start Date Time is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (smsCampData.camp_data.end_date.length === 0) {
      error_data["end_date"] = "End Date Time is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (
      smsCampData.camp_data.is_recurring.length !== 0 &&
      smsCampData.camp_data.is_recurring === "yes" &&
      smsCampData.camp_data.recurring_type.length === 0
    ) {
      error_data["recurring_type"] = "Please select recurring type.";
      setErrors(error_data);
      isError = "true";
    }

    if (
      smsCampData.camp_data.is_recurring.length !== 0 &&
      smsCampData.camp_data.is_recurring === "yes" &&
      smsCampData.camp_data.recurring_at.length === 0
    ) {
      error_data["recurring_at"] = "Please select recurring at.";
      setErrors(error_data);
      isError = "true";
    }

    if (smsCampData.operation_time.length === 0) {
      error_data["operation_time"] = "Please atleast add one operation time.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {

      let file_name = "";
      if (selectedMsgOption === "sms") {
        file_name = "";
      } else {
        if (file === undefined) {
          file_name = "";
        } else {
          file_name = file.name;
        }
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("sms_camp_uuid", sms_camp_uuid);
      formData.append("camp_file_name", file_name);
      formData.append("name", smsCampData.camp_data.name);
      formData.append("message_body", messageVal);
      formData.append("is_recurring", smsCampData.camp_data.is_recurring);
      formData.append("start_date", smsCampData.camp_data.start_date);
      formData.append("end_date", smsCampData.camp_data.end_date);
      formData.append("recurring_type", smsCampData.camp_data.recurring_type);
      formData.append("recurring_at", smsCampData.camp_data.recurring_at);
      formData.append("timezone", smsCampData.camp_data.timezone);
      formData.append("from_number", smsCampData.camp_data.from_number);
      formData.append("camp_message_type", selectedMsgOption);
      formData.append("camp_sms_type", selectedOption);
      formData.append("operation_time", JSON.stringify(smsCampOpData));
      formData.append("contact_group_uuid", JSON.stringify(smsCampGroupData));
      formData.append("sms_template_id", smsCampData.camp_data.sms_template_id);
      formData.append("createdby", createdby);
      formData.append("main_uuid", main_uuid);
      formData.append("user_uuid", createdby);
      
      dispatch(updateSmsCampaign(formData, token))
        .then((res) => {
          if (res.status === 500) {
            notifyError("Something went wrong.");
          } else if (res.status !== 200) {
            error_data["name"] = res.message;
            setErrors(error_data);
            isError = "true";
          } else {
            notifyInfo(res.message);
            navigate("/sms-campaign", { replace: true });
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });

    }
  };

  function handleSelect(data) {
    const textboxValue = textboxRef.current.value;
    let msg_val = textboxValue + " " + data.target.value;
    setMessageVal(msg_val);
    const newGroupData1 = { ...smsCampData };
    newGroupData1["message"] = msg_val;
    setSmsCampData(newGroupData1);
  }

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  function handleSelectCheck(data) {
    let rec_chk_val = data.target.value;
    let check_val = "";
    if (rec_chk_val !== "" && rec_chk_val === "checked") {
      setRecuringCheck("");
      check_val = "no";
    } else {
      setRecuringCheck("checked");
      check_val = "yes";
    }

    const newCampaignData = { ...smsCampData };
    newCampaignData["camp_data"]["is_recurring"] = check_val;
    setSmsCampData(newCampaignData);
  }

  function handleSelectLeadList(data) {
    const newCampaignData1 = { ...smsCampData };
    newCampaignData1["contact_group_uuid"] = data;
    setSmsCampData(newCampaignData1);
    setSmsCampGroupData(data);
    setRows(data);
  }

  function tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }

  const handleDelete = (index, del_sel_day, e) => {
    setSmsCampOpData([
      ...smsCampOpData.filter((item) => item.operation_uuid !== index),
    ]);

    if (del_sel_day === "monday") {
      return setMondayData([
        ...mondayData.filter((item) => item.operation_uuid !== index),
      ]);
    } else if (del_sel_day === "tuesday") {
      return setTuesdayData([
        ...tuesdayData.filter((item) => item.operation_uuid !== index),
      ]);
    } else if (del_sel_day === "wednesday") {
      return setWednesdayData([
        ...wednesdayData.filter((item) => item.operation_uuid !== index),
      ]);
    } else if (del_sel_day === "thursday") {
      return setThursdayData([
        ...thursdayData.filter((item) => item.operation_uuid !== index),
      ]);
    } else if (del_sel_day === "friday") {
      return setFridayData([
        ...fridayData.filter((item) => item.operation_uuid !== index),
      ]);
    } else if (del_sel_day === "saturday") {
      return setSaturdayData([
        ...saturdayData.filter((item) => item.operation_uuid !== index),
      ]);
    } else if (del_sel_day === "sunday") {
      return setSundayData([
        ...sundayData.filter((item) => item.operation_uuid !== index),
      ]);
    }
  };

  const handleUpdate = (index, operation_uuid, e) => {
    setOperationUuid(operation_uuid);
    setUpdateData(index);
    setUpdateTimeSlotModal(index);
  };

  function handleSelectType(data) {
    setSelectedMsgOption(data.target.value);
    dispatch(getSmsTemplateList(data.target.value, main_uuid, createdby, token))
      .then((response) => {
        setSmsTemplateData(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });

    const newGroupData1 = { ...smsCampData };
    newGroupData1["camp_message_type"] = data.target.value;
    setSmsCampData(newGroupData1);
  }

  const breadCurmList = [
    { label: "SMS", link: "/", is_active: "NO" },
    { label: "SMS Campaign", link: "/sms-campaign", is_active: "NO" },
    {
      label: "Update SMS Campaign :" + smsCampData.camp_data.name,
      link: "/",
      is_active: "YES",
    },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                Update SMS Campaign - {smsCampData.camp_data.name}
              </h4>
              <Button
                type="button"
                className="btn btn-default btn-sm"
                onClick={() => navigate("/sms-campaign", { replace: true })}
              >
                <i className="fa fa-reply" aria-hidden="true"></i> Back
              </Button>
            </div>
            <div className="card-body">
              <form action="" onSubmit={(e) => submitHandler(e)}>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group input-primary">
                      <label className="mb-1">
                        <strong>Campaign Name</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control bg-white"
                        placeholder="Campaign Name"
                        name="name"
                        value={smsCampData.camp_data.name}
                        onChange={handleBlur}
                      />
                      <span className="text-danger">{errors.name}</span>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="form-group input-primary">
                      <label className="mb-1">
                        <strong>From Number</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control bg-white"
                        name="from_number"
                        onChange={handleBlur}
                        value={smsCampData.camp_data.from_number}
                      >
                        <option value="">Select From Number</option>
                        {assignNumberData.map((number) => (
                          <option key={number.uuid} value={number.did_number}>
                            {number.did_number}
                          </option>
                        ))}
                      </select>
                      <span className="text-danger">{errors.from_number}</span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-3">
                    <div className="form-group input-primary">
                      <label className="mb-1">
                        <strong>Message Type</strong>
                      </label>
                      <select
                        className="form-control bg-white"
                        name="camp_message_type"
                        value={selectedMsgOption}
                        onChange={handleSelectType}
                      >
                        <option value="">Select Message Type</option>
                        <option value="sms">SMS</option>
                        <option value="mms">MMS</option>
                      </select>
                      <span className="text-danger">
                        {errors.camp_message_type}
                      </span>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Select SMS Type</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <div className="form-group mb-0">
                        <label className="radio-inline mr-3">
                          <input
                            type="radio"
                            name="sms_template"
                            value="sms_template"
                            checked={selectedOption === "sms_template"}
                            onChange={handleOptionChange}
                          />{" "}
                          SMS Template
                        </label>
                        <label className="radio-inline mr-3">
                          <input
                            type="radio"
                            name="text"
                            value="text"
                            checked={selectedOption === "text"}
                            onChange={handleOptionChange}
                          />{" "}
                          Text
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-6"
                    style={{
                      display:
                        selectedOption === "sms_template" ? "block" : "none",
                    }}
                  >
                    <div className="form-group mt-2 input-primary">
                      <label className="mb-1">
                        <strong> SMS Template</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control bg-white"
                        name="sms_template_id"
                        value={smsCampData.camp_data.sms_template_id}
                        onChange={handleSelect}
                      >
                        <option value="">Select SMS Template</option>
                        {smsTemplateData.map((user) => (
                          <option
                            key={user.sms_temp_uuid}
                            value={user.sms_temp_uuid}
                          >
                            {user.name}
                          </option>
                        ))}
                      </select>
                      <span className="text-danger">
                        {errors.sms_template_id}
                      </span>
                    </div>
                  </div>

                  <div
                    className="col-6"
                    style={{
                      display:
                        selectedMsgOption === "mms" && selectedOption === "text"
                          ? "block"
                          : "none",
                    }}
                  >
                    <div className="form-group mt-2 input-primary">
                      <label
                        className="mb-1"
                        title="File size shoud be less then 2 MB."
                      >
                        <strong>Replace File</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="file"
                        className="form-control bg-white"
                        name="file"
                        onChange={handleBlur}
                      />
                      <span>
                        File Name:- {smsCampData.camp_data.camp_file_name}
                      </span>
                      <span className="text-danger">{errors.file}</span>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="form-group input-primary">
                      <label className="mb-1">
                        <strong>Message</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control bg-white"
                        placeholder="Message"
                        name="message_body"
                        ref={textboxRef}
                        value={messageVal}
                        onChange={handleBlur}
                        // onChange={e => setMessageVal(e.target.value)}
                      ></textarea>
                      <span className="text-danger">{errors.message_body}</span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <div className="form-group mt-2 input-primary">
                      <label className="mb-1">
                        <strong> Add Lead List</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <Select
                        className="form-control bg-white"
                        options={contactGroupList}
                        name="contact_group_uuid"
                        placeholder="Select List"
                        value={smsCampGroupData}
                        onChange={handleSelectLeadList}
                        isSearchable={true}
                        isMulti
                      />

                      <span className="text-danger">
                        {errors.contact_group_uuid}
                      </span>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="card-body">
                      <label className="mb-1">
                        <strong> Lead List</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <Table responsive className="w-100">
                        <thead>
                          <tr role="row">
                            <td>Name</td>
                            <td>No. of contacts</td>
                          </tr>
                        </thead>
                        <tbody>
                          {rows.map((row, index) => (
                            <tr key={index}>
                              <td>{row.label}</td>
                              <td>{row.count}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-6">
                    <div className="form-group col-xl-12 p-0 input-primary">
                      <label className="text-black font-w500 mb-3">
                        Start Date
                      </label>
                      <span className="text-danger">*</span>
                      <input
                        type="date"
                        name="start_date"
                        value={smsCampData.camp_data.start_date}
                        onChange={handleBlur}
                        className="form-control bg-white"
                      />
                      <span className="text-danger">{errors.start_date}</span>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="form-group col-xl-12 p-0 input-primary">
                      <label className="text-black font-w500 mb-3">
                        End Date
                      </label>
                      <span className="text-danger">*</span>
                      <input
                        type="date"
                        name="end_date"
                        value={smsCampData.camp_data.end_date}
                        onChange={handleBlur}
                        className="form-control bg-white"
                      />
                      <span className="text-danger">{errors.end_date}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-3">
                    <div className="form-group input-primary">
                      <label className="w-100 mb-1">
                        <strong>Recurring</strong>
                        <span className="text-danger">*</span>
                      </label>

                      <label className="switch">
                        <input
                          type="checkbox"
                          name="is_recurring"
                          onChange={handleSelectCheck}
                          value={recuringCheck}
                          checked={recuringCheck}
                        />
                        <span className="slider round"> </span>
                      </label>
                    </div>
                  </div>
                  <div
                    className="col-xl-3"
                    style={{
                      display:
                        smsCampData.camp_data.is_recurring === "yes"
                          ? "block"
                          : "none",
                    }}
                  >
                    <div className="form-group input-primary">
                      <label className="w-100 mb-1">
                        <strong></strong>
                      </label>
                      <input
                        type="text"
                        className="form-control bg-white"
                        readOnly
                        value="Every "
                      />
                    </div>
                  </div>
                  <div
                    className="col-xl-3"
                    style={{
                      display:
                        smsCampData.camp_data.is_recurring === "yes"
                          ? "block"
                          : "none",
                    }}
                  >
                    <div className="form-group input-primary">
                      <label className="mb-1">
                        <strong>Recurring At</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control bg-white"
                        name="recurring_at"
                        onChange={handleBlur}
                        value={smsCampData.camp_data.recurring_at}
                      >
                        <option value="">Select Recurring At</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                      <span className="text-danger">{errors.recurring_at}</span>
                    </div>
                  </div>
                  <div
                    className="col-xl-3"
                    style={{
                      display:
                        smsCampData.camp_data.is_recurring === "yes"
                          ? "block"
                          : "none",
                    }}
                  >
                    <div className="form-group input-primary">
                      <label className="mb-1">
                        <strong>Recurring Type</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control bg-white"
                        name="recurring_type"
                        onChange={handleBlur}
                        value={smsCampData.camp_data.recurring_type}
                      >
                        <option value="">Select Recurring Type</option>
                        <option value="day">Day</option>
                        <option value="week">Week</option>
                        <option value="month">Month</option>
                        <option value="year">Year</option>
                      </select>
                      <span className="text-danger">
                        {errors.recurring_type}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="card-body">
                    <label className="mb-1">
                      <strong> Campaign Operation Time</strong>
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="default-tab m-3 w-100">
                    <Tab.Container defaultActiveKey="monday">
                      <Nav as="ul" className="nav-tabs">
                        <Nav.Item as="li">
                          <Nav.Link
                            id="sunday"
                            eventKey="sunday"
                            onClick={handleBlur1}
                          >
                            <i className={`la la-clock mr-2`} />
                            Sun
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                          <Nav.Link
                            id="monday"
                            eventKey="monday"
                            onClick={handleBlur1}
                          >
                            <i className={`la la-clock mr-2`} />
                            Mon
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                          <Nav.Link
                            id="tuesday"
                            eventKey="tuesday"
                            onClick={handleBlur1}
                          >
                            <i className={`la la-clock mr-2`} />
                            Tue
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                          <Nav.Link
                            id="wednesday"
                            eventKey="wednesday"
                            onClick={handleBlur1}
                          >
                            <i className={`la la-clock mr-2`} />
                            Wed
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                          <Nav.Link
                            id="thursday"
                            eventKey="thursday"
                            onClick={handleBlur1}
                          >
                            <i className={`la la-clock mr-2`} />
                            Thurs
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                          <Nav.Link
                            id="friday"
                            eventKey="friday"
                            onClick={handleBlur1}
                          >
                            <i className={`la la-clock mr-2`} />
                            Fri
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                          <Nav.Link
                            id="saturday"
                            eventKey="saturday"
                            onClick={handleBlur1}
                          >
                            <i className={`la la-clock mr-2`} />
                            sat
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>

                      <Tab.Content className="pt-12">
                        <Tab.Pane eventKey="sunday">
                          <div className="row mt-4">
                            <div className="col-12">
                              <h4 className="card-title">Sunday</h4>

                              <Table responsive className="w-100">
                                <thead>
                                  {sundayData.map((name) => (
                                    <tr role="row" key={name.operation_uuid}>
                                      <td>
                                        {tConvert(name.start_time)} To{" "}
                                        {tConvert(name.end_time)}
                                      </td>
                                      <td>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-danger light pull-right mr-2"
                                          onClick={(e) =>
                                            handleDelete(
                                              name.operation_uuid,
                                              name.day,
                                              e
                                            )
                                          }
                                        >
                                          Delete
                                        </span>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-info light pull-right mr-2"
                                          onClick={(e) =>
                                            handleUpdate(
                                              name,
                                              name.operation_uuid,
                                              e
                                            )
                                          }
                                          // onClick={() =>
                                          //   setUpdateTimeSlotModal(true)
                                          // }
                                          // state={{
                                          //   slot_uuid:
                                          //     name.operation_uuid,
                                          //   start_time: name.start_time,
                                          //   end_time: name.end_time,
                                          // }}
                                        >
                                          Edit
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </thead>
                                <tbody>
                                  <div
                                    className="col-sm-6 pull-left"
                                    title="Add New Time"
                                  >
                                    <span
                                      role="button"
                                      style={{ cursor: "pointer" }}
                                      className="badge badge-primary light mr-2"
                                      onClick={() => setAddTimeSlotModal(true)}
                                    >
                                      <i
                                        className="fa fa-plus-circle mr-2"
                                        aria-hidden="true"
                                      />
                                      Add New Time
                                    </span>
                                  </div>
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="monday">
                          <div className="row mt-4">
                            <div className="col-12">
                              <h4 className="card-title">Monday</h4>

                              <Table responsive className="w-100">
                                <thead>
                                  {mondayData.map((name) => (
                                    <tr role="row" key={name.operation_uuid}>
                                      <td>
                                        {tConvert(name.start_time)} To{" "}
                                        {tConvert(name.end_time)}
                                      </td>
                                      <td>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-danger light pull-right mr-2"
                                          onClick={(e) =>
                                            handleDelete(
                                              name.operation_uuid,
                                              name.day,
                                              e
                                            )
                                          }
                                        >
                                          Delete
                                        </span>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-info light pull-right mr-2"
                                          onClick={(e) =>
                                            handleUpdate(
                                              name,
                                              name.operation_uuid,
                                              e
                                            )
                                          }
                                          // onClick={() =>
                                          //   setUpdateTimeSlotModal(true)
                                          // }
                                          // state={{
                                          //   slot_uuid:
                                          //     name.operation_uuid,
                                          //   start_time: name.start_time,
                                          //   end_time: name.end_time,
                                          // }}
                                        >
                                          Edit
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </thead>
                                <tbody>
                                  <div
                                    className="col-sm-6 pull-left"
                                    title="Add New Time"
                                  >
                                    <span
                                      role="button"
                                      style={{ cursor: "pointer" }}
                                      className="badge badge-primary light mr-2"
                                      onClick={() => setAddTimeSlotModal(true)}
                                    >
                                      <i
                                        className="fa fa-plus-circle mr-2"
                                        aria-hidden="true"
                                      />
                                      Add New Time
                                    </span>
                                  </div>
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="tuesday">
                          <div className="row mt-4">
                            <div className="col-12">
                              <h4 className="card-title">Tuesday</h4>

                              <Table responsive className="w-100">
                                <thead>
                                  {tuesdayData.map((name) => (
                                    <tr role="row" key={name.operation_uuid}>
                                      <td>
                                        {tConvert(name.start_time)} To{" "}
                                        {tConvert(name.end_time)}
                                      </td>
                                      <td>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-danger light pull-right mr-2"
                                          onClick={(e) =>
                                            handleDelete(
                                              name.operation_uuid,
                                              name.day,
                                              e
                                            )
                                          }
                                        >
                                          Delete
                                        </span>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-info light pull-right mr-2"
                                          onClick={(e) =>
                                            handleUpdate(
                                              name,
                                              name.operation_uuid,
                                              e
                                            )
                                          }
                                        >
                                          Edit
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </thead>
                                <tbody>
                                  <div
                                    className="col-sm-6 pull-left"
                                    title="Add New Time"
                                  >
                                    <span
                                      role="button"
                                      style={{ cursor: "pointer" }}
                                      className="badge badge-primary light mr-2"
                                      onClick={() => setAddTimeSlotModal(true)}
                                    >
                                      <i
                                        className="fa fa-plus-circle mr-2"
                                        aria-hidden="true"
                                      />
                                      Add New Time
                                    </span>
                                  </div>
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="wednesday">
                          <div className="row mt-4">
                            <div className="col-12">
                              <h4 className="card-title">Wednesday</h4>

                              <Table responsive className="w-100">
                                <thead>
                                  {wednesdayData.map((name) => (
                                    <tr role="row" key={name.operation_uuid}>
                                      <td>
                                        {tConvert(name.start_time)} To{" "}
                                        {tConvert(name.end_time)}
                                      </td>
                                      <td>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-danger light pull-right mr-2"
                                          onClick={(e) =>
                                            handleDelete(
                                              name.operation_uuid,
                                              name.day,
                                              e
                                            )
                                          }
                                        >
                                          Delete
                                        </span>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-info light pull-right mr-2"
                                          onClick={(e) =>
                                            handleUpdate(
                                              name,
                                              name.operation_uuid,
                                              e
                                            )
                                          }
                                        >
                                          Edit
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </thead>
                                <tbody>
                                  <div
                                    className="col-sm-6 pull-left"
                                    title="Add New Time"
                                  >
                                    <span
                                      role="button"
                                      style={{ cursor: "pointer" }}
                                      className="badge badge-primary light mr-2"
                                      onClick={() => setAddTimeSlotModal(true)}
                                    >
                                      <i
                                        className="fa fa-plus-circle mr-2"
                                        aria-hidden="true"
                                      />
                                      Add New Time
                                    </span>
                                  </div>
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="thursday">
                          <div className="row mt-4">
                            <div className="col-12">
                              <h4 className="card-title">Thursday</h4>

                              <Table responsive className="w-100">
                                <thead>
                                  {thursdayData.map((name) => (
                                    <tr role="row" key={name.operation_uuid}>
                                      <td>
                                        {tConvert(name.start_time)} To{" "}
                                        {tConvert(name.end_time)}
                                      </td>
                                      <td>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-danger light pull-right mr-2"
                                          onClick={(e) =>
                                            handleDelete(
                                              name.operation_uuid,
                                              name.day,
                                              e
                                            )
                                          }
                                        >
                                          Delete
                                        </span>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-info light pull-right mr-2"
                                          onClick={(e) =>
                                            handleUpdate(
                                              name,
                                              name.operation_uuid,
                                              e
                                            )
                                          }
                                        >
                                          Edit
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </thead>
                                <tbody>
                                  <div
                                    className="col-sm-6 pull-left"
                                    title="Add New Time"
                                  >
                                    <span
                                      role="button"
                                      style={{ cursor: "pointer" }}
                                      className="badge badge-primary light mr-2"
                                      onClick={() => setAddTimeSlotModal(true)}
                                    >
                                      <i
                                        className="fa fa-plus-circle mr-2"
                                        aria-hidden="true"
                                      />
                                      Add New Time
                                    </span>
                                  </div>
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="friday">
                          <div className="row mt-4">
                            <div className="col-12">
                              <h4 className="card-title">Friday</h4>

                              <Table responsive className="w-100">
                                <thead>
                                  {fridayData.map((name) => (
                                    <tr role="row" key={name.operation_uuid}>
                                      <td>
                                        {tConvert(name.start_time)} To{" "}
                                        {tConvert(name.end_time)}
                                      </td>
                                      <td>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-danger light pull-right mr-2"
                                          onClick={(e) =>
                                            handleDelete(
                                              name.operation_uuid,
                                              name.day,
                                              e
                                            )
                                          }
                                        >
                                          Delete
                                        </span>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-info light pull-right mr-2"
                                          onClick={(e) =>
                                            handleUpdate(
                                              name,
                                              name.operation_uuid,
                                              e
                                            )
                                          }
                                        >
                                          Edit
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </thead>
                                <tbody>
                                  <div
                                    className="col-sm-6 pull-left"
                                    title="Add New Time"
                                  >
                                    <span
                                      role="button"
                                      style={{ cursor: "pointer" }}
                                      className="badge badge-primary light mr-2"
                                      onClick={() => setAddTimeSlotModal(true)}
                                    >
                                      <i
                                        className="fa fa-plus-circle mr-2"
                                        aria-hidden="true"
                                      />
                                      Add New Time
                                    </span>
                                  </div>
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="saturday">
                          <div className="row mt-4">
                            <div className="col-12">
                              <h4 className="card-title">Saturday</h4>

                              <Table responsive className="w-100">
                                <thead>
                                  {saturdayData.map((name) => (
                                    <tr role="row" key={name.operation_uuid}>
                                      <td>
                                        {tConvert(name.start_time)} To{" "}
                                        {tConvert(name.end_time)}
                                      </td>
                                      <td>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-danger light pull-right mr-2"
                                          onClick={(e) =>
                                            handleDelete(
                                              name.operation_uuid,
                                              name.day,
                                              e
                                            )
                                          }
                                        >
                                          Delete
                                        </span>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-info light pull-right mr-2"
                                          onClick={(e) =>
                                            handleUpdate(
                                              name,
                                              name.operation_uuid,
                                              e
                                            )
                                          }
                                        >
                                          Edit
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </thead>
                                <tbody>
                                  <div
                                    className="col-sm-6 pull-left"
                                    title="Add New Time"
                                  >
                                    <span
                                      role="button"
                                      style={{ cursor: "pointer" }}
                                      className="badge badge-primary light mr-2"
                                      onClick={() => setAddTimeSlotModal(true)}
                                    >
                                      <i
                                        className="fa fa-plus-circle mr-2"
                                        aria-hidden="true"
                                      />
                                      Add New Time
                                    </span>
                                  </div>
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </Tab.Pane>

                        <span className="text-danger">
                          {errors.operation_time}
                        </span>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 text-right">
                    <Button type="submit" variant="primary" className="btn-sm">
                      Update
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal className="fade" show={addTimeSlotModal}>
        <TimeSlotForm
          closeModel={closeModel}
          day={daySel}
          onAddDestination={addDestinationHandler}
        />
      </Modal>
      <Modal className="fade" show={updateTimeSlotModal}>
        <UpdateTimeSlotForm
          closeModel={closeModel}
          day={daySel}
          updateData={updateData}
          onUpdateDestination={UpdateDestinationHandler}
        />
      </Modal>
    </Fragment>
  );
}

export default UpdateSmsTemplateForm;
