import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import "./style.css";
import Tooltip from "rc-tooltip";
import EasyEdit, { Types } from "react-easy-edit";
import { getDestinationTypeList } from "../../actions/comman";
import Loader from "../../layouts/Loader";

function Forwarding(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  let extensionData = props.extensionData;

  const errors= {
    forward_route_type: "",
    forward_destination: "",
    external_phone_number: "",
  };

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const save = (value, attribute) => {
    let data = { ...extensionData };
    data[attribute] = value;
    props.save(data);
  };

  const getDestination = (
    forward_route_type,
    ivr_menu_name,
    ring_group_extension,
    extension,
    time_condition_name,
    voicemail_name,
    did_number,
    external_phone_number
  ) => {
    if (forward_route_type === "ivr") {
      return "Auto Attendent - " + ivr_menu_name;
    } else if (forward_route_type === "extention") {
      return "Extention - " + extension;
    } else if (forward_route_type === "ring-group") {
      return "Ring Group - " + ring_group_extension;
    } else if (forward_route_type === "did") {
      return "Inbound Number - " + did_number;
    } else if (forward_route_type === "voicemail") {
      return "Voicemail - " + voicemail_name;
    } else if (forward_route_type === "time_condition") {
      return "Time Based Routing - " + time_condition_name;
    } else if (forward_route_type === "external_phone_number") {
      return "External Phone Number - " + external_phone_number;
    }
  };

  const CustomDisplay = (props) => {
    const [options, setOptions] = useState([]);
    const [showExNum, setShowExNum] = useState(
      extensionData.forward_route_type
    );

    const [extData, setExtData] = useState({
      forward_route_type: extensionData.forward_route_type,
      forward_destination: extensionData.forward_destination,
      forward_destination_value: extensionData.forward_destination_value,
    });

    let new_data = { ...extensionData };

    function inputonChange(e) {
      let new_data1 = { ...extData };

      props.setParentValue(new_data1);
      if (e.target.name === "forward_route_type") {
        new_data1[e.target.name] = e.target.value;
        new_data[e.target.name] = e.target.value;
        if (e.target.value === "external_phone_number") {
          setShowExNum("external_phone_number");
        } else {
          setShowExNum(e.target.name);
          getoptions(e.target.value);
        }

        new_data1["external_phone_number"] = "";
        new_data1["forward_destination"] = "";
        new_data["external_phone_number"] = "";
        new_data["forward_destination"] = "";
      }

      if (e.target.name === "forward_destination") {
        new_data[e.target.name] = e.target.value;
        new_data1[e.target.name] = e.target.value;
        
        
      }

      setExtData(new_data1);
      extensionData = { ...new_data };
    }

    function handleBlur(e) {
      let new_data1 = { ...extData };
      new_data1[e.target.name] = e.target.value;

      props.setParentValue(new_data1);
      if (e.target.name === "external_phone_number") {
        new_data[e.target.name] = e.target.value;
      }
      extensionData = { ...new_data };
    }

    const getoptions = (type) => {
      const main_uuid = user_data.main_uuid;
      setLoading(true);
      dispatch(getDestinationTypeList(createdby, token, main_uuid, type))
        .then((response) => {
          setOptions(response.data);
          setLoading(false);
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    };

    return (
      <div>
        {loading && <Loader />}
        <div className="row">
          <div className="col-6">
            <div className="form-group input-primary">
              <label>Route To</label>
              <select
                className="form-control bg-white"
                name="forward_route_type"
                id="forward_route_type"
                onChange={(e) => inputonChange(e)}
                defaultValue={extensionData.forward_route_type}
              >
                <option value="">Select Destination Type</option>
                <option value="ivr">Auto Attendent</option>
                <option value="extention">Extensions</option>
                <option value="ring-group">Ring Groups</option>
                <option value="did">DID</option>
                <option value="voicemail">Voicemail</option>
                <option value="time_condition">Time Based Routing</option>
                <option value="external_phone_number">
                  External Phone Number
                </option>
              </select>
              <span className="text-danger">{errors.forward_route_type}</span>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group input-primary">
              <label>Destination</label>
              {showExNum === "external_phone_number" ? (
                <>
                  <input
                    type="text"
                    className="form-control bg-white"
                    placeholder="Enter External Phone Number"
                    name="external_phone_number"
                    onChange={handleBlur}
                    value={extensionData.external_phone_number}
                  />
                  <span className="text-danger">
                    {errors.external_phone_number}
                  </span>
                </>
              ) : (
                <>
                  <select
                    className="form-control bg-white"
                    name="forward_destination"
                    id="forward_destination"
                    onChange={(e) => inputonChange(e)}
                    defaultValue={extensionData.forward_destination}
                  >
                    <option value="">Select Destination</option>

                    {options &&
                      options.map((number) =>
                        number.value !== extensionData.extension_uuid ? (
                          <option key={number.value} value={number.value}>
                            {number.text}
                          </option>
                        ) : (
                          "None"
                        )
                      )}
                  </select>
                  <span className="text-danger">
                    {errors.forward_destination}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const CustomDisplay1 = (props) => {
    return (
      <p>
        {getDestination(
          extensionData.forward_route_type,
          extensionData.ivr_menu_name,
          extensionData.ring_destination,
          extensionData.extension,
          extensionData.time_condition_name,
          extensionData.voicemail_name,
          extensionData.did_number,
          extensionData.external_phone_number
        )}
      </p>
    );
  };

  const handleChange = (e, default_val) => {
    let value = "";
    if (default_val === "YES") {
      value = "NO";
    } else {
      value = "YES";
    }

    let data = { ...extensionData };
    data[e.target.name] = value;
    extensionData = { ...data };

    props.save(extensionData);
  };

  let number_array = new Array(60).fill(1).map((_, i) => {
    return { label: i + 1, value: i + 1 };
  });

  return (
    <Fragment>
      <div className="row ml-2 mr-2">
        <div className="col-lg-6 col-md-6 col-6">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                Initial Extension Ringtime
                <Tooltip
                  placement="top"
                  overlay={<span>Initial Extension Ringtime</span>}
                >
                  <span className="ti-info-alt ml-1"></span>
                </Tooltip>
              </h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="ml-3 form-group input-primary">
                    <label className="w-100">
                      Ringtime (sec)
                      <Tooltip
                        placement="top"
                        overlay={<span>Ringtime (sec)</span>}
                      >
                        <span className="ti-info-alt ml-1"></span>
                      </Tooltip>
                    </label>
                    <EasyEdit
                      type={Types.SELECT}
                      onSave={(value) => {
                        save(value, "initial_extension_ringtime");
                      }}
                      //onCancel={cancel}
                      options={number_array}
                      saveButtonLabel={
                        <i className="fa fa-check" aria-hidden="true"></i>
                      }
                      saveButtonStyle="label-primary easy-edit-button rounded-lg"
                      cancelButtonLabel={
                        <i className="fa fa-times" aria-hidden="true"></i>
                      }
                      cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                      attributes={{
                        name: "initial_extension_ringtime",
                        className: "form-control bg-white p-0 ",
                        style: { height: "35px" },
                      }}
                      placeholder="Ringtime"
                      value={
                        extensionData.initial_extension_ringtime
                          ? extensionData.initial_extension_ringtime
                          : "None"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-6">
          <div className="card">
            <div className="card-header customToggle">
              <h4 className="card-title">
                Forward Destination
                <Tooltip
                  placement="top"
                  overlay={<span>Forward Destination</span>}
                >
                  <span className="ti-info-alt ml-1"></span>
                </Tooltip>
              </h4>
              <span className="custom-control custom-switch float-right">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="forward_enable"
                  name="forward_enable"
                  checked={
                    extensionData.forward_enable === "YES" ? "checked" : ""
                  }
                  onChange={(e) =>
                    handleChange(e, extensionData.forward_enable)
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor="forward_enable"
                ></label>
              </span>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12">
                  <div className="ml-3 form-group input-primary">
                    <label className="w-100">
                      Forward Route and Destination
                      <Tooltip placement="top" overlay={<span>Extension</span>}>
                        <span className="ti-info-alt ml-1"></span>
                      </Tooltip>
                    </label>
                    <EasyEdit
                      type={Types.TEXT}
                      onSave={(value) => {
                        save(value, "route_and_destination");
                      }}
                      editComponent={<CustomDisplay />}
                      displayComponent={<CustomDisplay1 />}
                      saveButtonLabel={
                        <i className="fa fa-check" aria-hidden="true"></i>
                      }
                      saveButtonStyle="label-primary easy-edit-button rounded-lg"
                      cancelButtonLabel={
                        <i className="fa fa-times" aria-hidden="true"></i>
                      }
                      cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                      attributes={{
                        name: "route_and_destination",
                        className: "form-control bg-white h-75",
                      }}
                      viewAttributes={{ className: "fs-20" }}
                      onValidate={(value) => {
                        if (value) {
                          if (
                            value.forward_route_type &&
                            value.forward_destination
                          ) {
                            return true;
                          } else {
                            return false;
                          }
                        } else {
                          return false;
                        }
                       
                      }}
                      placeholder="Please select route and destination"
                      value={extensionData.forward_route_type}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Forwarding;
