import { SET_MESSAGE, API_FAIL } from "./types";

import ExtensionService from "../services/extension.service";

export const getExtensions =
  (
    user_type,
    group_per,
    group_uuid,
    role,
    permission,
    role_uuid,
    search,
    off_set,
    limits,
    orderby,
    main_uuid,
    createdby,
    token
  ) =>
  (dispatch) => {
    return ExtensionService.getExtensions(
      user_type,
      group_per,
      group_uuid,
      role,
      permission,
      role_uuid,
      search,
      off_set,
      limits,
      orderby,
      main_uuid,
      createdby,
      token
    ).then(
      (data) => {
        if (
          data.status === 406 ||
          data.status === 500 ||
          data.status === 200
        ) {
          return data;
        } else {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const createExtension = (userData, token) => (dispatch) => {
  return ExtensionService.createExtension(userData, token).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const updateExtension = (userData, token) => (dispatch) => {
  return ExtensionService.updateExtension(userData, token).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getExtensionDetail = (createdby, token, extuuid) => (dispatch) => {
  return ExtensionService.getExtensionDetail(createdby, token, extuuid).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getCallForwardDetail =
  (createdby, token, extuuid) => (dispatch) => {
    return ExtensionService.getCallForwardDetail(
      createdby,
      token,
      extuuid
    ).then(
      (data) => {
        if (
          data.status === 406 ||
          data.status === 500 ||
          data.status === 200
        ) {
          return data;
        } else {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const getExtensionList = (createdby, token, mainuuid) => (dispatch) => {
  return ExtensionService.getExtensionList(createdby, token, mainuuid).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getUserExtension = (useruuid, createdby, token) => (dispatch) => {
  return ExtensionService.getUserExtension(useruuid, createdby, token).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const deleteExtension =
  (extension_uuid, createdby, token) => (dispatch) => {
    return ExtensionService.deleteExtension(
      extension_uuid,
      createdby,
      token
    ).then(
      (data) => {
        if (
          data.status === 406 ||
          data.status === 500 ||
          data.status === 200
        ) {
          return data;
        } else {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const getNextExtensionID =
  (createdby, main_admin_uuid, token) => (dispatch) => {
    return ExtensionService.getNextExtensionID(
      createdby,
      main_admin_uuid,
      token
    ).then(
      (data) => {
        if (
          data.status === 406 ||
          data.status === 500 ||
          data.status === 200
        ) {
          return data;
        } else {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const copyExtensions =
  (extension_uuid, createdby, main_admin_uuid, token) => (dispatch) => {
    return ExtensionService.copyExtensions(
      extension_uuid,
      createdby,
      main_admin_uuid,
      token
    ).then(
      (data) => {
        if (
          data.status === 406 ||
          data.status === 500 ||
          data.status === 200
        ) {
          return data;
        } else {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };
