import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const getNotesList = (contact_uuid, main_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "get-notes-list",
      {
        contact_uuid,
        main_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const createNote = (data, token) => {
  return axios
    .post(API_URL + "create-note", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const updateNote = (note_uuid, note, main_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "update-note",
      {
        note_uuid,
        note,
        main_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const deleteNote = (note_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "delete-note",
      {
        note_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

export default {
  getNotesList,
  createNote,
  deleteNote,
  updateNote,
};
