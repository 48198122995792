import { SET_MESSAGE, API_FAIL, API_SUCCESS } from "./types";

import commanService from "../services/comman.service";

export const getDestinationTypeList =
  (createdby, token, main_uuid, type) => (dispatch) => {
    return commanService
      .getDestinationTypeList(createdby, token, main_uuid, type)
      .then(
        (data) => {
          if (data.status !== 200) {
            dispatch({
              type: API_FAIL,
            });

            dispatch({
              type: SET_MESSAGE,
              payload: data.message,
            });

            return Promise.reject();
          }

          dispatch({
            type: API_SUCCESS,
            payload: { user: data },
          });

          return data;
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });

          return Promise.reject();
        }
      );
  };

export const getDestinationList =
  (createdby, token, main_uuid, type) => (dispatch) => {
    return commanService
      .getDestinationList(createdby, token, main_uuid, type)
      .then(
        (data) => {
          if (data.status !== 200) {
            dispatch({
              type: API_FAIL,
            });

            dispatch({
              type: SET_MESSAGE,
              payload: data.message,
            });

            return Promise.reject();
          }

          dispatch({
            type: API_SUCCESS,
            payload: { user: data },
          });

          return data;
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });

          return Promise.reject();
        }
      );
  };

export const getSetCallarIdNumber =
  (createdby, token, main_uuid, did_number) => (dispatch) => {
    return commanService
      .getSetCallarIdNumber(createdby, token, main_uuid, did_number)
      .then(
        (data) => {
          if (data.status !== 200) {
            dispatch({
              type: API_FAIL,
            });

            dispatch({
              type: SET_MESSAGE,
              payload: data.message,
            });

            return Promise.reject();
          }

          dispatch({
            type: API_SUCCESS,
            payload: { user: data },
          });

          return data;
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });

          return Promise.reject();
        }
      );
  };

export const getDidListReassign =
  (createdby, token, main_uuid, did_number) => (dispatch) => {
    return commanService
      .getDidListReassign(createdby, token, main_uuid, did_number)
      .then(
        (data) => {
          if (data.status !== 200) {
            dispatch({
              type: API_FAIL,
            });

            dispatch({
              type: SET_MESSAGE,
              payload: data.message,
            });

            return Promise.reject();
          }

          dispatch({
            type: API_SUCCESS,
            payload: { user: data },
          });

          return data;
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });

          return Promise.reject();
        }
      );
  };

export const ReassignCallarId =
  (createdby, token, main_uuid, did_number, extensionsData, status) =>
  (dispatch) => {
    return commanService
      .ReassignCallarId(
        createdby,
        token,
        main_uuid,
        did_number,
        extensionsData,
        status
      )
      .then(
        (data) => {
          if (data.status !== 200) {
            dispatch({
              type: API_FAIL,
            });

            dispatch({
              type: SET_MESSAGE,
              payload: data.message,
            });

            return Promise.reject();
          }

          dispatch({
            type: API_SUCCESS,
            payload: { user: data },
          });

          return data;
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });

          return Promise.reject();
        }
      );
  };

export const getDestinationTypeList1 =
  (createdby, token, main_uuid, type) => (dispatch) => {
    return commanService
      .getDestinationTypeList1(createdby, token, main_uuid, type)
      .then(
        (data) => {
          if (data.status !== 200) {
            dispatch({
              type: API_FAIL,
            });

            dispatch({
              type: SET_MESSAGE,
              payload: data.message,
            });

            return Promise.reject();
          }

          dispatch({
            type: API_SUCCESS,
            payload: { user: data },
          });

          return data;
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });

          return Promise.reject();
        }
      );
  };

export const getDropDownUserList =
  (createdby, token, main_uuid, type) => (dispatch) => {
    return commanService
      .getDropDownUserList(createdby, token, main_uuid, type)
      .then(
        (data) => {
          if (data.status !== 200) {
            dispatch({
              type: API_FAIL,
            });

            dispatch({
              type: SET_MESSAGE,
              payload: data.message,
            });

            return Promise.reject();
          }

          dispatch({
            type: API_SUCCESS,
            payload: { user: data },
          });

          return data;
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });

          return Promise.reject();
        }
      );
  };

export const getNumberListDropdown =
  (
    action,
    assign_number,
    main_uuid,
    user_type,
    group_per,
    group_uuid,
    createdby,
    token
  ) =>
  (dispatch) => {
    return commanService
      .getNumberListDropdown(
        action,
        assign_number,
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        createdby,
        token
      )
      .then(
        (data) => {
          if (data.status !== 200) {
            dispatch({
              type: API_FAIL,
            });

            dispatch({
              type: SET_MESSAGE,
              payload: data.message,
            });

            return Promise.reject();
          }

          dispatch({
            type: API_SUCCESS,
            payload: { user: data },
          });

          return data;
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });

          return Promise.reject();
        }
      );
  };

export const getExtensionListDropdown =
  (
    action,
    assign_extension,
    main_uuid,
    user_type,
    group_per,
    group_uuid,
    createdby,
    token
  ) =>
  (dispatch) => {
    return commanService
      .getExtensionListDropdown(
        action,
        assign_extension,
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        createdby,
        token
      )
      .then(
        (data) => {
          if (data.status !== 200) {
            dispatch({
              type: API_FAIL,
            });

            dispatch({
              type: SET_MESSAGE,
              payload: data.message,
            });

            return Promise.reject();
          }

          dispatch({
            type: API_SUCCESS,
            payload: { user: data },
          });

          return data;
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });

          return Promise.reject();
        }
      );
  };

export const getUserListDropdown =
  (
    action,
    assign_user,
    main_uuid,
    user_type,
    group_per,
    group_uuid,
    createdby,
    token
  ) =>
  (dispatch) => {
    return commanService
      .getUserListDropdown(
        action,
        assign_user,
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        createdby,
        token
      )
      .then(
        (data) => {
          if (data.status !== 200) {
            dispatch({
              type: API_FAIL,
            });

            dispatch({
              type: SET_MESSAGE,
              payload: data.message,
            });

            return Promise.reject();
          }

          dispatch({
            type: API_SUCCESS,
            payload: { user: data },
          });

          return data;
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });

          return Promise.reject();
        }
      );
  };

export const getTimezone = () => (dispatch) => {
  return commanService.getTimezone().then(
    (data) => {
      if (data.status !== 200) {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }

      dispatch({
        type: API_SUCCESS,
        payload: { user: data },
      });

      return data;
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getLanguageList = (main_uuid, createdby, token) => (dispatch) => {
  return commanService.getLanguageList(main_uuid, createdby, token).then(
    (data) => {
      if (data.status !== 200) {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }

      dispatch({
        type: API_SUCCESS,
        payload: { user: data },
      });

      return data;
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getUserAssignNumberList =
  (user_uuid, main_uuid, createdby, token) => (dispatch) => {
    return commanService
      .getUserAssignNumberList(user_uuid, main_uuid, createdby, token)
      .then(
        (data) => {
          if (data.status !== 200) {
            dispatch({
              type: API_FAIL,
            });

            dispatch({
              type: SET_MESSAGE,
              payload: data.message,
            });

            return Promise.reject();
          }

          dispatch({
            type: API_SUCCESS,
            payload: { user: data },
          });

          return data;
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });

          return Promise.reject();
        }
      );
  };

export const getDispositionList =
  (user_uuid, main_uuid, createdby, token) => (dispatch) => {
    return commanService
      .getDispositionList(user_uuid, main_uuid, createdby, token)
      .then(
        (data) => {
          if (data.status !== 200) {
            dispatch({
              type: API_FAIL,
            });

            dispatch({
              type: SET_MESSAGE,
              payload: data.message,
            });

            return Promise.reject();
          }

          dispatch({
            type: API_SUCCESS,
            payload: { user: data },
          });

          return data;
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });

          return Promise.reject();
        }
      );
  };

export const getSmsNotification =
  (main_uuid, createdby, token) => (dispatch) => {
    return commanService.getSmsNotification(main_uuid, createdby, token).then(
      (data) => {
        if (data.status !== 200) {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }

        dispatch({
          type: API_SUCCESS,
          payload: { user: data },
        });

        return data;
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const readSmsNotification =
  (main_uuid, createdby, token) => (dispatch) => {
    return commanService.readSmsNotification(main_uuid, createdby, token).then(
      (data) => {
        if (data.status !== 200) {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }

        dispatch({
          type: API_SUCCESS,
          payload: { user: data },
        });

        return data;
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const getAreaCodeList = (createdby, token) => (dispatch) => {
  return commanService.getAreaCodeList(createdby, token).then(
    (data) => {
      if (data.status !== 200) {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }

      dispatch({
        type: API_SUCCESS,
        payload: { user: data },
      });

      return data;
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getCompanyAndE911Details =
  (user_uuid, main_uuid, createdby, token) => (dispatch) => {
    return commanService
      .getCompanyAndE911Details(user_uuid, main_uuid, createdby, token)
      .then(
        (data) => {
          if (data.status !== 200) {
            dispatch({
              type: API_FAIL,
            });

            dispatch({
              type: SET_MESSAGE,
              payload: data.message,
            });

            return Promise.reject();
          }

          dispatch({
            type: API_SUCCESS,
            payload: { user: data },
          });

          return data;
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });

          return Promise.reject();
        }
      );
  };

export const updateCompanyAndPersonalInfo = (userData, token) => (dispatch) => {
  return commanService.updateCompanyAndPersonalInfo(userData, token).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const updateProfilePic = (userData, token) => (dispatch) => {
  return commanService.updateProfilePic(userData, token).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const updateSkipStepAdmin =
  (user_uuid, main_uuid, createdby, token) => (dispatch) => {
    return commanService
      .updateSkipStepAdmin(user_uuid, main_uuid, createdby, token)
      .then(
        (data) => {
          if (data.status !== 200) {
            dispatch({
              type: API_FAIL,
            });

            dispatch({
              type: SET_MESSAGE,
              payload: data.message,
            });

            return Promise.reject();
          }

          dispatch({
            type: API_SUCCESS,
            payload: { user: data },
          });

          return data;
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });

          return Promise.reject();
        }
      );
  };

  export const checkModuleAssign = (module_name,module_uuid,check_type, createdby, token) => (dispatch) => {
    return commanService.checkModuleAssign(module_name,module_uuid,check_type, createdby, token).then(
      (data) => {
        if (data.status !== 200) {
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        }
  
        dispatch({
          type: API_SUCCESS,
          payload: { user: data },
        });
  
        return data;
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };