import React, { Fragment, useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import PageTitle from "../../layouts/PageTitle";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { getPlans, deletePlan } from "../../actions/plan";
import PermissionCheck from "../../common/PermissionCheck";
import Loader from "../../layouts/Loader";

function Plan() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;
  const module_name = "roles permissions";

  let listing_per = PermissionCheck(module_name, "listing", "", "", "");

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }

  const [planEvent, setPlanEvent] = useState("");

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  let search = "";
  let off_set = 0;
  let status = "ACTIVE";
  let limits = 10;
  let orderby = "name ASC";
  let role_uuid = "";

  const [plans, setPlans] = useState([]);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getPlans(
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        search,
        off_set,
        status,
        limits,
        orderby,
        role_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        setPlans(response.data.data);
        setPlanEvent("");
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, [planEvent]);

  const deletePlanBtn = (uuid) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this plan?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deletePlanService(uuid),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deletePlanService = (uuid) => {
    setLoading(true);
    dispatch(deletePlan(uuid, createdby, token))
      .then((response) => {
        setPlanEvent("delete");
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  };
  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle activeMenu="Plans" motherMenu="" />
      <>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Manage Plans</h4>
                {/* {add_per === "yes" ? ( */}
                <Button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() => navigate("/create-plan", { replace: true })}
                >
                  Add Plan
                </Button>
                {/* ) : (
                     <></>
                   )} */}
              </div>
              <div className="card-body">
                <Table responsive className="w-100">
                  <thead>
                    <tr role="row">
                      <th>Name</th>
                      <th>Status</th>
                      <th>Total Price</th>
                      {listing_per === "group" || user_type === "admin" ? (
                        <th>Created By</th>
                      ) : (
                        <></>
                      )}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {plans.map((plan) => (
                      <tr key={plan.plan_uuid}>
                        <td>
                          <Link
                            to="/permissions"
                            state={{ role_uuid: plan.plan_uuid }}
                            className="text-success"
                          >
                            {plan.name}
                          </Link>
                        </td>
                        <td>{plan.status}</td>
                        <td>{plan.final_price}</td>

                        <td>
                          <Fragment>
                            <div className="d-flex">
                              {plan.role_type !== "default" ? (
                                <Link
                                  to="/update-plan"
                                  state={{
                                    plan_uuid: plan.plan_uuid,
                                  }}
                                 
                                  hidden={PermissionCheck(
                                    module_name,
                                    "edit",
                                    plan.group_uuid,
                                    plan.user_created_by,
                                    plan.created_by
                                  )}
                                  className="btn btn-primary shadow btn-xs sharp mr-1"
                                >
                                  <i className="fa fa-pencil"></i>
                                </Link>
                              ) : (
                                <></>
                              )}

                              <Link
                                to="#"
                                onClick={() => deletePlanBtn(plan.plan_uuid)}
                                hidden={PermissionCheck(
                                  module_name,
                                  "delete",
                                  plan.group_uuid,
                                  plan.user_created_by,
                                  plan.created_by
                                )}
                                className="btn btn-danger shadow btn-xs sharp"
                              >
                                <i className="fa fa-trash"></i>
                              </Link>
                            </div>
                          </Fragment>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </>
      {/* ) : (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Manage Roles</h4>
              </div>
              <div className="card-body">
                <h3>You have don't access this modules</h3>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </Fragment>
  );
}

export default Plan;
