import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import InboxMessage from "./InboxMessage";
import Read from "../Read/Read";
import { Dropdown, DropdownButton, Modal } from "react-bootstrap";
import Pagination from "../../../layouts/Pagination";
import { getFolders, getFolderEmails,ChangeMailFlage,DeleteMail,DeleteFolder } from "../../../actions/email/emails"; //CheckFolderCount
import PageTitle from "../../../layouts/PageTitle";
import CheckModulePermisson from "../../../common/CheckModulePermisson";
import PermissionCheck from "../../../common/PermissionCheck";
import Loader from "../../../layouts/Loader";
import PerfectScrollbar from "react-perfect-scrollbar";
import FolderForm  from "./FolderForm";
import MoveToFolder from "./MoveToFolder";
import UpdateFolderForm from "./UpdateFolderForm";
import ComposeMail from "./ComposeMail";
import '../style.css';
import { notifyInfo, notifyError } from "../../Toastr/Toastr";
import { confirmAlert } from "react-confirm-alert";
import ButtonGroup from 'react-bootstrap/ButtonGroup';

const Inbox = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;
  const module_name = "email";

  let module_per = CheckModulePermisson(module_name);

  const dispatch = useDispatch();
  let navigate = useNavigate();

  let isPermission = true;
  if (module_per === "" || user_type === "admin") {
    isPermission = true;
  } else {
    isPermission = false;
  }

  let listing_per = PermissionCheck(module_name, "listing", "", "", "");

  if (!isPermission) {
    navigate("/error-403");
  }

  if (listing_per === "none") {
    navigate("/error-403");
  }

  const [isListActive, setisListActive] = useState(true);
  const [folders, setFolders] = useState([]);
  const [messages, setMessages] = useState([]);
  const [activeFolder, setActiveFolder] = useState("");
  const [totalFolderMessage, setTotalFolderMessage] = useState(0);
  const [activeEmail, setActiveEmail] = useState("");
  const [pageCount, setpageCount] = useState(0);
  const [search, setSearch] = useState({
    mailType : 'all',
  });
  const [emailEvent, setEmailEvent] = useState("0");
  const [offSet, setOffSet] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isFolderFormOpen, setIsFolderFormOpen] = useState(false);
  const [moveToFolderModal, setMoveToFolderModal] = useState(false);
  const [updateFolderModal, setUpdateFolderModal] = useState(false);
  const [composeMailModal, setComposeMailModal] = useState(false);
  const [updateFolderName, setUpdateFolderName] = useState("");
  const [selectedMails,setSelectedMails] = useState({
    selectd_mails: [],
    checked: 0,
    select_type: "none",
  });
  const [forwardAttachments, setForwardAttachments] = useState("");

  let limits = 10;

  useEffect(() => {
    setLoading(true);
    dispatch(getFolders(main_uuid, createdby, token))
      .then((response) => {
        setFolders(response.data);

        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);


  useEffect(() => {
    const interval = setInterval(() => {
        //CheckFolderCount
      fnReloadFolder();
    }, 30000);
  
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  const fnReloadFolder = () => {    

    dispatch(getFolders(main_uuid, createdby, token))
      .then((response) => {
        
        if(activeFolder){
          response.data.forEach((folder) => {
            
            if(folder.name === activeFolder.name){

              if(folder.unseen !== activeFolder.unseen){
                setEmailEvent(Math.random);
              }
            }
            
          });
        } 
        
        setFolders(response.data);

        return;
      })
      .catch(() => {
        
      });
  }

  useEffect(() => {
    if (activeFolder !== '') {
      setLoading(true);
      dispatch(
        getFolderEmails(
          main_uuid,
          createdby,
          activeFolder.path,
          search,
          offSet,
          limits,
          token
        )
      )
        .then((response) => {
          setMessages(response.data);

          if (selectedMails.select_type === "all") {
            SelectAll("all");
          }

          setLoading(false);
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [activeFolder, emailEvent,search]);

  const selectFolder = (folder) => {
    if (folder.hasOwnProperty("status")) {
      let total = folder.status.messages;

      setpageCount(Math.ceil(total / limits));
      setTotalFolderMessage(total);

      setOffSet(total-limits);
      
    } else {
      setTotalFolderMessage(0);
      setOffSet(0);
    }
    setActiveFolder(folder);
    setisListActive(true);

    setActiveEmail("");
  };

  const showMessage = (source,uid,isRead) => {

    if(!isRead){

      fnChangeMailFlage(uid,'read','single');
    }
    
    source['active_email_id'] = uid;
    setActiveEmail(source);
    setisListActive(false);
    
  };

  const handleMarkStar = (uid,action) => {

    fnChangeMailFlage(uid,action,'single');
        
  };

  const showFolderForm = (e) => {
    e.preventDefault();
    if(isFolderFormOpen){
      setIsFolderFormOpen(false);
    }else{
      setIsFolderFormOpen(true);
    }
    
  }

  const handleCreateFolder = (folder) => {

    let folder_obj = {
      "path": folder,
      "pathAsListed": folder,
      "flags": {},
      "delimiter": "/",
      "listed": true,
      "parentPath": "",
      "parent": [],
      "name": folder,
      "status": {
          "path": folder,
          "messages": 0,
          "unseen": 0
      },
      "subscribed": true
    }

    if(updateFolderName.length > 0){
      let new_folders =  folders.filter((folder) => {
        return folder.path !== updateFolderName;        
      }); 

      setFolders([...new_folders,folder_obj]);
      setUpdateFolderName('');
      setUpdateFolderModal(false);
    }else{
      setFolders([...folders,folder_obj]);
      setIsFolderFormOpen(false);
    }   
    
  }

  const handleUpdateFolderModal = (e,value,folder) => {
    e.preventDefault();
    setUpdateFolderModal(value);

    if(value){
      setUpdateFolderName(folder);
    }else{
      setUpdateFolderName('');
    }
  }

  const deleteFolder = (e,folder) => {
    e.preventDefault();

    confirmAlert({
      title: "",
      message:
        "Are you sure to delete this folder?",
      buttons: [
        {
          label: "Yes",
          onClick: () => fnDeleteFolder(folder),
        },
        {
          label: "No",
        },
      ],
   });
    
  }

  const fnDeleteFolder = (folder_name) => {
    dispatch(DeleteFolder(main_uuid, createdby,folder_name,token))
    .then((response) => {

      if (response.data.status === "SUCCESS") {
        let new_folders =  folders.filter((folder) => {
          return folder.path !== folder_name;        
        }); 
        
        setFolders(new_folders);
        notifyInfo(response.message);
      } else {
          notifyError(response.message);
      }
      
      setLoading(false);
      return;
    })
    .catch(() => {
      setLoading(false);
    });
  }

  const fnChangeMailFlage = (uid,newFlage,actionType) => {

    setLoading(true);

    let uids = [];
    if(actionType === 'single'){
      uids[0] = uid;
    }else{
      uids = selectedMails.selectd_mails;
    }

    let folder_name = activeFolder.path;

    dispatch(ChangeMailFlage(main_uuid, createdby,uids, newFlage,folder_name, token))
      .then((response) => {
         let new_messages =  messages.map((message, index) => {

          if(uids.includes(message.uid)){
            if(newFlage === 'read'){
              message['isRead'] = true;
            }else if(newFlage === 'unread'){
              message['isRead'] = false;
            }else if(newFlage === 'markfevorite'){
              message['isFevorite'] = true;
            }else if(newFlage === 'removefevorite'){
              message['isFevorite'] = false;
            }
            
            return message;
          }else{
            return message
          }
          
        });
    
         setMessages(new_messages);
         setLoading(false);
         return;
      })
      .catch(() => {
        setLoading(false);
      });
  }

  const deleteMail = (uid,actionType) => {

    confirmAlert({
      title: "",
      message:
        "Are you sure to delete this mail?",
      buttons: [
        {
          label: "Yes",
          onClick: () => fnDeleteMail(uid,actionType),
        },
        {
          label: "No",
        },
      ],
   });
    
 }

 const fnDeleteMail = (uid,actionType) => {

  setLoading(true);

  let folder_path = activeFolder.path;

    let uids = [];
    if(actionType === 'single'){
      uids[0] = uid;
    }else{
      uids = selectedMails.selectd_mails;
    }

  dispatch(DeleteMail(main_uuid, createdby,folder_path,uids, token))
    .then((response) => {

      if(response.status === 200){
        let new_messages =  messages.filter((message) => {
          return !uids.includes(message.uid);        
        }); 
        
        setMessages(new_messages);
        setActiveEmail("");
        setisListActive(true);
      }else{
        notifyError(response.message);
      }
      
      setLoading(false);
      return;
    })
    .catch(() => {
      setLoading(false);
    });
 }

  const SelectAll = (type) => {
    let mail_uids = [];
   
    messages.map(
      (message) => (mail_uids = [...mail_uids, message.uid])
    );

    const newarr = [];

    newarr["selectd_mails"] = mail_uids;
    newarr["checked"] = mail_uids.length;
    newarr["select_type"] = type;

    setSelectedMails(newarr);

    return;
  };

  const DeSelectAll = () => {
    const newarr = [];
    newarr["selectd_mails"] = [];
    newarr["checked"] = 0;
    newarr["select_type"] = "none";

    setSelectedMails(newarr);
  };

  const checkMail = (e, mail_uid) => {
    const newarr = [];

    if (e.target.checked) {
      //checked : add element
      const newdata = [...selectedMails.selectd_mails, mail_uid];
      newarr["selectd_mails"] = newdata;
      newarr["checked"] = newdata.length;
    } else {
      //uncheck : remove element
      const newdata = selectedMails.selectd_mails.filter(
        (arr) => arr !== mail_uid
      );

      newarr["selectd_mails"] = newdata;
      newarr["checked"] = newdata.length;
    }

    newarr["select_type"] = "none";

    setSelectedMails(newarr);
  };

  const handleMoveToFolderModel = (value,actionType = '') => {

    if(actionType === 'single'){
      const newarr = [];

      const newdata = [...selectedMails.selectd_mails, activeEmail.active_email_id];
      newarr["selectd_mails"] = newdata;
      newarr["checked"] = newdata.length;
      newarr["select_type"] = "none";
      setSelectedMails(newarr);
    }

    setMoveToFolderModal(value);
  }

  const handleMoveFolder = () => {
    let uids = selectedMails.selectd_mails;

    let new_messages =  messages.filter((message) => {
      return !uids.includes(message.uid);        
    }); 
    
    setMessages(new_messages);
    DeSelectAll();
  }

  const handleComposeMailModal = (value) => {
    setComposeMailModal(value);
  }

  const forwordAsAttachment = () => {

    let uids = selectedMails.selectd_mails;

    let message_files = [];

      messages.map((message) => {

        if(uids.includes(message.uid)){
        
          let obj = {
            emlBuffer:message.str_source,
            file_name : message.source.subject+'.eml',
            uid : message.uid,
          }
          message_files.push(obj);
        }
        return true;
    }); 

    setForwardAttachments(message_files);
    handleComposeMailModal(true);
  }

  const searchList = (e,type) => {
    e.preventDefault();

    setSearch({mailType:type});
  }

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;

    let page_offset = limits * currentPage;

    let offset_count = totalFolderMessage - page_offset;

    setOffSet(offset_count);
    setEmailEvent(Math.random);
  };

  const breadCurmList = [
    { label: "Home", link: "/", is_active: "NO" },
    { label: "Email", link: "#", is_active: "YES" },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
      
      <PageTitle breadCurmList={breadCurmList} />

      <div className="row">
        <div className="col-lg-6 pr-2">
          <div className="card">
            <div className="card-body">
              <div className="email-left-box px-0 mb-3">
                <div className="p-0 mb-4">
                  <Link
                    to="#"
                    className="btn btn-primary btn-block"
                    onClick={() => handleComposeMailModal(true)}
                  >
                    Compose
                  </Link>
                </div>

                <PerfectScrollbar
                    className={`p-0 dz-scroll widget-media`}
                    id="DZ_W_Contacts_Body2"
                    style={{height: '320px'}} 
                >
                  <ul className="timeline">
                    {folders &&
                      folders.map((folder, index) => (                        
                        <li className={`${
                              activeFolder.name === folder.name
                                ? "list-group-item justify-content-between align-items-center active"
                                : "list-group-item justify-content-between align-items-center"
                            }`}
                           key={index}>

                              <Dropdown>
                                <Dropdown.Toggle
                                  aria-expanded="false"
                                  data-toggle="dropdown"
                                  className="pull-left"
                                  as={ButtonGroup}
                                >
                                  <i className="fa fa-ellipsis-v font-18 align-middle mr-2"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="dropdown-menu">
                                  <Dropdown.Item
                                    to="#"
                                    onClick={(e)=>handleUpdateFolderModal(e,'true',folder.path)}
                                    className="dropdown-item"
                                  >
                                    Edit
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    to="#"
                                    onClick={(e)=>deleteFolder(e,folder.path)}
                                    className="dropdown-item"
                                  >
                                    Delete
                                 </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                              
                              <a href="#!" className="text-black" onClick={() => selectFolder(folder)} >{folder.name}</a>

                            <span className="badge badge-primary light badge-sm float-right cussor-pointer" onClick={() => selectFolder(folder)}>
                              {folder.hasOwnProperty("status")
                                ? folder.status.unseen
                               : ""}
                            </span>
                        </li>
                      ))}
                  </ul>
                </PerfectScrollbar>

                <div className="p-0 mt-4 text-center">
                  <Link
                    to="#"
                    className="h4"
                    onClick={(e) => showFolderForm(e)}
                  >
                    create Folder
                  </Link>
                  
                  {isFolderFormOpen && 
                    <FolderForm handleCreateFolder={handleCreateFolder} />
                  }
                  
                </div>
                
              </div>
              <div
                className={`${
                  isListActive
                    ? "email-right-box ml-0 ml-sm-4 ml-sm-0 pr-0"
                    : "email-right-box ml-0 ml-sm-4 ml-sm-0 pr-0"
                }`}
              >
                <div role="toolbar" className="toolbar ml-1 ml-sm-0">
                  <div className="btn-group mb-1 mr-1 ml-1">
                      <DropdownButton
                        title=""
                        id="bg-nested-dropdown"
                        size="sm"
                        variant="btn btn-dark"
                      >
                        <Dropdown.Item
                          eventKey="1"
                          href="#"
                          onClick={() => SelectAll("page")}
                        >
                          Select This Page
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="2"
                          href="#"
                          onClick={() => SelectAll("all")}
                        >
                          Select All
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="3"
                          href="#"
                          onClick={() => DeSelectAll()}
                        >
                          DeSelect All
                        </Dropdown.Item>
                      </DropdownButton>
                  </div>

                  <div className="btn-group mr-1 ml-1">
                    <h5>
                        {activeFolder !== '' ? activeFolder.name : ''}
                        <br></br>
                        <small>{totalFolderMessage} Messages</small>
                    </h5>
                  </div>

                  <div className="btn-group mb-1 pull-right">
                    <button
                      className="btn btn-primary light px-3"
                      type="button"
                    >
                      <i className="ti-reload"></i>
                    </button>
                  </div>

                  {selectedMails.checked > 0 &&
                    <Dropdown className="btn-group mb-1 pull-right mr-2">
                      <Dropdown.Toggle
                        aria-expanded="false"
                        data-toggle="dropdown"
                        className="btn btn-primary px-3 light dropdown-toggle ml-1"
                        type="button"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                        >
                          <g fill="none">
                            <path d="M0 0H24V24H0z"></path>
                            <circle cx="12" cy="5" r="2" fill="#000"></circle>
                            <circle cx="12" cy="12" r="2" fill="#000"></circle>
                            <circle cx="12" cy="19" r="2" fill="#000"></circle>
                          </g>
                        </svg>
                        
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu">
                        <Dropdown.Item
                          to="#"
                          className="dropdown-item"
                          onClick={(e) => fnChangeMailFlage('','unread','multiple')}
                        >
                          Mark as Unread
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="/email-inbox"
                          className="#"
                          onClick={(e) => fnChangeMailFlage('','read','multiple')}
                        >
                          Mark as Read
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="#"
                          className="dropdown-item"
                          onClick={(e) => handleMoveToFolderModel(true,'multiple')}
                        >
                          Move to
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="#"
                          className="dropdown-item"
                          onClick={(e) => deleteMail('','multiple')}                          
                        >
                          Delete
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="#"
                          className="dropdown-item"
                          onClick={(e) => forwordAsAttachment()}                          
                        >
                          Forward as attachment
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  }

                  {selectedMails.checked === 0 && 
                    <Dropdown className="btn-group mb-1 pull-right mr-2">
                      <Dropdown.Toggle
                        aria-expanded="false"
                        data-toggle="dropdown"
                        className="btn btn-primary px-3 light dropdown-toggle ml-1"
                        type="button"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                        >
                          <g fill="none">
                            <path d="M0 0H24V24H0z"></path>
                            <circle cx="12" cy="5" r="2" fill="#000"></circle>
                            <circle cx="12" cy="12" r="2" fill="#000"></circle>
                            <circle cx="12" cy="19" r="2" fill="#000"></circle>
                          </g>
                        </svg>
                        
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu">
                        <Dropdown.Item
                          to="#"
                          className={`${
                            search.mailType === 'unread'
                              ? "dropdown-item active"
                              : "dropdown-item"
                          }`}
                          onClick={(e) => searchList(e,'unread')}
                        >
                          Show Unread
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="/email-inbox"
                          className={`${
                            search.mailType === 'read'
                              ? "dropdown-item active"
                              : "dropdown-item"
                          }`}
                          onClick={(e) => searchList(e,'read')}
                        >
                          Show Read
                        </Dropdown.Item> 
                        <Dropdown.Item
                          to="/email-inbox"
                          className={`${
                            search.mailType === 'all'
                              ? "dropdown-item active"
                              : "dropdown-item"
                          }`}
                          onClick={(e) => searchList(e,'all')}
                        >
                          Show All
                        </Dropdown.Item>                        
                      </Dropdown.Menu>
                    </Dropdown>
                  }

                </div>
                <div className="email-list mt-3">
                  {/** Single Message */}
                  <ul className="timeline">
                    {messages &&
                      messages.map((message, index) => (
                        <InboxMessage
                          key={index}
                          id={index}
                          message={message}
                          showMessage={showMessage}
                          activeEmail={activeEmail}
                          checkMail={checkMail}
                          selectedMails={selectedMails}
                          handleMarkStar={handleMarkStar}
                        ></InboxMessage>
                    ))}
                    
                  </ul>
                  
                </div>
                <div className="row mt-4">
                  <div className="col-12 pl-3">
                  {totalFolderMessage > 0 &&
                    <Pagination
                      pageCount={pageCount}
                      handlePageNumberClick={handlePageClick}
                    />
                  }
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        <div className="col-lg-6 pl-2">

          <div className="card">
            <div className="card-body">
              {/* <div className="email-right-box ml-0 ml-sm-4 ml-sm-0"> */}
              <div >
                {activeEmail !== "" ? <Read activeEmail={activeEmail} deleteMail={deleteMail} handleMoveToFolderModel={handleMoveToFolderModel} /> : ""}

              </div>
            </div>
          </div>
              
        </div>
      </div>

      {/* <!-- move to folder --> */}
      <Modal className="fade" show={moveToFolderModal}>
        <MoveToFolder handleMoveToFolderModel={handleMoveToFolderModel} folders={folders} activeFolderPath={activeFolder.path} uid={selectedMails.selectd_mails} handleMoveFolder={handleMoveFolder} />
      </Modal>

      {/* <!-- update folder --> */}
      <Modal className="fade" show={updateFolderModal}>
        <UpdateFolderForm handleUpdateFolderModal={handleUpdateFolderModal} updateFolderName={updateFolderName} handleCreateFolder={handleCreateFolder}  />
      </Modal>

      {/* <!-- compose mail --> */}
      <Modal className="fade" show={composeMailModal} size='lg'>
        <ComposeMail handleComposeMailModal={handleComposeMailModal} forwardAttachments={forwardAttachments} />
      </Modal>

    </Fragment>
  );
};

export default Inbox;
