import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { notifyInfo, notifyError } from "../../Toastr/Toastr";
import { updatePassword } from "../../../actions/user";
import validator from "validator";
import Loader from "../../../layouts/Loader";

const ChangePassword = (props) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;

  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const [loading, setLoading] = useState(false);

  const [passwordData, setPasswordData] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
    user_uuid: createdby,
    createdby: createdby,
    main_uuid: main_uuid,
  });

  const [errors, setErrors] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const handleBlur = (e) => {
    const newGroupData = { ...passwordData };
    let error_data = { ...errors };

    if (e.target.name === "old_password") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Old password is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "new_password") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "New password is required.";
        setErrors(error_data);
      } else {
        if (
          validator.isStrongPassword(e.target.value, {
            minLength: 8,
            minLowercase: 1,
            minUppercase: 1,
            minNumbers: 1,
            minSymbols: 1,
          })
        ) {
          error_data[e.target.name] = "";
          setErrors(error_data);
        } else {
          error_data[e.target.name] =
            "Password is weak. Should have a minimum of 8 characters. Ex. Test@123";
          setErrors(error_data);
        }
      }
    }

    if (e.target.name === "confirm_password") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Confirm password is required.";
        setErrors(error_data);
      } else {
        if (
          validator.isStrongPassword(e.target.value, {
            minLength: 8,
            minLowercase: 1,
            minUppercase: 1,
            minNumbers: 1,
            minSymbols: 1,
          })
        ) {
          error_data[e.target.name] = "";
          setErrors(error_data);
        } else {
          error_data[e.target.name] =
            "Password is weak. Should have a minimum of 8 characters. Ex. Test@123";
          setErrors(error_data);
        }
      }
    }

    newGroupData[e.target.name] = e.target.value;
    setPasswordData(newGroupData);
  };

 
  const changePasswordBtn = (e) => {
    let error_data = { ...errors };
    let isError = "false";
    setLoading(true);

    if (passwordData.old_password.length === 0) {
      error_data["old_password"] = "Old Password is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (passwordData.new_password.length === 0) {
      error_data["new_password"] = "New Password is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (passwordData.confirm_password.length === 0) {
      error_data["confirm_password"] = "Confirm Password is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (passwordData.new_password !== passwordData.confirm_password) {
      error_data["new_password"] =
        "New Password and Confirm Password is not match.";
      setErrors(error_data);
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create user API
      let data = { ...passwordData };
      // data[attribute] = value;

      dispatch(
        updatePassword(
          data.old_password,
          data.new_password,
          data.user_uuid,
          token
        )
      )
        .then((res) => {
          if (res.status === 200) {
            notifyInfo(res.message);
            setLoading(false);
            navigate("/app-profile", { replace: true });
          } else if (res.status === 203) {
            error_data["old_password"] = res.message;
            setErrors(error_data);
            isError = "true";
          } else if (res.status === 500) {
            setLoading(false);
            notifyError("Something went wrong.");
          } else {
            setLoading(false);
            notifyError("Something went wrong.");
          }
          return;
        })
        .catch(() => {
          // setLoading(false);
        });
    }
  };

  return (
    <div className="pt-3">
      {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="mb-1">
                <strong>Old Password</strong>
                <span className="text-danger">*</span>
              </label>
              <input
                type="password"
                className="form-control"
                placeholder="Old Password"
                name="old_password"
                id="old_password"
                onChange={handleBlur}
                value={passwordData.old_password}
              />
              <span className="text-danger">{errors.old_password}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label className="mb-1">
                <strong>New Password</strong>
                <span className="text-danger">*</span>
              </label>
              <input
                type="password"
                className="form-control"
                placeholder="New Password"
                name="new_password"
                id="new_password"
                onChange={handleBlur}
                value={passwordData.new_password}
              />
              <span className="text-danger">{errors.new_password}</span>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="mb-1">
                <strong>Confirm Password</strong>
                <span className="text-danger">*</span>
              </label>
              <input
                type="password"
                className="form-control"
                placeholder="Confirm Password"
                name="confirm_password"
                id="confirm_password"
                onChange={handleBlur}
                value={passwordData.confirm_password}
              />
              <span className="text-danger">{errors.confirm_password}</span>
            </div>
          </div>
        </div>

        <div className="row text-right">
          <div className="col-sm">
            <Button
              type="button"
              variant="primary"
              className="btn-sm mr-2"
              onClick={(e) => changePasswordBtn()}
            >
              Update Password
            </Button>
          </div>
        </div>
      </Modal.Body>
    </div>
  );
};

export default ChangePassword;
