import React from "react";
import { useNavigate } from "react-router-dom";
import MultiStepForm from "./MultiStepForm";

const Onboarding = () => {
  let navigate = useNavigate();

  var user = JSON.parse(localStorage.getItem("user"));
  var user_data = user.data.data;
  if (
    user_data.is_did_done === "YES" &&
    user_data.is_email_config_done === "YES" &&
    user_data.is_branding_done === "YES" &&
    user_data.is_user_done === "YES"
  ) {
    navigate("/");
  }

  return (
    <>
      <div className="form-head d-flex mb-0 mb-lg-4 align-items-start">
        <div className="mr-auto">
          <h3 className="text-warning font-w500 mb-1">
            Welcome to Zongo! Follow the steps below to get started and complete
            your set up!
          </h3>
          <p className="mb-0 font-w500">
            You can skip if you know what your are doing and do the set up from
            panel. We recommend below steps to get started quickly. For any
            queries please contact support on support@zongo.com
          </p>
        </div>
      </div>
      <MultiStepForm />

      <div className="form-head d-flex mb-0 mb-lg-4 align-items-start mt-4">
        <div className="mr-auto">
          <h4 className="text-primary font-w500 mb-1">
            You have been assigned extension 1000 and registered with same on
            the web-phone on the right!
          </h4>
        </div>
      </div>
    </>
  );
};

export default Onboarding;
