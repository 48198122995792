import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

//emailCampaign

const getEmailCampaignList = (data, token) => {
  
  return axios
    .post(API_URL + "get-email-campaign-list", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const createEmailCampaign = (data, token) => {
  
  return axios
    .post(API_URL + "create-email-campaign", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getEmailCampaignDetails = (data, token) => {
  
  return axios
    .post(API_URL + "get-email-campaign-detail", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const updateEmailCampaign = (data, token) => {
  
  return axios
    .post(API_URL + "update-email-campaign", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const deleteEmailCampaign = (email_campaign_uuid, createdby, token) => {
  
  return axios
    .post(API_URL + "delete-email-campaign", {email_campaign_uuid, createdby}, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const getEmailCampaignReport = (email_campaign_uuid, createdby,start_date,end_date, token) => {
  
  return axios
    .post(API_URL + "get-email-campaign-report", {email_campaign_uuid, createdby,start_date,end_date}, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      return response.data;
    });
};

export default {
  getEmailCampaignList,
  createEmailCampaign,
  getEmailCampaignDetails,
  updateEmailCampaign,
  deleteEmailCampaign,
  getEmailCampaignReport,
};
