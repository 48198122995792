
import React, {Fragment,useEffect,useState} from "react";
import EasyEdit, { Types } from "react-easy-edit";
import { useDispatch } from "react-redux";
import { getDestinationTypeList } from "../../actions/ivr";
import { Button } from "react-bootstrap";
import TimeSlotForm from "./TimeSlotForm";

const UpdateDay = (props) => {

    const user = JSON.parse(localStorage.getItem("user"));
    const user_data = user.data.data;
    const token = user.data.token;
    const createdby = user_data.user_uuid;

    let errors = props.errors;
    let defaultDay = props.defaultDay;
    let timeSlotOption = props.timeSlotOption;
    let times = props.times;
    let selectDay = props.selectDay;
    let addTimeSlot = props.addTimeSlot;

    const dispatch = useDispatch();

    const [offHours, setOffHours] = useState([]);
    const [timeSlots, setTimeSlots] = useState([]);

    useEffect(() => {
        if(timeSlotOption.hasOwnProperty(0)){
            
            let off_hours = props.timeSlotOption.filter(option => option.type === 'off_hours');
            let time_slots = props.timeSlotOption.filter(option => option.type === 'default');

            setOffHours(off_hours);
            setTimeSlots(time_slots);
        }

    },[timeSlotOption])

    const CustomDisplay = (props) => {
        const [options, setOptions] = useState([]);
    
        const [extData, setExtData] = useState({
          time_condition_times_uuid: props.time_condition_times_uuid,
          route_type: props.route_type,
          route_to: props.route_to,
          route_to_name: props.route_to_name,
          token: token,
          createdby: createdby,
          main_admin_uuid: createdby,
          user_uuid: createdby,
        });
    
        if (options === "") {
          const main_uuid = user_data.main_uuid;
    
          dispatch(
            getDestinationTypeList(createdby, token, main_uuid, props.route_type)
          )
            .then((response) => {
              setOptions(response.data);
              return;
            })
            .catch(() => {
            });
        }
    
        //let new_data = { ...timeconditionData };
    
        function inputonChange(e) {
          let new_data1 = { ...extData };
    
          //let data = { ...availabilityData };
    
          if (e.target.name === "route_type") {
            new_data1[e.target.name] = e.target.value;
            new_data1["route_to"] = "";
            new_data1["route_to_name"] = "";
            //new_data[e.target.name] = e.target.value;
            //data["route_type"] = e.target.value;
            //data["route_to"] = "";
            getoptions(e.target.value);
          }
    
          if (e.target.name === "route_to") {
            const { options, selectedIndex } = e.target;
            new_data1[e.target.name] = e.target.value;
            //new_data["route_to_name"] = options[selectedIndex].innerHTML;
            //data["route_to"] = e.target.value;
            // new_data[e.target.name] = e.target.value;
            new_data1["route_to_name"] = options[selectedIndex].innerHTML;
          }
    
          props.setParentValue(new_data1);
          setExtData(new_data1);
          // timeconditionData = { ...new_data };
        }
    
        const getoptions = (type) => {
          const main_uuid = user_data.main_uuid;
    
          dispatch(getDestinationTypeList(createdby, token, main_uuid, type))
            .then((response) => {
              setOptions(response.data);
              return;
            })
            .catch(() => {
            });
        };
    
        return (
          <div>
            <div className="row">
              <div className="col-6">
                <div className="form-group ">
                  <label>Route To</label>
                  <select
                    className="form-control"
                    name="route_type"
                    id={extData}
                    onChange={(e) => inputonChange(e)}
                    defaultValue={props.route_type}
                  >
                    <option value="">Select Destination Type</option>
                    <option value="ivr">Auto-Attendant</option>
                    <option value="extention">Extention</option>
                    <option value="ring-group">Ring Groups</option>
                    <option value="did">DID</option>
                    <option value="voicemail">Voicemail</option>
                    <option value="time_condition">Time-Based Routing</option>
                  </select>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label>Destination</label>
                  <select
                    className="form-control"
                    name="route_to"
                    id={extData}
                    onChange={(e) => inputonChange(e)}
                    value={extData.route_to}
                  >
                    <option value="">Select Destination</option>
    
                    {options &&
                      options.map((number) => (
                        <option key={number.value} value={number.value}>
                          {number.text}
                        </option>
                      ))}
    
                    {/* {options &&
                      options.map((number) =>
                        number.value != timeconditionData.time_condition_uuid ? (
                          <option key={number.value} value={number.value}>
                            {number.text}
                          </option>
                        ) : null
                      )} */}
                  </select>
                </div>
              </div>
            </div>
          </div>
        );
      };
    
      const RouteAndDestination = (props) => {
        if (props.route_type === "ivr") {
          return (
            <span className="text-center ml-5">
              {"Auto-Attendant - " + props.route_to_name}
            </span>
          );
        } else if (props.route_type === "extention") {
          return (
            <span className="text-center ml-5">
              {"Extention - " + props.route_to_name}
            </span>
          );
        } else if (props.route_type === "ring-group") {
          return (
            <span className="text-center ml-5">
              {"Ring Group - " + props.route_to_name}
            </span>
          );
        } else if (props.route_type === "did") {
          return (
            <span className="text-center ml-5">
              {"Inbound Number - " + props.route_to_name}
            </span>
          );
        } else if (props.route_type === "voicemail") {
          return (
            <span className="text-center ml-5">
              {"Voicemail - " + props.route_to_name}
            </span>
          );
        } else if (props.route_type === "time_condition") {
          return (
            <span className="text-center ml-5">
              {"Time-Based Routing - " + props.route_to_name}
            </span>
          );
        }
      };


    return (
        <Fragment>
            <span className="text-danger err_msg">
                {errors.time_validation_up}
            </span>

            <h4 className="card-title">Schedule - {defaultDay.toUpperCase()}</h4>

            {timeSlots && timeSlots.map((timeSlot,j) => (
                <div className="form-row mt-3" key={timeSlot.id}> 
                    <div className="col mt-2 mt-sm-0">
                        <EasyEdit
                            type={Types.TEXT}
                            onSave={(value) => {
                                props.save(
                                value,
                                "time_slot_name",
                                timeSlot.id,
                                timeSlot.from_time,
                                timeSlot.to_time,
                                timeSlot.route_type,
                                timeSlot.route_to,
                                timeSlot.time_slot_name,
                                timeSlot.time_condition_uuid
                                );
                            }}
                            saveButtonLabel={<i className="fa fa-check" aria-hidden="true" ></i>}
                            saveButtonStyle="label-primary easy-edit-button rounded-lg"
                            cancelButtonLabel={<i className="fa fa-times" aria-hidden="true" ></i>}
                            viewAttributes={{className:"inline_edit_div"}}
                            cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                            attributes={{name: "time_slot_name",className:"form-control h-75 w-75",}}
                            onValidate={(value) => {
                                if (
                                value.time_slot_name ===
                                ""
                                ) {
                                return false;
                                } else {
                                return true;
                                }
                            }}
                            validationMessage="Please enter name"
                            placeholder="Please enter name"
                            value={timeSlot.time_slot_name}
                        />
                    </div>

                    <div className="col mt-2 mt-sm-0">
                        <select className="form-control bg-white time_field_h p-2" name="from_time" onChange={(e) =>props.handleToFrom(e,timeSlot)}
                            value={props.conDateFormate(timeSlot.from_time)}
                            id={"from_" +j}
                        >
                        <option value=""> Select From Time </option>
                            {times &&
                                times.map((number) => (
                                    <option value={number} key={number}> {number} </option>                               
                                )
                            )}
                        </select>
                        <span className="text-danger">
                            {errors.from_time.j !==""? errors.from_time.j : ""}
                        </span>

                        
                    </div>

                    <div className="mt-1 text-center p-1">{"To"}</div>

                    <div className="col mt-2 mt-sm-0">
                        <select className="form-control bg-white time_field_h p-2" name="to_time" onChange={(e) =>props.handleToFrom(e,timeSlot)}
                            value={props.conDateFormate(timeSlot.to_time)}
                            id={j}
                        >
                        <option value=""> Select To Time </option>
                            {times &&
                                times.map((number) => (
                                    <option value={number} key={number}> {number} </option>                               
                                )
                            )}
                        </select>
                        <span className="text-danger">
                            {errors.to_time.j !==""? errors.to_time.j : ""}
                        </span>
                    </div>

                    <div className="col mt-2 mt-sm-0">
                        <EasyEdit
                            type={Types.TEXT}
                            onSave={(value) => {
                                props.save(
                                value,
                                "route_and_destination",
                                timeSlot.time_condition_uuid,
                                timeSlot.from_time,
                                timeSlot.to_time,
                                timeSlot.route_type,
                                timeSlot.route_to,
                                timeSlot.time_slot_name,
                                timeSlot.time_condition_uuid
                                );
                            }}
                            editComponent={
                                <CustomDisplay
                                    route_type={timeSlot.route_type}
                                    route_to_name={timeSlot.route_to_name}
                                    route_to={timeSlot.route_to}
                                    time_condition_times_uuid={timeSlot.id}
                                />
                            }
                            displayComponent={
                                <RouteAndDestination
                                    route_type={timeSlot.route_type}
                                    route_to_name={timeSlot.route_to_name}
                                />
                            }

                            saveButtonLabel={<i className="fa fa-check" aria-hidden="true" ></i>}
                            saveButtonStyle="label-primary easy-edit-button rounded-lg"
                            cancelButtonLabel={<i className="fa fa-times" aria-hidden="true" ></i>}
                            viewAttributes={{className:"inline_edit_div"}}
                            cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                            attributes={{name: "route_and_destination",className:"form-control h-75 w-75",}}

                            onValidate={(value) => {
                                if (
                                value.route_to ===
                                ""
                                ) {
                                return false;
                                } else {
                                return true;
                                }
                            }}
                            validationMessage="Please enter route to"
                            placeholder="Route and destination"
                            value={timeSlot.route_type}
                        />
                    </div>

                    <div className="mt-1 text-center p-1">
                        <Button
                            to="#"
                            className="btn btn-danger shadow btn-xs sharp"
                            onClick={(e) =>{ e.preventDefault(); props.fnDeleteTimeSlot(timeSlot.id,timeSlot.time_condition_data_uuid)}}
                        >
                            <i className="fa fa-trash"></i>
                        </Button>
                    </div>
                </div>
             ))
            }

            <div className="form-row mt-4 mb-2"> 
                <div className="col mt-2 mt-sm-0">
                    
                    <a href="#!" className="text text-success mb-2 mr-2"
                        onClick={(e) => { e.preventDefault(); props.openAddTimeSlotform(selectDay.day_uuid,props.time_condition_uuid,addTimeSlot)}}
                    >   
                        <i className="fa fa-plus mr-2"></i>
                        Add Time Block
                    </a>
                </div>
            </div>

            {addTimeSlot &&
                <TimeSlotForm
                    destinationTypeOption=""
                    createAvailTimeSlot={props.createAvailTimeSlot}
                    time_condition_uuid={props.time_condition_uuid}
                    time_condition_data_uuid={props.selectDay.day_uuid}
                    errors={errors}
                />
            }

            {offHours && offHours.map((offHour,j) => (
                <div className="form-row mt-4" key={offHour.id}> 
                    <div className="col mt-2 mt-sm-0">
                        <div className="easy-edit-wrapper inline_edit_div">
                            <i className="fa fa-building"></i>
                            Off Hour
                        </div>                    
                    </div> 
                    <div className="col mt-2 mt-sm-0">
                        <EasyEdit
                            type={Types.TEXT}
                            onSave={(value) => {
                                props.save(
                                value,
                                "route_and_destination",
                                offHour.time_condition_uuid,
                                offHour.from_time,
                                offHour.to_time,
                                offHour.route_type,
                                offHour.route_to,
                                offHour.time_slot_name,
                                offHour.time_condition_uuid
                                );
                            }}
                            editComponent={
                                <CustomDisplay
                                    route_type={offHour.route_type}
                                    route_to_name={offHour.route_to_name}
                                    route_to={offHour.route_to}
                                    time_condition_times_uuid={offHour.id}
                                />
                            }
                            displayComponent={
                                <RouteAndDestination
                                    route_type={offHour.route_type}
                                    route_to_name={offHour.route_to_name}
                                />
                            }

                            saveButtonLabel={<i className="fa fa-check" aria-hidden="true" ></i>}
                            saveButtonStyle="label-primary easy-edit-button rounded-lg"
                            cancelButtonLabel={<i className="fa fa-times" aria-hidden="true" ></i>}
                            viewAttributes={{className:"inline_edit_div"}}
                            cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                            attributes={{name: "route_and_destination",className:"form-control h-75 w-75",}}

                            onValidate={(value) => {
                                if (
                                value.route_to ===
                                ""
                                ) {
                                return false;
                                } else {
                                return true;
                                }
                            }}
                            validationMessage="Please enter route to"
                            placeholder="Route and destination"
                            value={offHour.route_type}
                        />
                    </div> 
                </div>  
            ))}
            
        </Fragment>
    );
}

export default UpdateDay;