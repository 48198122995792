import React, { Fragment, useState, useEffect } from "react";
import { Table, Button, Row, Tab, Nav } from "react-bootstrap";
import { useDispatch } from "react-redux";
import "./style.css";
import Tooltip from "rc-tooltip";
import EasyEdit, { Types } from "react-easy-edit";
import { getDestinationTypeList } from "../../actions/comman";
import { confirmAlert } from "react-confirm-alert";
import TimeSlotForm from "./TimeSlotForm";
import {
  getAvailabilityTimeDetails,
  createTimeSlot,
  copyTimeSlot,
} from "../../actions/useravailability";
import moment from "moment";
import Loader from "../../layouts/Loader";

function Availability(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  let extensionData = props.extensionData;

  let extension_uuid = extensionData.extension_uuid;
  let timeconditionsday = props.timeconditionsday;
  let timeSlotOption = props.timeSlotOption;

  const [addTimeSlot, setAddTimeSlot] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allTimeSlot, setAllTimeSlot] = useState([]);

  const handleTimeSlotEvent = (event) => {
    //setTimeSlotEvent();
  };

  const [availabilityData, setAvailabilityData] = useState({
    user_availability_times_uuid: "",
    from_time: "",
    to_time: "",
    route_type: "",
    route_to: "",
    token: token,
    createdby: createdby,
    main_admin_uuid: createdby,
    user_uuid: createdby,
  });

  const [selectDay, setSelectDay] = useState({});

  const [errors, setErrors] = useState({
    time_condition_data_uuid: "",
    from_time: "",
    to_time: "",
    route_to: "",
    route_type: "",
    time_slot_time: "",
  });

  let defaultDay = props.defaultDay;

  const dispatch = useDispatch();

  const getTimeSlot = (uuid, day) => {
    setLoading(true);
    let select_data_data = { ...selectDay };

    select_data_data["day_uuid"] = uuid;
    select_data_data["day"] = day;
    select_data_data["user_uuid"] = createdby;
    setAddTimeSlot(false);
    setSelectDay(select_data_data);
    dispatch(getAvailabilityTimeDetails(uuid, createdby, token))
      .then((response) => {
        let arr = response.data.filter(obj => obj.type === "default").map((obj, i) => {
          
          return {
            user_availability_times_uuid: obj.user_availability_times_uuid,
            from_time: obj.from_time,
            to_time: obj.to_time,
          };
         
        });

        const filteredArray = arr.filter((element) => element);
        setAllTimeSlot(filteredArray);
        props.saveOption(response.data);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (timeconditionsday.length > 0) {
      getTimeSlot(defaultDay, timeconditionsday[0].user_availability_day);
    }
  }, [timeconditionsday]);

  const save = (
    value,
    attribute,
    time_slot_uuid,
    from_time,
    to_time,
    route_type,
    route_to,
    user_availability_data_uuid
  ) => {
    let data = { ...availabilityData };

    data["user_availability_times_uuid"] = time_slot_uuid;
    data["user_availability_data_uuid"] = user_availability_data_uuid;

    if (attribute === "from_time") {
      data["from_time"] = value;
      data["to_time"] = to_time;
      data["route_type"] = route_type;
      data["route_to"] = route_to;
      props.saveTimeSlot(data);
    }

    if (attribute === "to_time") {
      data["to_time"] = value;
      data["from_time"] = from_time;
      data["route_type"] = route_type;
      data["route_to"] = route_to;
      props.saveTimeSlot(data);
    }

    if (attribute === "route_and_destination") {
      if (
        value !== "ivr" &&
        value !== "extention" &&
        value !== "ring-group" &&
        value !== "did" &&
        value !== "voicemail" &&
        value !== "time_condition"
      ) {
        value.from_time = from_time;
        value.to_time = to_time;
        value.user_availability_data_uuid = user_availability_data_uuid;
        props.saveTimeSlot(value);
      }
    }

    setAvailabilityData(data);
  };

  const CustomDisplay = (props) => {
    const [options, setOptions] = useState([]);

    const [extData, setExtData] = useState({
      user_availability_times_uuid: props.user_availability_times_uuid,
      route_type: props.route_type,
      route_to: props.route_to,
      route_to_name: props.route_to_name,
      token: token,
      createdby: createdby,
      main_admin_uuid: createdby,
      user_uuid: createdby,
    });

    if (options === "") {
      const main_uuid = user_data.main_uuid;
      setLoading(true);
      dispatch(
        getDestinationTypeList(createdby, token, main_uuid, props.route_type)
      )
        .then((response) => {
          setOptions(response.data);
          setLoading(false);
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    }

    let new_data = { ...extensionData };

    function inputonChange(e) {
      let new_data1 = { ...extData };

      let data = { ...availabilityData };

      if (e.target.name === "route_type") {
        new_data1[e.target.name] = e.target.value;
        new_data1["route_to"] = "";
        new_data1["route_to_name"] = "";
        new_data[e.target.name] = e.target.value;
        data["route_type"] = e.target.value;
        data["route_to"] = "";
        getoptions(e.target.value);
      }

      if (e.target.name === "route_to") {
        const { options, selectedIndex } = e.target;
        new_data1[e.target.name] = e.target.value;
        new_data["route_to_name"] = options[selectedIndex].innerHTML;
        data["route_to"] = e.target.value;
        // new_data[e.target.name] = e.target.value;
        new_data1["route_to_name"] = options[selectedIndex].innerHTML;
      }

      props.setParentValue(new_data1);
      setExtData(new_data1);
      extensionData = { ...new_data };
    }

    const getoptions = (type) => {
      const main_uuid = user_data.main_uuid;
      setLoading(true);
      dispatch(getDestinationTypeList(createdby, token, main_uuid, type))
        .then((response) => {
          setOptions(response.data);
          setLoading(false);
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    };

    return (
      <div>
        {loading && <Loader />}
        <div className="row">
          <div className="col-6">
            <div className="form-group ">
              <label>Route To</label>
              <select
                className="form-control"
                name="route_type"
                id={extData}
                onChange={(e) => inputonChange(e)}
                defaultValue={props.route_type}
              >
                <option value="">Select Destination Type</option>
                <option value="ivr">Auto Attendent</option>
                <option value="extention">Extention</option>
                <option value="ring-group">Ring Groups</option>
                <option value="did">DID</option>
                <option value="voicemail">Voicemail</option>
                <option value="time_condition">Time Based Routing</option>
              </select>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label>Destination</label>
              <select
                className="form-control"
                name="route_to"
                id={extData}
                onChange={(e) => inputonChange(e)}
                value={extData.route_to}
              >
                <option value="">Select Destination</option>

                {options &&
                  options.map((number) => (
                    <option key={number.value} value={number.value}>
                      {number.text}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const RouteAndDestination = (props) => {
    if (props.route_type === "ivr") {
      return (
        <span className="text-center ml-5">
          {"Auto Attendent - " + props.route_to_name}
        </span>
      );
    } else if (props.route_type === "extention") {
      return (
        <span className="text-center ml-5">
          {"Extention - " + props.route_to_name}
        </span>
      );
    } else if (props.route_type === "ring-group") {
      return (
        <span className="text-center ml-5">
          {"Ring Group - " + props.route_to_name}
        </span>
      );
    } else if (props.route_type === "did") {
      return (
        <span className="text-center ml-5">
          {"Inbound Number - " + props.route_to_name}
        </span>
      );
    } else if (props.route_type === "voicemail") {
      return (
        <span className="text-center ml-5">
          {"Voicemail - " + props.route_to_name}
        </span>
      );
    } else if (props.route_type === "time_condition") {
      return (
        <span className="text-center ml-5">
          {"Time Condition - " + props.route_to_name}
        </span>
      );
    }
  };

  const fnDeleteTimeSlot = (uuid, user_availability_data_uuid) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this time availability?",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            deleteTimeSlotService(uuid, user_availability_data_uuid),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteTimeSlotService = (uuid, user_availability_data_uuid) => {
    props.deleteTimeSlot(uuid, user_availability_data_uuid);
  };

  const openAddTimeSlotform = (uuid, day) => {
    if (selectDay.day_uuid === "") {
      let select_data_data = { ...selectDay };

      select_data_data["day_uuid"] =
        timeconditionsday[0] !== undefined
          ? timeconditionsday[0].user_availability_data_uuid
          : "";
      select_data_data["day"] =
        timeconditionsday[0] !== undefined
          ? timeconditionsday[0].user_availability_day
          : "";
      select_data_data["extension_uuid"] = extension_uuid;
      setSelectDay(select_data_data);
    }

    if (addTimeSlot === true) {
      setAddTimeSlot(false);
    } else {
      setAddTimeSlot(true);
    }

    // setAddTimeSlotModal(true);
  };

  const createAvailTimeSlot = (timeData) => {
    //call create Avai lTime Slot API

    let from_time = timeConvert24(timeData.from_time);
    let to_time = timeConvert24(timeData.to_time);
    let error_data = { ...errors };

    if (isTimeSlotAvailable(from_time, to_time)) {
      // Add the new time slot to the array
      setAllTimeSlot([...allTimeSlot, { from_time, to_time }]);
      error_data["time_slot_time"] = "";
      dispatch(createTimeSlot(timeData))
        .then((response) => {
          if (response.status === 200) {
            // notifyInfo(response.message);
            props.createAvailTimeSlot(timeData);
            setAddTimeSlot(false);
            props.changeExtensionEvent(Math.random);
          } else if (response.status === 500) {
            // notifyError("Something went wrong.");
          } else {
            // notifyError("Something went wrong.");
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      error_data["time_slot_time"] = "Time slot is not available";
    }
    setErrors(error_data);
  };

  const copyExtTime = (type, uuid, day) => {
    let select_day_uuid = "";
    if (selectDay.day_uuid === "") {
      let select_data_data = { ...selectDay };

      select_data_data["day_uuid"] =
        timeconditionsday[0] !== undefined
          ? timeconditionsday[0].user_availability_data_uuid
          : "";

      select_day_uuid =
        timeconditionsday[0] !== undefined
          ? timeconditionsday[0].user_availability_data_uuid
          : "";

      select_data_data["day"] =
        timeconditionsday[0] !== undefined
          ? timeconditionsday[0].user_availability_day
          : "";
      select_data_data["extension_uuid"] = extension_uuid;
      setSelectDay(select_data_data);
    } else {
      select_day_uuid = selectDay.day_uuid;
    }

    let type_msg = "";
    if (type === "copy_all_weekdays") {
      type_msg = "Copy All weekdays";
    } else if (type === "copy_to_all") {
      type_msg = "Copy To All";
    } else if (type === "copy_to_weekends") {
      type_msg = "Copy To Weekends";
    }

    confirmAlert({
      title: "",
      message: "Are you sure to " + type_msg + "?",
      buttons: [
        {
          label: "Yes",
          onClick: () => copyExtensionService(type, select_day_uuid),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const copyExtensionService = (type, select_day_uuid) => {
    dispatch(copyTimeSlot(type, select_day_uuid, createdby, main_uuid, token))
      .then((response) => {
        if (response.status === 200) {
          // notifyInfo(response.message);
          // setExtensionEvent("delete");
          return;
        } else if (response.status === 500) {
          // notifyError("Something went wrong.");
        } else {
          // notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  function conDateFormate(time) {
    const moment24 = moment(time, "HH:mm");

    return moment24.format("h:mm A");
  }

  const handleToFrom = (e, data) => {
    let data1 = { ...data };

    let id = data.user_availability_times_uuid;

    let value = e.target.value;

    let from_time = "";
    let to_time = "";

    if (e.target.name === "from_time") {
      from_time = timeConvert24(value);
      to_time = data.to_time;
      data1["from_time"] = from_time;
    }

    if (e.target.name === "to_time") {
      to_time = timeConvert24(value);
      from_time = data.from_time;
      data1["to_time"] = to_time;
    }

    let isError = "false";
    const date = "2023-04-04";
    let error_data = { ...errors };
    let f_date = from_time;
    let t_date = to_time;

    const date1 = new Date(date + " " + f_date);
    const date2 = new Date(date + " " + t_date);

    if (date1.getTime() === date2.getTime()) {
      isError = "true";
      error_data["time_slot_time"] = "Time Slot must be 15 minute.";
      setErrors(error_data);
      return false;
    }

    if (date1.getTime() > date2.getTime()) {
      isError = "true";
      error_data["time_slot_time"] =
        "To time should be greater then from time.";
      setErrors(error_data);
      return false;
    } else {
      error_data["time_slot_time"] = "";
      setErrors(error_data);
    }

    // if (e.target.name == "from_time" && e.target.value.length === 0) {
    //   error_data[e.target.name] = e.target.name + " is required.";
    //   setErrors(error_data);
    // } else {
    //   error_data[e.target.name] = "";
    //   setErrors(error_data);
    // }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create user API

      if (isTimeSlotAvailable(f_date, t_date, id)) {
        error_data["time_slot_time"] = "";

        data1["token"] = token;
        data1["createdby"] = createdby;
        data1["main_admin_uuid"] = createdby;
        data1["user_uuid"] = createdby;

        props.saveTimeSlot(data1);
        setAvailabilityData(data1);
      } else {
        isError = "true";
        // error_data["to_time"] = {};
        // error_data["to_time"][select_id] = "Time slot is not available";
        error_data["time_slot_time"] = "Time slot is not available";
      }
    }
  };

  const isTimeSlotAvailable = (from_time, to_time, id = "") => {
    let time_array = allTimeSlot;

    if (id !== "") {
      time_array = time_array.filter(
        (element) => element.user_availability_times_uuid !== id
      );
    }

    for (const slot of time_array) {
      if (
        (from_time >= slot.from_time && from_time < slot.to_time) ||
        (to_time > slot.from_time && to_time <= slot.to_time)
      ) {
        return false; // Time slot is already occupied
      }
    }
    return true; // Time slot is available
  };

  function timeConvert24(time) {
    return moment(time, ["h:mm A"]).format("HH:mm");
  }

  const times = [];
  for (let i = 0; i < 24; i++) {
    times.push(
      `${i % 12 || 12}:00 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:15 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:30 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:45 ${i < 12 ? "AM" : "PM"}`
    );
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                Availability
                <Tooltip placement="top" overlay={<span>Availability</span>}>
                  <span className="ti-info-alt ml-1"></span>
                </Tooltip>
              </h4>
            </div>
            <div className="card-body">
              {timeconditionsday[0] !== undefined && (
                <Row>
                  <Tab.Container
                    // defaultActiveKey={
                    //   timeconditionsday[0] != undefined
                    //     ? timeconditionsday[0].user_availability_data_uuid
                    //     : ""
                    // }
                    defaultActiveKey={defaultDay}
                  >
                    <div className="col-sm-2 col-xl-2 col-xxl-3">
                      <Nav as="ul" className="flex-column m-0 nav-pills mb-3">
                        {timeconditionsday &&
                          timeconditionsday.map((data, i) => (
                            <Nav.Item
                              as="li"
                              key={data.user_availability_data_uuid}
                            >
                              <Nav.Link
                                onClick={() =>
                                  getTimeSlot(
                                    data.user_availability_data_uuid,
                                    data.user_availability_day
                                  )
                                }
                                eventKey={data.user_availability_data_uuid}
                              >
                                {data.user_availability_day.toUpperCase()}
                              </Nav.Link>
                            </Nav.Item>
                          ))}
                      </Nav>
                    </div>
                    <div className="col-sm-10 col-xl-10 col-xxl-9">
                      <Tab.Content className="ml-2">
                        {timeconditionsday &&
                          timeconditionsday.map((data, i) => (
                            <Tab.Pane
                              eventKey={data.user_availability_data_uuid}
                              key={data.user_availability_data_uuid}
                            >
                              <Table responsive className="w-100">
                                <div
                                  id="example_wrapper"
                                  className="dataTables_wrapper"
                                >
                                  <table
                                    id="example"
                                    className="display w-100 dataTable"
                                  >
                                    <thead>
                                      <tr role="row">
                                        <th key={"d" + i} colSpan="4">
                                          {" "}
                                          <span
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                            className="mr-1"
                                          >
                                            {data.user_availability_day}
                                          </span>
                                          Schedule
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {timeSlotOption[0] !== undefined &&
                                        timeSlotOption.map((data1, j) => (
                                          <>
                                            <div key={"fate_" + j}>
                                              {data1.type === "off_hours" ? (
                                                <>
                                                  <div className="row">
                                                    <div className="col-lg-1 col-md-1 col-1"></div>
                                                    <div className="col-lg-10 col-md-10 col-10">
                                                      <i className="fa fa-plus mr-2"></i>
                                                      <label
                                                        className="text text-success mb-2 mr-2"
                                                        onClick={() =>
                                                          openAddTimeSlotform(
                                                            selectDay.user_availability_data_uuid,
                                                            selectDay.user_availability_day,
                                                            addTimeSlot
                                                          )
                                                        }
                                                      >
                                                        Add Time Block
                                                      </label>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </div>

                                            {data1.type === "off_hours" ? (
                                              <>
                                                <tr key={"patel_" + j}>
                                                  {addTimeSlot === true ? (
                                                    <TimeSlotForm
                                                      key={"patell_" + j}
                                                      timeSlot={""}
                                                      destinationTypeOption={""}
                                                      selectDay={selectDay}
                                                      handleTimeSlotEvent={
                                                        handleTimeSlotEvent
                                                      }
                                                      createAvailTimeSlot={
                                                        createAvailTimeSlot
                                                      }
                                                    />
                                                  ) : (
                                                    <></>
                                                  )}
                                                </tr>
                                                <tr>
                                                  <td colSpan={"5"}>
                                                    <span className="text-danger">
                                                      {errors.time_slot_time}
                                                    </span>
                                                  </td>
                                                </tr>
                                              </>
                                            ) : (
                                              <></>
                                            )}

                                            <tr key={"patel1_" + j}>
                                              {data1.type === "off_hours" ? (
                                                <>
                                                  <td colSpan={"3"}>
                                                    <div className="easy-edit-wrapper inline_edit_div h-75">
                                                      <i className="fa fa-building"></i>{" "}
                                                      Off hours
                                                    </div>
                                                  </td>
                                                </>
                                              ) : (
                                                <>
                                                  <td>
                                                    <select
                                                      className="form-control bg-white time_field_h"
                                                      name="from_time"
                                                      onChange={(e) =>
                                                        handleToFrom(e, data1)
                                                      }
                                                      value={conDateFormate(
                                                        data1.from_time
                                                      )}
                                                      id={"from_" + j}
                                                      toNumber={data1.to_time}
                                                    >
                                                      <option value="">
                                                        Select To Time
                                                      </option>
                                                      {times &&
                                                        times.map((number) => (
                                                          <option
                                                            key={number}
                                                            value={number}
                                                          >
                                                            {number}
                                                          </option>
                                                        ))}
                                                    </select>
                                                  </td>
                                                  <td className="pl-0 pr-0">
                                                    {"To"}
                                                  </td>
                                                  <td>
                                                    <select
                                                      className="form-control bg-white time_field_h"
                                                      name="to_time"
                                                      onChange={(e) =>
                                                        handleToFrom(e, data1)
                                                      }
                                                      value={conDateFormate(
                                                        data1.to_time
                                                      )}
                                                      id={j}
                                                    >
                                                      <option value="">
                                                        Select To Time
                                                      </option>
                                                      {times &&
                                                        times.map((time) => (
                                                          <option
                                                            key={time}
                                                            value={time}
                                                          >
                                                            {time}
                                                          </option>
                                                        ))}
                                                    </select>
                                                  </td>
                                                </>
                                              )}

                                              <td className="pr-0">
                                                <EasyEdit
                                                  type={Types.TEXT}
                                                  onSave={(value) => {
                                                    save(
                                                      value,
                                                      "route_and_destination",
                                                      data1.user_availability_times_uuid,
                                                      data1.from_time,
                                                      data1.to_time,
                                                      data1.route_type,
                                                      data1.route_to,
                                                      data1.user_availability_data_uuid
                                                    );
                                                  }}
                                                  editComponent={
                                                    <CustomDisplay
                                                      route_type={
                                                        data1.route_type
                                                      }
                                                      route_to_name={
                                                        data1.route_to_name
                                                      }
                                                      route_to={data1.route_to}
                                                      user_availability_times_uuid={
                                                        data1.user_availability_times_uuid
                                                      }
                                                    />
                                                  }
                                                  displayComponent={
                                                    <RouteAndDestination
                                                      route_type={
                                                        data1.route_type
                                                      }
                                                      route_to_name={
                                                        data1.route_to_name
                                                      }
                                                    />
                                                  }
                                                  saveButtonLabel={
                                                    <i
                                                      className="fa fa-check"
                                                      aria-hidden="true"
                                                    ></i>
                                                  }
                                                  saveButtonStyle="label-primary easy-edit-button rounded-lg"
                                                  cancelButtonLabel={
                                                    <i
                                                      className="fa fa-times"
                                                      aria-hidden="true"
                                                    ></i>
                                                  }
                                                  viewAttributes={{
                                                    className:
                                                      "inline_edit_div",
                                                  }}
                                                  cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                                                  attributes={{
                                                    name: "route_and_destination",
                                                    className:
                                                      "form-control h-75 w-75",
                                                  }}
                                                  onValidate={(value) => {
                                                    if (value.route_to === "") {
                                                      return false;
                                                    } else {
                                                      return true;
                                                    }
                                                  }}
                                                  validationMessage="Please enter route to"
                                                  placeholder="Route and destination"
                                                  value={data1.route_type}
                                                />
                                              </td>

                                              <td>
                                                {data1.type === "default" ? (
                                                  <Button
                                                    to="#"
                                                    className="btn btn-danger shadow btn-xs sharp"
                                                    onClick={() =>
                                                      fnDeleteTimeSlot(
                                                        data1.user_availability_times_uuid,
                                                        data1.user_availability_data_uuid
                                                      )
                                                    }
                                                  >
                                                    <i className="fa fa-trash"></i>
                                                  </Button>
                                                ) : (
                                                  <></>
                                                )}
                                              </td>
                                            </tr>
                                          </>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                              </Table>
                            </Tab.Pane>
                          ))}
                      </Tab.Content>{" "}
                    </div>
                    <div className="card-body mb-2">
                      <div className="row">
                        <div className="col-lg-2 col-md-2 col-2"></div>
                        <div className="col-lg-10 col-md-10 col-10">
                          <div className="ml-5">
                            <i className="fa fa-copy mr-2"></i>
                            <label
                              className="text text-info w500 mb-2 mr-2"
                              onClick={() =>
                                copyExtTime(
                                  "copy_all_weekdays",
                                  selectDay.user_availability_data_uuid,
                                  selectDay.user_availability_day
                                )
                              }
                            >
                              Copy to all weekdays
                            </label>

                            <i className="fa fa-copy ml-2 mr-2"></i>
                            <label
                              className="text text-info w500 mb-2 mr-2"
                              onClick={() =>
                                copyExtTime(
                                  "copy_to_all",
                                  selectDay.user_availability_data_uuid,
                                  selectDay.user_availability_day
                                )
                              }
                            >
                              Copy to all
                            </label>

                            <i className="fa fa-copy ml-2 mr-2"></i>
                            <label
                              className="text text-info w500 mb-2"
                              onClick={() =>
                                copyExtTime(
                                  "copy_to_weekends",
                                  selectDay.user_availability_data_uuid,
                                  selectDay.user_availability_day
                                )
                              }
                            >
                              Copy to weekends
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Container>
                </Row>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Availability;
