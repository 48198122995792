import React, { Fragment, useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";

function BarChart(props) {

   let barLables = props.barLables;
   let bar_data = props.barData;
   let colors = [
      'rgba(52, 57, 87, 1)',
      'rgba(88, 115, 254,1)',
      'rgba(51, 205, 220)',
      'rgba(220, 140, 51)',
      'rgba(156, 51, 220)',

   ];

   const [barDatasets, setBarDatasets] = useState([]);

   useEffect(() => {

      let datasets = [];
      let dataLables = ['Opened','Clicked','Bounced','Unsubscribed','Delivered'];

      dataLables.map((lable,index) => (   
         datasets.push({
            label: lable,
            data: Array(barLables.length).fill(0),
            borderColor: colors[index],
            borderWidth: "0",
            backgroundColor: colors[index],
         })
      ))

      barLables.map((lable,index) => (          

         bar_data.map((row) => {

         if(lable === row.date){            

            datasets.map((set) => {
               if(set.label === 'Opened'){
                  set.data[index] = row.opencount
               }

               if(set.label === 'Clicked'){
                  set.data[index] = row.clickcount
               }
               if(set.label === 'Bounced'){
                  set.data[index] = row.bouncecount
               }
               if(set.label === 'Unsubscribed'){
                  set.data[index] = row.unSubscribecount
               }
               if(set.label === 'Delivered'){
                  set.data[index] = row.deliveredcount
               }
               return true;

            })
         }
       return true;

         })

      ));

      setBarDatasets(datasets);

   },[bar_data])

   

   // const barOptions = {
   //    responsive: true,
   //    tooltips: {
   //      enabled: true,
   //    },
   //    legend: {
   //      position: 'top',
   //    },
   //    title: {
   //      display: true,
   //      text: 'Chart.js Bar Chart',
   //    },
   //  };

   const barOptions = {
      legend: {
         position: 'top',
      },
      scales: {
         yAxes: [
            {
               ticks: {
                  beginAtZero: true,
               },
            },
         ],
         xAxes: [
            {
               // Change here
               barPercentage: 0.5,
            },
         ],
      },
   };
    
      const barData = {
        labels: barLables,
       datasets: barDatasets,
    };


  return (
    <Fragment>
      <Bar data={barData} height={290} options={barOptions} />
    </Fragment>
  );
}

export default BarChart;
