import React, { Fragment, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Card, Modal, Button } from "react-bootstrap";
import { notifyInfo, notifyError } from "../../components/Toastr/Toastr";
import { useNavigate } from "react-router-dom";
import profile from "../../../images/profile/demo_profile.png";
import {
  updateCompanyAndPersonalInfo,
  updateSkipStepAdmin,
} from "../../actions/comman";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import "bootstrap/dist/css/bootstrap.min.css";
import Tooltip from "rc-tooltip";
import { GoogleApiWrapper } from "google-maps-react";
import Loader from "../../layouts/Loader";
const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

function BrandingAndConfiguration(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  let user_data = props.user_data;
  let timeZones = props.timeZones;
  const PROFILE_URL = process.env.REACT_APP_SERVER_PROFILE_URL;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  let brandingDataNew = props.brandingData;
  let token = props.token;

  const closeModel = (arg) => {
    props.closeBACModel(arg);
  };

  const [address, setAddress] = useState(props.brandingData.company_address);
  const [brandingData, setBrandingData] = useState(props.brandingData);
  const [manuallyCheck, setManuallyCheck] = useState("checked");
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({
    company_email: "",
    company_phone_number: "",
    company_address: "",
    company_address2: "",
    city: "",
    state: "",
    postal_code: "",
    phone_number: "",
    time_zone: "",
  });

  const handleBlur = (e) => {
    const newGroupData = { ...brandingData };
    let error_data = { ...errors };

    if (e.target.name === "company_email") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Company email is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "company_phone_number") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Company phone number is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "company_address") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Company address is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "locality") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Suite/Apt is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "city") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "City is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "state") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "state is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "postal_code") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Postal code is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    newGroupData[e.target.name] = e.target.value;
    setBrandingData(newGroupData);
  };

  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);

      let add_data = results[0].address_components;

      const newformdata = { ...brandingData };

      newformdata.company_address = results[0].formatted_address;
      setAddress(results[0].formatted_address);
      add_data.forEach((add, index) => {
        if (
          add.types.find((element) => element === "subpremise") === "subpremise"
        ) {
          newformdata.locality = add.long_name;
        }

        if (
          add.types.find((element) => element === "postal_code") === "postal_code"
        ) {
          newformdata.postal_code = add.long_name;
        }

        if (
          add.types.find(
            (element) => element === "administrative_area_level_1"
          ) === "administrative_area_level_1"
        ) {
          newformdata.state = add.long_name;
        }
        if (
          add.types.find(
            (element) => element === "administrative_area_level_2"
          ) === "administrative_area_level_2"
        ) {
          newformdata.city = add.long_name;
        } else {
          if (
            add.types.find(
              (element) => element === "administrative_area_level_3"
            ) === "administrative_area_level_3"
          ) {
            newformdata.city = add.long_name;
          }
        }
        setBrandingData(newformdata);
      });
    } catch (error) {
      console.error("Error selecting address:", error);
    }
  };

  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);
  const hiddenFileInput1 = useRef(null);
  
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (e) => {
    const MAX_FILE_SIZE = 5120; // 5MB
    if (
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg" ||
      e.target.files[0].type === "image/jpeg"
    ) {
      const fileSizeKiloBytes = e.target.files[0].size / 1024;

      if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        // setErrorMsg("File size is greater than maximum limit");
        notifyError("File size not allow greater then 5MB.");
        return;
      }

      save(e.target.files[0], "file", "company_photo");
    } else {
      notifyError("File allow only waw and mp3.");
      return;
    }
    // const fileUploaded = event.target.files[0];
    // handleFile(fileUploaded);
  };

  const handleChangePro = (e) => {
    const MAX_FILE_SIZE = 5120; // 5MB
    if (
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg" ||
      e.target.files[0].type === "image/jpeg"
    ) {
      const fileSizeKiloBytes = e.target.files[0].size / 1024;

      if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        // setErrorMsg("File size is greater than maximum limit");
        notifyError("File size not allow greater then 5MB.");
        return;
      }
      save(e.target.files[0], "file", "profile_pic");
    } else {
      notifyError("File allow only waw and mp3.");
      return;
    }
    // const fileUploaded = event.target.files[0];
    // handleFile(fileUploaded);
  };

  const save = (value, attribute, field_name) => {
    let formData = new FormData();
    setLoading(true);
    if (attribute === "file") {
      formData.append("file", value);
      formData.append("field_name", field_name);
      formData.append("fileName", value.name);
      formData.append("company_email", brandingData.company_email);
      formData.append("company_profile", brandingDataNew.company_logo);
      formData.append("profile_pic", brandingDataNew.profile_image);
      formData.append(
        "company_phone_number",
        brandingData.company_phone_number
      );
      formData.append("company_address", brandingData.company_address);
      formData.append("company_address2", brandingData.company_address2);
      formData.append("city", brandingData.city);
      formData.append("state", brandingData.state);
      formData.append("postal_code", brandingData.postal_code);
      formData.append("phone_number", brandingData.phone_number);
      formData.append("time_zone", brandingData.time_zone);
      
      formData.append("createdby", createdby);
      formData.append("main_uuid", main_uuid);
      formData.append("user_uuid", brandingData.user_uuid);
      formData.append(attribute, value);
     
    } else {
      formData.append("file", "");
      formData.append("fileName", "");
      formData.append("company_email", brandingData.company_email);
      formData.append("company_profile", brandingDataNew.company_logo);
      formData.append("profile_pic", brandingDataNew.profile_image);
      formData.append(
        "company_phone_number",
        brandingData.company_phone_number
      );
      formData.append("company_address", brandingData.company_address);
      formData.append("company_address2", brandingData.company_address2);
      formData.append("city", brandingData.city);
      formData.append("state", brandingData.state);
      formData.append("postal_code", brandingData.postal_code);
      formData.append("phone_number", brandingData.phone_number);
      formData.append("time_zone", brandingData.time_zone);
     
      formData.append("createdby", createdby);
      formData.append("main_uuid", main_uuid);
      formData.append("user_uuid", brandingData.user_uuid);

      // formData.delete(attribute);

      formData.append(attribute, value);
      
    }

    dispatch(updateCompanyAndPersonalInfo(formData, token))
      .then((res) => {
        if (res.status === 200) {
          notifyInfo(res.message);
          setLoading(false);
          props.brandingEventHandle(Math.random);
        } else if (res.status === 500) {
          setLoading(false);
          notifyError("Something went wrong.");
        } else {
          setLoading(false);
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {});
  };

  const updateCompanyAndPerInfo = (e) => {
    let error_data = { ...errors };
    let isError = "false";
    setLoading(true);

    if (brandingData.company_email.length === 0) {
      error_data["company_email"] = "Company Email is required.";
      setErrors(error_data);
      isError = "true";
    } else {
      if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
          brandingData.company_email
        )
      ) {
        error_data["company_email"] = "Invalid email address.";
        setErrors(error_data);
        isError = "true";
      }
    }

    if (brandingData.company_phone_number.length === 0) {
      error_data["company_phone_number"] = "Company Phone Number is required.";
      setErrors(error_data);
      isError = "true";
    } else {
      if (!/^[0-9]{7,11}$/i.test(brandingData.company_phone_number)) {
        error_data["company_phone_number"] = "Invalid company phone number.";
        setErrors(error_data);
        isError = "true";
      }
    }

    if (brandingData.company_address.length === 0) {
      error_data["company_address"] = "Address is required.";
      setErrors(error_data);
      isError = "true";
    }

    // if (brandingData.company_address2.length === 0) {
    //   error_data["company_address2"] = "Address Line Two is required.";
    //   setErrors(error_data);
    //   isError = "true";
    // }

    if (brandingData.city.length === 0) {
      error_data["city"] = "City host is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (brandingData.state.length === 0) {
      error_data["state"] = "State is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (brandingData.postal_code.length === 0) {
      error_data["postal_code"] = "Ziocode is required.";
      setErrors(error_data);
      isError = "true";
    } else {
      if (!/^[0-9]{5,6}$/i.test(brandingData.postal_code)) {
        error_data["postal_code"] = "Invalid zip code.";
        setErrors(error_data);
        isError = "true";
      }
    }

    if (brandingData.phone_number.length === 0) {
      error_data["phone_number"] = "Phone Number is required.";
      setErrors(error_data);
      isError = "true";
    } else {
      if (!/^[0-9]{7,11}$/i.test(brandingData.phone_number)) {
        error_data["phone_number"] = "Invalid phone number.";
        setErrors(error_data);
        isError = "true";
      }
    }

    if (brandingData.time_zone.length === 0) {
      error_data["time_zone"] = "Time Zone is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create user API
      let data = { ...brandingData };
      // data[attribute] = value;

      data["company_profile"] = brandingDataNew.company_logo;
      data["profile_pic"] = brandingDataNew.profile_image;
      data["createdby"] = createdby;
      data["main_uuid"] = main_uuid;
      // data["token"] = token;

      dispatch(updateCompanyAndPersonalInfo(data, token))
        .then((response) => {
          setBrandingData(data);
          props.handleThreeData(3);
          closeModel(false);
          notifyInfo(response.message);
          user.data.data.is_branding_done = "YES";
          localStorage.setItem("user", JSON.stringify(user));
          setLoading(false);
          navigate("/onboarding", { replace: true });
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const skipBtn = (e) => {
    setLoading(true);
    dispatch(updateSkipStepAdmin(createdby, main_uuid, createdby, token))
      .then((response) => {
        user.data.data.is_branding_done = "YES";
        localStorage.setItem("user", JSON.stringify(user));
        navigate("/onboarding", { replace: true });
        setLoading(false);
        window.location.reload();
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  };

  let profile_src = "";
  if (
    brandingDataNew.profile_image !== "" &&
    brandingDataNew.profile_image !== null
  ) {
    profile_src = PROFILE_URL + brandingDataNew.profile_image;
  } else {
    profile_src = profile;
  }

  let company_logo_src = "";
  if (
    brandingDataNew.company_logo !== "" &&
    brandingDataNew.company_logo !== null
  ) {
    company_logo_src = PROFILE_URL + brandingDataNew.company_logo;
  } else {
    company_logo_src = profile;
  }

  function handleSelectCheck(data) {
    let rec_chk_val = data.target.value;

    if (rec_chk_val !== "" && rec_chk_val === "checked") {
      setManuallyCheck("");
    } else {
      setManuallyCheck("checked");
    }
  }

  // const handleChildData = (dataFromChild) => {
  // let add_data = dataFromChild.address_components;
  // const newformdata = { ...props.formData };
  // newformdata.company_data.company_address = dataFromChild.formatted_address;
  // add_data.forEach((add, index) => {
  //   if (
  //     add.types.find((element) => element == "postal_code") == "postal_code"
  //   ) {
  //     newformdata.company_data.postal_code = add.long_name;
  //   }
  //   if (
  //     add.types.find((element) => element == "administrative_area_level_1") ==
  //     "administrative_area_level_1"
  //   ) {
  //     newformdata.company_data.state = add.long_name;
  //   }
  //   if (
  //     add.types.find((element) => element == "administrative_area_level_2") ==
  //     "administrative_area_level_2"
  //   ) {
  //     newformdata.company_data.city = add.long_name;
  //   } else {
  //     if (
  //       add.types.find(
  //         (element) => element == "administrative_area_level_3"
  //       ) == "administrative_area_level_3"
  //     ) {
  //       newformdata.company_data.city = add.long_name;
  //     }
  //   }
  // });
  // props.handleThreeData(newformdata);
  // };

  return (
    <Fragment>
      {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>Company And Personal Information</Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xl="12">
            <Card className="bgl-primary">
              <Card.Header className="d-block card-header">
                <Card.Title>Company Information</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="row">
                  <div className="col-3">
                    <div className="profile-photo">
                      <img
                        src={company_logo_src}
                        className="img-fluid rounded-circle"
                        alt="Company Logo"
                      />
                    </div>
                    <div className="mt-2">
                      <Tooltip
                        placement="top"
                        overlay={<span>Upload New File</span>}
                      >
                        <button
                          className="button-upload btn btn-sm btn-info ml-2 position-relative"
                          onClick={(e) => hiddenFileInput1.current.click()}
                        >
                          Company Logo
                        </button>
                      </Tooltip>

                      <input
                        type="file"
                        onChange={(e) => handleChange(e)}
                        ref={hiddenFileInput1}
                        //ref={el => (hiddenFileInput = el)}
                        id="company_photo"
                        name="company_photo"
                        accept="image/png, image/gif, image/jpeg"
                        style={{ display: "none" }} // Make the file input element invisible
                      />
                    </div>
                  </div>

                  <div className="col-9">
                    <div className="row">
                      <div className="col-6">
                        <div className="form-group">
                          <label className="mb-1">
                            <strong>Company Email</strong>
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Company Email"
                            name="company_email"
                            id="company_email"
                            onChange={handleBlur}
                            value={brandingData.company_email}
                          />
                          <span className="text-danger">
                            {errors.company_email}
                          </span>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label className="mb-1">
                            <strong>Company Phone Number</strong>
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Company Phone Number"
                            name="company_phone_number"
                            id="company_phone_number"
                            onChange={handleBlur}
                            value={brandingData.company_phone_number}
                          />
                          <span className="text-danger">
                            {errors.company_phone_number}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <label className="text-label">
                            Company Addess
                            <span className="text-danger lr-4">*</span>
                            <span className="ml-4">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="check1"
                                name="is_recurring"
                                onChange={handleSelectCheck}
                                value={manuallyCheck}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="check1"
                              >
                                Enter manually
                              </label>
                            </span>
                          </label>
                        </div>
                        {manuallyCheck === "checked" ? (
                          <PlacesAutocomplete
                            value={address}
                            onChange={(value) => setAddress(value)}
                            onSelect={handleSelect}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <input
                                  {...getInputProps({
                                    placeholder: "Search Places...",
                                    className: "form-control",
                                  })}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion) => (
                                    <div
                                      {...getSuggestionItemProps(suggestion)}
                                      key={suggestion.placeId}
                                    >
                                      {suggestion.description}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        ) : (
                          <div className="form-group">
                            <textarea
                              rows={3}
                              className="form-control"
                              name="company_address"
                              placeholder="address"
                              onChange={handleBlur}
                              value={brandingData.company_address}
                            />
                          </div>
                        )}
                      </div>

                      <div className="col-lg-6 mt-2">
                        <div className="form-group">
                          <label className="text-label">Suite/Apt</label>
                          <span className="text-danger">*</span>
                          <input
                            type="text"
                            className="form-control"
                            name="locality"
                            id="locality"
                            placeholder="Suite/Apt"
                            onChange={handleBlur}
                            value={brandingData.locality}
                          />
                          <span className="text-danger">{errors.locality}</span>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="text-label">City</label>
                          <span className="text-danger">*</span>
                          <input
                            type="text"
                            className="form-control"
                            name="city"
                            id="city"
                            placeholder=""
                            onChange={handleBlur}
                            value={brandingData.city}
                          />
                          <span className="text-danger">{errors.city}</span>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="text-label">State</label>
                          <span className="text-danger">*</span>
                          <input
                            type="text"
                            className="form-control"
                            name="state"
                            id="state"
                            placeholder=""
                            onChange={handleBlur}
                            value={brandingData.state}
                          />
                          <span className="text-danger">{errors.state}</span>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="text-label">Postal code</label>
                          <span className="text-danger">*</span>
                          <input
                            type="text"
                            className="form-control"
                            name="postal_code"
                            id="postal_code"
                            placeholder=""
                            onChange={handleBlur}
                            value={brandingData.postal_code}
                          />
                          <span className="text-danger">
                            {errors.postal_code}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl="12">
            <Card className="bgl-primary">
              <Card.Header className="d-block card-header">
                <Card.Title>Personal Information</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="row">
                  <div className="col-3">
                    <div className="profile-photo">
                      <img
                        src={profile_src}
                        className="img-fluid rounded-circle"
                        alt="profile"
                      />
                    </div>

                    <div className="mt-2">
                      <Tooltip
                        placement="top"
                        overlay={<span>Upload New File</span>}
                      >
                        <button
                          className="button-upload btn btn-sm btn-info ml-4 position-relative"
                          onClick={(e) => hiddenFileInput.current.click()}
                        >
                          Profile Pic
                        </button>
                      </Tooltip>

                      <input
                        type="file"
                        onChange={(e) => handleChangePro(e)}
                        ref={hiddenFileInput}
                        name="profile_upload"
                        //ref={el => (hiddenFileInput = el)}
                        id="profile_upload"
                        accept="image/png, image/gif, image/jpeg"
                        style={{ display: "none" }} // Make the file input element invisible
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <label className="mb-1">
                            <strong>Phone Number</strong>
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Phone Number"
                            name="phone_number"
                            id="phone_number"
                            onChange={handleBlur}
                            value={brandingData.phone_number}
                          />
                          <span className="text-danger">
                            {errors.phone_number}
                          </span>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label className="mb-1">
                            <strong>Email</strong>
                          </label>
                          <label className="mb-1">
                            <strong>{brandingData.email}</strong>
                          </label>
                          <span className="text-danger">{errors.email}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="row mb-2">
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>Time Zone</strong>
                          <span className="text-danger">*</span>
                        </label>

                        <select
                          className="form-control"
                          value={brandingData.time_zone}
                          name="time_zone"
                          onChange={handleBlur}
                        >
                          <option value="">Select Time Zone</option>
                          {timeZones.map((timeZone) => (
                            <option key={timeZone.value} value={timeZone.value}>
                              {timeZone.label}
                            </option>
                          ))}
                        </select>
                        <span className="text-danger">{errors.time_zone}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <div className="row text-right">
          <div className="col-sm">
            <Button
              type="button"
              variant="primary"
              className="btn-sm mr-2"
              onClick={(e) => updateCompanyAndPerInfo()}
            >
              Save and Continue
            </Button>
            <Button
              type="button"
              onClick={(e) => skipBtn()}
              variant="primary"
              className="btn-sm"
            >
              Skip
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Fragment>
  );
}

export default GoogleApiWrapper({
  apiKey: API_KEY,
})(BrandingAndConfiguration);

// export default BrandingAndConfiguration;
