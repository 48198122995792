import React, { Fragment, useState, useEffect } from "react";
import { Button,Tab, Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../../layouts/PageTitle";
import SectionOverview from "./SectionOverview";
import ContactCustomFieldForm from "./ContactCustomFieldForm";
import { notifyInfo, notifyError } from "../../Toastr/Toastr";
import { createContact, getContactPersona, getContactTags } from "../../../actions/contacts";
import { getContactFormLayout } from "../../../actions/customContactLayout";
import { getUserListDropdown } from "../../../actions/comman";
import PermissionCheck from "../../../common/PermissionCheck";
import validator from 'validator'
import axios from "axios";
import Loader from "../../../layouts/Loader";

function ContactForm(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;

  let listing_per = PermissionCheck("did", "listing", "", "", "");

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }

  const [formData, setFormData] = useState([]);

  const [users, setUsers] = useState([]);
  const [personaOptions, setPersonaOptions] = useState([]);
  const [tagsOptions, setTagsOptions] = useState([]);

  const [contactData, setContactData] = useState({
    contact_type: "Individual",
    bussiness_name: "",
    contact_title: "",
    first_name: "",
    last_name: "",
    email: "",
    work_contact_number: "",
    other_contact_number: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
    address: "",
    address2: "",
    custom_field: "NO",
    assign_user: createdby,
    custom_field_data: {},
    createdby: createdby,
    main_user_uuid: main_uuid,
    user_uuid: createdby,
    bussiness_site : "",
    job_title : "",
    job_industry : "",    
    facebook_url : "",
    linked_in_url : "",
    twitter_url : "",
    instagram_url : "",
    site_favicon : "",
    persona : "",
    tags : "",
    selectedPersona : "",
    selectedTags : "",
    dependent_field : "NO",
    dependent_field_data: {},
  });
  
  const [errors, setErrors] = useState({
    contact_type: "",
    email: "",
    work_contact_number: "",
  });
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(
      getContactFormLayout(
        main_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        //console.log(response);
        setFormData(response.data);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);


  // useEffect(() => {
  //   let search = "";
  //   let offSet = "";
  //   let limits = "";
  //   let orderby = "created_at DESC";

  //   dispatch(
  //     getCustomFieldList(
  //       search,
  //       offSet,
  //       limits,
  //       orderby,
  //       main_uuid,
  //       createdby,
  //       token
  //     )
  //   )
  //     .then((response) => {
  //       console.log(response.data.data);
  //       setCustomFields(response.data.data);
  //       return;
  //     })
  //     .catch(() => {
  //       setLoading(false);
  //     });
  // }, []);

  useEffect(() => {
    dispatch(
      getUserListDropdown(
        "create",
        "",
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        setUsers(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    dispatch(
      getContactPersona(
        main_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        //console.log(response.data.data);
        setPersonaOptions(response.data.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    dispatch(
      getContactTags(
        main_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        //console.log(response.data.data);
        setTagsOptions(response.data.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const handleBlur = (e) => {
    const newContactData = { ...contactData };
    let error_data = { ...errors };

    if(e.target.name === "bussiness_site"){
      if (validator.isURL(e.target.value)) {
        getFavicon(e.target.value);
      } else {
        error_data[e.target.name] = "URL is not valid";
        setErrors(error_data);
      }      
    }

    if (
      (e.target.name === "contact_type" ||
        e.target.name === "email" ||
        e.target.name === "work_contact_number") &&
      e.target.value.length === 0
    ) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {

      if (e.target.name === "email") {

        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
          error_data[e.target.name] = "Email is invalid.";
          setErrors(error_data);
        } else {
          error_data[e.target.name] = "";
          setErrors(error_data);
        }
      }else{
        error_data[e.target.name] = "";
        setErrors(error_data);
      }

    }
    newContactData[e.target.name] = e.target.value;
    setContactData(newContactData);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (contactData.contact_type.length === 0) {
      error_data["contact_type"] = "Contact type is required.";
      setErrors(error_data);
      isError = "true";
    }

    
    if (contactData.email.length === 0) {
      error_data["email"] = "Email is required.";
      setErrors(error_data);
      isError = "true";
    }else {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(contactData.email)) {
        error_data["email"] = "Email is invalid.";
        setErrors(error_data);
      } else {
        error_data["email"] = "";
        setErrors(error_data);
      }
    }

    if (contactData.work_contact_number.length === 0) {
      error_data["work_contact_number"] = "Work contact is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (contactData.assign_user.length === 0) {
      error_data["assign_user"] = "Please select assign user.";
      setErrors(error_data);
      isError = "true";
    }
    //return false;

    if (isError === "true") {
      setLoading(false);
      return;
    } else {

      //return false;
      //call create user API
      dispatch(createContact(contactData,token))
        .then((res) => {
          if (res.status === 200) {
            notifyInfo(res.message);
            navigate("/contacts", { replace: true });
          } else if (res.status === 406) {
            error_data["work_contact_number"] = res.message;
            setErrors(error_data);
            isError = "true";
          } else if (res.status === 500) {
            notifyError("Something went wrong.");
          } else {
            notifyError("Something went wrong.");
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const handlecustomFieldData = (name, value) => {
    
    const newContactData = { ...contactData };
    newContactData["custom_field_data"][name] = value;
    newContactData["custom_field"] = "YES";

    setContactData(newContactData);
  };

  const handleDependentFieldData = (data, value) => {

    let arr = {
      value : value,
      main_field_uuid : data.main_field_uuid,
      contact_custom_dependent_field_uuid : data.contact_custom_dependent_field_uuid
    }
    
    const newContactData = { ...contactData };
    newContactData["dependent_field_data"][data.contact_custom_dependent_field_uuid] = arr;
    newContactData["dependent_field"] = "YES";

    setContactData(newContactData);
  };

  const handleSelectPersona = (data) => {
    
    const newContactData = { ...contactData };
    if(data){
      newContactData['persona'] = data.value;
    }else{
      newContactData['persona'] = '';
    }    
    newContactData['selectedPersona'] = data;
    setContactData(newContactData);
    //setSelectedPersona(data);
    
  }

  const handleSelectTags = (data) => {

    let values = Object.values(data).map(k => k['value']);
    
    const newContactData = { ...contactData };
    if(data){
      newContactData['tags'] = values;
    }else{
      newContactData['tags'] = '';
    }    

    newContactData['selectedTags'] = data;
    setContactData(newContactData);

  }

  const getFavicon = async (url) =>{

    try {
      const {
        data: { icons }
      } = await axios.get('http://www.google.com/s2/favicons?domain='+url);
      if (icons[0].src !== "") {

        const newarr = { ...contactData };

        newarr["site_favicon"] = icons[0].src;

        setContactData(newarr);

          //setFavicon(icons[0].src);
      } else {
        //throw new Error("undefined url");
      }
    } catch (error) {
      //setError(true);
    }

  }

  const breadCurmList = [
    { label: "Home", link: "/", is_active: "NO" },
    { label: "Contacts", link: "/contacts", is_active: "NO" },
    { label: "Create New Contact", link: "", is_active: "YES" },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />
        <form action="" onSubmit={(e) => submitHandler(e)}>

          {formData.map((row) => (
            <span key={row.contact_form_section_uuid}>
             
              <Tab.Container defaultActiveKey={row.section_type === 'overview'? 'overview' : row.tabs.length > 0 ? row.tabs[0].contact_form_tab_uuid : ''}>
                <Nav as='ul' className='nav-tabs ringtabs mt-4'>
                    
                    {row.section_type === 'overview' && 
                      <>
                      <Nav.Item as='li' key="1">
                        <Nav.Link eventKey="overview">
                            {row.section_title}
                        </Nav.Link>
                      </Nav.Item>
                      
                      </>
                    }                 

                    {row.tabs.map((tab) => (
                      <Nav.Item as='li' key={tab.contact_form_tab_uuid}>
                        <Nav.Link eventKey={tab.contact_form_tab_uuid} >
                            {tab.tab_name}
                            
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                
                </Nav>
          
                <Tab.Content className='ringgroup'>
                    
                    {row.section_type === 'overview' && 
                      (
                        <Tab.Pane eventKey="overview" key="1" className="pt-4" >
                            <SectionOverview handleBlur={handleBlur} errors={errors} contactData={contactData} users={users} personaOptions={personaOptions} handleSelectPersona={handleSelectPersona} handleSelectTags={handleSelectTags} tagsOptions={tagsOptions}/>
                        </Tab.Pane>
                        
                      )
                    }

                    {row.tabs.map((tab) => ( 
                    
                      <Tab.Pane eventKey={tab.contact_form_tab_uuid} key={tab.contact_form_tab_uuid} className="pt-4" >
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-6" >
                              <div className="card">
                                  <div className="card-body">
                                    {tab.sec_one_fileds.map((field) => (

                                      <span key={field.custom_field_uuid}>
                                        <ContactCustomFieldForm  field={field} handlecustomFieldData={handlecustomFieldData} contactFiledData={contactData.custom_field_data} handleDependentFieldData={handleDependentFieldData} dependent_field_data={contactData.dependent_field_data} />
                                      </span>
                                      
                                    ))}
                                  

                                  </div>
                              </div>
                          </div>

                          <div className="col-lg-6 col-md-6 col-6" >
                              <div className="card">
                                  <div className="card-body">
                                    {tab.sec_two_fileds.map((field) => (
                                      <span key={field.custom_field_uuid}>
                                        <ContactCustomFieldForm  field={field}  handlecustomFieldData={handlecustomFieldData} contactFiledData={contactData.custom_field_data} handleDependentFieldData={handleDependentFieldData} dependent_field_data={contactData.dependent_field_data} />
                                      </span>
                                    ))}
                                    

                                  </div>
                              </div>
                          </div>
                          
                        </div>
                      </Tab.Pane>

                    ))}

                </Tab.Content>

              </Tab.Container>
            
            </span>
        ))}

        <div className="row text-right">
          <div className="col-sm">
            <Button type="submit" variant="primary btn-sm">
              Save
            </Button>
          </div>
        </div>

      </form>
    </Fragment>
  );
}

export default ContactForm;
