import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const getCompanySignatureList = (main_uuid,createdby,signature_type,token) => {
  return axios
    .post(API_URL + "emails/get-company-signature-list", {main_uuid,createdby,signature_type}, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      
      return response.data;
    });
};

const createEmailSignature = (data,token) => {
  return axios
    .post(API_URL + "emails/create-email-signature", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      
      return response.data;
    });
};

const updateEmailSignature = (data,token) => {
  return axios
    .post(API_URL + "emails/update-email-signature", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      
      return response.data;
    });
};

const deleteEmailSignature = (data,token) => {
  return axios
    .post(API_URL + "emails/delete-email-signature", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      
      return response.data;
    });
};

const assignSignatureToUser = (data,token) => {
  return axios
    .post(API_URL + "emails/assign-signature-to-user", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      
      return response.data;
    });
};

const getUserEmailSignature = (createdby,user_uuid,token) => {
  return axios
    .post(API_URL + "emails/get-user-email-signature", {createdby,user_uuid}, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      
      return response.data;
    });
};

const getUserSignatureList = (createdby,user_uuid,token) => {
  return axios
    .post(API_URL + "emails/get-user-signature-list", {createdby,user_uuid}, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      
      return response.data;
    });
};

const handleSignaturePermission = (data,token) => {
  return axios
    .post(API_URL + "emails/handle-signature-permission", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      
      return response.data;
    });
};



export default {
  getCompanySignatureList,
  createEmailSignature,
  updateEmailSignature,
  deleteEmailSignature,
  assignSignatureToUser,
  getUserEmailSignature,
  getUserSignatureList,
  handleSignaturePermission
 
};
