import {
    SET_MESSAGE,
    API_FAIL,
    API_SUCCESS,
  } from "./types";

  import CustomFieldService from "../services/customfield.service";


  export const getCustomFieldList = (search,off_set,limits,orderby,main_uuid,createdby,token) => (dispatch) => {
    return CustomFieldService.getCustomFieldList(search,off_set,limits,orderby,main_uuid,createdby,token).then(
      (data) => {

        //console.log("action :",data.status);
        
        if(data.status !== 200){
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        }
  
        dispatch({
          type: API_SUCCESS,
          payload: { user: data },
        });
  
        return data;
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const createCustomField = (data,token) => (dispatch) => {
    return CustomFieldService.createCustomField(data,token).then(
      (data) => {
        
        if(data.status !== 200){
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        }
  
        dispatch({
          type: API_SUCCESS,
          payload: { user: data },
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const updateCustomField = (req_data,token) => (dispatch) => {
    
    return CustomFieldService.updateCustomField(req_data,token).then(
      (data) => {
        
        if(data.status !== 200){
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        }
  
        dispatch({
          type: API_SUCCESS,
          payload: { user: data },
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const getCutomFieldDetail = (createdby,token,custom_field_uuid) => (dispatch) => {
    return CustomFieldService.getCutomFieldDetail(createdby,token,custom_field_uuid).then(
      (data) => {
        
        if(data.status !== 200){
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        }
  
        dispatch({
          type: API_SUCCESS,
          payload: { user: data },
        });
  
        return data;
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };


  export const deleteCustomField = (custom_field_uuid,createdby,token) => (dispatch) => {
    return CustomFieldService.deleteCustomField(custom_field_uuid,createdby,token).then(
      (data) => {
        
        if(data.status !== 200){
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        }
  
        dispatch({
          type: API_SUCCESS,
          payload: { user: data },
        });
        return data;
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const getDependentFields = (createdby,token,custom_field_uuid,custom_field_value) => (dispatch) => {
    return CustomFieldService.getDependentFields(createdby,token,custom_field_uuid,custom_field_value).then(
      (data) => {
        
        if(data.status !== 200){
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        }
  
        dispatch({
          type: API_SUCCESS,
          payload: { user: data },
        });
  
        return data;
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };


