import React from 'react';
import { Link } from "react-router-dom";

const AttachMents = ({
    file
})  => {

    let label = file ? file.filename : '';

    const bytesToSize = (bytes) => {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }

    const download = (e,file,label) => {
        e.preventDefault();

        const buffer = Buffer.from(file.content);
        const blob = new Blob([buffer]);
                                
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.target = '_self';
        a.download = label;
        a.click();
        window.URL.revokeObjectURL(url);
    }

    return (

        <div className="widget-stat card">
            <div className="card-body ">
                <div className="media ai-icon">
                    
                    <div className="media-body">
                        <p className="mb-1">{label} ({bytesToSize(file? file.size : '')})</p>
                    </div>
                    
                    <Link
                        to="#"
                        className="text-primary ml-2 h4"
                        onClick={(e) => download(e,file,label)}
                    >
                        <i className='fa fa-download'></i>
                    </Link>
                  
                </div>
            </div>
        </div>

    )
}

export default AttachMents;