import React, {Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Table } from "react-bootstrap";
import {
  getContactActivity
} from "../../actions/contacts";
import Pagination from "../../layouts/Pagination";
import Loader from "../../layouts/Loader";

const Activites = ({contact}) => {

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  
  const [contactActivity, setContactActivity] = useState([]);
  const [activityEvent, setActivityEvent] = useState(false);
  
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [pageCount, setpageCount] = useState(0);
  const [offSet, setOffSet] = useState(0);
  const limits = 10;
  
  useEffect(() => {
    setLoading(true);
    dispatch(
      getContactActivity(
        createdby,
        token,
        contact.contact_uuid,
        offSet,
        limits,
      )
    )
    .then((response) => {
      setContactActivity(response.data.data);

      const total = response.data.total;

      setpageCount(Math.ceil(total / limits));

      setLoading(false);
      return;
    })
    .catch(() => {
      setLoading(false);
    });

  }, [contact,activityEvent]);

  const handlePageClick = async (data) => {

    setOffSet(limits * data.selected);
    setActivityEvent(Math.random());
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <div className="card">
        <div className="card-body">
        
          <Table responsive className="w-100">
            <thead>
              <tr role="row">
                <th>Type</th>
                <th>Date Created</th>
                <th>Description</th>
              </tr>
            </thead>

            {contactActivity && contactActivity.map((row) => (
                <tbody key={row.contact_activity_uuid}>
                  <tr role="row">
                    <td>{row.activity_type.replace(/_/g, ' ').toLowerCase()} </td>
                    <td>{row.datetime} </td>
                    <td>{row.description} </td>
                  </tr>
                </tbody>
            ))}

          </Table>

          <Pagination
            pageCount={pageCount}
            handlePageNumberClick={handlePageClick}
          />
        </div>
      </div>

    </Fragment>    
  );
};

export default Activites;
