import { SET_MESSAGE, API_FAIL } from "./types";

import SmsService from "../services/contactsNotes.service";

export const getNotesList =
  (
    contact_uuid,
    main_uuid,
    createdby,
    token
  ) =>
  (dispatch) => {
    return SmsService.getNotesList(
      contact_uuid,
      main_uuid,
      createdby,
      token
    ).then(
      (data) => {
        if (
          data.status === 406 ||
          data.status === 500 ||
          data.status === 200
        ) {
          return data;
        } else {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

  export const createNote = (userData,token) => (dispatch) => {
    return SmsService.createNote(userData,token).then(
      (data) => {
        if (data.status === 406) {
          return data;
        } else if (data.status !== 200) {
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        } else {
          return data;
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const updateNote = (note_uuid,note, main_uuid, createdby, token) => (dispatch) => {
    return SmsService.updateNote(note_uuid,note, main_uuid, createdby, token).then(
      (data) => {
        if (data.status === 406) {
          return data;
        } else if (data.status !== 200) {
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        } else {
          return data;
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const deleteNote = (note_uuid, createdby, token) => (dispatch) => {
    return SmsService.deleteNote(note_uuid, createdby, token).then(
      (data) => {
        if (data.status === 406) {
          return data;
        } else if (data.status !== 200) {
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        } else {
          return data;
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  
