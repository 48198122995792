import React, { Fragment, useState, useEffect } from "react";
import { Tab, Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../layouts/PageTitle";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import { createIvr, getDestinationList, getNextIvrID } from "../../actions/ivr";
import { getRecordingList } from "../../actions/recording";
import GeneralFieldsForm from "./GeneralFieldsForm";
import "./style.css";
import Loader from "../../layouts/Loader";

function IvrForm(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const [ivrData, setIvrData] = useState({
    ivr_menu_name: "",
    ivr_menu_extension: "",
    ivr_menu_welcome_message: "",
    //ivr_menu_greet_short: "",
    ivr_menu_timeout: "5",
    ivr_menu_exit_action: "",
    ivr_menu_direct_dial: "FALSE",
    ivr_menu_cid_prefix: "",
    ivr_menu_invalid_sound: "",
    ivr_menu_exit_sound: "",
    ivr_menu_pin_number: "",
    ivr_menu_max_failures: "",
    ivr_menu_max_timeouts: "",
    ivr_menu_max_attempt: "3",
    ivr_menu_invalid_destination: "00000000-0000-0000-0000-000000000000",
    ivr_menu_invalid_destination_type: "",
    ivr_menu_enabled: "TRUE",
    token: token,
    createdby: createdby,
    main_admin_uuid: main_uuid,
    user_uuid: createdby,
  });

  const [errors, setErrors] = useState({
    ring_group_name: "",
    ring_group_extension: "",
    ivr_menu_welcome_message: "",
  });

  const [loading, setLoading] = useState(false);
  const [recordings, setRecordings] = useState([]);
  const [destination, setDestinations] = useState([]);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  // const addOptionHandler = (data) => {
  //   const newIvrdata = { ...ivrData };
  //   newIvrdata["ivr_menu_options"] = data;
  //   setIvrData(newIvrdata);
  // };

  const handleBlur = (e) => {
    const newIvrdata = { ...ivrData };
    let error_data = { ...errors };

    if (
      (e.target.name === "ivr_menu_name" ||
        e.target.name === "ivr_menu_extension" ||
        e.target.name === "ivr_menu_welcome_message") &&
      e.target.value.length === 0
    ) {
      let message = "";
      if (e.target.name === "ivr_menu_name") {
        message = "Auto attendant name is required.";
      }
      if (e.target.name === "ivr_menu_welcome_message") {
        message = "Welcome message is required.";
      }

      error_data[e.target.name] = message;
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }

    if (
      e.target.name === "ivr_menu_invalid_destination" &&
      e.target.value.length > 0
    ) {
      const value_arr = e.target.value.split("_");

      newIvrdata["ivr_menu_invalid_destination"] = value_arr[1];
      newIvrdata["ivr_menu_invalid_destination_type"] = value_arr[0];
    } else if (e.target.name === "ivr_menu_direct_dial") {
      let default_val = ivrData.ivr_menu_direct_dial;
      let value = "";
      if (default_val === "TRUE") {
        value = "FALSE";
      } else {
        value = "TRUE";
      }

      newIvrdata["ivr_menu_direct_dial"] = value;
    } else {
      newIvrdata[e.target.name] = e.target.value;
    }

    setIvrData(newIvrdata);
  };

  useEffect(() => {
    dispatch(getRecordingList(createdby, token, "ivr"))
      .then((response) => {
        //setRecordings(response.data.data);

        if (response.data.data !== "") {
          let arrayObj = {};
          let i = 0;
          arrayObj = response.data.data.map((item) => {
            i = i + 1;
            return {
              label: item.recording_name,
              value: item.recording_uuid,
            };
          });

          // let ddd = {
          //   label: "Add New File",
          //   value: "file_new_upload",
          // };
          // arrayObj[i] = ddd;

          setRecordings(arrayObj);
        }
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const main_uuid = user_data.main_uuid;

    dispatch(getDestinationList(createdby, token, main_uuid))
      .then((response) => {
        let groupedOptions = [];
        response.data.forEach((option) => {
          if (!groupedOptions[option.optgroup])
            groupedOptions[option.optgroup] = [];
          groupedOptions[option.optgroup].push({
            value: option.value,
            text: option.text,
          });
        });

        setDestinations(groupedOptions);

        //setDestinations(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const main_uuid = user_data.main_uuid;

    dispatch(getNextIvrID(main_uuid, token, createdby))
      .then((response) => {
        let newData = { ...ivrData };
        newData["ivr_menu_extension"] = response.data.extension;
        setIvrData(newData);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (ivrData.ivr_menu_name.length === 0) {
      error_data["ivr_menu_name"] = "Auto attendant name is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (ivrData.ivr_menu_welcome_message.length === 0) {
      error_data["ivr_menu_welcome_message"] = "Welcome message is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (!/^[0-9\s]*$/i.test(ivrData.ivr_menu_timeout)) {
      error_data["ivr_menu_timeout"] = "Invalid ivr menu timeout.";
      setErrors(error_data);
      isError = "true";
    }

    // if (!/^[0-9\s]*$/i.test(ivrData.ivr_menu_pin_number)) {
    //   error_data["ivr_menu_pin_number"] = "Invalid ivr menu pin number.";
    //   setErrors(error_data);
    //   isError = "true";
    // }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create user API
      dispatch(createIvr(ivrData))
        .then((res) => {
          if (res.status === 200) {
            notifyInfo(res.message);
            let ivr_menu_uuid = res.data;
            setLoading(false);
            navigate("/update-ivr", {
              replace: true,
              state: {
                ivr_menu_uuid: ivr_menu_uuid,
                activeTab: "keypad_options",
              },
            });
          } else if (res.status === 500) {
            setLoading(false);
            notifyError("Something went wrong.");
          } else {
            setLoading(false);
            notifyError("Something went wrong.");
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const show_alert = (e) => {
    e.preventDefault();
    alert("Please save general information.");
  };

  const breadCurmList = [
    { label: "Calls", link: "/", is_active: "NO" },
    { label: "Auto Attendent", link: "/ivr", is_active: "NO" },
    { label: "Add New Auto Attendent", link: "", is_active: "YES" },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />

      <Tab.Container defaultActiveKey="general">
        <Nav as="ul" className="nav-tabs ringtabs mt-4">
          <Nav.Item as="li" key="1">
            <Nav.Link eventKey="general">
              <i className={`la la-tty mr-2`} />
              General
            </Nav.Link>
          </Nav.Item>

          <Nav.Item as="li" key="2">
            <Nav.Link onClick={(e) => show_alert(e)}>
              <i className={`fa fa-th mr-2`} />
              Keypad Options
            </Nav.Link>
          </Nav.Item>

          {/* <Nav.Item as='li' key="3">
              <Nav.Link onClick={(e) =>show_alert(e)}>
                  <i className={`la la-envelope mr-2`} />
                  Advanced Options                  
              </Nav.Link>
              </Nav.Item> */}
        </Nav>

        <Tab.Content className="ringgroup">
          <Tab.Pane eventKey="general" key="1" className="pt-4">
            <GeneralFieldsForm
              ivrData={ivrData}
              recordings={recordings}
              destination={destination}
              errors={errors}
              handleBlur={handleBlur}
              submitHandler={submitHandler}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Fragment>
  );
}

export default IvrForm;
