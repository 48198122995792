import React, { Fragment, useState, useEffect} from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  getContactGroupList,
} from "../../../actions/callcampaign";
import Loader from "../../../layouts/Loader";

function MoveContactForm(props) {

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  
  const closeModel = (arg) => {
    props.closeModel(arg);
  };

  const dispatch = useDispatch();

  const [groupData, setGroupData] = useState({
    group_uuid : "",
    type : "move",
  });

  const [groupError, setGroupError] = useState('');

  const [loading, setLoading] = useState(false);
  const [contactGroupList, setContactGroupList] = useState([]);

  useEffect(() => {
    setLoading(true);
    dispatch(getContactGroupList(main_uuid, createdby, token))
      .then((response) => {
        setContactGroupList(response.data);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const handleBlur = (e) => {
    if(e.target.value === ''){
      
        setGroupError('Please select group .');
      
    }else{
      let new_arr = {
        group_uuid : "",
        type : "move",
      };

      new_arr[e.target.name] = e.target.value;

      setGroupData(new_arr);
      setGroupError('');
    }
    
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if(groupData.group_uuid === ''){
      setGroupError('Please select group.');
    }else{
      props.moveContact(groupData);
      closeModel(false);
    }    
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>Move Contacts</Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <form action="" onSubmit={(e) => submitHandler(e)}>
        <Modal.Body>
          <div className="row">           
            <div className="col-sm">
              {" "}
              
              <div className="form-group input-primary">
                <label className="mb-1">
                  <strong>Select Group</strong>
                  <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control bg-white"
                  name="group_uuid"
                  id="group_uuid"
                  onChange={handleBlur}
                >
                  <option value="">Select Group</option>
                  {contactGroupList.map((group) => (
                    <option value={group.value} key={group.value}>{group.label}</option>
                  ))}
                </select>
                <span className="text-danger">{groupError}</span>
              </div>

            </div>
            
          </div>
         
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => closeModel(false)} variant="danger light btn-sm">
            Close
          </Button>
          <Button type="submit" value = "save" variant="primary btn-sm">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Fragment>
  );
}

export default MoveContactForm;
