import React, { Fragment, useState, useEffect } from "react";
import { Table, Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import UserUpdateForm from "./UserUpdateForm";
import Loader from "../../layouts/Loader";
import { getUsersRole, getUserDetail } from "../../actions/user";

function UsersRole(props) {
  const [addUserModal, setAddUserModal] = useState(false);
  const [UserEvent, setUserEvent] = useState("");

  const handleUserEvent = (event) => {
    setUserEvent(event);
  };

  const closeModel = (value) => {
    setAddUserModal(value);
  };

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [userData, setUserData] = useState([]);

  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  let search = "";
  let off_set = 0;
  let status = "ACTIVE";
  let limits = 10;
  let orderby = "first_name ASC";
  let role_uuid = props.role_uuid;

  useEffect(() => {
    dispatch(
      getUsersRole(
        search,
        off_set,
        status,
        limits,
        orderby,
        role_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        setUsers(response.data.data);
        setUserEvent("");
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, [UserEvent]);

  const showUpdateForm = (useruuid) => {
    dispatch(getUserDetail(useruuid, createdby, token))
      .then((response) => {
        setUserData(response.data);
        setAddUserModal(true);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Manage Users</h4>
              {/* <Button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={() => setAddUserModal(true)}
              >
                Add New User
              </Button> */}
            </div>

            <div className="card-body">
              <Table responsive className="w-100">
                <div id="example_wrapper" className="dataTables_wrapper">
                  <table id="example" className="display w-100 dataTable">
                    <thead>
                      <tr role="row">
                        <th>Name</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {users.map((user) => (
                        <tr key={user.uuid}>
                          <td>
                            {user.first_name} {user.last_name}
                          </td>
                          <td>{user.email}</td>
                          <td>{user.status}</td>
                          <td>
                            <Fragment>
                              <div className="d-flex">
                                <Button
                                  to="#"
                                  className="btn btn-primary shadow btn-xs sharp mr-1"
                                  onClick={() => showUpdateForm(user.uuid)}
                                >
                                  <i className="fa fa-pencil"></i>
                                </Button>
                                {/* <Button className="btn btn-danger shadow btn-xs sharp">
                                  <i className="fa fa-trash"></i>
                                </Button> */}
                              </div>
                            </Fragment>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Table>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- add role Modal --> */}
      <Modal className="fade" show={addUserModal}>
        <UserUpdateForm
          user={userData}
          closeModel={closeModel}
          handleUserEvent={handleUserEvent}
        />
      </Modal>
    </Fragment>
  );
}

export default UsersRole;
