import React, { Fragment } from "react";
import { Pie } from "react-chartjs-2";



function PieChart(props) {

   let reportData = props.reportData;

  const pieData = {
    datasets: [
       {
          data: [reportData.clickcount, reportData.opencount, reportData.deliveredcount],
          borderWidth: 0,
          backgroundColor: [
             `${"rgba(89, 59, 219, 0.9)"}`,
             `${"rgba(89, 59, 219, 0.7)"}`,
             `${"rgba(89, 59, 219, 0.5)"}`,
          ],
          hoverBackgroundColor: [
             `${"rgba(89, 59, 219, 0.9)"}`,
             `${"rgba(89, 59, 219, 0.7)"}`,
             `${"rgba(89, 59, 219, 0.5)"}`,
          ],
       },
    ],
    labels: ["Recipients who Clicked", "Total Opens", "Successfull Deliveries"],
 };

 const pieOptions = {
    responsive: true,
    legend: {
      position: 'top',
   },
    maintainAspectRatio: false,
 };


  return (
    <Fragment>
      <Pie data={pieData} height={330} options={pieOptions} />
    </Fragment>
  );
}

export default PieChart;
