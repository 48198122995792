import React, { useState } from "react";
import Cart from "./Cart";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import "bootstrap/dist/css/bootstrap.min.css";
// import MapContainer from "./MapContainer";
import { GoogleApiWrapper } from "google-maps-react";
const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const StepThree = (props) => {

  const [manuallyCheck, setManuallyCheck] = useState("checked");
  const [address, setAddress] = useState(props.formData.company_data.company_address);

  const handleBlur = (e) => {
    const newformdata = { ...props.formData };
    const errorsformdata = { ...props.errors };

    if (e.target.name === "company_name") {
      if (e.target.value.length === 0) {
        errorsformdata.company_data.company_name = " is required.";
      } else {
        errorsformdata.company_data.company_name = "";
      }
      newformdata.company_data.company_name = e.target.value;
    }

    if (e.target.name === "industry") {
      if (e.target.value.length === 0) {
        errorsformdata.company_data.industry = "Industry is required.";
      } else {
        errorsformdata.company_data.industry = "";
      }

      newformdata.company_data.industry = e.target.value;
    }

    if (e.target.name === "company_type") {
      if (e.target.value.length === 0) {
        errorsformdata.company_data.company_type = "Company Type is required.";
      } else {
        errorsformdata.company_data.company_type = "";
      }
      newformdata.company_data.company_type = e.target.value;
    }

    if (e.target.name === "company_address") {
      if (e.target.value.length === 0) {
        errorsformdata.company_data.company_address =
          "Company Address is required.";
      } else {
        errorsformdata.company_data.company_address = "";
      }
      newformdata.company_data.company_address = e.target.value;
    }

    if (e.target.name === "locality") {
      if (e.target.value.length === 0) {
        errorsformdata.company_data.locality = "Locality is required.";
      } else {
        errorsformdata.company_data.locality = "";
      }
      newformdata.company_data.locality = e.target.value;
    }

    if (e.target.name === "city") {
      if (e.target.value.length === 0) {
        errorsformdata.company_data.city = "City is required.";
      } else {
        errorsformdata.company_data.city = "";
      }
      newformdata.company_data.city = e.target.value;
    }

    if (e.target.name === "state") {
      if (e.target.value.length === 0) {
        errorsformdata.company_data.state = "State is required.";
      } else {
        errorsformdata.company_data.state = "";
      }
      newformdata.company_data.state = e.target.value;
    }

    if (e.target.name === "postal_code") {
      if (e.target.value.length === 0) {
        errorsformdata.company_data.industry = "Postal Code is required.";
      } else {
        if (/^\d{5,6}$/.test(e.target.value)) {
          errorsformdata.company_data.postal_code = "";
        } else {
          errorsformdata.company_data.postal_code = "Postal code invalid.";
        }
      }
      newformdata.company_data.postal_code = e.target.value;
    }

    props.handleThreeData(newformdata);
  };

  function handleSelectCheck(data) {
    let rec_chk_val = data.target.value;

    if (rec_chk_val !== "" && rec_chk_val === "checked") {
      setManuallyCheck("");
    } else {
      setManuallyCheck("checked");
    }
  }

  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);

      let add_data = results[0].address_components;

      const newformdata = { ...props.formData };

      newformdata.company_data.company_address = results[0].formatted_address;


      setAddress(results[0].formatted_address);
      add_data.forEach((add, index) => {
        if (
          add.types.find((element) => element === "subpremise") === "subpremise"
        ) {
          newformdata.company_data.house_number = add.long_name;
        }

        if (add.types.find((element) => element === "premise") === "premise") {
          newformdata.company_data.locality = add.long_name;
        }

        if (add.types.find((element) => element === "route") === "route") {
          newformdata.company_data.locality = add.long_name;
        }

        if (
          add.types.find((element) => element === "postal_code") === "postal_code"
        ) {
          newformdata.company_data.postal_code = add.long_name;
        }

        if (
          add.types.find(
            (element) => element === "administrative_area_level_1"
          ) === "administrative_area_level_1"
        ) {
          newformdata.company_data.state = add.long_name;
        }

        if (
          add.types.find(
            (element) => element === "administrative_area_level_2"
          ) === "administrative_area_level_2"
        ) {
          newformdata.company_data.city = add.long_name;
        } else {
          if (
            add.types.find(
              (element) => element === "administrative_area_level_3"
            ) === "administrative_area_level_3"
          ) {
            newformdata.company_data.city = add.long_name;
          }
        }

        props.handleThreeData(newformdata);
      });
    } catch (error) {
      console.error("Error selecting address:", error);
    }
  };

  return (
    <section>
      <div className="row">
        <div className="col-sm-8 mt-3">
          <h4 className="card-title">Business Information</h4>
          <hr />
          <div className="card-body">
            <div className="basic-form">
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="text-label">Company Name</label>
                    <span className="text-danger">*</span>
                    <input
                      type="text"
                      name="company_name"
                      className="form-control"
                      placeholder=""
                      onChange={handleBlur}
                      value={props.formData.company_data.company_name}
                    />
                    <span className="text-danger">
                      {props.errors.company_data.company_name}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="text-label">Industry</label>
                    <span className="text-danger">*</span>
                    <input
                      type="text"
                      className="form-control"
                      name="industry"
                      id="industry"
                      placeholder=""
                      onChange={handleBlur}
                      value={props.formData.company_data.industry}
                    />
                    <span className="text-danger">
                      {props.errors.company_data.industry}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="text-label">Company Type</label>
                    <span className="text-danger">*</span>
                    <select
                      className="form-control"
                      name="company_type"
                      onChange={handleBlur}
                      value={props.formData.company_data.company_type}
                    >
                      <option value="">Select Company Type</option>
                      <option value="corporation">Corporation</option>
                      <option value="llc">LLC</option>
                      <option value="non_profit">Non-Profit</option>
                      <option value="sole_prop">Sole Prop</option>
                    </select>
                    <span className="text-danger">
                      {props.errors.company_data.company_type}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="text-label">
                      Company Addess
                      <span className="text-danger lr-4">*</span>
                      <span className="ml-4">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="check1"
                          name="is_recurring"
                          onChange={handleSelectCheck}
                          value={manuallyCheck}
                        />
                        <label className="form-check-label" htmlFor="check1">
                          Enter manually
                        </label>
                      </span>
                    </label>
                    
                    {manuallyCheck === "checked" ? (
                      <PlacesAutocomplete
                        value={address}
                        onChange={(value) => setAddress(value)}
                        onSelect={handleSelect}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <input
                              {...getInputProps({
                                placeholder: "Search Places...",
                                className: "form-control",
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => (
                                <div
                                  {...getSuggestionItemProps(suggestion)}
                                  key={suggestion.placeId}
                                >
                                  {suggestion.description}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    ) : (
                      <div className="form-group">
                        <textarea
                          rows={3}
                          className="form-control"
                          name="company_address"
                          placeholder="address"
                          onChange={handleBlur}
                          value={props.formData.company_data.company_address}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="text-label">Suite/Apt</label>
                    <span className="text-danger">*</span>
                    <input
                      type="text"
                      className="form-control"
                      name="locality"
                      id="locality"
                      placeholder="Suite/Apt"
                      onChange={handleBlur}
                      value={props.formData.company_data.locality}
                    />
                    <span className="text-danger">
                      {props.errors.company_data.locality}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="text-label">City</label>
                    <span className="text-danger">*</span>
                    <input
                      type="text"
                      className="form-control"
                      name="city"
                      id="city"
                      placeholder=""
                      onChange={handleBlur}
                      value={props.formData.company_data.city}
                    />
                    <span className="text-danger">
                      {props.errors.company_data.city}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="text-label">State</label>
                    <span className="text-danger">*</span>
                    <input
                      type="text"
                      className="form-control"
                      name="state"
                      id="state"
                      placeholder=""
                      onChange={handleBlur}
                      value={props.formData.company_data.state}
                    />
                    <span className="text-danger">
                      {props.errors.company_data.state}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="text-label">Postal code</label>
                    <span className="text-danger">*</span>
                    <input
                      type="text"
                      className="form-control"
                      name="postal_code"
                      id="postal_code"
                      placeholder=""
                      onChange={handleBlur}
                      value={props.formData.company_data.postal_code}
                    />
                    <span className="text-danger">
                      {props.errors.company_data.postal_code}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4 mt-3">
          <Cart
            formData={props.formData}
            handleCartData={props.handleCartData}
          />
        </div>
      </div>
    </section>
  );
};

// export default StepThree;
export default GoogleApiWrapper({
  apiKey: API_KEY,
})(StepThree);
