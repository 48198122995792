import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Card, Accordion, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { getTab, updatePermission } from "../../actions/role";
import { useDispatch } from "react-redux";
import PermissionsRows from "./PermissionsRows";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import Loader from "../../layouts/Loader";

const UiAccordion = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [tabData, setTabData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [activeDefault, setActiveDefault] = useState(0);
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;

  const [permissionData, setPermissionData] = useState([]);
  const [rowDataa, setRowDataa] = useState([]);
  const [socket, setSocket] = useState(null);

  const handleRoleEvent = (event) => {
    //setRoleEvent(event);
  };

  const params = useLocation();
  let role_uuid_new = params.state.role_uuid;

  useEffect(() => {
    dispatch(getTab(role_uuid_new, createdby, token))
      .then((response) => {
        setTabData(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const WEB_SOCKET_URL = process.env.REACT_APP_SERVER_WEB_SOCKET_URL;
    // Establishing a WebSocket connection
    const ws = new WebSocket(WEB_SOCKET_URL);

    // Set up event listeners
    ws.onopen = () => {
      console.log("WebSocket connection opened");
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed");
    };

    setSocket(ws);

    // Cleanup the WebSocket connection when the component unmounts
    return () => {
      ws.close();
    };
  }, []);

  const rowDataPass = (index) => {
    setPermissionData(index);
    setRowDataa(index);
  };

  const handleChange = (i, e) => {
    var action = e.target.value;

    const newTabData = [...tabData];
    newTabData[i]["status"] = action;
    setTabData(newTabData);
  };

  const [errors, setErrors] = useState({});

  let rowData = {};

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create user API
      dispatch(
        updatePermission(
          tabData,
          permissionData,
          role_uuid_new,
          createdby,
          token
        )
      )
        .then((res) => {
          if (res.status === 200) {
            const fileData = {
              type: "change_permission",
              data: role_uuid_new,
            };

            if (socket && socket.readyState === WebSocket.OPEN) {
              socket.send(JSON.stringify(fileData));
            }
            notifyInfo(res.message);
            setLoading(false);
            navigate("/roles", {
              state: { role_uuid: role_uuid_new },
            });
          } else if (res.status === 406) {
            error_data["role_name"] = res.message;
            setErrors(error_data);
            isError = "true";
          } else if (res.status === 500 || res === 500) {
            setLoading(false);
            notifyError("Something went wrong.");
          } else {
            setLoading(false);
            notifyError("Something went wrong.");
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <Row>
        {/* <!-- Column starts --> */}
        <Col xl="12">
          {/* <!-- Default accordion --> */}
          <form action="" onSubmit={(e) => submitHandler(e)}>
            <Accordion
              className="accordion accordion-primary"
              defaultActiveKey="0"
            >
              {tabData.map((d, i) => (
                <div
                  className="accordion__item"
                  key={i}
                  style={
                    d.slug === "web-phone" ? { pointerEvents: "none" } : {}
                  }
                >
                  <div className="row">
                    <div className="col-10">
                      <Accordion.Toggle
                        as={Card.Text}
                        eventKey={`${i}`}
                        className={`accordion__header accordion__header rounded-lg ${
                          activeDefault === i ? "" : "collapsed"
                        }`}
                        onClick={() =>
                          setActiveDefault(activeDefault === i ? -1 : i)
                        }
                      >
                        <span className="accordion__header--text">
                          {d.tab_name}
                        </span>

                        {d.slug !== "web-phone" ? (
                          <span className="accordion__header--indicator"></span>
                        ) : (
                          <></>
                        )}
                      </Accordion.Toggle>
                    </div>
                    <div className="col-2">
                      <select
                        className={
                          d.status === "disable"
                            ? "tab_sel form-control time_field_red"
                            : "tab_sel form-control time_field_hp"
                        }
                        id={d.slug}
                        // onChange={(handleChange(i))}
                        onChange={(e) => handleChange(i, e)}
                        defaultValue={d.status}
                      >
                        <option value="enable">Enable</option>
                        <option value="disable">Disable</option>
                      </select>
                    </div>
                  </div>

                  {d.slug !== "web-phone" ? (
                    <Accordion.Collapse eventKey={`${i}`}>
                      <div className="accordion__body--text">
                        <PermissionsRows
                          tabName={d.slug}
                          role_uuid={role_uuid_new}
                          tabPermissions={tabData}
                          handleRoleEvent={handleRoleEvent}
                          i={i}
                          rowDataPass={rowDataPass}
                          rowData={rowData}
                          rowDataa={rowDataa}
                        />
                      </div>
                    </Accordion.Collapse>
                  ) : (
                    ''
                  )}
                </div>
              ))}
            </Accordion>
            <div className="text-right mr-5">
              <Button type="submit" variant="btn btn-sm btn-primary">
                Save
              </Button>
            </div>
          </form>
        </Col>
        {/* <!-- Column ends --> */}
      </Row>{" "}
    </Fragment>
  );
};

export default UiAccordion;
