import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { updateGroup } from "../../actions/internalchatgroup";
import { getGroupUserList, getGroupDetails } from "../../actions/internalChat";
import { Button, Modal } from "react-bootstrap";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import Loader from "../../layouts/Loader";

const UpdateGroupForm = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const closeModel = (arg) => {
    props.closeModel(arg);
  };

  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [groupUserData, setGroupUserData] = useState([]);

  const [groupData, setGroupData] = useState({
    assign_user_data: "",
    group_uuid: props.groupUuid,
    main_uuid: main_uuid,
    createdby: createdby,
  });

  const [errors, setErrors] = useState({
    name: "",
    assign_user_data: "",
    group_owner: "",
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(getGroupUserList(main_uuid, createdby, token))
      .then((response) => {
        setUsers(response.data);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(getGroupDetails(props.groupUuid, createdby, token))
      .then((response) => {
        setGroupUserData(response.data.groupUserData);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (groupData.assign_user_data.length === 0) {
      error_data["assign_user_data"] = "Please select atleat one User.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create user API
      const data = { ...groupData };
      dispatch(updateGroup(data, token))
        .then((res) => {
          if (res.status === 200) {
            notifyInfo(res.message);
            setLoading(false);
            props.closeModel(false);
            props.handleGroupEvent();
          } else if (res.status === 500) {
            notifyError("Something went wrong.");
          } else {
            notifyError("Something went wrong.");
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  function handleSelect(data) {
    groupData["assign_user_data"] = data;
    setGroupUserData(data);
    setGroupData(groupData);
  }

  return (
    <Fragment>
      {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>Add And Remove Group Member</Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <form action="" onSubmit={(e) => submitHandler(e)}>
        <Modal.Body>
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label className="mb-1">
                    <strong>Assign Users</strong>
                    <span className="text-danger">*</span>
                  </label>

                  <Select
                    className="form-control"
                    options={users}
                    name="assign_user_data"
                    placeholder="Select User"
                    value={groupUserData}
                    onChange={handleSelect}
                    isSearchable={true}
                    isMulti
                  />

                  <span className="text-danger">{errors.assign_user_data}</span>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" variant="primary" className="btn-sm">
            Save
          </Button>
          <Button
            onClick={() => closeModel(false)}
            className="btn-sm ml-2"
            variant="danger light"
          >
            Close
          </Button>
        </Modal.Footer>
      </form>
    </Fragment>
  );
};

export default UpdateGroupForm;
