import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Table, Button,ButtonGroup,
  DropdownButton,
  Dropdown, } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import PageTitle from "../../../layouts/PageTitle";
import {
  getcontactGroupList,
  deletecontactGroup,
} from "../../../actions/contactgroup";
import CheckModulePermisson from "../../../common/CheckModulePermisson";
import PermissionCheck from "../../../common/PermissionCheck";
import { notifyInfo, notifyError } from "../../Toastr/Toastr";
import Loader from "../../../layouts/Loader";
import Pagination from "../../../layouts/Pagination";

function ContactGroups(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;
  const module_name = "contact-groups";

  let module_per = CheckModulePermisson(module_name);

  // let isPermission = true;
  // if (module_per === "" || user_type === "admin") {
  //   isPermission = true;
  // } else {
  //   isPermission = false;
  // }

  let listing_per = PermissionCheck(module_name, "listing", "", "", "");

  const dispatch = useDispatch();
  let navigate = useNavigate();

  if (listing_per === "none") {
    navigate("/error-403");
  }

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }

  const [groupEvent, setGroupEvent] = useState("");

  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [offSet, setOffSet] = useState(0);
  const [totalGroups, setTotalGroups] = useState(0);
  const [limits, setLimits] = useState(10);

  let search = "";
  let orderby = "created_at desc";

  if (module_per === "" || user_type === "admin") {
    useEffect(() => {
      setLoading(true);
      dispatch(
        getcontactGroupList(
          user_type,
          group_per,
          group_uuid,
          search,
          offSet,
          limits,
          orderby,
          main_uuid,
          createdby,
          token
        )
      )
        .then((response) => {
          setGroups(response.data.data);
          const total = response.data.total;
          setTotalGroups(total);
          setpageCount(Math.ceil(total / limits));

          setGroupEvent("");
          setLoading(false);
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    }, [groupEvent,limits]);
  }

  const handlePageClick = async (data) => {

    setOffSet(limits * data.selected);
    setGroupEvent(Math.random);
  };

  const fnDeleteGroup = (uuid) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this group?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteGroupService(uuid),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteGroupService = (uuid) => {
    setLoading(true);
    dispatch(deletecontactGroup(uuid, createdby, token))
      .then((response) => {
        if (response.status === 200) {
          notifyInfo(response.message);
          setGroupEvent(uuid);
          return;
        } else if (response.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const chnageLimit = (value) => {
    setLimits(value);
  }

  const breadCurmList = [
    { label: "Contact", link: "/contacts", is_active: "NO" },
    { label: "Groups ", link: "", is_active: "YES" },
  ];

  return (
    <Fragment>
      <PageTitle breadCurmList={breadCurmList}  />
      {loading && <Loader />}

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Manage Contact Groups ({totalGroups})</h4>
              {/* {add_group === "yes" ? (
                <Button
                  type="button"
                  className="btn btn-sm btn-default"
                  onClick={() =>
                    navigate("/add-contact-group", { replace: true })
                  }
                >
                  Add New Contact Group
                </Button>
              ) : (
                <></>
              )} */}
            </div>

            <div className="card-body">
            Show
              <ButtonGroup className="ml-2 mr-2">
                <DropdownButton
                  as={ButtonGroup}
                  id="bg-nested-dropdown"
                  size="sm"
                  title={limits}
                  variant="custom-dropdown"
                >
                  <Dropdown.Item
                    eventKey="1"
                    href="#"
                    onClick={() => chnageLimit(10)}
                  >
                    10
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="1"
                    href="#"
                    onClick={() => chnageLimit(25)}
                  >
                    25
                  </Dropdown.Item>

                  <Dropdown.Item
                    eventKey="1"
                    href="#"
                    onClick={() => chnageLimit(50)}
                  >
                    50
                  </Dropdown.Item>

                  <Dropdown.Item
                    eventKey="1"
                    href="#"
                    onClick={() => chnageLimit(100)}
                  >
                    100
                  </Dropdown.Item>
                  
                </DropdownButton>
              </ButtonGroup>
              entries

              <Table responsive className="w-100">
                <thead>
                  <tr role="row">
                    <th>Name</th>
                    {listing_per === "group" || user_type === "admin" ? (
                      <th>Created By</th>
                    ) : (
                      <></>
                    )}
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {groups.map((group) => (
                    <tr key={group.uuid}>
                      <td>
                        <Link
                          to="/update-contact-group"
                          state={{
                            contact_group_uuid: group.uuid,
                          }}
                          //className="text-success"
                        >
                          {group.group_name} ({group.contact_count})
                        </Link>

                        
                      </td>
                      {listing_per === "group" ||
                      listing_per === "all" ||
                      user_type === "admin" ? (
                        <td>{group.created_by_name}</td>
                      ) : (
                        <></>
                      )}
                      <td>
                        <Fragment>
                          <div className="d-flex">
                            {
                              <Link
                                to="/update-contact-group"
                                state={{
                                  contact_group_uuid: group.uuid,
                                }}
                                hidden={PermissionCheck(
                                  module_name,
                                  "edit",
                                  group.uuid,
                                  group.user_created_by,
                                  group.created_by
                                )}
                                className="btn btn-primary shadow btn-xs sharp mr-1"
                              >
                                <i className="fa fa-pencil"></i>
                              </Link>
                            }
                            <Button
                              to="#"
                              className="btn btn-danger shadow btn-xs sharp"
                              onClick={() => fnDeleteGroup(group.uuid)}
                              hidden={PermissionCheck(
                                module_name,
                                "delete",
                                group.uuid,
                                group.user_created_by,
                                group.created_by
                              )}
                            >
                              <i className="fa fa-trash"></i>
                            </Button>
                          </div>
                        </Fragment>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <Pagination
                pageCount={pageCount}
                handlePageNumberClick={handlePageClick}
              />

            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ContactGroups;
