import React, { Fragment, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import { createTimecondition } from "../../actions/timeconditions";
import "./style.css";

function TimeconditionForm(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const closeModel = (arg) => {
    props.closeModel(arg);
  };

  const [timeconditionData, setTimeconditionData] = useState({
    time_condition_id: props.timeRoutingId,
    time_condition_name: "",
    createdby: createdby,
    main_admin_uuid: main_uuid,
    user_uuid: createdby,
  });

  const [errors, setErrors] = useState({
    time_condition_name: "",
    route_type: "",
    route_to: "",
  });
  
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBlur = (e) => {
    const newTimeconditionData = { ...timeconditionData };
    let error_data = { ...errors };

    if (e.target.name === "time_condition_name" && e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }

    newTimeconditionData[e.target.name] = e.target.value;
    setTimeconditionData(newTimeconditionData);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    if (timeconditionData.time_condition_name.length === 0) {
      error_data["time_condition_name"] =
        "Time-Based Routing name is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      
      return;
    } else {
      //call create user API

      const newTimeconditionData = { ...timeconditionData };

      newTimeconditionData["time_condition_id"] = props.timeRoutingId;
      dispatch(createTimecondition(newTimeconditionData, token))
        .then((res) => {
          if (res.status === 200) {
            notifyInfo(res.message);
            let lc_id = res.data;

            navigate(`/update-business-hours`, {
              state: { time_condition_uuid: lc_id },
            });
          } else if (res.status === 406) {
            error_data["time_condition_name"] = res.message;
            setErrors(error_data);
            isError = "true";
          } else if (res.status === 500) {
            notifyError("Something went wrong.");
          } else {
            notifyError("Something went wrong.");
          }
        })
        .catch(() => {
          //setLoading(false);
        });
    }
  };

  return (
    <Fragment>
      <Modal.Header>
        <Modal.Title>Add New Time-Based Routing</Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <form action="" onSubmit={(e) => submitHandler(e)}>
        <Modal.Body>
          <div className="form-group">
            <label className="mb-1">
              <strong>Name</strong>
              <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              //placeholder="Enter a name"
              name="time_condition_name"
              defaultValue={timeconditionData.time_condition_name}
              onChange={handleBlur}
            />
            <span className="text-danger">{errors.time_condition_name}</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => closeModel(false)}
            variant="danger light btn-sm"
          >
            Close
          </Button>
          <Button type="submit" variant="primary btn-sm">
            Save changes
          </Button>
        </Modal.Footer>
      </form>
    </Fragment>
  );
}

export default TimeconditionForm;
