import React from "react";
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';

const Info = ({ toggleTab, PerfectScrollbar, toggleChatBox, contact, isDialpadShow, setDialNumber, isExtensionListShow }) => {

   const dailNumber = (event,number) => {
      event.preventDefault();
      setDialNumber(number)
   }
   return (
      <div
         className={`tab-pane fade  ${
            toggleTab === "info" ? "active show" : ""
         }`}
         id="info"
         role="tabpanel"
      >
         {/* <div className="UserData"> */}
           
            <PerfectScrollbar
               className={`card-body p-0 dz-scroll  ${
                  toggleChatBox ? "ps ps--active-y" : ""
               } ${
                  isDialpadShow === 'YES' || isExtensionListShow === 'YES' ? "contacts_body_new" : "contacts_body"
               }`}
               id="DZ_W_Contacts_Body1"
            >
               {/* <div className="basic-list-group"> */}
                  {/* {Object.keys(contact).length > 0 &&  */}
                  {contact && 
                     <div className="UserData timeline clearfix">

                           <p>Account Name <span>
                              {contact.bussiness_name ? contact.bussiness_name : contact.contact_title}
                              {Object.keys(contact).length > 0 && 
                                 <Link
                                    to="/view-contact"
                                    state={{
                                    contact_uuid: contact.contact_uuid,
                                    group_uuid: contact.group_uuid,
                                    user_created_by: contact.user_created_by,
                                    }}
                                 >
                                    <i className="fa fa-chevron-right"></i>
                                 </Link>
                              } 
                              
                              
                           </span></p>

                           <p>Contact Name <span>
                              {contact.first_name} {contact.last_name}
                              {Object.keys(contact).length > 0 && 
                                 <Link
                                    to="/view-contact"
                                    state={{
                                    contact_uuid: contact.contact_uuid,
                                    group_uuid: contact.group_uuid,
                                    user_created_by: contact.user_created_by,
                                    }}
                                 >
                                    <i className="fa fa-chevron-right"></i>
                                 </Link>
                              }
                           </span></p>

                           <p>Email <span>{contact.email}</span></p>
                           <p className="pull-left" style={{width:"fit-content"}}>Phone <span> {contact.work_contact_number}</span></p>
                              {/* <a className="ml-2"
                                 href="#"
                                 onClick={(event) => dailNumber(event,contact.work_contact_number)}
                              >
                                 <i className="fa fa-phone"></i>
                              </a> */}
                              {Object.keys(contact).length > 0 && 
                                 <span className="w-75 dialUser mt-3">
                                    <Dropdown>
                                       <Dropdown.Toggle variant="" id="dropdown-basic">
                                       <span className=""><i className="fa fa-phone"></i></span>
                                       </Dropdown.Toggle>

                                       <Dropdown.Menu>
                                          <Dropdown.Item href="#" onClick={(e) => dailNumber(e,contact.work_contact_number)}>{contact.work_contact_number}</Dropdown.Item>
                                          <Dropdown.Item href="#" onClick={(e) => dailNumber(e,contact.other_contact_number)}>{contact.other_contact_number}</Dropdown.Item>
                                       </Dropdown.Menu>
                                    </Dropdown> 
                                 </span>
                              }
                          
                           {Object.keys(contact).length > 0 && 
                              <p className="d-inline-block">Last call <span>09 June'23 04:22PM- 5m 32s</span></p>
                           }

                        {/* <div className="profile-name px-3 pt-2">
                           <h4 className="text-primary mb-0">{contact.first_name} {contact.last_name}</h4>
                           <p><small>Name</small></p>
                        </div>
                        <div className="profile-name px-3">
                           <h4 className="mb-0">{contact.bussiness_name ? contact.bussiness_name : contact.contact_title}</h4>
                           <p><small>Contact Title</small></p>
                        </div>
                        <div className="profile-name px-3">
                           <h4 className="mb-0"><i className="fa fa-phone"></i> {contact.work_contact_number}</h4>
                           <p><small>Contact</small></p>
                        </div>
                        <div className="profile-name px-3">
                           <h4 className="mb-0"><i className="fa fa-envelope"></i> {contact.email}</h4>
                           <p><small>Email</small></p>
                        </div>
                        <div className="profile-name px-3">
                           <h4 className="mb-0">24/06/2023 12:00 pm</h4>
                           <p><small>Last Call</small></p>
                        </div> */}
                     </div>
                     
                    
                  }
               {/* </div> */}
            </PerfectScrollbar>
            
         {/* </div> */}
      </div>
   );
};

export default Info;
