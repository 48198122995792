import React from "react";
import MultiStepForm from "./MultiStepForm";

const AddSequence = () => {

  return (
    <div className="authincation">
      <div className="container">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="authincation-content w-100">
            <div className="row no-gutters">
              <div className="col-xl-12">
                <div className="auth-form">
                  <h4 className="text-center mb-4">Add New Sequence</h4>
                  <MultiStepForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSequence;
