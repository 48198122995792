import { SET_MESSAGE, API_FAIL } from "./types";

import WebphoneService from "../services/webphone.service";

export const getDispositionList = (createdby, main_uuid,token) => (dispatch) => {
  return WebphoneService.getDispositionList(createdby, main_uuid,token).then(
    (data) => {
      
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


