import { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { logout } from "../actions/auth";
import { clearMessage } from "../actions/message";
import EventBus from "../common/EventBus";

function Logout(params) {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const logOut = useCallback(() => {
    dispatch(logout());
    window.location.reload();
    navigate("/login", { replace: true });
  }, [dispatch]);

  EventBus.on("logout", () => {
    logOut();
  });

  useEffect(() => {
    //return () => {
    logOut();
    EventBus.remove("logout");
    localStorage.clear();
    dispatch(clearMessage());
    window.location.reload();
    navigate("/login", { replace: true });
    //};
  }, []); //[currentUser, logOut]
}

export default Logout;
