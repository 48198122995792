import React from "react";
import BuyNumber from "./BuyNumber";

const StepOne = (props) => {
  return (
    <section>
      <BuyNumber
       type={props.type}
        didsData={props.didsData}
        handleBuyAndConfirmData={props.handleBuyAndConfirmData}
      />
    </section>
  );
};

export default StepOne;
