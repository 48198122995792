import { SET_MESSAGE, API_FAIL } from "./types";

import cdrservice from "../services/cdr.service";

export const getCdrs = (
  user_type,
  group_per,
  group_uuid,
  search,
  searchData,
  off_set,
  limits,
  orderby,
  main_uuid,
  createdby,
  token
) => (dispatch) => {
  return cdrservice.getCdrs(
    user_type,
    group_per,
    group_uuid,
    search,
    searchData,
    off_set,
    limits,
    orderby,
    main_uuid,
    createdby,
    token
  ).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
