
import React, { useState,Fragment,useEffect} from "react";
import { useDispatch } from "react-redux";
import {EditorState, ContentState} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from 'html-to-draftjs';
import Loader from "../../layouts/Loader";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import { getUserSignatureList,getUserEmailSignature,handleSignaturePermission} from "../../actions/email/emailSignature";
import { confirmAlert } from "react-confirm-alert";



function ViewEmailSignature(props) {

    const user = JSON.parse(localStorage.getItem("user"));
    const user_data = user.data.data;
    const token = user.data.token;
    const createdby = user_data.user_uuid;

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [userSignatureList, setUserSignatureList] = useState([]);
    const [userSignature, setUserSignature] = useState([]);
    const [activeSignature, setActiveSignature] = useState('');

    const [editorState, setEditorState] = useState(
        EditorState.createEmpty()
    );

    let  userData = props.userData;


    useEffect(() => {
      if(userData && userData.uuid && userData.uuid!== ''){

        let user_uuid = userData.uuid;

        dispatch(getUserEmailSignature(createdby,user_uuid,token))
          .then((response) => {
            
            if(response.data[0]){
              
              setUserSignature(response.data[0]);  

              setActiveSignature(response.data[0]);  
                
              if(response.data[0].signature_text !== ''){
                const blocksFromHTML = htmlToDraft(response.data[0].signature_text)
                const contentState = ContentState.createFromBlockArray(
                    blocksFromHTML.contentBlocks,
                    blocksFromHTML.entityMap
                )
    
                setEditorState(EditorState.createWithContent(contentState));
              }   
            }            
    
            return;
          })
          .catch(() => {
            
          });

      }
    }, [userData]);

    useEffect(() => {
      if(userData && userData.uuid && userData.uuid!== ''){

        let user_uuid = userData.uuid;

        dispatch(getUserSignatureList(createdby,user_uuid,token))
          .then((response) => {
            setUserSignatureList(response.data);
    
            if(response.data[0]){
              
              if(!activeSignature){
                setActiveSignature(response.data[0]);  
                
                if(response.data[0].signature_text !== ''){
                  const blocksFromHTML = htmlToDraft(response.data[0].signature_text)
                  const contentState = ContentState.createFromBlockArray(
                      blocksFromHTML.contentBlocks,
                      blocksFromHTML.entityMap
                  )
      
                  setEditorState(EditorState.createWithContent(contentState));
                }   
              }     
    
            }            
    
            return;
          })
          .catch(() => {
            setLoading(false);
          });
      }
        
    }, [userData]);

    const selectSignature = (e,signature) => {
        e.preventDefault();
    
        if(signature.signature_text){
          const blocksFromHTML = htmlToDraft(signature.signature_text)
          const contentState = ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap
          )
    
          setEditorState(EditorState.createWithContent(contentState));
        }else{
          setEditorState(EditorState.createEmpty());
        }      
    
        setActiveSignature(signature);
    }

    const userSignatureCreatePermission = (e) => {
      e.preventDefault();

      let message = '';
      let is_create_signature = '';
      if (e.target.checked) {
        message = 'Are you sure give permission to this user?';
        is_create_signature = 'YES';
      }else{
        message = 'Are you sure to remove permission of this user?';
        is_create_signature = 'NO';
      }

      confirmAlert({
        title: "",
        message:message,
        buttons: [
          {
            label: "Yes",
            onClick: () => changeSignatureCreatePermission(is_create_signature),
          },
          {
            label: "No",
          },
        ],
      });

    }

    const changeSignatureCreatePermission = (is_create_signature) => {
      let data = {
        createdby : createdby,
        user_uuid : userData.uuid,
        is_create_signature : is_create_signature
      }

      dispatch(handleSignaturePermission(data,token))
          .then((response) => {
            
            if (response.status === 200) {

              let signature_data = {...userSignature};
              signature_data['is_create_signature'] = is_create_signature;
              setUserSignature(signature_data);

              notifyInfo(response.message);
            }  else if (response.status === 500) {
                notifyError("Something went wrong! Try again in some time");
            } else {
                notifyError("Something went wrong! Try again in some time");
            }       
    
            return;
          })
          .catch(() => {
            
          });
    }
  
    
         
    return (    
        <Fragment>
            {loading && <Loader />}
            <div className="row">

                <div className="col-md-4"> 

                  <div className="basic-list-group">
                    <div className="list-group">
                    {userSignature && userSignature.type === "COMPANY" &&    
                        <a href="#!" key={userSignature.email_signature_uuid} className={`${
                            activeSignature.email_signature_uuid === userSignature.email_signature_uuid
                            ? "list-group-item list-group-item-action active"
                            : "list-group-item list-group-item-action"
                            }`} 
                            
                            onClick={(e)=>selectSignature(e,userSignature)}
                            >
                            {userSignature.signature_name} (default)

                        </a> 
                    }
                    {userSignatureList && 
                        userSignatureList.map((signature) => (       
                        <a href="#!" key={signature.email_signature_uuid} className={`${
                            activeSignature.email_signature_uuid === signature.email_signature_uuid
                            ? "list-group-item list-group-item-action active"
                            : "list-group-item list-group-item-action"
                            }`} 
                            
                            onClick={(e)=>selectSignature(e,signature)}
                            >
                            {signature.signature_name}
                            {userSignature.email_signature_uuid === signature.email_signature_uuid &&  ' (default)' } 

                        </a> 
                            
                        ))
                    }
                                            
                    </div>
                  </div>
               
                </div>
                <div className="col-md-8">
                                   
                <div className="row">
                  <div className="col-md-6"> 
                    <p><b>{activeSignature.signature_name} </b></p>
                  </div>

                  <div className="col-md-6 text-right"> 
                   
                    <div className="form-check mb-2">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={userSignature.is_create_signature === 'YES'}
                        onChange={(e) =>
                          userSignatureCreatePermission(e)
                        }
                      />
                      <label className="form-check-label">Can User create thair email signatures?</label>
                    </div>
                    
                  </div>

                </div>
                
                <div className="form-group input-primary">
                    <div className="border border-primary rounded p-2" >
                        <Editor
                            editorStyle={{ minHeight: '300px',    backgroundColor: 'white' }}
                            editorState={editorState}
                            onEditorStateChange={editorState => setEditorState(editorState)}
                        />
                    </div>
                    
                </div>

            </div>

        </div>
            
    </Fragment> );

}

export default ViewEmailSignature;