import React, { Fragment,useState, useEffect } from "react";
import { Link,useNavigate } from "react-router-dom";
import { Table, Modal,Button, } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import PageTitle from "../../../layouts/PageTitle";
import Pagination from "../../../layouts/Pagination";
import CustomFieldForm from "../../../components/CRM/contacts/CustomFieldForm";

import { getCustomFieldList,deleteCustomField } from "../../../actions/customfield";

function CustomFields() {

    const [customFields, setCustomFields] = useState([]);
    const [customFieldEvent, setCustomFieldEvent] = useState('');
    const [addCustomFieldModal, setAddCustomFieldModal] = useState(false);
    const [pageCount, setpageCount] = useState(0);
    const [offSet, setOffSet] = useState(0);

    const handleCustomFieldEvent = (event) => {
        setCustomFieldEvent(event);
    };

    const closeModel = (value) => {
        setAddCustomFieldModal(value);
    };

    const dispatch = useDispatch();
    let navigate = useNavigate();


    const user = JSON.parse(localStorage.getItem("user"));
    const user_data = user.data.data;
    const token = user.data.token;
    const createdby = user_data.user_uuid;
    let search = '';
    //let off_set = 1;
    let limits = 10;
    let orderby = 'created_at DESC';
    let main_uuid = '';

    useEffect(() => {

        dispatch(getCustomFieldList(search,offSet,limits,orderby,main_uuid,createdby,token))
            .then((response) => {
                setCustomFields(response.data.data);
                const total = response.data.total;
                
                setpageCount(Math.ceil(total / limits));
                setCustomFieldEvent('');
                return;
            })
            .catch(() => {
                //setLoading(false);
            });        

    }, [customFieldEvent]);
   
    const handlePageClick = async (data) => {    
        setOffSet(limits * data.selected);
        setCustomFieldEvent('page-change');
    };

    const fnDeleteCustomField = (uuid) => {
        
        confirmAlert({
            title: "",
            message: "Are you sure to delete this contact?",
            buttons: [
              {
                label: "Yes",
                onClick: () => deleteCustomFieldService(uuid)
              },
              {
                label: "No"
              }
            ]
        });
    }

    const deleteCustomFieldService = (uuid) => {
        dispatch(deleteCustomField(uuid,createdby,token))
            .then((response) => {                
                setCustomFieldEvent('delete');
                return;
            })
            .catch(() => {
                //setLoading(false);
            });        
    }
 
    return(
        <Fragment>
            <PageTitle activeMenu="Contacts" motherMenu="Home" />
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Manage Custom Fields</h4>
                            <div>
                                <Button type="button" className="btn btn-sm btn-default mr-2" onClick={() => navigate("/contacts", { replace: true })}>
                                    <i className="fa fa-reply" aria-hidden="true"></i> Back To Contacts
                                </Button>

                                <Button type="button" className="btn btn-sm btn-info mr-2" onClick={() => navigate("/field-setting", { replace: true }) }>Settings</Button>

                                <Button type="button" className="btn btn-sm btn-primary mr-2" onClick={() => setAddCustomFieldModal(true)}>Add New Field</Button>
                            </div>
                        </div>
                        <div className="card-body">
                            <Table responsive className="w-100">
                                <thead>
                                <tr role="row">
                                    <th>Field Name</th>
                                    <th>Field Type</th>
                                    <th>Field Options</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {customFields.map((field) => ( 
                                    <tr key={field.custom_field_uuid}>

                                        <td>{field.field_name}</td>
                                        <td>{field.field_type}</td>
                                        <td>{field.field_options}</td>
                                        <td>
                                            <Fragment>
                                                <div className="d-flex">
                                                    {/* { <Link
                                                        to="/update-contact"
                                                        state={{ custom_field_uuid: field.custom_field_uuid }}
                                                        className="btn btn-primary shadow btn-xs sharp mr-1"
                                                    >
                                                        <i className="fa fa-pencil"></i>
                                                    </Link>
                                                    
                                                    } */}
                                                    <Link
                                                        to="#"
                                                        onClick={() => fnDeleteCustomField(field.concustom_field_uuidtact_uuid)}
                                                        className="btn btn-danger shadow btn-xs sharp"
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </Link>
                                                </div>
                                            </Fragment>
                                        </td>
                                    </tr>
                                ))} 
                                </tbody>
                            </Table>
                            
                            <Pagination pageCount={pageCount} handlePageNumberClick={handlePageClick} />
                            

                        </div>                        
                    </div>
                </div>
            </div>
            
            {/* <!-- add field Modal --> */}
            <Modal className="fade" show={addCustomFieldModal}>
                <CustomFieldForm closeModel={closeModel} handleCustomFieldEvent={handleCustomFieldEvent}/>
            </Modal>
        </Fragment>        
    );

}

export default CustomFields;