import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const createSmsTemplate = (data, token) => {
  return axios
    .post(API_URL + "create-sms-template  ", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // console.log(response, "=response===fate");
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getSmsTemplateList = (
  main_uuid,
  user_type,
  group_per,
  group_uuid,
  search,
  off_set,
  status,
  limits,
  orderby,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "sms-template-list",
      {
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        search,
        off_set,
        status,
        limits,
        orderby,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const deleteSmsTemplate = (sms_temp_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "delete-sms-template",
      {
        sms_temp_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

// const updateSmsTemplate = (smsTempData, token) => {
//   return axios
//     .post(
//       API_URL + "update-sms-template",
//       {
//         smsTempData,
//         token,
//       },
//       {
//         headers: {
//           Authorization: token,
//         },
//       }
//     )
//     .then((response) => {
//       return response.data;
//     });
// };

const updateSmsTemplate = (data, token) => {
  console.log(data,"===data==");
  console.log(token,"===token 22===");
  return axios
    .post(API_URL + "update-sms-template  ", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      console.log(response, "=response===fate");
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getSmsTemplateDetail = (createdby, token, sms_temp_uuid) => {
  return axios
    .post(
      API_URL + "get-sms-template-details",
      {
        createdby,
        sms_temp_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getContactFieldsList = (createdby, token) => {
  return axios
    .post(
      API_URL + "get-contact-fields-list",
      {
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

export default {
  createSmsTemplate,
  getSmsTemplateList,
  deleteSmsTemplate,
  updateSmsTemplate,
  getSmsTemplateDetail,
  getContactFieldsList,
};
