import React, { Fragment, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../layouts/PageTitle";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import { getDidRouting, updateDIDRouting } from "../../actions/did";
import { getDestinationTypeList } from "../../actions/comman";
import "./style.css";
import { getMusicOnHoldFile } from "../../actions/did";
import Tooltip from "rc-tooltip";
import EasyEdit, { Types } from "react-easy-edit";
import Loader from "../../layouts/Loader";

function UpdateNumber() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const params = useLocation();
  const did_uuid = params.state.did_uuid;

  const [loading, setLoading] = useState(false);

  const [didRoute, setDidRoute] = useState({
    did_uuid: did_uuid,
    did_action_type: "",
    did_action_uuid: "",
    description: "",
    music_on_hold: "",
    route_val: "",
    createdby: createdby,
    main_uuid: user_data.main_uuid,
  });

  const [musicOnHold, setMusicOnHold] = useState([]);

  const [options, setOptions] = useState([]);
  const [did, setDid] = useState("");

  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDidRouting(createdby, did_uuid, token))
      .then((response) => {
        if (response.data !== "") {
          getoptions(response.data[0].did_action_type);
          setDid(response.data[0].did_number);

          let new_data = { ...didRoute };
          new_data["did_action_type"] = response.data[0].did_action_type;
          new_data["did_action_uuid"] = response.data[0].did_action_uuid;
          new_data["description"] = response.data[0].description;
          new_data["music_on_hold"] = response.data[0].music_on_hold;
          new_data["route_val"] = response.data.route_val;
          setDidRoute(new_data);
        }

        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, [did_uuid]);

  useEffect(() => {
    dispatch(getMusicOnHoldFile(createdby, main_uuid, token))
      .then((response) => {
        if (response.data !== "") {
          let arrayObj = {};
          let i = 0;
          arrayObj = response.data.map((item) => {
            i = i + 1;
            return {
              label: item.recording_name,
              value: item.recording_uuid,
            };
          });

          let ddd = {
            label: "Add New File",
            value: "file_new_upload",
          };
          arrayObj[i] = ddd;

          setMusicOnHold(arrayObj);
        }
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, [did_uuid]);

  const getoptions = (type) => {
    const main_uuid = user_data.main_uuid;

    dispatch(getDestinationTypeList(createdby, token, main_uuid, type))
      .then((response) => {
        setOptions(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const CustomDisplay = (props) => {
    let new_data = { ...didRoute };

    function inputonChange(e) {
      if (e.target.name === "did_action_type") {
        new_data[e.target.name] = e.target.value;
        getoptions(e.target.value);
      }

      if (e.target.name === "did_action_uuid") {
        new_data[e.target.name] = e.target.value;
        getoptions(e.target.value);
      }

      setDidRoute(new_data);

      props.setParentValue(JSON.stringify(new_data));
    }

    return (
      <div>
        <div className="row">
          <div className="col-6">
            <div className="form-group ">
              <label>Route To</label>
              <select
                className="form-control"
                name="did_action_type"
                onChange={(e) => inputonChange(e)}
                value={didRoute.did_action_type}
              >
                <option value="">Select Destination Type</option>
                <option value="ivr">Auto-Attendant</option>
                <option value="extention">Extension</option>
                <option value="ring-group">Ring Groups</option>
                <option value="did">DID</option>
                <option value="voicemail">Voicemail</option>
                <option value="time_condition">Time Condition</option>
              </select>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label>Destination</label>
              <select
                className="form-control"
                name="did_action_uuid"
                onChange={(e) => inputonChange(e)}
                value={didRoute.did_action_uuid}
              >
                <option value="">Select Destination</option>

                {options &&
                  options.map((number) =>
                    number.value !== did_uuid ? (
                      <option key={number.value} value={number.value}>
                        {number.text}
                      </option>
                    ) : null
                  )}
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const CustomDisplay1 = (props) => {
    return (
      <div>
        <div className="row">
          <div className="col-6">
            <div className="form-group ">
              <label>
                {didRoute.did_action_type === "ivr"
                  ? "Auto Attendant"
                  : didRoute.did_action_type}{" "}
                - {didRoute.route_val}
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const save = (value, attribute) => {
    if (attribute === "music_on_hold" && value === "file_new_upload") {
      navigate(`/upload-recording`, {
        state: { defaultTab: "moh", type: "update_did", did_uuid: did_uuid },
      });
      return false;
    }
    let data = { ...didRoute };
    if (attribute === "route_and_destination") {
      data[attribute] = value;
    } else {
      data[attribute] = value;
    }

    setLoading(true);
    //call create user API
    dispatch(updateDIDRouting(data, token))
      .then((response) => {
        if (response.status === 200) {
          notifyInfo(response.message);
          setLoading(false);
          navigate("/inbound-numbers");
        } else if (response.status === 500) {
          setLoading(false);
          notifyError("Something went wrong.");
        } else {
          setLoading(false);
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const breadCurmList = [
    { label: "Calls", link: "/", is_active: "NO" },
    { label: "Inbound Numbers", link: "/inbound-numbers", is_active: "NO" },
    { label: "Update Inbound Number", link: "/", is_active: "YES" },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />
      <div className="row">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Inbound Number : {did}</h4>
            <Button
              type="button"
              className="btn btn-default btn-sm"
              onClick={() => navigate("/inbound-numbers", { replace: true })}
            >
              <i className="fa fa-reply" aria-hidden="true"></i> Back
            </Button>
          </div>
          <div className="card-body">
            <div className="row inline_edit ml-2 mr-2">
              <div className="col-lg-6 col-md-6 col-6">
                <div className="card card_green_shadow">
                  <div className="card-body">
                    <div className="form-group">
                      <label>
                        Inbound Number
                        <Tooltip
                          placement="top"
                          overlay={<span>Inbound Number</span>}
                        >
                          <span className="ti-info-alt ml-1"></span>
                        </Tooltip>
                      </label>
                      <p className="font-w600 text-black h4">{did}</p>
                    </div>
                    <div className="form-group">
                      <label className="w-100">
                        Description
                        <Tooltip
                          placement="top"
                          overlay={<span>Description</span>}
                        >
                          <span className="ti-info-alt ml-1"></span>
                        </Tooltip>
                      </label>
                      <EasyEdit
                        type={Types.TEXT}
                        onSave={(value) => {
                          save(value, "description");
                        }}
                        saveButtonLabel={
                          <i className="fa fa-check" aria-hidden="true"></i>
                        }
                        saveButtonStyle="label-primary easy-edit-button rounded-lg"
                        cancelButtonLabel={
                          <i className="fa fa-times" aria-hidden="true"></i>
                        }
                        cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                        attributes={{
                          name: "description",
                          className: "form-control h-75",
                        }}
                        placeholder="Description"
                        value={didRoute.description}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-6">
                <div className="card card_green_shadow">
                  <div className="card-body">
                    <div className="form-group">
                      <label>
                        Route and Destination
                        <Tooltip
                          placement="top"
                          overlay={<span>Route and Destination</span>}
                        >
                          <span className="ti-info-alt  ml-1"></span>
                        </Tooltip>
                      </label>
                      <EasyEdit
                        type={Types.TEXT}
                        onSave={(value) => {
                          save(value, "route_and_destination");
                        }}
                        editComponent={<CustomDisplay />}
                        displayComponent={<CustomDisplay1 />}
                        saveButtonLabel={
                          <i className="fa fa-check" aria-hidden="true"></i>
                        }
                        saveButtonStyle="label-primary easy-edit-button rounded-lg"
                        cancelButtonLabel={
                          <i className="fa fa-times" aria-hidden="true"></i>
                        }
                        cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                        attributes={{
                          name: "route_and_destination",
                          className: "form-control h-75",
                        }}
                        viewAttributes={{ className: "fs-20" }}
                        onValidate={(value) => {
                          return value ? true : false;
                        }}
                        placeholder="Route and Destination"
                        value={didRoute.description}
                      />
                    </div>
                    <div className="form-group">
                      <label className="w-100">
                        Music On Hold
                        <Tooltip
                          placement="top"
                          overlay={<span>Music On Hold</span>}
                        >
                          <span className="ti-info-alt  ml-1"></span>
                        </Tooltip>
                      </label>
                      <EasyEdit
                        type={Types.SELECT}
                        onSave={(value) => {
                          save(value, "music_on_hold");
                        }}
                        //onCancel={cancel}
                        options={musicOnHold}
                        saveButtonLabel={
                          <i className="fa fa-check" aria-hidden="true"></i>
                        }
                        saveButtonStyle="label-primary easy-edit-button rounded-lg"
                        cancelButtonLabel={
                          <i className="fa fa-times" aria-hidden="true"></i>
                        }
                        cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                        attributes={{
                          name: "music_on_hold",
                          className: "form-control p-0 music_on_hold",
                          style: { height: "35px" },
                        }}
                        onValidate={(value) => {
                          return value ? true : false;
                        }}
                        placeholder="Music On Hold"
                        value={didRoute.music_on_hold}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default UpdateNumber;
