import React, { Fragment, useState, useRef } from "react";
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { notifyError,notifyInfo } from "../../Toastr/Toastr";
import { getMeetingDetails } from "../../../actions/calender/CalendarMeeting";
import Loader from "../../../layouts/Loader";
import DailyIframe from '@daily-co/daily-js';
import { Button } from "react-bootstrap";
import logo from "../../../../images/Zongo.jpg";
import {createAccessToken} from "../../../actions/calender/Daily";

function AttendeeMeetingScreen(props) {

  const [searchParams] = useSearchParams();

  const user_meeting_token = searchParams.get('t');
  const videoCallRef = useRef(null);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [meetingData, setMeetingData] = useState(false);
  const [isUserVerified, setIsUserVerified] = useState(false);
  const [meetingPassword, setMeetingPassword] = useState("");
  const [userVeriyError, setUserVeriyError] = useState("");

  const handleBlur = (e) => {
    if(e.target.value.length === 0){
      setUserVeriyError('Please enter a password');
    }

    setMeetingPassword(e.target.value);
  }

  const verifyPassword = () => {
    setLoading(true);
    dispatch(getMeetingDetails(user_meeting_token,'ATTENDEE')).then((response) => {
      if (response.status === 200) {

        setMeetingData(response.data);
        const meeting_url = response.data.meeting_url;

        if(meetingPassword !== response.data.meeting_password){
          
          setUserVeriyError('Invalid Password!')
          setIsUserVerified(false);
        }else{
          
          setIsUserVerified(true);
          setUserVeriyError('')

          const room_name = meeting_url.replace('https://zongo.daily.co/', '');

          let tokenReq =  {"properties":{"room_name":room_name}}

          dispatch(createAccessToken(tokenReq)).then((tokenResponse) => {
        
            if(tokenResponse.data.token){
  
              if (!videoCallRef.current) return;

              const callFrame = DailyIframe.createFrame(videoCallRef.current, {
                showLeaveButton: true,
              });        
      
              callFrame.join({ url: meeting_url,token: tokenResponse.data.token });
              setLoading(false);

              return () => {
                callFrame.destroy();
                setLoading(false);
              };

            }else{
              notifyError('Someting went wrong please try again.');
              setLoading(false);
            }
            return;
          })
          .catch(() => {
            setLoading(false);
          });
         
          

        }        
      }
      if (response.status === 404) {
        setUserVeriyError('URL Expired!')
        setIsUserVerified(false);
      }      
      setLoading(false);
      return;
    })
    .catch(() => {
      setLoading(false);
    });
  }

  return (
    <Fragment>
      {loading && <Loader />}

      {!isUserVerified && 
      <div className="authincation h-100 p-meddle">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-9">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">

                    <div className="auth-form">

                      <span className="d-flex justify-content-center"><img className="" src={logo} alt="" height={60} /></span>

                      <span className="d-flex justify-content-center">                        

                        <div className="form-group input-primary mt-3">
                          <label className="mb-1">
                            <strong>Enter Meeting Password</strong>
                          </label>

                          <input
                            type="text"
                            className="form-control bg-white"
                            name="password"
                            defaultValue={meetingPassword}
                            onChange={handleBlur}
                          />
                          <p className="text-red text-center mt-3">{userVeriyError}</p>

                          <Button type="button" onClick={verifyPassword} variant="primary btn-sm mr-2">
                            Submit
                          </Button>
                        </div> 
                          
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      }

      <div ref={videoCallRef} style={{ width: '100%', height: '700px' }}></div>
      
      

    </Fragment>
  );
}

export default AttendeeMeetingScreen;
