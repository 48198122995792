import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./index.css";
import { getPrefix } from "../../../actions/number";
import { searchDid } from "../../../actions/did";
import Numbers from "./Numbers";
import Loader from "../../../layouts/Loader";

const TollFree = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  let number_type = "toll_free_";

  const [prefixData, setPrefixData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [did, setDid] = useState([]);

  const [didData, setDidData] = useState({
    number_type: "toll-free",
    locality: "",
    area_code: "",
    limit: "21",
    prefix: "",
    createdby: createdby,
    main_admin_uuid: createdby,
    user_uuid: createdby,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(getPrefix(createdby, token))
      .then((response) => {
        if (response.status === 200) {
          searchDidFun(response.data[0].prefix);
        }
        setPrefixData(response.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const handleBlur = (e) => {
    searchDidFun(e.target.value);
  };

  const searchDidFun = (prefix) => {
    const newDiddata = { ...didData };
    newDiddata["prefix"] = prefix;
    newDiddata["prefix_arr"] = [prefix];
    setDidData(newDiddata);

    setLoading(true);

    dispatch(searchDid(newDiddata, token))
      .then((response) => {
        setDid(response.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleSelectedNumberData = (dataFromFour) => {
    props.handleSelectedNumber(dataFromFour);
  };

  const handleUnSelectedNumberData = (dataFromFour) => {
    props.handleUnSelectedNumber(dataFromFour);
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <div className="col-lg-12">
        <div className="card bgl-primary">
          <div className="card-header">
            <h4 className="card-title">Select  {props.type === "user_onboarding" ? "Number" : "Numbers"}</h4>
          </div>
          <div className="card-body card-body-new ">
            <div className="row">
              <div className="col-xl-3 col-xxl-3 col-lg-3">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-header pb-0 border-0">
                        <h3 className="fs-20 text-black">Prefix</h3>
                      </div>
                      <div className="card-body">
                        <select
                          className="form-control prefix_sel"
                          name="prefix"
                          value={didData.prefix_arr}
                          onChange={handleBlur}
                          multiple
                        >
                          <option value="">Select Prefix</option>
                          {prefixData.map((number) => (
                            <option value={number.prefix} key={number.prefix}>
                              {number.prefix}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-9 col-xxl-9 col-lg-9">
                <Numbers
                  didAll={did}
                  didsData={props.didsData}
                  didData={didData}
                  type={props.type}
                  number_type={number_type}
                  handleSelectedNumberData={handleSelectedNumberData}
                  handleUnSelectedNumberData={handleUnSelectedNumberData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TollFree;
