import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const getDncList = (
  user_type,
  group_per,
  group_uuid,
  search,
  searchData,
  off_set,
  limits,
  orderby,
  main_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "get-dnclist",
      {
        user_type,
        group_per,
        group_uuid,
        search,
        searchData,
        off_set,
        limits,
        orderby,
        createdby,
        main_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const createDncList = (data) => {
  return axios
    .post(API_URL + "create-dnclist", data, {
      headers: {
        Authorization: data.token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

// const updateDncList = (data) => {
//   return axios
//     .post(API_URL + "update-dnclist", data, {
//       headers: {
//         Authorization: data.token,
//       },
//     })
//     .then((response) => {
//       // if (response.data.status == '200') {

//       // }
//       return response.data;
//     });
// };


const deleteDncList = (dnc_list_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "delete-dnclist",
      {
        dnc_list_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const importCsv = (data, token) => {
  return axios
    .post(API_URL + "import-dnc-csv", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getMappingFields = (createdby, token, filename) => {
  return axios
    .post(
      API_URL + "get-dnc-mappingFields",
      {
        filename,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const insertCsvData = (data, token) => {
  return axios
    .post(API_URL + "insert-dnc-csv-data", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const deleteMultipleDncList = (selectedList, createdby, token) => {
  return axios
    .post(
      API_URL + "multiple-delete-dnclist",
      {
        selectedList,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

export default {
  getDncList,
  createDncList,
  //updateDncList,
  deleteDncList,
  importCsv,
  getMappingFields,
  insertCsvData,
  deleteMultipleDncList,
};
