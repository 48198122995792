import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const getUserMeetingAvailability = (createdby, token) => {
  return axios
    .post(
      API_URL + "calender/user-meeting-availability",
      {
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateUserMeetingAvailability = (update_data, token) => {
  return axios
    .post(
      API_URL + "calender/update-user-meeting-availability",
      update_data,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const craeteUserMeetingAvailability = (create_data, token) => {
  return axios
    .post(API_URL + "calender/create-user-meeting-availability", create_data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};


const deleteUserMeetingAvailability = (user_meeting_availability_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "calender/delete-user-meeting-availability",
      {
        user_meeting_availability_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getDayTimeslots = (user_uuid, day) => {
  return axios
    .post(
      API_URL + "calender/get-day-time-slots",
      {
        day,
        user_uuid
      }
    )
    .then((response) => {
      return response.data;
    });
};




export default {
  getUserMeetingAvailability,
  updateUserMeetingAvailability,
  craeteUserMeetingAvailability,
  deleteUserMeetingAvailability,
  getDayTimeslots,
};
