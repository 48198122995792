import { SET_MESSAGE, API_FAIL } from "../types";

import CalendarMeetingService from "../../services/calender/CalendarMeeting.service";


  export const scheduleMeeting = (create_data,token) => (dispatch) => {
    return CalendarMeetingService.scheduleMeeting(create_data,token).then(
      (data) => {
        if (data.status === 406) {
          return data;
        } else if (data.status !== 200) {
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        } else {
          return data;
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };


  export const getCalendarMeetings = (createdby,token) => (dispatch) => {
    return CalendarMeetingService.getCalendarMeetings(createdby,token).then(
      (data) => {        
          return data;        
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const cancelCalendarMeeting = (calendar_meeting_uuid,createdby,token) => (dispatch) => {
    return CalendarMeetingService.cancelCalendarMeeting(calendar_meeting_uuid,createdby,token).then(
      (data) => {        
          return data;        
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const reScheduleCalendarMeeting = (data,token) => (dispatch) => {
    return CalendarMeetingService.reScheduleCalendarMeeting(data,token).then(
      (data) => {        
          return data;        
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const createCalendarEvent = (data,token) => (dispatch) => {
    return CalendarMeetingService.createCalendarEvent(data,token).then(
      (data) => {        
          return data;        
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const getEventContacts = (createdby,calendar_meeting_uuid,token) => (dispatch) => {
    return CalendarMeetingService.getEventContacts(createdby,calendar_meeting_uuid,token).then(
      (data) => {        
          return data;        
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const getMeetingDetails = (user_meeting_token,type) => (dispatch) => {
    return CalendarMeetingService.getMeetingDetails(user_meeting_token,type).then(
      (data) => {        
          return data;        
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };
