
import React,{Fragment,useState,useEffect} from "react";
import { Modal,Button } from "react-bootstrap";
import { SendEmail } from "../../../actions/email/emails";
import { emailTemplateDropdown,getEmailTemplateImages } from "../../../actions/emailtemplate";
import { getUserEmailSignature } from "../../../actions/email/emailSignature";
import { contactDropdown } from "../../../actions/contacts";
import { useDispatch } from "react-redux";
import Loader from "../../../layouts/Loader";
//import { notifyInfo, notifyError } from "../../Toastr/Toastr";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import { Link } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import PermissionCheck from "../../../common/PermissionCheck";
import "grapesjs/dist/css/grapes.min.css";
import grapesjs from "grapesjs";
import grapesJSMJML from "grapesjs-mjml";
import grapesJSCKeditor from "grapesjs-plugin-ckeditor";


const TEMPLATE_IMAGE_URL = process.env.REACT_APP_SERVER_EMAIL_TEMPLATE_IMAGE_URL;
const API_URL = process.env.REACT_APP_SERVER_URL;

function ComposeMail(props) {

    const user = JSON.parse(localStorage.getItem("user"));
    const user_data = user.data.data;
    const token = user.data.token;
    const createdby = user_data.user_uuid;
    const main_uuid = user_data.main_uuid;
    const user_type = user_data.role;
    let listing_per = PermissionCheck("contact", "listing", "", "", "");

    let group_per = listing_per;
    let group_uuid = "";
    if (listing_per === "group") {
        group_uuid = user_data.group_id;
    }

    const forwardAttachments = props.forwardAttachments;

    const [loading, setLoading] = useState(false);
    const [isCc, setIsCc] = useState(false);
    const [isBcc, setIsBcc] = useState(false);
    const [isTemplate, setIsTemplate] = useState(false);
    const [ToContactOptions, setToContactOptions] = useState([]);
    const [contactOptions, setContactOptions] = useState([]);
    const [emailTemplates, setEmailTemplates] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [userSignature, setUserSignature] = useState([]);
    const [mailData,setMailData] = useState({
        to_mail : '',
        cc_mail : '',
        bcc_mail : '',
        subject : '',
        body_text : '',
    });

    const [errors,setErrors] = useState({
        to_mail : '',
        subject : '',
        body_text : '',
    });

    const [images, setImages] = useState({});
    const [body, setBody] = useState("");
    const [htmlBody, setHtmlBody] = useState("");
    const [isHtmlEdit, setIsHtmlEdit] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        let data = {
            createdby : createdby,
            main_uuid : main_uuid,
            group_uuid : group_uuid,
            user_type : user_type,
            group_per : group_per,
        }
        dispatch(
            contactDropdown(
            data,
            token
          )
        )
          .then((response) => {
            //
            let contacts = [];
            let toConatcts = [];
            response.data.data.map((contact, index) => {
                toConatcts[index] = {label:'<'+contact.first_name+' '+contact.last_name+'>'+contact.email, value: contact.contact_uuid}
                contacts[index] = {label:'<'+contact.first_name+' '+contact.last_name+'>'+contact.email, value: contact.email}
                return true;
            })

            setContactOptions(contacts);
            setToContactOptions(toConatcts);
            return;
          })
          .catch(() => {
            setLoading(false);
          });
    }, []);

    useEffect(() => {
        dispatch(
            emailTemplateDropdown(createdby,main_uuid,token)
        )
          .then((response) => {
            setEmailTemplates(response.data);

            return;
          })
          .catch(() => {
            setLoading(false);
          });
    }, []);

    useEffect(() => {
        dispatch(
            getEmailTemplateImages(createdby,token)
        )
          .then((response) => {
            let image_arr = response.data.map((image) => {
                return {
                    src: TEMPLATE_IMAGE_URL+image.image_name,
                    name: image.image_name
                }
            });

            setImages(image_arr);
            return;
          })
          .catch(() => {
            setLoading(false);
          });
    }, []);

    useEffect(() => {
        let user_uuid = createdby;
        dispatch(getUserEmailSignature(createdby,user_uuid,token))
          .then((response) => {
            if(response.data[0]){
              setUserSignature(response.data[0]); 
                
                setBody('<br><br>----<br>'+response.data[0].signature_text);
                setHtmlBody('<br><br>----<br>'+response.data[0].signature_text);
            }            
    
            return;
          })
          .catch(() => {
            
          });
    }, []);

    useEffect(() => {

        if(isHtmlEdit){
            var editor = grapesjs.init({
                fromElement: true,
                container: "#grapesjs-container",
                height: "500px",
                width: "auto",
                storageManager: false,
                avoidInlineStyle: false,
                //panels: { defaults: [] }, // Avoid default panels
                plugins: [grapesJSMJML,grapesJSCKeditor],
                pluginsOpts: {
                  [grapesJSMJML]: {},
                  'grapesjs-plugin-ckeditor': {}
                },
                assetManager: {
                    assets: images,
                    upload: API_URL + "upload-template-image",
                    uploadName: 'files',
                }
              });
      
              // Function to hide components, blocks, and devices
              const hideAll = () => {
                  // Hide components panel
                 // editor.Panels.removePanel("views-container");
          
                  // Hide blocks panel
                  //editor.Panels.removePanel("views");
          
                  // Hide devices panel
                  editor.Panels.removePanel("devices-c");
          
                  // You can also disable the device manager to prevent users from switching devices
                  editor.getConfig().deviceManager = false;
          
                  // Refresh the editor to apply changes
                  editor.render();
              };
        
            // Call the hideAll function after the editor is initialized
            hideAll();
          
          
            editor.on("update", () => {
                setBody(editor.getHtml());
                setHtmlBody(editor.Canvas.getBody().outerHTML);
            });

            const defaultContent = '<mjml><mj-body>'+body+'</mj-body></mjml>';
            //editor.setComponents(defaultContent);
        
            editor.addComponents(defaultContent);
        
            return () => {
            editor.destroy();
            };
        }
        
    }, [body,isHtmlEdit]);

    const closeModel = (value) => {
        props.handleComposeMailModal(value);
    }

    const toggleCc = () => {
        if(isCc){
            setIsCc(false);
        }else{
            setIsCc(true);
        }
    }

    const toggleBcc = () => {
        if(isBcc){
            setIsBcc(false);
        }else{
            setIsBcc(true);
        }
    }

    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file }, status) => { 
        if (status === 'done') { 

            setAttachments([...attachments,file]);
        }
    }   

    const handleBlur = (e) => {

        let new_mailData = {...mailData};
        let error_data = {...errors};

        if(e.target.name === 'email_template'){
            let template_data =  emailTemplates.filter((emailTemplate) => {
                return emailTemplate.email_template_uuid === e.target.value;        
            }); 

            if(template_data !== ''){
                new_mailData['subject'] = template_data[0].subject;

                setBody(template_data[0].body+userSignature.signature_text);
                setHtmlBody(template_data[0].html_body+userSignature.signature_text);
                new_mailData['cc'] = '';
                new_mailData['bcc'] = '';
                setMailData(new_mailData);
                setIsCc(false);
                setIsBcc(false);
                setIsTemplate(true);
            }else{

                new_mailData['subject'] = '';
            }            

        }else{

            if (e.target.value.length === 0) {            
                error_data[e.target.name] = 'Field is required.';
            } else {
                error_data[e.target.name] = '';
            }

            new_mailData[e.target.name] = e.target.value;
            setMailData(new_mailData);
            setErrors(error_data);
        }
    }

    const handleSelectTo = (data) => {
        let values = Object.values(data).map(k => k['value']);
        
        let new_mailData = {...mailData};
        new_mailData['to_mail'] = values;

        setMailData(new_mailData);
    }

    const handleSelectCc = (data) => {
        let values = Object.values(data).map(k => k['value']);
        
        let new_mailData = {...mailData};
        new_mailData['cc_mail'] = values;

        setMailData(new_mailData);
    }

    const handleSelectBcc = (data) => {
        let values = Object.values(data).map(k => k['value']);
        
        let new_mailData = {...mailData};
        new_mailData['bcc_mail'] = values;

        setMailData(new_mailData);
    }

    const submitHandler = (e) =>{
        e.preventDefault();

        let error_data = {...errors};
        let isError = "false";

        setLoading(true);

        let body_html = htmlBody;

        if (mailData.to_mail.length === 0) {
            error_data["to_mail"] = "Field is required.";
            setErrors(error_data);
            isError = "true";
        }

        if (mailData.subject.length === 0) {
            error_data["subject"] = "Field is required.";
            setErrors(error_data);
            isError = "true";
        }

        if (body_html.length === 0) {
            error_data["body_text"] = "Field is required.";
            setErrors(error_data);
            isError = "true";
        }

        if (isError === "true") {
            setLoading(false);
            return;
        } else {

            const formData = new FormData();
            formData.append('to_mail', mailData.to_mail);
            formData.append('cc_mail', mailData.cc_mail);
            formData.append('bcc_mail', mailData.bcc_mail);
            formData.append('subject', mailData.subject);
            formData.append('body_text', body_html);
            //formData.append('forwardAttachments', JSON.stringify(forwardAttachments));
            formData.append('main_uuid', main_uuid);
            formData.append('createdby', createdby);

            for (let i = 0; i < forwardAttachments.length; i++) {
                
               const buffer = Buffer.from(forwardAttachments[i].emlBuffer);
                const blob = new Blob([buffer]);

                formData.append('forwardAttachments', blob, forwardAttachments[i].file_name);

            }
            
            for (let i = 0; i < attachments.length; i++) {
                formData.append("files", attachments[i]);
            }

            // let new_mailData = {...mailData};
            // new_mailData['forwardAttachments'] = forwardAttachments;
            // new_mailData['attachments'] = attachments;
            // new_mailData['main_uuid'] = main_uuid;
            // new_mailData['createdby'] = createdby;

            dispatch(
                SendEmail(
                formData,
                token
            )
            )
            .then((response) => {
                setLoading(false);
                closeModel(false);
                return;
            })
            .catch(() => {
                setLoading(false);
            });
        }
    }

    const download = (e,file,label) => {
        e.preventDefault();

        const buffer = Buffer.from(file);
        const blob = new Blob([buffer]);
                                
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.target = '_self';
        a.download = label;
        a.click();
        window.URL.revokeObjectURL(url);
    }

    const toggleEditor = () => {
        if(isHtmlEdit){
            setIsHtmlEdit(false);
        }else{
            setIsHtmlEdit(true);
        }
    }

    return (
        <Fragment>
            {loading && <Loader />}
            <form action="" onSubmit={(e) => submitHandler(e)}>
                <Modal.Header>
                    <Modal.Title>New Email</Modal.Title>
                    <Button type="submit" variant="primary btn-sm mr-5">Send Email</Button>
                    <Button
                        variant=""
                        className="close"
                        onClick={() => closeModel(false)}
                    >
                        <span>&times;</span>
                    </Button>
                </Modal.Header>
            
                <Modal.Body>
                
                <div className="form-group input-primary">
                    <label className="mb-1">
                        <strong>To:</strong>
                    </label>

                    <CreatableSelect
                        className="form-control bg-white border-primary"
                        name="to_mail"
                        isClearable
                        isMulti
                        onChange={handleSelectTo}
                        options={ToContactOptions}
                        //value={selectedPersona}
                      /> 

                    {/* <input type="text" className="form-control bg-transparent" placeholder=" To:"  name="to_mail" onChange={handleBlur}/> */}
                    
                    {!isTemplate && 
                        <>
                            <a href="#!" className="text-info pull-right" onClick={toggleBcc}>Bcc</a>
                            <a href="#!" className="text-info pull-right mr-3" onClick={toggleCc}>Cc</a>
                        </>
                    }   

                    <span className="text-danger">
                          {errors.to_mail}
                    </span>
                </div>
                
                {isCc && 
                    <div className="form-group input-primary">
                        <label className="mb-1">
                            <strong>Cc:</strong>
                        </label>
                        <CreatableSelect
                            className="form-control bg-white border-primary"
                            name="cc_mail"
                            isClearable
                            isMulti
                            onChange={handleSelectCc}
                            options={contactOptions}
                            //value={selectedPersona}
                        /> 

                    </div>
                }

                {isBcc && 
                    <div className="form-group input-primary">
                        <label className="mb-1">
                            <strong>Bcc:</strong>
                        </label>
                        <CreatableSelect
                            className="form-control bg-white border-primary"
                            name="bcc_mail"
                            isClearable
                            isMulti
                            onChange={handleSelectBcc}
                            options={contactOptions}
                            //value={selectedPersona}
                        /> 

                    </div>
                }

                <div className="form-group input-primary">
                    <select className="form-control bg-transparent" name="email_template" onChange={handleBlur}> 
                        <option value="">Select Template</option>
                        {emailTemplates && emailTemplates.map((emailTemplate) => (
                            <option key={emailTemplate.email_template_uuid} value={emailTemplate.email_template_uuid}>{emailTemplate.template_name}</option>
                        ))

                        }
                    </select>
                    
                </div>

                <div className="form-group input-primary">
                    <input type="text" className="form-control bg-transparent" placeholder=" Subject:" name="subject" onChange={handleBlur} value={mailData.subject} />
                    <span className="text-danger">
                          {errors.subject}
                    </span>
                </div>
                
                <div className="form-group input-primary">
                    <button type="button" className="btn btn-primary btn-sm" onClick={toggleEditor}>{isHtmlEdit ? 'Preview' : 'Edit' } Message</button>
                </div>

                {!isHtmlEdit && <div dangerouslySetInnerHTML={{ __html: htmlBody }} />}
                {isHtmlEdit && <div id="grapesjs-container"></div>}

                <div className="form-group input-primary" style={{display:'none'}}>
                   
                    <span className="text-danger">
                          {errors.body_text}
                    </span>
                </div>

                <h5 className="mb-4"><i className="fa fa-paperclip"></i> Attatchment</h5>

                {forwardAttachments && forwardAttachments.map((obj,index) =>  
                    <Link
                        to="#"
                        key={index}
                        className="btn text-left btn-sm light btn-dark ml-2 mb-3"
                        onClick={(e) => download(e,obj.emlBuffer,obj.file_name)}
                    >
                        {obj.file_name}
                    </Link>
                    
                )}

                <Dropzone
                    //getUploadParams={getUploadParams}
                    onChangeStatus={handleChangeStatus}
                    //onSubmit={handleFileSubmit}
                    //accept="image/*,audio/*,video/*"
                    styles={{
                        dropzone: {
                           minHeight: 200,
                           maxHeight: 250,
                           width: "100%",
                           backgroundColor: "#f2f4fa",
                           border: "1px dashed #DDDFE1",
                           overflow: "hidden",
                        },
                        inputLabel: {
                           color: "#7e7e7e",
                           fontSize: "18px",
                           fontWeight: "normal",
                           backgroundColor: "#f2f4fa",
                        },
                     }}
                />
                
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        onClick={() => closeModel(false)}
                        variant="danger light btn-sm"
                    >
                        Close
                    </Button>
                    <Button type="submit" variant="primary btn-sm">Send Email</Button>
                </Modal.Footer>
            </form>
        </Fragment>
    );
}

export default ComposeMail;