import React, { Fragment, useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import { createTask,updateTask } from "../../actions/Tasks";
import Loader from "../../layouts/Loader";
import { getUserListDropdown } from "../../actions/comman";

function TaskForm(props) {
  const closeModel = (arg) => {
    props.closeModel(arg);
  };

  const task = props.taskUpdate;

  let page_title = "Add Task";
  if (task.action === "update") {
    page_title = "Update Task";
  }

  const times = [];
  for (let i = 0; i < 24; i++) {
    times.push(
      `${i % 12 || 12}:00 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:15 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:30 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:45 ${i < 12 ? "AM" : "PM"}`
    );
  }

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;
  let group_uuid = "";
  let group_per = 'all';

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  const dispatch = useDispatch();

  const [errors, setErrors] = useState({
    task_title: "",
    task_date: "",
    task_time: "",
    description: "",
    assign_user: "",
  });

  const [taskData, setTaskData] = useState({
    contact_task_uuid: task.contact_task_uuid,
    task_title: task.task_title,
    task_date: task.task_date,
    task_time: task.task_time,
    action: task.action,
    description: task.description,
    assign_user: task.assign_user,
    status: task.status,
    contact_uuid: task.contact_uuid,
    main_admin_uuid: main_uuid,
    createdby: createdby,
  });

  useEffect(() => {
    dispatch(
      getUserListDropdown(
        "create",
        "",
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        setUsers(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const handleBlur = (e) => {
    const newData = { ...taskData };
    let error_data = { ...errors };
    if (e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
       
      newData[e.target.name] = e.target.value;      
      setTaskData(newData);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (taskData.task_title.length === 0) {
      error_data["task_title"] = "Task is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (taskData.task_date && taskData.task_date.length === 0) {
      error_data["task_date"] = "Task date is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (taskData.task_time && taskData.task_time.length === 0) {
      error_data["task_time"] = "Task time is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (taskData.description.length === 0) {
      error_data["description"] = "Description is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (taskData.assign_user.length === 0) {
      error_data["assign_user"] = "Assign User is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {

      if (task.action === "update") {
        fnupdateTask();
      }else{
        fnaddTask();
      }
      
    }
  };

  const fnaddTask = () => {

    let error_data = { ...errors };

    // create task API
    dispatch(createTask(taskData,token))
    .then((res) => {
      closeModel(false);
      props.handleTaskEvent(Math.random());

      if (res.status === 200) {
        notifyInfo(res.message);
        closeModel(false);
      } else if (res.status === 406) {
        error_data["number"] = res.message;
        setErrors(error_data);
      } else if (res.status === 500) {
        notifyError("Something went wrong.");
      } else {
        notifyError("Something went wrong.");
      }
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
    });
  }

  const fnupdateTask = () => {

    let error_data = { ...errors };

    // create task API
    dispatch(updateTask(taskData,token))
    .then((res) => {
      closeModel(false);
      props.handleTaskEvent(Math.random());

      if (res.status === 200) {
        notifyInfo(res.message);
        closeModel(false);
      } else if (res.status === 406) {
        error_data["number"] = res.message;
        setErrors(error_data);
      } else if (res.status === 500) {
        notifyError("Something went wrong.");
      } else {
        notifyError("Something went wrong.");
      }
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
    });
  }

  return (
    <Fragment>
      {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>{page_title} </Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <form action="" onSubmit={(e) => submitHandler(e)}>
        <Modal.Body>
          <div className="row">
            <div className="col-sm">
              <div className="form-group input-primary">
                <label className="mb-1">
                  <strong>Task Title</strong>
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control bg-white"
                  name="task_title"
                  value={taskData.task_title}
                  onChange={handleBlur}
                />
                <span className="text-danger">{errors.task_title}</span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm">
              <div className="form-group input-primary">
                <label className="mb-1">
                  <strong>Task Date</strong>
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  className="form-control bg-white"
                  name="task_date"
                  value={taskData.task_date}
                  onChange={handleBlur}
                />
                <span className="text-danger">{errors.task_date}</span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm">
              <div className="form-group input-primary">
                <label className="mb-1">
                  <strong>Task Time</strong>
                  <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control bg-white"
                  name="task_time"
                  onChange={handleBlur}
                  value={taskData.task_time}
                >
                  <option value="">Select Time</option>
                  {times &&
                    times.map((time) => (
                      <option key={time} value={time}>
                        {time}
                      </option>
                    ))}
                </select>
                <span className="text-danger">{errors.task_time}</span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm">
              <div className="form-group input-primary">
                <label className="mb-1">
                  <strong>Description</strong>
                </label>
                <input
                  type="text"
                  className="form-control bg-white"
                  name="description"
                  value={taskData.description}
                  onChange={handleBlur}
                />
                <span className="text-danger">{errors.description}</span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm">
              <div className="form-group input-primary">
                <label className="mb-1">
                  <strong>Assign User</strong>
                </label>
                <select
                  className="form-control bg-white"
                  name="assign_user"
                  onChange={handleBlur}
                  value={taskData.assign_user}
                >
                  <option value="">Select User</option>
                  {users.map((user) => (
                    <option key={user.uuid} value={user.uuid}>
                      {user.user_name}
                    </option>
                  ))}
                  <option key={createdby} value={createdby}>
                    Assign Me
                  </option>
                </select>
                <span className="text-danger">{errors.assign_user}</span>
              </div>
            </div>
          </div>
          
          {task.action === "update" && 
            <div className="row">
              <div className="col-sm">
                <div className="form-group input-primary">
                  <label className="mb-1">
                    <strong>Status</strong>
                  </label>
                  <select
                    className="form-control bg-white"
                    name="status"
                    onChange={handleBlur}
                    value={taskData.status}
                  >
                      <option value="OPEN">Open</option>
                      <option value="IN_PROGRESS">In Progress</option>
                      <option value="CLOSE">Close</option>
                  </select>
                  <span className="text-danger">{errors.status}</span>
                </div>
              </div>
            </div>
          }
          
          
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => closeModel(false)}
            variant="danger light btn-sm"
          >
            Close
          </Button>
          <Button type="submit" variant="primary btn-sm">
            {task.action === "update" ? "Update" : "Add"}
          </Button>
        </Modal.Footer>
      </form>
    </Fragment>
  );
}

export default TaskForm;
