import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const getPrefix = (createdby, token) => {
  return axios
    .post(
      API_URL + "numbers/get-prefix",
      {
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getStates = (createdby, token) => {
  return axios
    .post(
      API_URL + "numbers/states",
      {
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getAreaCode = (createdby, token, state_uuid) => {
  return axios
    .post(
      API_URL + "numbers/get-area-code",
      {
        createdby,
        state_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

export default {
  getPrefix,
  getStates,
  getAreaCode,
};
