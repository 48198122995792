import React, { Fragment, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../layouts/PageTitle";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import Accordion from "./UiAccordion";
import { getPlanDetail, updatePlan } from "../../actions/plan";
import Loader from "../../layouts/Loader";

function UpdatePlanForm() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useLocation();
  const plan_uuid = params.state.plan_uuid;

  const [planData, setPlanData] = useState({
    plan_uuid: plan_uuid,
    name: "",
    status: "active",
    plan_type: "recurring",
    final_price: "",
    description: "",
    main_user_uuid: main_uuid,
    token: token,
    createdby: createdby,
  });

  const [errors, setErrors] = useState({
    name: "",
    status: "",
    plan_type: "",
  });

  const [loading, setLoading] = useState(false);
  const [modulePriceDataFinal, SeModulePriceDataFinal] = useState(false);

  const handleBlur = (e) => {
    const newUserData = { ...planData };
    let error_data = { ...errors };
    if (e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }

    newUserData[e.target.name] = e.target.value;
    setPlanData(newUserData);
  };

  const modulePriceData = (data) => {
    SeModulePriceDataFinal(data);
  };

  useEffect(() => {
    dispatch(getPlanDetail(plan_uuid, createdby, token))
      .then((response) => {
        setPlanData(response.data.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    
    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (!planData.name) {
      error_data["name"] = "Please Enter name.";
      setErrors(error_data);
      isError = "true";
    }

    if (!planData.status) {
      error_data["status"] = "Please select status.";
      setErrors(error_data);
      isError = "true";
    } else if (!/^[A-Za-z]+$/i.test(planData.status)) {
      error_data["status"] = "Invalid status.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create plan API
      dispatch(updatePlan(planData, modulePriceDataFinal, createdby, token))
        .then((res) => {
          if (res.status === 200) {
            notifyInfo(res.message);
            navigate("/plans");
          } else if (res.status === 406) {
            error_data["role_name"] = res.message;
            setErrors(error_data);
            isError = "true";
          } else if (res.status === 500 || res === 500) {
            notifyError("Something went wrong.");
          } else {
            notifyError("Something went wrong.");
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const calFinalPrice = (data) => {
    const obj = Object.entries(data);
    let final_price = 0;
    obj.forEach((data_modules, index) => {
      let data = data_modules["1"];
      data.forEach((data_m, i) => {
        if (parseInt(data_m.price) > 0 && data_m.status === "enable") {
          final_price = parseFloat(final_price) + parseFloat(data_m.price);
        }
      });
    });
    let new_arr = { ...planData };
    new_arr["final_price"] = final_price.toFixed(2);
    setPlanData(new_arr);
  };

  let action = "update";

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle activeMenu="Plan" motherMenu="Plans" />

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Create Plan</h4>
              <Button
                type="button"
                className="btn btn-default btn-sm"
                onClick={() => navigate("/plans", { replace: true })}
              >
                <i className="fa fa-reply" aria-hidden="true"></i> Back
              </Button>
            </div>
            <div className="card-body">
              <form action="" onSubmit={(e) => submitHandler(e)}>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Name</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        name="name"
                        id="name"
                        value={planData.name}
                        onChange={handleBlur}
                      />
                      <span className="text-danger">{errors.name}</span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Status</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control"
                        value={planData.status}
                        name="status"
                        onChange={handleBlur}
                      >
                        <option value="">Select Status</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive </option>
                      </select>
                      <span className="text-danger">{errors.status}</span>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <Accordion
                    planData={planData}
                    calFinalPrice={calFinalPrice}
                    modulePriceData={modulePriceData}
                    action={action}
                  />
                </div>

                {/* <div className="col-6">
                  <div className="form-group">
                    <label className="mb-1">
                      <strong>Plan Type</strong>
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control"
                      value={planData.plan_type}
                      name="plan_type"
                      onChange={handleBlur}
                      
                    >
                      <option value="recurring">Recurring </option>
                    </select>
                    <span className="text-danger">{errors.email}</span>
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Final Price</strong>
                      </label>

                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="0.00"
                          name="final_price"
                          id="final_price"
                          value={planData.final_price}
                          onChange={handleBlur}
                        />
                        {/* <div className="input-group-append">
                          <span className="input-group-text">.00</span>
                        </div> */}
                      </div>

                      <span className="text-danger">{errors.final_price}</span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Description</strong>
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Description"
                        name="description"
                        value={planData.description}
                        onChange={handleBlur}
                      ></textarea>
                      <span className="text-danger">{errors.description}</span>
                    </div>
                  </div>
                </div>
                <div className="row text-right">
                  <div className="col-sm">
                    <Button type="submit" variant="primary" className="btn-sm">
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default UpdatePlanForm;
