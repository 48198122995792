import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import "./style.css";
import { getRolePermissions } from "../../actions/role";
import Loader from "../../layouts/Loader";

function PermissionsRows(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [rolePermissions, setRolePermissions] = useState([]);
 
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;

  if (props.tabName !== "webphone") {
    useEffect(() => {
      setLoading(true);
      dispatch(
        getRolePermissions(props.role_uuid, createdby, token, props.tabName)
      )
        .then((response) => {
          setRolePermissions(response.data.data);
          props.rowData[props.tabName] = response.data.data;
          props.rowDataPass(props.rowData);
          setLoading(false);
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    }, []);
  }

  const handleChange = (tabname, i, e) => {
    var action = e.target.value;
    var type = e.target.id;
    props.rowDataa[tabname][i][type] = action;
  };

  const table_rows = [];

  rolePermissions.forEach((row, j) => {
    table_rows.push(
      <tr key={row.module_permission_uuid}>
        <td className="tbl_td">{row.module_name}</td>
        <td className="tbl_td">
          <select
            className={
              row.module_status === "disable"
                ? "form-control time_field_red"
                : "form-control time_field_hp"
            }
            name={row.module_permission_uuid}
            defaultValue={row.module_status}
            id="module_status"
            onChange={(e) => handleChange(props.tabName, j, e)}
          >
            <option value="enable">Enable</option>
            <option value="disable">Disable</option>
          </select>
        </td>

        <td className="tbl_td">
          <select
            className="form-control time_field_hp"
            name={row.module_permission_uuid}
            defaultValue={row.listing}
            id="listing"
            onChange={(e) => handleChange(props.tabName, j, e)}
          >
            <option value="all">All</option>
            <option value="owner">Owner</option>
            <option value="group">Group</option>
            <option value="none">None</option>
          </select>
        </td>

        <td className="tbl_td">
          <select
            className="form-control time_field_hp"
            name={row.module_permission_uuid}
            id="add"
            defaultValue={row.add}
            onChange={(e) => handleChange(props.tabName, j, e)}
          >
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </td>

        <td className="tbl_td">
          <select
            className="form-control time_field_hp"
            name={row.module_permission_uuid}
            defaultValue={row.edit}
            id="edit"
            onChange={(e) => handleChange(props.tabName, j, e)}
          >
            <option value="all">All</option>
            <option value="owner">Owner</option>
            <option value="group">Group</option>
            <option value="none">None</option>
          </select>
        </td>

        <td className="tbl_td">
          <select
            className="form-control time_field_hp"
            name={row.module_permission_uuid}
            defaultValue={row.delete}
            id="delete"
            onChange={(e) => handleChange(props.tabName, j, e)}
          >
            <option value="all">All</option>
            <option value="owner">Owner</option>
            <option value="group">Group</option>
            <option value="none">None</option>
          </select>
        </td>
        <td className="tbl_td">
          <select
            className="form-control time_field_hp"
            name={row.module_permission_uuid}
            defaultValue={row.view}
            id="view"
            onChange={(e) => handleChange(props.tabName, j, e)}
          >
            <option value="all">All</option>
            <option value="owner">Owner</option>
            <option value="group">Group</option>
            <option value="none">None</option>
          </select>
        </td>
      </tr>
    );
  });

  return (
    <div className="widget-media ps ps--active-y">
      {loading && <Loader />}
      <Table responsive className="w-100">
        <thead>
          <tr>
            <th></th>
            <th>Access</th>
            <th>List</th>
            <th>Add</th>
            <th>Edit</th>
            <th>Delete</th>
            <th>View</th>
          </tr>
        </thead>

        <tbody>{table_rows}</tbody>
      </Table>
    </div>
  );
}

export default PermissionsRows;
