import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { updatePassword } from "../../actions/user";
import { createExtension } from "../../actions/extensions";
import { notifyInfo, notifyError } from "../../components/Toastr/Toastr";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import Loader from "../../layouts/Loader";

function PasswordForm(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;

  const closeModel = (arg) => {
    props.closePasswordModel(arg);
  };

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [passwordData, setPasswordData] = useState({
    new_password: "",
    confirm_password: "",
    old_password: "",
    type: "onboarding",
    main_admin_uuid: user_data.main_uuid,
    createdby: user_data.user_uuid,
    user_uuid: user_data.user_uuid,
  });

  const [errors, setErrors] = useState({
    new_password: "",
    confirm_password: "",
    old_password: "",
  });

  const handleBlur = (e) => {
    const newData = { ...passwordData };
    let error_data = { ...errors };
   
    if (e.target.name === "old_password") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Old password is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "new_password") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "New password is required.";
        setErrors(error_data);
      } else {
        if (
          validator.isStrongPassword(e.target.value, {
            minLength: 8,
            minLowercase: 1,
            minUppercase: 1,
            minNumbers: 1,
            minSymbols: 1,
          })
        ) {
          error_data[e.target.name] = "";
          setErrors(error_data);
        } else {
          error_data[e.target.name] =
            "Password is weak. Should have a minimum of 8 characters. Ex. Test@123";
          setErrors(error_data);
        }
      }
    }

    if (e.target.name === "confirm_password") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Confirm password is required.";
        setErrors(error_data);
      } else {
        if (
          validator.isStrongPassword(e.target.value, {
            minLength: 8,
            minLowercase: 1,
            minUppercase: 1,
            minNumbers: 1,
            minSymbols: 1,
          })
        ) {
          error_data[e.target.name] = "";
          setErrors(error_data);
        } else {
          error_data[e.target.name] =
            "Password is weak. Should have a minimum of 8 characters. Ex. Test@123";
          setErrors(error_data);
        }
      }
    }

    newData[e.target.name] = e.target.value;
    setPasswordData(newData);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    if (passwordData.new_password === "") {
      error_data["new_password"] = "Password is required.";
      setErrors(error_data);
      isError = "true";
    } else {
      if (
        validator.isStrongPassword(passwordData.new_password, {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        error_data["new_password"] = "";
        setErrors(error_data);
      } else {
        error_data["new_password"] =
          "Password is weak. Should have a minimum of 8 characters. Ex. Test@123";
        setErrors(error_data);
      }
    }

    if (passwordData.confirm_password === "") {
      error_data["confirm_password"] = "Confirm password is required.";
      setErrors(error_data);
      isError = "true";
    } else {
      if (
        validator.isStrongPassword(passwordData.confirm_password, {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        error_data["confirm_password"] = "";
        setErrors(error_data);
      } else {
        error_data["confirm_password"] =
          "Password is weak. Should have a minimum of 8 characters. Ex. Test@123";
        setErrors(error_data);
      }
    }

    if (passwordData.old_password === "") {
      error_data["old_password"] = "Old password is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (passwordData.confirm_password !== passwordData.new_password) {
      error_data["new_password"] =
        "New Password and Confirm Password is not match.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      // setLoading(false);
      return;
    } else {
      //call create user API
      dispatch(
        updatePassword(
          passwordData.old_password,
          passwordData.new_password,
          passwordData.user_uuid,
          token,
          passwordData.type
        )
      )
        .then((res) => {
          if (res.status === 200) {
            notifyInfo(res.message);
            closeModel(false);
            const ext_pass = Math.floor(1000000 + Math.random() * 9000000);
            const extensionData = {
              extension: "",
              extension_password: ext_pass.toString(),
              voicemail_password: ext_pass.toString(),
              voicemail_enabled: "NO",
              call_waiting_enabled: "YES",
              call_waiting_timeout: "20",
              max_registrations: "1",
              dnd_enabled: "NO",
              initial_extension_ringtime: "15",
              limit_max: "1",
              limit_destination: "",
              voicemail_mail_to: "",
              voicemail_file: "",
              call_timeout: "20",
              call_screen_enabled: "",
              description: "",
              when_create: "login",
              user: user_data.user_uuid,
              createdby: user_data.user_uuid,
              main_admin_uuid: user_data.main_uuid,
              user_uuid: user_data.user_uuid,
            };

            dispatch(createExtension(extensionData, token))
              .then(() => {
                user.data.data.is_password_done = "YES";
                localStorage.setItem("user", JSON.stringify(user));
                navigate("/user-onboarding", { replace: true });
                window.location.reload();
              })
              .catch(() => {
                setLoading(false);
              });
          } else if (res.status === 203) {
            error_data["old_password"] = res.message;

            setErrors(error_data);
            // isError = "true";
          } else if (res.status === 500) {
            notifyError("Something went wrong.");
          } else {
            notifyError("Something went wrong.");
          }
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const changePasswordBtn = (e) => {
    let error_data = { ...errors };
    let isError = "false";

    if (passwordData.old_password.length === 0) {
      error_data["old_password"] = "Currant Password is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (passwordData.new_password.length === 0) {
      error_data["new_password"] = "New Password is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (passwordData.confirm_password.length === 0) {
      error_data["confirm_password"] = "Confirm Password is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (passwordData.new_password !== passwordData.confirm_password) {
      error_data["new_password"] =
        "New Password and Confirm Password is not match.";
      setErrors(error_data);
    }

    if (isError === "true") {
      // setLoading(false);
      return;
    } else {
      //call create user API
      let data = { ...passwordData };
      // data[attribute] = value;

      dispatch(
        updatePassword(
          data.old_password,
          data.new_password,
          data.user_uuid,
          token,
          passwordData.type
        )
      )
        .then((res) => {
          if (res.status === 200) {
            notifyInfo(res.message);
            closeModel(false);
            const ext_pass = Math.floor(1000000 + Math.random() * 9000000);
            const extensionData = {
              extension: "1000",
              extension_password: ext_pass.toString(),
              voicemail_password: ext_pass.toString(),
              voicemail_enabled: "NO",
              call_waiting_enabled: "YES",
              call_waiting_timeout: "20",
              max_registrations: "1",
              dnd_enabled: "NO",
              initial_extension_ringtime: "15",
              limit_max: "1",
              limit_destination: "",
              voicemail_mail_to: "",
              voicemail_file: "",
              call_timeout: "20",
              call_screen_enabled: "",
              description: "",
              when_create: "login",
              user: user_data.user_uuid,
              createdby: user_data.user_uuid,
              main_admin_uuid: user_data.main_uuid,
              user_uuid: user_data.user_uuid,
            };
            dispatch(createExtension(extensionData, token))
              .then(() => {
                navigate("/user-onboarding", { replace: true });
                window.location.reload();
              })
              .catch(() => {
                setLoading(false);
              });
          } else if (res.status === 203) {
            error_data["old_password"] = res.message;
            setErrors(error_data);
            // isError = "true";
          } else if (res.status === 500) {
            notifyError("Something went wrong.");
          } else {
            notifyError("Something went wrong.");
          }
          return;
        })
        .catch(() => {
          // setLoading(false);
        });
    }
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>Set New Password</Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <form action="" onSubmit={(e) => submitHandler(e)}>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label className="mb-1">
                  <strong>Currant Password</strong>
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="password"
                  className="form-control"
                  name="old_password"
                  id="password"
                  onChange={handleBlur}
                />
                <span className="text-danger">{errors.old_password}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label className="mb-1">
                  <strong>New Password</strong>
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="password"
                  className="form-control"
                  name="new_password"
                  id="password"
                  onChange={handleBlur}
                />
                <span className="text-danger">{errors.new_password}</span>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label className="mb-1">
                  <strong>Confirm Password</strong>
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="password"
                  className="form-control"
                  name="confirm_password"
                  id="password"
                  onChange={handleBlur}
                />
                <span className="text-danger">{errors.confirm_password}</span>
              </div>
            </div>
          </div>

          <div className="row text-right">
            <div className="col-sm">
              <Button
                type="button"
                variant="primary"
                className="btn-sm mr-2"
                onClick={(e) => changePasswordBtn()}
              >
                Update Password
              </Button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Fragment>
  );
}

export default PasswordForm;
