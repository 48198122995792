import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getDidListReassign } from "../../actions/comman";
import "./style.css";
import { Button, Modal } from "react-bootstrap";
import Loader from "../../layouts/Loader";

function ReAssignDid(props) {
  const token = props.didData.token;
  const createdby = props.didData.createdby;
  const main_uuid = props.didData.main_uuid;
  const did_number = props.didData.did_number;

  const [loading, setLoading] = useState(false);

  const closeModel = (arg) => {
    props.closeModel(arg);
  };

  const [errors, setErrors] = useState({
    did: "",
  });

  const [didData, setDidData] = useState([]);
  const [did, setDid] = useState([]);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setDid(e.target.value);
    return;
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getDidListReassign(createdby, token, main_uuid, did_number))
      .then((response) => {
        setDidData(response.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const reAssignCallarId = (did_number, extensionsData) => {
    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (did.length === 0) {
      error_data["did"] = "Please select DID Number.";
      setErrors(error_data);
      isError = "true";
    } else {
      error_data["did"] = "";
      setErrors(error_data);
      isError = "false";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      props.deleteInboundNumber("re-assign", did_number, extensionsData);
    }
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>Re Assign Inbound Number</Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col">
            <label>DID Number</label>
            <select
              className="form-control"
              name="did_number"
              id={"did_number"}
              onChange={(e) => handleChange(e)}
              value={did}
            >
              <option value="">Select DID Number</option>
              {didData &&
                didData.map((number) => (
                  <option key={number.uuid} value={number.did_number}>
                    {number.did_number}
                  </option>
                ))}
            </select>
            <span className="text-danger">{errors.did}</span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger light btn-sm" onClick={() => closeModel(false)}>
          Close
        </Button>
        <Button
          variant=""
          type="button"
          onClick={() => reAssignCallarId(did, props.extensionsData)}
          className="btn btn-sm btn-primary"
        >
          Re Assign
        </Button>
      </Modal.Footer>
    </Fragment>
  );
}

export default ReAssignDid;
