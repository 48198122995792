import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import "./style.css";
import { Button, Modal } from "react-bootstrap";
import { getPrefix } from "./../../actions/number";

function FilterForm(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;

  const [prefixData, setPrefixData] = useState([]);

  const closeModel = (arg) => {
    props.closeModel(arg);
  };

  const [didData, setDidData] = useState({
    destination_type: "",
    prefix: "",
    description: "",
  });

  const [errors, setErrors] = useState({
    route_name: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPrefix(createdby, token))
      .then((response) => {
        setPrefixData(response.data);
      })
      .catch(() => {
        
      });
  }, []);

  const handleChange = (e) => {
    let new_data = { ...didData };
    new_data[e.target.name] = e.target.value;
    setDidData(new_data);
    return;
  };

  const fnUpdateRoute = (test) => {
    let error_data = { ...errors };
    let isError = "false";

    if (
      didData.destination_type.length < 0 ||
      didData.prefix.length < 0 ||
      didData.description.length < 0
    ) {
      error_data["route_name"] = "Please search anything.";
      setErrors(error_data);
      isError = "true";
    } else {
      error_data["route_name"] = "";
      setErrors(error_data);
      isError = "false";
    }

    if (isError === "true") {
    
      return;
    } else {
      props.applySearch(didData);
    }
  };

  const fnResetFilter = () => {
    let new_data = { ...didData };
    new_data["destination_type"] = "";
    new_data["prefix"] = "";
    new_data["description"] = "";
    setDidData(new_data);
    props.applySearch(new_data);
    return;
  };

  return (
    <Fragment>
      <Modal.Header>
        <Modal.Title>Filter</Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-xl-6 col-xxl-6 col-lg-6">
            <div className="form-group ">
              <label>Destination Type</label>
              <select
                className="form-control"
                name="destination_type"
                onChange={(e) => handleChange(e)}
                value={didData.destination_type}
              >
                <option value="">Select Destination Type</option>
                <option value="ivr">IVR</option>
                <option value="extention">Extention</option>
                <option value="did">Did</option>
                <option value="voicemail">Voicemail</option>
                <option value="time_condition">Time Condition</option>
              </select>
            </div>
          </div>
          <div className="col-xl-6 col-xxl-6 col-lg-6">
            <div className="form-group ">
              <label>Prefix</label>
              <select
                className="form-control"
                name="prefix"
                value={didData.prefix}
                onChange={(e) => handleChange(e)}
              >
                <option value="">Select Prefix</option>
                {prefixData.map((number) => (
                  <option value={number.prefix} key={number.prefix}>
                    {number.prefix}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6 col-xxl-6 col-lg-6">
            <div className="form-group ">
              <label>Description contains word</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter a description"
                name="description"
                value={didData.description}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <span className="text-danger">{errors.route_name}</span>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger light btn-sm" onClick={() => fnResetFilter()}>
          Reset
        </Button>
        <Button
          variant=""
          type="button"
          onClick={() => fnUpdateRoute("")}
          className="btn btn-sm btn-primary"
        >
          Apply Search
        </Button>
      </Modal.Footer>
    </Fragment>
  );
}

export default FilterForm;
