import React from "react";

/// Image
import "./index.css"; 

const FloatBtn = ({
  onWebphone, webphone
}) => {


  return (

    <div className={`${ webphone ? "sidebar-tab move-to-left" : "sidebar-tab"} `} id="sidebar-tab">
      <div className="vertical-text" id="sidebar-tab-text" onClick={() => onWebphone()}><span className="fa fa-phone vertical-text arrow active"></span> </div>
    </div>

  );
};

export default FloatBtn;
