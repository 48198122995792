import React, { Fragment, useState, useEffect} from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  getContactGroupList,
} from "../../../actions/callcampaign";
import Loader from "../../../layouts/Loader";

function SaveSearchForm(props) {

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  
  const closeModel = (arg) => {
    props.closeSaveSearchModel(arg);
  };

  const dispatch = useDispatch();

  const [groupData, setGroupData] = useState({
    group_name : "",
    group_uuid : "",
    type : "",
  });
  const [groupNameError, setGroupNameError] = useState('');
  const [groupError, setGroupError] = useState('');

  const [loading, setLoading] = useState(false);
  const [contactGroupList, setContactGroupList] = useState([]);

  useEffect(() => {
    setLoading(true);
    dispatch(getContactGroupList(main_uuid, createdby, token))
      .then((response) => {
        setContactGroupList(response.data);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const handleBlur = (e) => {
    if(e.target.value === ''){
      if(e.target.name === 'group_name'){
        setGroupNameError('Enter group name.');
      }

      if(e.target.name === 'group_uuid'){
        setGroupError('Please select group .');
      }
      
    }else{
      let new_arr = {
        group_name : "",
        group_uuid : "",
        type : "",
      };

      new_arr[e.target.name] = e.target.value;

      if(e.target.name === 'group_name'){
        new_arr['type'] = 'new_added';
      }

      if(e.target.name === 'group_uuid'){
        new_arr['type'] = 'update';
      }

      setGroupData(new_arr);
      setGroupNameError('');
    }
    
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if(groupData.group_name === '' && groupData.group_uuid === ''){
      setGroupError('Please select group or Enter group name .');
    }else{
      props.saveSearch(groupData);
      setGroupNameError('');
      closeModel(false);
    }    
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>Save Contact Search</Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <form action="" onSubmit={(e) => submitHandler(e)}>
        <Modal.Body>
          <div className="row">           
            <div className="col-sm">
              {" "}
              

              <div className="form-group">
                <label className="mb-1">
                  <strong>Select Group</strong>
                  <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  name="group_uuid"
                  id="group_uuid"
                  onChange={handleBlur}
                >
                  <option value="">Select Group</option>
                  {contactGroupList.map((group) => (
                    <option value={group.value} key={group.value}>{group.label}</option>
                  ))}
                </select>
                <span className="text-danger">{groupError}</span>
              </div>

              <p className="text-center font-w700">OR</p>

              <div className="form-group">
                <label className="mb-1">
                  <strong>Group/Saved Search Name</strong>
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  //placeholder="Group/Saved Search Name"
                  name="group_name"
                  onChange={handleBlur}
                />
                <span className="text-danger">{groupNameError}</span>
              </div>

            </div>
            
          </div>
         
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => closeModel(false)} variant="danger light btn-sm">
            Close
          </Button>
          <Button type="submit" value = "save" variant="primary btn-sm">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Fragment>
  );
}

export default SaveSearchForm;
