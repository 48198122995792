import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import PermissionCheck from "../../common/PermissionCheck";

import {
    getWebphoneContact,
  } from "../../actions/contacts";

const AutocompleteTextBox = (props) => {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const dispatch = useDispatch();  

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;
  const module_name = "contacts";
  // let module_per = CheckModulePermisson(module_name);
  let listing_per = PermissionCheck(module_name, "listing", "", "", "");

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }

  const fetchSuggestions = async (value) => {
    // Make an HTTP request to the server to fetch suggestions based on the input value
    const data = {
        limits: 10,
        off_set: 0,
        orderby: "c.created_at DESC",
        group_per: group_per,
        group_uuid: group_uuid,
        user_type: user_type,
        token: token,
        createdby: createdby,
        main_uuid: main_uuid,
        user_uuid: createdby,
        search : value
    }
       
    dispatch(getWebphoneContact(data))
    .then((response) => {
        setSuggestions(response.data.data);
        return;
    })
    .catch(() => {
        
    });
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    fetchSuggestions(value);
  };

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion.first_name+' '+suggestion.last_name);
    props.setSelectedContact(suggestion);
    setSuggestions([]);
  };

  return (

    <div className="form-group mb-0 p-2">
      <input type="text" value={inputValue} className="form-control" placeholder="Type to search..." onChange={handleInputChange} />

      <ul className="list-group" style={{position : 'fixed',zIndex: '9',width: '96%'}}>
        {suggestions.map((suggestion, index) => (
          <li key={suggestion.contact_uuid} onClick={() => handleSuggestionClick(suggestion)} className="list-group-item" style={{backgroundColor : 'white'}}>
            {suggestion.first_name} {suggestion.last_name}
          </li>
        ))}
      </ul>

          {/* <div className="pr-1 col-sm-10 input-success-o input-group-lg">
        
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              className="form-control input-rounded"
              placeholder="Type to search..."
            />
            <ul className="list-group" style={{position : 'fixed',zIndex: '9',width: '16%'}}>
              {suggestions.map((suggestion, index) => (
                <li key={suggestion.contact_uuid} onClick={() => handleSuggestionClick(suggestion)} className="list-group-item" style={{backgroundColor : 'white'}}>
                  {suggestion.first_name} {suggestion.last_name}
                </li>
              ))}
            </ul>
          </div> */}

    </div>  

    
  );
};

export default AutocompleteTextBox;
