import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import { updateRinggroup } from "../../actions/ringgroups";
import { getMusicOnHoldFile } from "../../actions/did";
import { getRecordingList } from "../../actions/recording";
import "./style.css";
import Tooltip from "rc-tooltip";
import EasyEdit, { Types } from "react-easy-edit";
import Loader from "../../layouts/Loader";

function AdvanceFields(props) {
  const secondsToHms = (d) => {
    d = Number(d);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var mDisplay = m > 0 ? m + (s > 0 ? " min, " : " min ") : "";
    var sDisplay = s > 0 ? s + (s === 1 ? " sec" : " sec") : "";
    return mDisplay + sDisplay;
  };

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  let ringgroupData = props.ringgroupData;

  let answer_time_number_array = new Array(20).fill(15).map((_, i) => {
    return { label: secondsToHms((i + 1) * 15), value: (i + 1) * 15 };
  });
  const [musicOnHold, setMusicOnHold] = useState([]);
  const [greetings, setGreetings] = useState([]);
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(getMusicOnHoldFile(createdby, main_uuid, token))
      .then((response) => {
        let arrayObj = [];
        if (response.data !== "") {
          let i = 0;
          arrayObj = response.data.map((item) => {
            i = i + 1;
            return {
              label: item.recording_name,
              value: item.recording_uuid,
            };
          });

          let ddd = {
            label: "Add New File",
            value: "file_new_upload",
          };
          arrayObj[i] = ddd;

          setMusicOnHold(arrayObj);
        } else {
          let ddd = {
            label: "Add New File",
            value: "file_new_upload",
          };
          arrayObj[0] = ddd;
          setMusicOnHold(arrayObj);
        }
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(getRecordingList(createdby, token, "vm_greeting"))
      .then((response) => {
        // /setGreetings(response.data.data);
        //return;
        let arrayObj = [];
        if (response.data.data !== "") {
          let i = 0;
          arrayObj = response.data.data.map((item) => {
            i = i + 1;
            return {
              label: item.recording_name,
              value: item.recording_uuid,
            };
          });

          let ddd = {
            label: "Add New File",
            value: "file_new_upload",
          };
          arrayObj[i] = ddd;

          setGreetings(arrayObj);
        } else {
          let ddd = {
            label: "Add New File",
            value: "file_new_upload",
          };
          arrayObj[0] = ddd;

          setGreetings(arrayObj);
        }
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const handleChange = (e, default_val) => {
    let value = "";
    if (default_val === "YES") {
      value = "NO";
    } else {
      value = "YES";
    }

    save(value, e.target.name);
  };

  const save = (value, attribute) => {
    if (attribute === "ring_group_music_on_hold" && value === "file_new_upload") {
      navigate(`/upload-recording`, {
        state: {
          defaultTab: "moh",
          type: "update_ringgroup",
          ring_group_uuid: ringgroupData.ring_group_uuid,
        },
      });
      return false;
    }

    if (attribute === "ring_group_greeting" && value === "file_new_upload") {
      navigate(`/upload-recording`, {
        state: {
          defaultTab: "vm_greeting",
          type: "update_ringgroup",
          ring_group_uuid: ringgroupData.ring_group_uuid,
        },
      });
      return false;
    }

    let data = { ...ringgroupData };
    data["createdby"] = createdby;
    data["main_admin_uuid"] = ringgroupData.main_admin_uuid;
    data["user_uuid"] = createdby;
    data["ring_group_uuid"] = ringgroupData.ring_group_uuid;
    data[attribute] = value;
    data["attribute"] = attribute;
    data["ring_group_destinations"] = [];
    //return false;
    setLoading(true);
    dispatch(updateRinggroup(data, token))
      .then((res) => {
        if (res.status === 200) {
          notifyInfo(res.message);
          props.changeRingGroupEvent(Math.random);
        } else if (res.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const CustomDisplay = (props) => {
    let value = "None";

    return <p>{props.time > 0 ? secondsToHms(props.time) : value}</p>;
  };

  const CustomGreetings = (props) => {
    function inputonChange(e) {
      if (e.target.value === "file_new_upload") {
        navigate(`/upload-recording`, {
          state: {
            defaultTab: "vm_greeting",
            type: "update_ringgroup",
            ring_group_uuid: ringgroupData.ring_group_uuid,
          },
        });
        return false;
      }

      props.setParentValue(e.target.value);
    }

    return (
      <div>
        <select
          name="ring_group_greeting"
          className="form-control p-0"
          onChange={inputonChange}
          defaultValue={ringgroupData.ring_group_greeting}
          style={{ height: "35px" }}
        >
          {greetings.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const CustomMoh = (props) => {
    function inputonChange(e) {
      if (e.target.value === "file_new_upload") {
        navigate(`/upload-recording`, {
          state: {
            defaultTab: "moh",
            type: "update_ringgroup",
            ring_group_uuid: ringgroupData.ring_group_uuid,
          },
        });
        return false;
      }

      props.setParentValue(e.target.value);
    }

    return (
      <div>
        <select
          name="ring_group_music_on_hold"
          className="form-control p-0"
          onChange={inputonChange}
          defaultValue={ringgroupData.ring_group_music_on_hold}
          style={{ height: "35px" }}
        >
          {musicOnHold.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <div className="row">
        <div className="col-lg-4 col-md-4 col-6">
          <div className="card">
            <div className="card-body">
              <div className="form-group customToggle">
                <label className="w-100">
                  Greeting to Caller
                  <Tooltip
                    placement="top"
                    overlay={<span>Greeting to Caller</span>}
                  >
                    <span className="ti-info-alt ml-1"></span>
                  </Tooltip>
                </label>

                <p>
                  {ringgroupData.enable_caller_greeting}
                  <span className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      name="enable_caller_greeting"
                      id="enable_caller_greeting"
                      checked={
                        ringgroupData.enable_caller_greeting === "YES"
                          ? "checked"
                          : ""
                      }
                      onChange={(e) =>
                        handleChange(e, ringgroupData.enable_caller_greeting)
                      }
                    />
                    <label
                      className="custom-control-label cursor-pointer"
                      htmlFor="enable_caller_greeting"
                    ></label>
                  </span>
                </p>

                {ringgroupData.enable_caller_greeting === "YES" && (
                  <EasyEdit
                    type={Types.SELECT}
                    onSave={(value) => {
                      save(value, "ring_group_greeting");
                    }}
                    //onCancel={cancel}
                    options={greetings}
                    editComponent={<CustomGreetings />}
                    saveButtonLabel={
                      <i className="fa fa-check" aria-hidden="true"></i>
                    }
                    saveButtonStyle="label-primary easy-edit-button rounded-lg"
                    cancelButtonLabel={
                      <i className="fa fa-times" aria-hidden="true"></i>
                    }
                    cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                    attributes={{
                      name: "ring_group_greeting",
                      className: "form-control p-0 music_on_hold",
                      style: { height: "35px" },
                      //onChange:{handleChange1}
                    }}
                    //onValidate={value => {console.log(value); return true}}
                    placeholder="None"
                    value={ringgroupData.ring_group_greeting}
                  />
                )}
              </div>

              <div className="form-group customToggle">
                <label className="w-100">
                  Call Confirm
                  <Tooltip placement="top" overlay={<span>Call Confirm</span>}>
                    <span className="ti-info-alt ml-1"></span>
                  </Tooltip>
                </label>

                <p>
                  {ringgroupData.call_confirm}
                  <span className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      name="call_confirm"
                      id="call_confirm"
                      checked={
                        ringgroupData.call_confirm === "YES" ? "checked" : ""
                      }
                      onChange={(e) =>
                        handleChange(e, ringgroupData.call_confirm)
                      }
                    />
                    <label
                      className="custom-control-label cursor-pointer"
                      htmlFor="call_confirm"
                    ></label>
                  </span>
                </p>
              </div>

              <div className="form-group customToggle">
                <label className="w-100">
                  Music on Hold
                  <Tooltip placement="top" overlay={<span>Music on Hold</span>}>
                    <span className="ti-info-alt ml-1"></span>
                  </Tooltip>
                </label>

                <EasyEdit
                  type={Types.SELECT}
                  onSave={(value) => {
                    save(value, "ring_group_music_on_hold");
                  }}
                  //onCancel={cancel}
                  options={musicOnHold}
                  editComponent={<CustomMoh />}
                  saveButtonLabel={
                    <i className="fa fa-check" aria-hidden="true"></i>
                  }
                  saveButtonStyle="label-primary easy-edit-button rounded-lg"
                  cancelButtonLabel={
                    <i className="fa fa-times" aria-hidden="true"></i>
                  }
                  cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                  attributes={{
                    name: "ring_group_music_on_hold",
                    className: "form-control p-0 music_on_hold",
                    style: { height: "35px" },
                  }}
                  placeholder="None"
                  value={ringgroupData.ring_group_music_on_hold}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-4 col-6">
          <div className="card">
            <div className="card-body">
              <div className="form-group customToggle">
                <label className="w-100">
                  Skip Busy Extension
                  <Tooltip
                    placement="top"
                    overlay={<span>Skip Busy Extension</span>}
                  >
                    <span className="ti-info-alt ml-1"></span>
                  </Tooltip>
                </label>

                <p>
                  {ringgroupData.skip_buy_extension}
                  <span className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      name="skip_buy_extension"
                      id="skip_buy_extension"
                      checked={
                        ringgroupData.skip_buy_extension === "YES"
                          ? "checked"
                          : ""
                      }
                      onChange={(e) =>
                        handleChange(e, ringgroupData.skip_buy_extension)
                      }
                    />
                    <label
                      className="custom-control-label cursor-pointer"
                      htmlFor="skip_buy_extension"
                    ></label>
                  </span>
                </p>
              </div>

              <div className="form-group customToggle">
                <label className="w-100">
                  Position Announcement
                  <Tooltip
                    placement="top"
                    overlay={<span>Position Announcement</span>}
                  >
                    <span className="ti-info-alt ml-1"></span>
                  </Tooltip>
                </label>

                <p>
                  {ringgroupData.position_announcement}
                  <span className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      name="position_announcement"
                      id="position_announcement"
                      checked={
                        ringgroupData.position_announcement === "YES"
                          ? "checked"
                          : ""
                      }
                      onChange={(e) =>
                        handleChange(e, ringgroupData.position_announcement)
                      }
                    />
                    <label
                      className="custom-control-label cursor-pointer"
                      htmlFor="position_announcement"
                    ></label>
                  </span>
                </p>
              </div>

              <div className="form-group customToggle">
                <label className="w-100">
                  Caller Approximate Hold Time
                  <Tooltip
                    placement="top"
                    overlay={<span>Caller Approximate Hold Time</span>}
                  >
                    <span className="ti-info-alt  ml-1"></span>
                  </Tooltip>
                </label>

                <p>
                  {ringgroupData.caller_approx_time}
                  <span className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      name="caller_approx_time"
                      id="caller_approx_time"
                      checked={
                        ringgroupData.caller_approx_time === "YES"
                          ? "checked"
                          : ""
                      }
                      onChange={(e) =>
                        handleChange(e, ringgroupData.caller_approx_time)
                      }
                    />
                    <label
                      className="custom-control-label cursor-pointer"
                      htmlFor="caller_approx_time"
                    ></label>
                  </span>
                </p>

                {/* <EasyEdit
                                    type={Types.SELECT}
                                    onSave={value => {
                                        save(value,'caller_approx_time')
                                    }}
                                    //onCancel={cancel}
                                    options={number_array}
                                    saveButtonLabel={<i className="fa fa-check" aria-hidden="true"></i>}
                                    saveButtonStyle = 'label-primary easy-edit-button rounded-lg'
                                    cancelButtonLabel={<i className="fa fa-times" aria-hidden="true"></i>}
                                    cancelButtonStyle= 'label-danger easy-edit-button rounded-lg'
                                    attributes={{ name: 'caller_approx_time', className : 'form-control p-0', style:{ height: '35px'}}}
                                    placeholder="None"
                                    viewAttributes={{ className:'pull-left' }}
                                    value={ringgroupData.caller_approx_time}
                                />  
                                {ringgroupData.caller_approx_time > 0 && <span className="ml-1 font-w600 text-black">Min</span>} */}
              </div>

              {/* <div className="form-group customToggle">

                                <label className="w-100">Play Hold Time
                                    <Tooltip
                                        placement="top"
                                        overlay={<span>Play Hold Time</span>}
                                    >
                                        <span className="ti-info-alt ml-1"></span>                                    
                                    </Tooltip>
                                </label> 
                                
                                <p>{ringgroupData.play_hold_time} 
                                    <span className="custom-control custom-switch">

                                        <input type="checkbox" className="custom-control-input" name="play_hold_time" id="play_hold_time"  checked={(ringgroupData.play_hold_time == 'YES') ? 'checked' : ''} onChange={(e) => handleChange(e,ringgroupData.play_hold_time)}/>
                                         <label className="custom-control-label cursor-pointer" htmlFor="play_hold_time"></label>
                                    </span>
                                </p>
                            </div> */}

              {/* <div className="form-group customToggle">

                                <label className="w-100">Greetings Message
                                    <Tooltip
                                        placement="top"
                                        overlay={<span>Greetings Message</span>}
                                    >
                                        <span className="ti-info-alt ml-1"></span>                                    
                                    </Tooltip>
                                </label>

                                <EasyEdit
                                    type={Types.SELECT}
                                    onSave={(value) => {
                                    save(value, "ring_group_greeting");
                                    }}
                                    //onCancel={cancel}
                                    options={greetings}
                                    saveButtonLabel={
                                    <i className="fa fa-check" aria-hidden="true"></i>
                                    }
                                    saveButtonStyle="label-primary easy-edit-button rounded-lg"
                                    cancelButtonLabel={
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                    }
                                    cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                                    attributes={{
                                    name: "ring_group_greeting",
                                    className: "form-control p-0 music_on_hold",
                                    style: { height: "35px" },
                                    }}
                                    placeholder="Music On Hold"
                                    value={ringgroupData.ring_group_greeting}
                                />

                            </div> */}
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-4 col-6">
          <div className="card">
            <div className="card-body">
              <div className="form-group customToggle">
                <label className="w-100">
                  Placeholder Callback
                  <Tooltip
                    placement="top"
                    overlay={<span>Placeholder Callback</span>}
                  >
                    <span className="ti-info-alt ml-1"></span>
                  </Tooltip>
                </label>

                <p>
                  {ringgroupData.placeholder_callback}
                  <span className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      name="placeholder_callback"
                      id="placeholder_callback"
                      checked={
                        ringgroupData.placeholder_callback === "YES"
                          ? "checked"
                          : ""
                      }
                      onChange={(e) =>
                        handleChange(e, ringgroupData.placeholder_callback)
                      }
                    />
                    <label
                      className="custom-control-label cursor-pointer"
                      htmlFor="placeholder_callback"
                    ></label>
                  </span>
                </p>
              </div>

              <div className="form-group">
                <label className="w-100">
                  Service Level Agreement (SLA)
                  <Tooltip
                    placement="top"
                    overlay={<span>Service Level Agreement (SLA)</span>}
                  >
                    <span className="ti-info-alt  ml-1"></span>
                  </Tooltip>
                </label>

                <EasyEdit
                  type={Types.SELECT}
                  onSave={(value) => {
                    save(value, "service_answer_time");
                  }}
                  //onCancel={cancel}
                  displayComponent={
                    <CustomDisplay time={ringgroupData.service_answer_time} />
                  }
                  options={answer_time_number_array}
                  saveButtonLabel={
                    <i className="fa fa-check" aria-hidden="true"></i>
                  }
                  saveButtonStyle="label-primary easy-edit-button rounded-lg"
                  cancelButtonLabel={
                    <i className="fa fa-times" aria-hidden="true"></i>
                  }
                  cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                  attributes={{
                    name: "service_answer_time",
                    className: "form-control p-0",
                    style: { height: "35px" },
                  }}
                  placeholder="None"
                  viewAttributes={{ className: "pull-left" }}
                  value={ringgroupData.service_answer_time}
                />
                {/* {ringgroupData.service_answer_time}  */}
                {/* {ringgroupData.service_answer_time > 0 && <span className="ml-1 font-w600 text-black">Sec</span>} */}
              </div>

              {/* <div className="form-group customToggle">

                                <label className="w-100">Follow Me
                                    <Tooltip
                                        placement="top"
                                        overlay={<span>Follow Me</span>}
                                    >
                                        <span className="ti-info-alt ml-1"></span>                                    
                                    </Tooltip>
                                </label> 
                                
                                <p>{ringgroupData.follow_me} 
                                    <span className="custom-control custom-switch">

                                        <input type="checkbox" className="custom-control-input" name="follow_me" id="follow_me"  checked={(ringgroupData.follow_me == 'YES') ? 'checked' : ''} onChange={(e) => handleChange(e,ringgroupData.follow_me)}/>
                                         <label className="custom-control-label cursor-pointer" htmlFor="follow_me"></label>
                                    </span>
                                </p>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AdvanceFields;
