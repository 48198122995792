import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Table } from "react-bootstrap";
import Draggable from "react-draggable";
import { getExtensions } from "../../actions/extensions";
import PermissionCheck from "../../common/PermissionCheck";
import PerfectScrollbar from "react-perfect-scrollbar";

const Extensions = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;
  const module_name = "extensions";
  let listing_per = PermissionCheck(module_name, "listing", "", "", "");

  let group_per = listing_per;
  let group_uuid = "";
  if (
    listing_per === "group" &&
    user_data.group_id !== "" &&
    user_data.group_id !== null
  ) {
    group_uuid = user_data.group_id;
  }

  let limits = "show_all";
  let offSet = 0;
  let orderby = "e.extension ASC";

  let isExtensionListShow = props.isExtensionListShow;
  let isDialpadShow = props.isDialpadShow;

  const extensionListToggal = props.extensionListToggal;
  const extensionListkey = props.extensionListkey;

  const { EXTposition } = props.extensionListPosition;

  const [extensions, setExtensions] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [extensionEvent, setExtensionEvent] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getExtensions(
        user_type,
        group_per,
        group_uuid,
        user_data.role,
        listing_per,
        user_data.role_uuid,
        searchVal,
        offSet,
        limits,
        orderby,
        main_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        setExtensions(response.data.data);

        return;
      })
      .catch(() => {});
  }, [extensionEvent]);

  const searchExtension = (e) => {
    setExtensionEvent(Math.random);
  };

  const setSearchExtension = (e) => {
    setSearchVal(e.target.value);
  };

  return (
    <Draggable
      handle=".handle_ext"
      defaultPosition={EXTposition}
      position={null}
      grid={[1, 1]}
      onDrag={props.handleExtensionDrag}
      key={extensionListkey} // Adding the key here
    >
      <div
        className={`bgl-success handle_ext ${
          isDialpadShow === "YES" || isExtensionListShow === "NO"
            ? "position-absolute"
            : ""
        } ${extensionListToggal === "showlist" ? "d-block" : "d-none"}`}
        style={{ top: "0px" }}
      >
        {/* <input type='text' name="search_ext" className="form-control w-75" value={searchVal} onchange={searchExtension} /> */}
        <button
          type="button"
          className="badge badge-circle badge-danger pull-right"
          onClick={() => props.toggalExtensionList("")}
        >
          <span>×</span>
        </button>

        <div className="input-group mt-3">
          <input
            type="text"
            className="form-control h-75"
            value={searchVal}
            onChange={setSearchExtension}
          />
          <div className="input-group-append">
            <button
              className="btn btn-primary btn-light btn-sm"
              type="button"
              onClick={searchExtension}
            >
              <i className="fa fa-search" aria-hidden="true"></i>
            </button>
          </div>
        </div>

        <PerfectScrollbar
          className={`p-0 dz-scroll "ps ps--active-y`}
          id="DZ_W_Contacts_Body1"
          style={{ height: "307px" }}
        >
          <Table responsive className="w-100">
            <tbody>
              {extensions &&
                extensions.map((extension) => (
                  <tr key={extension.extension_uuid}>
                    <td>{extension.username}</td>
                    <td>{extension.extension}</td>
                    <td>
                      <button className="p-0 btn ml-1 text-green">
                        <i className="fa fa-circle"></i>
                      </button>
                      <button
                        className="p-0 btn ml-1 text-green"
                        onClick={(e) =>
                          props.dailNumber(e, extension.extension)
                        }
                      >
                        <i className="fa fa-phone"></i>
                      </button>
                      <button className="p-0 btn ml-1 text-green">
                        <i className="fa fa-comment-o"></i>
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </PerfectScrollbar>
      </div>
    </Draggable>
  );
};

export default Extensions;
