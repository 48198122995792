import React, { Fragment, useState } from "react";
import { Table, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../layouts/PageTitle";
import Loader from "../../layouts/Loader";
import { confirmAlert } from "react-confirm-alert";
import {
  updateRecording,
  MohFileDelete,
  MohFileUpdate,
} from "../../actions/recording";
import Tooltip from "rc-tooltip";
import { notifyError } from "../Toastr/Toastr";

const RECORDING_JS_URL = process.env.REACT_APP_SERVER_RECORDING_URL;

function UpdateMohForm() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const domain_name = user_data.domain_name;

  const MAX_FILE_SIZE = 10240; // 5MB

  const location = useLocation();
  let defaultTab = "moh";

  let recording = location.state.recording;

  let type = location.state.type;
  let ring_group_uuid = location.state.ring_group_uuid;

  const [file, setFile] = useState([]);
  const [mohs, setMohs] = useState(recording.moh_file);

  const [recordingData, setRecordingData] = useState({
    recording_name: recording.recording_name,
    recording_type: "moh",
    file: "",
    createdby: createdby,
    main_admin_uuid: main_uuid,
    user_uuid: createdby,
    recording_uuid: recording.recording_uuid,
  });

  const [errors, setErrors] = useState({
    recording_name: "",
    file: "",
  });

  const [loading, setLoading] = useState(false);
  const [draggedRowIndex, setDraggedRowIndex] = useState(null);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBlur = (e) => {
    const newRecordingData = { ...recordingData };
    let error_data = { ...errors };

    if (e.target.name === "recording_name" && e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }

    // const MIN_FILE_SIZE = 1024 // 1MB
    const MAX_FILE_SIZE = 10240; // 5MB

    if (e.target.name === "file") {
      for (let i = 0; i < e.target.files.length; i++) {
        if (
          e.target.files[i].type === "audio/wav" ||
          e.target.files[i].type === "audio/mpeg" ||
          e.target.files[i].type === "audio/x-m4a"
        ) {
          const fileSizeKiloBytes = e.target.files[0].size / 1024;

          if (fileSizeKiloBytes > MAX_FILE_SIZE) {
            // setErrorMsg("File size is greater than maximum limit");
            error_data[e.target.name] =
              "File size not allow greater then 10MB.";
            setErrors(error_data);
            return;
          }
        } else {
          error_data[e.target.name] =
            e.target.name + " allow only waw and mp3.";
          setErrors(error_data);
          return;
        }
      }
      if (error_data["file"] === "") {
        setFile(e.target.files);
      }
    }

    if (e.target.name === "recording_name") {
      newRecordingData[e.target.name] = e.target.value;
    }

    setRecordingData(newRecordingData);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (recordingData.recording_name.length === 0) {
      error_data["recording_name"] = "Recording name is required.";
      setErrors(error_data);
      isError = "true";
    }

    for (let i = 0; i < file.length; i++) {
      if (
        file[i].type === "audio/wav" ||
        file[i].type === "audio/mpeg" ||
        file[i].type === "audio/x-m4a"
      ) {
        const fileSizeKiloBytes = file[0].size / 1024;

        if (fileSizeKiloBytes > MAX_FILE_SIZE) {
          // setErrorMsg("File size is greater than maximum limit");
          error_data[e.target.name] = "File size not allow greater then 10MB.";
          setErrors(error_data);
          isError = "true";
        }
      } else {
        error_data[e.target.name] = e.target.name + " allow only waw and mp3.";
        setErrors(error_data);
        isError = "true";
      }
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create user API

      const formData = new FormData();

      formData.append("fileName", file.name);
      formData.append("domain_name", domain_name);
      formData.append("recording_name", recordingData.recording_name);
      formData.append("recording_type", recordingData.recording_type);
      formData.append("token", recordingData.token);
      formData.append("createdby", recordingData.createdby);
      formData.append("main_admin_uuid", recordingData.main_admin_uuid);
      formData.append("user_uuid", recordingData.user_uuid);
      formData.append("recording_uuid", recordingData.recording_uuid);
      for (let i = 0; i < file.length; i++) {
        formData.append("file", file[i]);
      }
     

      dispatch(updateRecording(formData, token))
        .then((res) => {
          if (res === undefined) {
            navigate("/recordings", {
              state: { defaultTab: defaultTab },
              replace: true,
            });
          }

          if (res.status !== 200) {
            error_data["recording_name"] = res.message;
            setErrors(error_data);
            isError = "true";
          } else {
            if (type === "upload_recording") {
              navigate("/recordings", {
                state: { defaultTab: defaultTab },
                replace: true,
              });
            } else if (type === "update_ringgroup") {
              navigate("/view-ringgroup", {
                state: {
                  ring_group_uuid: ring_group_uuid,
                  activeTab: "advanced_options",
                },
                replace: true,
              });
            } else {
              navigate("/recordings", {
                state: { defaultTab: defaultTab },
                replace: true,
              });
            }
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const navigate_back = () => {
    navigate("/recordings", {
      state: { defaultTab: defaultTab },
      replace: true,
    });
    return;
  };

  const deleteMohFile = (uuid) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this file?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteMohService(uuid),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteMohService = (uuid) => {
    setLoading(true);
    dispatch(MohFileDelete(uuid, createdby, token))
      .then((response) => {
        if (response.status === 200) {
          setMohs(response.data);

          
          setLoading(false);
          return;
        } else if (response.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleDragStart = (e, index) => {
    setDraggedRowIndex(index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault(); // Necessary for allowing a drop
  };

  const handleDrop = (e, index) => {
    e.preventDefault();

    const draggedRow = mohs[draggedRowIndex];
    const remainingRows = mohs.filter((_, i) => i !== draggedRowIndex);
    const updatedRows = [
      ...remainingRows.slice(0, index),
      draggedRow,
      ...remainingRows.slice(index),
    ];

    dispatch(MohFileUpdate(updatedRows, createdby, token))
      .then((response) => {
        if (response.status === "200") {
          //console.log(response.data);
          setMohs(response.data);

          return;
        } else if (response.status === "500") {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const breadCurmList = [
    { label: "Calls", link: "/", is_active: "NO" },
    {
      label: "Audio Files",
      link: "/recordings",
      state: { defaultTab: defaultTab },
      is_active: "NO",
    },
    { label: "Update Moh", link: "", is_active: "YES" },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Update Moh</h4>

              <Button
                type="button"
                className="btn btn-default btn-sm"
                onClick={() => navigate_back()}
              >
                <i className="fa fa-reply" aria-hidden="true"></i> Back
              </Button>
            </div>
            <div className="card-body">
              <form action="" onSubmit={(e) => submitHandler(e)}>
                <div className="row">
                  <div className="col-sm">
                    <div className="form-group input-primary">
                      <label className="mb-1">
                        <strong>Recording Name</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control bg-white"
                        //placeholder="Recording Name"
                        name="recording_name"
                        id="recording_name"
                        onChange={handleBlur}
                        value={recordingData.recording_name}
                      />
                      <span className="text-danger">
                        {errors.recording_name}
                      </span>
                    </div>
                  </div>

                  <div className="col-sm">
                    <div className="form-group input-primary">
                      <label
                        className="mb-1"
                        //title="Allow only mp3, wav, m4a file and file size shoud be less then 10 MB."
                      >
                        <strong>Upload multiple Recording File</strong>
                        <Tooltip
                          placement="top"
                          overlay={
                            <span>
                              Allow only mp3, wav, m4a file and file size shoud
                              be less then 10 MB.
                            </span>
                          }
                        >
                          <span className="ti-info-alt ml-1"></span>
                        </Tooltip>
                      </label>
                      <input
                        type="file"
                        className="form-control bg-white"
                        name="file"
                        onChange={handleBlur}
                        multiple
                      />

                      <small>
                        Allow only mp3, wav, m4a file and file size shoud be
                        less then 10 MB.
                      </small>
                      <span className="text-danger">{errors.file}</span>
                    </div>
                  </div>

                  <div className="col-sm-1 text-center pt-2">
                    <Button type="submit" variant="primary btn-sm mt-4">
                      Save
                    </Button>
                  </div>
                </div>

                <div className="row mt-3">
                  <Table responsive className="w-100">
                    <thead>
                      <tr role="row">
                        <th>Seq.</th>
                        <th>Recording File</th>
                        <th>File Type</th>
                        <th>Play</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {mohs &&
                        mohs.map((moh, index) => (
                          <tr
                            key={moh.music_on_hold_files_uuid}
                            draggable
                            onDragStart={(e) => handleDragStart(e, index)}
                            onDragOver={(e) => handleDragOver(e, index)}
                            onDrop={(e) => handleDrop(e, index)}
                            style={{ cursor: "grabbing" }}
                          >
                            <th>{moh.file_order}</th>
                            <td>{moh.recording_filename}</td>
                            <td>{moh.recording_file_type}</td>
                            <td>
                              <audio
                                src={
                                  RECORDING_JS_URL +
                                  domain_name +
                                  "/" +
                                  "moh/" +
                                  moh.recording_filename
                                }
                                controls
                              />
                            </td>

                            <td>
                              <Button
                                onClick={() =>
                                  deleteMohFile(moh.music_on_hold_files_uuid)
                                }
                                className="btn btn-danger shadow btn-xs sharp"
                              >
                                <i className="fa fa-trash"></i>
                              </Button>

                              {/* <Link
                                //to="#"
                                onClick={() =>
                                  deleteMohFile(
                                    moh.music_on_hold_files_uuid
                                  )
                                }
                                className="btn btn-danger shadow btn-xs sharp position-relative ml-2"
                                style={{top:'-18px'}}
                              >
                                <i className="fa fa-trash"></i>
                            </Link> */}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default UpdateMohForm;
