import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const createBlockedNumber = (data, token) => {
  return axios
    .post(API_URL + "create-blocked-number", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // console.log(response, "=response===fate");
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getBlockedNumberList = (
  user_type,
  group_per,
  group_uuid,
  search,
  off_set,
  limits,
  orderby,
  main_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "blocked-number-list",
      {
        user_type,
        group_per,
        group_uuid,
        search,
        off_set,
        limits,
        orderby,
        main_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const importNumberCsv = (data, token) => {
  return axios
    .post(API_URL + "import-number-csv", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getNumberMappingFields = (createdby, token, filename) => {
  return axios
    .post(
      API_URL + "get-number-mappingFields",
      {
        filename,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const insertNumberCsvData = (data, token) => {
  return axios
    .post(API_URL + "insert-number-csv-data", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const deleteBlockedNumber = (blocked_number_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "delete-blocked-number",
      {
        blocked_number_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const deleteMultipleBlockedNumber = (
  selectd_blocked_number,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "delete-multiple-blocked-number",
      {
        selectd_blocked_number,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateBlockedNumber = (data, token) => {
  return axios
    .post(API_URL + "update-blocked-number", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getExportBlockNumbers = (selectd_blocked_number, createdby, token) => {
  return axios
    .post(
      API_URL + "get-export-block-numbers",
      {
        selectd_blocked_number,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getBlockedNumberSettingsDetails = (user_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "get-blocked-number-settings-details",
      {
        user_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateBlockedNumberSettings = (data, token) => {
  return axios
    .post(API_URL + "update-blocked-number-settings", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

// const getBlockedNumberDetail = (createdby, token, sms_temp_uuid) => {
//   return axios
//     .post(
//       API_URL + "get-blocked-number-details",
//       {
//         createdby,
//         sms_temp_uuid,
//       },
//       {
//         headers: {
//           Authorization: token,
//         },
//       }
//     )
//     .then((response) => {
//       return response.data;
//     });
// };

// const getBlockedNumberDetail = (createdby, token, sms_temp_uuid) => {
//   return axios
//     .post(
//       API_URL + "get-blocked-number-details",
//       {
//         createdby,
//         sms_temp_uuid,
//       },
//       {
//         headers: {
//           Authorization: token,
//         },
//       }
//     )
//     .then((response) => {
//       return response.data;
//     });
// };

export default {
  createBlockedNumber,
  getBlockedNumberList,
  importNumberCsv,
  getNumberMappingFields,
  insertNumberCsvData,
  deleteBlockedNumber,
  deleteMultipleBlockedNumber,
  updateBlockedNumber,
  getExportBlockNumbers,
  getBlockedNumberSettingsDetails,
  updateBlockedNumberSettings,
  // getBlockedNumberDetail,
};
