import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const addGroup = (data, token) => {
  return axios
    .post(API_URL + "internal-chat/create-group", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const updateGroup = (data, token) => {
  return axios
    .post(API_URL + "internal-chat/update-group", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const updateGroupName = (
  name,
  internal_chat_group_uuid,
  main_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "internal-chat/update-group-name",
      { name, internal_chat_group_uuid, main_uuid, createdby },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

export default {
  addGroup,
  updateGroup,
  updateGroupName,
};
