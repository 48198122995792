import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const getContactsList = (
  from_number,
  main_uuid,
  user_type,
  group_per,
  group_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "sms-chat/contact-list",
      {
        from_number,
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getAllContactsList = (
  main_uuid,
  user_type,
  group_per,
  group_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "sms-chat/get-all-contact-list",
      {
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getSmsLog = (to_number, from_number, main_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "sms-chat/get-sms-log",
      {
        to_number,
        from_number,
        main_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const sendSms = (
  from_number,
  to_number,
  message,
  main_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "sms-chat/send-sms",
      {
        from_number,
        to_number,
        message,
        main_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getUserAssignNumberList = (sms_campaign_log_uuid,main_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "sms-chat/get-user-assign-list",
      {
        sms_campaign_log_uuid,
        main_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const readSmsParticular = (
  sms_campaign_log_uuid,

  main_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "read-sms-particular",
      {
        sms_campaign_log_uuid,
        main_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

export default {
  getContactsList,
  getAllContactsList,
  getSmsLog,
  sendSms,
  getUserAssignNumberList,
  readSmsParticular,
};
