import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const getSmsLog = (
  main_uuid,
  user_type,
  group_per,
  group_uuid,
  search,
  off_set,
  status,
  limits,
  orderby,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "sms-log",
      {
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        search,
        off_set,
        status,
        limits,
        orderby,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getHistorySmsLog = (contact_uuid, main_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "sms-history-log",
      {
        contact_uuid,
        main_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

export default {
  getSmsLog,
  getHistorySmsLog,
};
