import React, { useState, useEffect, useMemo } from "react";
import { getParticularRolePermission } from "../../../frontend/src/jsx/actions/permission";
import {
  notifyError,
  notifyWarning,
} from "../../src/jsx/components/Toastr/Toastr";

/// React router dom
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthVerify from "./common/AuthVerify";
/// Layout
import Nav from "./layouts/nav";
import NavLogin from "./layouts/navLogin";
import Footer from "./layouts/Footer";
import ProfileSidebar from "./layouts/ProfileSidebar";

/// Deshboard
import Home from "./components/Dashboard/Home";
import CallCenterDashboard from "./components/Dashboard/CallCenterDashboard";
import LiveAgent from "./components/LiveAgent/LiveAgent";
import Role from "./components/user/Role";
import Users from "./components/user/Users";
import CreateUsers from "./components/user/CreateUserForm";
import UpdateUsers from "./components/user/UpdateUserForm";
import ViewUser from "./components/user/ViewUser";
import UsersRole from "./components/user/UsersRole";
import Extension from "./components/extension/Extension";
import ExtensionForm from "./components/extension/ExtensionForm";
import UpdateExtensionForm from "./components/extension/UpdateExtensionForm";
import RingGroups from "./components/ringgroup/RingGroups";
import RinggroupForm from "./components/ringgroup/RinggroupForm";
import ViewRingGroup from "./components/ringgroup/ViewRingGroup";
//did
import Did from "./components/did/Did";
import UpdateNumber from "./components/did/UpdateNumber";
import AddDidForm from "./components/did/AddDidForm";
import DidRoute from "./components/did/DidRoute";

import Recording from "./components/recording/Recording";
import UploadRecordingForm from "./components/recording/UploadRecordingForm";
import UpdateMohForm from "./components/recording/UpdateMohForm";

import Ivr from "./components/ivr/Ivr";
import IvrForm from "./components/ivr/IvrForm";
import UpdateIvrForm from "./components/ivr/UpdateIvrForm";

import TimeConditions from "./components/timeCondition/TimeConditions";
import TimeconditionForm from "./components/timeCondition/TimeconditionForm";
import UpdateTimeConditionForm from "./components/timeCondition/UpdateTimeConditionForm";
import AddTimeConditionForm from "./components/timeCondition/AddTimeConditionForm";

import Permissions from "./components/user/Permissions";

//import Webphone from "./components/webphone/Webphone";

/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import Error403 from "./pages/Error403";
import Logout from "./pages/Logout";
import ForgotPassword from "./pages/ForgotPassword";
import CreatePassword from "./pages/CreatePassword";
import Calender from "./components/Dashboard/Calender";
import Communication from "./components/Dashboard/Communication";

//Onboarding module
import Onboarding from "./onboarding/Onboarding";

//User Onboardin module
import UserOnboarding from "./userOnboarding/UserOnboarding";

//Contact module
import Contacts from "./components/CRM/contacts/Contacts";
import ContactForm from "./components/CRM/contacts/ContactForm";
import UpdateContactForm from "./components/CRM/contacts/UpdateContactForm";
import ContactView from "./components/CRM/contacts/ContactView";
import BulkUpdateContactForm from "./components/CRM/contacts/BulkUpdateContactForm";
import BulkReassignContactForm from "./components/CRM/contacts/BulkReassignContactForm";

//Import Contact
import ImportContact from "./components/CRM/contacts/ImportContact";
import MappingFields from "./components/CRM/contacts/MappingFields";
import MappingFieldsConfirm from "./components/CRM/contacts/MappingFieldsConfirm";

//custome fields
import CustomFields from "./components/CRM/contacts/CustomFields";
import FieldSettings from "./components/CRM/contacts/FieldSettings";
import ContactCustomForm from "./components/CRM/contactCustomForm/CustomForm";

import ContactGroups from "./components/CRM/contactgroup/ContactGroups";
import UpdateContactGroupForm from "./components/CRM/contactgroup/UpdateContactGroupForm";

//groups
import Groups from "./components/group/Groups";
import GroupsAssignUser from "./components/group/Users";
import AddGroupForm from "./components/group/AddGroupForm";
import UpdateGroupForm from "./components/group/UpdateGroupForm";

//sms Template
import SmsTemplate from "./components/smsTemplate/SmsTemplate";
import CreateSmsTempForm from "./components/smsTemplate/CreateSmsTempForm";
import UpdateSmsTempForm from "./components/smsTemplate/UpdateSmsTempForm";

//sms Template
import EmailTemplate from "./components/emailTemplate/EmailTemplate";
import CreateEmailTempForm from "./components/emailTemplate/CreateEmailTempForm";
import UpdateEmailTempForm from "./components/emailTemplate/UpdateEmailTempForm";

//sms Campaign
import SmsCampaign from "./components/smsCampaign/SmsCampaign";
import CreateSmsCampaign from "./components/smsCampaign/CreateSmsCampaignForm";
import UpdateSmsCampaign from "./components/smsCampaign/UpdateSmsCampaignForm";

//Call Campaign
import CallCampaign from "./components/callCampaign/CallCampaign";
import CreateCallCampaign from "./components/callCampaign/CreateCallCampaignForm";
import UpdateCallCampaign from "./components/callCampaign/UpdateCallCampaignForm";
import PowerDial from "./components/callCampaign/PowerDial";
import PowerDialScreen from "./components/callCampaign/PowerDialScreen";

//Email 
import Emails from "./components/Email/Inbox/Inbox";

//sms Campaign
import SmsLog from "./components/sms/Sms";

//chat sms
import chatSms from "./components/chatSms/chatSms";

import Plan from "./components/plan/Plan";
import CreatePlanForm from "./components/plan/CreatePlanForm";
import UpdatePlanForm from "./components/plan/UpdatePlanForm";

//DNC List
import DncList from "./components/dncList/DncList";

//blockedNumbers List
import blockedNumbers from "./components/blockedNumbers/BlockedNumbers";

//cdrs
import Cdr from "./components/cdr/Cdr";

//internal Chat
import internalChat from "./components/internalChat/internalChat";

//Email campaign
import EmailCampaign from "./components/emailCampaign/EmailCampaign";
import CreateEmailCampaignForm from "./components/emailCampaign/CreateEmailCampaignForm";
import UpdateEmailCampaignForm from "./components/emailCampaign/UpdateEmailCampaignForm";
import EmailCampaignReport from "./components/emailCampaign/EmailCampaignReport";

//calendar meeting scheduler
import ScheduleMeeting from "./components/Dashboard/calender/ScheduleMeeting";
import MeetingScreen from "./components/Dashboard/calender/MeetingScreen";
import AttendeeMeetingScreen from "./components/Dashboard/calender/AttendeeMeetingScreen";

// import Wizard from "./components/Forms/Wizard/Wizard";

//CRM Contact module
import Sequence from "./components/CRM/sequence/Sequence";
import AddSequence from "./components/CRM/sequence/AddSequence";

/// Css
import "./index.css";

const appRoutes = [
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
  { path: "/register", component: Registration },
  { path: "/forgot-password", component: ForgotPassword },
  { path: "/create-password", component: CreatePassword },
  { path: "/schedule-meeting", component: ScheduleMeeting },
  { path: "/attendee-meeting-screen", component: AttendeeMeetingScreen },
  { path: "/app-profile", component: AppProfile, isSecure: true },
  { path: "/onboarding", component: Onboarding, isSecure: true },
  { path: "/user-onboarding", component: UserOnboarding, isSecure: true },
  { path: "/", component: Home, isSecure: true },
  { path: "/call-center-dashboard", component: CallCenterDashboard, isSecure: true },
  { path: "/live-agent", component: LiveAgent, isSecure: true },
  { path: "/roles", component: Role, isSecure: true },
  { path: "/users", component: Users, isSecure: true },
  { path: "/create-user", component: CreateUsers, isSecure: true },
  { path: "/update-user", component: UpdateUsers, isSecure: true },
  { path: "/view-user", component: ViewUser, isSecure: true },
  { path: "/users-role", component: UsersRole, isSecure: true },
  { path: "/extensions", component: Extension, isSecure: true },
  { path: "/add-extension", component: ExtensionForm, isSecure: true },
  { path: "/update-extension", component: UpdateExtensionForm, isSecure: true },
  { path: "/permissions", component: Permissions, isSecure: true },
  { path: "/ring-groups", component: RingGroups, isSecure: true },
  { path: "/add-ringgroup", component: RinggroupForm, isSecure: true },
  { path: "/view-ringgroup", component: ViewRingGroup, isSecure: true },
  { path: "/inbound-numbers", component: Did, isSecure: true },
  { path: "/update-number", component: UpdateNumber, isSecure: true },
  { path: "/add-did", component: AddDidForm, isSecure: true },
  { path: "/did-routing", component: DidRoute, isSecure: true },
  { path: "/recordings", component: Recording, isSecure: true },
  { path: "/upload-recording", component: UploadRecordingForm, isSecure: true },
  { path: "/update-moh-form", component: UpdateMohForm, isSecure: true },
  { path: "/ivr", component: Ivr, isSecure: true },
  { path: "/add-ivr", component: IvrForm, isSecure: true },
  { path: "/update-ivr", component: UpdateIvrForm, isSecure: true },
  { path: "/business-hours", component: TimeConditions, isSecure: true },
  { path: "/add-time-conditions", component: TimeconditionForm, isSecure: true },
  { path: "/update-business-hours", component: UpdateTimeConditionForm, isSecure: true },
  { path: "/add-time-condition", component: AddTimeConditionForm, isSecure: true },
  { path: "/contacts", component: Contacts, isSecure: true },
  { path: "/add-contact", component: ContactForm, isSecure: true },
  { path: "/update-contact", component: UpdateContactForm, isSecure: true },
  { path: "/view-contact", component: ContactView, isSecure: true },
  { path: "/bulk-update-contact", component: BulkUpdateContactForm, isSecure: true },
  { path: "/bulk-assign-contact", component: BulkReassignContactForm, isSecure: true },
  { path: "/field-setting", component: FieldSettings, isSecure: true },
  { path: "/import-contact", component: ImportContact, isSecure: true },
  { path: "/mapping-fields", component: MappingFields, isSecure: true },
  { path: "/mapping-fields-confirmation", component: MappingFieldsConfirm, isSecure: true },
  { path: "/contact-custom-form", component: ContactCustomForm, isSecure: true },
  { path: "/groups", component: Groups, isSecure: true },
  { path: "/groups-assign-user", component: GroupsAssignUser, isSecure: true },
  { path: "/add-group", component: AddGroupForm, isSecure: true },
  { path: "/update-group", component: UpdateGroupForm, isSecure: true },
  { path: "/custom-fields", component: CustomFields, isSecure: true },
  { path: "/contact-group", component: ContactGroups, isSecure: true },
  { path: "/update-contact-group", component: UpdateContactGroupForm, isSecure: true },
  { path: "error-403", component: Error403, isSecure: true },
  { path: "contact-management", component: Communication, isSecure: true },
  { path: "calendar", component: Calender, isSecure: true },
  { path: "communication", component: Home, isSecure: true },
  { path: "/sms-template", component: SmsTemplate, isSecure: true },
  { path: "/create-sms-template", component: CreateSmsTempForm, isSecure: true },
  { path: "/update-sms-template", component: UpdateSmsTempForm, isSecure: true },
  { path: "/sms-campaign", component: SmsCampaign, isSecure: true },
  { path: "/create-sms-campaign", component: CreateSmsCampaign, isSecure: true },
  { path: "/update-sms-campaign", component: UpdateSmsCampaign, isSecure: true },
  { path: "/email-template", component: EmailTemplate, isSecure: true },
  { path: "/create-email-template", component: CreateEmailTempForm, isSecure: true },
  { path: "/update-email-template", component: UpdateEmailTempForm, isSecure: true },
  { path: "/email-campaign", component: EmailCampaign, isSecure: true },
  { path: "/create-email-campaign", component: CreateEmailCampaignForm, isSecure: true },
  { path: "/update-email-campaign", component: UpdateEmailCampaignForm, isSecure: true },
  { path: "/email-campaign-report", component: EmailCampaignReport, isSecure: true },
  { path: "/call-campaign", component: CallCampaign, isSecure: true },
  { path: "/create-call-campaign", component: CreateCallCampaign, isSecure: true },
  { path: "/update-call-campaign", component: UpdateCallCampaign, isSecure: true },
  { path: "/power-dial", component: PowerDial, isSecure: true },
  { path: "/power-dial-screen", component: PowerDialScreen, isSecure: true },
  { path: "/sms-logs", component: SmsLog, isSecure: true },
  { path: "/chat-sms", component: chatSms, isSecure: true },
  { path: "/plans", component: Plan, isSecure: true },
  { path: "/create-plan", component: CreatePlanForm, isSecure: true },
  { path: "/update-plan", component: UpdatePlanForm, isSecure: true },
  { path: "/email-inbox", component: Emails, isSecure: true },
  { path: "/dnc-list", component: DncList, isSecure: true },
  { path: "/blocked-numbers-list", component: blockedNumbers, isSecure: true },
  { path: "/cdr", component: Cdr, isSecure: true },
  { path: "/internal-chat", component: internalChat, isSecure: true },
  { path: "/sequence", component: Sequence, isSecure: true },
 	{ path: "/add-sequence", component: AddSequence, isSecure: true },
  { path: "/meeting-screen", component: MeetingScreen, isSecure: true },
];

const Markup = ({ showProfileSideBar }) => {
  const [showProfile, setShowProfile] = useState(false);
  const [showWebphone, setShowWebphone] = useState(false);
  const [isLogout, setIsLogout] = useState("No");
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const user = useMemo(() => JSON.parse(localStorage.getItem("user")), []);

  useEffect(() => {
    if(user) {
      const user_data = user.data.data;

      const WEB_SOCKET_URL = process.env.REACT_APP_SERVER_WEB_SOCKET_URL;
      const socket = new WebSocket(WEB_SOCKET_URL);
      socket.onmessage = (event) => {
        let data = JSON.parse(event.data);
  
        if (data.type === "user_delete") {
          if (user_data.user_uuid === data.data) {
            setIsLogout("Yes");
          }
        }
  
        if (user_data.role !== "superadmin" || user_data.role !== "admin") {
          if (
            data.data === user_data.role_uuid &&
            data.type === "change_permission"
          ) {
            let role_data = {
              type: "permission_change",
              role_uuid: data.data,
              createdby: user_data.user_uuid,
            };
  
            dispatch(getParticularRolePermission(role_data, user.data.token))
              .then((response) => {
                if (response.status === 200) {
                  user.data.data.role_permission =
                    response.data.permission_data;
                  localStorage.setItem("user", JSON.stringify(user));
                  notifyWarning(response.message);
                  return;
                } else if (response.status === 500) {
                  notifyError("Something went wrong.");
                } else {
                  notifyError("Something went wrong.");
                }
              })
              .catch(() => {});
          }
  
          if (
            data.old_role === user_data.role_uuid &&
            data.type === "role_change"
          ) {
            let role_data = {
              type: "role_change",
              role_uuid: data.data,
              createdby: user_data.user_uuid,
            };
  
            dispatch(getParticularRolePermission(role_data, user.data.token))
              .then((response) => {
                if (response.status === 200) {
                  user.data.data.role_permission =
                    response.data.permission_data;
                  user.data.data.role = response.data.role_name;
                  user.data.data.role_uuid = data.data;
                  localStorage.setItem("user", JSON.stringify(user));
                  notifyWarning(response.message);
                  return;
                } else if (response.status === 500) {
                  notifyError("Something went wrong.");
                } else {
                  notifyError("Something went wrong.");
                }
              })
              .catch(() => {});
          }
        }
      };
    }
  }, [user]);

  const {isProfileDone, isUserProfileDone} = useMemo(() => {
    let IsSubuser = true;
    let isProfileDone = true;
    let isUserProfileDone = true;
    if(user) {
      const user_data = user.data.data;

      if (user_data.role === "superadmin" || user_data.role === "admin") {
        IsSubuser = false;
        isUserProfileDone = false;
      }
  
      if (user_data.role === "superadmin") {
        isProfileDone = false;
      }
  
      if (
        user_data.is_did_done === "YES" &&
        user_data.is_email_config_done === "YES" &&
        user_data.is_branding_done === "YES" &&
        user_data.is_user_done === "YES"
      ) {
        // navigate("/");
        isProfileDone = false;
      }
  
      if (IsSubuser) {
        // navigate("/");
        isProfileDone = false;
        isUserProfileDone = true;
  
        if (
          user_data.is_did_done === "YES" &&
          user_data.is_email_config_done === "YES" &&
          user_data.is_password_done === "YES" &&
          user_data.is_e911_done === "YES"
        ) {
          // navigate("/");
          isUserProfileDone = false;
        }
      }
    }

    return {isProfileDone, isUserProfileDone};
  }, [user])

  return (
    <Router basename="/">
      <div
        id={isLoggedIn ? "main-wrapper": ""}
        className={`${isLoggedIn ? "show menu-toggle" : "mh100vh"} ${showWebphone ? "right-profile" : ""} `}
      >
        {isLoggedIn && !isProfileDone && !isUserProfileDone && (
          <Nav
            showProfileSideBar={showProfileSideBar}
            showProfile={() => setShowProfile(false)}
            showWebphone={(val) => setShowWebphone(val)} // jignisha webphone UI
          />
        )}

        {isLoggedIn && (isProfileDone || isUserProfileDone) && (
          <NavLogin
            showProfileSideBar={showProfileSideBar}
            showProfile={() => setShowProfile(false)}
          />
        )}

        {isLoggedIn && isProfileDone && isUserProfileDone && (
          <ProfileSidebar
            addClass={showProfile ? "active" : ""}
            hideProfile={() => setShowProfile(false)}
          />
        )}

        <div className={isLoggedIn ? isUserProfileDone && isProfileDone ? "content-body content-body1" : "content-body" : ""}>
          <div className={isLoggedIn ? isUserProfileDone && isProfileDone ? "container mt-0" : "container-fluid" : ""}>
            <Routes>
              {appRoutes.map(({isSecure, ...item}) =>  
                isSecure
                  ? <Route key={Math.random} exact path={`/${item.path}`} element={isLoggedIn ? <item.component /> : <Navigate to="/login" />} />
                  : <Route key={Math.random} exact path={`/${item.path}`} element={<item.component />} />
              )}
            </Routes>
          </div>
        </div>
        {isLoggedIn && <Footer />}

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>

      <AuthVerify logOut={""} isLogout={isLogout} />
    </Router>
  )
}

export default Markup;
