import React, { Fragment, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import {
  updateRinggroupDestination,
  deleteRingGroupDestination,
  updateRinggroup,
} from "../../actions/ringgroups";
import "./style.css";
import Tooltip from "rc-tooltip";
import { confirmAlert } from "react-confirm-alert";
import EasyEdit, { Types } from "react-easy-edit";
import PerfectScrollbar from "react-perfect-scrollbar";
import Loader from "../../layouts/Loader";

function GeneralFields(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  let ringgroupData = props.ringgroupData;
  let extensions = props.extensions;
  let destinations = props.ringgroupData.ring_group_destinations;
  let destinationOptions = props.destinationOptions;

  let number_array = new Array(12).fill(1).map((_, i) => {
    return { label: (i + 1) * 5, value: (i + 1) * 5 };
  });

  let ring_timeout_array = new Array(60).fill(0).map((_, i) => {
    return { label: i, value: i };
  });

  let skill_set_array = new Array(10).fill(0).map((_, i) => {
    return { label: i, value: i };
  });

  const RingStrategyList = [
    { label: "Ring All", value: "ring_all" },
    { label: "Linear", value: "linear" },
    { label: "Round Robin", value: "round_robin" },
    { label: "Fewest Calls", value: "fewest_calls" },
    { label: "Skill Based", value: "skill_based" },
  ];

  let i = 1;

  const dispatch = useDispatch();

  const [draggedRowIndex, setDraggedRowIndex] = useState(null);
  const [loading, setLoading] = useState(false);

  const [ringGroupDestination, setRingGroupDestination] = useState({
    destination_order: "",
    destination_type: "",
    destination: "",
    skill_set: "",
    extension: "",
    number: "",
    ring_timeout: 20,
    ring_group_uuid: ringgroupData.ring_group_uuid,
    createdby: createdby,
    main_uuid: main_uuid,
  });

  const [errors, setErrors] = useState({
    extension: "",
    did: "",
  });

  const handleChange = (e) => {
    let last_data = destinations.slice(-1);
    let order = 1;
    let error_data = { ...errors };

    if (last_data && last_data.length > 0) {
      order = parseInt(last_data[0].destination_order) + parseInt(1);
    }

    let data = { ...ringGroupDestination };
    data["ring_group_uuid"] = ringgroupData.ring_group_uuid;
    data["main_uuid"] = ringgroupData.main_admin_uuid;
    data["action_type"] = "add";
    data["destination_order"] = order;
    data["ring_timeout"] = 20;

    if (e.target.name === "skill_set") {
      data["skill_set"] = e.target.value;
    } else {
      if (e.target.value.length === 0) {
        if (e.target.name === "extension") {
          error_data["extension"] = "Destination is required.";
        } else {
          error_data["did"] = "Destination is required.";
        }
      } else {
        error_data["extension"] = "";
        error_data["did"] = "";
      }

      if (checkUniqueDestination(destinations, e.target.value)) {
        if (e.target.name === "extension") {
          error_data["extension"] = "Destination is already used.";
        } else {
          error_data["did"] = "Destination is already used.";
        }
      } else {
        error_data["extension"] = "";
        error_data["did"] = "";
      }

      if (e.target.name === "number") {
        if (/^\d{9,11}$/.test(e.target.value)) {
          error_data["did"] = "";
        } else {
          error_data["did"] = "Number is invalid(9 to 11 digits allow).";
        }
      }

      data["destination_type"] = e.target.name;
      data["destination"] = e.target.value;
      data[e.target.name] = e.target.value;
    }

    setErrors(error_data);
    setRingGroupDestination(data);
  };

  const saveDestination = () => {
    let isError = "false";
    let error_data = { ...errors };
    setLoading(true);

    if (ringGroupDestination.destination.length === 0) {
      if (ringGroupDestination.destination_type === "extension") {
        error_data["extension"] = "Destination is required.";
      } else {
        error_data["did"] = "Destination is required.";
      }
      isError = "true";
    }

    if (
      checkUniqueDestination(destinations, ringGroupDestination.destination)
    ) {
      if (ringGroupDestination.destination_type === "extension") {
        error_data["extension"] = "Destination is already used..";
      } else {
        error_data["did"] = "Destination is already used..";
      }
      isError = "true";
    }

    if (ringGroupDestination.destination_type === "number") {
      if (/^\d{9,11}$/.test(ringGroupDestination.destination)) {
        error_data["did"] = "";
      } else {
        error_data["did"] = "Number is invalid(9 to 11 digits allow).";
        isError = "true";
      }
    }

    if (isError === "true") {
      setErrors(error_data);
      return false;
    }

    setLoading(true);
    dispatch(updateRinggroupDestination(ringGroupDestination, token))
      .then((res) => {
        if (res.status === 200) {
          notifyInfo(res.message);
          props.changeRingGroupEvent(Math.random);

          let data = { ...ringGroupDestination };
          data["destination_order"] = "";
          data["ring_timeout"] = 20;
          data["skill_set"] = "";
          data["destination_type"] = "";
          data["destination"] = "";
          data["extension"] = "";
          data["number"] = "";
          setLoading(false);
          setRingGroupDestination(data);
        } else if (res.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateRingTimeout = (value, destination) => {
    let data = { ...destination };
    data["ring_group_uuid"] = destination.ring_group_uuid;
    data["main_uuid"] = destination.main_admin_uuid;
    data["action_type"] = "update";
    data["ring_timeout"] = value;
    data["skill_set"] = destination.destination_skill_set;
    data["createdby"] = createdby;
    setLoading(true);

    dispatch(updateRinggroupDestination(data, token))
      .then((res) => {
        if (res.status === 200) {
          notifyInfo(res.message);
          setLoading(false);
          props.changeRingGroupEvent(Math.random);
        } else if (res.status === 500) {
          setLoading(false);
          notifyError("Something went wrong.");
        } else {
          setLoading(false);
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateSkillSet = (value, destination) => {
    let data = { ...destination };
    data["ring_group_uuid"] = destination.ring_group_uuid;
    data["main_uuid"] = destination.main_admin_uuid;
    data["action_type"] = "update";
    data["ring_timeout"] = destination.destination_ring_timeout;
    data["skill_set"] = value;
    data["createdby"] = createdby;

    setLoading(true);
    dispatch(updateRinggroupDestination(data, token))
      .then((res) => {
        if (res.status === 200) {
          notifyInfo(res.message);
          props.changeRingGroupEvent(Math.random);
        } else if (res.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fnDeleteRingGroupDestination = (ring_group_destination_uuid) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this ring group destination?",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            deleteRingGroupDestinationService(ring_group_destination_uuid),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteRingGroupDestinationService = (ring_group_destination_uuid) => {
    setLoading(true);

    dispatch(
      deleteRingGroupDestination(ring_group_destination_uuid, createdby, token)
    )
      .then((response) => {
        if (response.status === 200) {
          notifyInfo(response.message);

          props.changeRingGroupEvent(Math.random);
          setLoading(false);
          //updateRows(destinations);
          return;
        } else if (response.status === 500) {
          setLoading(false);
          notifyError("Something went wrong.");
        } else {
          setLoading(false);
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const save = (value, attribute) => {
    let data = { ...ringgroupData };
    setLoading(true);
    data["createdby"] = createdby;
    data["main_admin_uuid"] = ringgroupData.main_admin_uuid;
    data["user_uuid"] = createdby;
    data["ring_group_uuid"] = ringgroupData.ring_group_uuid;

    if (attribute === "ring_group_fail_destination") {
      const answer_array = value.split("_");

      data["ring_group_fail_destination_type"] = answer_array[0];
      data["ring_group_fail_destination"] = answer_array[1];
    } else {
      data[attribute] = value;
    }

    data["attribute"] = attribute;
    data["ring_group_destinations"] = [];

    dispatch(updateRinggroup(data, token))
      .then((res) => {
        if (res.status === 200) {
          notifyInfo(res.message);
          setLoading(false);
          props.changeRingGroupEvent(Math.random);
        } else if (res.status === 500) {
          setLoading(false);
          notifyError("Something went wrong.");
        } else {
          setLoading(false);
          notifyError("Something went wrong.");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const CustomDisplay = (props) => {
    let value = "FailOver Destination";

    if (ringgroupData.ring_group_fail_destination_type === "ivr") {
      value = "Auto-Attendant - " + ringgroupData.ivr_menu_name;
    } else if (ringgroupData.ring_group_fail_destination_type === "extention") {
      value = "Extention - " + ringgroupData.extension;
    } else if (ringgroupData.ring_group_fail_destination_type === "ring-group") {
      value = "Ring Group - " + ringgroupData.ring_destination;
    } else if (ringgroupData.ring_group_fail_destination_type === "did") {
      value = "Inbound Number - " + ringgroupData.did_number;
    } else if (ringgroupData.ring_group_fail_destination_type === "voicemail") {
      value = "Voicemail - " + ringgroupData.voicemail_id;
    } else if (
      ringgroupData.ring_group_fail_destination_type === "time_condition"
    ) {
      value = "Time Condition - " + ringgroupData.time_condition_name;
    }

    return <p>{value}</p>;
  };

  const CustomEdit = (props) => {
    function inputonChange(e) {
      props.setParentValue(e.target.value);
    }

    return (
      <div>
        <select
          name="ring_group_fail_destination_str"
          className="form-control p-0"
          onChange={inputonChange}
          defaultValue={ringgroupData.ring_group_fail_destination_str}
          style={{ height: "35px" }}
        >
          {Object.keys(destinationOptions).map((group, index) => {
            return (
              <optgroup key={index} label={group}>
                {destinationOptions[group].map((option) => {
                  return (
                    <option key={option.value} value={option.value}>
                      {option.text}
                    </option>
                  );
                })}
              </optgroup>
            );
          })}
        </select>
      </div>
    );
  };

  const handleDragStart = (e, index) => {
    setDraggedRowIndex(index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault(); // Necessary for allowing a drop
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    setLoading(true);
    const draggedRow = destinations[draggedRowIndex];
    const remainingRows = destinations.filter((_, i) => i !== draggedRowIndex);
    const updatedRows = [
      ...remainingRows.slice(0, index),
      draggedRow,
      ...remainingRows.slice(index),
    ];

    setLoading(true);
    updatedRows.forEach(async (row, index) => {
      let data = { ...row };
      data["ring_group_uuid"] = row.ring_group_uuid;
      data["main_uuid"] = row.main_admin_uuid;
      data["action_type"] = "update";
      data["ring_timeout"] = row.destination_ring_timeout;
      data["skill_set"] = row.destination_skill_set;
      data["destination_order"] = index + 1;
      data["createdby"] = createdby;

      await dispatch(updateRinggroupDestination(data, token))
        .then((res) => {
          if (res.status === 200) {
            //notifyInfo(res.message);
            setLoading(false);
          } else if (res.status === 500) {
            setLoading(false);
            notifyError("Something went wrong.");
          } else {
            setLoading(false);
            notifyError("Something went wrong.");
          }
        })
        .catch(() => {
          setLoading(false);
        });
    });

    setLoading(false);
    props.changeRingGroupEvent(Math.random);
  };

  const checkUniqueDestination = (array, value) => {
    return array.some(function (el) {
      if (el.destination_type === "extension") {
        return el.destination_type_uuid === value;
      } else {
        return el.destination_number === value;
      }
    });
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <div className="row">
        <div className="col-lg-4 col-md-4 col-6">
          <div className="card">
            <div className="card-body">
              <div className="form-group">
                <label className="w-100">
                  Ring Group ID
                  <Tooltip placement="top" overlay={<span>Ring Group ID</span>}>
                    <span className="ti-info-alt ml-1"></span>
                  </Tooltip>
                </label>
                <p>{ringgroupData.ring_group_extension}</p>
              </div>

              <div className="form-group">
                <label className="w-100">
                  Ring Group Name <span className="text-danger">*</span>
                  <Tooltip
                    placement="top"
                    overlay={<span>Ring Group Name</span>}
                  >
                    <span className="ti-info-alt ml-1"></span>
                  </Tooltip>
                </label>

                <EasyEdit
                  type={Types.TEXT}
                  onSave={(value) => {
                    save(value, "ring_group_name");
                  }}
                  //onCancel={cancel}
                  saveButtonLabel={
                    <i className="fa fa-check" aria-hidden="true"></i>
                  }
                  saveButtonStyle="label-primary easy-edit-button rounded-lg"
                  cancelButtonLabel={
                    <i className="fa fa-times" aria-hidden="true"></i>
                  }
                  cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                  attributes={{
                    name: "ring_group_name",
                    className: "form-control h-75",
                  }}
                  //viewAttributes={{className: 'fs-20'}}
                  onValidate={(value) => {
                    return value ? true : false;
                  }}
                  validationMessage="Ring group name is required!"
                  placeholder="None"
                  value={ringgroupData.ring_group_name}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-4 col-6">
          <div className="card">
            <div className="card-body">
              <div className="form-group">
                <label className="w-100">
                  Ring Strategy <span className="text-danger">*</span>
                  <Tooltip placement="top" overlay={<span>Ring Strategy</span>}>
                    <span className="ti-info-alt ml-1"></span>
                  </Tooltip>
                </label>

                <EasyEdit
                  type={Types.SELECT}
                  onSave={(value) => {
                    save(value, "ring_group_strategy");
                  }}
                  //onCancel={cancel}
                  options={RingStrategyList}
                  saveButtonLabel={
                    <i className="fa fa-check" aria-hidden="true"></i>
                  }
                  saveButtonStyle="label-primary easy-edit-button rounded-lg"
                  cancelButtonLabel={
                    <i className="fa fa-times" aria-hidden="true"></i>
                  }
                  cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                  attributes={{
                    name: "ring_group_strategy",
                    className: "form-control p-0",
                    style: { height: "35px" },
                  }}
                  placeholder="None"
                  onValidate={(value) => {
                    return value ? true : false;
                  }}
                  validationMessage="Ring group strategy is required!"
                  value={ringgroupData.ring_group_strategy}
                />

                {/* <p>{ringgroupData.ring_group_strategy}</p> */}
              </div>

              <div className="form-group">
                <label className="w-100">
                  CID Name Prefix
                  <Tooltip
                    placement="top"
                    overlay={<span>CID Name Prefix</span>}
                  >
                    <span className="ti-info-alt ml-1"></span>
                  </Tooltip>
                </label>

                <EasyEdit
                  type={Types.TEXT}
                  onSave={(value) => {
                    save(value, "cid_name_prefix");
                  }}
                  //onCancel={cancel}
                  saveButtonLabel={
                    <i className="fa fa-check" aria-hidden="true"></i>
                  }
                  saveButtonStyle="label-primary easy-edit-button rounded-lg"
                  cancelButtonLabel={
                    <i className="fa fa-times" aria-hidden="true"></i>
                  }
                  cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                  attributes={{
                    name: "cid_name_prefix",
                    className: "form-control h-75",
                  }}
                  //viewAttributes={{className: 'fs-20'}}
                  // onValidate={(value) => {
                  //     return value ? true : false;
                  // }}
                  //validationMessage="What just happened?"
                  placeholder="None"
                  value={ringgroupData.cid_name_prefix}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-4 col-6">
          <div className="card">
            <div className="card-body">
              <div className="form-group">
                <label className="w-100">
                  Max Caller Wait Time
                  <Tooltip
                    placement="top"
                    overlay={<span>Max Caller Wait Time</span>}
                  >
                    <span className="ti-info-alt  ml-1"></span>
                  </Tooltip>
                </label>

                <EasyEdit
                  type={Types.SELECT}
                  onSave={(value) => {
                    save(value, "max_wait_time");
                  }}
                  //onCancel={cancel}
                  options={number_array}
                  saveButtonLabel={
                    <i className="fa fa-check" aria-hidden="true"></i>
                  }
                  saveButtonStyle="label-primary easy-edit-button rounded-lg"
                  cancelButtonLabel={
                    <i className="fa fa-times" aria-hidden="true"></i>
                  }
                  cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                  attributes={{
                    name: "max_wait_time",
                    className: "form-control p-0",
                    style: { height: "35px" },
                  }}
                  placeholder="None"
                  viewAttributes={{ className: "pull-left" }}
                  value={ringgroupData.max_wait_time}
                />
                {ringgroupData.max_wait_time > 0 && (
                  <span className="ml-1 font-w600 text-black">Min</span>
                )}
              </div>

              <div className="form-group">
                <label className="w-100">
                  Failover Destination After Max Wait Time
                  <Tooltip
                    placement="top"
                    overlay={
                      <span>Failover Destination After Max Wait Time</span>
                    }
                  >
                    <span className="ti-info-alt ml-1"></span>
                  </Tooltip>
                </label>

                <EasyEdit
                  type={Types.SELECT}
                  onSave={(value) => {
                    save(value, "ring_group_fail_destination");
                  }}
                  editComponent={<CustomEdit />}
                  displayComponent={<CustomDisplay />}
                  saveButtonLabel={
                    <i className="fa fa-check" aria-hidden="true"></i>
                  }
                  saveButtonStyle="label-primary easy-edit-button rounded-lg"
                  cancelButtonLabel={
                    <i className="fa fa-times" aria-hidden="true"></i>
                  }
                  cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                  attributes={{
                    name: "ring_group_fail_destination",
                    className: "form-control h-75",
                  }}
                  viewAttributes={{ className: "fs-20" }}
                  onValidate={(value) => {
                    return value ? true : false;
                  }}
                  placeholder="None"
                  value={ringgroupData.ring_group_fail_destination}
                />

                {/* <p>{ringgroupData.ring_group_timeout_action}</p> */}
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-8 col-md-8 col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Extension List</h4>
            </div>
            <div className="card-body p-0">
              <PerfectScrollbar
                className={`p-0 dz-scroll "ps ps--active-y`}
                id="DZ_W_Contacts_Body1"
                style={{ height: "307px" }}
              >
                <Table responsive className="w-100">
                  <thead>
                    <tr role="row">
                      <th className="border-top border-primary">Seq.</th>
                      <th className="border-top border-primary">Type</th>
                      <th className="border-top border-primary">Ext</th>
                      <th className="border-top border-primary">Skill Set</th>
                      <th className="border-top border-primary">
                        Ring Timeout
                      </th>
                      <th className="border-top border-primary"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {destinations &&
                      destinations.map((destination, index) => (
                        <tr
                          key={destination.ring_group_destination_uuid}
                          draggable
                          onDragStart={(e) => handleDragStart(e, index)}
                          onDragOver={(e) => handleDragOver(e, index)}
                          onDrop={(e) => handleDrop(e, index)}
                          style={{ cursor: "grabbing" }}
                        >
                          <th>{destination.destination_order}</th>
                          <td>{destination.destination_type}</td>
                          <td>
                            {destination.destination_type === "extension"
                              ? destination.extension
                              : destination.destination_number}
                          </td>
                          <td>
                            {destination.destination_type === "extension" ? (
                              <EasyEdit
                                type={Types.SELECT}
                                onSave={(value) => {
                                  updateSkillSet(value, destination);
                                }}
                                //onCancel={cancel}
                                options={skill_set_array}
                                saveButtonLabel={
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  ></i>
                                }
                                saveButtonStyle="label-primary easy-edit-button rounded-lg"
                                cancelButtonLabel={
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                  ></i>
                                }
                                cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                                attributes={{
                                  name: "destination_skill_set",
                                  className: "form-control p-0",
                                  style: { height: "35px" },
                                }}
                                placeholder="None"
                                viewAttributes={{ className: "pull-left" }}
                                value={destination.destination_skill_set}
                              />
                            ) : (
                              destination.destination_skill_set
                            )}
                          </td>
                          <td>
                            <EasyEdit
                              type={Types.SELECT}
                              onSave={(value) => {
                                updateRingTimeout(value, destination);
                              }}
                              //onCancel={cancel}
                              options={ring_timeout_array}
                              saveButtonLabel={
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                              }
                              saveButtonStyle="label-primary easy-edit-button rounded-lg"
                              cancelButtonLabel={
                                <i
                                  className="fa fa-times"
                                  aria-hidden="true"
                                ></i>
                              }
                              cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                              attributes={{
                                name: "destination_ring_timeout",
                                className: "form-control p-0",
                                style: { height: "35px" },
                              }}
                              placeholder="None"
                              viewAttributes={{ className: "pull-left" }}
                              value={destination.destination_ring_timeout}
                            />
                          </td>
                          <td>
                            <div className="d-flex">
                              <button
                                onClick={() =>
                                  fnDeleteRingGroupDestination(
                                    destination.ring_group_destination_uuid
                                  )
                                }
                                className="btn float-left ml-3 pb-1 trash-btn btn-danger btn-sm"
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </PerfectScrollbar>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-4 col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7 pr-0">
                  <div className="form-group mb-4 clearfix mt-4 input-primary">
                    <label className="w-100">Add Extension </label>
                    <select
                      className="form-control height45 bg-white"
                      name="extension"
                      onChange={handleChange}
                      value={ringGroupDestination.extension}
                    >
                      <option value="">Select Extension</option>
                      {extensions &&
                        extensions.map((extension) => (
                          <option key={extension.value} value={extension.value}>
                            {extension.text}
                          </option>
                        ))}
                    </select>
                    <span className="text-danger">{errors.extension}</span>
                  </div>
                </div>

                <div className="col-3 pr-0">
                  <div className="form-group mb-4 clearfix mt-4 input-primary">
                    <label className="w-100">Skill Set </label>
                    <select
                      className="form-control height45 bg-white"
                      name="skill_set"
                      onChange={handleChange}
                      value={ringGroupDestination.skill_set}
                    >
                      <option value="">Select Skill Set</option>
                      {skill_set_array &&
                        skill_set_array.map((arr) => (
                          <option key={arr.value} value={arr.value}>
                            {arr.label}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="col-2 form-group mb-4 clearfix mt-2 input-primary">
                  <button
                    className="btn btn-primary trash-btn mr-2 mt-5"
                    onClick={saveDestination}
                  >
                    <span className="ti-plus"></span>
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="col-10 pr-0">
                  <div className="form-group clearfix input-primary">
                    <label className="w-100">Add External number </label>
                    <input
                      type="text"
                      name="number"
                      onChange={handleChange}
                      className="form-control height45 float-left bg-white"
                      value={ringGroupDestination.number}
                    />

                    <span className="text-danger">{errors.did}</span>
                  </div>
                </div>

                <div className="col-2 form-group mb-4 clearfix mt-2 input-primary">
                  <button
                    className="btn btn-primary trash-btn mr-2 mt-4"
                    onClick={saveDestination}
                  >
                    <span className="ti-plus"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default GeneralFields;
