import React, { useState } from "react";
import Loader from "../../../../layouts/Loader";

const StepTwo = (props) => {
  const days = Array.from({ length: 25 }, (_, i) => i + 1);
  const [objects, setObjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stageData, setStageData] = useState({
    step_name: "",
    action_type: "",
    action_value: "",
    description: "",
  });
  const [errors, setErrors] = useState({
    step_name: "",
    action_type: "",
    action_value: "",
    description: "",
  });

  const handleBlur = (e) => {
    const newformdata = { ...props.formData };
    const errorsformdata = { ...props.errors };

    const newStageData = { ...stageData };

    let error_data = { ...errors };
    if (e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }
    newStageData[e.target.name] = e.target.value;

    setStageData(newStageData);

    // props.handleTwoData(newformdata);
    // props.handleTwoError(errorsformdata);
  };

  const addStage = () => {
    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (stageData.step_name.length === 0) {
      error_data["step_name"] = "Step name is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (stageData.action_type.length === 0) {
      error_data["action_type"] = "Please select action type.";
      setErrors(error_data);
      isError = "true";
    }

    if (stageData.action_value.length === 0) {
      error_data["action_value"] = "Please select action value.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      const newObject = {
        id: objects.length + 1,
        step_name: stageData.step_name,
        action_type: stageData.action_type,
        action_value: stageData.action_value,
        description: stageData.description,
      };

      // Update the state with the new object
      setObjects([...objects, newObject]);

      const newObject1 = {
        id: "",
        step_name: "",
        action_type: "",
        action_value: "",
        description: "",
      };
      setStageData(newObject1);
      setLoading(false);
    }

    // newStageData[e.target.name] = e.target.value;

    // setStageData(newStageData);
  };

  const handleReset = () => {
    const newObject1 = {
      id: "",
      step_name: "",
      action_type: "",
      action_value: "",
      description: "",
    };
    setStageData(newObject1);
  };

  const handleStageChange = (index, fieldName, newValue) => {
    setObjects((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index] = {
        ...updatedItems[index],
        [fieldName]: newValue,
      };

      return updatedItems;
    });
  };

  return (
    <>
      {loading && <Loader />}
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Add stages & Titles </h4>
        </div>
        <div className="card-body">
          <div className="basic-form">
            <div className="row">
              <div className="col-lg-4">
                <div className="form-group">
                  <label className="text-label">Step Name</label>
                  <span className="text-danger">*</span>
                  <input
                    type="text"
                    name="step_name"
                    id="step_name"
                    className="form-control bg-white"
                    placeholder="Step Name"
                    onChange={handleBlur}
                    value={stageData.step_name}
                  />
                  <span className="text-danger">{errors.step_name}</span>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label className="text-label">Action</label>
                  <span className="text-danger">*</span>
                  <select
                    className="form-control bg-white"
                    name="action_type"
                    id="action_type"
                    onChange={handleBlur}
                    value={stageData.action_type}
                  >
                    <option value="">Select Action</option>
                    <option value="sms_campaign">SMS Campaigns </option>
                    <option value="email_campaign">Email campaigns</option>
                    <option value="linked_invite">Linked Invite</option>
                    <option value="task">Task</option>
                    <option value="calendar_invite">Calendar Invite</option>
                  </select>
                  <span className="text-danger">{errors.action_type}</span>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="form-group">
                  <label className="text-label">Action Value</label>
                  <span className="text-danger">*</span>
                  <select
                    className="form-control bg-white"
                    name="action_value"
                    id="action_value"
                    onChange={handleBlur}
                    value={stageData.action_value}
                  >
                    <option value="">Select Department Type</option>
                    <option value="Executive">Executive</option>
                    <option value="Sales">Sales</option>
                    <option value="Marketing">Marketing</option>
                    <option value="HR">HR</option>
                    <option value="Development">Development</option>
                    <option value="Support">Support</option>
                    <option value="Other">Other</option>
                  </select>
                  <span className="text-danger">{errors.action_value}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="text-label">Description </label>
                  <span className="text-danger">*</span>
                  <textarea
                    className="form-control  bg-white"
                    placeholder="Message"
                    name="description"
                    value={stageData.description}
                    onChange={handleBlur}
                  ></textarea>

                  <span className="text-danger">{errors.description}</span>
                </div>
              </div>
            </div>
            <div className="row text-right">
              <div className="col-sm ">
                <button
                  className="btn btn-warning trash-btn pull-right ml-2"
                  onClick={handleReset}
                >
                  Reset
                </button>
                <button
                  className="btn btn-primary trash-btn pull-right ml-2"
                  onClick={addStage}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {objects.map((obj, index) => (
        <>
          <div className="card" key={obj.id}>
            <div className="card-header">
              <h4 className="card-title">{obj.step_name}</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">
                    Stage {obj.id}
                  </label>
                  <div className="col-sm-3">
                    <select
                      className="form-control bg-white"
                      name="stage_days"
                      id="stage_days"
                      onChange={(e) =>
                        handleStageChange(index, "stage_days", e.target.value)
                      }
                      value={obj.stage_days}
                    >
                      {days.map((day) => (
                        <option key={day} value={day}>
                          {day} Days
                        </option>
                      ))}
                    </select>
                  </div>

                  <label className="col-sm-1 col-form-label">Action</label>
                  <div className="col-sm-3">
                    <select
                      className="form-control bg-white"
                      name="action_type"
                      onChange={(e) =>
                        handleStageChange(index, "action_type", e.target.value)
                      }
                      value={obj.action_type}
                    >
                      <option value="">Select Action</option>
                      <option value="sms_campaign">SMS Campaigns </option>
                      <option value="email_campaign">Email campaigns</option>
                      <option value="linked_invite">Linked Invite</option>
                      <option value="task">Task</option>
                      <option value="calendar_invite">Calendar Invite</option>
                    </select>
                  </div>
                  <div className="col-sm-3">
                    <select
                      className="form-control bg-white"
                      name="action_value"
                      onChange={(e) =>
                        handleStageChange(index, "action_value", e.target.value)
                      }
                      value={obj.action_value}
                    >
                      <option value="">Select Department Type</option>
                      <option value="Executive">Executive</option>
                      <option value="Sales">Sales</option>
                      <option value="Marketing">Marketing</option>
                      <option value="HR">HR</option>
                      <option value="Development">Development</option>
                      <option value="Support">Support</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="col-sm-12 mt-2">{obj.description}</div>
                </div>

                <h4 className="card-title">Conditions</h4>
                <hr />
                <div className="basic-form">
                  <div className="form-group row">
                    <label className="col-sm-1 col-form-label">If</label>

                    <div className="col-sm-3">
                      <select
                        className="form-control bg-white"
                        name="department"
                        onChange={handleBlur}
                        // value={props.formData.stage_data.department}
                      >
                        <option value="">Select Action</option>
                        <option value="sms_campaign">SMS Campaigns </option>
                        <option value="email_campaign">Email campaigns</option>
                        <option value="linked_invite">Linked Invite</option>
                        <option value="task">Task</option>
                        <option value="calendar_invite">Calendar Invite</option>
                      </select>
                    </div>

                    <div className="col-sm-3">
                      <select
                        className="form-control bg-white"
                        name="department"
                        onChange={handleBlur}
                        // value={props.formData.stage_data.department}
                      >
                        <option value="">Select Value</option>
                        <option value="sms_campaign">SMS Campaigns </option>
                        <option value="email_campaign">Email campaigns</option>
                        <option value="linked_invite">Linked Invite</option>
                        <option value="task">Task</option>
                        <option value="calendar_invite">Calendar Invite</option>
                      </select>
                    </div>
                    <label className="col-sm-1 col-form-label">Then</label>
                    <div className="col-sm-3">
                      <select
                        className="form-control bg-white"
                        name="department"
                        onChange={handleBlur}
                        // value={props.formData.stage_data.department}
                      >
                        <option value="">Select Department Type</option>
                        <option value="Executive">Executive</option>
                        <option value="Sales">Sales</option>
                        <option value="Marketing">Marketing</option>
                        <option value="HR">HR</option>
                        <option value="Development">Development</option>
                        <option value="Support">Support</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ))}
    </>
  );
};

export default StepTwo;
