import React, { Fragment, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../../layouts/PageTitle";
import { getUserListDropdown } from "../../../actions/comman";
import PermissionCheck from "../../../common/PermissionCheck";
import Loader from "../../../layouts/Loader";
import { updateBulkContact } from "../../../actions/contacts";
import { getBulkUpdateFields } from "../../../actions/customContactLayout";
import ContactCustomFieldForm from "./ContactCustomFieldForm";

function BulkUpdateContactForm() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;

  let listing_per = PermissionCheck("did", "listing", "", "", "");

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }

  const params = useLocation();
  const contacts = params.state.contacts;

  const [users, setUsers] = useState([]);
  const [bulkUpdateFields, setBulkUpdateFields] = useState([]);

  const [contactData, setContactData] = useState({
    contacts: contacts,
    contact_type: "",
    bussiness_name: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
    address: "",
    address2: "",
    assign_user: "",
    bussiness_site: "",
    job_title: "",
    job_industry: "",
    token: token,
    createdby: createdby,
    main_user_uuid: createdby,
    user_uuid: createdby,
    custom_field_data: {},
    custom_field: "NO",
  });

  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBlur = (e) => {
    const newContactData = { ...contactData };
    
    newContactData[e.target.name] = e.target.value;
    setContactData(newContactData);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    setLoading(true);

    console.log(contactData);

      //call create user API
      dispatch(updateBulkContact(contactData))
        .then(() => {
          navigate("/contacts", { replace: true });
        })
        .catch(() => {
          setLoading(false);
        });
    
  };

  useEffect(() => {

    setLoading(true);
    dispatch(
      getBulkUpdateFields(
        main_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        setBulkUpdateFields(response.data);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    dispatch(
      getUserListDropdown(
        "create",
        "",
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        setUsers(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const handlecustomFieldData = (name, value) => {
    
    const newContactData = { ...contactData };
    newContactData["custom_field_data"][name] = value;
    newContactData["custom_field"] = "YES";

    setContactData(newContactData);
  };

  const handleDependentFieldData = (data, value) => {

    // let arr = {
    //   value : value,
    //   main_field_uuid : data.main_field_uuid,
    //   contact_custom_dependent_field_uuid : data.contact_custom_dependent_field_uuid
    // }
    
    // const newContactData = { ...contactData };
    // newContactData["dependent_field_data"][data.contact_custom_dependent_field_uuid] = arr;
    // newContactData["dependent_field"] = "YES";

    //setContactData(newContactData);
  };

  return (
    <Fragment>
       {loading && <Loader />}
      <PageTitle activeMenu="Bulk Update Contact" motherMenu="Contacts" />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Bulk Update Contact</h4>
              <div >
                <div className="btn-group pull-right">
                  <Button
                    type="button"
                    className="btn btn-default btn-sm"
                    onClick={() => navigate("/contacts", { replace: true })}
                  >
                    <i className="fa fa-reply" aria-hidden="true"></i> Back
                  </Button>
                </div>
              </div>
            </div>
            <div className="card-body">
              <form action="" onSubmit={(e) => submitHandler(e)}>

              {bulkUpdateFields.map((val) => (

                <div className="row" key={val.contact_bulk_update_field_uuid}>

                  {val.form_field_type === 'MAIN_FORM' && 
                      <div className="col-sm">
                        <div className="form-group input-primary">
                          
                          {val.contact_field === 'contact_type' && 
                            <>
                              <label className="mb-1">
                                <strong>Contact Type</strong>
                              </label>
                              <select
                                className="form-control bg-white"
                                name="contact_type"
                                onChange={handleBlur}
                                value={contactData.contact_type}
                              >
                                <option value="">Select Type</option>
                                <option value="Individual">Individual</option>
                                <option value="Business">Business</option>
                              </select>
                            </>
                          }

                          {val.contact_field === 'job_title' && 
                            <>
                              <label className="mb-1">
                                <strong>Job Title</strong>
                              </label>
                              <input
                                type="text"
                                className="form-control bg-white"
                                name="job_title"
                                onChange={handleBlur}
                                value={contactData.job_title}
                              />
                              {/* <span className="text-danger">
                                {errors.job_title}
                              </span> */}
                            </>
                          }

                          {val.contact_field === 'job_industry' && 
                            <>
                              <label className="mb-1">
                                <strong>Job Industry</strong>
                              </label>
                              <input
                                type="text"
                                className="form-control bg-white"
                                name="job_industry"
                                onChange={handleBlur}
                                value={contactData.job_industry}
                              />
                              {/* <span className="text-danger">
                                {errors.job_title}
                              </span> */}
                            </>
                          }

                          {val.contact_field === 'bussiness_name' && 
                            <>
                              <label className="mb-1">
                                <strong>Bussiness Name</strong>
                              </label>
                              <input
                                type="text"
                                className="form-control bg-white"
                                name="bussiness_name"
                                onChange={handleBlur}
                                value={contactData.bussiness_name}
                              />
                              {/* <span className="text-danger">
                                {errors.job_title}
                              </span> */}
                            </>
                          }

                          {val.contact_field === 'bussiness_site' && 
                            <>
                              <label className="mb-1">
                                <strong>Bussiness Site</strong>
                              </label>
                              <input
                                type="text"
                                className="form-control bg-white"
                                name="bussiness_site"
                                onChange={handleBlur}
                                value={contactData.bussiness_site}
                              />
                              {/* <span className="text-danger">
                                {errors.job_title}
                              </span> */}
                            </>
                          }

                          {val.contact_field === 'assign_to' && 
                            <>
                              <label className="mb-1">
                                <strong>Assign User</strong>
                              </label>
                              <select
                                className="form-control bg-white"
                                name="assign_user"
                                onChange={handleBlur}
                                value={contactData.assign_user}
                              >
                                <option value="">Select User</option>
                                {users.map((user) => (
                                  <option key={user.uuid} value={user.uuid}>
                                    {user.user_name}
                                  </option>
                                ))}
                                <option key={createdby} value={createdby}>
                                  Assign Me
                                </option>
                              </select>
                              {/* <span className="text-danger">
                                {errors.job_title}
                              </span> */}
                            </>
                          }

                          {val.contact_field === 'address_1' && 
                            <>
                              <label className="mb-1">
                                <strong>Address</strong>
                              </label>
                              <input
                                type="text"
                                className="form-control bg-white"
                                //placeholder="Address"
                                name="address"
                                onChange={handleBlur}
                                value={contactData.address}
                              />
                              {/* <span className="text-danger">
                                {errors.job_title}
                              </span> */}
                            </>
                          }

                        {val.contact_field === 'address_2' && 
                            <>
                              <label className="mb-1">
                                <strong>Address2</strong>
                              </label>
                              <input
                                type="text"
                                className="form-control bg-white"
                                //placeholder="Address2"
                                name="address2"
                                onChange={handleBlur}
                                value={contactData.address2}
                              />
                              {/* <span className="text-danger">
                                {errors.job_title}
                              </span> */}
                            </>
                          }

                          {val.contact_field === 'country' && 
                            <>
                              <label className="mb-1">
                                <strong>Country</strong>
                              </label>
                              <input
                                type="text"
                                className="form-control bg-white"
                                //placeholder="Country"
                                name="country"
                                onChange={handleBlur}
                                value={contactData.country}
                              />
                              {/* <span className="text-danger">
                                {errors.job_title}
                              </span> */}
                            </>
                          }

                          {val.contact_field === 'state' && 
                            <>
                              <label className="mb-1">
                                <strong>State</strong>
                              </label>
                              <input
                                type="text"
                                className="form-control bg-white"
                                //placeholder="State"
                                name="state"
                                onChange={handleBlur}
                                value={contactData.state}
                              />
                              {/* <span className="text-danger">
                                {errors.job_title}
                              </span> */}
                            </>
                          }

                          {val.contact_field === 'city' && 
                            <>
                              <label className="mb-1">
                                <strong>City</strong>
                              </label>
                              <input
                                type="text"
                                className="form-control bg-white"
                                //placeholder="City"
                                name="city"
                                onChange={handleBlur}
                                value={contactData.city}
                              />
                              {/* <span className="text-danger">
                                {errors.job_title}
                              </span> */}
                            </>
                          }

                          {val.contact_field === 'zipcode' && 
                            <>
                              <label className="mb-1">
                                <strong>Zipcode</strong>
                              </label>
                              <input
                                type="text"
                                className="form-control bg-white"
                                //placeholder="Zipcode"
                                name="zipcode"
                                onChange={handleBlur}
                                value={contactData.zipcode}
                              />
                              {/* <span className="text-danger">
                                {errors.job_title}
                              </span> */}
                            </>
                          }

                          {/* <span className="text-danger">{errors.contact_type}</span> */}
                        </div>
                      </div>
                  }


                  {val.form_field_type === 'CUSTOM' && 
                      
                      <ContactCustomFieldForm  field={val} handlecustomFieldData={handlecustomFieldData} contactFiledData={contactData.custom_field_data} handleDependentFieldData={handleDependentFieldData} dependent_field_data={contactData.dependent_field_data} />
                  }
                  
                </div>

              ))}

                <div className="row text-right">
                  <div className="col-sm">
                    <Button type="submit" variant="primary" className="btn-sm">
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default BulkUpdateContactForm;
