import React, { Fragment, useState } from "react";
import "./style.css";
import Tooltip from "rc-tooltip";
import EasyEdit, { Types } from "react-easy-edit";

function GeneralFields(props) {
  let extensionData = props.extensionData;

  const handleChange = (e, default_val) => {
    let value = "";
    if (default_val === "YES") {
      value = "NO";
    } else {
      value = "YES";
    }

    let data = { ...extensionData };
    data[e.target.name] = value;
    extensionData = { ...data };

    props.save(extensionData);
  };

  const save = (value, attribute) => {
    let data = { ...extensionData };
    if (attribute === "extension_password") {
      data[attribute] = value;
    } else {
      data[attribute] = value;
    }

    props.save(data);
  };

  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const CustomDisplay = (props) => {
    if (passwordType === "password") {
      return <div>**********</div>;
    } else {
      return (
        <div>
          <label>{extensionData.extension_password}</label>
        </div>
      );
    }
  };

  let number_array = new Array(30).fill(1).map((_, i) => {
    return { label: i + 1, value: i + 1 };
  });

  return (
    <Fragment>
      
      <div className="row">
        <div className="col-lg-6 col-md-6 col-6">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                Extension
                <Tooltip placement="top" overlay={<span>Extension</span>}>
                  <span className="ti-info-alt ml-1"></span>
                </Tooltip>
              </h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="ml-3 form-group input-primary">
                    <label className="w-100">
                      Extension
                      <Tooltip placement="top" overlay={<span>Extension</span>}>
                        <span className="ti-info-alt ml-1"></span>
                      </Tooltip>
                    </label>
                    <p>{extensionData.extension}</p>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-5">
                  <div className="form-group input-primary">
                    <label className="w-100">
                      Password
                      <span className="text-danger">*</span>
                      <Tooltip placement="top" overlay={<span>Password</span>}>
                        <span className="ti-info-alt ml-1"></span>
                      </Tooltip>
                    </label>

                    <EasyEdit
                      type={Types.PASSWORD}
                      onSave={(value) => {
                        save(value, "extension_password");
                      }}
                      displayComponent={<CustomDisplay />}
                      saveButtonLabel={
                        <i className="fa fa-check" aria-hidden="true"></i>
                      }
                      saveButtonStyle="label-primary easy-edit-button rounded-lg"
                      cancelButtonLabel={
                        <i className="fa fa-times" aria-hidden="true"></i>
                      }
                      cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                      attributes={{
                        name: "extension_password",
                        className: "form-control bg-white h-75",
                      }}
                      placeholder="Password"
                      value={extensionData.extension_password}
                    />
                  </div>
                </div>
                <div className="col-lg-1 col-md-1 col-1">
                  <div className="mt-2">
                    {passwordType === "password" ? (
                      <i
                        className="fa fa-eye-slash"
                        onClick={togglePassword}
                      ></i>
                    ) : (
                      <i className="fa fa-eye" onClick={togglePassword}></i>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-6">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                User Info
                <Tooltip placement="top" overlay={<span>User Info</span>}>
                  <span className="ti-info-alt ml-1"></span>
                </Tooltip>
              </h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="ml-3 form-group input-primary">
                    <label className="w-100">
                      Name
                      <Tooltip placement="top" overlay={<span>Name</span>}>
                        <span className="ti-info-alt ml-1"></span>
                      </Tooltip>
                    </label>
                    {extensionData.username === " " ||
                    extensionData.username === null ? (
                      <>{"None"}</>
                    ) : (
                      <p>{extensionData.username}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="form-group input-primary">
                    <label className="w-100">
                      Ext Caller ID
                      <Tooltip
                        placement="top"
                        overlay={<span>Ext Caller ID</span>}
                      >
                        <span className="ti-info-alt ml-1"></span>
                      </Tooltip>
                    </label>
                    {extensionData.assign_did_number === "" ||
                    extensionData.assign_did_number === null ? (
                      <>{"None"}</>
                    ) : (
                      <p>{extensionData.assign_did_number}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 col-md-6 col-6">
          <div className="card">
            <div className="card-header customToggle">
              <h4 className="card-title">
                Call Waiting
                <Tooltip placement="top" overlay={<span>Call Waiting</span>}>
                  <span className="ti-info-alt ml-1"></span>
                </Tooltip>
              </h4>
              <span className="custom-control custom-switch float-right">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="call_waiting_enabled"
                  name="call_waiting_enabled"
                  checked={
                    extensionData.call_waiting_enabled === "YES" ? "checked" : ""
                  }
                  onChange={(e) =>
                    handleChange(e, extensionData.call_waiting_enabled)
                  }
                />
                <label
                  className="custom-control-label check_box_pointer"
                  htmlFor="call_waiting_enabled"
                ></label>
              </span>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="ml-3 form-group input-primary">
                    <label className="w-100">
                      Waiting Timeout
                      <Tooltip
                        placement="top"
                        overlay={<span>Waiting Timeout</span>}
                      >
                        <span className="ti-info-alt ml-1"></span>
                      </Tooltip>
                    </label>
                    <EasyEdit
                      type={Types.SELECT}
                      onSave={(value) => {
                        save(value, "call_waiting_timeout");
                      }}
                      //onCancel={cancel}
                      options={number_array}
                      saveButtonLabel={
                        <i className="fa fa-check" aria-hidden="true"></i>
                      }
                      saveButtonStyle="label-primary easy-edit-button rounded-lg"
                      cancelButtonLabel={
                        <i className="fa fa-times" aria-hidden="true"></i>
                      }
                      cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                      attributes={{
                        name: "call_waiting_timeout",
                        className: "form-control bg-white p-0 ",
                        style: { height: "35px" },
                      }}
                      onValidate={(value) => {
                        const re = /^[0-9\b]+$/;
                        if (value === "" || re.test(value)) {
                          return true;
                        } else {
                          return false;
                        }
                      }}
                      validationMessage="Please enter or valid call waiting timeout"
                      placeholder="Waiting Timeout"
                      value={
                        extensionData.call_waiting_timeout
                          ? extensionData.call_waiting_timeout
                          : null
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-6">
          <div className="card">
            <div className="card-header customToggle">
              <h4 className="card-title">
                Do Not Disturb (DND)
                <Tooltip
                  placement="top"
                  overlay={<span>Do Not Disturb (DND)</span>}
                >
                  <span className="ti-info-alt ml-1"></span>
                </Tooltip>
              </h4>
              <span className="custom-control custom-switch float-right">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="dnd_enabled"
                  name="dnd_enabled"
                  checked={extensionData.dnd_enabled === "YES" ? "checked" : ""}
                  onChange={(e) => handleChange(e, extensionData.dnd_enabled)}
                />
                <label
                  className="custom-control-label check_box_pointer"
                  htmlFor="dnd_enabled"
                ></label>
              </span>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="ml-3 form-group input-primary">
                    <p>
                      {extensionData.dnd_enabled === "YES"
                        ? "Enabled"
                        : "Disabled"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default GeneralFields;
