import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { notifyInfo, notifyError } from "../../Toastr/Toastr";
import { updateCompanyInfo } from "../../../actions/auth";
import EasyEdit, { Types } from "react-easy-edit";
import profile from "../../../../images/profile/demo_profile.png";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import Tooltip from "rc-tooltip";
import { GoogleApiWrapper } from "google-maps-react";
import Loader from "../../../layouts/Loader";
const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const CompanyProfile = (props) => {
  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const PROFILE_URL = process.env.REACT_APP_SERVER_PROFILE_URL;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const [loading, setLoading] = useState(false);
  const [manuallyCheck, setManuallyCheck] = useState("checked");
  const [address, setAddress] = useState(
    props.formData1.company_data.company_address
  );

  const [errors, setErrors] = useState({
    company_email: "",
    company_phone_number: "",
    company_address: "",
    company_address2: "",
    city: "",
    state: "",
    postal_code: "",
    phone_number: "",
    time_zone: "",
  });

  // Create a reference to the hidden file input element
  const hiddenFileInput1 = useRef(null);
  // Programatically click the hidden file input element
  // when the Button component is clicked

  useEffect(() => {
    if (props.formData1.company_data.company_address !== "") {
      setAddress(props.formData1.company_data.company_address);
    }
  }, [props.formData1.company_data.company_address]);

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (e) => {
    const MAX_FILE_SIZE = 5120; // 5MB
    if (
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg" ||
      e.target.files[0].type === "image/jpeg"
    ) {
      const fileSizeKiloBytes = e.target.files[0].size / 1024;

      if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        // setErrorMsg("File size is greater than maximum limit");
        notifyError("File size not allow greater then 5MB.");
        return;
      }

      save(e.target.files[0], "file", "company_photo");
    } else {
      notifyError("File allow only waw and mp3.");
      return;
    }
    // const fileUploaded = event.target.files[0];
    // handleFile(fileUploaded);
  };

  const save = (value, attribute) => {
    let formData = new FormData();

    const newformdata = { ...props.formData1 };
    let data = { ...newformdata.company_data };

    setLoading(true);

    if (attribute === "file") {
      formData.append("file", value);

      formData.append("fileName", value.name);
      formData.append(
        "company_uuid",
        props.formData1.company_data.company_uuid
      );
      formData.append("company_email", data.company_email);
      formData.append("company_profile", data.company_logo);
      formData.append("company_phone_number", data.company_phone_number);
      formData.append("company_address", data.company_address);
      formData.append("company_address2", data.company_address2);
      formData.append("locality", data.locality);
      formData.append("city", data.city);
      formData.append("state", data.state);
      formData.append("postal_code", data.postal_code);
      formData.append("token", token);
      formData.append("createdby", createdby);
      formData.append("main_uuid", main_uuid);
      formData.append("user_uuid", props.formData1.company_data.user_uuid);
      formData.delete(attribute);
      formData.append(attribute, value);
      
    } else {
      formData.append("file", "");
      formData.append("fileName", "");
      formData.append(
        "company_uuid",
        props.formData1.company_data.company_uuid
      );
      formData.append("company_email", data.company_email);
      formData.append("company_profile", data.company_logo);
      formData.append("locality", data.locality);
      formData.append("company_phone_number", data.company_phone_number);
      formData.append("company_address", data.company_address);
      formData.append("company_address2", data.company_address2);
      formData.append("city", data.city);
      formData.append("state", data.state);
      formData.append("postal_code", data.postal_code);
      formData.append("token", token);
      formData.append("createdby", createdby);
      formData.append("main_uuid", main_uuid);
      formData.append("user_uuid", props.formData1.company_data.user_uuid);

      formData.delete(attribute);

      formData.append(attribute, value);
      
    }

    dispatch(updateCompanyInfo(formData, token))
      .then((res) => {
        if (res.status === 200) {
          notifyInfo(res.message);
          setLoading(false);
          props.handleCompanyEvent(Math.random);
        } else if (res.status === 500) {
          setLoading(false);
          notifyError("Something went wrong.");
        } else {
          setLoading(false);
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {});
  };

  let company_logo_src = "";
  if (
    props.formData1.company_data.company_logo !== "" &&
    props.formData1.company_data.company_logo !== null
  ) {
    company_logo_src = PROFILE_URL + props.formData1.company_data.company_logo;
  } else {
    company_logo_src = profile;
  }

  function handleSelectCheck(data) {
    let rec_chk_val = data.target.value;

    if (rec_chk_val !== "" && rec_chk_val === "checked") {
      setManuallyCheck("");
    } else {
      setManuallyCheck("checked");
    }
  }

  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      
      let error_data = { ...errors };
      setLoading(true);

      let add_data = results[0].address_components;

      const newformdata = { ...props.formData1 };

      newformdata.company_data.company_address = results[0].formatted_address;

      if (newformdata.company_data.company_address.length === 0) {
        error_data["company_address"] = "Address is required.";
        setErrors(error_data);
      } else {
        error_data["company_address"] = "";
        setErrors(error_data);
      }

      setAddress(results[0].formatted_address);
      add_data.forEach((add, index) => {
        if (
          add.types.find((element) => element === "subpremise") === "subpremise"
        ) {
          newformdata.company_data.house_number = add.long_name;
        }

        if (add.types.find((element) => element === "premise") === "premise") {
          newformdata.company_data.locality = add.long_name;
        }

        if (add.types.find((element) => element === "route") === "route") {
          newformdata.company_data.locality = add.long_name;
        }

        if (
          add.types.find((element) => element === "postal_code") === "postal_code"
        ) {
          newformdata.company_data.postal_code = add.long_name;
        }

        if (
          add.types.find(
            (element) => element === "administrative_area_level_1"
          ) === "administrative_area_level_1"
        ) {
          newformdata.company_data.state = add.long_name;
        }

        if (
          add.types.find(
            (element) => element === "administrative_area_level_2"
          ) === "administrative_area_level_2"
        ) {
          newformdata.company_data.city = add.long_name;
        } else {
          if (
            add.types.find(
              (element) => element === "administrative_area_level_3"
            ) === "administrative_area_level_3"
          ) {
            newformdata.company_data.city = add.long_name;
          }
        }

      });

      let data = { ...newformdata.company_data };

      let formData = new FormData();

      formData.append("file", "");
      formData.append("fileName", "");
      formData.append("company_uuid", newformdata.company_data.company_uuid);
      formData.append("company_email", data.company_email);
      formData.append("company_profile", data.company_logo);
      formData.append("locality", data.locality);
      formData.append("company_phone_number", data.company_phone_number);
      formData.append("company_address", data.company_address);
      formData.append("company_address2", "");
      formData.append("city", data.city);
      formData.append("state", data.state);
      formData.append("postal_code", data.postal_code);
      formData.append("phone_number", data.phone_number);
      formData.append("token", token);
      formData.append("createdby", createdby);
      formData.append("main_uuid", main_uuid);
      formData.append("user_uuid", newformdata.company_data.user_uuid);

      dispatch(updateCompanyInfo(formData, token))
        .then((res) => {
          if (res.status === 200) {
            notifyInfo(res.message);
            setLoading(false);
            props.handleCompanyEvent(Math.random);
          } else if (res.status === 500) {
            setLoading(false);
            notifyError("Something went wrong.");
          } else {
            setLoading(false);
            notifyError("Something went wrong.");
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error("Error selecting address:", error);
    }
  };

  return (
    <div className="pt-3">
      {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>Company Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-3">
            <div className="profile-photo">
              <img
                src={company_logo_src}
                className="img-fluid rounded-circle cmp_img_height"
                alt="Company Logo"
              />
            </div>
            <div className="mt-2">
              <Tooltip placement="top" overlay={<span>Upload New File</span>}>
                <button
                  className="button-upload btn btn-sm btn-info ml-2 position-relative"
                  onClick={(e) => hiddenFileInput1.current.click()}
                >
                  Company Logo
                </button>
              </Tooltip>

              <input
                type="file"
                onChange={(e) => handleChange(e)}
                ref={hiddenFileInput1}
                //ref={el => (hiddenFileInput = el)}
                id="company_photo"
                name="company_photo"
                accept="image/png, image/gif, image/jpeg"
                style={{ display: "none" }} // Make the file input element invisible
              />
            </div>
          </div>

          <div className="col-4">
            <div className="col-12">
              <div className="form-group">
                <label className="mb-1">
                  <strong>Company Name</strong>
                </label>
                <p>{props.formData1.company_data.company_name}</p>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label className="mb-1">
                  <strong>Email</strong>
                  <span className="text-danger">*</span>
                </label>

                <EasyEdit
                  type={Types.TEXT}
                  onSave={(value) => {
                    save(value, "company_email");
                  }}
                  saveButtonLabel={
                    <i className="fa fa-check" aria-hidden="true"></i>
                  }
                  saveButtonStyle="label-primary easy-edit-button rounded-lg"
                  cancelButtonLabel={
                    <i className="fa fa-times" aria-hidden="true"></i>
                  }
                  cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                  attributes={{
                    name: "company_email",
                    className: "form-control bg-white h-75",
                  }}
                  onValidate={(value) => {
                   
                    if (value === "") {
                      return false;
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
                    ) {
                      return false;
                    } else {
                      return true;
                    }
                  }}
                  placeholder="Email"
                  value={props.formData1.company_data.company_email}
                />
                <span className="text-danger">{errors.company_email}</span>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label className="mb-1">
                  <strong>Phone Number</strong>
                  <span className="text-danger">*</span>
                </label>

                <EasyEdit
                  type={Types.TEXT}
                  onSave={(value) => {
                    save(value, "company_phone_number");
                  }}
                  saveButtonLabel={
                    <i className="fa fa-check" aria-hidden="true"></i>
                  }
                  saveButtonStyle="label-primary easy-edit-button rounded-lg"
                  cancelButtonLabel={
                    <i className="fa fa-times" aria-hidden="true"></i>
                  }
                  cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                  attributes={{
                    name: "company_phone_number",
                    className: "form-control bg-white h-75",
                  }}
                  onValidate={(value) => {
                    if (!/^[0-9]{7,11}$/i.test(value)) {
                      return false;
                    } else {
                      return true;
                    }
                  }}
                  placeholder="Phone Number"
                  value={props.formData1.company_data.company_phone_number}
                />
                <span className="text-danger">
                  {errors.company_phone_number}
                </span>
              </div>
            </div>
          </div>

          <div className="col-5">
            <div className="row mb-2"></div>
            <div className="row  mb-2">
              <div className="col-12">
                <div className="form-group">
                  <label className="text-label">
                    Company Addess
                    <span className="text-danger lr-4">*</span>
                    <span className="ml-4">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="check1"
                        name="is_recurring"
                        onChange={handleSelectCheck}
                        value={manuallyCheck}
                      />

                      <label className="form-check-label" htmlFor="check1">
                        Enter manually
                      </label>
                    </span>
                  </label>
                </div>
                {manuallyCheck === "checked" ? (
                  <PlacesAutocomplete
                    value={address}
                    onChange={(value) => setAddress(value)}
                    onSelect={handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          {...getInputProps({
                            placeholder: "Search Places...",
                            className: "form-control",
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => (
                            <div
                              {...getSuggestionItemProps(suggestion)}
                              key={suggestion.placeId}
                            >
                              {suggestion.description}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                ) : (
                  <div className="form-group">
                    <EasyEdit
                      type={Types.TEXTAREA}
                      onSave={(value) => {
                        save(value, "company_address");
                      }}
                      saveButtonLabel={
                        <i className="fa fa-check" aria-hidden="true"></i>
                      }
                      saveButtonStyle="label-primary easy-edit-button rounded-lg"
                      cancelButtonLabel={
                        <i className="fa fa-times" aria-hidden="true"></i>
                      }
                      cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                      attributes={{
                        name: "city",
                        className: "form-control bg-white h-75",
                      }}
                      placeholder="Please enter company_address"
                      value={props.formData1.company_data.company_address}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label className="text-label">Suite/Apt</label>
                  <span className="text-danger">*</span>

                  <EasyEdit
                    type={Types.TEXT}
                    onSave={(value) => {
                      save(value, "locality");
                    }}
                    saveButtonLabel={
                      <i className="fa fa-check" aria-hidden="true"></i>
                    }
                    saveButtonStyle="label-primary easy-edit-button rounded-lg"
                    cancelButtonLabel={
                      <i className="fa fa-times" aria-hidden="true"></i>
                    }
                    cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                    attributes={{
                      name: "city",
                      className: "form-control bg-white h-75",
                    }}
                    placeholder="Please enter suite/apt"
                    value={props.formData1.company_data.locality}
                  />
                  <span className="text-danger">{errors.locality}</span>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label className="text-label">City</label>
                  <span className="text-danger">*</span>

                  <EasyEdit
                    type={Types.TEXT}
                    onSave={(value) => {
                      save(value, "city");
                    }}
                    saveButtonLabel={
                      <i className="fa fa-check" aria-hidden="true"></i>
                    }
                    saveButtonStyle="label-primary easy-edit-button rounded-lg"
                    cancelButtonLabel={
                      <i className="fa fa-times" aria-hidden="true"></i>
                    }
                    cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                    attributes={{
                      name: "city",
                      className: "form-control bg-white h-75",
                    }}
                    onValidate={(value) => {
                      return value ? true : false;
                    }}
                    placeholder="Please enter city"
                    value={props.formData1.company_data.city}
                  />
                  <span className="text-danger">{errors.city}</span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label className="text-label">State</label>
                  <span className="text-danger">*</span>

                  <EasyEdit
                    type={Types.TEXT}
                    onSave={(value) => {
                      save(value, "state");
                    }}
                    saveButtonLabel={
                      <i className="fa fa-check" aria-hidden="true"></i>
                    }
                    saveButtonStyle="label-primary easy-edit-button rounded-lg"
                    cancelButtonLabel={
                      <i className="fa fa-times" aria-hidden="true"></i>
                    }
                    cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                    attributes={{
                      name: "state",
                      className: "form-control bg-white h-75",
                    }}
                    onValidate={(value) => {
                      return value ? true : false;
                    }}
                    placeholder="Please enter state"
                    value={props.formData1.company_data.state}
                  />
                  <span className="text-danger">{errors.state}</span>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label className="text-label">Postal code</label>
                  <span className="text-danger">*</span>

                  <EasyEdit
                    type={Types.TEXT}
                    onSave={(value) => {
                      save(value, "postal_code");
                    }}
                    saveButtonLabel={
                      <i className="fa fa-check" aria-hidden="true"></i>
                    }
                    saveButtonStyle="label-primary easy-edit-button rounded-lg"
                    cancelButtonLabel={
                      <i className="fa fa-times" aria-hidden="true"></i>
                    }
                    cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                    attributes={{
                      name: "postal_code",
                      className: "form-control bg-white h-75",
                    }}
                    onValidate={(value) => {
                      if (!/^[0-9]{4,6}$/i.test(value)) {
                        return false;
                      } else {
                        return true;
                      }
                    }}
                    placeholder="Please enter postal code"
                    value={props.formData1.company_data.postal_code}
                  />
                  <span className="text-danger">{errors.postal_code}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: API_KEY,
})(CompanyProfile);
