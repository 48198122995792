import { SET_MESSAGE, API_FAIL } from "../types";

import emailCampaignService from "../../services/emailCampaign.service";

//emailCampaign

export const getEmailCampaignList = (
  data,
  token
) => (dispatch) => {
  return emailCampaignService.getEmailCampaignList(
    data,
    token
  ).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


export const createEmailCampaign = (
  data,
  token
) => (dispatch) => {
  return emailCampaignService.createEmailCampaign(
    data,
    token
  ).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getEmailCampaignDetails = (
  data,
  token
) => (dispatch) => {
  return emailCampaignService.getEmailCampaignDetails(
    data,
    token
  ).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


export const updateEmailCampaign = (
  data,
  token
) => (dispatch) => {
  return emailCampaignService.updateEmailCampaign(
    data,
    token
  ).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


export const deleteEmailCampaign = (
  email_campaign_uuid, createdby,
  token
) => (dispatch) => {
  return emailCampaignService.deleteEmailCampaign(
    email_campaign_uuid, createdby,
    token
  ).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


export const getEmailCampaignReport = (
  email_campaign_uuid, createdby,start_date,end_date,
  token
) => (dispatch) => {
  return emailCampaignService.getEmailCampaignReport(
    email_campaign_uuid, createdby,start_date,end_date,
    token
  ).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};