import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { notifyInfo } from "../components/Toastr/Toastr";
import { useLocation } from "react-router-dom";
import { createPassword, checkToken, createNewLink } from "../actions/auth";
import validator from "validator";
import Loader from "../layouts/Loader";

const CreatePassword = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [loading, setLoading] = useState(false);
  const [expiresStatus, setExpiresStatus] = useState("No");

  const [passData, setPassData] = useState({
    password: "",
    confirm_password: "",
  });

  const [emailData, setEmailData] = useState({
    email: "",
  });

  const [errors, setErrors] = useState({
    password: "",
    confirm_password: "",
  });

  const [emailErrors, setEmailErrors] = useState({
    email: "",
  });

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (expiresStatus === "No") {
      let error_data = { ...errors };
      let isError = "false";

      setLoading(true);

      if (passData.password.length === 0) {
        error_data["password"] = "Password is required.";
        setErrors(error_data);
        isError = "true";
      }

      if (passData.confirm_password.length === 0) {
        error_data["confirm_password"] = "Confirm password is required.";
        setErrors(error_data);
        isError = "true";
        setLoading(false);
      } else {
        if (passData.password !== passData.confirm_password) {
          error_data["confirm_password"] =
            "Password and confirm passeord can not match.";
          setErrors(error_data);
          isError = "true";
        }
      }

      if (isError === "true") {
        setLoading(false);
        return;
      } else {
        //call create user API

        dispatch(createPassword(passData, params.get("token")))
          .then((response) => {
            notifyInfo(response.message);
            setLoading(false);
            navigate("/login", { replace: true });
          })
          .catch(() => {
            setLoading(false);
          });
      }
    } else {
      let error_data = { ...emailErrors };
      let isError = "false";

      setLoading(true);

      if (emailData.email.length === 0) {
        error_data["email"] = "Email is required.";
        setEmailErrors(error_data);
        isError = "true";
      }

      if (isError === "true") {
        setLoading(false);
        return;
      } else {
        //call create create New Link API

        dispatch(createNewLink(emailData, params.get("token")))
          .then((response) => {
            notifyInfo(response.message);
            setLoading(false);
            navigate("/login", { replace: true });
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    dispatch(checkToken(params.get("token")))
      .then((response) => {
        if (response.status === 202) {
          notifyInfo(response.message);
          navigate("/login", { replace: true });
        } else if (response.status === 203) {
          setExpiresStatus("Yes");
        } else if (response.status === 204) {
          notifyInfo(response.message);
          navigate("/login", { replace: true });
        }
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const handleBlur = (e) => {
    const newPassdata = { ...passData };
    let error_data = { ...errors };

    if (e.target.name === "password" && e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      newPassdata[e.target.name] = e.target.value;
      setErrors(error_data);
    } else if (e.target.name === "password") {
      // error_data[e.target.name] = "";
      // setErrors(error_data);
      // newPassdata[e.target.name] = e.target.value;
      if (
        validator.isStrongPassword(e.target.value, {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        error_data[e.target.name] = "";
        newPassdata[e.target.name] = e.target.value;
        //setErrors(error_data);
      } else {
        error_data[e.target.name] =
          "Password is weak. Should have a minimum of 8 characters. Ex. Test@123";
        // setErrors(error_data);
        newPassdata[e.target.name] = e.target.value;
      }
    }

    if (e.target.name === "confirm_password" && e.target.value.length === 0) {
      error_data["confirm_password"] = "Confirm password is required.";
      newPassdata["confirm_password"] = e.target.value;
      // setErrors(error_data);
    } else if (e.target.name === "confirm_password") {
      // error_data[e.target.name] = "";
      // setErrors(error_data);
      // newPassdata[e.target.name] = e.target.value;
      if (
        validator.isStrongPassword(e.target.value, {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
       
        error_data[e.target.name] = "";
        newPassdata[e.target.name] = e.target.value;
        //setErrors(error_data);
      } else {
        error_data[e.target.name] =
          "Password is weak. Should have a minimum of 8 characters. Ex. Test@123";
        // setErrors(error_data);
        newPassdata[e.target.name] = e.target.value;
      }
    }

    setErrors(error_data);

    setPassData(newPassdata);
  };

  const handleBlur1 = (e) => {
    const newPassdata = { ...emailData };
    let error_data = { ...emailErrors };

    if (e.target.name === "email" && e.target.value.length === 0) {
      newPassdata[e.target.name] = e.target.value;
      error_data[e.target.name] = e.target.name + " is required.";
      setEmailErrors(error_data);
    } else {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
        error_data[e.target.name] = "Email is invalid.";
        setEmailErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setEmailErrors(error_data);
      }
      newPassdata[e.target.name] = e.target.value;
    }

    setEmailData(newPassdata);
  };

  return (
    <div className="authincation h-100 p-meddle">
      {loading && <Loader />}
      <div className="container h-100">
        {" "}
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              {expiresStatus === "No" ? (
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <h4 className="text-center mb-4">Create Password</h4>
                      <form onSubmit={(e) => onSubmit(e)}>
                        <div className={`form-group`}>
                          <label>
                            <strong>Password</strong>
                          </label>

                          <input
                            type="password"
                            className="form-control"
                            name="password"
                            value={passData.password}
                            onChange={handleBlur}
                            //   onChange={(e) => setPassw(e.target.value)}
                            placeholder="Password"
                          />
                          <span className="text-danger">{errors.password}</span>
                        </div>
                        <div className={`form-group`}>
                          <label>
                            <strong>Confirm Password</strong>
                          </label>

                          <input
                            type="password"
                            className="form-control"
                            name="confirm_password"
                            value={passData.confirm_password}
                            onChange={handleBlur}
                            //   onChange={(e) => setPassw(e.target.value)}
                            placeholder="Confirm Password"
                          />
                          <span className="text-danger">
                            {errors.confirm_password}
                          </span>
                        </div>

                        <div className="text-center">
                          <input
                            type="submit"
                            value="SUBMIT"
                            className="btn btn-primary btn-block"
                          />
                        </div>
                      </form>

                      <div className="new-account mt-3">
                        <p>
                          Go Back To{" "}
                          <Link className="text-primary" to="/page-login">
                            Sign in
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <h4 className="text-center mb-4">
                        Create New Password Link{" "}
                      </h4>
                      <form onSubmit={(e) => onSubmit(e)}>
                        <div className={`form-group`}>
                          <label>
                            <strong>Email</strong>
                          </label>

                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            value={emailData.email}
                            onChange={handleBlur1}
                            //   onChange={(e) => setPassw(e.target.value)}
                            placeholder="email"
                          />
                          <span className="text-danger">
                            {emailErrors.email}
                          </span>
                        </div>

                        <div className="text-center">
                          <input
                            type="submit"
                            value="Send Link"
                            className="btn btn-primary btn-block"
                          />
                        </div>
                      </form>

                      <div className="new-account mt-3">
                        <p>
                          Go Back To{" "}
                          <Link className="text-primary" to="/page-login">
                            Sign in
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePassword;
