import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;



const getDispositionList = (
  createdby, main_uuid,token
) => {
  return axios
    .post(
      API_URL + "get-disposition-list",
      {
        createdby, main_uuid
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};


export default {
  getDispositionList
};
