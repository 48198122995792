import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Table,
  Button,
  ButtonGroup,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import PageTitle from "../../layouts/PageTitle";
import Pagination1 from "../../layouts/Pagination";
import Loader from "../../layouts/Loader";
import {
  getEmailTemplateList,
  deleteEmailTemplate,
} from "../../actions/emailtemplate";
import CheckModulePermisson from "../../common/CheckModulePermisson";
import PermissionCheck from "../../common/PermissionCheck";
import { notifyInfo, notifyError } from "../Toastr/Toastr";

function EmailTemplate(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;
  const module_name = "email-template";

  let module_per = CheckModulePermisson(module_name);

  let isPermission = true;
  if (module_per === "" || user_type === "admin") {
    isPermission = true;
  } else {
    isPermission = false;
  }

  let listing_per = PermissionCheck(module_name, "listing", "", "", "");
  let add_per = PermissionCheck(module_name, "add", "", "", "");

  const dispatch = useDispatch();
  let navigate = useNavigate();

  if (listing_per === "none") {
    navigate("/error-403");
  }

  if (!isPermission) {
    navigate("/error-403");
  }

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }

  const [smsTemplateEvent, setSmsTemplateEvent] = useState("");

  const [limits, setLimits] = useState(10);
  const [loading, setLoading] = useState(false);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [offSet, setOffSet] = useState(0);

  let search = "";
  let status = "ACTIVE";
  let orderby = "created_at ASC";

  if (module_per === "" || user_type === "admin") {
    useEffect(() => {
      // setLoading(true);
      dispatch(
        getEmailTemplateList(
          main_uuid,
          user_type,
          group_per,
          group_uuid,
          search,
          offSet,
          status,
          limits,
          orderby,
          createdby,
          token
        )
      )
        .then((response) => {
          setLoading(false);
          setEmailTemplates(response.data.data);
          const total = response.data.total;
          setTotalRecords(total);
          setpageCount(Math.ceil(total / limits));
          setSmsTemplateEvent("");
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    }, [smsTemplateEvent, limits]);
  }

  const handlePageClick = async (data) => {
    setOffSet(limits * data.selected);
    setSmsTemplateEvent(Math.random());
  };

  const fnDeleteSmsTemp = (uuid) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this Email Template?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteEmailTempService(uuid),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteEmailTempService = (uuid) => {
    setLoading(true);
    dispatch(deleteEmailTemplate(uuid, createdby, token))
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          notifyInfo(response.message);
          setSmsTemplateEvent(uuid);
          return;
        } else if (response.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const chnageLimit = (value) => {
    setLimits(value);
  };

  const breadCurmList = [
    { label: "Email", link: "/", is_active: "NO" },
    { label: "Email Template", link: "/email-template", is_active: "NO" },
    { label: "Total Amount : " + totalRecords, link: "", is_active: "YES" },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />
      {isPermission ? (
        <>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Manage Email Templates</h4>

                  {add_per === "yes" ? (
                    <Button
                      type="button"
                      className="btn btn-sm btn-default"
                      onClick={() =>
                        navigate("/create-email-template", { replace: true })
                      }
                    >
                      Add New Email Template
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="card-body">
                  Show
                  <ButtonGroup className="ml-2 mr-2">
                    <DropdownButton
                      as={ButtonGroup}
                      id="bg-nested-dropdown"
                      size="sm"
                      title={limits}
                      variant="custom-dropdown"
                    >
                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(10)}
                      >
                        10
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(25)}
                      >
                        25
                      </Dropdown.Item>

                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(50)}
                      >
                        50
                      </Dropdown.Item>

                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(100)}
                      >
                        100
                      </Dropdown.Item>
                    </DropdownButton>
                  </ButtonGroup>
                  entries
                  <Table responsive className="w-100">
                    <thead>
                      <tr role="row">
                        <th>Template Name</th>
                        <th>Subject</th>
                        <th>Type</th>
                        {listing_per === "group" || user_type === "admin" ? (
                          <th>Created By</th>
                        ) : (
                          <></>
                        )}
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {emailTemplates.map((emailTemp) => (
                        <tr key={emailTemp.email_template_uuid}>
                          <td>{emailTemp.template_name}</td>
                          <td>{emailTemp.subject}</td>
                          <td>{emailTemp.message_type}</td>
                          {listing_per === "group" ||
                          listing_per === "all" ||
                          user_type === "admin" ? (
                            <td>{emailTemp.created_by_name}</td>
                          ) : (
                            <></>
                          )}
                          <td>
                            <Fragment>
                              <div className="d-flex">
                                {
                                  <Link
                                    to="/update-email-template"
                                    state={{
                                      email_template_uuid:
                                        emailTemp.email_template_uuid,
                                    }}
                                    hidden={PermissionCheck(
                                      module_name,
                                      "edit",
                                      emailTemp.group_uuid,
                                      emailTemp.user_created_by,
                                      emailTemp.created_by
                                    )}
                                    className="btn btn-primary shadow btn-xs sharp mr-1"
                                  >
                                    <i className="fa fa-pencil"></i>
                                  </Link>
                                }

                                <Button
                                  to="#"
                                  className="btn btn-danger shadow btn-xs sharp"
                                  onClick={() =>
                                    fnDeleteSmsTemp(
                                      emailTemp.email_template_uuid
                                    )
                                  }
                                  hidden={PermissionCheck(
                                    module_name,
                                    "delete",
                                    emailTemp.group_uuid,
                                    emailTemp.user_created_by,
                                    emailTemp.created_by
                                  )}
                                >
                                  <i className="fa fa-trash"></i>
                                </Button>
                              </div>
                            </Fragment>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Pagination1
                    pageCount={pageCount}
                    handlePageNumberClick={handlePageClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Manage SMS Templates</h4>
              </div>
              <div className="card-body">
                <h3>You have don't access this modules</h3>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default EmailTemplate;
