import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../layouts/Loader";
import { forgotPassword } from "../actions/auth";

const ForgotPassword = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { message } = useSelector((state) => state.message);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [emailError, setemailError] = useState(true);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (email.length === 0) {
      setIsEmailValid(false);
      setemailError("Email id is required.");
      setLoading(false);
      return;
    }
    setIsEmailValid(true);

    dispatch(forgotPassword(email))
      .then(() => {
        setLoading(false);
        navigate("/login", { replace: true });
        //window.location.reload();
      })
      .catch(() => {
        setLoading(false);
      });
  };
  return (
    <div className="authincation h-100 p-meddle">
      {loading && <Loader />}
      <div className="container h-100">
        {" "}
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <h4 className="text-center mb-4">Forgot Password</h4>
                    <form onSubmit={(e) => onSubmit(e)}>
                      <div
                        className={`form-group ${
                          !isEmailValid ? "input-warning-o" : ""
                        }`}
                      >
                        <label>
                          <strong>Email</strong>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        {!isEmailValid && (
                          <span className="text-danger">{emailError}</span>
                        )}
                      </div>

                      {message && (
                        <div className="form-group">
                          <div className="alert alert-danger" role="alert">
                            {message}
                          </div>
                        </div>
                      )}

                      <div className="text-center">
                        <input
                          type="submit"
                          value="SUBMIT"
                          className="btn btn-primary btn-block"
                        />
                      </div>
                    </form>

                    <div className="new-account mt-3">
                      <p>
                        Go Back To{" "}
                        <Link className="text-primary" to="/login">
                          Sign in
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
