
import React,{Fragment,useState} from "react";
import { Modal,Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import Loader from "../../layouts/Loader";
import { createEmailSignature } from "../../actions/email/emailSignature";

function CreateEmailSignature(props) {

    const user = JSON.parse(localStorage.getItem("user"));
    const user_data = user.data.data;
    const token = user.data.token;
    const createdby = user_data.user_uuid;
    const main_uuid = user_data.main_uuid;

     const type = props.type;

    const [loading, setLoading] = useState(false);
    const [signatureData,setSignatureData] = useState({
        type : type,
        signature_name : '',
        createdby : createdby,
        main_uuid : main_uuid
    });

    const [errors,setErrors] = useState({
        signature_name : '',
    });

    const dispatch = useDispatch();


    const closeModel = (value) => {
        props.handleCreateSignatureModal(value);
    }  

    const handleBlur = (e) => {

        let newData = {...signatureData};
        let error_data = {...errors};

        if (e.target.value.length === 0) {            
            error_data[e.target.name] = 'Field is required.';
        } else {
            error_data[e.target.name] = '';
        }

        newData[e.target.name] = e.target.value;
        setSignatureData(newData);
        setErrors(error_data);
    }


    const submitHandler = (e) =>{
        e.preventDefault();

        let error_data = {...errors};
        let isError = "false";

        setLoading(true);

        if (signatureData.signature_name.length === 0) {
            error_data["signature_name"] = "Field is required.";
            setErrors(error_data);
            isError = "true";
        }


        if (isError === "true") {
            setLoading(false);
            return;
        } else {

            dispatch(createEmailSignature(signatureData,token))
            .then((response) => {

                if (response.status === 200) {
                    notifyInfo(response.message);
                    closeModel(false);
                    props.handleSignatureEvent(Math.random);
                    
                } else if (response.status === 406) {
                    error_data["signature_name"] = response.message;
                    setErrors(error_data);
                    isError = "true";
                } else if (response.status === 500) {
                    notifyError("Something went wrong! Try again in some time");
                    closeModel(false);
                } else {
                    notifyError("Something went wrong! Try again in some time");
                    closeModel(false);
                }

                setLoading(false);
                
                return;
            })
            .catch(() => {
                setLoading(false);
            });
        }
    }

    return (
        <Fragment>
            {loading && <Loader />}
            <form action="" onSubmit={(e) => submitHandler(e)}>
                <Modal.Header>
                    <Modal.Title>Name new signature</Modal.Title>
                    <Button
                        variant=""
                        className="close"
                        onClick={() => closeModel(false)}
                    >
                        <span>&times;</span>
                    </Button>
                </Modal.Header>
            
                <Modal.Body>
                
                <div className="form-group input-primary">
                    
                    <input type="text" className="form-control bg-transparent" placeholder=" Signature name"  name="signature_name" onChange={handleBlur}/>
                    
                    <span className="text-danger">
                          {errors.signature_name}
                    </span>
                </div>
                                
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        onClick={() => closeModel(false)}
                        variant="danger light btn-sm"
                    >
                        Close
                    </Button>
                    <Button type="submit" variant="primary btn-sm">Create</Button>
                </Modal.Footer>
            </form>
        </Fragment>
    );
}

export default CreateEmailSignature;