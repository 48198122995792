import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const getSequenceList = (
  user_uuid,
  main_uuid,
  search,
  off_set,
  status,
  limits,
  orderby,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "crm/sequence-list",
      {
        user_uuid,
        main_uuid,
        search,
        off_set,
        status,
        limits,
        orderby,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const sequenceStatusChange = (sequence_uuid, status, createdby, token) => {
  return axios
    .post(
      API_URL + "crm/status-update-sequence",
      {
        sequence_uuid,
        status,
        createdby
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const sequenceDelete = (sequence_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "crm/delete-sequence",
      {
        sequence_uuid,
        createdby
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getContactGroups = (main_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "crm/get-contact-groups",
      {
        main_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

export default {
  getSequenceList,
  sequenceStatusChange,
  sequenceDelete,
  getContactGroups
};
