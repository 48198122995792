import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Loader from "../../layouts/Loader";
import EasyEdit, { Types } from "react-easy-edit";
import Tooltip from "rc-tooltip";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import {
  getBlockedNumberSettingsDetails,
  updateBlockedNumberSettings,
} from "../../actions/blockednumbers";
import { getDestinationTypeList } from "../../actions/comman";

function BlockedNumbers() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [settingsDetails, setSettingsDetails] = useState([]);
  const [settingsDetailsEvent, setSettingsDetailsEvent] = useState("");
  const [options, setOptions] = useState([]);
  const [hideDestination, setHideDestination] = useState(true);

  useEffect(() => {
    setLoading(true);
    dispatch(getBlockedNumberSettingsDetails(createdby, createdby, token))
      .then((response) => {
        if (response.status === 200) {
          setSettingsDetails(response.data);
          getoptions(response.data.route);
          setLoading(false);
          return;
        } else if (response.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [settingsDetailsEvent]);

  const handleChange = (e, default_val) => {
    let value = "";
    if (default_val === "YES") {
      value = "NO";
    } else {
      value = "YES";
    }

    let data = { ...settingsDetails };
    data[e.target.name] = value;

    updateData(data);
  };

  const getoptions = (type) => {
    const main_uuid = user_data.main_uuid;
    setLoading(true);
    dispatch(getDestinationTypeList(createdby, token, main_uuid, type))
      .then((response) => {
        setOptions(response.data);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const CustomDisplay = (props) => {
    let new_data = { ...settingsDetails };

    function inputonChange(e) {
      if (e.target.name === "route") {
        if (e.target.value === "terminate_call") {
          setHideDestination(false);
        } else {
          setHideDestination(true);
          getoptions(e.target.value);
        }
        new_data[e.target.name] = e.target.value;
      }

      if (e.target.name === "destination") {
        new_data[e.target.name] = e.target.value;
      }
      setSettingsDetails(new_data);
    }

    return (
      <div>
        <div className="row">
          <div className="col-6">
            <div className="form-group ">
              <label>Route To</label>
              <select
                className="form-control"
                name="route"
                onChange={(e) => inputonChange(e)}
                value={props.route}
              >
                <option value="">Select Destination Type</option>
                <option value="ivr">Auto-Attendant</option>
                <option value="extention">Extension</option>
                <option value="ring-group">Ring Groups</option>
                <option value="did">DID</option>
                <option value="voicemail">Voicemail</option>
                <option value="time_condition">Time-Base Routing</option>
                <option value="terminate_call">Terminate Call</option>
              </select>
            </div>
          </div>

          {hideDestination ? (
            <div className="col-6">
              <div className="form-group">
                <label>Destination</label>
                <select
                  className="form-control"
                  name="destination"
                  onChange={(e) => inputonChange(e)}
                  value={props.destination}
                >
                  <option value="">Select Destination</option>

                  {options &&
                    options.map((number) => (
                      <option key={number.value} value={number.value}>
                        {number.text}
                      </option>
                    ))}

                  {/* {options &&
                  options.map((number) =>
                    number.value != did_uuid ? (
                      <option key={number.value} value={number.value}>
                        {number.text}
                      </option>
                    ) : null
                  )} */}
                </select>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  let route_des_val = "";

  if (settingsDetails.route === "terminate_call") {
    route_des_val = "Terminate Call";
  } else {
    route_des_val =
      settingsDetails.route_val + " - " + settingsDetails.destination_val;
  }

  const save = (value, attribute) => {
    let data = { ...settingsDetails };
    if (attribute === "route_and_destination") {
      data[attribute] = value;
    } else {
      data[attribute] = value;
    }

    updateData(data);
  };

  const updateData = (data) => {
    //call create user API
    data["createdby"] = createdby;
    data["user_uuid"] = createdby;
    data["main_admin_uuid"] = main_uuid;
    setLoading(true);
    dispatch(updateBlockedNumberSettings(data, token))
      .then((response) => {
        if (response.status === 200) {
          notifyInfo(response.message);
          setSettingsDetailsEvent(Math.random());
          // navigate("/inbound-numbers", { replace: true });
        } else if (response.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <div className="row inline_edit ml-2 mr-2">
        <div className="col-lg-6 col-md-6 col-6 mt-4">
          <div className="card card_green_shadow">
            <div className="card-body">
              <div className="form-group customToggle">
                <label className="w-100">
                  Block Anonymous Calls
                  <Tooltip
                    placement="top"
                    overlay={<span>Block Anonymous Calls</span>}
                  >
                    <span className="ti-info-alt ml-1"></span>
                  </Tooltip>
                </label>
                <span className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="anonymous_calls"
                    name="anonymous_calls"
                    checked={
                      settingsDetails.anonymous_calls === "YES" ? "checked" : ""
                    }
                    onChange={(e) =>
                      handleChange(e, settingsDetails.anonymous_calls)
                    }
                  />
                  <label
                    className="custom-control-label check_box_pointer"
                    htmlFor="anonymous_calls"
                  >
                    {" "}
                    {settingsDetails.anonymous_calls === "YES" ? "YES" : "NO"}
                  </label>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-6 mt-4">
          <div className="card card_green_shadow">
            <div className="card-body">
              <div className="form-group">
                <label>
                  Destination for Blacklisted Calls
                  <Tooltip
                    placement="top"
                    overlay={<span>Destination for Blacklisted Calls</span>}
                  >
                    <span className="ti-info-alt  ml-1"></span>
                  </Tooltip>
                </label>
                <EasyEdit
                  type={Types.TEXT}
                  onSave={(value) => {
                    save(value, "route_and_destination");
                  }}
                  editComponent={
                    <CustomDisplay
                      route={settingsDetails.route}
                      destination={settingsDetails.destination}
                      option={settingsDetails.option}
                    />
                  }
                  // displayComponent={
                  //   <CustomDisplay1
                  //     route={settingsDetails.route}
                  //     destination={settingsDetails.destination}
                  //   />
                  // }
                  saveButtonLabel={
                    <i className="fa fa-check" aria-hidden="true"></i>
                  }
                  saveButtonStyle="label-primary easy-edit-button rounded-lg"
                  cancelButtonLabel={
                    <i className="fa fa-times" aria-hidden="true"></i>
                  }
                  cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                  attributes={{
                    name: "route_and_destination",
                    className: "form-control h-75",
                  }}
                  viewAttributes={{ className: "fs-20" }}
                  // onValidate={(value) => {
                  //   return value ? true : false;
                  // }}
                  placeholder="Route and Destination"
                  value={route_des_val}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default BlockedNumbers;
