import React, { Fragment, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import { createDncList } from "../../actions/dnsList";
import Loader from "../../layouts/Loader";

function DncListForm(props) {
  const closeModel = (arg) => {
    props.closeModel(arg);
  };

  const listData = props.dncListUpdate;

  let page_title = "Add Number to DNC List";
  if (listData.action === "update") {
    page_title = "Update DNC List";
  }

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({
    number: "",
    description: "",
  });

  const [dncListData, setDncListData] = useState({
    dnc_list_uuid: listData.dnc_list_uuid,
    number: listData.number,
    action: listData.action,
    account_name: listData.account_name,
    description: listData.description,
    main_admin_uuid: main_uuid,
    token: token,
    createdby: createdby,
  });

  const handleBlur = (e) => {
    const newData = { ...dncListData };
    let error_data = { ...errors };
    if (e.target.name === "number" && e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      if (e.target.name === "number") {
        if (/^\d{9,11}$/.test(e.target.value)) {
          error_data[e.target.name] = "";
          setErrors(error_data);
        } else {
          error_data[e.target.name] =
            "Number is invalid(9 to 11 digits allow).";
          setErrors(error_data);
        }
      }

      newData[e.target.name] = e.target.value;
      setDncListData(newData);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (dncListData.number.length === 0) {
      error_data["number"] = "Number is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      // create bloack number API
      dispatch(createDncList(dncListData))
        .then((res) => {
          closeModel(false);
          props.handleDncEvent(Math.random());

          if (res.status === 200) {
            notifyInfo(res.message);
            navigate("/dnc-list", { replace: true });
          } else if (res.status === 406) {
            error_data["number"] = res.message;
            setErrors(error_data);
            isError = "true";
          } else if (res.status === 500) {
            notifyError("Something went wrong.");
          } else {
            notifyError("Something went wrong.");
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>{page_title} </Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <form action="" onSubmit={(e) => submitHandler(e)}>
        <Modal.Body>
          <div className="row">
            <div className="col-sm">
              <div className="form-group input-primary">
                <label className="mb-1">
                  <strong>Number</strong>
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control bg-white"
                  placeholder="Enter Number"
                  name="number"
                  id="number"
                  value={dncListData.number}
                  onChange={handleBlur}
                />
                <span className="text-danger">{errors.number}</span>
              </div>
            </div>
            <div className="col-sm">
              <div className="form-group input-primary">
                <label className="mb-1">
                  <strong>Account Name</strong>
                </label>
                <input
                  type="text"
                  className="form-control bg-white"
                  placeholder="Enter Account Name"
                  name="account_name"
                  id="account_name"
                  value={dncListData.account_name}
                  onChange={handleBlur}
                />
                <span className="text-danger">{errors.account_name}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <div className="form-group input-primary">
                <label className="mb-1">
                  <strong>Description</strong>
                </label>
                <textarea
                  className="form-control bg-white"
                  name="description"
                  value={dncListData.description}
                  onChange={handleBlur}
                ></textarea>

                <span className="text-danger">{errors.description}</span>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => closeModel(false)}
            variant="danger light btn-sm"
          >
            Close
          </Button>
          <Button type="submit" variant="primary btn-sm">
            {listData.action === "update" ? "Update" : "Add"}
          </Button>
        </Modal.Footer>
      </form>
    </Fragment>
  );
}

export default DncListForm;
