import React, { Fragment, useState, useEffect } from "react";
import { Tab, Nav } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../layouts/PageTitle";
import { notifyError } from "../Toastr/Toastr";
import { updateExtension, getExtensionDetail } from "../../actions/extensions";
import GeneralFields from "./GeneralFields";
import VoicemainFields from "./VoicemainFields";
import Forwarding from "./Forwarding";
import Loader from "../../layouts/Loader";

function UpdateExtensionForm() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;

  const params = useLocation();
  const extuuid = params.state.extension_uuid;
  const activeTab = params.state.activeTab;

  const [extensionEvent, setExtensionEvent] = useState("");

  const [extensionData, setExtensionData] = useState({
    extension_uuid: "",
    extension: "",
    extension_password: "",
    call_waiting_enabled: "",
    call_waiting_timeout: "",
    dnd_enabled: "",
    voicemail_uuid: "",
    voicemail_enabled: "",
    voicemail_password: "",
    voicemail_mail_to_enabled: "",
    voicemail_mail_to: "",
    ring_time_out: "1",
    forward_enable: "",
    forward_route_type: "",
    forward_destination: "",
    forward_destination_value: "",
    initial_extension_ringtime: "TRUE",
    description: "",
    ivr_menu_name: "",
    ring_group_extension: "",
    time_condition_name: "",
    voicemail_name: "",
    did_number: "",
    vm_greeting: "",
    createdby: createdby,
    main_admin_uuid: createdby,
    user_uuid: createdby,
  });

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getExtensionDetail(createdby, token, extuuid))
      .then((response) => {
        const newarr = { ...response.data[0] };
        newarr["createdby"] = createdby;
        setExtensionData(newarr);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [extensionEvent]);

  const changeExtensionEvent = (value) => {
    setExtensionEvent(value);
  };

  const save = (data) => {
    setLoading(true);
    //call update Extension API
    dispatch(updateExtension(data, token))
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          changeExtensionEvent(Math.random);
        } else if (response.status === 500) {
          setLoading(false);
          notifyError("Something went wrong.");
        } else {
          setLoading(false);
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const userChange = (data) => {
    setExtensionData(data);
  };

  const breadCurmList = [
    { label: "Calls", link: "/", is_active: "NO" },
    { label: "Extensions", link: "/extensions", is_active: "NO" },
    { label: extensionData.extension, link: "", is_active: "YES" },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />
      <Tab.Container defaultActiveKey={activeTab}>
        <Nav as="ul" className="nav-tabs extensiontabs mt-4">
          <Nav.Item as="li" key="1">
            <Nav.Link eventKey="general">
              <i className={`la la-tty mr-2`} />
              General
            </Nav.Link>
          </Nav.Item>

          <Nav.Item as="li" key="2">
            <Nav.Link eventKey="voicemail">
              <i className={`la la-voicemail mr-2`} />
              Voicemail
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li" key="3">
            <Nav.Link eventKey="forwarding">
              <i className={`la la-share-square mr-2`} />
              Forwarding
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content className="extensionpage">
          <Tab.Pane eventKey="general" key="1" className="pt-4">
            <GeneralFields
              extensionData={extensionData}
              save={save}
              userChange={userChange}
              changeExtensionEvent={changeExtensionEvent}
            ></GeneralFields>
          </Tab.Pane>
          <Tab.Pane eventKey="voicemail" key="2" className="pt-4">
            <VoicemainFields
              extensionData={extensionData}
              save={save}
              changeExtensionEvent={changeExtensionEvent}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="forwarding" key="3" className="pt-4">
            <Forwarding
              extensionData={extensionData}
              save={save}
              changeExtensionEvent={changeExtensionEvent}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Fragment>
  );
}

export default UpdateExtensionForm;
