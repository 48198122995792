import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const checkEmailConfig = (data, token) => {
  
  return axios
    .post(API_URL + "check-email-config", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getEmailConfiguration = (data, token) => {
  
  return axios
    .post(API_URL + "get-email-config", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const updateEmailConfig = (data, token) => {
  
  return axios
    .post(API_URL + "update-email-config", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      return response.data;
    });
};

export default {
  checkEmailConfig,
  getEmailConfiguration,
  updateEmailConfig,
};
