import React, { Fragment } from "react";
import { Modal, Button } from "react-bootstrap";
import MultiStepNumber from "./MultiStepNumber";

function Numbers(props) {
  const closeModel = (arg) => {
    props.closeModel(arg);
  };

  let type = props.type;

  let title = "Add Business Phone Number";
  if (type === "normal") {
    title = "Add New Inbound Number";
  }

  return (
    <Fragment>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <MultiStepNumber type={props.type} />
      </Modal.Body>
    </Fragment>
  );
}

export default Numbers;
