import React, {Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Table, Modal, Button } from "react-bootstrap";
import {
  getNotesList,
  createNote,
  deleteNote,
  updateNote
} from "../../actions/contactsNotes";
import Loader from "../../layouts/Loader";
import { notifyInfo, notifyError } from "../../components/Toastr/Toastr";
import { confirmAlert } from "react-confirm-alert";
import EasyEdit, { Types } from 'react-easy-edit';

const Notes = ({contact}) => {

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const [showNoteModal, setShowNoteModal] = useState(false);
  const [contactsNotes, setContactsNotes] = useState([]);
  const [NoteText, setNoteText] = useState("");
  const [noteEvent, setNoteEvent] = useState("");

  const [errors, setErrors] = useState({
    note: "",
  });

  const [note, setNote] = useState({
    note: "",
    contact_uuid: contact.contact_uuid,
    createdby: createdby,
    main_admin_uuid: createdby,
    user_uuid: createdby,
  });
  const [loading, setLoading] = useState(false);
  
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getNotesList(contact.contact_uuid, main_uuid, createdby, token))
      .then((response) => {
        setContactsNotes(response.data);
        return;
      })
      .catch(() => {
        //setLoading(false);
      });
  }, [contact,noteEvent]);

  function read_more(noteTxt) {
    setNoteText(noteTxt);
    setShowNoteModal(true);
  }

  const handleBlur = (e) => {
    const newExtensionData = { ...note };
    newExtensionData[e.target.name] = e.target.value;
    setNote(newExtensionData);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (!note.note) {
      error_data["note"] = "Please enter note.";
      setErrors(error_data);
      isError = "true";
    } else {
      error_data["note"] = "";
      setErrors(error_data);
      isError = "false";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //add notes API

      dispatch(createNote(note, token))
        .then((res) => {
          if (res.status === 200) {
            notifyInfo(res.message);
            setNoteEvent(Math.random);

            const newData = { ...note };
            newData["note"] = '';
            setNote(newData);
           
          } else if (res.status === 406) {
            error_data["note"] = res.message;
            setErrors(error_data);
            isError = "true";
          } else if (res.status === 500) {
            notifyError("Something went wrong.");
          } else {
            notifyError("Something went wrong.");
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const deleteNotes = (uuid) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this Note?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteNoteService(uuid),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteNoteService = (uuid) => {
    setLoading(true);
    dispatch(deleteNote(uuid, createdby, token))
      .then((response) => {
        if (response.status === 200) {
          notifyInfo(response.message);
          setNoteEvent(uuid);
        } else if (response.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const CustomDisplay = props => {
    const val = props.value;

   let str = val.length > 50 ? val.slice(0, 50) : val;

    return str;
  };

  const save = (value,note_uuid) => {
    setLoading(true);
    dispatch(updateNote(note_uuid, value, main_uuid, createdby, token))
      .then((response) => {
        setNoteEvent(Math.random);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
    
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <div className="card">
        <div className="card-body">
        
          <Table responsive className="w-100">
            <thead>
              <tr role="row">
                <th>Note</th>
                <th>Date Created</th>
                <th>
                  <form action="" onSubmit={(e) => submitHandler(e)} className="form-inline pull-right">
                      <input
                        type="text"
                        className="form-control bg-white h-75"
                        name="note"
                        placeholder="Add a note..."
                        value={note.note}
                        onChange={handleBlur}
                      />

                      <Button
                        type="submit"
                        className="btn btn-primary btn-sm ml-2"
                      >
                        Add Note
                      </Button>
                      <span className="text-danger">{errors.note}</span>
                  </form>
                </th>
              </tr>
            </thead>

            {contactsNotes && contactsNotes.map((note) => (
                <tbody key={note.note_uuid}>
                  <tr role="row">
                    <td>
                      <EasyEdit
                        type={Types.TEXTAREA}
                        onSave={value => {
                          save(value,note.note_uuid)
                        }}
                        //onCancel={cancel}
                        saveButtonLabel={<i className="fa fa-check" aria-hidden="true"></i>}
                        saveButtonStyle = 'label-primary easy-edit-button rounded-lg'
                        cancelButtonLabel={<i className="fa fa-times" aria-hidden="true"></i>}
                        cancelButtonStyle= 'label-danger easy-edit-button rounded-lg'
                        attributes={{ name: 'note', className : 'form-control h-75'}}
                        viewAttributes={{className : 'w-75 font-w600 text-dark'}}
                        onValidate={(value) => {                          
                          return value ? true : false;
                        }}
                        //validationMessage="What just happened?"
                        displayComponent={<CustomDisplay />}
                        value={note.notes}
                      />
                      {note.notes.length > 50 ? (
                        <a href="#!" 
                        className="user_info_read_more"
                          onClick={() => read_more(note.notes)}
                        >
                          Read More
                        </a>
                      ) : (
                        ""
                      )}
                    </td>

                    <td>
                      {note.created_at}
                    </td>
                    <td>
                      <Link
                        to="#"
                        onClick={() =>
                          deleteNotes(note.note_uuid)
                        }
                        className="btn btn-danger shadow btn-xs sharp"
                      >
                        <i className="fa fa-trash"></i>
                      </Link>
                    </td>
                  </tr>
                </tbody>
            ))}

          </Table>
        </div>
      </div>

      <Modal className="fade" show={showNoteModal}>
        <Modal.Header>
          <Modal.Title>Full Note</Modal.Title>
          <Button
              variant=""
              className="close"
              onClick={() => setShowNoteModal(false)}
          >
              <span>&times;</span>
          </Button>
        </Modal.Header>

        <Modal.Body>{NoteText}</Modal.Body>
        
      </Modal>

    </Fragment>    
  );
};

export default Notes;
