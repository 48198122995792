import React from "react";
import OnboardingList from "./OnboardingList";

const StepOne = (props) => {
  return (
    <section>
      <OnboardingList
        handleOneData={props.handleOneData}
        currentStep={props.currentStep}
        stepData={props.stepData}
      />
    </section>
  );
};

export default StepOne;
