import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const getEmailFolders = (main_uuid,createdby,token) => {
  return axios
    .post(API_URL + "emails/get-folders", {main_uuid,createdby}, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      
      return response.data;
    });
};


const getFolderEmails = (main_uuid,createdby,folder,search,offSet,limits,token) => {
  return axios
    .post(API_URL + "emails/get-folder-emails", {main_uuid,createdby,folder,search,offSet,limits,}, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      
      return response.data;
    });
};

const ChangeMailFlage = (main_uuid,createdby,uid,setStatus,folder_name,token) => {
  return axios
    .post(API_URL + "emails/change-mail-flage", {main_uuid,createdby,uid,folder_name,setStatus}, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      
      return response.data;
    });
};

const CreateFolder = (main_uuid,createdby,folder_name,token) => {
  return axios
    .post(API_URL + "emails/create-folder", {main_uuid,createdby,folder_name}, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      
      return response.data;
    });
};

const UpdateFolder = (main_uuid,createdby,new_folder_name,old_folder_name,token) => {
  return axios
    .post(API_URL + "emails/update-folder", {main_uuid,createdby,new_folder_name,old_folder_name}, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      
      return response.data;
    });
};


const DeleteFolder = (main_uuid,createdby,folder_name,token) => {
  return axios
    .post(API_URL + "emails/delete-folder", {main_uuid,createdby,folder_name}, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      
      return response.data;
    });
};

const DeleteMail = (main_uuid,createdby,folder,uid,token) => {
  return axios
    .post(API_URL + "emails/delete-mail", {main_uuid,createdby,folder,uid}, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      
      return response.data;
    });
};


const MoveEmail = (main_uuid,createdby,source_folder,dest_folder,uid,token) => {
  return axios
    .post(API_URL + "emails/move-email", {main_uuid,createdby,source_folder,dest_folder,uid}, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      
      return response.data;
    });
};

const CheckFolderCount = (main_uuid,createdby,token) => {
  return axios
    .post(API_URL + "emails/check-folder-count", {main_uuid,createdby}, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      
      return response.data;
    });
};

const SendEmail = (req_data,token) => {
  
  return axios
    .post(API_URL + "emails/send-email", req_data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      
      return response.data;
    });
};


export default {
  getEmailFolders,
  getFolderEmails,
  ChangeMailFlage,
  CreateFolder,
  UpdateFolder,
  DeleteFolder,
  DeleteMail,
  MoveEmail,
  CheckFolderCount,
  SendEmail
};
