import React, { Fragment, useState, useEffect } from "react";
import { Button, Table, Tab, Nav, Modal } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {getContactGroupList} from "../../actions/smscampaign";
import { emailTemplateDropdown } from "../../actions/emailtemplate";
import { updateEmailCampaign,getEmailCampaignDetails } from "../../actions/email/emailCampaign";
import PageTitle from "../../layouts/PageTitle";
import Select from "react-select";
import "./style.css";
import TimeSlotForm from "./TimeSlotForm";
import UpdateTimeSlotForm from "./UpdateTimeSlotForm";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import Loader from "../../layouts/Loader";

function UpdateEmailCampaignForm(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const params = useLocation();
  const email_campaign_uuid = params.state.email_campaign_uuid;

  const [updateData, setUpdateData] = useState([]);
  const [updateTimeSlotModal, setUpdateTimeSlotModal] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);

  const [emailCampaignData, setEmailCampaignData] = useState({
    email_campaign_name: "",
    template_uuid: "",
    email_body	: "",
    attachment_file : "",
    is_recurring: "",
    recurring_type: "",
    recurring_at: "",
    start_date: "",
    end_date: "",
    campaign_status: "",
    contact_group_uuid: "",
    operation_time: "",
    token: token,
    main_uuid: main_uuid,
    createdby: createdby,
  });

  const [errors, setErrors] = useState({
    email_campaign_name: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [contactGroupList, setContactGroupList] = useState([]);
  const [emailCampGroupData, setEmailCampGroupData] = useState([]);
  const [rows, setRows] = useState([]);
  const [recuringCheck, setRecuringCheck] = useState("checked");
  const [addTimeSlotModal, setAddTimeSlotModal] = useState(false);

  const [daySel, setDaySel] = useState("monday");
  const [operationUuid, setOperationUuid] = useState("");
  const [file, setFile] = useState();

  const [emailCampOpData, setEmailCampOpData] = useState([
    {
      email_campaign_operation_time_uuid: 1,
      day: "monday",
      start_time: "09:00",
      end_time: "17:00",
    },
    {
      email_campaign_operation_time_uuid: 2,
      day: "tuesday",
      start_time: "09:00",
      end_time: "17:00",
    },
    {
      email_campaign_operation_time_uuid: 3,
      day: "wednesday",
      start_time: "09:00",
      end_time: "17:00",
    },
    {
      email_campaign_operation_time_uuid: 4,
      day: "thursday",
      start_time: "09:00",
      end_time: "17:00",
    },
    {
      email_campaign_operation_time_uuid: 5,
      day: "friday",
      start_time: "09:00",
      end_time: "17:00",
    },
  ]);

  const [mondayData, setMondayData] = useState([
    {
      email_campaign_operation_time_uuid: 1,
      day: "monday",
      start_time: "09:00",
      end_time: "17:00",
    },
  ]);

  const [tuesdayData, setTuesdayData] = useState([
    {
      email_campaign_operation_time_uuid: 2,
      day: "tuesday",
      start_time: "09:00",
      end_time: "17:00",
    },
  ]);

  const [wednesdayData, setWednesdayData] = useState([
    {
      email_campaign_operation_time_uuid: 3,
      day: "wednesday",
      start_time: "09:00",
      end_time: "17:00",
    },
  ]);

  const [thursdayData, setThursdayData] = useState([
    {
      email_campaign_operation_time_uuid: 4,
      day: "thursday",
      start_time: "09:00",
      end_time: "17:00",
    },
  ]);

  const [fridayData, setFridayData] = useState([
    {
      email_campaign_operation_time_uuid: 5,
      day: "friday",
      start_time: "09:00",
      end_time: "17:00",
    },
  ]);

  const [saturdayData, setSaturdayData] = useState([]);
  const [sundayData, setSundayData] = useState([]);

  const addDestinationHandler = (data) => {
    let monData = [];
    let tuesData = [];
    let wenData = [];
    let thursData = [];
    let friData = [];
    let satData = [];
    let sunData = [];

    setEmailCampOpData((emailCampOpData) => [...emailCampOpData, data]);
    const newTimeOpedata = [...emailCampOpData];
    newTimeOpedata.push(data);

    newTimeOpedata.forEach((emp, index) => {
      if (emp.day === "monday") {
        monData.push(emp);
      } else if (emp.day === "tuesday") {
        tuesData.push(emp);
      } else if (emp.day === "wednesday") {
        wenData.push(emp);
      } else if (emp.day === "thursday") {
        thursData.push(emp);
      } else if (emp.day === "friday") {
        friData.push(emp);
      } else if (emp.day === "saturday") {
        satData.push(emp);
      } else if (emp.day === "sunday") {
        sunData.push(emp);
      }
    });

    setMondayData(monData);
    setTuesdayData(tuesData);
    setWednesdayData(wenData);
    setThursdayData(thursData);
    setFridayData(friData);
    setSaturdayData(satData);
    setSundayData(sunData);

    const newCampaignData1 = { ...emailCampaignData };
    newCampaignData1["operation_time"] = newTimeOpedata;
    setEmailCampaignData(newCampaignData1);
  };

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const closeModel = (value) => {
    setAddTimeSlotModal(value);
    setUpdateTimeSlotModal(value);
  };

  const handleBlur = (e) => {
    const newCampaignData = { ...emailCampaignData };
    let error_data = { ...errors };
    if (e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }

    // const MIN_FILE_SIZE = 1024 // 1MB
    const MAX_FILE_SIZE = 2048; // 2MB

    if (e.target.name === "attachment_file") {
      const fileSizeKiloBytes = e.target.files[0].size / 1024;

      if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        
        error_data[e.target.name] = "File size not allow greater then 2MB.";
        setErrors(error_data);
        return;
      }

      setFile(e.target.files[0]);
    }

    newCampaignData[e.target.name] = e.target.value;
    setEmailCampaignData(newCampaignData);
  };

  let select_day = "";
  const handleBlur1 = React.useCallback(
    (e) => {
      select_day = e.currentTarget.id;
      setDaySel(select_day);
    },
    [""]
  );

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (emailCampaignData.email_campaign_name.length === 0) {
      error_data["email_campaign_name"] = "Campaign Name is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (emailCampaignData.contact_group_uuid.length === 0) {
      error_data["contact_group_uuid"] = "Please select atleast one lead list.";
      setErrors(error_data);
      isError = "true";
    }

    if (emailCampaignData.start_date.length === 0) {
      error_data["start_date"] = "Start Date Time is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (emailCampaignData.end_date.length === 0) {
      error_data["end_date"] = "End Date Time is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (emailCampaignData.is_recurring.length === 0 &&
        emailCampaignData.is_recurring === "yes"
    ) {
      error_data["recurring_type"] = "Please select recurring type.";
      setErrors(error_data);
      isError = "true";
    }

    if (emailCampaignData.is_recurring.length === 0 &&
        emailCampaignData.is_recurring === "yes"
    ) {
      error_data["recurring_at"] = "Please select recurring at.";
      setErrors(error_data);
      isError = "true";
    }

    if (emailCampOpData.length === 0) {
      error_data["operation_time"] = "Please atleast add one operation time.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create SMS Campaign API

      let file_name = "";

      const formData = new FormData();
      formData.append("file", file);
      formData.append("email_campaign_uuid", email_campaign_uuid);
      formData.append("camp_file_name", file_name);
      formData.append("email_campaign_name", emailCampaignData.email_campaign_name);
      formData.append("template_uuid", emailCampaignData.template_uuid);
      formData.append("email_body", emailCampaignData.email_body);
      formData.append("is_recurring", emailCampaignData.is_recurring);
      formData.append("start_date", emailCampaignData.start_date);
      formData.append("end_date", emailCampaignData.end_date);
      formData.append("recurring_type", emailCampaignData.recurring_type);
      formData.append("recurring_at", emailCampaignData.recurring_at);
      formData.append("operation_time", JSON.stringify(emailCampOpData));
      formData.append(
        "contact_group_uuid",
        JSON.stringify(emailCampaignData.contact_group_uuid)
      );
      
      formData.append("createdby", createdby);
      formData.append("main_uuid", main_uuid);
      formData.append("user_uuid", createdby);
      
      dispatch(updateEmailCampaign(formData, token))
        .then((res) => {
          if (res.status === 200) {
            
            notifyInfo(res.message);
            navigate("/email-campaign", { replace: true });
          } else if (res.status === 406) {
            error_data["email_campaign_name"] = res.message;
            setErrors(error_data);
            isError = "true";
          } else if (res.status === 500) {
            notifyError("Something went wrong.");
          } else {
            notifyError("Something went wrong.");
          }

          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };


  useEffect(() => {

    let data = {
      email_campaign_uuid : email_campaign_uuid,
      createdby : createdby,
    }

    dispatch(getEmailCampaignDetails(data, token))
      .then((response) => {
        const newCampaignData1 = response.data.campaign_data;
        // newCampaignData1 = response.data.campaign_data;
        // setSmsCampData(newCampaignData1);
        // console.log(response, "===response===");
        //newCampaignData1["camp_data"] = response.data.campaign_data;
        newCampaignData1["contact_group_uuid"] =
          response.data.campaign_contact_group;
        newCampaignData1["operation_time"] =
          response.data.campaign_operation_time;
        setEmailCampaignData(newCampaignData1);
        setEmailCampGroupData(response.data.campaign_contact_group);
        setEmailCampOpData(response.data.campaign_operation_time);
        setRows(response.data.campaign_contact_group);
       
        let op_data = response.data.campaign_operation_time;

        let monData = [];
        let tuesData = [];
        let wenData = [];
        let thursData = [];
        let friData = [];
        let satData = [];
        let sunData = [];
        op_data.forEach((emp, index) => {
          if (emp.day === "monday") {
            monData.push(emp);
          } else if (emp.day === "tuesday") {
            tuesData.push(emp);
          } else if (emp.day === "wednesday") {
            wenData.push(emp);
          } else if (emp.day === "thursday") {
            thursData.push(emp);
          } else if (emp.day === "friday") {
            friData.push(emp);
          } else if (emp.day === "saturday") {
            satData.push(emp);
          } else if (emp.day === "sunday") {
            sunData.push(emp);
          }
        });

        setMondayData(monData);
        setTuesdayData(tuesData);
        setWednesdayData(wenData);
        setThursdayData(thursData);
        setFridayData(friData);
        setSaturdayData(satData);
        setSundayData(sunData);
              
        if (response.data.campaign_data.is_recurring === "yes") {
          setRecuringCheck("checked");
        } else {
          setRecuringCheck("");
        }
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);


  useEffect(() => {
    dispatch(
        emailTemplateDropdown(createdby,main_uuid,token)
    )
      .then((response) => {
        //console.log(response.data);
        setEmailTemplates(response.data);

        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    dispatch(getContactGroupList(main_uuid, createdby, token))
      .then((response) => {
        setContactGroupList(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  

  const UpdateDestinationHandler = (data) => {
    let select_day = data.day;
    let myArray = [];

    let myArray1 = [];
    myArray1 = emailCampOpData;
    const i = myArray1.findIndex((x) => x.email_campaign_operation_time_uuid === operationUuid);
    myArray1[i] = data;
    setEmailCampOpData(myArray1);

    if (select_day === "monday") {
      myArray = mondayData;
      const i = myArray.findIndex((x) => x.email_campaign_operation_time_uuid === operationUuid);
      myArray[i] = data;
      setMondayData(myArray);
    }

    if (select_day === "tuesday") {
      myArray = tuesdayData;
      const i = myArray.findIndex((x) => x.email_campaign_operation_time_uuid === operationUuid);
      myArray[i] = data;
      setTuesdayData(myArray);
    }

    if (select_day === "wednesday") {
      myArray = wednesdayData;
      const i = myArray.findIndex((x) => x.email_campaign_operation_time_uuid === operationUuid);
      myArray[i] = data;
      setWednesdayData(myArray);
    }

    if (select_day === "thursday") {
      myArray = thursdayData;
      const i = myArray.findIndex((x) => x.email_campaign_operation_time_uuid === operationUuid);
      myArray[i] = data;
      setThursdayData(myArray);
    }

    if (select_day === "friday") {
      myArray = fridayData;
      const i = myArray.findIndex((x) => x.email_campaign_operation_time_uuid === operationUuid);
      myArray[i] = data;
      setFridayData(myArray);
    }

    if (select_day === "saturday") {
      myArray = saturdayData;
      const i = myArray.findIndex((x) => x.email_campaign_operation_time_uuid === operationUuid);
      myArray[i] = data;
      setSaturdayData(myArray);
    }

    if (select_day === "sunday") {
      myArray = sundayData;
      const i = myArray.findIndex((x) => x.email_campaign_operation_time_uuid === operationUuid);
      myArray[i] = data;
      setSundayData(myArray);
    }

    const data_camp = { ...emailCampaignData };
    data_camp["operation_time"] = emailCampOpData;
    setEmailCampaignData(data_camp);
  };

  function handleSelectTemplate(e) {

    let template_data =  emailTemplates.filter((emailTemplate) => {
      return emailTemplate.email_template_uuid === e.target.value;        
    }); 

    const data_camp = { ...emailCampaignData };
    data_camp["template_uuid"] = e.target.value;

    if(template_data !== ''){
      data_camp["email_body"] = template_data[0].html_body;
    }
    setEmailCampaignData(data_camp);

  }

  function handleSelectLeadList(data) {
    const newCampaignData1 = { ...emailCampaignData };
    newCampaignData1["contact_group_uuid"] = data;
    setEmailCampaignData(newCampaignData1);
    setRows(data);
  }

  function handleSelectCheck(data) {
    let rec_chk_val = data.target.value;
    console.log(rec_chk_val);
    let check_val = "";
    if (rec_chk_val !== "" && rec_chk_val === "checked") {
      setRecuringCheck("");
      check_val = "no";
    } else {
      setRecuringCheck("checked");
      check_val = "yes";
    }

    const newCampaignData = { ...emailCampaignData };
    newCampaignData["is_recurring"] = check_val;
    setEmailCampaignData(newCampaignData);
  }

  function tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }

  const handleDelete = (index, del_sel_day, e) => {
    setEmailCampOpData([
      ...emailCampOpData.filter((item) => item.email_campaign_operation_time_uuid !== index),
    ]);

    if (del_sel_day === "monday") {
      return setMondayData([
        ...mondayData.filter((item) => item.email_campaign_operation_time_uuid !== index),
      ]);
    } else if (del_sel_day === "tuesday") {
      return setTuesdayData([
        ...tuesdayData.filter((item) => item.email_campaign_operation_time_uuid !== index),
      ]);
    } else if (del_sel_day === "wednesday") {
      return setWednesdayData([
        ...wednesdayData.filter((item) => item.email_campaign_operation_time_uuid !== index),
      ]);
    } else if (del_sel_day === "thursday") {
      return setThursdayData([
        ...thursdayData.filter((item) => item.email_campaign_operation_time_uuid !== index),
      ]);
    } else if (del_sel_day === "friday") {
      return setFridayData([
        ...fridayData.filter((item) => item.email_campaign_operation_time_uuid !== index),
      ]);
    } else if (del_sel_day === "saturday") {
      return setSaturdayData([
        ...saturdayData.filter((item) => item.email_campaign_operation_time_uuid !== index),
      ]);
    } else if (del_sel_day === "sunday") {
      return setSundayData([
        ...sundayData.filter((item) => item.email_campaign_operation_time_uuid !== index),
      ]);
    }
  };

  const handleUpdate = (index, email_campaign_operation_time_uuid, e) => {
    setOperationUuid(email_campaign_operation_time_uuid);
    setUpdateData(index);
    setUpdateTimeSlotModal(index);
  };

 
  const breadCurmList = [
    { label: "Emails", link: "/", is_active: "NO" },
    { label: "Email Campaign", link: "/email-campaign", is_active: "NO" },
    { label: "Update Email Campaign", link: "/", is_active: "YES" },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Update Email Campaign : {emailCampaignData && emailCampaignData.email_campaign_name}</h4>
              <Button
                type="button"
                className="btn btn-default btn-sm"
                onClick={() => navigate("/email-campaign", { replace: true })}
              >
                <i className="fa fa-reply" aria-hidden="true"></i> Back
              </Button>
            </div>
            <div className="card-body">
              <form action="" onSubmit={(e) => submitHandler(e)}>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group input-primary">
                      <label className="mb-1">
                        <strong>Campaign Name</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control bg-white"
                        placeholder="Campaign Name"
                        name="email_campaign_name"
                        value={emailCampaignData.email_campaign_name}
                        onChange={handleBlur}
                      />
                      <span className="text-danger">{errors.email_campaign_name}</span>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="form-group input-primary">
                      <label className="mb-1">
                        <strong>Email Attachment</strong> {emailCampaignData.attachment_file}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="file"
                        className="form-control bg-white"
                        name="attachment_file"
                        //value={emailCampaignData.attachment_file}
                        onChange={handleBlur}
                      />
                      {/* <span className="text-danger">{errors.attachment_file}</span> */}
                    </div>
                  </div>
                  
                </div>

                <div className="row">                 
                  
                  <div className="col-6">
                    <div className="form-group mt-2 input-primary">
                      <label className="mb-1">
                        <strong> Email Template</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control bg-white"
                        name="template_uuid"
                        onChange={handleSelectTemplate}
                        value={emailCampaignData.template_uuid}
                      >
                        <option value="">Select Email Template</option>

                        {emailTemplates && emailTemplates.map((emailTemplate) => (
                            <option key={emailTemplate.email_template_uuid} value={emailTemplate.email_template_uuid}>{emailTemplate.template_name}</option>
                        ))}
                        
                      </select>
                      <span className="text-danger">
                        {errors.template_uuid}
                      </span>
                    </div>
                  </div>
                  
                  <div className="col-6">
                    <div className="form-group input-primary">
                      <label className="mb-1">
                        <strong>Email Body</strong>
                        <span className="text-danger">*</span>
                      </label>
                          <br></br>
                        <div>
                          <button type="button" className="btn btn-sm btn-primary" onClick={(e) => setPreviewModal(true)}>Preview</button>
                        </div>
                      
                      {/* <textarea
                        className="form-control bg-white"
                        placeholder="Message"
                        name="email_body"
                        ref={textboxRef}
                        onChange={handleBlur}
                        // onChange={e => setMessageVal(e.target.value)}
                      ></textarea> */}
                      <span className="text-danger">{errors.email_body}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group mt-2 input-primary">
                      <label className="mb-1">
                        <strong> Add Lead List</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <Select
                        className="form-control bg-white"
                        options={contactGroupList}
                        name="contact_group_uuid"
                        placeholder="Select List"
                        value={emailCampGroupData}
                        onChange={handleSelectLeadList}
                        isSearchable={true}
                        isMulti
                      />

                      <span className="text-danger">
                        {errors.contact_group_uuid}
                      </span>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="card-body">
                      <label className="mb-1">
                        <strong> Lead List</strong>
                      </label>
                      <Table responsive className="w-100">
                        <thead>
                          <tr role="row">
                            <td>Name</td>
                            <td>No. of contacts</td>
                          </tr>
                        </thead>
                        <tbody>
                          {rows.map((row, index) => (
                            <tr key={index}>
                              <td>{row.label}</td>
                              <td>{row.count}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    <div className="form-group col-xl-12 p-0 input-primary">
                      <label className="mb-1">
                        <strong>Start Date</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        name="start_date"
                        onChange={handleBlur}
                        value={emailCampaignData.start_date}
                        className="form-control bg-white"
                      />
                      <span className="text-danger">{errors.start_date}</span>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="form-group col-xl-12 p-0 input-primary">
                      <label className="mb-1">
                        <strong>End Date</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        name="end_date"
                        onChange={handleBlur}
                        value={emailCampaignData.end_date}
                        className="form-control bg-white"
                      />
                      <span className="text-danger">{errors.end_date}</span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-3">
                    <div className="form-group input-primary">
                      <label className="w-100 mb-1">
                        <strong>Recurring</strong>
                      </label>
                      <label className="switch">
                        <input
                          type="checkbox"
                          name="is_recurring"
                          onChange={handleSelectCheck}
                          value={recuringCheck}
                        />
                        <span className="slider round"> </span>
                      </label>
                    </div>
                  </div>
                  <div
                    className="col-xl-3"
                    style={{
                      display: emailCampaignData.is_recurring === "yes"
                          ? "block"
                          : "none",
                    }}
                  >
                    <div className="form-group input-primary">
                      <label className="w-100 mb-1">
                        <strong></strong>
                      </label>
                      <input
                        type="text"
                        className="form-control bg-white"
                        readOnly
                        value="Every "
                      />
                    </div>
                  </div>

                  <div
                    className="col-xl-3"
                    style={{
                      display: emailCampaignData.is_recurring === "yes"
                          ? "block"
                          : "none",
                    }}
                  >
                    <div className="form-group input-primary">
                      <label className="mb-1">
                        <strong>Recurring At</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control bg-white"
                        name="recurring_at"
                        onChange={handleBlur}
                        value={emailCampaignData.recurring_at}
                      >
                        <option value="">Select Recurring At</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                      <span className="text-danger">{errors.recurring_at}</span>
                    </div>
                  </div>
                  <div
                    className="col-xl-3"
                    style={{
                      display: emailCampaignData.is_recurring === "yes"
                          ? "block"
                          : "none",
                    }}
                  >
                    <div className="form-group input-primary">
                      <label className="mb-1">
                        <strong>Recurring Type</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control bg-white"
                        name="recurring_type"
                        onChange={handleBlur}
                        value={emailCampaignData.recurring_type}
                      >
                        <option value="">Select Recurring Type</option>
                        <option value="day">Day</option>
                        <option value="week">Week</option>
                        <option value="month">Month</option>
                        <option value="year">Year</option>
                      </select>
                      <span className="text-danger">
                        {errors.recurring_type}
                      </span>
                    </div>
                  </div>
                </div>
                

                <div className="row">
                  <div className="card-body">
                    <label className="mb-1">
                      <strong> Campaign Operation Time</strong>
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="default-tab m-3 w-100">
                    <Tab.Container defaultActiveKey="monday">
                      <Nav as="ul" className="nav-tabs">
                        <Nav.Item as="li">
                          <Nav.Link
                            id="sunday"
                            eventKey="sunday"
                            onClick={handleBlur1}
                          >
                            <i className={`la la-clock mr-2`} />
                            Sun
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                          <Nav.Link
                            id="monday"
                            eventKey="monday"
                            onClick={handleBlur1}
                          >
                            <i className={`la la-clock mr-2`} />
                            Mon
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                          <Nav.Link
                            id="tuesday"
                            eventKey="tuesday"
                            onClick={handleBlur1}
                          >
                            <i className={`la la-clock mr-2`} />
                            Tue
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                          <Nav.Link
                            id="wednesday"
                            eventKey="wednesday"
                            onClick={handleBlur1}
                          >
                            <i className={`la la-clock mr-2`} />
                            Wed
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                          <Nav.Link
                            id="thursday"
                            eventKey="thursday"
                            onClick={handleBlur1}
                          >
                            <i className={`la la-clock mr-2`} />
                            Thurs
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                          <Nav.Link
                            id="friday"
                            eventKey="friday"
                            onClick={handleBlur1}
                          >
                            <i className={`la la-clock mr-2`} />
                            Fri
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                          <Nav.Link
                            id="saturday"
                            eventKey="saturday"
                            onClick={handleBlur1}
                          >
                            <i className={`la la-clock mr-2`} />
                            sat
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>

                      <Tab.Content className="pt-12">
                        <Tab.Pane eventKey="sunday">
                          <div className="row mt-4">
                            <div className="col-12">
                              <h4 className="card-title">Sunday</h4>

                              <Table responsive className="w-100">
                                <thead>
                                  {sundayData.map((name) => (
                                    <tr role="row" key={name.email_campaign_operation_time_uuid}>
                                      <td>
                                        {tConvert(name.start_time)} To{" "}
                                        {tConvert(name.end_time)}
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-danger light pull-right mr-2"
                                          onClick={(e) =>
                                            handleDelete(
                                              name.email_campaign_operation_time_uuid,
                                              name.day,
                                              e
                                            )
                                          }
                                        >
                                          Delete
                                        </button>
                                        <button
                                          type="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-info light pull-right mr-2"
                                          onClick={(e) =>
                                            handleUpdate(
                                              name,
                                              name.email_campaign_operation_time_uuid,
                                              e
                                            )
                                          }
                                        >
                                          Edit
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </thead>
                               
                              </Table>

                              <button
                                type="button"
                                style={{ cursor: "pointer" }}
                                className="badge badge-primary light mr-2"
                                onClick={() => setAddTimeSlotModal(true)}
                              >
                                <i
                                  className="fa fa-plus-circle mr-2"
                                  aria-hidden="true"
                                />
                                Add New Time
                              </button>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="monday">
                          <div className="row mt-4">
                            <div className="col-12">
                              <h4 className="card-title">Monday</h4>

                              <Table responsive className="w-100">
                                <thead>
                                  {mondayData.map((name) => (
                                    <tr role="row" key={name.email_campaign_operation_time_uuid}>
                                      <td>
                                        {tConvert(name.start_time)} To{" "}
                                        {tConvert(name.end_time)}
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-danger light pull-right mr-2"
                                          onClick={(e) =>
                                            handleDelete(
                                              name.email_campaign_operation_time_uuid,
                                              name.day,
                                              e
                                            )
                                          }
                                        >
                                          Delete
                                        </button>
                                        <button
                                          type="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-info light pull-right mr-2"
                                          onClick={(e) =>
                                            handleUpdate(
                                              name,
                                              name.email_campaign_operation_time_uuid,
                                              e
                                            )
                                          }
                                        >
                                          Edit
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </thead>
                               
                              </Table>

                              <div
                                className="col-sm-6 pull-left"
                                title="Add New Time"
                              >
                                <button
                                  type="button"
                                  style={{ cursor: "pointer" }}
                                  className="badge badge-primary light mr-2"
                                  onClick={() => setAddTimeSlotModal(true)}
                                >
                                  <i
                                    className="fa fa-plus-circle mr-2"
                                    aria-hidden="true"
                                  />
                                  Add New Time
                                </button>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="tuesday">
                          <div className="row mt-4">
                            <div className="col-12">
                              <h4 className="card-title">Tuesday</h4>

                              <Table responsive className="w-100">
                                <thead>
                                  {tuesdayData.map((name) => (
                                    <tr role="row" key={name.email_campaign_operation_time_uuid}>
                                      <td>
                                        {tConvert(name.start_time)} To{" "}
                                        {tConvert(name.end_time)}
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-danger light pull-right mr-2"
                                          onClick={(e) =>
                                            handleDelete(
                                              name.email_campaign_operation_time_uuid,
                                              name.day,
                                              e
                                            )
                                          }
                                        >
                                          Delete
                                        </button>
                                        <button
                                          type="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-info light pull-right mr-2"
                                          onClick={(e) =>
                                            handleUpdate(
                                              name,
                                              name.email_campaign_operation_time_uuid,
                                              e
                                            )
                                          }
                                        >
                                          Edit
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </thead>
                                
                              </Table>

                              <div
                                className="col-sm-6 pull-left"
                                title="Add New Time"
                              >
                                <button
                                  type="button"
                                  style={{ cursor: "pointer" }}
                                  className="badge badge-primary light mr-2"
                                  onClick={() => setAddTimeSlotModal(true)}
                                >
                                  <i
                                    className="fa fa-plus-circle mr-2"
                                    aria-hidden="true"
                                  />
                                  Add New Time
                                </button>
                              </div>

                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="wednesday">
                          <div className="row mt-4">
                            <div className="col-12">
                              <h4 className="card-title">Wednesday</h4>

                              <Table responsive className="w-100">
                                <thead>
                                  {wednesdayData.map((name) => (
                                    <tr role="row" key={name.email_campaign_operation_time_uuid}>
                                      <td>
                                        {tConvert(name.start_time)} To{" "}
                                        {tConvert(name.end_time)}
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-danger light pull-right mr-2"
                                          onClick={(e) =>
                                            handleDelete(
                                              name.email_campaign_operation_time_uuid,
                                              name.day,
                                              e
                                            )
                                          }
                                        >
                                          Delete
                                        </button>
                                        <button
                                          type="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-info light pull-right mr-2"
                                          onClick={(e) =>
                                            handleUpdate(
                                              name,
                                              name.email_campaign_operation_time_uuid,
                                              e
                                            )
                                          }
                                        >
                                          Edit
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </thead>
                                
                              </Table>

                              <div
                                className="col-sm-6 pull-left"
                                title="Add New Time"
                              >
                                <button
                                  type="button"
                                  style={{ cursor: "pointer" }}
                                  className="badge badge-primary light mr-2"
                                  onClick={() => setAddTimeSlotModal(true)}
                                >
                                  <i
                                    className="fa fa-plus-circle mr-2"
                                    aria-hidden="true"
                                  />
                                  Add New Time
                                </button>
                              </div>

                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="thursday">
                          <div className="row mt-4">
                            <div className="col-12">
                              <h4 className="card-title">Thursday</h4>

                              <Table responsive className="w-100">
                                <thead>
                                  {thursdayData.map((name) => (
                                    <tr role="row" key={name.email_campaign_operation_time_uuid}>
                                      <td>
                                        {tConvert(name.start_time)} To{" "}
                                        {tConvert(name.end_time)}
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-danger light pull-right mr-2"
                                          onClick={(e) =>
                                            handleDelete(
                                              name.email_campaign_operation_time_uuid,
                                              name.day,
                                              e
                                            )
                                          }
                                        >
                                          Delete
                                        </button>
                                        <button
                                          type="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-info light pull-right mr-2"
                                          onClick={(e) =>
                                            handleUpdate(
                                              name,
                                              name.email_campaign_operation_time_uuid,
                                              e
                                            )
                                          }
                                        >
                                          Edit
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </thead>
                                
                              </Table>

                              <div
                                className="col-sm-6 pull-left"
                                title="Add New Time"
                              >
                                <button
                                  type="button"
                                  style={{ cursor: "pointer" }}
                                  className="badge badge-primary light mr-2"
                                  onClick={() => setAddTimeSlotModal(true)}
                                >
                                  <i
                                    className="fa fa-plus-circle mr-2"
                                    aria-hidden="true"
                                  />
                                  Add New Time
                                </button>
                              </div>

                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="friday">
                          <div className="row mt-4">
                            <div className="col-12">
                              <h4 className="card-title">Friday</h4>

                              <Table responsive className="w-100">
                                <thead>
                                  {fridayData.map((name) => (
                                    <tr role="row" key={name.email_campaign_operation_time_uuid}>
                                      <td>
                                        {tConvert(name.start_time)} To{" "}
                                        {tConvert(name.end_time)}
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-danger light pull-right mr-2"
                                          onClick={(e) =>
                                            handleDelete(
                                              name.email_campaign_operation_time_uuid,
                                              name.day,
                                              e
                                            )
                                          }
                                        >
                                          Delete
                                        </button>
                                        <button
                                          type="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-info light pull-right mr-2"
                                          onClick={(e) =>
                                            handleUpdate(
                                              name,
                                              name.email_campaign_operation_time_uuid,
                                              e
                                            )
                                          }
                                        >
                                          Edit
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </thead>
                              </Table>

                              <div
                                className="col-sm-6 pull-left"
                                title="Add New Time"
                              >
                                <button
                                  type="button"
                                  style={{ cursor: "pointer" }}
                                  className="badge badge-primary light mr-2"
                                  onClick={() => setAddTimeSlotModal(true)}
                                >
                                  <i
                                    className="fa fa-plus-circle mr-2"
                                    aria-hidden="true"
                                  />
                                  Add New Time
                                </button>
                              </div>

                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="saturday">
                          <div className="row mt-4">
                            <div className="col-12">
                              <h4 className="card-title">Saturday</h4>

                              <Table responsive className="w-100">
                                <thead>
                                  {saturdayData.map((name) => (
                                    <tr role="row" key={name.email_campaign_operation_time_uuid}>
                                      <td>
                                        {tConvert(name.start_time)} To{" "}
                                        {tConvert(name.end_time)}
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-danger light pull-right mr-2"
                                          onClick={(e) =>
                                            handleDelete(
                                              name.email_campaign_operation_time_uuid,
                                              name.day,
                                              e
                                            )
                                          }
                                        >
                                          Delete
                                        </button>
                                        <button
                                          type="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-info light pull-right mr-2"
                                          onClick={(e) =>
                                            handleUpdate(
                                              name,
                                              name.email_campaign_operation_time_uuid,
                                              e
                                            )
                                          }
                                        >
                                          Edit
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </thead>
                              </Table>

                              <div
                                className="col-sm-6 pull-left"
                                title="Add New Time"
                              >
                                <button
                                  type="button"
                                  style={{ cursor: "pointer" }}
                                  className="badge badge-primary light mr-2"
                                  onClick={() => setAddTimeSlotModal(true)}
                                >
                                  <i
                                    className="fa fa-plus-circle mr-2"
                                    aria-hidden="true"
                                  />
                                  Add New Time
                                </button>
                              </div>

                            </div>
                          </div>
                        </Tab.Pane>

                        <span className="text-danger">
                          {errors.operation_time}
                        </span>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 text-right">
                    <Button type="submit" variant="primary" className="btn-sm">
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal className="fade" show={addTimeSlotModal}>
        <TimeSlotForm
          closeModel={closeModel}
          day={daySel}
          onAddDestination={addDestinationHandler}
        />
      </Modal>
      <Modal className="fade" show={updateTimeSlotModal}>
        <UpdateTimeSlotForm
          closeModel={closeModel}
          day={daySel}
          updateData={updateData}
          onUpdateDestination={UpdateDestinationHandler}
        />
      </Modal>

      <Modal className="fade" show={previewModal}>
        <Modal.Header>
          <Modal.Title>Preview Email</Modal.Title>
          <Button variant="" className="close" onClick={() => setPreviewModal(false)}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <div className="content" dangerouslySetInnerHTML={{__html: emailCampaignData.email_body}}></div>
        {/* {emailCampaignData.email_body} */}
      </Modal>

    </Fragment>
  );
}

export default UpdateEmailCampaignForm;
