import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import Pagination from "../../layouts/Pagination";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./style.css";
import Tooltip from "rc-tooltip";

function Moh(props) {

  const mohs = props.recordings;
  const pageCount = props.pageCount;

  const Files = (props) => {
    let moh_files = props.moh_files;

    if (moh_files.length > 0) {
      return (
        <div>
          {moh_files.map((file, index) => {
            return <div key={index}>{file.recording_filename}</div>;
          })}
        </div>
      );
    } else {
      return "None";
    }
  };

  return (
    <Fragment>
      <div className="row mt-4">
        <div className="col-12">
          <div className="card-header">
            <h4 className="card-title">MoH</h4>
          </div>
          <Table responsive className="w-100">
            <thead>
              <tr role="row">
                <th>Recording Name</th>
                <th>File Play</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {mohs &&
                mohs.map((recording) => (
                  <tr key={recording.recording_uuid}>
                    <td>
                      <Link
                        to="/update-moh-form"
                        state={{
                          recording: recording,
                        }}
                        className="text-blue"
                      >
                        {recording.recording_name}
                      </Link>
                    </td>
                    <td>
                      <Tooltip
                        placement="top"
                        overlay={<Files moh_files={recording.moh_file} />}
                      >
                        <span className="text-blue font-weight-bold">
                          {recording.moh_file && recording.moh_file.length}
                        </span>
                      </Tooltip>
                    </td>
                    <td>
                      <Fragment>
                        <div className="d-flex">
                          <Link
                            to="/update-moh-form"
                            state={{
                              recording: recording,
                            }}
                            className="btn btn-primary shadow btn-xs sharp mr-1"
                          >
                            <i className="fa fa-pencil"></i>
                          </Link>

                          <Link
                            to="#"
                            onClick={() =>
                              props.deleteRecordingE(recording.recording_uuid)
                            }
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </Fragment>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>

          <Pagination
            pageCount={pageCount}
            handlePageNumberClick={props.handlePageClick}
          />
        </div>
      </div>
    </Fragment>
  );
}

export default Moh;
