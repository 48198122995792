import React from "react";
import DependentFieldForm from "./DependentFieldForm";

function ContactCustomFieldForm(props) {

        let field = props.field;
        
        var options = field.field_options.split(',');
        let value = '';

        if (props.contactFiledData[field.custom_field_uuid]) {
            value = props.contactFiledData[field.custom_field_uuid];
        }

        let dependent_field_data = props.dependent_field_data
        
        
        return (

            <div className="form-group input-primary col-md-6" >
                <label className="mb-1">
                <strong>{field.field_name}</strong>
                </label>

                {field.field_type === 'TEXT' && 
                 <input type="text" value={value} className="form-control bg-white" name={field.custom_field_uuid} onChange={(e) => props.handlecustomFieldData(e.target.name,e.target.value)} />
                }

                {field.field_type === 'CHECKBOX' && 

                    <span>
                        {options.map((option) => {
                            return ( <div className="form-check mb-2" key={option}>
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={value.includes(option)}
                                        value={option}
                                        name={field.custom_field_uuid}
                                        onChange={(e) => props.handlecustomFieldData(e.target.name,e.target.value)}
                                    />
                                    <label className="form-check-label">{option}</label>
                                </div>
                            );
                        })}
                    </span>
                }

                {field.field_type === 'DATE' && 
                    <input type="date" value={value} className="form-control bg-white" name={field.custom_field_uuid} onChange={(e) => props.handlecustomFieldData(e.target.name,e.target.value)} />
                }

                {field.field_type === 'DROPDOWN' && 
                    <span>
                        <select className="form-control bg-white" value={value} name={field.custom_field_uuid} onChange={(e) => props.handlecustomFieldData(e.target.name,e.target.value)}> ;
                            <option value=''>Select</option>;
        
                            {options.map((option) => {
                                return (<option key={option} value={option}>{option}</option>);
                            })}
                        </select>

                        {(field.is_dependent_available === 'YES' ) && 
                            <>
                            {field.dependent_fields.map((dependent_field) => {
                                return (<div key={dependent_field.contact_custom_dependent_field_uuid}>
                                    {value === dependent_field.answer_on_show && 
                                        <DependentFieldForm  dependent_field={dependent_field} handleDependentFieldData={props.handleDependentFieldData} dependent_field_data={dependent_field_data} />
                                    }
                                    
                                    {/* <label className="mb-1">
                                        <strong>{field.dependent_field_name}</strong>
                                    </label>
                                    <input type="text" className="form-control bg-white" name={field.contact_custom_dependent_field_uuid} 
                                    onChange={(e) => props.handlecustomFieldData(e.target.name,e.target.value,field.is_dependent_available)}
                                    />  */}
                                </div>)
                            })}
                            </>
                        }
                    </span>

                }

                {field.field_type === 'TEXTAREA' && 
                    <textarea className="form-control bg-white" value={value} name={field.custom_field_uuid} onChange={(e) => props.handlecustomFieldData(e.target.name,e.target.value)}></textarea>
                }
    
                {field.field_type === 'RADIO' &&
                    <div className="form-group mb-0">
                        {options.map((option) => {
                           
                            return (
                                <label className="radio-inline mr-3" key={option}>
                                    <input type="radio" name={field.custom_field_uuid} value={option} checked={value.includes(option)} 
                                        onChange={(e) => props.handlecustomFieldData(e.target.name,e.target.value,field.is_dependent_available)}
                                    /> {option}
                                </label>
                            );
                        })}

                    {(field.is_dependent_available === 'YES' ) && 
                        <>
                        {field.dependent_fields.map((dependent_field) => {
                            return (<div key={dependent_field.contact_custom_dependent_field_uuid}>
                                {value === dependent_field.answer_on_show && 
                                    <DependentFieldForm  dependent_field={dependent_field} handleDependentFieldData={props.handleDependentFieldData} dependent_field_data={dependent_field_data} />
                                }
                                
                                {/* <label className="mb-1">
                                    <strong>{field.dependent_field_name}</strong>
                                </label>
                                <input type="text" className="form-control bg-white" name={field.contact_custom_dependent_field_uuid} 
                                onChange={(e) => props.handlecustomFieldData(e.target.name,e.target.value,field.is_dependent_available)}
                                />  */}
                            </div>)
                        })}
                        </>
                    }

                    </div>
                    
                }
                
            </div>
        );
   
}

export default ContactCustomFieldForm;