import React from "react";
import PlanList from "./PlanList";

const StepOne = (props) => {
  return (
    <section>
      <PlanList
        planData={props.planData}
        planId={props.planId}
        formData={props.formData}
        errors={props.errors}
        handleOneData={props.handleOneData}
      />
    </section>
  );
};

export default StepOne;
