import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Table,
  Button,
  ButtonGroup,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import PageTitle from "../../layouts/PageTitle";
import { getGroupList, deleteGroup } from "../../actions/group";
import CheckModulePermisson from "../../common/CheckModulePermisson";
import PermissionCheck from "../../common/PermissionCheck";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import Pagination from "../../layouts/Pagination";
import Loader from "../../layouts/Loader";

function Groups(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;
  const module_name = "groups";

  let module_per = CheckModulePermisson(module_name);

  let isPermission = true;
  if (module_per === "" || user_type === "admin") {
    isPermission = true;
  } else {
    isPermission = false;
  }

  let listing_per = PermissionCheck(module_name, "listing", "", "", "");
  let add_per = PermissionCheck(module_name, "add", "", "", "");

  const dispatch = useDispatch();
  let navigate = useNavigate();

  if (listing_per === "none") {
    navigate("/error-403");
  }

  if (!isPermission) {
    navigate("/error-403");
  }

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }

  const [groupEvent, setGroupEvent] = useState("");


  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [groupTotal, setGroupTotal] = useState([]);
  const [limits, setLimits] = useState(10);
  const [pageCount, setpageCount] = useState(0);
  const [offSet, setOffSet] = useState(0);

  let search = "";
  let status = "ACTIVE";
  let orderby = "created_at ASC";

  if (module_per === "" || user_type === "admin") {
    useEffect(() => {
      dispatch(
        getGroupList(
          main_uuid,
          user_type,
          group_per,
          group_uuid,
          search,
          offSet,
          status,
          limits,
          orderby,
          createdby,
          token
        )
      )
        .then((response) => {
          setGroups(response.data.data);
          setGroupTotal(response.data.total);
          let total = response.data.total
          setpageCount(Math.ceil(total / limits));
          setGroupEvent("");
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    }, [groupEvent]);
  }

  const fnDeleteGroup = (uuid) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this group?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteGroupService(uuid),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteGroupService = (uuid) => {
    setLoading(true);
    dispatch(deleteGroup(uuid, createdby, token))
      .then((response) => {
        if (response.status === 200) {
          notifyInfo(response.message);
          setGroupEvent(uuid);
          setLoading(false);
          return;
        } else if (response.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handlePageClick = async (data) => {
    setOffSet(limits * data.selected);
    setGroupEvent(Math.random());
  };

  const chnageLimit = (value) => {
    setLimits(value);
  };

  const breadCurmList = [
    { label: "Roles & Permissions", link: "/roles", is_active: "NO" },
    { label: "Groups", link: "/groups", is_active: "YES" },
    { label: "Total Amount : " + groupTotal, link: "", is_active: "YES" },
  ];

  let str = " : ";
  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />
      {isPermission ? (
        <>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Manage Groups</h4>

                  {add_per === "yes" ? (
                    <Button
                      type="button"
                      className="btn btn-sm btn-default"
                      onClick={() => navigate("/add-group", { replace: true })}
                    >
                      Add New Group
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="card-body">
                  Show
                  <ButtonGroup className="ml-2 mr-2">
                    <DropdownButton
                      as={ButtonGroup}
                      id="bg-nested-dropdown"
                      size="sm"
                      title={limits}
                      variant="custom-dropdown"
                    >
                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(10)}
                      >
                        10
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(25)}
                      >
                        25
                      </Dropdown.Item>

                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(50)}
                      >
                        50
                      </Dropdown.Item>

                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(100)}
                      >
                        100
                      </Dropdown.Item>
                    </DropdownButton>
                  </ButtonGroup>
                  entries
                  <Table responsive className="w-100">
                    <div id="example_wrapper" className="dataTables_wrapper">
                      <table id="example" className="display w-100 dataTable">
                        <thead>
                          <tr role="row">
                            <th>Name</th>
                            <th>Group Owner</th>
                            {listing_per === "group" ||
                            user_type === "admin" ? (
                              <th>Created By</th>
                            ) : (
                              <></>
                            )}
                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {groups.map((group) => (
                            <tr key={group.uuid}>
                              <td>
                                <Link
                                  to="/groups-assign-user"
                                  state={{
                                    group_uuid: group.uuid,
                                    group_name: group.name,
                                  }}
                                  className="text-success"
                                >
                                  {group.name}
                                </Link>
                              </td>
                              <td>
                                {group.ownwer_name}{" "}
                                {group.extension !== null
                                  ? str + group.extension
                                  : ""}
                              </td>
                              {listing_per === "group" ||
                              listing_per === "all" ||
                              user_type === "admin" ? (
                                <td>{group.created_by_name}</td>
                              ) : (
                                <></>
                              )}
                              <td>
                                <Fragment>
                                  <div className="d-flex">
                                    {
                                      <Link
                                        to="/update-group"
                                        state={{
                                          group_uuid: group.uuid,
                                        }}
                                        hidden={PermissionCheck(
                                          module_name,
                                          "edit",
                                          group.group_uuid,
                                          group.user_created_by,
                                          group.created_by
                                        )}
                                        className="btn btn-primary shadow btn-xs sharp mr-1"
                                      >
                                        <i className="fa fa-pencil"></i>
                                      </Link>
                                    }

                                    <Button
                                      to="#"
                                      className="btn btn-danger shadow btn-xs sharp"
                                      onClick={() => fnDeleteGroup(group.uuid)}
                                      hidden={PermissionCheck(
                                        module_name,
                                        "delete",
                                        group.group_uuid,
                                        group.user_created_by,
                                        group.created_by
                                      )}
                                    >
                                      <i className="fa fa-trash"></i>
                                    </Button>
                                  </div>
                                </Fragment>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Table>
                  <Pagination
                    pageCount={pageCount}
                    handlePageNumberClick={handlePageClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Manage Roles</h4>
              </div>
              <div className="card-body">
                <h3>You have don't access this modules</h3>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Groups;
