
import React, { Fragment,useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUserMappingFields } from "../../actions/user";
import Loader from "../../layouts/Loader";

import "./style.css";

function StepTwo(props) {

    const user = JSON.parse(localStorage.getItem("user"));
    const user_data = user.data.data;
    const token = user.data.token;
    const createdby = user_data.user_uuid;
    
    const dispatch = useDispatch();

    let filename = props.fileName;
    let roles = props.roles;
    let errors = props.errors;
    let fieldsName = props.fieldsName;
    
    const [headerName, setHeaderName] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        dispatch(getUserMappingFields(createdby, token, filename))
          .then((response) => {
            setHeaderName(response.data.csvheader);
            setLoading(false);
            return;
          })
          .catch(() => {
            setLoading(false);
          });
    }, []);

    
    return (    
        <Fragment>
            {loading && <Loader />}
            <div className="row pl-5 pr-5">
                <div className="col-sm">
                    <div className="form-group">
                        <label className="mb-1">
                            <strong>First Name</strong>
                        </label>
                    </div>
                </div>
                <div className="col-sm">
                    <div className="form-group input-primary">
                        <select
                            className="form-control bg-white"
                            name={"first_name"}
                            onChange={props.handleBlur}
                        >
                        <option value="">Select CSV Field</option>
                        {headerName &&
                            headerName.map((names, i) => (
                            <option key={names} value={i}>
                                {names}
                            </option>
                            ))}
                        </select>
                        <span className="text-danger">{errors.first_name}</span>
                    </div>
                </div>
            </div>

            <div className="row pl-5 pr-5">
                <div className="col-sm">
                    <div className="form-group">
                        <label className="mb-1">
                            <strong>Lats Name</strong>
                        </label>
                    </div>
                </div>
                <div className="col-sm">
                    <div className="form-group input-primary">
                        <select
                            className="form-control bg-white"
                            name={"last_name"}
                            onChange={props.handleBlur}
                        >
                        <option value="">Select CSV Field</option>
                        {headerName &&
                            headerName.map((names, i) => (
                            <option key={names} value={i}>
                                {names}
                            </option>
                            ))}
                        </select>
                        <span className="text-danger">{errors.last_name}</span>
                    </div>
                </div>
            </div>

            <div className="row pl-5 pr-5">
                <div className="col-sm">
                    <div className="form-group">
                        <label className="mb-1">
                            <strong>Email</strong>
                        </label>
                    </div>
                </div>
                <div className="col-sm">
                    <div className="form-group input-primary">
                        <select
                            className="form-control bg-white"
                            name={"email"}
                            onChange={props.handleBlur}
                        >
                        <option value="">Select CSV Field</option>
                        {headerName &&
                            headerName.map((names, i) => (
                            <option key={names} value={i}>
                                {names}
                            </option>
                            ))}
                        </select>
                        <span className="text-danger">{errors.email}</span>
                    </div>
                </div>
            </div>

            <div className="row pl-5 pr-5">
                <div className="col-sm">
                    <div className="form-group">
                        <label className="mb-1">
                            <strong>Job Title</strong>
                        </label>
                    </div>
                </div>
                <div className="col-sm">
                    <div className="form-group input-primary">
                        <select
                            className="form-control bg-white"
                            name={"job_title"}
                            onChange={props.handleBlur}
                        >
                        <option value="">Select CSV Field</option>
                        {headerName &&
                            headerName.map((names, i) => (
                            <option key={names} value={i}>
                                {names}
                            </option>
                            ))}
                        </select>
                        <span className="text-danger">{errors.job_title}</span>
                    </div>
                </div>
            </div>

            <div className="row pl-5 pr-5">
                <div className="col-sm">
                    <div className="form-group">
                        <label className="mb-1">
                            <strong>Role</strong>
                        </label>
                    </div>
                </div>
                <div className="col-sm">
                    <div className="form-group input-primary">
                        <select
                            className="form-control bg-white"
                            value={fieldsName.role_uuid}
                            name="role_uuid"
                            onChange={props.handleBlur}
                        >
                            <option value="">Select Role</option>
                            {roles.map((role) => (
                            <option key={role.role_uuid} value={role.role_uuid}>
                                {role.role_name}
                            </option>
                            ))}
                        </select>
                        <span className="text-danger">{errors.role_uuid}</span>
                    </div>
                </div>
            </div>
            
    </Fragment> );

}

export default StepTwo;