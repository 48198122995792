import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const getIvrs = (
  user_type,
  group_per,
  group_uuid,
  search,
  off_set,
  limits,
  orderby,
  main_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "get-ivr-list",
      {
        user_type,
        group_per,
        group_uuid,
        search,
        off_set,
        limits,
        orderby,
        createdby,
        main_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const createIvr = (data) => {
  return axios
    .post(API_URL + "create-ivr", data, {
      headers: {
        Authorization: data.token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const updateIvr = (data) => {
  return axios
    .post(API_URL + "update-ivr", data, {
      headers: {
        Authorization: data.token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getIvrDetail = (createdby, token, ivr_menu_uuid) => {
  return axios
    .post(
      API_URL + "get-ivr-details",
      {
        createdby,
        ivr_menu_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const deleteIvr = (ivr_menu_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "delete-ivr",
      {
        ivr_menu_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getDestinationList = (createdby, token, main_uuid) => {
  return axios
    .post(
      API_URL + "get-destination-list",
      {
        createdby,
        main_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getDestinationTypeList = (createdby, token, main_uuid, type) => {
  return axios
    .post(
      API_URL + "get-destination-type-list",
      {
        createdby,
        main_uuid,
        type,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateIvrOptions = (data) => {
  return axios
    .post(API_URL + "update-ivr-options", data, {
      headers: {
        Authorization: data.token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getNextIvrID = (main_admin_uuid, token, createdby) => {
  return axios
    .post(
      API_URL + "get-ivr-next-id",
      {
        createdby,
        main_admin_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

export default {
  getIvrs,
  createIvr,
  updateIvr,
  getIvrDetail,
  deleteIvr,
  getDestinationList,
  getDestinationTypeList,
  updateIvrOptions,
  getNextIvrID,
};
