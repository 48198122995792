import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import "./style.css";
import { Button, Modal } from "react-bootstrap";
import { getRoleList } from "../../actions/role";

function FilterForm(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const [roleData, setRoleData] = useState([]);

  let statusOption = [
    {
      value: "ACTIVE",
      label: "ACTIVE",
    },
    {
      value: "INACTIVE",
      label: "INACTIVE",
    },
    {
      value: "INVITED",
      label: "INVITED",
    },
  ];

  let search_values = props.searchData;

  const [searchData, setSearchData] = useState({
    user_name: search_values.user_name,
    email: search_values.email,
    role: search_values.role,
    status: search_values.status,
  });

  const dispatch = useDispatch();

  useEffect(() => {

    dispatch(getRoleList(main_uuid, createdby, token))
      .then((response) => {
        setRoleData(response.data);
      })
      .catch(() => {
      });
  }, []);

  const handleChange = (e) => {
    let new_data = { ...searchData };
    new_data[e.target.name] = e.target.value;
    setSearchData(new_data);
    new_data["type"] = "search";
    return;
  };

  const fnSearch = () => {
    props.applySearch(searchData);
  };

  const fnResetFilter = () => {
    let new_data = { ...searchData };
    new_data["user_name"] = "";
    new_data["email"] = "";
    new_data["role"] = "";
    new_data["status"] = "";
    new_data["type"] = "reset";
    setSearchData(new_data);

    props.applySearch(new_data);
    return;
  };

  return (
    <Fragment>
      <Modal.Header>
        <Modal.Title>Filter</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-xl-6 col-xxl-6 col-lg-6">
            <div className="form-group input-primary">
              <label>Name</label>
              <input
                type="text"
                className="form-control bg-white"
                placeholder=""
                name="user_name"
                value={searchData.user_name}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="col-xl-6 col-xxl-6 col-lg-6">
            <div className="form-group input-primary">
              <label>Email</label>
              <input
                type="text"
                className="form-control bg-white"
                placeholder=""
                name="email"
                value={searchData.email}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-6 col-xxl-6 col-lg-6">
            <div className="form-group input-primary">
              <label>Role</label>
              <select
                className="form-control bg-white"
                name="role"
                value={searchData.role}
                onChange={(e) => handleChange(e)}
              >
                <option value="">Select Role</option>
                {roleData.map((data) => (
                  <option value={data.role_uuid} key={data.role_uuid}>
                    {data.role_name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-xl-6 col-xxl-6 col-lg-6">
            <div className="form-group input-primary">
              <label>Status</label>
              <select
                className="form-control bg-white"
                name="status"
                value={searchData.status}
                onChange={(e) => handleChange(e)}
              >
                <option value="">Select Status</option>
                {statusOption.map((data) => (
                  <option value={data.value} key={data.value}>
                    {data.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger light btn-sm" onClick={() => fnResetFilter()}>
          Reset
        </Button>
        <Button
          variant=""
          type="button"
          onClick={() => fnSearch("")}
          className="btn btn-sm btn-primary"
        >
          Apply Search
        </Button>
      </Modal.Footer>
    </Fragment>
  );
}

export default FilterForm;
