import React, { Fragment, useState, useEffect } from "react";
import { Button,Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Tab, Nav } from "react-bootstrap";
import { notifyError,notifyInfo } from "../../Toastr/Toastr";
import { createTask } from "../../../actions/Tasks";
import { contactDropdown } from "../../../actions/contacts";
import { getUserListDropdown } from "../../../actions/comman";
import {createCalendarEvent}  from "../../../actions/calender/CalendarMeeting";
import PermissionCheck from "../../../common/PermissionCheck";
import Loader from "../../../layouts/Loader";
import Select from "react-select";
import moment from "moment";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { GoogleApiWrapper } from "google-maps-react";
const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

function TaskEventForm(props) {
  
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const user_type = user_data.role;
  let listing_per = PermissionCheck("contact", "listing", "", "", "");

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
      group_uuid = user_data.group_id;
  }

  const currentTime = moment();
  const remainder = 15 - (currentTime.minute() % 15);
  const nextTime = currentTime.clone().add(remainder, 'minutes').startOf('minute');
  const start_time = nextTime.format('h:mm A');

   const end_currentTime = moment(start_time, 'hh:mm A');
  const end_nextTime = end_currentTime.clone().add(30, 'minutes');
  const end_time = end_nextTime.format('h:mm A');

  const selectedDate = props.selectedDate;
  const [loading, setLoading] = useState(false);
  const [contactOptions, setContactOptions] = useState([]);
  const [activeTab, setActiveTab] = useState('event');

  const times = [];
  for (let i = 0; i < 24; i++) {
    times.push(
      `${i % 12 || 12}:00 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:15 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:30 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:45 ${i < 12 ? "AM" : "PM"}`
    );
  }

  const [address, setAddress] = useState('');
  const [users, setUsers] = useState([]);
  const [eventData, setEventData] = useState({
    start_time: start_time,
    end_time: end_time,
    meeting_title : "",
    meeting_date : selectedDate,
    meeting_type : "",
    phone_call_type : '',
    call_number : '',
    online_meeting_type : '',
    meeting_url : '',
    google_address : '',
    contact_uuid : '',
    is_all_day : 'NO',
    event_reminder : 15,
    description : '',
    createdby : createdby,
    main_uuid : main_uuid,
  });
  const [taskData, setTaskData] = useState({
    task_title : "",
    task_date : selectedDate,
    task_time : start_time,
    contact_uuid : '',
    assign_user : createdby,
    description : '',
    createdby : createdby,
    main_uuid : main_uuid,
  });

  const dispatch = useDispatch();

  const [errors, setErrors] = useState({
    meeting_title : "",
    meeting_date : "",
    start_time: "",
    end_time: "",
    meeting_type : "",
    phone_call_type : '',
    call_number : '',
    online_meeting_type : '',
    meeting_url : '',
    google_address : ''
  });

  const [taskErrors, setTaskErrors] = useState({
    task_title: "",
    task_date: "",
    task_time: "",
    description: "",
    assign_user: "",
  });

  useEffect(() => {
    let data = {
      createdby : createdby,
      main_uuid : main_uuid,
      group_uuid : group_uuid,
      user_type : user_type,
      group_per : group_per,
    }

    dispatch(
      contactDropdown(
        data,
        token
      )
    )
    .then((response) => {
      //
      let contacts = [];
      response.data.data.map((contact, index) => {
          contacts[index] = {label:'<'+contact.first_name+' '+contact.last_name+'>'+contact.email, value: contact.contact_uuid}
          return true;
      })

      setContactOptions(contacts);
      return;
    })
    .catch(() => {
      setLoading(false);
    });
   
  }, []);

  useEffect(() => {
    dispatch(
      getUserListDropdown(
        "create",
        "",
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        setUsers(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const closeModel = (value) => {
    props.closeTaskEventModel(value);
  }

  const handleBlur = (e) => {
    let error_data = { ...errors };

    let event_data = { ...eventData };

    if(e.target.name === 'is_all_day'){
      if (e.target.checked) {
        event_data[e.target.name] = 'YES';
      }else{
        event_data[e.target.name] = 'NO';
      }

    }else{
      if(e.target.value.length === 0){
        error_data[e.target.name] = 'Field is required.';
        setErrors(error_data);
      }else{
        error_data[e.target.name] = '';
        setErrors(error_data);
      }

      if(e.target.name === 'meeting_type'){
        if(e.target.value === 'phone_call'){
          event_data['phone_call_type'] = 'call_to_calender_owner';
        }
      }
      event_data[e.target.name] = e.target.value;
    }      
    setEventData(event_data);
    
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (eventData.meeting_title.length === 0) {
      error_data["meeting_title"] = "Field is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (eventData.meeting_type.length === 0) {
      error_data["meeting_type"] = "Field is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (eventData.start_time.length === 0) {
      error_data["start_time"] = "Field is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (eventData.end_time.length === 0) {
      error_data["end_time"] = "Field is required.";
      setErrors(error_data);
      isError = "true";
    }

    if(eventData.meeting_type === 'phone_call'){
      if(eventData.phone_call_type === 'call_to_calender_owner' && eventData.call_number.length === 0){
        error_data['call_number'] = 'Field is required.';
        setErrors(error_data);
        isError = "true";
      }
    }

    if(eventData.meeting_type === 'online_meeting'){
      if(eventData.online_meeting_type.length === 0){
        error_data['online_meeting_type'] = 'Field is required.';
        setErrors(error_data);
        isError = "true";
      }

      if(eventData.online_meeting_type === 'external' &&  eventData.meeting_url.length === 0){
        error_data['meeting_url'] = 'Field is required.';
        setErrors(error_data);
        isError = "true";
      }
    }

    if(eventData.meeting_type === 'in_person'){
      if(eventData.google_address.length === 0){
        error_data['google_address'] = 'Field is required.';
        setErrors(error_data);
        isError = "true";
      }
    }

    if (eventData.contact_uuid.length === 0) {
      error_data["contact_uuid"] = "Field is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {

    dispatch(createCalendarEvent(eventData,token))
    .then((res) => {
      if (res.status === 200) {

        props.handleTaskEvent(Math.random);
        closeModel(false);
        setLoading(false);
      } else if (res.status === 500) {
        setLoading(false);
        notifyError("Something went wrong.");
      } else {
        setLoading(false);
        notifyError("Something went wrong.");
      }
    })
    .catch(() => {
      setLoading(false);
    });      

  }
  };

  const handleBlurTask = (e) => {
    let error_data = { ...taskErrors };

    let task_data = { ...taskData };

    if(e.target.value.length === 0){
        error_data[e.target.name] = 'Field is required.';
        setTaskErrors(error_data);
    }else{
      error_data[e.target.name] = '';
      setTaskErrors(error_data);
    }

    task_data[e.target.name] = e.target.value;
    setTaskData(task_data);
  }

  const submitTask = (e) => {
    e.preventDefault();

    let error_data = { ...taskErrors };
    let isError = "false";

    setLoading(true);

    if (taskData.task_title.length === 0) {
      error_data["task_title"] = "Task is required.";
      setTaskErrors(error_data);
      isError = "true";
    }

    if (taskData.task_date && taskData.task_date.length === 0) {
      error_data["task_date"] = "Task date is required.";
      setTaskErrors(error_data);
      isError = "true";
    }

    if (taskData.task_time && taskData.task_time.length === 0) {
      error_data["task_time"] = "Task time is required.";
      setTaskErrors(error_data);
      isError = "true";
    }

    if (taskData.description.length === 0) {
      error_data["description"] = "Description is required.";
      setTaskErrors(error_data);
      isError = "true";
    }

    if (taskData.assign_user.length === 0) {
      error_data["assign_user"] = "Assign User is required.";
      setTaskErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {

      dispatch(createTask(taskData,token))
      .then((res) => {
        closeModel(false);
        props.handleTaskEvent(Math.random());
  
        if (res.status === 200) {
          notifyInfo(res.message);
          closeModel(false);
        } else if (res.status === 406) {
          error_data["number"] = res.message;
          setTaskErrors(error_data);
        } else if (res.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
      
    }
  }

  const handleAddressSelect = async (selectedAddress) => {
    try {
      
      let error_data = { ...errors };

      const newformdata = { ...eventData };
      
      const results = await geocodeByAddress(selectedAddress);

      newformdata['google_address'] = results[0].formatted_address;
      setAddress(results[0].formatted_address);

      if (newformdata.in_person.google_address.length === 0) {
        error_data["google_address"] = "Address is required.";
        setErrors(error_data);
      } else {
        error_data["google_address"] = "";
        setErrors(error_data);
      }
      
      setEventData(newformdata);

    } catch (error) {
      console.error("Error selecting address:", error);
    }
  };

  const handleSelectContactList = (data) => {
    const event_data = { ...eventData };
    event_data["contact_uuid"] = data;
    setEventData(event_data);
  };


  return (
    <Fragment>
      {loading && <Loader />}
      
      <Modal.Header>
        <Modal.Title>Add New Event : {selectedDate}</Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      
        <Modal.Body>

        <Tab.Container defaultActiveKey={activeTab}>
            <Nav as="ul" className="nav-tabs ringtabs">
              <Nav.Item as="li">
                <Nav.Link
                  id="event"
                  eventKey="event"
                  onClick={() => setActiveTab("event")}
                >
                  Event
                </Nav.Link>
              </Nav.Item>

              <Nav.Item as="li">
                <Nav.Link
                  id="task"
                  eventKey="task"
                  onClick={() => setActiveTab("task")}
                >
                  Task
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content className="pt-12">
              {/* event form start */}
              <Tab.Pane eventKey="event">
                <form action="" onSubmit={(e) => submitHandler(e)}>

                  <div className="row mt-3">
                    <div className="form-group input-primary">
                      <label className="mb-1">
                        <strong>Title</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control bg-white h-75"
                        name="meeting_title"
                        value={eventData.meeting_title}
                        onChange={handleBlur}
                      />
                      <span className="text-danger">{errors.meeting_title}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm">
                      <div className="form-group input-primary">
                        <label className="mb-1">
                          <strong>Start Time</strong>
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control bg-white h-75"
                          name="start_time"
                          onChange={handleBlur}
                          value={eventData.start_time}
                        >
                          <option value="">Select Time</option>
                          {times &&
                            times.map((time) => (
                              <option key={time} value={time}>
                                {time}
                              </option>
                            ))}
                        </select>
                        <span className="text-danger">{errors.start_time}</span>
                      </div>
                    </div>

                    <div className="col-sm">
                      <div className="form-group input-primary">
                        <label className="mb-1">
                          <strong>End Time</strong>
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control bg-white h-75"
                          name="start_time"
                          onChange={handleBlur}
                          value={eventData.end_time}
                        >
                          <option value="">Select Time</option>
                          {times &&
                            times.map((time) => (
                              <option key={time} value={time}>
                                {time}
                              </option>
                            ))}
                        </select>
                        <span className="text-danger">{errors.end_time}</span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm">
                      <div className="custom-control custom-checkbox mt-4">
                        <input type="checkbox" className="custom-control-input" id="customCheckBox3" name="is_all_day"
                          checked={eventData.is_all_day === 'YES'}  
                          onChange={handleBlur}
                        />
                        <label className="custom-control-label" htmlFor="customCheckBox3"><strong>All Day</strong></label>
                      </div>
                    </div>

                    <div className="col-sm">
                      <div className="form-group input-primary">
                        <label className="mb-1">
                          <strong>Event Remainder</strong>
                          <span className="text-danger">*</span>
                        </label>
                        
                        <select
                          className="form-control bg-white h-75"
                          name="event_reminder"
                          onChange={handleBlur}
                          value={eventData.event_reminder}
                        >
                          <option value="5">5 min</option>
                          <option value="10">10 min</option>
                          <option value="15">15 min</option>
                          <option value="30">30 min</option>
                          <option value="60">1 hr</option>
                          <option value="120">2 hr</option>
                          <option value="360">6 hr</option>
                          <option value="720">12 hr</option>
                          <option value="1440">24 hr</option>
                        </select>
                        <span className="text-danger">{errors.event_reminder}</span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group input-primary">
                      <label className="mb-1">
                        <strong>Contact</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <Select
                        className="form-control bg-white"
                        //unstyled={true}
                        options={contactOptions}
                        name="contact_uuid"
                        placeholder="Select Contacts"
                        onChange={handleSelectContactList}
                        isSearchable={true}
                        tags={true}
                        isMulti
                      />
                      <span className="text-danger">{errors.contact_uuid}</span>
                    </div>
                  </div>

                  <div className="form-group input-primary">
                    <label className="mb-1">
                      <strong>Meeting Mode</strong>
                      <span className="text-danger">*</span>
                    </label>

                    <select className="form-control bg-white" name="meeting_type" value={eventData.meeting_type} onChange={handleBlur}>
                      <option value="">Select</option>                        
                        <option value="phone_call"> Phone Call </option>                               
                        <option value="online_meeting"> Online Meeting </option>
                        <option value="in_person"> In Person </option>
                    </select>
                    <span className="text-danger">{errors.meeting_type}</span>
                  </div>

                  {eventData.meeting_type === 'phone_call' && 
                    <div className="form-group input-primary mb-3">
                      <label className="mb-1">
                        <strong>Meeting Phone Number</strong>
                        <span className="text-danger">*</span>
                      </label>

                      <input
                        type="text"
                        className="form-control bg-white"
                        name="call_number"
                        defaultValue={eventData.call_number}
                        onChange={handleBlur}
                      />
                      <span className="text-danger">{errors.call_number}</span>
                    </div>
                  } 

                  {eventData.meeting_type === 'online_meeting' && 
                      <div className="form-row" > 
                        <div className="col mt-2 mt-sm-0">

                          <div className="form-group input-primary">
                            <label className="mb-1">
                              <strong>Online Call Type</strong>
                              <span className="text-danger">*</span>
                            </label>

                            <select
                              className="form-control bg-white"
                              name="online_meeting_type"
                              onChange={(e) => handleBlur(e)}
                              value={eventData.online_meeting_type}
                            >
                              <option value="">Select</option>
                              <option value="internal"> Internal </option>
                              <option value="external"> External </option>
                            </select>

                            <span className="text-danger">{errors.online_meeting_type}</span>
                          </div>                 
                        </div>

                        { eventData.online_meeting_type === 'external' && 
                          <div className="col mt-2 mt-sm-0">

                            <div className="form-group input-primary">
                              <label className="mb-1">
                                <strong>Meeting URL</strong>
                                <span className="text-danger">*</span>
                              </label>

                              <input
                                type="text"
                                className="form-control bg-white"
                                name="meeting_url"
                                defaultValue={eventData.meeting_url}
                                onChange={(e) => handleBlur(e)}
                              />
                              <span className="text-danger">{errors.meeting_url}</span>
                            </div>                 
                          </div>
                        }
                      </div>
                    }

                    { eventData.meeting_type === 'in_person' && 
                      <div className="form-row" > 
                        <div className="col mt-2 mt-sm-0">

                          <div className="form-group input-primary">
                            <label className="mb-1">
                              <strong>Address</strong>
                              <span className="text-danger">*</span>
                            </label>

                              <PlacesAutocomplete
                                value={address}
                                onChange={(value) => setAddress(value)}
                                onSelect={handleAddressSelect}
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps,
                                  loading,
                                }) => (
                                  <div>
                                    <input
                                      {...getInputProps({
                                        placeholder: "Enter Address...",
                                        className: "form-control bg-white",
                                      })}
                                      list="data"
                                    />
                                    <div className="autocomplete-dropdown-container">
                                      {loading && <div>Loading...</div>}
                                      <ul className="list-group cursor-pointer">
                                        {suggestions.map((suggestion) => (
                                          <li
                                            className=""
                                            {...getSuggestionItemProps(suggestion)}
                                            key={suggestion.placeId}
                                          >
                                            {suggestion.description}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>

                            <span className="text-danger">{errors.google_address}</span>
                          </div>                 
                        </div>
                      </div>
                    } 

                  <Button type="submit" variant="primary btn-sm mr-2">
                    Save
                  </Button>

                  <Button type="button" variant="danger btn-sm" onClick={(e) => closeModel(false)}>
                    close
                  </Button>
                </form>
              </Tab.Pane>
              {/* event form end */}

              {/* task form start */}
              <Tab.Pane eventKey="task">
                <form action="" onSubmit={(e) => submitTask(e)}>

                  <div className="row mt-3">
                    <div className="form-group input-primary">
                      <label className="mb-1">
                        <strong>Title</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control bg-white h-75"
                        name="task_title"
                        value={taskData.task_title}
                        onChange={handleBlurTask}
                      />
                      <span className="text-danger">{taskErrors.task_title}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm">
                      <div className="form-group input-primary">
                        <label className="mb-1">
                          <strong>Task Time</strong>
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control bg-white"
                          name="task_time"
                          onChange={handleBlurTask}
                          value={taskData.task_time}
                        >
                          <option value="">Select Time</option>
                          {times &&
                            times.map((time) => (
                              <option key={time} value={time}>
                                {time}
                              </option>
                            ))}
                        </select>
                        <span className="text-danger">{taskErrors.task_time}</span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group input-primary">
                      <label className="mb-1">
                        <strong>Contact</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control bg-white h-75"
                        name="contact_uuid"
                        onChange={handleBlurTask}
                        value={taskData.contact_uuid}
                      >
                        <option value="">Select Contact</option>
                        {contactOptions &&
                          contactOptions.map((contact) => (
                            <option key={contact.value} value={contact.value}>
                              {contact.label}
                            </option>
                          ))}
                      </select>
                      <span className="text-danger">{taskErrors.contact_uuid}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm">
                      <div className="form-group input-primary">
                        <label className="mb-1">
                          <strong>Assign User</strong>
                        </label>
                        <select
                          className="form-control bg-white"
                          name="assign_user"
                          onChange={handleBlurTask}
                          value={taskData.assign_user}
                        >
                          <option value="">Select User</option>
                          {users.map((user) => (
                            <option key={user.uuid} value={user.uuid}>
                              {user.user_name}
                            </option>
                          ))}
                          <option key={createdby} value={createdby}>
                            Assign Me
                          </option>
                        </select>
                        <span className="text-danger">{taskErrors.assign_user}</span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group input-primary">
                      <label className="mb-1">
                        <strong>Description</strong>
                      </label>
                      <input type="text" name="description" value={taskData.description} onChange={handleBlurTask} className="form-control bg-white" />
                      {/* <span className="text-danger">{errors.description}</span> */}
                    </div>
                  </div>

                  <Button type="submit" variant="primary btn-sm mr-2">
                    Save
                  </Button>

                  <Button type="button" variant="danger btn-sm" onClick={(e) => closeModel(false)}>
                    close
                  </Button>
                </form>
              </Tab.Pane>
              {/* task form end */}

            </Tab.Content>
          </Tab.Container>
          
        </Modal.Body>      

        {/* <Modal.Footer>             
          
        </Modal.Footer>  */}
      {/* </form> */}
    </Fragment>
  );
}

//export default TaskEventForm;

export default GoogleApiWrapper({
  apiKey: API_KEY,
})(TaskEventForm);
