import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { notifyInfo } from "../../Toastr/Toastr";
import { Card, Modal, Button } from "react-bootstrap";
import { updateE911Data } from "../../../actions/auth";
import PermissionCheck from "../../../common/PermissionCheck";
import { getNumberListDropdown } from "../../../actions/comman";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { GoogleApiWrapper } from "google-maps-react";
import Loader from "../../../layouts/Loader";
const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const E911 = (props) => {
  
  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;

  let listing_per = PermissionCheck("did", "listing", "", "", "");

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }

  const [address, setAddress] = useState(props.formData1.e911_data.address_1);
  const [numbers, setNumbers] = useState([]);
  const [manuallyCheck, setManuallyCheck] = useState("checked");
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({
    caller_id_name: "",
    caller_id_number: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    zip: "",
  });

  useEffect(() => {
    dispatch(
      getNumberListDropdown(
        "update",
        createdby,
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        setNumbers(response.data);
        return;
      })
      .catch(() => {
        //setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (props.formData1.e911_data.address_1 !== "") {
      setAddress(props.formData1.e911_data.address_1);
    }
  }, [props.formData1.e911_data.address_1]);

  const handleBlur = (e) => {
    const newformdata = { ...props.formData1 };
    let error_data = { ...errors };

    if (e.target.name === "caller_id_name") {
      newformdata.e911_data.caller_id_name = e.target.value;
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Caller ID name is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "caller_id_number") {
      newformdata.e911_data.caller_id_number = e.target.value;
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Caller ID Number is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "address_1") {
      newformdata.e911_data.address_1 = e.target.value;
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Address is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "house_number") {
      newformdata.e911_data.house_number = e.target.value;
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Suite/Apt/House number is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "street_name") {
      newformdata.e911_data.street_name = e.target.value;
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Street name is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "city") {
      newformdata.e911_data.city = e.target.value;
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "City is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "state") {
      newformdata.e911_data.state = e.target.value;
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "State is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "zip") {
      newformdata.e911_data.zip = e.target.value;
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Zip is required.";
        setErrors(error_data);
      } else {
        if (!/^[0-9]{4,6}$/i.test(e.target.value)) {
          error_data[e.target.name] = "Please enter zip.";
          setErrors(error_data);
        } else {
          error_data[e.target.name] = "";
          setErrors(error_data);
        }
      }
    }

    props.handleE911Data(newformdata);
  };

  const updateE911DataBtn = (e) => {
    let error_data = { ...errors };
    const newformdata = { ...props.formData1 };
    let isError = "false";
    setLoading(true);

    if (
      newformdata.e911_data.address_1 === null ||
      newformdata.e911_data.address_1 === ""
    ) {
      error_data["address_1"] = "Address is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (
      newformdata.e911_data.caller_id_name === null ||
      newformdata.e911_data.caller_id_name === ""
    ) {
      error_data["caller_id_name"] = "Caller ID Name is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (
      newformdata.e911_data.caller_id_number === null ||
      newformdata.e911_data.caller_id_number === ""
    ) {
      error_data["caller_id_number"] = "Caller ID Number is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (
      newformdata.e911_data.house_number === null ||
      newformdata.e911_data.house_number === ""
    ) {
      error_data["house_number"] = "House/Suite/Apt Number is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (
      newformdata.e911_data.street_name === null ||
      newformdata.e911_data.street_name === ""
    ) {
      error_data["street_name"] = "Street Name is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (
      newformdata.e911_data.city === null ||
      newformdata.e911_data.city === ""
    ) {
      error_data["city"] = "City is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (
      newformdata.e911_data.state === null ||
      newformdata.e911_data.state === ""
    ) {
      error_data["state"] = "State is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (
      newformdata.e911_data.zip === null ||
      newformdata.e911_data.zip === ""
    ) {
      error_data["zip"] = "zip is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create user API
      let data = { ...newformdata.e911_data };
      data["createdby"] = createdby;
      data["main_uuid"] = main_uuid;
      data["user_uuid"] = createdby;
      data["user_e911_uuid"] = props.formData1.e911_data.user_e911_uuid;

      dispatch(updateE911Data(data, token))
        .then((response) => {
          notifyInfo(response.message);
          props.handleE911Event(Math.random);
          setLoading(false);
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      
      let error_data = { ...errors };

      let add_data = results[0].address_components;

      const newformdata = { ...props.formData1 };

      newformdata.e911_data.address_1 = results[0].formatted_address;

      if (newformdata.e911_data.address_1.length === 0) {
        error_data["address_1"] = "Address is required.";
        setErrors(error_data);
      } else {
        error_data["address_1"] = "";
        setErrors(error_data);
      }

      setAddress(results[0].formatted_address);
      add_data.forEach((add, index) => {
        if (
          add.types.find((element) => element === "subpremise") === "subpremise"
        ) {
          newformdata.e911_data.house_number = add.long_name;
        }

        if (add.types.find((element) => element === "premise") === "premise") {
          newformdata.e911_data.street_name = add.long_name;
        }

        if (add.types.find((element) => element === "route") === "route") {
          newformdata.e911_data.street_name = add.long_name;
        }

        if (
          add.types.find((element) => element === "postal_code") === "postal_code"
        ) {
          newformdata.e911_data.zip = add.long_name;
        }

        if (
          add.types.find(
            (element) => element === "administrative_area_level_1"
          ) === "administrative_area_level_1"
        ) {
          newformdata.e911_data.state = add.long_name;
        }

        if (
          add.types.find(
            (element) => element === "administrative_area_level_2"
          ) === "administrative_area_level_2"
        ) {
          newformdata.e911_data.city = add.long_name;
        } else {
          if (
            add.types.find(
              (element) => element === "administrative_area_level_3"
            ) === "administrative_area_level_3"
          ) {
            newformdata.e911_data.city = add.long_name;
          }
        }

        props.handleE911Data(newformdata);
      });
    } catch (error) {
      console.error("Error selecting address:", error);
    }
  };

  function handleSelectCheck(data) {
    let rec_chk_val = data.target.value;

    if (rec_chk_val !== "" && rec_chk_val === "checked") {
      setManuallyCheck("");
    } else {
      setManuallyCheck("checked");
    }
  }

  return (
    <div className="pt-3">
      {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>E911 Callar ID Number and Address</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label className="mb-1">
                <strong>Caller ID Name</strong>
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Caller ID Name"
                name="caller_id_name"
                id="caller_id_name"
                onChange={handleBlur}
                value={props.formData1.e911_data.caller_id_name}
              />
              <span className="text-danger">{errors.caller_id_name}</span>
            </div>
          </div>
          {user_data.role === "admin" ? (
            <div className="col-6">
              <div className="form-group">
                <label className="mb-1">
                  <strong>Caller ID Number</strong>
                  <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  value={props.formData1.e911_data.caller_id_number}
                  name="caller_id_number"
                  onChange={handleBlur}
                >
                  <option value="">Select Caller ID Number</option>
                  {numbers.map((num) => (
                    <option key={num.value} value={num.value}>
                      {num.label}
                    </option>
                  ))}
                </select>
                {/* <input
                          type="text"
                          className="form-control"
                          placeholder="Caller ID Number"
                          name="caller_id_number"
                          id="caller_id_number"
                          onChange={handleBlur}
                          value={e911Data.caller_id_number}
                        /> */}
                <span className="text-danger">{errors.caller_id_number}</span>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        <Card.Header className="d-block card-header">
          <Card.Title>Address</Card.Title>
        </Card.Header>

        <div className="row">
          <div className="col-6">
            <div className="row">
              <div className="col-12 mt-2">
                <div className="form-group">
                  

                  <label className="text-label">
                    Addess
                    <span className="text-danger mr-4">*</span>
                    <span className="ml-4">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="check1"
                        name="is_recurring"
                        onChange={handleSelectCheck}
                        value={manuallyCheck}
                      />
                      <label className="form-check-label" htmlFor="check1">
                        Enter manually
                      </label>
                    </span>
                  </label>
                  {manuallyCheck === "checked" ? (
                    <PlacesAutocomplete
                      value={address}
                      onChange={(value) => setAddress(value)}
                      onSelect={handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: "Search Places...",
                              className: "form-control",
                            })}
                            list="data"
                          />
                          {/* <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              <datalist id="data">
                                {suggestions.map((suggestion) => (
                                  <option
                                    {...getSuggestionItemProps(suggestion)}
                                    key={suggestion.placeId}
                                    value={suggestion.description}
                                  />
                                ))}
                              </datalist>
                            </div> */}
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            <ul className="list-group">
                              {suggestions.map((suggestion) => (
                                <li
                                  className=""
                                  {...getSuggestionItemProps(suggestion)}
                                  key={suggestion.placeId}
                                >
                                  {suggestion.description}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  ) : (
                    <div className="form-group">
                      <textarea
                        rows={3}
                        className="form-control"
                        name="address_1"
                        placeholder="address"
                        onChange={handleBlur}
                        value={props.formData1.e911_data.address_1}
                      />
                    </div>
                  )}
                  <span className="text-danger">{errors.address_1}</span>
                </div>
              </div>

              <div className="col-6">
                <div className="form-group">
                  <label className="mb-1">
                    <strong>Street Name</strong>
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Street Name"
                    name="street_name"
                    id="street_name"
                    onChange={handleBlur}
                    value={props.formData1.e911_data.street_name}
                  />
                  <span className="text-danger">{errors.street_name}</span>
                </div>
              </div>

              <div className="col-6">
                <div className="form-group">
                  <label className="mb-1">
                    <strong>Suite/Apt</strong>
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Suite/Apt Number"
                    name="house_number"
                    id="house_number"
                    onChange={handleBlur}
                    value={props.formData1.e911_data.house_number}
                  />
                  <span className="text-danger">{errors.house_number}</span>
                </div>
              </div>

              <div className="col-6">
                <div className="form-group">
                  <label className="mb-1">
                    <strong>City</strong>
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="City"
                    name="city"
                    id="city"
                    onChange={handleBlur}
                    value={props.formData1.e911_data.city}
                  />
                  <span className="text-danger">{errors.city}</span>
                </div>
              </div>

              <div className="col-6">
                <div className="form-group">
                  <label className="mb-1">
                    <strong>State</strong>
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="State"
                    name="state"
                    id="state"
                    onChange={handleBlur}
                    value={props.formData1.e911_data.state}
                  />
                  <span className="text-danger">{errors.state}</span>
                </div>
              </div>

              <div className="col-6">
                <div className="form-group">
                  <label className="mb-1">
                    <strong>Zip</strong>
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Zip code"
                    name="zip"
                    id="zip"
                    onChange={handleBlur}
                    value={props.formData1.e911_data.zip}
                  />
                  <span className="text-danger">{errors.zip}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row text-right">
          <div className="col-sm">
            <Button
              type="button"
              variant="primary"
              className="btn-sm mr-2"
              onClick={(e) => updateE911DataBtn()}
            >
              Update E911
            </Button>
          </div>
        </div>
      </Modal.Body>
    </div>
  );
};

// export default E911;

export default GoogleApiWrapper({
  apiKey: API_KEY,
})(E911);
