import React, { Fragment, useState,useEffect } from "react";
import { Modal,Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getAreaCodeList } from "./../../actions/comman";
import { getStates } from "./../../actions/number";

function ExportForm(props) {

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  
  const closeModel = (arg) => {
    props.closeExportModal(arg);
  };

  let exportType = props.exportType;

  const dispatch = useDispatch();

  const [prefixData, setPrefixData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [error, setError] = useState('');
  const [value, setValue] = useState('');

  if (exportType === 'state') {
    useEffect(() => {
      dispatch(getStates(createdby, token))
        .then((response) => {
          setStateData(response.data);
        })
        .catch(() => {
          //setLoading(false);
        });
    }, []);
  
  }

  if (exportType === 'area_code') {
    useEffect(() => {
      dispatch(getAreaCodeList(createdby, token))
        .then((response) => {
          setPrefixData(response.data.data);
        })
        .catch(() => {
          //setLoading(false);
        });
    }, []);
  
  }
  
  const handleBlur = (e) => {
    if (e.target.value.length === 0) {
      setError('Please select value');
    }else{
      setValue(e.target.value);
      props.setExportData(e.target.value);
    }
    
  };

  const submitHandler = () => {
    if (value.length === 0) {
      setError('Please select value');
    }else{
      props.exportList('')
    }
    
  };

  return (
    <Fragment>
      {/* <Modal.Header>
        <Modal.Title>Select </Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header> */}
      <form action="" >
        <Modal.Body>
        <Button variant="pull-right" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
          <div className="row">
            {exportType === 'area_code' && 
                <div className="form-group input-primary">
                  <label>Area Code</label>
                  <select
                    className="form-control bg-white"
                    name="area_code"
                    onChange={(e) => handleBlur(e)}
                  >
                    <option value="">Select Area Code</option>
                    {prefixData.map((number) => (
                      <option value={number.area_code} key={number.area_code}>
                        {number.area_code}
                      </option>
                    ))}
                  </select>
                  <p className="text-danger">{error}</p>
              </div>
            }
            
            {exportType === 'state' && 
                <div className="form-group input-primary">
                  <label>State</label>
                  <select
                    className="form-control bg-white"
                    name="state_uuid"
                    onChange={(e) => handleBlur(e)}
                  >
                    <option value="">Select State</option>
                    {stateData.map((row) => (
                      <option value={row.uuid} key={row.uuid}>
                        {row.state_name}
                      </option>
                    ))}
                  </select>
                  <p className="text-danger">{error}</p>
                </div>
            }
            
            
          </div>

          <div className="row">
            <button type="button" onClick={() => submitHandler()}  className="btn btn-sm btn-primary pull-right">Export</button>
          </div>
          
        </Modal.Body>
        
      </form>
    </Fragment>
  );
}

export default ExportForm;
