import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const getDid = (
  group_per,
  group_uuid,
  user_type,
  search,
  filter,
  off_set,
  limits,
  orderby,
  main_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "list-did",
      {
        group_per,
        group_uuid,
        user_type,
        search,
        filter,
        off_set,
        limits,
        orderby,
        createdby,
        main_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const searchDid = (data, token) => {
  return axios
    .post(API_URL + "get-number", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const addDids = (data, token) => {
  return axios
    .post(API_URL + "buy-did", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const onboardingAddDids = (data, token) => {
  return axios
    .post(API_URL + "onboarding-buy-did", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const userOnboardingAddNumber = (data, token) => {
  return axios
    .post(API_URL + "user-onboarding-add-did", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const updateDid = (data, token) => {
  return axios
    .post(API_URL + "update-did", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getDidDetail = (createdby, token, extuuid) => {
  return axios
    .post(
      API_URL + "get-did-details",
      {
        createdby,
        extuuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getDidRouting = (createdby, did_uuid, token) => {
  return axios
    .post(
      API_URL + "get-did-routing",
      {
        createdby,
        did_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getMusicOnHoldFile = (createdby, main_admin_uuid, token) => {
  return axios
    .post(
      API_URL + "get-music-on-hold-file",
      {
        createdby,
        main_admin_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getMusicOnHoldFileName = (createdby, recording_uuid, token) => {
  return axios
    .post(
      API_URL + "get-music-on-hold-file-name",
      {
        createdby,
        recording_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateDIDRouting = (data, token) => {
  return axios
    .post(API_URL + "update-did-routing", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const updateRoute = (createdby, token, main_uuid, didRoute, didData) => {
  return axios
    .post(
      API_URL + "update-route",
      {
        createdby,
        main_uuid,
        didRoute,
        didData,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

export default {
  getDid,
  searchDid,
  updateDid,
  getDidDetail,
  addDids,
  onboardingAddDids,
  userOnboardingAddNumber,
  getDidRouting,
  getMusicOnHoldFile,
  getMusicOnHoldFileName,
  updateDIDRouting,
  updateRoute,
};
