import React, { Fragment, useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { notifyError,notifyInfo } from "../../Toastr/Toastr";
import { getEventList,deleteUserMeetingEvent } from "../../../actions/calender/UserMeetingEvent";
import Loader from "../../../layouts/Loader";
import MeetingEventForm from "./MeetingEventForm";
import { confirmAlert } from "react-confirm-alert";

const SERVER_URL = process.env.REACT_APP_FRONT_END_URL;

function UserEvent(props) {
  const closeModel = (arg) => {
    props.closeUserEventModel(arg);
  };

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;

  const [addEventForm, setAddEventForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState('');
  const [userEvents, setUserEvents] = useState([]);

  const [updateData, setUpdateData] = useState({
    event_title: '',
    meeting_duration: 30,
    action : 'add',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getEventList(
        createdby,
        token
      )
    )
      .then((response) => {
        setUserEvents(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, [event]);


  const toggleEventForm = () => {
    if(addEventForm){
      setAddEventForm(false);
    }else{
      setAddEventForm(true);
    }
  }

  const handleEvent = (value) => {

    let newUpdatedata = {...updateData}
    newUpdatedata['event_title'] = '';
    newUpdatedata['meeting_duration'] = 30;
    newUpdatedata['action']  = 'add';

    setUpdateData(newUpdatedata);

    setEvent(value);
    setAddEventForm(false);
  }

  const fnDeleteEvent = (user_meeting_event_uuid) => {
    confirmAlert({
      title: "",
      message: "Users will be unable to schedule further meetings with deleted event types. Meetings previously scheduled will not be affected.  Are you sure to delete this time slot?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteEventService(user_meeting_event_uuid),
        },
        {
          label: "No",
        },
      ],
    });
  }

  const deleteEventService = (user_meeting_event_uuid) => {
    dispatch(deleteUserMeetingEvent(user_meeting_event_uuid,createdby,token))
      .then((res) => {
        if (res.status === 200) {

          setEvent(Math.random);
        } else if (res.status === 500) {
          setLoading(false);
          notifyError("Something went wrong.");
        } else {
          setLoading(false);
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }

  const updateSlot = (data) => {
    let newUpdatedata = {...updateData}

    newUpdatedata['user_meeting_event_uuid'] = data.user_meeting_event_uuid;
    newUpdatedata['event_title'] = data.event_title;
    newUpdatedata['meeting_duration'] = data.meeting_duration;
    newUpdatedata['action']  = 'update';
    
    setUpdateData(newUpdatedata);
    setAddEventForm(true);
  }

  const closeForm = () => {
    let newUpdatedata = {...updateData}
    newUpdatedata['event_title'] = '';
    newUpdatedata['meeting_duration'] = 30;
    newUpdatedata['action']  = 'add';

    setUpdateData(newUpdatedata);
    setAddEventForm(false);
  }

  const copyLink = (e,user_meeting_event_uuid) => {
    e.preventDefault();

    let url = SERVER_URL+'schedule-meeting?t='+user_meeting_event_uuid;
    navigator.clipboard.writeText(url).then(() => {
      notifyInfo('URL copied to clipboard!');
    }).catch(err => {
      notifyError('Could not copy URL.');
    });
  }

  return (
    <Fragment>
      {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>User Events </Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      
        <Modal.Body>

          <div className="row">

            {userEvents &&
              userEvents.map((row) => (
                <div className="col-xl-3 col-lg-6 col-sm-6 col-xxl-4" key={row.user_meeting_event_uuid}>
                  <div className="widget-stat card bgl-info">
                    <div className="card-body p-4">
                      <h4 className="card-title">
                        <small><i className="fa fa-calendar-o" aria-hidden="true"></i> </small> 
                        {row.event_title}
                      </h4>
                      
                      <h4><i className="fa fa-clock-o" aria-hidden="true"></i> {row.meeting_duration} Min Duration</h4>
                      
                      <a href="#!" className="pull-left" onClick={(e) => copyLink(e,row.user_meeting_event_uuid)}>
                        <small><i className="fa fa-clone" aria-hidden="true"></i> Copy Link</small>
                      </a>

                      <div className="">
                        
                        <Button
                            type="button"
                            className="btn btn-danger shadow btn-xs sharp pull-right"
                            onClick={(e) =>{ fnDeleteEvent(row.user_meeting_event_uuid)}}
                        >
                            <i className="fa fa-trash"></i>
                        </Button>

                        <Button
                          type="button"
                          className="btn btn-info shadow btn-xs sharp mr-1 pull-right"
                          onClick={(e) =>{ updateSlot(row)}}
                        >
                          <i className="fa fa-pencil"></i>
                        </Button>

                      </div>
                    </div>
                  </div>
                </div>
              ))
            }

          </div>

          <div className="form-row mt-4 mb-2"> 
            <div className="col mt-2 mt-sm-0">
                
                <a href="#!" className="text text-success mb-2 mr-2" onClick={toggleEventForm} >   
                    <i className="fa fa-plus mr-2"></i>
                    Add New Event
                </a>
            </div>
          </div>

              {addEventForm &&
                <MeetingEventForm
                    updateData={updateData}
                    handleEvent={handleEvent}
                    closeForm={closeForm}
                />
              }

          
          
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => closeModel(false)}
            variant="danger light btn-sm"
          >
            Close
          </Button>
          
        </Modal.Footer>
    </Fragment>
  );
}

export default UserEvent;
