import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const getRecordings = (
  user_type,
  group_per,
  group_uuid,
  type,
  search,
  off_set,
  limits,
  orderby,
  main_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "get-recording-list",
      {
        user_type,
        group_per,
        group_uuid,
        type,
        search,
        off_set,
        limits,
        orderby,
        createdby,
        main_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const createRecording = (data, token) => {
 
  return axios
    .post(API_URL + "upload-recording", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const updateRecording = (data, token) => {
 
  return axios
    .post(API_URL + "update-recording", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getRecordingDetail = (createdby, token, extuuid) => {
  return axios
    .post(
      API_URL + "get-recordings-details",
      {
        createdby,
        extuuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const deleteRecording = (recording_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "delete-recording",
      {
        recording_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getRecordingList = (createdby, token, type) => {
  return axios
    .post(
      API_URL + "get-recording-dropdown",
      {
        createdby,
        type
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const MohFileDelete = (music_on_hold_files_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "delete-moh-file",
      {
        music_on_hold_files_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const MohFileUpdate = (music_on_hold_files, createdby, token) => {
  return axios
    .post(
      API_URL + "update-moh-file",
      {
        music_on_hold_files,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

export default {
  getRecordings,
  createRecording,
  getRecordingDetail,
  deleteRecording,
  getRecordingList,
  updateRecording,
  MohFileDelete,
  MohFileUpdate,
};
