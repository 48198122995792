import React, { Fragment, useState } from "react";
import "./index.css";
import { Col, Card, Table, Modal } from "react-bootstrap";
import ConfirmationPopup from "./ConfirmationPopup";

const SelectedNumbers = (props) => {
  let didsData = props.didsData;
  let setup_price = 3;
  let monthly_price = 5;

  const [confirmationPopupModal, setConfirmationPopupModal] = useState(false);

  function buyConfirm(data) {
    let newCampaignData1 = {};
    newCampaignData1.selected_dids = didsData;
    newCampaignData1.status = "buy_and_confirm";
    props.handleBuyAndConfirmData(newCampaignData1);
  }

  function removeNum(data, i) {
    const filterDid = didsData.filter((didss) => {
      return didss.phone_number !== data;
    });

    props.handleRemovedNumber(filterDid);
  }

  let toll_free_num = 0;
  let local_num = 0;
  let total_num = 0;

  didsData.forEach((index) => {
    total_num = total_num + 1;

    if (index.phone_number_type === "toll_free") {
      toll_free_num = toll_free_num + 1;
    } else {
      local_num = local_num + 1;
    }
  });

  function getState(did_data) {
    return Object.values(did_data).filter(k => k.region_type === "state").map((k) => {
     
        return k.region_name;
      
    });
  }

  const closeModel = (value) => {
    setConfirmationPopupModal(value);
  };

  return (
    <Fragment>
      <>
        {didsData.length > 0 && (
          <Col lg={12}>
            <Card>
              <Card.Header>
                <Card.Title>
                  Selected{" "}
                  {props.type === "user_onboarding" ? "Number" : "Numbers"}(
                  {total_num})
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>
                        <strong>Number</strong>
                      </th>
                      <th>
                        <strong>Type</strong>
                      </th>
                      <th>
                        <strong>State</strong>
                      </th>
                      {props.type === "user_onboarding" ? (
                        <></>
                      ) : (
                        <>
                          {" "}
                          <th>
                            <strong>Setup (*NRC)</strong>
                          </th>
                          <th>
                            <strong>Monthly (**MRC)</strong>
                          </th>
                        </>
                      )}
                      <th>
                        <strong>Action</strong>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {didsData.map((did, i) => (
                      <tr key={i}>
                        <td>
                          <strong>{did.phone_number}</strong>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="w-space-no">
                              {did.phone_number_type === "toll_free" ? (
                                <span> Tall Free </span>
                              ) : (
                                <span> Local </span>
                              )}
                            </span>
                          </div>
                        </td>
                        <td>
                          {did.phone_number_type === "toll_free" ? (
                            <span> - </span>
                          ) : (
                            <spam>{getState(did.region_information)}</spam>
                          )}
                        </td>
                        {props.type === "user_onboarding" ? (
                          <></>
                        ) : (
                          <>
                            <td>
                              <span> {setup_price}$ </span>
                            </td>
                            <td>
                              <span> {monthly_price}$ </span>
                            </td>
                          </>
                        )}
                        <td>
                          <div className="d-flex">
                            <div className={"btn_" + i}>
                              <button
                                type="button"
                                onClick={() => removeNum(did.phone_number, i)}
                                className="btn btn-sm btn-danger"
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>

            <div className="text-center">
              {props.type === "user_onboarding" ? (
                <button
                  type="button"
                  onClick={() => setConfirmationPopupModal(true)}
                  className="btn btn-sm btn-primary"
                >
                  Confirm Add Number
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => buyConfirm("buy_and_confirm")}
                  className="btn btn-sm btn-primary"
                >
                  Confirm and Buy
                </button>
              )}
            </div>
          </Col>
        )}
        <Modal
          className="fade bd-example-modal-lg"
          show={confirmationPopupModal}
          size="lg"
        >
          <ConfirmationPopup
            closeModel={closeModel}
            didsData={didsData}
            type={props.type}
            handleSendSmsEvent={""}
          />
        </Modal>
      </>
    </Fragment>
  );
};

export default SelectedNumbers;
