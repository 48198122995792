import React, { useState } from "react";

/// React router dom
import { Link } from "react-router-dom";
import "./index.css";

/// images
import logo from "../../../images/Zongo.jpg";
//import logoText from "../../../images/logo-text.png";

const NavHader = () => {
   const [toggle, setToggle] = useState(true);
   return (
      <div className="nav-header" style={{'height': '4.5rem','borderBottom': '1px solid lightgrey','boxShadow': '0 4px 6px -5px #686565'}}>
         <Link to="/" className="brand-logo">
            <img className="" src={logo} alt="" height={60} />
         </Link>

         <div className="nav-control" onClick={() => setToggle(!toggle)} style={{'backgroundColor' :'#70c5600d'}}>
            <div className={`hamburger mt-3 ${toggle ? "is-active" : ""}`} >
               <span className="line" style={{'backgroundColor' : '#f3f5f2 !important'}}></span>
               <span className="line" style={{'backgroundColor' : '#f3f5f2 !important'}}></span>
               <span className="line" style={{'backgroundColor' : '#f3f5f2 !important'}}></span>
            </div>
         </div>
      </div>
   );
};

export default NavHader;
