import React, { Fragment, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Col, Row, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css";
import PageTitle from "../../layouts/PageTitle";
import {
  getContactsList,
  getSmsLog,
  getUserAssignNumberList,
  sendSms,
  getAllContactsList,
} from "../../actions/chatSms";
import CheckModulePermisson from "../../common/CheckModulePermisson";
import PermissionCheck from "../../common/PermissionCheck";
import "./style.css";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import moment from "moment";
import SendSmsForm from "./SendSms";
import PerfectScrollbar from "react-perfect-scrollbar";
import Loader from "../../layouts/Loader";

function chatSms(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;
  const module_name = "chat-sms";

  let module_per = CheckModulePermisson(module_name);

  const params = useLocation();

  let isPermission = true;
  if (module_per === "" || user_type === "admin") {
    isPermission = true;
  } else {
    isPermission = false;
  }

  let listing_per = PermissionCheck(module_name, "listing", "", "", "");

  const dispatch = useDispatch();
  let navigate = useNavigate();

  if (listing_per === "none") {
    navigate("/error-403");
  }

  if (!isPermission) {
    navigate("/error-403");
  }

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }


  const closeModel = (value) => {
    setSendSmsModal(value);
  };

  const [loading, setLoading] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [contactListAll, setContactListAll] = useState([]);
  const [smsLogList, setSmsLogList] = useState([]);
  const [sendSmsModal, setSendSmsModal] = useState(false);
  const [assignNumberData, setAssignNumberData] = useState([]);
  const [fromNumber, setFromNumber] = useState("");
  const [toNumber, setToNumber] = useState("");
  const [message, setMessage] = useState("");
  const [textCount, setTextCount] = useState(0);
  const [smsCount, setSmsCount] = useState(0);

  let sms_campaign_log_uuid = "";

  if (params.state !== null) {
    sms_campaign_log_uuid = params.state.sms_campaign_log_uuid;
  }

  const [errors, setErrors] = useState({
    role_name: "",
  });

  useEffect(() => {
    dispatch(
      getUserAssignNumberList(
        sms_campaign_log_uuid,
        main_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        const newCampaignData = { ...fromNumber };
        if (sms_campaign_log_uuid === "") {
          newCampaignData["from_number"] = response.data.data[0].did_number;
        } else {
          newCampaignData["from_number"] = response.data.from_number;
        }

        setFromNumber(newCampaignData);
        setAssignNumberData(response.data.data);
        if (response.data.contact_list !== "") {
          setContactList(response.data.contact_list);
          setToNumber(response.data.contact_list[0].to_number);
        }
        if (response.data.sms_log_data !== "") {
          setSmsLogList(response.data.sms_log_data);
        }
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, [sms_campaign_log_uuid]);


  useEffect(() => {
    dispatch(
      getAllContactsList(
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        //console.log(response.data);
        setContactListAll(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);


  const handleBlur = (e) => {
    const newCampaignData = { ...fromNumber };
    newCampaignData[e.target.name] = e.target.value;
    setFromNumber(newCampaignData);
    getReloadMsg(e.target.value);
  };

  const handleReply = (e) => {
    // const newCampaignData = { ...fromNumber };
    // newCampaignData[e.target.name] = e.target.value;
    setMessage(e.target.value);
  };

  const getSmsLogBtn = (e) => {
    dispatch(
      getSmsLog(
        e.target.value,
        fromNumber.from_number,
        main_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        setSmsLogList(response.data.data);
        setToNumber(e.target.value);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const countChar = (e) => {
    let len = e.target.value.length;
    let str = e.target.value;

    let parts = [];
    let partSize = 160;

    while (str) {
      if (str.length < partSize) {
        parts.push(str);
        break;
      } else {
        parts.push(str.substr(0, partSize));
        str = str.substr(partSize);
      }
    }

    setTextCount(len);
    setSmsCount(parts.length);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    let error_data = { ...errors };
    let isError = "false";

    if (message.length === 0) {
      error_data["message"] = "Message is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      dispatch(
        sendSms(
          fromNumber.from_number,
          toNumber,
          message,
          main_uuid,
          createdby,
          token
        )
      )
        .then((res) => {
          if (res.status === 200) {
            closeModel(false);
            notifyInfo(res.message);
            setLoading(false);
            props.handleRoleEvent("added");
            navigate("/chat-sms");
          } else if (res.status === 406) {
            error_data["role_name"] = res.message;
            setErrors(error_data);
            isError = "true";
          } else if (res.status === 500) {
            notifyError("Something went wrong.");
          } else {
            notifyError("Something went wrong.");
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  function getReloadMsg(frm_number) {
    setLoading(true);
    dispatch(
      getContactsList(
        frm_number,
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        if (response.data.data !== "") {
          setContactList(response.data.data);
          setSmsLogList(response.data.sms_log_data);
          if (response.data.sms_log_data !== "") {
            setToNumber(response.data.sms_log_data[0].to_number);
          } else {
            setToNumber("");
          }
        } else {
          setContactList([]);
          setSmsLogList([]);
          setToNumber([]);
        }
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }

  const breadCurmList = [
    { label: "SMS", link: "/", is_active: "NO" },
    { label: "Chat SMS", link: "/chat-sms", is_active: "NO" },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />
      {isPermission ? (
        <>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      From Number
                    </label>
                    <div className="col-sm-4">
                      <select
                        className="form-control"
                        name="from_number"
                        value={fromNumber.from_number}
                        onChange={handleBlur}
                      >
                        <option value="">Select From Number</option>
                        {assignNumberData.map((number) => (
                          <option key={number.uuid} value={number.did_number}>
                            {number.did_number}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-sm-2"></div>
                    <div className="col-sm-4">
                      <Button
                        type="button"
                        className="btn btn-sm btn-primary pull-right"
                        onClick={() => setSendSmsModal(true)}
                      >
                        Send SMS
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="animated fadeIn demo-app">
            <Row>
              <Col lg={3}>
                <div className="card">
                  <div className="card-header pb-0 border-bottom-0">
                    <h4 className="card-title">Contacts</h4>
                  </div>

                  <div className="card-body">
                    <div id="">
                      {contactList.map((event) => (
                        <Button
                          key={event.to_number}
                          type="button"
                          className="btn-block btn btn-sm btn-primary"
                          value={event.to_number}
                          onClick={getSmsLogBtn}
                        >
                          {event.contact_uuid === null
                            ? event.to_number
                            : event.contact_name}
                        </Button>
                      ))}
                    </div>
                  </div>
                </div>
              </Col>

              <Col lg={9}>
                <div className="card">
                  <div className="card-header pb-0 border-bottom-0">
                    <h4 className="card-title">Conversations</h4>
                  </div>

                  <div className="card-body">
                    <div className="demo-app-calendar" id="mycalendartest">
                      <PerfectScrollbar
                        style={{ height: "350px" }}
                        className={`card-body msg_card_body dz-scroll  `}
                        id="DZ_W_Contacts_Body3"
                      >
                        {smsLogList.map((sms) => (
                          <div key={sms.sms_campaign_log_uuid}>
                            <div
                              className={
                                sms.direction === "outbound"
                                  ? "d-flex justify-content-start mb-4"
                                  : "d-flex justify-content-end mb-4"
                              }
                            >
                              <div className="img_cont_msg"></div>
                              <div
                                className={
                                  sms.direction === "outbound"
                                    ? "msg_cotainer"
                                    : "msg_cotainer_send"
                                }
                              >
                                {sms.message_text}
                                <span
                                  className={
                                    sms.direction === "outbound"
                                      ? "msg_time"
                                      : "msg_time_send"
                                  }
                                >
                                  {moment(sms.date_time).format(
                                    "DD/MM/YYYY h:mm A"
                                  )}
                                  {/* {sms.created_at} */}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </PerfectScrollbar>
                      {contactList !== "" ? (
                        <form action="" onSubmit={(e) => submitHandler(e)}>
                          <div className="card-footer type_msg">
                            <span className="pull-right">
                              {textCount}/{smsCount}
                            </span>
                            <div className="input-group">
                              <textarea
                                className="form-control reply_text"
                                placeholder="Type your message..."
                                onChange={handleReply}
                                onKeyUp={countChar}
                              ></textarea>
                              <div className="input-group-append reply_text">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  <i className="fa fa-location-arrow"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>

                {/* <MessageBox smsLogList={smsLogList} fromNumber={fromNumber} /> */}
              </Col>
            </Row>
            
          </div>
          <Modal className="fade" show={sendSmsModal}>
            <SendSmsForm
              closeModel={closeModel}
              fromNumber={fromNumber}
              toNumber={toNumber}
              assignNumberData={assignNumberData}
              contactList={contactList}
              contactListAll={contactListAll}
              handleSendSmsEvent={getReloadMsg}
            />
          </Modal>
        </>
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Manage Sms Log</h4>
              </div>
              <div className="card-body">
                <h3>You have don't access this modules</h3>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default chatSms;
