import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../actions/auth";
import { createExtension } from "../actions/extensions";
import "./index.css";
import Loader from "../../jsx/layouts/Loader";

const Login = () => {
  let navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [passw, setPassw] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [emailError, setemailError] = useState(true);
  const [isPasswValid, setIsPasswValid] = useState(true);
  const [passError, setpassError] = useState(true);

  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();

    if (email.length === 0) {
      setIsEmailValid(false);
      setemailError("Email id is required.");
      return;
    }
    if (passw.length === 0) {
      setIsPasswValid(false);
      setpassError("Password id is required.");
      return;
    }

    setIsEmailValid(true);
    setIsPasswValid(true);

    setLoading(true);

    dispatch(login(email, passw))
      .then((data) => {
        const user = JSON.parse(localStorage.getItem("user"));
        const user_data = user.data.data;
        const token = user.data.token;
        const createdby = user_data.user_uuid;
        const main_uuid = user_data.main_uuid;

        if (user_data.role === "admin" && user_data.extension === "") {
          const ext_pass = Math.floor(1000000 + Math.random() * 9000000);

          const extensionData = {
            extension: "1000",
            extension_password: ext_pass.toString(),
            voicemail_password: ext_pass.toString(),
            voicemail_enabled: "NO",
            call_waiting_enabled: "YES",
            call_waiting_timeout: "20",
            max_registrations: "1",
            dnd_enabled: "NO",
            initial_extension_ringtime: "15",
            limit_max: "1",
            limit_destination: "",
            voicemail_mail_to: "",
            voicemail_file: "",
            call_timeout: "20",
            call_screen_enabled: "",
            description: "",
            when_create: "login",
            user: createdby,
            createdby: createdby,
            main_admin_uuid: main_uuid,
            user_uuid: createdby,
          };

          dispatch(createExtension(extensionData, token))
            .then(() => {
              //navigate("/extensions", {replace: true});
              //navigate("/onboarding");

              if (
                user_data.is_did_done === "YES" &&
                user_data.is_email_config_done === "YES" &&
                user_data.is_branding_done === "YES" &&
                user_data.is_user_done === "YES"
              ) {
                navigate("/");
              } else {
                navigate("/onboarding");
              }

              //window.location.reload();
            })
            .catch(() => {
              setLoading(false);
            });
        } else {
          if (user_data.role === "admin") {
            if (
              user_data.is_did_done === "YES" &&
              user_data.is_email_config_done === "YES" &&
              user_data.is_branding_done === "YES" &&
              user_data.is_user_done === "YES"
            ) {
              navigate("/");
            } else {
              navigate("/onboarding");
            }
          }
          if (user_data.role === "superadmin") {
            navigate("/");
          } else {

            console.log(user_data);
            if (
              user_data.is_did_done === "YES" &&
              user_data.is_email_config_done === "YES" &&
              user_data.is_password_done === "YES" &&
              user_data.is_e911_done === "YES"
            ) {
              console.log('12345678901234567890');
              navigate("/");
            }else{
              console.log('abcdefghijklmno');
              navigate("/user-onboarding");
            }
            
          }

          //window.location.reload();
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  if (isLoggedIn) {
    //localStorage.clear();
    navigate("/", { replace: true });
  }

  function handleClick() {
    navigate("/register");
    window.location.reload();
  }

  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <div className="authincation h-100 p-meddle">
      {loading && <Loader />}
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <h4 className="text-center mb-4">Sign in your account</h4>
                    <form action="" onSubmit={(e) => submitHandler(e)}>
                      <div
                        className={`form-group ${
                          !isEmailValid ? "input-warning-o" : ""
                        }`}
                      >
                        <label className="mb-1">
                          <strong>Email</strong>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          name="Email"
                          value={email}
                          // onChange={handleBlur}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        {!isEmailValid && (
                          <span className="text-danger">{emailError}</span>
                        )}
                      </div>
                      <div
                        className={`form-group ${
                          !isPasswValid ? "input-warning-o" : ""
                        }`}
                      >
                        <label className="mb-1">
                          <strong>Password</strong>
                        </label>
                        <input
                          type={passwordType}
                          className="form-control"
                          name="password"
                          value={passw}
                          // onChange={handleBlur}
                          onChange={(e) => setPassw(e.target.value)}
                          placeholder="Password"
                        />
                        <div className="input-group-btn">
                          <button
                            type="button"
                            className="btn btn-outline-warning pass_in"
                            onClick={togglePassword}
                          >
                            {passwordType === "password" ? (
                              <i className="fa fa-eye-slash"></i>
                            ) : (
                              <i className="fa fa-eye"></i>
                            )}
                          </button>
                        </div>
                        {!isPasswValid && (
                          <span className="text-danger">{passError}</span>
                        )}
                      </div>

                      {message && (
                        <div className="form-group">
                          <div className="alert alert-danger" role="alert">
                            {message}
                          </div>
                        </div>
                      )}

                      <div className="form-row d-flex justify-content-between mt-4 mb-2">
                        <div className="form-group">
                          <div className="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="basic_checkbox_1"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="basic_checkbox_1"
                            >
                              Remember my preference
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <Link to="/forgot-password">
                            Forgot Password?
                          </Link>
                        </div>
                      </div>
                      <div className="text-center">
                        <input
                          type="submit"
                          value="Sign Me In"
                          className="btn btn-primary btn-block"
                        />
                      </div>
                    </form>
                    <div className="new-account mt-3">
                      <p>
                        Don't have an account?{" "}
                        <Link className="text-primary" onClick={handleClick}>
                          Sign up
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
