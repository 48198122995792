import React, { Fragment, useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
 import { getCustomContactFormFields, createBulkUpdateField } from "../../../actions/customContactLayout";

function BulkUpdateFieldForm(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const [errors, setErrors] = useState({
    form_field: "",
  }); 
  
  const [customFields, setCustomFields] = useState([]); 

  const [fieldData, setFieldData] = useState({
    form_field: "",
    createdby: createdby,
    main_user_uuid: main_uuid,
    user_uuid: createdby,
});

  const dispatch = useDispatch();

  useEffect(() => {

    dispatch(
      getCustomContactFormFields(
        createdby,
        token
      )
    )
      .then((response) => {
        setCustomFields(response.data);
        return;
      })
      .catch(() => {
      });
  }, []);

  const handleBlur = (e) => {
    const newFieldData = { ...fieldData };
    let error_data = { ...errors };
    
    if(e.target.value.length === 0){
        error_data[e.target.name] = "Field is required.";
        setErrors(error_data);
    }else{
        error_data[e.target.name] = "";
        setErrors(error_data);
    }
    newFieldData[e.target.name] = e.target.value;
    setFieldData(newFieldData);  
  };

  const closeModel = (arg) => {
    props.closeBulkUpdateModel(arg);
  }
  
  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";
    
    if(fieldData.form_field.length === 0){
        error_data['form_field'] = "Field is required.";
        setErrors(error_data);
        isError = "true";
    }

    if(isError === "true"){
      return;
    }else{

      dispatch(createBulkUpdateField(fieldData,token))
      .then(() => {
          closeModel(false);
          props.handleBulkUpdateFieldEvent(Math.random);
      })
      .catch(() => {
        
      });
    }
   
  }

  return (
    <Fragment>

      <Modal.Header>
        <Modal.Title>Add New Field To bulk Update form</Modal.Title>
        <Button
            variant=""
            className="close"
            onClick={() => closeModel(false)}
        >
            <span>&times;</span>
        </Button>
      </Modal.Header>

      <form action="" onSubmit={(e) => submitHandler(e)}>
        <Modal.Body>

          <div className="form-group input-primary">
              <label className="mb-1">
                  <strong>Select Field</strong>
              </label>
              <select className="form-control bg-white" onChange={handleBlur} name="form_field">
              <option value="">Select</option>
                <optgroup label="Main Fields">
                  <option value="contact_type">Contact Type</option>
                  <option value="job_title">Job Title</option>
                  <option value="job_industry">Job Industry</option>
                  <option value="bussiness_name">Bussiness Name</option>
                  <option value="bussiness_site">Bussiness Site</option>
                  <option value="assign_to">Assign To</option>
                  <option value="address_1">Address 1</option>
                  <option value="address_2">Address 2</option>
                  <option value="country">Country</option>
                  <option value="state">State</option>
                  <option value="city">City</option>
                  <option value="zipcode">Zipcode</option>
                </optgroup>
                <optgroup label="Custom Fields">
                  {customFields.map((val) => (
                        <option key={val.custom_field_uuid} value={val.custom_field_uuid}> {val.field_name} </option>
                  ))}
                </optgroup>
              </select>
              <span className="text-danger">{errors.section_title}</span>
          </div>

        </Modal.Body>
        <Modal.Footer>
            <Button
                onClick={() => closeModel(false)}
                variant="danger light btn-sm"
            >
                Close
            </Button>
            <Button type="submit" variant="primary btn-sm">Save</Button>
        </Modal.Footer>
      </form>
    </Fragment>
  );
}

export default BulkUpdateFieldForm;
