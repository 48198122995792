import React, {Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Table, Modal } from "react-bootstrap";
import {
  getTasksList,
  deleteTask
} from "../../actions/Tasks";
import Loader from "../../layouts/Loader";
import TaskForm from "./TaskForm";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import { confirmAlert } from "react-confirm-alert";

const Tasks = ({contact}) => {

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const [taskModal, setTaskModal] = useState(false);
  const [contactTasks, setContactTasks] = useState([]);
  const [taskEvent, setTaskEvent] = useState("");

  const [taskUpdate, setTaskUpdate] = useState({
    contact_task_uuid: "",
    task_title: "",
    task_date: "",
    task_time: "",
    description: "",
    assign_user: "",
    status : "",
    contact_uuid : contact.contact_uuid,
    action: "add",
  });
  const [loading, setLoading] = useState(false);
  
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getTasksList(contact.contact_uuid, main_uuid, createdby, token))
      .then((response) => {
        setContactTasks(response.data);
        return;
      })
      .catch(() => {
        //setLoading(false);
      });
  }, [contact,taskEvent]);

  const fndeleteTask = (uuid) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this Task?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteTaskService(uuid),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteTaskService = (uuid) => {
    setLoading(true);
    dispatch(deleteTask(uuid, createdby, token))
      .then((response) => {
        if (response.status === 200) {
          notifyInfo(response.message);
          setTaskEvent(uuid);
        } else if (response.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const addTask = (e) => {
    
    let data = {...taskUpdate}
    data['contact_task_uuid'] = '';
    data['task_title'] = '';
    data['task_date'] = '';
    data['task_time'] = '';
    data['description'] = '';
    data['assign_user'] = '';
    data['status'] = '';
    data['action'] = 'add';

    setTaskUpdate(data);
    setTaskModal(true);
};

  const updateTask = (task) => {
        let data = {...taskUpdate}
        data['contact_task_uuid'] = task.contact_task_uuid;
        data['task_title'] = task.task_title;
        data['task_date'] = task.task_date;
        data['task_time'] = task.task_time;
        data['description'] = task.description;
        data['assign_user'] = task.assign_user;
        data['status'] = task.status;
        data['contact_uuid'] = task.contact_uuid;
        data['action'] = 'update';

        setTaskUpdate(data);
        setTaskModal(true);
  };

  const closeModel = (value) => {
    setTaskModal(value);
  };

  const handleTaskEvent = () => {
    setTaskEvent(Math.random);
  }

  return (
    <Fragment>
      {loading && <Loader />}
      <div className="card">
        <div className="card-body">
        
          <Table responsive className="w-100">
            <thead>
              <tr role="row">
                <th>Task</th>
                <th>Description</th>
                <th>Status</th>
                <th>Assign User</th>
                <th>Task Date</th>
                <th>
                  <Link
                    to="#!"
                    onClick={(e) =>addTask(e)}
                    className="btn btn-primary shadow btn-xs"
                  >
                    <i className="fa fa-plus"></i>
                  </Link>
                </th>
              </tr>
            </thead>

            {contactTasks && contactTasks.map((task) => (
                <tbody key={task.contact_task_uuid}>
                  <tr role="row">
                    <td>{task.task_title}</td>
                    <td>{task.description}</td>
                    <td>
                      {task.status === 'OPEN' && <p className="text-info">Open</p>}
                      {task.status === 'IN_PROGRESS' && <p className="text-warning">In Progress</p>}
                      {task.status === 'CLOSE' && <p className="text-green">Close</p>}
                    </td>
                    <td>{task.assign_user_name}</td>
                    <td>{task.task_date} {task.task_time}</td>
                    <td>
                      <Link
                        to="#!"
                        onClick={() =>
                          updateTask(task)
                        }
                        className="btn btn-primary shadow btn-xs sharp mr-1"
                      >
                        <i className="fa fa-pencil"></i>
                      </Link>
                      <Link
                        to="#!"
                        onClick={() =>
                          fndeleteTask(task.contact_task_uuid)
                        }
                        className="btn btn-danger shadow btn-xs sharp"
                      >
                        <i className="fa fa-trash"></i>
                      </Link>
                    </td>
                  </tr>
                </tbody>
            ))}

          </Table>
        </div>
      </div>

      <Modal className="fade" show={taskModal}>
        <TaskForm taskUpdate={taskUpdate} closeModel={closeModel} handleTaskEvent={handleTaskEvent}/>
        
      </Modal>

    </Fragment>    
  );
};

export default Tasks;
