import React, { useState } from "react";
import {
  Card,
  Tab,
  Button,
  Col,
  Modal,
  Nav,
  Table,
  Badge,
} from "react-bootstrap";

import "rc-tooltip/assets/bootstrap.css";
import Tooltip from "rc-tooltip";
import TermAndConditions from "./TermAndConditions";

const StepTwo = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const plan_type = user_data.plan_type;

  let plan_type_cap = "";
  let months = 0;
  if (plan_type === "quarterly") {
    months = 3;
    plan_type_cap = "Quarterly";
  } else {
    months = 12;
    plan_type_cap = "Annual";
  }

  let didsData = props.didsData;
  let type = props.type;

  const [basicModal, setBasicModal] = useState(false);

  const closeModel = (value) => {
    setBasicModal(value);
  };

  let toll_free_num = 0;
  let toll_free_num_price = 0.0;
  let local_num = 0;
  let local_num_price = 0.0;
  let total_num = 0;
  let total_price = 0.0;
  let setup_price = 3;
  let monthly_price = 5;
  let grand_total = 0;

  let number_type = "";

  didsData.forEach((index) => {
    total_num = total_num + 1;
    total_price =
      parseFloat(total_price) + parseFloat(index.cost_information.upfront_cost);

    if (index.phone_number_type === "toll_free") {
      number_type = "Toll Free Number";
      toll_free_num = toll_free_num + 1;
      toll_free_num_price =
        parseFloat(toll_free_num_price) +
        parseFloat(index.cost_information.upfront_cost);
    } else {
      number_type = "Local Number";
      local_num = local_num + 1;
      local_num_price =
        parseFloat(local_num_price) +
        parseFloat(index.cost_information.upfront_cost);
    }
  });

  let total_num_before_free = total_num;

  if (type === "normal") {
    
  } else {
    total_num = total_num - 1;
  }

  let setup_total_price = setup_price * total_num;
  let did_total_price = monthly_price * total_num;
  let did_grand_total_price = did_total_price * months;

  grand_total = did_grand_total_price + setup_total_price;

  function selectMoreNumber(data) {
    let newCampaignData1 = {};
    newCampaignData1.selected_dids = data;
    newCampaignData1.status = "mode_select";
    props.handleSelectMoreNumber(newCampaignData1);
  }

  function removeNum(data, i) {
    const filterDid = didsData.filter((didss) => {
      return didss.phone_number !== data;
    });
    props.handleRemovedNumber(filterDid);
  }

  function getState(did_data) {
    return Object.values(did_data).filter(k => k.region_type === "state").map((k) => {
      //if (k.region_type === "state") {
        return k.region_name;
      //}
    });
  }

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>
            <a className="timeline-panel text-muted" href="/react/widget-basic">
              <h6 className="mb-0">
                {props.type === "user_onboarding"
                  ? number_type
                  : "local_num local numbers and" +
                    toll_free_num +
                    "Toll Free numbers. Total " +
                    total_num_before_free +
                    " numbers."}
              </h6>
            </a>
          </Card.Title>
        </Card.Header>
      </Card>

      <Col lg={12}>
        <Card>
          <Card.Header>
            <Card.Title>
              Selected {props.type === "user_onboarding" ? "Number" : "Numbers"}(
              {total_num_before_free})
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <div className="row">
              <div
                className={
                  props.type === "user_onboarding" ? "col-xl-12" : "col-xl-9"
                }
              >
                <Table responsive>
                  <thead>
                    <tr>
                      <th>
                        <strong>Number</strong>
                      </th>
                      <th>
                        <strong>Type</strong>
                      </th>
                      <th>
                        <strong>State</strong>
                      </th>
                      {props.type === "user_onboarding" ? (
                        <></>
                      ) : (
                        <>
                          <th className="width80">
                            <strong>Setup (*NRC)</strong>
                          </th>
                          <th>
                            <strong>Monthly (**MRC)</strong>
                          </th>
                          <th>
                            <strong>Action</strong>
                          </th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {didsData.map((did, i) => (
                      <tr key={i}>
                        <td>
                          <strong>{did.phone_number}</strong>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="w-space-no">
                              {did.phone_number_type === "toll_free" ? (
                                <span> Toll Free </span>
                              ) : (
                                <span> Local </span>
                              )}
                            </span>
                          </div>
                        </td>
                        <td>
                          {did.phone_number_type === "toll_free" ? (
                            <span> - </span>
                          ) : (
                            <spam>{getState(did.region_information)}</spam>
                          )}
                        </td>
                        {props.type === "user_onboarding" ? (
                          <></>
                        ) : (
                          <>
                            <td className="td_width">
                              <span>
                                {" "}
                                ${setup_price}
                                {i === 0 && type === "onboarding" ? (
                                  <Badge variant="info light" className="ml-1">
                                    (Included)
                                  </Badge>
                                ) : (
                                  ""
                                )}
                              </span>
                            </td>
                            <td className="td_width">
                              <span>
                                {" "}
                                ${monthly_price}{" "}
                                {i === 0 && type === "onboarding" ? (
                                  <Badge variant="info light">(Included)</Badge>
                                ) : (
                                  ""
                                )}
                              </span>
                            </td>

                            <td>
                              <div className="d-flex">
                                <div className={"btn_" + i}>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      removeNum(did.phone_number, i)
                                    }
                                    className="btn btn-sm btn-danger"
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            </td>
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {props.type === "user_onboarding" ? (
                  <></>
                ) : (
                  <>
                    <div className="row bottum_fix">
                      <div className="col-xl-9">*Non-Recurring Charge</div>
                      <div className="col-xl-9">**Monthly Recurring Charge</div>
                    </div>
                  </>
                )}
              </div>
              {props.type === "user_onboarding" ? (
                <></>
              ) : (
                <>
                  <div className="col-xl-3 cart_backgournd">
                    <Tab.Container defaultActiveKey={plan_type}>
                      <Nav
                        as="ul"
                        className="nav-pills justify-content-center mt-2"
                      >
                        <Nav.Item as="li" key={plan_type}>
                          <Nav.Link
                            eventKey={plan_type}
                            className="p-1 pl-3 pr-3"
                          >
                            <Tooltip
                              placement="top"
                              overlay={<span>Current Billing Cycle</span>}
                            >
                              <span>{plan_type_cap}</span>
                            </Tooltip>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>

                      {/* <Tab.Content className="pt-4">
                    <Tab.Pane eventKey={plan_type} key={plan_type}> */}
                      <ul className="list-group">
                        <li className="list-group-item item1 d-flex justify-content-between lh-condensed">
                          <div>
                            <h5 className="">
                              {total_num} DID's
                              <span>
                                <i className="mdi mdi-close"></i>
                              </span>
                              $3 setup
                            </h5>
                          </div>
                          <h5 className="text-center">${setup_total_price}</h5>
                        </li>
                        <li className="list-group-item item1 d-flex justify-content-between lh-condensed">
                          <div>
                            <h5 className="">
                              {total_num} DID's
                              <span>
                                <i className="mdi mdi-close"></i>
                              </span>
                              $5 monthly
                            </h5>
                          </div>
                          <h5 className="text-center">${did_total_price}</h5>
                        </li>
                        <li className="list-group-item item1 d-flex justify-content-between lh-condensed">
                          <div>
                            <h5 className="">
                              ${did_total_price}
                              <span>
                                <i className="mdi mdi-close"></i>
                              </span>
                              {months} months
                            </h5>
                          </div>
                          <h5 className="text-center">
                            ${did_grand_total_price}
                          </h5>
                        </li>
                        {/* <li className="list-group-item item1 d-flex justify-content-between lh-condensed">
                          <div>
                            <h5 className="">Total Amount</h5>
                          </div>
                          <h5 className="text-center">
                            ${grand_total_with_out_dis}
                          </h5>
                        </li>
                        <li className="list-group-item item1 d-flex justify-content-between lh-condensed">
                          <div>
                            <h5 className="">1 DID Free(Included)</h5>
                          </div>
                          <h5 className="text-center">
                            - ${one_did_price_total}
                          </h5>
                        </li> */}
                        <hr className="hr_class" />
                        <div>
                          <h4 className="">
                            {" "}
                            Total:{" "}
                            <span className="pull-right">
                              ${grand_total}(USD)
                            </span>
                          </h4>
                        </div>
                        <div></div>
                      </ul>
                      {/* </Tab.Pane>
                  </Tab.Content> */}
                    </Tab.Container>
                  </div>
                </>
              )}
            </div>
          </Card.Body>
        </Card>
      </Col>

      <div className="text-center">
        <Button
          variant="primary"
          className="btn btn-sm btn-primary mr-2"
          onClick={() => setBasicModal(true)}
        >
          Buy
        </Button>

        <button
          type="button"
          onClick={() => selectMoreNumber(didsData)}
          className="btn btn-sm btn-primary"
        >
          {props.type === "user_onboarding" ? "Change Number" : "Select More"}
        </button>
      </div>

      <Modal className="fade bd-example-modal-lg" show={basicModal} size="lg">
        <TermAndConditions
          closeModel={closeModel}
          didsData={didsData}
          grand_total={grand_total}
          type={type}
          handleSendSmsEvent={""}
        />
      </Modal>
    </>
  );
};

export default StepTwo;
