import React, { Fragment, useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css";
import PageTitle from "../../layouts/PageTitle";
import ContactDetail from "../contactComman/ContactDetail";
import { getContactFormLayout } from "../../actions/customContactLayout";
import Loader from "../../layouts/Loader";
import {
  getDialToContact,
  startPowerdialCall,
} from "../../actions/callcampaign";

function PowerDial() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const params = useLocation();
  const call_campaign_uuid = params.state.call_campaign_uuid;

  const [formData, setFormData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dialContact, setDialContact] = useState({
    custom_field_data : {},
    dependent_field : "NO",
    dependent_field_data: {},
  });
  const [wrapUpTime, setWrapUpTime] = useState(0);
  const [showwrapUp, setShowwrapUp] = useState(false);
  const [nextContact, setNextContact] = useState('');
  const [startCallBtn, setStartCallBtn] = useState(true);

  let wrapUpTimer = wrapUpTime;

  const dispatch = useDispatch();
  const Ref = useRef(null);
  
  useEffect(() => {
    dispatch(
      getDialToContact(
        main_uuid,
        call_campaign_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        const newarr = { ...response.data };

        let custom_field_data = [];
        let dependent_field_data = [];

        response.data[0].custom_field_data.map((field) => {
          
          custom_field_data[field.custom_field_uuid] = {
            custom_field_value_uuid : field.custom_field_value_uuid,
            field_value : field.field_value,
            contact_dependent_filed_value_uuid : field.contact_dependent_filed_value_uuid,
            dependent_value : field.dependent_value,
          };

          dependent_field_data[field.contact_custom_dependent_field_uuid] = {
            contact_custom_dependent_field_uuid : field.contact_custom_dependent_field_uuid,
            main_field_uuid : field.custom_field_uuid,
            value : field.dependent_value,
          }; 

          return true;
        })

        newarr["custom_field_data"] = custom_field_data;
        newarr["dependent_field_data"] = dependent_field_data;

        setDialContact(newarr);
        setWrapUpTime(response.data.wrap_up_time);
        setShowwrapUp(false);
        setStartCallBtn(true);
        //setPowerDialEvent("");
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  },[nextContact]);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getContactFormLayout(
        main_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        //console.log(response);
        setFormData(response.data);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);


  const startCall = () => {

    //loadNextContact();
    
    dispatch(
      startPowerdialCall(
        main_uuid,
        call_campaign_uuid,
        createdby,
        dialContact.work_contact_number,
        token
      )
    )
    .then((response) => {
      setStartCallBtn(false);
      return;
    })
    .catch(() => {
      setLoading(false);
    });

  }

  const startTimer = () => {

    if (wrapUpTimer > 0) {

        // update the timer
        // check if less than 10 then we need to
        // add '0' at the beginning of the variable
        setWrapUpTime(prevWrapUpTime => prevWrapUpTime - 1);

        wrapUpTimer = wrapUpTimer - 1;
    }else{
      if (Ref.current)clearInterval(Ref.current); 

      setNextContact(Math.random());
    }
  }

  window.loadNextContact = () => {
    setWrapUpTime(dialContact.wrap_up_time);
    setShowwrapUp(true);
    setStartCallBtn(false);

    if (Ref.current)clearInterval(Ref.current); 
  
    const id = setInterval(() => {
      startTimer();
  }, 1000)
  Ref.current = id;
   
  }
  

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle activeMenu="Power Dial Campaign" motherMenu="Call" />
     
        <>
        <div className="card">
          <div className="card-header border-0 pb-0">
            {/* <h4 className="card-title">Power Dial Campaign Contact : {call_campaign}</h4> */}

            <h5 className="card-title">Contact Detail</h5>

            {(startCallBtn)? 
            
            <Button
              type="button"
              className="btn btn-success btn-sm"
              onClick={() => startCall()}                    
            >
              <i className="fa fa-phone" aria-hidden="true"></i> Dial
            </Button> : 
            ""}

            {(showwrapUp)? <p>Time to next Lead : {wrapUpTime}</p> : ''}                  
            
          </div>

          <div className="card-body">

            <ContactDetail contactDetail={dialContact} formData={formData} />

          </div>
        </div>
        </>
      
    </Fragment>
  );
}

export default PowerDial;
