import React, { Fragment, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../../layouts/PageTitle";
import { getUserListDropdown } from "../../../actions/comman";
import PermissionCheck from "../../../common/PermissionCheck";
import Loader from "../../../layouts/Loader";
import { updateBulkContact } from "../../../actions/contacts";

function BulkReassignContactForm() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;

  let listing_per = PermissionCheck("did", "listing", "", "", "");

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }

  const params = useLocation();
  const contacts = params.state.contacts;

  const [users, setUsers] = useState([]);

  const [contactData, setContactData] = useState({
    contacts: contacts,
    contact_type: "",
    bussiness_name: "",
    contact_title: "",
    first_name: "",
    last_name: "",
    email: "",
    work_contact_number: "",
    other_contact_number: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
    address: "",
    address2: "",
    assign_user: "",
    token: token,
    createdby: createdby,
    main_user_uuid: createdby,
    user_uuid: createdby,
  });

  const [errors, setErrors] = useState({
    assign_user: "",
  });
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBlur = (e) => {
    const newContactData = { ...contactData };
    let error_data = { ...errors };

    if (e.target.value.length === 0) {
      error_data[e.target.name] = "Please select assign user.";
      setErrors(error_data);
    }
    
    newContactData[e.target.name] = e.target.value;
    setContactData(newContactData);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    if (contactData.assign_user.length === 0) {
      error_data["assign_user"] = "Please select assign user.";
      setErrors(error_data);
      isError = "true";
    }
    setLoading(true);

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create user API
      dispatch(updateBulkContact(contactData))
        .then(() => {
          navigate("/contacts", { replace: true });
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };


  useEffect(() => {
    dispatch(
      getUserListDropdown(
        "create",
        "",
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        setUsers(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Fragment>
       {loading && <Loader />}
      <PageTitle activeMenu="Bulk Update Contact" motherMenu="Contacts" />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Bulk Assign Contact</h4>
              <div >
                <div className="btn-group pull-right">
                  <Button
                    type="button"
                    className="btn btn-default btn-sm"
                    onClick={() => navigate("/contacts", { replace: true })}
                  >
                    <i className="fa fa-reply" aria-hidden="true"></i> Back
                  </Button>
                </div>
              </div>
            </div>
            <div className="card-body">
              <form action="" onSubmit={(e) => submitHandler(e)}>
                
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Assign User</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="assign_user"
                        onChange={handleBlur}
                        value={contactData.assign_user}
                      >
                        <option value="">Select User</option>
                        {users.map((user) => (
                          <option key={user.uuid} value={user.uuid}>
                            {user.user_name}
                          </option>
                        ))}
                        <option key={createdby} value={createdby}>
                          Assign Me
                        </option>
                      </select>
                      <span className="text-danger">
                        {errors.assign_user}
                      </span>
                    </div>
                  </div>
                  
                </div>

                <div className="row text-right">
                  <div className="col-sm">
                    <Button type="submit" variant="primary" className="btn-sm">
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default BulkReassignContactForm;
