import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import "./index.css";
import { Card, Row, Button, Col, Modal } from "react-bootstrap";
import { userOnboardingAddNumber } from "../../../actions/did";
import { notifyInfo, notifyError } from "../../../components/Toastr/Toastr";
import Loader from "../../../layouts/Loader";

const ConfirmationPopup = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;

  const ddidData = {
    dids: "",
    createdby: createdby,
    main_admin_uuid: createdby,
    user_uuid: createdby,
  };

  const closeModel = (arg) => {
    props.closeModel(arg);
  };

  const [loading, setLoading] = useState(false);

  let didsData = props.didsData;
  let grand_total = props.grand_total;
  let type = props.type;

  function removeDuplicates(arr) {
    return [...new Set(arr)];
  }

  let number = [];
  let city = [];
  let area_code = [];
  let j = 0;

  didsData.forEach((did_data, i) => {
    number[i] = did_data.phone_number.substring(2, 12);

    area_code[i] = did_data.phone_number.substring(2, 5);

    if (did_data.phone_number_type === "local") {
      Object.values(did_data.region_information).map((k) => {
        if (k.region_type === "location") {
          city[j] = k.region_name;
          j = j + 1;
        }
        return true;
      });
    }
  });

  number = number.toString();
  city = city.toString();
  area_code = removeDuplicates(area_code);
  area_code = area_code.toString();
  city = removeDuplicates(city);

  const dispatch = useDispatch();

  function buyNumber(data) {
    setLoading(true);
    ddidData["dids"] = data;
    ddidData["grand_total"] = grand_total;
    ddidData["type"] = type;

    dispatch(userOnboardingAddNumber(ddidData, token))
      .then((res) => {
        if (res.status === 200) {
          notifyInfo(res.message);
          closeModel(false);
          setLoading(false);
          if (type === "onboarding") {
            user.data.data.is_did_done = "YES";
            localStorage.setItem("user", JSON.stringify(user));
            setLoading(false);
            window.location = "onboarding";
            // navigate("/onboarding", { replace: true });
          } else if (type === "user_onboarding") {
            user.data.data.is_did_done = "YES";
            localStorage.setItem("user", JSON.stringify(user));
            setLoading(false);
            window.location = "user-onboarding";
            // navigate("/onboarding", { replace: true });
          } else {
            setLoading(false);
            window.location = "inbound-numbers";
            // navigate("/inbound-numbers", { replace: true });
          }
        } else if (res.status === 500) {
          setLoading(false);
          notifyError("Something went wrong.");
        } else {
          setLoading(false);
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }

  return (
    <Fragment>
      {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>Confirmation Add Number</Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col xl="12">
            <Card className="">
              <Card.Body className="card-body">
                <div className="row">
                  <div className="col-12">
                    <p>Are you sure want add number.</p>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => closeModel(false)}
          className="btn-sm"
          variant="danger light"
        >
          Close
        </Button>
        <button
          type="button"
          onClick={() => buyNumber(didsData)}
          className="btn btn-sm btn-primary mr-2"
        >
          Add NUmber
        </button>
      </Modal.Footer>
    </Fragment>
  );
};

export default ConfirmationPopup;
