import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const getAvailabilityDetails = (user_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "user-availability/get-availability-details",
      {
        user_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getAvailabilityTimeDetails = (
  user_availability_data_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "user-availability/get-availability-time-details",
      {
        user_availability_data_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateTimeSlot = (data) => {
  return axios
    .post(API_URL + "user-availability/extension-update-time-slot", data, {
      headers: {
        Authorization: data.token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const deleteTimeSlot = (
  user_availability_times_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "user-availability/extension-delete-time-slot",
      {
        user_availability_times_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const createTimeSlot = (data) => {
  return axios
    .post(API_URL + "user-availability/create-time-slot", data, {
      headers: {
        Authorization: data.token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const copyTimeSlot = (
  type,
  select_day_uuid,
  createdby,
  main_admin_uuid,
  token
) => {
  console.log(type, "==type===");
  console.log(select_day_uuid, "===select_day_uuid==");
  console.log(createdby, "==createdby===");
  console.log(main_admin_uuid, "==main_admin_uuid===");
  console.log(token, "==token===");

  return axios
    .post(
      API_URL + "user-availability/copy-avalability-time-slot",
      {
        type,
        select_day_uuid,
        createdby,
        main_admin_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

export default {
  getAvailabilityDetails,
  getAvailabilityTimeDetails,
  updateTimeSlot,
  deleteTimeSlot,
  createTimeSlot,
  copyTimeSlot,
};
