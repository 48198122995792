import React from "react";

import { Link } from "react-router-dom";

/// Image
import profile from "../../../images/profile/pic1.jpg";
import "./index.css";
import PermissionsCheck from "./../../common/CheckTabPermisson";

/// images
import logo from "../../../images/Zongo.jpg";
/// Css
import "./index.css";

const Header = ({ toggle, onProfile, user_data }) => {
  let path = window.location.pathname.split("/");
  path = path[path.length - 1];
  path = path === "";

  function setActiveToggleButton(val) {
    localStorage.setItem("tab_value", val);
  }

  return (
    <div className="header header_onboarding bg-success">
      <div className="header-content">
        <div className="nav-header-login">
          <Link to="/" className="brand-logo">
            <img className="zongo_logo" src={logo} alt="" />
          </Link>
        </div>
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            {/* <div className="profile-tab"> */}
            <ul className="navbar-nav header-left">
              <li className="nav-item mr-2">
                {user_data.role === 'admin' && 
                  <a
                    href="/onboarding"
                    className="text-warning"
                    aria-expanded="false"
                    onClick={() => setActiveToggleButton("onboarding")}
                    hidden={PermissionsCheck("onboarding")}
                  >
                    <h3 className="text-white">Onboarding</h3>
                  </a>
                }

                {user_data.role !== 'admin' && 
                  <a
                    href="/user-onboarding"
                    className="text-warning"
                    aria-expanded="false"
                  >
                    <h3 className="text-white">Onboarding</h3>
                  </a>
                }
                
              </li>
            </ul>

            <ul className="navbar-nav header-right">
              <li className="nav-item dropdown header-profile header_profile_onboarding">
                <Link
                  className="nav-link"
                  to="#"
                  role="button"
                  onClick={(e) => {
                    onProfile();
                    e.preventDefault();
                  }}
                >
                  <div className="header-info">
                    <span>
                      {user_data.first_name} {user_data.last_name}
                    </span>
                  </div>
                  <img
                    src={profile}
                    width={15}
                    className="rounded-circle"
                    alt=""
                  />
                </Link>
                <div
                  className={`dropdown-menu dropdown-menu-right ${
                    toggle === "profile" ? "show" : ""
                  }`}
                >
                  <Link to="/app-profile" className="dropdown-item ai-icon">
                    <svg
                      id="icon-user1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                      <circle cx={12} cy={7} r={4} />
                    </svg>
                    <span className="ml-2">Profile </span>
                  </Link>

                  <Link
                    to="/logout"
                    className="dropdown-item ai-icon setting_topm"
                  >
                    <svg
                      id="icon-logout"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-danger"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                      <polyline points="16 17 21 12 16 7" />
                      <line x1={21} y1={12} x2={9} y2={12} />
                    </svg>
                    <span className="ml-2">Logout </span>
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
