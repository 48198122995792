import React from "react";

const ChatBox = ({ onClick, toggle }) => {

  return (
    <div className={`chatbox ${toggle === "chatbox" ? "active" : ""}`}>
      <div className="chatbox-close" onClick={() => onClick()}></div>
      <div className="custom-tab-1">
        <ul className="nav nav-tabs">
          <li className="nav-item">Notification</li>
        </ul>
        <div className="tab-content">Notification</div>
      </div>
    </div>
  );
};

export default ChatBox;
