import React, { Fragment,useState } from "react";
import moment from "moment";
import AttachMents from "./AttachMents";
import { Dropdown,Modal } from "react-bootstrap";
import ReplyMail from "./ReplyMail";
//import { DeleteMail } from "../../../actions/email/emails";


const Read = (props) => {

   const emailData = props.activeEmail;

   const [replyMailModal, setReplyMailModal] = useState(false);
   const [mailType, setMailType] = useState('');

   const deleteMail = (e) => {
      e.preventDefault();

      let uid = emailData.active_email_id;
      props.deleteMail(uid,'single')

   }

   const showForldersToMove = (e) => {
      e.preventDefault();
      props.handleMoveToFolderModel(true,'single');
   }

   const handleReplyMailModal = (value,mailType='') => {
      setReplyMailModal(value);
      setMailType(mailType);
    }

   return (
      <Fragment>
                     
         <div className="row">
            <div className="col-12">
               <div className="right-box-padding">
                  
                  <div className="read-content">
                     <div className="media pt-3 d-sm-flex d-block justify-content-between">
                        <div className="clearfix mb-3 d-flex">
                           
                           <div className="media-body mr-2">
                              <h5 className="text-primary mb-0 mt-1">
                                 
                              {emailData.from.value[0].name}({emailData.from.value[0].address})
                              
                              </h5>
                              <p className="mb-0">{moment(emailData.date).format("MM/DD/YYYY h:mm A")}</p>
                              <b>{emailData.subject}</b>

                              <p className="read-content-email">
                                 To: {emailData.to.value[0].name}({emailData.to.value[0].address})
                              </p>
                              <p className="read-content-email">
                                 ReplyTo: {emailData.replyTo && emailData.replyTo.value[0].address}
                              </p>

                           </div>
                        </div>
                        <div className="clearfix mb-3">

                           <Dropdown className="btn-group mb-1 pull-right mr-2">
                              <Dropdown.Toggle
                                 aria-expanded="false"
                                 data-toggle="dropdown"
                                 className="btn btn-primary btn-sm px-3 light ml-1"
                                 type="button"
                              >
                                 <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 24 24"
                                 >
                                    <g fill="none">
                                    <path d="M0 0H24V24H0z"></path>
                                    <circle cx="12" cy="5" r="2" fill="#000"></circle>
                                    <circle cx="12" cy="12" r="2" fill="#000"></circle>
                                    <circle cx="12" cy="19" r="2" fill="#000"></circle>
                                    </g>
                                 </svg>
                        
                                 
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu">
                                 <Dropdown.Item
                                    to="#"
                                    className="dropdown-item"
                                    onClick={(e) => handleReplyMailModal(true,'REPLY')}
                                 >
                                    Reply
                                 </Dropdown.Item>
                                 <Dropdown.Item
                                    to="#"
                                    className="dropdown-item"
                                    onClick={(e) => handleReplyMailModal(true,'REPLY')}
                                 >
                                    Reply To All
                                 </Dropdown.Item>
                                 <Dropdown.Item
                                    to="#"
                                    className="dropdown-item"
                                    onClick={(e) => handleReplyMailModal(true,'FORWARD')}
                                 >
                                    Forward
                                 </Dropdown.Item>
                                 <Dropdown.Item
                                    to="#"
                                    className="dropdown-item"
                                    onClick={(e) => showForldersToMove(e)}
                                 >
                                    Move To
                                 </Dropdown.Item>

                                 <Dropdown.Item
                                    to="#"
                                    className="dropdown-item"
                                    onClick={(e) => deleteMail(e)}
                                 >
                                   Delete
                                 </Dropdown.Item>
                                 
                                 
                              </Dropdown.Menu>
                           </Dropdown>

                        </div>
                     </div>
                     <hr />
                     
                     <div className="read-content-body">                       

                        <div className=" justify-center text-text items-center flex p-4">
                           <iframe width="100%" height="500"
                              srcDoc={emailData.html ? emailData.html : emailData.text}
                              className="w-full aspect-video border-0  text-text overflow-y-scroll h-full  body"
                              title="email-body"
                           />
                        </div>

                     </div>
                     <div className="read-content-attachment">
                        
                        <div className="row attachment">

                        {emailData.attachments && emailData.attachments.map((files,index) => {
                           
                           return (
                              <div className="col-auto" key={index}>
                                 <AttachMents
                                    file={files}
                                 />
                              </div>
                           );
                           
                        })}


                        </div>
                     </div>
                  </div>
                 
               </div>
            </div>
         </div>

         {/* <!-- reply mail --> */}
         <Modal className="fade" show={replyMailModal} size='lg'>
            <ReplyMail handleReplyMailModal={handleReplyMailModal} emailData={emailData} mailType={mailType} />
         </Modal>

      </Fragment>
   );
};

export default Read;
