import React, { Fragment, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../../layouts/PageTitle";
import { insertCsvData } from "../../../actions/contacts";
import { getUserList } from "../../../actions/user";
import { confirmAlert } from "react-confirm-alert";
import { notifyInfo, notifyError } from "../../Toastr/Toastr";
import Loader from "../../../layouts/Loader";

function MappingFieldsConfirm(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;

  // const [headerName, setHeaderName] = useState([]);
  // const [fieldsName, setFieldsName] = useState([]);
  const [users, setUsers] = useState([]);
  const params = useLocation();
  const filename = params.state.filename;
  const mappingfields = params.state.mappingfields;
  const data_count = params.state.data_count;
  const total_record = data_count.data;
  const message = data_count.message;

  const [checkVal, setCheckVal] = useState(false);

  if (total_record === 0) {
    // setCheckVal(true);
  }

  const [contactData, setContactData] = useState({
    filename: filename,
    mappingfields: mappingfields,
    assignto: "",
    duplicate: checkVal,
    token: token,
    createdby: createdby,
    main_admin_uuid: createdby,
    user_uuid: createdby,
  });

  const duplicateContact = (record) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this role?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRoleService("true"),
        },
        {
          label: "No",
          onClick: () => deleteRoleService("false"),
        },
      ],
    });
  };

  const deleteRoleService = (record) => {
    if (record === "true") {
      setCheckVal(true);
      contactData["duplicate"] = true;
    } else {
      contactData["duplicate"] = false;
      setCheckVal(false);
    }
  };

  const [errors, setErrors] = useState({
    recording_name: "",
    file: "",
  });

  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBlur = (e) => {
    const newRecordingData = { ...contactData };
    let error_data = { ...errors };

    if (
      (e.target.name === "recording_name" || e.target.name === "file") &&
      e.target.value.length === 0
    ) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }

    newRecordingData[e.target.name] = e.target.value;
    setContactData(newRecordingData);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (contactData.assignto.length === 0) {
      error_data["user"] = "Please select to assign to.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create insert csv data API
      dispatch(insertCsvData(contactData))
        .then((response) => {
          if (response.status === 200) {
            notifyInfo(response.message);
            navigate("/contacts", { replace: true });
          } else if (response.status === 500) {
            notifyError("Something went wrong.");
          } else {
            notifyError("Something went wrong.");
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  // useEffect(() => {
  //   dispatch(getMappingFields(createdby, token, filename))
  //     .then((response) => {
  //       // setHeaderName(response.data.csvheader);
  //       // setFieldsName(response.data.dbfieldsname);
  //       return;
  //     })
  //     .catch(() => {
  //       setLoading(false);
  //     });
  // }, []);

  useEffect(() => {
    dispatch(getUserList(createdby, token))
      .then((response) => {
        setUsers(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle activeMenu="Mapping Fields" motherMenu="Contact" />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Mapping Fields</h4>
              <Button
                type="button"
                className="btn btn-default btn-sm"
                onClick={() => navigate("/contacts", { replace: true })}
              >
                <i className="fa fa-reply" aria-hidden="true"></i> Back
              </Button>
            </div>
            <div className="card-body">
              <form action="" onSubmit={(e) => submitHandler(e)}>
                <div className="row">
                  <div className="col-sm">
                    <div className="form-group">
                      <input
                        type="checkbox"
                        defaultChecked={checkVal}
                        // onChange={handleChange}
                        onChange={() => duplicateContact(checkVal)}
                        name="ter"
                        id="chk"
                        checked={checkVal}
                        className="form-check-input1"
                      />
                      <label className="ml-3 mb-1">
                        <strong>{message}</strong>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Assign To</strong>
                      </label>
                      <select
                        className="form-control"
                        name="assignto"
                        onChange={handleBlur}
                      >
                        <option value="">Select Assign To</option>
                        {users &&
                          users.map((user) => (
                            <option key={user.uuid} value={user.uuid}>
                              {user.first_name} {user.last_name}
                            </option>
                          ))}
                      </select>
                      <span className="text-danger">{errors.assignto}</span>
                    </div>
                  </div>
                </div>

                <div className="row text-right">
                  <div className="col-sm">
                    <Button type="submit" variant="primary" className="btn-sm">
                      Insert Mapping Data
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default MappingFieldsConfirm;
