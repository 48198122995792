import React from "react";
import { useNavigate } from "react-router-dom";
import MultiStepForm from "./MultiStepForm";

const UserOnboarding = () => {
  let navigate = useNavigate();
 
  var user = JSON.parse(localStorage.getItem("user"));
  var user_data = user.data.data;

  if (user_data.role === "admin") {
    if (
      user_data.is_did_done === "YES" &&
      user_data.is_email_config_done === "YES" &&
      user_data.is_branding_done === "YES" &&
      user_data.is_user_done === "YES"
    ) {
      navigate("/");
    } else {
      navigate("/onboarding");
    }
  }

  // if (
  //   user_data.is_did_done === "YES" &&
  //   user_data.is_email_config_done === "YES" &&
  //   user_data.is_password_done === "YES" &&
  //   user_data.is_e911_done === "YES"
  // ) {
  //   navigate("/");
  // } else {
  //   // navigate("/onboarding");
  // }


  return (
    <>
      <div className="form-head d-flex mb-0 mb-lg-4 align-items-start">
        <div className="mr-auto">
          <h3 className="text-warning font-w500 mb-1">
            Welcome to Zongo! Follow the steps below to get started and complete
            your set up!
          </h3>
        </div>
      </div>
      <MultiStepForm />
    </>
  );
};

export default UserOnboarding;
