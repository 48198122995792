import React, { Fragment, useState, useEffect } from "react";
import { Button, Tab, Nav, Row } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../layouts/PageTitle";
import CalendarView from "./CalendarView";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import EasyEdit, { Types } from "react-easy-edit";
import {
  getTimeConditionDetail,
  updateTimeCondition,
  getBusinessHoursTimeDetails,
  createTimeSlot,
  deleteTimeSlot,
  updateTimeSlotWeekly,
  copyTimeSlot,
} from "../../actions/timeconditions";

import "./style.css";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import Loader from "../../layouts/Loader";
import UpdateDay from "./UpdateDay";

function UpdateTimeconditionForm(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const [timeSlotEvent, setTimeSlotEvent] = useState("");

  const [selectDay, setSelectDay] = useState({});
  const [defaultDay, setDefaultDay] = useState("monday");

  const [timeSlotOption, setTimeSlotOption] = useState({});
  const [addTimeSlot, setAddTimeSlot] = useState(false);

  const params = useLocation();
  const time_condition_uuid = params.state.time_condition_uuid;

  const [timeconditionsday, setTimeconditionsday] = useState([]);

  const [calendarEvent, setCalendarEvent] = useState("");

  const [allTimeSlot, setAllTimeSlot] = useState([]);

  const [timeconditionData, setTimeconditionData] = useState({
    time_condition_uuid: "",
    time_condition_name: "",
    createdby: createdby,
    main_admin_uuid: createdby,
    user_uuid: createdby,
  });

  const [errors, setErrors] = useState({
    time_condition_name: "",
    route_type: "",
    route_to: "",
    from_time: "",
    to_time: "",
    time_slot_time: "",
    time_validation_up: "",
  });

  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleToFrom = (e, data) => {
    let id = data.id;
    let name = e.target.name;
    let value = e.target.value;

    let from_time = "";
    let to_time = "";

    if (e.target.name === "from_time") {
      from_time = timeConvert24(value);
      to_time = data.to_time;
    }

    if (e.target.name === "to_time") {
      to_time = timeConvert24(value);
      from_time = data.from_time;
    }

    let isError = "false";
    const date = "2023-04-04";
    let error_data = { ...errors };
    let f_date = from_time;
    let t_date = to_time;

    const date1 = new Date(date + " " + f_date);
    const date2 = new Date(date + " " + t_date);

    if (date1.getTime() === date2.getTime()) {
      isError = "true";
      error_data["time_slot_time"] = "Time Slot must be 15 minute.";
      setErrors(error_data);
      return false;
    }

    if (date1.getTime() > date2.getTime()) {
      isError = "true";
      // error_data["to_time"] = {};
      // error_data["to_time"][select_id] =
      // "To time should be greater then from time.";
      error_data["time_validation_up"] =
        "To time should be greater then from time.";
      setErrors(error_data);
      return false;
    } else {
      error_data["time_validation_up"] = "";
      setErrors(error_data);
    }

    if (isTimeSlotAvailable(f_date, t_date, id)) {
      error_data["time_validation_up"] = "";
    } else {
      isError = "true";
      // error_data["to_time"] = {};
      // error_data["to_time"][select_id] = "Time slot is not available";
      error_data["time_validation_up"] = "Time slot is not available";
    }

    if (e.target.name === "from_time" && e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create user API
      setLoading(true);
      dispatch(
        updateTimeSlotWeekly(id, name, value, createdby, main_uuid, token)
      )
        .then((res) => {
          if (res.status === 200) {
            notifyInfo(res.message);
            setDefaultDay(defaultDay);
            setLoading(false);
            setTimeSlotEvent(Math.random);
          } else if (res.status === 406) {
            error_data["time_condition_name"] = res.message;
            setErrors(error_data);
            isError = "true";
            setLoading(false);
          } else if (res.status === 500) {
            setLoading(false);
            notifyError("Something went wrong.");
          } else {
            setLoading(false);
            notifyError("Something went wrong.");
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    dispatch(getTimeConditionDetail(createdby, token, time_condition_uuid))
      .then((response) => {

        if (selectDay.day_uuid === undefined) {
          getTimeSlot(
            response.data.time_condition_data[0].time_condition_data_uuid,
            response.data.time_condition_data[0].time_condition_day
          );
        } else {
          getTimeSlot(selectDay.day_uuid, selectDay.day);
        }

        setTimeconditionData(response.data.time_condition[0]);
        setTimeconditionsday(response.data.time_condition_data);
        //setIsDestination(true);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, [timeSlotEvent]);

  const copyExtTime = (type, uuid, day) => {
    let select_day_uuid = "";
    if (selectDay.day_uuid === "") {
      let select_data_data = { ...selectDay };

      select_data_data["day_uuid"] =
        timeconditionsday[0] !== undefined
          ? timeconditionsday[0].time_condition_data_uuid
          : "";

      select_day_uuid =
        timeconditionsday[0] !== undefined
          ? timeconditionsday[0].time_condition_data_uuid
          : "";

      select_data_data["day"] =
        timeconditionsday[0] !== undefined
          ? timeconditionsday[0].time_condition_day
          : "";
      select_data_data["time_condition_uuid"] =
        timeconditionData.time_condition_uuid;
      setSelectDay(select_data_data);
    } else {
      select_day_uuid = selectDay.day_uuid;
    }

    let type_msg = "";
    if (type === "copy_all_weekdays") {
      type_msg = "Copy All weekdays";
    } else if (type === "copy_to_all") {
      type_msg = "Copy To All";
    } else if (type === "copy_to_weekends") {
      type_msg = "Copy To Weekends";
    }

    confirmAlert({
      title: "",
      message: "Are you sure to " + type_msg + "?",
      buttons: [
        {
          label: "Yes",
          onClick: () => copyExtensionService(type, select_day_uuid),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const copyExtensionService = (type, select_day_uuid) => {
    dispatch(copyTimeSlot(type, select_day_uuid, createdby, main_uuid, token))
      .then((response) => {
        if (response.status === 200) {
          notifyInfo(response.message);
          setTimeSlotEvent(Math.random);
          return;
        } else if (response.status === 500) {
          // notifyError("Something went wrong.");
        } else {
          // notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getTimeSlot = (uuid, day) => {
    setDefaultDay(day);
    let select_data_data = { ...selectDay };
    select_data_data["day_uuid"] = uuid;
    select_data_data["day"] = day;

    setAddTimeSlot(false);
    setSelectDay(select_data_data);
    dispatch(getBusinessHoursTimeDetails(uuid, createdby, token))
      .then((response) => {
        setTimeSlotOption(response.data);

        let arr = response.data.filter(obj => obj.type === "default").map((obj, i) => {         
            return {
              id: obj.id,
              from_time: obj.from_time,
              to_time: obj.to_time,
            };
        });

        const filteredArray = arr.filter((element) => element);
        setAllTimeSlot(filteredArray);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (timeconditionsday.length > 0) {
      // getTimeSlot(
      //   timeconditionsday[0].time_condition_data_uuid,
      //   timeconditionsday[0].time_condition_day
      // );
    }
  }, [timeconditionsday]);

  const openAddTimeSlotform = (uuid, day) => {
    if (selectDay.day_uuid === "") {
      let select_data_data = { ...selectDay };
      select_data_data["day_uuid"] =
        timeconditionsday[0] !== undefined
          ? timeconditionsday[0].ext_availability_data_uuid
          : "";
      select_data_data["day"] =
        timeconditionsday[0] !== undefined
          ? timeconditionsday[0].ext_availability_day
          : "";
      // select_data_data["extension_uuid"] = extension_uuid;
      setSelectDay(select_data_data);
    }
    if (addTimeSlot === true) {
      setAddTimeSlot(false);
    } else {
      setAddTimeSlot(true);
    }
   
  };

  const createAvailTimeSlot = (timeData) => {

    let from_time = timeConvert24(timeData.from_time);
    let to_time = timeConvert24(timeData.to_time);

    let error_data = { ...errors };

    if (isTimeSlotAvailable(from_time, to_time)) {
      // Add the new time slot to the array
      setAllTimeSlot([...allTimeSlot, { from_time, to_time }]);
      error_data["time_slot_time"] = "";
      // You can also perform other actions here, e.g., save to a database
      //call create Avai lTime Slot API
      dispatch(createTimeSlot(timeData))
        .then((response) => {
          if (response.status === 200) {
            notifyInfo(response.message);
            // props.createAvailTimeSlot(timeData);
            setAddTimeSlot(false);
            setTimeSlotEvent(Math.random);
          } else if (response.status === 500) {
            notifyError("Something went wrong.");
          } else {
            notifyError("Something went wrong.");
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      error_data["time_slot_time"] = "Time slot is not available";
    }
    setErrors(error_data);
  };

  const save = (
    value,
    attribute,
    time_slot_uuid,
    from_time,
    to_time,
    route_type,
    route_to,
    time_slot_name,
    time_condition_data_uuid
  ) => {
    if (attribute === "route_and_destination") {
      if (
        value !== "ivr" &&
        value !== "extention" &&
        value !== "ring-group" &&
        value !== "did" &&
        value !== "voicemail" &&
        value !== "time_slot_name" &&
        value !== "time_condition"
      ) {
        value.from_time = from_time;
        value.to_time = to_time;
        value.time_condition_data_uuid = time_slot_uuid;
        value.time_slot_name = time_slot_name;

        updateTimeSlotWeeklyFun(
          time_slot_uuid,
          attribute,
          value,
          createdby,
          main_uuid,
          token
        );

        // dispatch(
        //   updateTimeSlotWeekly(
        //     time_slot_uuid,
        //     attribute,
        //     value,
        //     createdby,
        //     main_uuid,
        //     token
        //   )
        // )
        //   .then((res) => {
        //     if (res.status == "200") {
        //       notifyInfo(res.message);

        //       setDefaultDay(defaultDay);
        //       setTimeSlotEvent(Math.random);
        //     } else if (res.status == "406") {
        //       // error_data["time_condition_name"] = res.message;
        //       // setErrors(error_data);
        //       // isError = "true";
        //     } else if (res.status == "500") {
        //       notifyError("Something went wrong.");
        //     } else {
        //       notifyError("Something went wrong.");
        //     }
        //   })
        //   .catch(() => {
        //     setLoading(false);
        //   });
      }
    }

    if (attribute === "time_slot_name") {
      if (
        value !== "ivr" &&
        value !== "extention" &&
        value !== "ring-group" &&
        value !== "did" &&
        value !== "voicemail" &&
        value !== "time_slot_name" &&
        value !== "time_condition"
      ) {
        let datad = {
          from_time: from_time,
          to_time: to_time,
          time_condition_data_uuid: time_slot_uuid,
          time_condition_time_uuid: time_condition_data_uuid,
          route_type: route_type,
          route_to: route_to,
          time_slot_name: value,
        };

        updateTimeSlotWeeklyFun(
          time_slot_uuid,
          attribute,
          datad,
          createdby,
          main_uuid,
          token
        );
      }
    }
    // setAvailabilityData(data);
  };

  const updateTimeSlotWeeklyFun = (
    time_slot_uuid,
    attribute,
    value,
    createdby,
    main_uuid,
    token
  ) => {
    //call create user API
    setLoading(true);
    dispatch(
      updateTimeSlotWeekly(
        time_slot_uuid,
        attribute,
        value,
        createdby,
        main_uuid,
        token
      )
    )
      .then((res) => {
        if (res.status === 200) {
          notifyInfo(res.message);

          setDefaultDay(defaultDay);
          setLoading(false);
          setTimeSlotEvent(Math.random);
        } else if (res.status === 406) {
          // error_data["time_condition_name"] = res.message;
          // setErrors(error_data);
          // isError = "true";
          setLoading(false);
        } else if (res.status === 500) {
          setLoading(false);
          notifyError("Something went wrong.");
        } else {
          setLoading(false);
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const saveName = (value, attribute) => {
    let timeconditionDatanew = { ...timeconditionData };
    timeconditionDatanew[attribute] = value;
    timeconditionDatanew["createdby"] = createdby;
    let error_data = { ...errors };
    setLoading(true);

    dispatch(updateTimeCondition(timeconditionDatanew, token))
      .then((res) => {
        if (res.status === 200) {
          notifyInfo(res.message);
          setLoading(false);
          navigate(`/update-business-hours`, {
            state: { time_condition_uuid: time_condition_uuid },
          });
          error_data["time_condition_name"] = "";
          setErrors(error_data);
        } else if (res.status === 406) {
          error_data["time_condition_name"] = res.message;
          setErrors(error_data);
          setLoading(false);
        } else if (res.status === 500) {
          setLoading(false);
          notifyError("Something went wrong.");
        } else {
          setLoading(false);
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });

    // setAvailabilityData(data);
  };

 

  const fnDeleteTimeSlot = (uuid, time_condition_data_uuid) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this time block?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteTimeSlotService(uuid, time_condition_data_uuid),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteTimeSlotService = (uuid, time_condition_data_uuid) => {
    setLoading(true);
    dispatch(deleteTimeSlot(uuid, createdby, token))
      .then((response) => {
        if (response.status === 200) {
          // notifyInfo(response.message);
          // setDefaultDay(extension_availability_data_uuid);
          // changeExtensionEvent(Math.random);
          setLoading(false);
          setTimeSlotEvent(Math.random);
        } else if (response.status === 500) {
          setLoading(false);
          notifyError("Something went wrong.");
        } else {
          setLoading(false);
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  function conDateFormate(time) {
    const moment24 = moment(time, "HH:mm");

    return moment24.format("h:mm A");

  }

  function timeConvert24(time) {
    return moment(time, ["h:mm A"]).format("HH:mm");
  }

  const times = [];
  for (let i = 0; i < 24; i++) {
    times.push(
      `${i % 12 || 12}:00 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:15 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:30 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:45 ${i < 12 ? "AM" : "PM"}`
    );
  }

  const breadCurmList = [
    { label: "Calls", link: "/", is_active: "NO" },
    { label: "Time-Based Routing", link: "/business-hours", is_active: "NO" },
    {
      label:
        "Update Time-Based Routing -" + timeconditionData.time_condition_name,
      link: "/",
      is_active: "YES",
    },
  ];

  const isTimeSlotAvailable = (from_time, to_time, id = "") => {
    let time_array = allTimeSlot;

    if (id !== "") {
      time_array = time_array.filter((element) => element.id !== id);
    }
    for (const slot of time_array) {
      if (
        (from_time >= slot.from_time && from_time < slot.to_time) ||
        (to_time > slot.from_time && to_time <= slot.to_time)
      ) {
        return false; // Time slot is already occupied
      }
    }
    return true; // Time slot is available
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                Call Schedule Name - {timeconditionData.time_condition_name}
              </h4>
              <Button
                type="button"
                className="btn btn-default btn-sm"
                onClick={() => navigate("/business-hours", { replace: true })}
              >
                <i className="fa fa-reply" aria-hidden="true"></i> Back
              </Button>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="mb-1">
                      <strong>Call Schedule Name</strong>
                      <span className="text-danger">*</span>
                    </label>
                    <EasyEdit
                      type={Types.TEXT}
                      onSave={(value) => {
                        saveName(value, "time_condition_name");
                      }}
                      saveButtonLabel={
                        <i className="fa fa-check" aria-hidden="true"></i>
                      }
                      saveButtonStyle="label-primary easy-edit-button rounded-lg"
                      cancelButtonLabel={
                        <i className="fa fa-times" aria-hidden="true"></i>
                      }
                      viewAttributes={{
                        className: "inline_edit_div h-75",
                      }}
                      cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                      attributes={{
                        name: "from_time",
                        className: "form-control h-75",
                      }}
                      placeholder="Call Schedule Name"
                      value={timeconditionData.time_condition_name}
                    />
                    <span className="text-danger">
                      {errors.time_condition_name}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                
                    <div className="default-tab">
                      <Tab.Container defaultActiveKey="Home">
                        {/* <Schedule /> */}
                        <Nav as="ul" className="nav-tabs">
                          <Nav.Item as="li">
                            <Nav.Link eventKey="Home">
                              <i className={`la la-clock mr-2`} />
                              Weekly Schedule
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item as="li">
                            <Nav.Link
                              eventKey="Profile"
                              onClick={() => setCalendarEvent(Math.random)}
                            >
                              <i className={`la la-calendar mr-2`} />
                              Holiday & Specifics
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                        <Tab.Content className="pt-12">
                          <Tab.Pane eventKey="Home">
                            <div className="card-header">
                              <h4 className="card-title">Weekly Schedule</h4>
                            </div>
                            <div className="card-body">
                              {timeconditionsday[0] !== undefined && (
                                <Row>

                                <div className="col-md-2 basic-list-group">
                                    <div className="list-group">
                                    {timeconditionsday &&
                                      timeconditionsday.map((data, i) => ( 
                                        <a href="#!" key={data.time_condition_data_uuid} 
                                          className={`${
                                            selectDay.day_uuid === data.time_condition_data_uuid
                                              ? "list-group-item list-group-item-action active"
                                              : "list-group-item list-group-item-action"
                                            }`} 
                                          onClick={(e) => {
                                            e.preventDefault();
                                            getTimeSlot(
                                              data.time_condition_data_uuid,
                                              data.time_condition_day
                                            )
                                          }                                            
                                          }
                                        >
                                          {data.time_condition_day.toUpperCase()}
                                        </a> 
                                      ))
                                    }
                                                                                 
                                    </div>
                                </div>

                                <div className="col-md-10">
                                  <UpdateDay errors={errors} defaultDay={defaultDay} timeSlotOption={timeSlotOption}
                                    save={save} times = {times}
                                    handleToFrom={handleToFrom}
                                    conDateFormate={conDateFormate}
                                    fnDeleteTimeSlot={fnDeleteTimeSlot}
                                    openAddTimeSlotform={openAddTimeSlotform}
                                    selectDay={selectDay}
                                    addTimeSlot={addTimeSlot}
                                    createAvailTimeSlot={createAvailTimeSlot}
                                    time_condition_uuid={time_condition_uuid}
                                  />
                                </div>
                                
                                <div className="row">
                                  <div className="col-lg-2 col-md-2 col-2"></div>
                                  <div className="col-lg-10 col-md-10 col-10">
                                    <i className="fa fa-copy mr-2"></i>
                                    <label
                                      className="text text-info w500 mb-2 mr-2"
                                      onClick={() =>
                                        copyExtTime(
                                          "copy_all_weekdays",
                                          selectDay.day_uuid,
                                          selectDay.day
                                        )
                                      }
                                    >
                                      Copy to all weekdays
                                    </label>

                                    <i className="fa fa-copy ml-2 mr-2"></i>
                                    <label
                                      className="text text-info w500 mb-2 mr-2"
                                      onClick={() =>
                                        copyExtTime(
                                          "copy_to_all",
                                          selectDay.day_uuid,
                                          selectDay.day
                                        )
                                      }
                                    >
                                      Copy to all
                                    </label>

                                    <i className="fa fa-copy ml-2 mr-2"></i>
                                    <label
                                      className="text text-info w500 mb-2"
                                      onClick={() =>
                                        copyExtTime(
                                          "copy_to_weekends",
                                          selectDay.day_uuid,
                                          selectDay.day
                                        )
                                      }
                                    >
                                      Copy to weekends
                                    </label>
                                  </div>
                                </div>
                                </Row>
                              )}
                            </div>
                          </Tab.Pane>

                          <Tab.Pane eventKey="Profile">
                            <div className="card-header">
                              <h4 className="card-title">Calendar</h4>
                            </div>

                            
                              <CalendarView
                                key={timeSlotEvent}
                                calendarEvent={calendarEvent}
                              />
                            
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default UpdateTimeconditionForm;
