import { SET_MESSAGE, API_FAIL } from "./types";

import TimeconditionService from "../services/timecondition.service";

export const getTimeConditions =
  (
    user_type,
    group_per,
    group_uuid,
    search,
    off_set,
    limits,
    orderby,
    main_uuid,
    createdby,
    token
  ) =>
  (dispatch) => {
    return TimeconditionService.getTimeConditions(
      user_type,
      group_per,
      group_uuid,
      search,
      off_set,
      limits,
      orderby,
      main_uuid,
      createdby,
      token
    ).then(
      (data) => {
        if (
          data.status === 406 ||
          data.status === 500 ||
          data.status === 200
        ) {
          return data;
        } else {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const createTimecondition = (userData, token) => (dispatch) => {
  return TimeconditionService.createTimecondition(userData, token).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const updateTimeCondition = (userData, token) => (dispatch) => {
  return TimeconditionService.updateTimeCondition(userData, token).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getTimeConditionDetail =
  (createdby, token, time_condition_uuid) => (dispatch) => {
    return TimeconditionService.getTimeConditionDetail(
      createdby,
      token,
      time_condition_uuid
    ).then(
      (data) => {
        if (
          data.status === 406 ||
          data.status === 500 ||
          data.status === 200
        ) {
          return data;
        } else {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const deleteTimeCondition =
  (time_condition_uuid, createdby, token) => (dispatch) => {
    return TimeconditionService.deleteTimeCondition(
      time_condition_uuid,
      createdby,
      token
    ).then(
      (data) => {
        if (
          data.status === 406 ||
          data.status === 500 ||
          data.status === 200
        ) {
          return data;
        } else {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const createTimeSlot = (userData) => (dispatch) => {
  return TimeconditionService.createTimeSlot(userData).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const updateTimeSlot = (userData) => (dispatch) => {
  return TimeconditionService.updateTimeSlot(userData).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const deleteTimeSlot =
  (time_slot_uuid, createdby, token) => (dispatch) => {
    return TimeconditionService.deleteTimeSlot(
      time_slot_uuid,
      createdby,
      token
    ).then(
      (data) => {
        if (
          data.status === 406 ||
          data.status === 500 ||
          data.status === 200
        ) {
          return data;
        } else {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const deleteTimeSlotEvent =
  (time_slot_uuid, createdby, token) => (dispatch) => {
    return TimeconditionService.deleteTimeSlotEvent(
      time_slot_uuid,
      createdby,
      token
    ).then(
      (data) => {
        if (
          data.status === 406 ||
          data.status === 500 ||
          data.status === 200
        ) {
          return data;
        } else {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const getTimeSlotDetail =
  (createdby, token, time_condition_data_uuid) => (dispatch) => {
    return TimeconditionService.getTimeSlotDetail(
      createdby,
      token,
      time_condition_data_uuid
    ).then(
      (data) => {
        if (
          data.status === 406 ||
          data.status === 500 ||
          data.status === 200
        ) {
          return data;
        } else {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const getParticularTimeSlotDetail =
  (createdby, token, time_slot_uuid) => (dispatch) => {
    return TimeconditionService.getParticularTimeSlotDetail(
      createdby,
      token,
      time_slot_uuid
    ).then(
      (data) => {
        if (
          data.status === 406 ||
          data.status === 500 ||
          data.status === 200
        ) {
          return data;
        } else {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const getTimeSlotDetailEvents =
  (createdby, token, time_condition_uuid) => (dispatch) => {
    return TimeconditionService.getTimeSlotDetailEvents(
      createdby,
      token,
      time_condition_uuid
    ).then(
      (data) => {
        if (
          data.status === 406 ||
          data.status === 500 ||
          data.status === 200
        ) {
          return data;
        } else {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const getTimeSlotParticularEvents =
  (createdby, token, time_condition_data_uuid) => (dispatch) => {
    return TimeconditionService.getTimeSlotParticularEvents(
      createdby,
      token,
      time_condition_data_uuid
    ).then(
      (data) => {
        if (
          data.status === 406 ||
          data.status === 500 ||
          data.status === 200
        ) {
          return data;
        } else {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const getBusinessHoursTimeDetails =
  (time_condition_uuid, createdby, token) => (dispatch) => {
    return TimeconditionService.getBusinessHoursTimeDetails(
      time_condition_uuid,
      createdby,
      token
    ).then(
      (data) => {
        if (
          data.status === 406 ||
          data.status === 500 ||
          data.status === 200
        ) {
          return data;
        } else {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const getNextTimeConditionID =
  (createdby, main_admin_uuid, token) => (dispatch) => {
    return TimeconditionService.getNextTimeConditionID(
      createdby,
      main_admin_uuid,
      token
    ).then(
      (data) => {
        if (
          data.status === 406 ||
          data.status === 500 ||
          data.status === 200
        ) {
          return data;
        } else {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const updateTimeSlotWeekly =
  (
    time_condition_time_uuid,
    field_name,
    value,
    createdby,
    main_admin_uuid,
    token
  ) =>
  (dispatch) => {
    return TimeconditionService.updateTimeSlotWeekly(
      time_condition_time_uuid,
      field_name,
      value,
      createdby,
      main_admin_uuid,
      token
    ).then(
      (data) => {
        if (
          data.status === 406 ||
          data.status === 500 ||
          data.status === 200
        ) {
          return data;
        } else {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const copyTimeSlot =
  (type, select_day_uuid, createdby, main_admin_uuid, token) => (dispatch) => {
    return TimeconditionService.copyTimeSlot(
      type,
      select_day_uuid,
      createdby,
      main_admin_uuid,
      token
    ).then(
      (data) => {
        if (
          data.status === 406 ||
          data.status === 500 ||
          data.status === 200
        ) {
          return data;
        } else {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const getParticularDayTimeSlot =
  (createdby, token, select_day) => (dispatch) => {
    return TimeconditionService.getParticularDayTimeSlot(
      createdby,
      token,
      select_day
    ).then(
      (data) => {
        if (
          data.status === 406 ||
          data.status === 500 ||
          data.status === 200
        ) {
          return data;
        } else {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };
