import React, { Fragment, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css";
import PageTitle from "../../layouts/PageTitle";
import { getEmailCampaignReport } from "../../actions/email/emailCampaign";
import CheckModulePermisson from "../../common/CheckModulePermisson";
import PermissionCheck from "../../common/PermissionCheck";
import Loader from "../../layouts/Loader";
import PieChart from "./PieChart";
import BarChart from "./BarChart";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

function EmailCampaignReport(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const user_type = user_data.role;
  const module_name = "email-campaign";

  const params = useLocation();
  const email_campaign_uuid = params.state.email_campaign_uuid;

  let module_per = CheckModulePermisson(module_name);

  let isPermission = true;
  if (module_per === "" || user_type === "admin") {
    isPermission = true;
  } else {
    isPermission = false;
  }

  let listing_per = PermissionCheck(module_name, "listing", "", "", "");

  const dispatch = useDispatch();
  let navigate = useNavigate();

  if (listing_per === "none") {
    navigate("/error-403");
  }

  if (!isPermission) {
    navigate("/error-403");
  }

  const newDate = new Date();
  let day = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  let todayDate = month+'/'+day+'/'+year;

  let date2 = new Date(newDate.getTime() - 7 * 24 * 60 * 60 * 1000);
  let start_day = date2.getDate();
  let start_month = date2.getMonth() + 1;
  let start_year = date2.getFullYear();

  let pastDate = start_month+'/'+start_day+'/'+start_year;


  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [clickReportData, setClickReportData] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const [barLables, setBarLables] = useState([]);
  const barData  = [];
  const reportEvent = '';
  const [dateRange, setDateRange] = useState({
    start_date : pastDate,
    end_date : todayDate
  });

  useEffect(() => {
    setLoading(true);

    dispatch(
      getEmailCampaignReport(
        email_campaign_uuid,
        createdby,
        dateRange.start_date,
        dateRange.end_date,
        token
      )
    ).then((response) => {
        
        setReportData(response.data.campaign_counts);
        setCampaignData(response.data.campaign_data);
        setBarLables(response.data.bar_lables);
        setClickReportData(response.data.click_report);

        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, [reportEvent,dateRange]);
  
  const handleCallback = (start, end) => {
    
    setDateRange({
        start_date : start.format('MM-DD-YYYY'),
        end_date : end.format('MM-DD-YYYY'),
      })

    //console.log("A new date selection was made: " + start.format('MM-DD-YYYY') + ' to ' + end.format('MM-DD-YYYY'));
  }

  const calculatePercentage = (numerator, denominator) => {
    if (denominator === 0) {
      return '0';
    }
    return ((numerator / denominator) * 100).toFixed(2) + '%';
  }
 

  const breadCurmList = [
    { label: "Emails", link: "/", is_active: "NO" },
    { label: "Email Campaign Reports", link: "", is_active: "YES" },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />
      {isPermission ? (
        <>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Email Campaign Reports : {campaignData.email_campaign_name}</h4>

                  <div className="input-primary">
                    <DateRangePicker
                      initialSettings={{ startDate: dateRange.start_date, endDate: dateRange.end_date }}
                      onCallback={handleCallback}
                    >
                      <input type="text" className="form-control w-75 bg-white pull-left" />
                    </DateRangePicker>
                  
                    <Button
                      type="button"
                      className="btn btn-default btn-sm pull-right"
                      onClick={() => navigate("/email-campaign", { replace: true })}
                    >
                      <i className="fa fa-reply" aria-hidden="true"></i> Back
                    </Button>

                  </div>

                </div>

              </div>
            </div>

            <div className="col-sm">
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <span className="mr-3 bgl-primary text-primary">
                      
                      <i className="fa fa-envelope-open-o"></i>
                    </span>
                    <div className="media-body">
                      <p className="mb-1">Opened</p>
                      <h4 className="mb-0">{reportData.opencount}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm">
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <span className="mr-3 bgl-warning text-warning">
                      
                      <i className="fa fa-mouse-pointer"></i>
                    </span>
                    <div className="media-body">
                      <p className="mb-1">Clicked</p>
                      <h4 className="mb-0">{reportData.clickcount}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm">
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <span className="mr-3 bgl-danger text-danger">
                      
                      <i className="fa fa-circle-o"></i>
                    </span>
                    <div className="media-body">
                      <p className="mb-1">Bounced</p>
                      <h4 className="mb-0">{reportData.bouncecount}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm">
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <span className="mr-3 bgl-success text-success">
                      
                      <i className="fa fa-ban"></i>
                    </span>
                    <div className="media-body">
                      <p className="mb-1">Unsubscribed</p>
                      <h4 className="mb-0">{reportData.unsubscribecount}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm">
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <span className="mr-3 bgl-info text-info">
                      
                      <i className="fa fa-envelope-o"></i>
                    </span>
                    <div className="media-body">
                      <p className="mb-1">Delivered</p>
                      <h4 className="mb-0">{reportData.deliveredcount}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="card col-md-8">
                
                <div className="card-body pb-0">
                  {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex px-0 justify-content-between">
                      <strong>Click Rate</strong>
                      <span className="mb-0">{calculatePercentage(reportData.clickcount,campaignData.total_contacts)}</span>
                    </li>
                    <li className="list-group-item d-flex px-0 justify-content-between">
                      <strong>Successfull Deliveries</strong>
                      <span className="mb-0">{reportData.deliveredcount}</span>
                    </li>
                    <li className="list-group-item d-flex px-0 justify-content-between">
                      <strong>Total Opens</strong>
                      <span className="mb-0">{reportData.opencount}</span>
                    </li>
                    <li className="list-group-item d-flex px-0 justify-content-between">
                      <strong>Recipients who Clicked</strong>
                      <span className="mb-0">{reportData.clickcount}</span>
                    </li>
                  </ul>

                  
                  <div className="card-header pb-0">
                    <h2 className="card-title">clicked Links</h2>
                  </div>

                  <ul className="list-group list-group-flush">
                  {clickReportData.map((row,index) => (
                    <li className="list-group-item d-flex px-0 justify-content-between" key={index}>
                      <p className="text-info">{row.clicked_ulr}</p>
                      <span className="mb-0">{row.clicked_count}</span>
                    </li>
                  ))}
                    
                    
                  </ul>

                </div>
                
              </div>

              <div className="col-md-4">
                <div>
                  <PieChart reportData={reportData} />
                </div>

                <div>

                  

                  <BarChart barLables={barLables} barData={barData} />
                  

                </div>
                     
                                
              </div>

            </div>

          </div>
        </>
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Email Campaign Reports</h4>
              </div>
              <div className="card-body">
                <h3>You have don't access this modules</h3>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default EmailCampaignReport;
