import React, { Fragment, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../layouts/PageTitle";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import { searchDid, addDids } from "../../actions/did";
import "./style.css";
import Loader from "../../layouts/Loader";

function DidForm(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;

  const Div = (prop) => {
    return (
      <div className="form-row" data-id={prop.dataid}>
        <div className="col">
          <label></label>
          <h4>{prop.value}</h4>
        </div>

        <div className="col p-4">
          <button
            className="btn btn-danger btn-sm"
            onClick={() => removeNum(prop.value, prop.dataid)}
          >
            <i className="fa fa-minus" aria-hidden="true" />
          </button>
        </div>
      </div>
    );
  };

  const [inputList, setInputList] = useState([]);
  const [adddid, setAdddid] = useState([]);

  function addNum(data, i) {
    setAdddid([did[i], ...adddid]);

    const filteredDid = did.filter((dids) => {
      return dids.phone_number !== data;
    });
    setDid(filteredDid);

    setInputList(
      inputList.concat(<Div value={data} dataid={i} key={inputList.length} />)
    );
  }

  function removeNum(data, i) {

    const filterDid = adddid.filter((didss) => {
      return didss.phone_number !== data;
    });
    setAdddid(filterDid);
  }

  const [didData, setDidData] = useState({
    number_type: "",
    locality: "",
    area_code: "",
    limit: "",
    token: token,
    createdby: createdby,
    main_admin_uuid: createdby,
    user_uuid: createdby,
  });

  const ddidData = {
    dids: "",
    token: token,
    createdby: createdby,
    main_admin_uuid: createdby,
    user_uuid: createdby,
  };

  const [did, setDid] = useState([]);

  const [errors, setErrors] = useState({
    extension: "",
    extension_password: "",
    voicemail_password: "",
  });

  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBlur = (e) => {
    const newDiddata = { ...didData };
    let error_data = { ...errors };

    if (e.target.name === "area_code" && e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }
    newDiddata[e.target.name] = e.target.value;
    setDidData(newDiddata);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (didData.area_code.length === 0) {
      error_data["area_code"] = "Area Code is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (!/^[0-9\s]*$/i.test(didData.limit)) {
      error_data["limit"] = "Invalid limit.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create user API
      dispatch(searchDid(didData))
        .then((response) => {
          setDid(response.data);
          // navigate("/ring-groups", { replace: true });
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const submitHandler1 = (e) => {
    ddidData["dids"] = adddid;

    e.preventDefault();
    //call create user API
    dispatch(addDids(ddidData))
      .then((res) => {
        if (res.status === 200) {
          notifyInfo(res.message);
          navigate("/did", { replace: true });
        } else if (res.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
       {loading && <Loader />}
      <PageTitle activeMenu="Add Phone Numbers" motherMenu="Applications" />
      <div className="row">
        <div className="col-8">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Add Phone Numbers</h4>
              <Button
                type="button"
                className="btn btn-default btn-sm"
                onClick={() => navigate("/did", { replace: true })}
              >
                <i className="fa fa-reply" aria-hidden="true"></i> Back
              </Button>
            </div>
            <div className="card-body">
              <form action="" onSubmit={(e) => submitHandler(e)}>
                <div className="row">
                  <div className="col-sm">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Area Code</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter a Area Code"
                        name="area_code"
                        onChange={handleBlur}
                      />
                      <span className="text-danger">{errors.area_code}</span>
                    </div>
                  </div>
                  <div class="col-sm">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Number Type</strong>
                      </label>
                      <select
                        className="form-control"
                        name="number_type"
                        onChange={handleBlur}
                        value={didData.number_type}
                      >
                        <option value="">Select Number Type</option>
                        <option value="toll-free">Toll-free</option>
                      </select>
                      <span className="text-danger">
                        {errors.number_type}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div className="col-sm">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>State/City</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter a State/City"
                        name="locality"
                        onChange={handleBlur}
                      />
                      <span className="text-danger">{errors.locality}</span>
                    </div>
                  </div>
                  <div class="col-sm">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Limit</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter a limit"
                        name="limit"
                        onChange={handleBlur}
                      />
                      <span className="text-danger">{errors.limit}</span>
                    </div>
                  </div>
                </div>

                <div className="row text-right">
                  <div className="col-sm">
                    <Button type="submit" variant="primary" className="btn-sm">
                      Search
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-4">
          {adddid.length > 0 && (
            <div>
              <h4 className="card-title">DID List</h4>
              <form action="" onSubmit={(e) => submitHandler1(e)}>
                {/* {inputList} */}

                {adddid.map((did, i) => (
                  <div className="form-row" key={i}>
                    <div className="col">
                      <label></label>
                      <h4>{did.phone_number}</h4>
                    </div>

                    <div className="col p-4">
                      <div className={"btn_" + i}>
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => removeNum(did.phone_number, i)}
                        >
                          <i className="fa fa-minus" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="row text-right">
                  <Button type="submit" variant="primary" className="btn-sm">
                    Buy Did
                  </Button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
      <div className="row">
        {did.length > 0 && (
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">DID List</h4>
              </div>
              <div className="card-body">
                {did.map((did, i) => (
                  <div className="form-row" key={i}>
                    <div className="col">
                      <label></label>
                      <h4>{did.phone_number}</h4>
                    </div>

                    <div className="col p-4">
                      <div className={"btn_" + i}>
                        <button
                          onClick={() => addNum(did.phone_number, i)}
                          type="button"
                          className="btn btn-primary text-center btn-sm"
                        >
                          <i className="fa fa-plus-circle" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default DidForm;
