import {
    SET_MESSAGE,
    API_FAIL,
    API_SUCCESS,
  } from "./types";

  import customContactLayout from "../services/customContactLayout.service";

  //customContactLayout

  export const getContactFormLayout = (main_uuid,createdby,token) => (dispatch) => {
    return customContactLayout.getContactFormLayout(main_uuid,createdby,token).then(
      (data) => {

        if(data.status !== 200){
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        }
  
        dispatch({
          type: API_SUCCESS,
          payload: { user: data },
        });
  
        return data;
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const getCustomContactFields = (main_uuid,createdby,token) => (dispatch) => {
    return customContactLayout.getCustomContactFields(main_uuid,createdby,token).then(
      (data) => {

        if(data.status !== 200){
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        }
  
        dispatch({
          type: API_SUCCESS,
          payload: { user: data },
        });
  
        return data;
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const createTab = (data,token) => (dispatch) => {
    return customContactLayout.createTab(data,token).then(
      (data) => {
        
        if(data.status !== 200){
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        }
  
        dispatch({
          type: API_SUCCESS,
          payload: { user: data },
        });
  
        return data;
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const createSection = (data,token) => (dispatch) => {
    return customContactLayout.createSection(data,token).then(
      (data) => {
        
        if(data.status !== 200){
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        }
  
        dispatch({
          type: API_SUCCESS,
          payload: { user: data },
        });
  
        return data;
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const deleteTab = (data,token) => (dispatch) => {
    
    return customContactLayout.deleteTab(data,token).then(
      (data) => {
        
        if(data.status !== 200){
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        }
  
        dispatch({
          type: API_SUCCESS,
          payload: { user: data },
        });
  
        return data;
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const setCustomFieldPosition = (data,token) => (dispatch) => {
    return customContactLayout.setCustomFieldPosition(data,token).then(
      (data) => {
        
        if(data.status !== 200){
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        }
  
        dispatch({
          type: API_SUCCESS,
          payload: { user: data },
        });
  
        return data;
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const updateCustomFieldName = (data,token) => (dispatch) => {
    return customContactLayout.updateCustomFieldName(data,token).then(
      (data) => {
        
        if(data.status !== 200){
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        }
  
        dispatch({
          type: API_SUCCESS,
          payload: { user: data },
        });
  
        return data;
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const updateContactFormSection = (data,token) => (dispatch) => {
    return customContactLayout.updateContactFormSection(data,token).then(
      (data) => {
        
        if(data.status !== 200){
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        }
  
        dispatch({
          type: API_SUCCESS,
          payload: { user: data },
        });
  
        return data;
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const getCustomContactFormFields = (createdby,token) => (dispatch) => {
    return customContactLayout.getCustomContactFormFields(createdby,token).then(
      (data) => {
        
        if(data.status !== 200){
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        }
  
        dispatch({
          type: API_SUCCESS,
          payload: { user: data },
        });
  
        return data;
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const createBulkUpdateField = (data,token) => (dispatch) => {
    return customContactLayout.createBulkUpdateField(data,token).then(
      (data) => {
        
        if(data.status !== 200){
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        }
  
        dispatch({
          type: API_SUCCESS,
          payload: { user: data },
        });
  
        return data;
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const getBulkUpdateFields = (main_uuid,createdby,token) => (dispatch) => {
    return customContactLayout.getBulkUpdateFields(main_uuid,createdby,token).then(
      (data) => {
        
        if(data.status !== 200){
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        }
  
        dispatch({
          type: API_SUCCESS,
          payload: { user: data },
        });
  
        return data;
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const deleteBulkUpdateField = (contact_bulk_update_field_uuid,createdby,token) => (dispatch) => {
    return customContactLayout.deleteBulkUpdateField(contact_bulk_update_field_uuid,createdby,token).then(
      (data) => {
        
        if(data.status !== 200){
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        }
  
        dispatch({
          type: API_SUCCESS,
          payload: { user: data },
        });
  
        return data;
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };
