import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const updateProfile = (first_name, last_name, uuid, token) => {
  let createdby = uuid;

  return axios
    .post(
      API_URL + "update-user-profile",
      {
        first_name,
        last_name,
        uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      if (response.data.status === 200) {
        const user = JSON.parse(localStorage.getItem("user"));
        const user_data = user.data.data;
        user_data.first_name = first_name;
        user_data.last_name = last_name;

        let arr = { data: { data: user_data } };

        localStorage.setItem("user", JSON.stringify(arr));
      }

      return response.data;
    });
};

const changePassword = (old_password, new_password, uuid, token, type) => {
  let createdby = uuid;

  return axios
    .post(
      API_URL + "change-password",
      {
        old_password,
        new_password,
        uuid,
        createdby,
        type,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const createUser = (data, token) => {
  return axios
    .post(API_URL + "create-user", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const updateUser = (data, token) => {
  return axios
    .post(API_URL + "update-user", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const updateUserRole = (data, token) => {
  return axios
    .post(API_URL + "update-user-role", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getUsers = (
  main_uuid,
  user_type,
  group_per,
  group_uuid,
  search,
  searchData,
  off_set,
  status,
  limits,
  orderby,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "get-user-list",
      {
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        search,
        searchData,
        off_set,
        status,
        limits,
        orderby,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getUsersRole = (
  search,
  off_set,
  status,
  limits,
  orderby,
  role_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "get-user-list-role",
      {
        search,
        off_set,
        status,
        limits,
        orderby,
        role_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getUserList = (createdby, token) => {
  return axios
    .post(
      API_URL + "dropdown/get-user-list",
      {
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getUserListDropDown = (createdby, token) => {
  return axios
    .post(
      API_URL + "get-user-list-dropdown",
      {
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getUserDetail = (useruuid, createdby, token) => {
  return axios
    .post(
      API_URL + "get-user-details",
      {
        useruuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const importUserCsv = (data, token) => {
  return axios
    .post(API_URL + "import-user-csv", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getUserMappingFields = (createdby, token, filename) => {
  return axios
    .post(
      API_URL + "get-user-mappingFields",
      {
        filename,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const insertUserCsvData = (data) => {
  return axios
    .post(API_URL + "insert-user-csv-data", data, {
      headers: {
        Authorization: data.token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const saveE911Data = (data, token) => {
  return axios
    .post(API_URL + "save-e911-data", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const updateDIDNumber = (data, token) => {
  return axios
    .post(API_URL + "update-did-number", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const updateUserStatus = (data, token) => {
  return axios
    .post(API_URL + "user/user-status-update", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const updateUserGroup = (data, token) => {
  return axios
    .post(API_URL + "update-user-group", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const userDelete = (data, token) => {
  return axios
    .post(API_URL + "delete-user", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const updateReInvite = (data, token) => {
  return axios
    .post(API_URL + "user/update-re-invite", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

export default {
  updateProfile,
  changePassword,
  createUser,
  getUsers,
  getUsersRole,
  getUserList,
  getUserListDropDown,
  getUserDetail,
  updateUser,
  importUserCsv,
  getUserMappingFields,
  insertUserCsvData,
  saveE911Data,
  updateDIDNumber,
  updateUserStatus,
  updateUserRole,
  updateUserGroup,
  userDelete,
  updateReInvite,
};
