import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import StepOne from "./Wizard/StepOne";
import StepTwo from "./Wizard/StepTwo";
import StepThree from "./Wizard/StepThree";
import StepFour from "./Wizard/StepFour";
import "./index.css";
import { registrationStatus } from "../actions/auth";

const MultiStepForm = () => {
  var user = JSON.parse(localStorage.getItem("user"));
  var user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;

  const [currentStep, setCurrentStep] = useState(1);
  const [stepData, setStepData] = useState(1);
  const [stepEvent, setStepEvent] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(registrationStatus(token, createdby, createdby))
      .then((response) => {
        let get_data = response.data;
        setStepData(response.data);
        if (get_data.is_did_done === "YES") {
          setCurrentStep(currentStep + 1);
        }

        if (
          get_data.is_did_done === "YES" &&
          get_data.is_email_config_done === "YES"
        ) {
          setCurrentStep(currentStep + 2);
        }

        if (
          get_data.is_did_done === "YES" &&
          get_data.is_email_config_done === "YES" &&
          get_data.is_branding_done === "YES"
        ) {
          setCurrentStep(currentStep + 3);
        }

        // if (
        //   get_data.is_did_done == "YES" &&
        //   get_data.is_email_config_done == "YES" &&
        //   get_data.is_branding_done == "YES" &&
        //   get_data.is_user_done == "YES"
        // ) {
        //   navigate("/");
        // }
        return;
      })
      .catch(() => {
        //setLoading(false);
      });

    // if (user_data.is_did_done == "YES") {
    //   setCurrentStep(currentStep + 1);
    // }
  }, [stepEvent]);

  const handleOneData = (dataFromOne) => {
    //setFormData(dataFromOne);
  };

  const handleTwoData = (dataFromTwo) => {
    //setFormData(dataFromTwo);
  };

  const handleThreeData = (dataFromThree) => {
    user.data.data.is_branding_done = "YES";
    localStorage.setItem("user", JSON.stringify(user));
    window.location.reload(); 
    setStepEvent(Math.random());
  };

  const handleFourData = (dataFromFour) => {
    //setFormData(dataFromFour);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <StepOne
            handleOneData={handleOneData}
            currentStep={currentStep}
            stepData={stepData}
          />
        );
      case 2:
        return (
          <StepTwo
            currentStep={currentStep}
            handleTwoData={handleTwoData}
            stepData={stepData}
          />
        );
      case 3:
        return (
          <StepThree
            handleThreeData={handleThreeData}
            currentStep={currentStep}
            stepData={stepData}
          />
        );
      case 4:
        return (
          <StepFour
            currentStep={currentStep}
            handleFourData={handleFourData}
            stepData={stepData}
          />
        );
      default:
        return null;
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
  };
 
  return (
    <div className="row">
      <div className="col-xl-12 col-xxl-12">
        <form
          action=""
          onSubmit={(e) => submitHandler(e)}
          id="step-form-horizontal"
          className="step-form-horizontal"
        >
          <ol className="go2441762052">
            <li
              className={currentStep < 1 ? "go2067797667" : "go767851704"}
              value="0"
            >
              <span>1</span>
            </li>
            <li
              className={currentStep < 2 ? "go2067797667" : "go767851704"}
              value="1"
            >
              <span>2</span>
            </li>
            <li
              className={currentStep < 3 ? "go2067797667" : "go767851704"}
              value="2"
            >
              <span>3</span>
            </li>
            <li
              className={currentStep < 4 ? "go2067797667" : "go767851704"}
              value="3"
            >
              <span>4</span>
            </li>
          </ol>
          <div className="mt-4 mb-4">{renderStep()}</div>

          <div className="next_pre_btn">
            {/* {currentStep > 1 && (
              <button
                className="prevStyle"
                type="button"
                onClick={handlePrevStep}
              >
                Previous
              </button>
            )} */}
            {/* {currentStep < 4 && (
              <button
                className="nextStyle"
                type="button"
                onClick={handleNextStep}
              >
                Next
              </button>
            )} */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default MultiStepForm;
