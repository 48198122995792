import React, {useState,useEffect} from "react";
import { useDispatch } from "react-redux";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Modal } from "react-bootstrap";
import Availability from "../Dashboard/calender/Availability";
import UserEvent from "../Dashboard/calender/UserEvent";
import {getCalendarMeetings,getEventContacts}  from "../../actions/calender/CalendarMeeting";
import { getAllTasks } from "../../actions/Tasks";
import Loader from "../../layouts/Loader";
import EventView from "../Dashboard/calender/EventView";
import TaskEventForm from "../Dashboard/calender/TaskEventForm";
import TaskUpdateForm from "../Dashboard/calender/TaskUpdateForm";

function Calender() {

   const user = JSON.parse(localStorage.getItem("user"));
   const user_data = user.data.data;
   const token = user.data.token;
   const createdby = user_data.user_uuid;

   const [loadMeetings, setLoadMeetings] = useState('');
   const [loadTasks, setLoadTasks] = useState('');
   const [events, setEvents] = useState([]);
   const [selectedEvent, setSelectedEvent] = useState([]);
   const [selectedDate, setSelectedDate] = useState('');
   const [calendarMeetings, setCalendarMeetings] = useState([]);
   const [loading, setLoading] = useState(false);
   const [availabilityModal, setAvailabilityModal] = useState(false);
   const [userEventModal, setUserEventModal] = useState(false);
   const [eventModal, setEventModal] = useState(false);
   const [taskEventModal, setTaskEventModal] = useState(false);
   const [taskUpdateModal, setTaskUpdateModal] = useState(false);
   const [calendarTasks, setCalendarTasks] = useState([]);
   const [selectedTask, setSelectedTask] = useState([]);
   const dispatch = useDispatch();

   useEffect(() => {
 
      setLoading(true);
      dispatch(getCalendarMeetings(createdby,token)).then((response) => {
        if (response.status === 200) {

         if(response.data){
            setCalendarMeetings(response.data);
            
            let eventData = response.data.map(function(row) {
               let new_row = [];
               new_row['start'] = new Date(row.start_date);
               new_row['title'] = row.meeting_title;
               new_row['id'] = row.calendar_meeting_uuid;
               new_row['type'] = 'EVENT';
               new_row['backgroundColor'] = '#3388DC';
               new_row['extendedProps'] = {
                  type : 'EVENT'
                };
               return new_row;
            })
            //setEvents(eventData);

            dispatch(getAllTasks(createdby,token)).then((response) => {
               if (response.status === 200) {
       
                if(response.data){
                   setCalendarTasks(response.data);
                   
                   let eventData2 = response.data.map(function(row) {
                      let new_row = [];
                      new_row['start'] = new Date(row.task_date);
                      new_row['title'] = row.task_title;
                      new_row['id'] = row.contact_task_uuid;
                      new_row['backgroundColor'] = '#9C33DC';
                      new_row['type'] = 'TASK';
                      new_row['extendedProps'] = {
                        type : 'TASK'
                      };
                      
                      return new_row;
                   })
                   setEvents([...eventData,...eventData2]);
                }
                   
               }
               
               setLoading(false);
               return;
            })
         }
            
        }
        
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
    }, [loadMeetings,loadTasks]);

    
   const closeModel = (value) => {
      setAvailabilityModal(value);
   }

   const closeUserEventModel = (value) => {
      setUserEventModal(value);
   }

   const closeEventModel = (value) => {
      setEventModal(value);
   }

   // const renderEventContent = (eventInfo) => {
      
   //    return (
   //    <>
   //       <b>{eventInfo.timeText}</b>
   //       <i>{eventInfo.event.title}</i>
   //    </>
   //    )
   // }

   const handleEventClick = (data) => {
      
      let event_data = data.event.extendedProps;
      let id = data.event.id;

      if(event_data.type === 'EVENT'){         
         let eventData =  calendarMeetings.filter(row => row.calendar_meeting_uuid === id);

         if(eventData[0]){
            let data = eventData[0];

            if(data.event_type === 'CONTACT'){
               setLoading(true);
               dispatch(getEventContacts(createdby,id,token)).then((response) => {
                  if (response.status === 200) {
                     data['contacts'] =   response.data
                  }               
                  setLoading(false);
                  return;
               })
            }
            setSelectedEvent(data);
            setEventModal(true);
         } 
      }else if(event_data.type === 'TASK'){
         let taskData =  calendarTasks.filter(row => row.contact_task_uuid === id);
         if(taskData[0]){
            setSelectedTask(taskData[0]);
            setTaskUpdateModal(true);
         }         
      }      
   }

   const handleDateClick = (selectInfo) => {
      //alert(selectInfo.startStr);
      setSelectedDate(selectInfo.startStr);
      setTaskEventModal(true);
   }

   const handleMeetingEvent = (value) => {
      setLoadMeetings(value);
   }

   const closeTaskEventModel = (value) => {
      setTaskEventModal(value);
   }

   const handleTaskEvent = (value) => {
      setLoadTasks(value);
   }

   const closeTaskUpdateModel = (value) => {
      setTaskUpdateModal(value);
   }
  
   return (
      <>
      {loading && <Loader />}
        <div className="form-head d-flex mb-0 mb-lg-4 align-items-start">
            <div className="mr-auto d-none d-lg-block">
               <h2 className="text-black font-w600">Calendar</h2>
               <p className="mb-0">Current task list</p>
            </div>
            <div className="d-none d-lg-flex align-items-center">
               <button type="button" className="btn btn-success btn-sm mr-4" onClick={() => setUserEventModal(true)}>Events</button>
            </div>
            <div className="d-none d-lg-flex align-items-center">
               <button type="button" className="btn btn-info btn-sm mr-4" onClick={() => setAvailabilityModal(true)}>Set AVailability</button>
            </div>
         </div>
         <div className="row">
            <div className="col-xl-12">
                  
               <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  headerToolbar={{
                     left: "prev,next today",
                     center: "title",
                     right: "dayGridMonth,timeGridWeek,timeGridDay",
                   }}
                  initialView='dayGridMonth'
                  editable={true}
                  selectable={true}
                  selectMirror={true}
                  dayMaxEvents={true}
                  weekends={true}
                  events={events}
                  eventColor='#378006'
                  eventDisplay="block"
                  //eventContent={renderEventContent}
                  eventClick={handleEventClick}
                  select={handleDateClick}
               />

            </div>
         </div>

         <Modal className="fade" show={availabilityModal} size="xl">
            <Availability closeModel={closeModel} />            
         </Modal>

         <Modal className="fade" show={userEventModal}  dialogClassName="modal-fullscreen mw-100" >
            <UserEvent closeUserEventModel={closeUserEventModel} />            
         </Modal>

         <Modal className="fade" show={eventModal} size="lg">
            <EventView closeEventModel={closeEventModel} eventData={selectedEvent} handleMeetingEvent={handleMeetingEvent}/>            
         </Modal>

         <Modal className="fade" show={taskEventModal} size="lg">
             <TaskEventForm closeTaskEventModel={closeTaskEventModel} selectedDate={selectedDate} handleTaskEvent={handleTaskEvent} />
         </Modal>

         <Modal className="fade" show={taskUpdateModal} size="lg">
             <TaskUpdateForm closeTaskUpdateModel={closeTaskUpdateModel} handleTaskEvent={handleTaskEvent} selectedTask={selectedTask}/>
         </Modal>
      </>
   );
}

export default Calender;
