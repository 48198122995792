import React from "react";
import moment from "moment";

const Footer = () => {
   const date = new Date();
  return (
    <div className="footer">
      <div className="copyright">
        <p>
          Copyright © &amp; All rights reserved{" "}
          <a
            href="http://dexignzone.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Zongo
          </a>{" "}
          {moment(date).format("YYYY")}
        </p>
      </div>
    </div>
  );
};

export default Footer;
