import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Table,
  Modal,
  Button,
  ButtonGroup,
  DropdownButton,
  Dropdown,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import Pagination from "../../layouts/Pagination";
import { useDispatch } from "react-redux";
import PageTitle from "../../layouts/PageTitle";
import Loader from "../../layouts/Loader";
import UserForm from "./UserForm";
import UserInviteForm from "./UserInviteForm";
import { getUsers, userDelete } from "../../actions/user";
import CheckModulePermisson from "../../common/CheckModulePermisson";
import PermissionCheck from "../../common/PermissionCheck";
import filter_img from "./../../../images/profile/filter.png";
import FilterForm from "./FilterForm";
import { confirmAlert } from "react-confirm-alert";
import { notifyInfo, notifyError } from "../Toastr/Toastr";

function Users(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;
  const module_name = "users";

  let module_per = CheckModulePermisson(module_name);

  let isPermission = true;
  if (module_per === "" || user_type === "admin") {
    isPermission = true;
  } else {
    isPermission = false;
  }
  let navigate = useNavigate();
  let listing_per = PermissionCheck(module_name, "listing", "", "", "");
  let add_per = PermissionCheck(module_name, "add", "", "", "");

  if (listing_per === "none") {
    navigate("/error-403");
  }

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }

  const [limits, setLimits] = useState(10);
  const [pageCount, setpageCount] = useState(0);
  const [offSet, setOffSet] = useState(0);
  const [addUserModal, setAddUserModal] = useState(false);
  const [inviteUserModal, setInviteUserModal] = useState(false);
  const [UserEvent, setUserEvent] = useState("");
  const [userTotal, setUserTotal] = useState("");
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const userData = [];
  const [search, setSearch] = useState("");
  const [socket, setSocket] = useState(null);

  const [searchData, setSearchData] = useState({
    user_name: "",
    email: "",
    role: "",
    status: "",
  });

  const handleUserEvent = (event) => {
    setUserEvent(event);
  };

  const closeModel = (value) => {
    setAddUserModal(value);
    //setInviteUserModal(value);
  };

  const closeInviteModel = (value) => {
    setInviteUserModal(value);
  };

  const dispatch = useDispatch();

  let status = "ACTIVE";
  let orderby = "first_name ASC";

  if (module_per === "" || user_type === "admin") {
    useEffect(() => {
      setLoading(true);
      dispatch(
        getUsers(
          main_uuid,
          user_type,
          group_per,
          group_uuid,
          search,
          searchData,
          offSet,
          status,
          limits,
          orderby,
          createdby,
          token
        )
      )
        .then((response) => {
          setUsers(response.data.data);
          const total = response.data.total;
          setUserTotal(total);
          setpageCount(Math.ceil(total / limits));
          setUserEvent("");
          setLoading(false);
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    }, [UserEvent, limits]);
  }

  
  useEffect(() => {

    const WEB_SOCKET_URL = process.env.REACT_APP_SERVER_WEB_SOCKET_URL;
    // Establishing a WebSocket connection
    const ws = new WebSocket(WEB_SOCKET_URL);

    // Set up event listeners
    ws.onopen = () => {
      console.log("WebSocket connection opened");
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed");
    };

    setSocket(ws);

    // Cleanup the WebSocket connection when the component unmounts
    return () => {
      ws.close();
    };
  }, []);

  const handlePageClick = async (data) => {
    setOffSet(limits * data.selected);
    setUserEvent(Math.random);
  };

  const chnageLimit = (value) => {
    setLimits(value);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setUserEvent(Math.random);
  };

  const applySearch = (data) => {
    setSearchData(data);
    setUserEvent(Math.random);
    document.body.click();
  };

  const popoverBottom = (
    <Popover id="popover-positioned-bottom" title="Popover bottom">
      <FilterForm applySearch={applySearch} searchData={searchData} />.
    </Popover>
  );

  const deleteUser = (user_uuid) => {
    confirmAlert({
      title: "",
      message: "Are you sure you want to delete this User?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteUserService(user_uuid),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteUserService = (user_uuid) => {
    setLoading(true);
    let data = {
      user_uuid: user_uuid,
      createdby: createdby,
    };

    dispatch(userDelete(data, token))
      .then((response) => {
        if (response.status === 200) {
          const fileData = {
            type: "user_delete",
            file_type: "", // You can replace this with the actual username
            data: user_uuid,
            fileName: "",
          };

          if (socket && socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify(fileData));
          }
          notifyInfo(response.message);
          setLoading(false);
          setUserEvent(Math.random);
          return;
        } else if (response.status === 500) {
          setLoading(false);
          notifyError("Something went wrong.");
        } else {
          setLoading(false);
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const breadCurmList = [
    { label: "Calls", link: "/", is_active: "NO" },
    { label: "Users", link: "/users", is_active: "NO" },
    { label: "Total Amount : " + userTotal, link: "", is_active: "YES" },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />

      {isPermission ? (
        <>
          <div className="row">
            <div className="col-12">
              <div className="card">
                {/* <Accordion
                  className='accordion cursor-pointer'
                  defaultActiveKey='0'
                >
                  <div className='accordion__item p-0' key='1'> */}
                <div className="card-header">
                  <h4 className="card-title">
                    Manage Users
                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      placement="bottom"
                      overlay={popoverBottom}
                    >
                      <span className="cursor-pointer ml-3">
                        <img
                          src={filter_img}
                          className="img-fluid rounded-circle"
                          alt="Search"
                        />
                      </span>
                    </OverlayTrigger>
                  </h4>
                  <span>
                    {add_per === "yes" ? (
                      <Button
                        type="button"
                        className="btn btn-sm btn-primary mt-2"
                        onClick={() => {
                          setAddUserModal(true);
                        }}
                      >
                        Add New User
                      </Button>
                    ) : (
                      <></>
                    )}

                    {add_per === "yes" ? (
                      <Button
                        type="button"
                        className="btn btn-sm btn-primary ml-3 mt-2"
                        onClick={() => setInviteUserModal(true)}
                      >
                        Bulk Upload
                      </Button>
                    ) : (
                      <></>
                    )}

                    <div className="input-group input-success w-50 pull-right">
                      <input
                        className="form-control text-primary"
                        type="search"
                        placeholder="Search Here"
                        aria-label="Search"
                        onChange={(e) => handleSearchChange(e)}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text text-primary">
                          <svg
                            width={20}
                            height={20}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                              fill="#A4A4A4"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </span>
                </div>
                <div className="card-body">
                  Show
                  <ButtonGroup className="ml-2 mr-2">
                    <DropdownButton
                      as={ButtonGroup}
                      id="bg-nested-dropdown"
                      size="sm"
                      title={limits}
                      variant="custom-dropdown"
                    >
                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(10)}
                      >
                        10
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(25)}
                      >
                        25
                      </Dropdown.Item>

                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(50)}
                      >
                        50
                      </Dropdown.Item>

                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(100)}
                      >
                        100
                      </Dropdown.Item>
                    </DropdownButton>
                  </ButtonGroup>
                  entries
                  {/* <Accordion.Collapse eventKey='1'>
                    <div className='accordion__body--text'>
                        <FilterForm /> 
                    </div>
                  </Accordion.Collapse> */}
                  <Table responsive className="w-100">
                    <thead>
                      <tr role="row">
                        <th>Name</th>
                        <th>Extension</th>
                        <th>Email</th>
                        <th>Role</th>
                        {listing_per === "group" || user_type === "admin" ? (
                          <th>Created By</th>
                        ) : (
                          <></>
                        )}
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {users.map((user) => (
                        <tr key={user.uuid}>
                          <td>
                            <Link
                              to="/view-user"
                              state={{
                                useruuid: user.uuid,
                              }}
                              hidden={PermissionCheck(
                                module_name,
                                "edit",
                                user.group_uuid,
                                user.user_created_by,
                                user.created_by
                              )}
                              className="text-blue"
                            >
                              {user.first_name} {user.last_name}
                            </Link>
                          </td>

                          <td>
                            {user.extension !== null ? user.extension : ""}
                          </td>
                          <td>{user.email}</td>
                          <td>{user.role_name}</td>
                          {listing_per === "group" ||
                          listing_per === "all" ||
                          user_type === "admin" ? (
                            <td>{user.created_by_name}</td>
                          ) : (
                            <></>
                          )}
                          <td>
                            <span
                              className={
                                user.status === "ACTIVE"
                                  ? "text-green"
                                  : user.status === "INACTIVE"
                                  ? "text-red"
                                  : "text-warning"
                              }
                            >
                              {user.status}
                            </span>
                          </td>
                          <td>
                            <Fragment>
                              <div className="d-flex">
                                <Link
                                  to="/update-role-name"
                                  state={{
                                    group_uuid: user.uuid,
                                    group_name: user.name,
                                  }}
                                  hidden={PermissionCheck(
                                    module_name,
                                    "edit",
                                    user.group_uuid,
                                    user.user_created_by,
                                    user.created_by
                                  )}
                                  className="text-success"
                                ></Link>
                                <Button
                                  className="btn btn-danger shadow btn-xs sharp"
                                  onClick={() => deleteUser(user.uuid)}
                                  hidden={PermissionCheck(
                                    module_name,
                                    "delete",
                                    user.group_uuid,
                                    user.user_created_by,
                                    user.created_by
                                  )}
                                >
                                  <i className="fa fa-trash"></i>
                                </Button>
                              </div>
                            </Fragment>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Pagination
                    pageCount={pageCount}
                    handlePageNumberClick={handlePageClick}
                  />
                </div>{" "}
                {/* card body closed */}
                {/* </div>
                </Accordion> */}
              </div>
            </div>
          </div>

          {/* <!-- add role Modal --> */}
          <Modal className="fade" show={addUserModal}>
            <UserForm
              user={userData}
              closeModel={closeModel}
              handleUserEvent={handleUserEvent}
            />
          </Modal>

          <Modal
            className="fade bd-example-modal-lg"
            show={inviteUserModal}
            size="lg"
          >
            <UserInviteForm
              user={userData}
              closeInviteModel={closeInviteModel}
              handleUserEvent={handleUserEvent}
            />
          </Modal>
        </>
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Manage Users</h4>
              </div>
              <div className="card-body">
                <h3>You have don't access this modules</h3>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Users;
