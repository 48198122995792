import { SET_MESSAGE, API_FAIL } from "./types";

import InternalChatGroupService from "../services/internalchatgroup.service";

export const addGroup = (groupData, token) => (dispatch) => {
  return InternalChatGroupService.addGroup(groupData, token).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 200 ||
        data.status === 500
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const updateGroup = (data, token) => (dispatch) => {
  return InternalChatGroupService.updateGroup(data, token).then(
    (data) => {
      if (data.status === 406) {
        return data;
      } else if (data.status !== 200) {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      } else {
        return data;
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const updateGroupName = (name, internal_chat_group_uuid, main_uuid, createdby, token) => (dispatch) => {
  return InternalChatGroupService.updateGroupName(name, internal_chat_group_uuid, main_uuid, createdby, token).then(
    (data) => {
      if (data.status === 406) {
        return data;
      } else if (data.status !== 200) {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      } else {
        return data;
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
