import React, { Fragment, useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";

import {
  getDispositionList,
} from "../../actions/webphone";

function DispositionForm(props) {
  const closeModel = (arg) => {
    props.closeDispositionModal(arg);
  };

  const [dispositions, setDispositions] = useState([]);
  const [selectedDisposition, setSelectedDispositions] = useState({
    disposition : "",
    notes: "",
  });

   const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  useEffect(() => {
    dispatch(getDispositionList(createdby, main_uuid,token))
      .then((response) => {
          setDispositions(response.data.data);
        
        return;
      })
      .catch(() => {
        //setLoading(false);
      });
  }, []);

  const handalBlur = (e) => {
    e.preventDefault();

    let newData = {...selectedDisposition}
    newData[e.target.name] = e.targe.value; 

    setSelectedDispositions(newData);
    closeModel(false);
  }

  const submitHandler = (e) => {
    e.preventDefault();

    closeModel(false);
  }

  return (
    <Fragment>
      <Modal.Header>
        <Modal.Title>Add Disposition </Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <form action="" onSubmit={(e) => submitHandler(e)}>
        <Modal.Body>
          <div className="row">
            <div className="col-sm">
              <div className="form-group">
                <label className="mb-1">
                  <strong>{props.HangupCause}</strong>
                </label>
              </div>
              
            </div>
          </div>

          <div className="row">
            <div className="col-sm">
              <div className="form-group">
                <label className="mb-1">
                  <strong>Select Disposition</strong>
                </label>
                <select
                  className="form-control"
                  name="disposition"
                  onChange={(e) => handalBlur()}
                >
                  <option value="">Select Disposition</option>
                  {dispositions &&
                  dispositions.map((disposition) => (
                    <option key={disposition.disposition_uuid} value={disposition.name}>
                      {disposition.name}
                    </option>
                  ))}
                </select>
                {/* <span className="text-danger">{errors.disposition}</span> */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm">
              <div className="form-group">
                <label className="mb-1">
                  <strong>Notes</strong>
                </label>
                <input type="text" className="form-control" name="notes" onChange={(e) => handalBlur()} />
                  
                {/* <span className="text-danger">{errors.disposition}</span> */}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => closeModel(false)}
            className="btn-sm"
            variant="danger light"
          >
            Close
          </Button>

          <Button type="submit" name="submit" variant="primary btn-sm">
            Save
          </Button>

        </Modal.Footer>
      </form>
      
    </Fragment>
  );
}

export default DispositionForm;
