import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllPlanList } from "../../actions/plan";
import { Tab, Nav, Button } from "react-bootstrap";
import "./index.css";
import Loader from "../../layouts/Loader";

const PlanList = (props) => {
  const [planData, setPlanData] = useState([]);
  const [tabName, setTabName] = useState("quarterly");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(getAllPlanList())
      .then((response) => {
        setPlanData(response.data);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const tabData = [
    {
      name: "Quarterly",
      icon: "quarterly",
    },
    {
      name: "Annual",
      icon: "yearly",
    },
  ];

  const results = [];

  const tabChange = (tab_name) => {

    let final_price = 0;

    const newarr = { ...props.formData };

    planData.forEach((employee, index) => {
      if (tab_name === tabName) {
        final_price = parseFloat(employee.plan_price).toFixed(2);
        employee.final_price = final_price.toFixed(2);
      } else if (tab_name === "yearly") {
        final_price = parseFloat(employee.plan_price) * parseFloat(11);
        employee.final_price = final_price.toFixed(2);
        newarr.cart_data.months = 11;
      } else if (tab_name === "quarterly") {
        final_price = parseFloat(employee.plan_price) * 3;
        employee.final_price = final_price.toFixed(2);
        newarr.cart_data.months = 3;
      }

      results[index] = employee;
    });

    newarr.plan_data.final_price = final_price.toFixed(2);
    newarr.cart_data.sub_total = final_price.toFixed(2);
    newarr.cart_data.grand_total = final_price.toFixed(2);
    newarr.plan_data.type = tab_name;
    props.handleOneData(newarr);
    setPlanData(results);
    setTabName(tab_name);
  };

  const selectOnPlan = (
    plan_uuid,
    price,
    name,
    description,
    tabName,
    plan_price
  ) => {
    const newformdata = { ...props.formData };

    props.errors.plan_data.plan_uuid = "";

    newformdata.plan_data.plan_name = name;
    if (tabName === "yearly") {
      let months = 11;
      let final_p = parseFloat(plan_price) * parseInt(months);
      newformdata.plan_data.final_price = final_p.toFixed(2);
      newformdata.cart_data.sub_total = final_p.toFixed(2);
      newformdata.cart_data.total = parseFloat(plan_price).toFixed(2);
      newformdata.cart_data.grand_total = final_p.toFixed(2);
      newformdata.cart_data.months = 11;
    } else if (tabName === "quarterly") {
      let months = 3;
      let final_p = parseFloat(plan_price) * parseInt(months);
      newformdata.plan_data.final_price = final_p.toFixed(2);
      newformdata.cart_data.sub_total = final_p.toFixed(2);
      newformdata.cart_data.total = parseFloat(plan_price).toFixed(2);
      newformdata.cart_data.grand_total = final_p.toFixed(2);
      newformdata.cart_data.months = months;
    }

    newformdata.plan_data.description = description;
    newformdata.plan_data.type = tabName;
    newformdata.plan_data.plan_price = parseFloat(plan_price);
    newformdata.plan_data.plan_uuid = plan_uuid;
    props.planData(plan_uuid);
    props.handleOneData(newformdata);
    // navigate("/recordings", {
    //   state: { defaultTab: defaultTab },
    //   replace: true,
    // });
  };

  function countFinalPrice(basic_pride, months) {
    if (tabName === "yearly") {
      let final_p = basic_pride * 11;
      return final_p.toFixed(2);
    } else if (tabName === "quarterly") {
      let final_p = basic_pride * 3;
      return final_p.toFixed(2);
    }
  }

  return (
    <Fragment>
       {loading && <Loader />}
      <section className="pricewrap">
        <Tab.Container defaultActiveKey={props.formData.plan_data.type}>
          <Nav as="ul" className="nav-pills mb-2 light">
            {tabData.map((data, i) => (
              <Nav.Item as="li" key={i} onClick={() => tabChange(data.icon)}>
                <Nav.Link eventKey={data.name.toLowerCase()}>
                  {data.name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <Tab.Content className="pt-4">
            {tabData.map((data, i) => (
              <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                <div className="row" key={i}>
                  {planData.map((plan, j) => (
                    <div className="col-lg-4" key={j}>
                      <div className="card plan_card">
                        {plan.name === "Ultra" ? (
                          <div className="value-txt">Best Value</div>
                        ) : (
                          <></>
                        )}

                        {plan.name === "Advance" ? (
                          <div className="most-txt">Most Popular</div>
                        ) : (
                          <></>
                        )}

                        <h3>{plan.name}</h3>
                        <p>{plan.description}</p>
                        <div className="price-title">
                          {/* <span>$29</span> */}$
                          {countFinalPrice(
                            plan.plan_price,
                            props.formData.cart_data.months
                          )}
                        </div>
                        {tabName === "yearly" ? (
                          <p>/user/month - paid annually</p>
                        ) : (
                          <p>/user/month - paid quarterly</p>
                        )}

                        <div className="mb-3 text-center">
                          {plan.plan_uuid === props.planId ? (
                            <Button variant="success">
                              Selected{" "}
                              <span className="btn-icon-right">
                                <i className="fa fa-check" />
                              </span>
                            </Button>
                          ) : (
                            <button
                              type="button"
                              onClick={() =>
                                selectOnPlan(
                                  plan.plan_uuid,
                                  plan.final_price,
                                  plan.name,
                                  plan.description,
                                  tabName,
                                  plan.plan_price
                                )
                              }
                              className="btn btn-primary"
                            >
                              Buy Now
                            </button>
                          )}
                        </div>
                        <ul>
                          <li>
                            <span className="ti-check"></span> Unlimited
                            domestic calling*
                          </li>
                          <li>
                            <span className="ti-check"></span> SMS and MMS
                          </li>
                          <li>
                            <span className="ti-check"></span> IVR
                          </li>
                          <li>
                            <span className="ti-check"></span> Google, Microsoft
                            apps, and access to APIs
                          </li>
                          <li>
                            <span className="ti-check"></span> Single sign-on
                          </li>
                          <li>
                            <span className="ti-check"></span> Analytics for IT
                            administrations
                          </li>
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Tab.Container>
      </section>
    </Fragment>
  );
};

export default PlanList;
