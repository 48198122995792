import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./style.css";
import Tooltip from "rc-tooltip";
import EasyEdit, { Types } from "react-easy-edit";
import { getRecordingList } from "../../actions/recording";

function VoicemainFields(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  let extensionData = props.extensionData;

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [greetings, setGreetings] = useState({});
  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  let cls_name = "";
  if (extensionData.voicemail_enabled === "YES") {
    cls_name = "col-lg-6 col-md-6 col-6 vm_enable";
  } else {
    cls_name = "col-lg-6 col-md-6 col-6 vm_disable";
  }

  const handleChange = (e, default_val) => {
    let value = "";
    if (default_val === "YES") {
      value = "NO";
    } else {
      value = "YES";
    }

    let data = { ...extensionData };
    data[e.target.name] = value;
    extensionData = { ...data };

    props.save(extensionData);
  };

  useEffect(() => {
    dispatch(getRecordingList(createdby, token, "vm_greeting"))
      .then((response) => {
        if (response.data.data !== "") {
          let arrayObj = [];
          let i = 0;
          arrayObj = response.data.data.map((item) => {
            i = i + 1;
            return {
              label: item.recording_name,
              value: item.recording_uuid,
            };
          });
          arrayObj[i] = {
            label: "Add New File",
            value: "file_new_upload",
          };
          setGreetings(arrayObj);
        } else {
          // let arrayObj = [];
          // let i = 0;
          let arrayObj = [{
            label: "Add New File",
            value: "file_new_upload",
          }];

          setGreetings(arrayObj);
        }
        return;
      })
      .catch(() => {
        //setLoading(false);
      });
  }, []);

  const save = (value, attribute) => {
    if (attribute === "vm_greeting" && value === "file_new_upload") {
      navigate(`/upload-recording`, {
        state: {
          defaultTab: "vm_greeting",
          type: "update_ext",
          extension_uuid: extensionData.extension_uuid,
        },
      });
      return false;
    }

    let data = { ...extensionData };
    data[attribute] = value;
    props.save(data);
  };

  const CustomDisplay = (props) => {
    if (passwordType === "password") {
      return <div>**********</div>;
    } else {
      return (
        <div>
          <label>
            {extensionData.extension_password !== ""
              ? extensionData.extension_password
              : "None"}
          </label>
        </div>
      );
    }
  };

  let number_array = new Array(60).fill(1).map((_, i) => {
    return { label: i + 1, value: i + 1 };
  });

  return (
    <Fragment>
      <div className="row ml-2 mr-2">
        <div className="col-lg-6 col-md-6 col-6">
          <div className="card">
            <div className="card-header customToggle">
              <h4 className="card-title">
                VM Settings
                <Tooltip placement="top" overlay={<span>VM Settings</span>}>
                  <span className="ti-info-alt ml-1"></span>
                </Tooltip>
              </h4>
              <span className="custom-control custom-switch float-right">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="voicemail_enabled"
                  name="voicemail_enabled"
                  checked={
                    extensionData.voicemail_enabled === "YES" ? "checked" : ""
                  }
                  onChange={(e) =>
                    handleChange(e, extensionData.voicemail_enabled)
                  }
                />
                <label
                  className="custom-control-label check_box_pointer"
                  htmlFor="voicemail_enabled"
                ></label>
              </span>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="ml-3 form-group input-primary">
                    <label className="w-100">
                      Enabled
                      <Tooltip placement="top" overlay={<span>Enabled</span>}>
                        <span className="ti-info-alt ml-1"></span>
                      </Tooltip>
                    </label>

                    <p>{extensionData.extension}</p>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-5">
                  <div className="form-group input-primary">
                    <label className="w-100">
                      VM Password
                      <Tooltip
                        placement="top"
                        overlay={<span>VM Password</span>}
                      >
                        <span className="ti-info-alt ml-1"></span>
                      </Tooltip>
                    </label>

                    <EasyEdit
                      type={Types.TEXT}
                      onSave={(value) => {
                        save(value, "voicemail_password");
                      }}
                      //onCancel={cancel}
                      displayComponent={<CustomDisplay />}
                      saveButtonLabel={
                        <i className="fa fa-check" aria-hidden="true"></i>
                      }
                      saveButtonStyle="label-primary easy-edit-button rounded-lg"
                      cancelButtonLabel={
                        <i className="fa fa-times" aria-hidden="true"></i>
                      }
                      cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                      attributes={{
                        name: "voicemail_password",
                        className: "form-control bg-white h-75",
                      }}
                      //viewAttributes={{className: 'fs-20'}}
                      // onValidate={(value) => {
                      //     return value ? true : false;
                      // }}
                      //validationMessage="What just happened?"
                      placeholder="VM Password"
                      value={
                        extensionData.voicemail_password
                          ? extensionData.voicemail_password
                          : "None"
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-1 col-md-1 col-1">
                  <div className="mt-2">
                    {passwordType === "password" ? (
                      <i
                        className="fa fa-eye-slash"
                        onClick={togglePassword}
                      ></i>
                    ) : (
                      <i className="fa fa-eye" onClick={togglePassword}></i>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={cls_name}>
          <div className="card">
            <div className="card-header customToggle">
              <h4 className="card-title">
                Voicemail To email
                <Tooltip
                  placement="top"
                  overlay={<span>Voicemail To email</span>}
                >
                  <span className="ti-info-alt ml-1"></span>
                </Tooltip>
              </h4>
              <span className="custom-control custom-switch float-right">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="voicemail_mail_to_enabled"
                  name="voicemail_mail_to_enabled"
                  checked={
                    extensionData.voicemail_mail_to_enabled === "YES"
                      ? "checked"
                      : ""
                  }
                  onChange={(e) =>
                    handleChange(e, extensionData.voicemail_mail_to_enabled)
                  }
                />
                <label
                  className="custom-control-label check_box_pointer"
                  htmlFor="voicemail_mail_to_enabled"
                ></label>
              </span>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="form-group input-primary">
                    <label className="w-100">
                      Email
                      <Tooltip placement="top" overlay={<span>Email</span>}>
                        <span className="ti-info-alt ml-1"></span>
                      </Tooltip>
                    </label>
                    <EasyEdit
                      type={Types.TEXT}
                      onSave={(value) => {
                        save(value, "voicemail_mail_to");
                      }}
                      //onCancel={cancel}
                      saveButtonLabel={
                        <i className="fa fa-check" aria-hidden="true"></i>
                      }
                      saveButtonStyle="label-primary easy-edit-button rounded-lg"
                      cancelButtonLabel={
                        <i className="fa fa-times" aria-hidden="true"></i>
                      }
                      cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                      attributes={{
                        name: "voicemail_mail_to",
                        className: "form-control bg-white",
                      }}
                      onValidate={(value) => {
                        
                        if (value === "") {
                          return false;
                        } else if (
                          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                            value
                          )
                        ) {
                          return false;
                        } else {
                          return true;
                        }
                      }}
                      placeholder="Email"
                      value={
                        extensionData.voicemail_mail_to
                          ? extensionData.voicemail_mail_to
                          : "None"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row ml-2 mr-2">
        <div className={cls_name}>
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                Ring Time Out
                <Tooltip placement="top" overlay={<span>Ring Time Out</span>}>
                  <span className="ti-info-alt ml-1"></span>
                </Tooltip>
              </h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="form-group input-primary">
                    <label className="w-100">
                      Time Out
                      <Tooltip
                        placement="top"
                        overlay={<span>Time in seconds</span>}
                      >
                        <span className="ti-info-alt ml-1"></span>
                      </Tooltip>
                    </label>
                    <EasyEdit
                      type={Types.SELECT}
                      onSave={(value) => {
                        save(value, "ring_time_out");
                      }}
                      //onCancel={cancel}
                      options={number_array}
                      saveButtonLabel={
                        <i className="fa fa-check" aria-hidden="true"></i>
                      }
                      saveButtonStyle="label-primary easy-edit-button rounded-lg"
                      cancelButtonLabel={
                        <i className="fa fa-times" aria-hidden="true"></i>
                      }
                      cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                      attributes={{
                        name: "ring_time_out",
                        className: "form-control bg-white p-0 ",
                        style: { height: "35px" },
                      }}
                      placeholder="Time Out"
                      value={
                        extensionData.ring_time_out
                          ? extensionData.ring_time_out
                          : "None"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={cls_name}>
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                VM Greeting
                <Tooltip placement="top" overlay={<span>VM Greeting</span>}>
                  <span className="ti-info-alt ml-1"></span>
                </Tooltip>
              </h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="form-group input-primary">
                    <label className="w-100">
                      VM Access code
                      <Tooltip
                        placement="top"
                        overlay={<span>VM Access code</span>}
                      >
                        <span className="ti-info-alt ml-1"></span>
                      </Tooltip>
                    </label>

                    <p>*97</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="form-group input-primary">
                    <label className="w-100">
                      VM Greetings
                      <Tooltip
                        placement="top"
                        overlay={<span>VM Greetings</span>}
                      >
                        <span className="ti-info-alt ml-1"></span>
                      </Tooltip>
                    </label>

                    <EasyEdit
                      type={Types.SELECT}
                      onSave={(value) => {
                        save(value, "vm_greeting");
                      }}
                      //onCancel={cancel}
                      options={greetings}
                      saveButtonLabel={
                        <i className="fa fa-check" aria-hidden="true"></i>
                      }
                      saveButtonStyle="label-primary easy-edit-button rounded-lg"
                      cancelButtonLabel={
                        <i className="fa fa-times" aria-hidden="true"></i>
                      }
                      cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                      attributes={{
                        name: "vm_greetings",
                        className: "form-control bg-white p-0 vm_greeting",
                        style: { height: "35px" },
                      }}
                      placeholder="Please select VM Greetings"
                      value={
                        extensionData.vm_greeting
                          ? extensionData.vm_greeting
                          : null
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default VoicemainFields;
