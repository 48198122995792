import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import { updateRoute } from "../../actions/did";
import { getDestinationList } from "../../actions/comman";
import "./style.css";
import { Button, Modal } from "react-bootstrap";
import Loader from "../../layouts/Loader";

function RouteUpdate(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  let didData = props.didData;

  const [loading, setLoading] = useState(false);

  const closeModel = (arg) => {
    props.closeModel(arg);
  };

  const [didRoute, setDidRoute] = useState({
    route_name: "",
  });

  const [errors, setErrors] = useState({
    route_name: "",
  });

  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDestinationList(createdby, token, main_uuid, ""))
      .then((response) => {
        setOptions(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const handleChange = (e) => {
    let new_data = { ...didRoute };
    new_data["route_name"] = e.target.value;
    setDidRoute(new_data);
    return;
  };

  const fnUpdateRoute = (test) => {
    let error_data = { ...errors };
    let isError = "false";
    setLoading(true);

    if (didRoute.route_name.length === 0) {
      error_data["route_name"] = "Please select Route and Destination.";
      setErrors(error_data);
      isError = "true";
    } else {
      error_data["route_name"] = "";
      setErrors(error_data);
      isError = "false";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      let did_data = {};

      did_data["did_uuid"] = didData.did_uuid;
      did_data["did_number"] = didData.did_number;

      dispatch(updateRoute(createdby, token, main_uuid, didRoute, did_data))
        .then((response) => {
          if (response.status === 200) {
            notifyInfo(response.message);
            closeModel(false);
            setLoading(false);
            props.handleDidListEvent(Math.random);
          } else if (response.status === 500) {
            setLoading(false);
            notifyError("Something went wrong.");
          } else {
            setLoading(false);
            notifyError("Something went wrong.");
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const SelectInputRound = ({ groupedOptions }) => {
    return (
      <select
        className="inputSelectRound form-control override-fc"
        name="ivr_menu_option_action"
        data-id={"route_name"}
        onChange={(e) => handleChange(e)}
        value={didData.route_name}
      >
        {Object.keys(groupedOptions).map((group, index) => {
          return (
            <optgroup key={index} label={group}>
              {renderOptions(groupedOptions[group])}
            </optgroup>
          );
        })}
      </select>
    );
  };

  const groupedOptions = {};
  options.forEach((option) => {
    if (option.value !== "did_" + didData.did_uuid) {
      if (!groupedOptions[option.optgroup])
        groupedOptions[option.optgroup] = [];
      groupedOptions[option.optgroup].push({
        value: option.value,
        text: option.text,
      });
    }
  });

  const renderOptions = (options) => {
    return options.map((option) => {
      return (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      );
    });
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>Update Route</Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <SelectInputRound groupedOptions={groupedOptions} />
          <span className="text-danger">{errors.route_name}</span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger light btn-sm" onClick={() => closeModel(false)}>
          Close
        </Button>
        <Button
          variant=""
          type="button"
          onClick={() => fnUpdateRoute("")}
          className="btn btn-sm btn-primary"
        >
          Save changes
        </Button>
      </Modal.Footer>
    </Fragment>
  );
}

export default RouteUpdate;
