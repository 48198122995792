import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { deleteGroupMember } from "../../actions/internalChat";
import Loader from "../../layouts/Loader";

const groupMemberDelete = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;

  let group_delete = props.groupDelete;

  const closeModel = (arg) => {
    props.closeModel(arg);
  };

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  function deleteMember(data) {
    setLoading(true);
    dispatch(
      deleteGroupMember(
        props.memberUuid,
        props.groupUuid,
        group_delete,
        createdby,
        token
      )
    )
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          props.closeModel(false);
          props.handleGroupDeleteEvent();

          // notifyInfo(response.message);
          return;
        } else if (response.status === 500) {
          // notifyError("Something went wrong.");
        } else {
          // notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }

  let msg = "";

  if (group_delete === "all") {
    msg = "remove this group";
  } else {
    msg = "delete this group member";
  }

  return (
    <Fragment>
      {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>Group Member Delete</Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>

      <Modal.Body>
        <span>Are are sure want to {msg}?</span>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => closeModel(false)}
          className="btn-sm"
          variant="danger light"
        >
          Close
        </Button>
        <button
          type="button"
          onClick={() => deleteMember(props.memberUuid)}
          className="btn btn-sm btn-danger mr-2"
        >
          Delete
        </button>
      </Modal.Footer>
    </Fragment>
  );
};

export default groupMemberDelete;
