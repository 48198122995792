import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import { getSetCallarIdNumber, ReassignCallarId } from "../../actions/comman";
import "./style.css";
import { Button, Modal } from "react-bootstrap";
import ReAssignDid from "./ReAssignDid";
import Loader from "../../layouts/Loader";

function DeleteDid(props) {
  let didData = props.didData;
  const token = props.didData.token;
  const createdby = props.didData.createdby;
  const main_uuid = props.didData.main_uuid;
  const did_number = props.didData.did_number;

  const [loading, setLoading] = useState(false);

  const closeModel = (arg) => {
    props.closeModel(arg);
  };
  const [reassignDidModal, setReassignDidModal] = useState(false);

  const closeReassignDidModel = (value) => {
    setReassignDidModal(value);
  };

  const [errors, setErrors] = useState({
    did: "",
  });

  const [extensionsData, setExtensionsData] = useState([]);
  const [extensions, setExtensions] = useState([]);

  const dispatch = useDispatch();

  let ext = "";

  useEffect(() => {
    setLoading(true);
    dispatch(getSetCallarIdNumber(createdby, token, main_uuid, did_number))
      .then((response) => {
        let data = response.data;

        if (data !== "") {
          data.forEach((employee) => {
            ext += employee.extension + ",";
          });
          ext = ext.slice(0, -1);
          setExtensions(ext);
          setExtensionsData(response.data);
          setLoading(false);
          return;
        } else {
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const deleteInboundNumber = (status, did_number, extensionsData) => {
    let error_data = { ...errors };
    let isError = "false";

    if (status === "Yes") {
      error_data["did"] =
        "Please re-assign callar id first then this inbound number delete.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setErrors(error_data);
      return false;
    }

    setLoading(true);
    dispatch(
      ReassignCallarId(
        createdby,
        token,
        main_uuid,
        did_number,
        extensionsData,
        status
      )
    )
      .then((response) => {
        if (response.status === 200) {
          notifyInfo(response.message);
          closeModel(false);
          setLoading(false);
          props.closeMainModel(false);
          props.handleDidListEvent(Math.random);
        } else if (response.status === 500) {
          setLoading(false);
          notifyError("Something went wrong.");
        } else {
          setLoading(false);
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>Delete Inbound Number - {did_number}</Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        {extensions !== "" ? (
          <>
            <div className="row">
              This number is being used as the CID for the following extensions:{" "}
              {extensions}. Please reassign the CID for these extensions before
              you can delete this number.
            </div>
            <div className="row">
              <div className="col-12">
                Re assign another DID Number to{" "}
                <Button
                  variant="success light btn-sm"
                  onClick={() => setReassignDidModal(true)}
                >
                  Click Here
                </Button>
              </div>
            </div>
          </>
        ) : (
          <div className="row">
            This number did number is not use any extentions in as callar id.
          </div>
        )}
        <span className="text-danger">{errors.did}</span>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger light btn-sm" onClick={() => closeModel(false)}>
          Close
        </Button>
        {extensions !== "" ? (
          <Button
            variant=""
            type="button"
            onClick={() =>
              deleteInboundNumber("Yes", did_number, extensionsData)
            }
            className="btn btn-sm btn-danger"
          >
            Delete
          </Button>
        ) : (
          <Button
            variant=""
            type="button"
            onClick={() =>
              deleteInboundNumber("No", did_number, extensionsData)
            }
            className="btn btn-sm btn-danger"
          >
            Delete
          </Button>
        )}
      </Modal.Footer>

      <Modal className="fade" show={reassignDidModal}>
        <ReAssignDid
          closeModel={closeReassignDidModel}
          closeMainModel={closeModel}
          handleSendSmsEvent={""}
          didData={didData}
          extensionsData={extensionsData}
          deleteInboundNumber={deleteInboundNumber}
        />
      </Modal>
    </Fragment>
  );
}

export default DeleteDid;
