import React, { Fragment } from "react";
import { Tab, Nav, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
 import { deleteTab,setCustomFieldPosition } from "../../../actions/customContactLayout";
import Tooltip from "rc-tooltip";
import { confirmAlert } from "react-confirm-alert";
import { notifyInfo, notifyError } from "../../Toastr/Toastr";

function Section(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  let formData = props.formData;
  let sectionIndex = props.sectionIndex;
  let activeTab = formData.section_type === 'overview'? 'overview' : formData.tabs.length > 0 ? formData.tabs[0].contact_form_tab_uuid : '';

  const dispatch = useDispatch();
  
  const addNewTab = () => {
    props.handleAddTab(formData.contact_form_section_uuid);
  }

  const FndeleteTab = (e,tab) => {
    
    confirmAlert({
      title: "",
      message: "Are you sure to delete this tab?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteTabService(tab),
        },
        {
          label: "No",
        },
      ],
    });

  }

  const deleteTabService = (tab) => {
   
    let data = {
      tab_uuid : tab.contact_form_tab_uuid,
      tab_order : tab.tab_order,
      section_uuid : tab.contact_form_section_uuid,
      main_user_uuid : main_uuid,
      createdby : createdby,
    }
    dispatch(deleteTab(data, token))
      .then((response) => {

        if (response.status === 200) {
          notifyInfo(response.message);
          props.handleFormEvent(Math.random);
          return;
        } else if (response.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
        
      })
      .catch(() => {
        //setLoading(false);
      });
  };

  const removeField = (e,custom_field_uuid) => {
    let empty_uuid = '00000000-0000-0000-0000-000000000000';

    let data = {
      tab_uuid : empty_uuid,
      section_uuid : empty_uuid,
      custom_field_uuid : custom_field_uuid,
      section_type : null,
      main_user_uuid : main_uuid,
      createdby : createdby,
    }
    dispatch(setCustomFieldPosition(data, token))
      .then((response) => {

        if (response.status === 200) {
          //notifyInfo(response.message);
          props.handleFormEvent(Math.random);
          props.handleCustomFieldEvent(Math.random);
          return;
        } else if (response.status === 500) {
          //notifyError("Something went wrong.");
        } else {
          //notifyError("Something went wrong.");
        }
        
      })
      .catch(() => {
        //setLoading(false);
      });
  }

  return (
    <Fragment>
          <span className="contact-form">
          <Tab.Container defaultActiveKey={activeTab} >
            <Nav as='ul' className='nav-tabs ringtabs mt-4'>
                
                {formData.section_type === 'overview' && 
                  <>
                  <Nav.Item as='li' key="1">
                    <Nav.Link eventKey="overview">
                        {formData.section_title}
                    </Nav.Link>
                  </Nav.Item>
                  
                  </>
                }                 

                {formData.tabs.map((tab) => (

                  <Nav.Item as='li' key={tab.contact_form_tab_uuid} onDragStart={(e) => props.handleSectionDragStart(e, sectionIndex)} draggable onDrop={(e) => props.handleSectionDrop(sectionIndex, e)}
                  onDragOver={props.handleDragOver} className="cursor-drag">
                    {/* <Nav.Link eventKey={tab.contact_form_tab_uuid} onclick={props.setCurrentTab(tab.contact_form_tab_uuid,tab.contact_form_section_uuid)}> */}
                    <Nav.Link eventKey={tab.contact_form_tab_uuid} className="cursor-drag" >
                        {tab.tab_name}
                        <button className="ml-2 btn btn-xxs btn-outline-danger btn-rounded pull-right" onClick={(e) => FndeleteTab(e,tab)}>-</button>
                    </Nav.Link>
                  </Nav.Item>
                ))}

                <Nav.Item as='li' key="2" >
                    <Tooltip
                      placement="top"
                      overlay={<span>Add New Tab</span>}
                    >
                    <Button
                      type="button"
                      className="btn btn-primary btn-sm ml-2 mt-1"
                      onClick={addNewTab}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i>
                    </Button>
                  </Tooltip>
                </Nav.Item>        
            </Nav>
          
            <Tab.Content className='ringgroup'>
                
                {formData.section_type === 'overview' && 
                  (
                    <Tab.Pane eventKey="overview" key="1" className="pt-4">
                      <div className="row">
                        
                        <div className="col-lg-6 col-md-6 col-6">
                            <div className="card">
                                <div className="card-body">
                                  
                                  <div className="h6">Contact Title</div>                              
                                  <div className="h6">Job Title</div>
                                  <div className="h6">Bussiness Name</div>
                                  <div className="h6">First Name</div>
                                  <div className="h6">Email</div>
                                  <div className="h6">Work Contact Number</div>                                
                                  <div className="h6">Address 1</div>
                                  <div className="h6">Country</div>
                                  <div className="h6">City</div>
                                  <div className="h6">Facebook Url</div>
                                  <div className="h6">Instagram Url</div>
                                  <div className="h6">Persona</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                            <div className="card">
                                <div className="card-body">

                                  <div className="h6">Contact Type</div>
                                  <div className="h6">Job Industry</div>
                                  <div className="h6">Bussiness Site</div>
                                  <div className="h6">Last Name</div>
                                  <div className="h6">Assign To</div>
                                  <div className="h6">Other Contact Number</div>                              
                                  <div className="h6">Address 2</div>
                                  <div className="h6">State</div>
                                  <div className="h6">Zipcode</div>
                                  <div className="h6">LinkedIn Url</div>
                                  <div className="h6">Twitter Url</div>
                                  <div className="h6">Tabs</div>
                                </div>
                            </div>
                        </div>
                      </div>
                      
                    </Tab.Pane>
                  )
                }

                {formData.tabs.map((tab) => ( 
                
                  <Tab.Pane eventKey={tab.contact_form_tab_uuid} key={tab.contact_form_tab_uuid} className="pt-4" >
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-6" onDrop={(e) => props.handleDrop(e,tab,'section_1')}
                  onDragOver={props.handleDragOver}>
                          <div className="card">
                            

                              <div className="card-body">
                                <span>
                                  <Tooltip
                                    placement="top"
                                    overlay={<span>Add New Field</span>}
                                  >
                                    <button onClick={() => props.handleAddField(tab,'section_1')} className="btn btn-sm btn-primary pull-right">
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                    </button>
                                  </Tooltip>
                                </span>

                                {tab.sec_one_fileds.map((filed) => (
                                  <span key={filed.custom_field_uuid}>
                                    <p className="mb-0"  draggable onDragStart={(e) => props.handleDragStart(e, filed.custom_field_uuid)}>
                                      <span className="h4 cursor-drag " >{filed.field_name}</span>
                                      <span className="h4">
                                        <button className="btn btn-sm text-danger" onClick={(e) => removeField(e,filed.custom_field_uuid)}><i className="fa fa-times" aria-hidden="true"></i></button>
                                      </span>
                                    </p>

                                    {filed.dependent_fields.map((dependent_field) => (
                                 
                                        <div key={dependent_field.contact_custom_dependent_field_uuid} className="h4 text-muted ml-3 " >
                                          <i className="fa fa-level-up mr-2" aria-hidden="true" style={{transform: "rotate(90deg)"}}></i>
                                          {dependent_field.filed_name}
                                        </div>
                                        
                                    ))}

                                  </span>
                                ))}

                              </div>
                          </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-6" onDrop={(e) => props.handleDrop(e,tab,'section_2')}
                  onDragOver={props.handleDragOver}>
                          <div className="card">
                              <div className="card-body">
                                <span>
                                  <Tooltip
                                    placement="top"
                                    overlay={<span>Add New Field</span>}
                                  >
                                    <button onClick={() => props.handleAddField(tab,'section_2')} className="btn btn-sm btn-primary pull-right">
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                    </button>
                                  </Tooltip>
                                </span>

                                {tab.sec_two_fileds.map((filed) => (
                                  <span key={filed.custom_field_uuid}>
                                    <p className="mb-0"  draggable onDragStart={(e) => props.handleDragStart(e, filed.custom_field_uuid)}>
                                      <span className="h4 cursor-drag " >{filed.field_name}</span>
                                      <span className="h4">
                                        <button className="btn btn-sm text-danger" onClick={(e) => removeField(e,filed.custom_field_uuid)}><i className="fa fa-times" aria-hidden="true"></i></button>
                                      </span>
                                    </p>

                                    {filed.dependent_fields.map((dependent_field) => (
                                 
                                        <div key={dependent_field.contact_custom_dependent_field_uuid} className="h4 text-muted ml-3 " >{dependent_field.filed_name}</div>
                                        
                                    ))}

                                  </span>
                                ))}
                              
                              {/* 
                                {tab.sec_two_fileds.map((filed) => (
                                  <p key={filed.custom_field_uuid} draggable onDragStart={(e) => props.handleDragStart(e, filed.custom_field_uuid)}>
                                    <span className="h4 cursor-drag ">{filed.field_name}</span>
                                    <span className="h4">
                                      <button className="btn btn-sm text-danger" onClick={(e) => removeField(e,filed.custom_field_uuid)}><i className="fa fa-times" aria-hidden="true"></i></button>
                                    </span>
                                  </p>
                                ))} */}
                               

                              </div>
                          </div>
                      </div>
                      
                    </div>

                   
                  </Tab.Pane>

                ))}

            </Tab.Content>

          </Tab.Container>
          </span>
    </Fragment>
  );
}

export default Section;
