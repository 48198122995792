import React, { Component } from "react";

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";

///
// import drump from "../../../images/card/drump.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
    // this.mm("dispose");
    // console.log(this.mm);
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBarCrm extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");

    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }

    btn.addEventListener("click", toggleFunc);

    //sidebar icon Heart blast
    var handleheartBlast = document.querySelector(".heart");

    function heartBlast() {
      return handleheartBlast.classList.toggle("heart-blast");
    }

    handleheartBlast.addEventListener("click", heartBlast);
  }
  render() {
    /// Path
    var path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];
    /// Active menu

    let table = ["table-bootstrap-basic", "table-datatable-basic"],
      pages = [
        "register",
        "login",
        "lock-screen",
        "error-400",
        "error-403",
        "error-404",
        "error-500",
        "error-503",
      ];
    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${table.includes(path) ? "mm-active" : ""}`}>
              <Link
                className="has-arrow ai-icon"
                to="/contact-management"
                aria-expanded="false"
              >
                <i className="flaticon-381-network"></i>
                <span className="nav-text">Table ff</span>
              </Link>
            </li>
            <li className={`${pages.includes(path) ? "mm-active" : ""}`}>
              <Link
                className="has-arrow ai-icon"
                to="/sequence"
                aria-expanded="false"
              >
                <i className="flaticon-381-layer-1"></i>
                <span className="nav-text">Pages</span>
              </Link>
            </li>
            <li>
              <Link
                className="has-arrow ai-icon"
                to="/login"
                aria-expanded="false"
              >
                <i className="flaticon-381-layer-1"></i>
                <span className="nav-text">Pages</span>
              </Link>
            </li>
          </MM>
          <div className="copyright">
            <p className="fs-14 font-w200">
              <strong className="font-w400">Eclan Dashboard</strong> Dashboard ©
              2021 All Rights Reserved
            </p>
            <p>
              Made with <span className="heart"></span> by DexignZone
            </p>
          </div>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBarCrm;
