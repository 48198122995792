import React, { Fragment, useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { notifyError } from "../../Toastr/Toastr";
import { getUserMeetingAvailability,deleteUserMeetingAvailability } from "../../../actions/calender/UserMeetingAvailability";
import Loader from "../../../layouts/Loader";
import TimeSlotForm from "./TimeSlotForm";
import { confirmAlert } from "react-confirm-alert";

function Availability(props) {
  const closeModel = (arg) => {
    props.closeModel(arg);
  };

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;

  const [addTimeSlotForm, setAddTimeSlotForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState('');
  const [userAvailability, setUserAvailability] = useState([]);

  const times = [];
  for (let i = 0; i < 24; i++) {
    times.push(
      `${i % 12 || 12}:00 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:15 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:30 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:45 ${i < 12 ? "AM" : "PM"}`
    );
  }

  let days = [{key : 'mon',value:'Monday'}, {key : 'tue',value:'Tuesday'}, {key : 'wed',value:'Wednesday'}, {key : 'thu',value:'Thursday'}, {key : 'fri',value:'Friday'}, {key : 'sat',value:'Saturday'}, {key : 'sun',value:'Sunday'}];

  const [selectDay, setSelectedDay] = useState({
    'day' : 'mon',
    'data' : [],
  });

  const [updateData, setUpdateData] = useState({
    day: selectDay.day,
    slot_title: "",
    start_time: "",
    end_time: "",
    meeting_modes : [],
    action : 'add',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getUserMeetingAvailability(
        createdby,
        token
      )
    )
      .then((response) => {
        setUserAvailability(response.data);
        changeSelectedDay(selectDay.day,response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, [event]);


  const changeSelectedDay = (day,data = '') => {    
    
    if(data === ''){
      data = userAvailability;
    }

    let selectedData = {...selectDay}
    selectedData['data'] = data.filter((row) => row.day === day);
    selectedData['day'] = day;

    setSelectedDay(selectedData);
  }


  const toggleAddSlotForm = () => {
    if(addTimeSlotForm){
      setAddTimeSlotForm(false);
    }else{
      let newUpdatedata = {...updateData}

      newUpdatedata['day'] = selectDay.day;
      newUpdatedata['action']  = 'add';
      
      setUpdateData(newUpdatedata);
      setAddTimeSlotForm(true);
    }
  }

  const handleEvent = (value) => {
    setEvent(value);
    setAddTimeSlotForm(false);
  }

  const fnDeleteTimeSlot = (user_meeting_availability_uuid) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this time slot?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteTimeSlotService(user_meeting_availability_uuid),
        },
        {
          label: "No",
        },
      ],
    });
  }

  const deleteTimeSlotService = (user_meeting_availability_uuid) => {
    dispatch(deleteUserMeetingAvailability(user_meeting_availability_uuid,createdby,token))
      .then((res) => {
        if (res.status === 200) {

          setEvent(Math.random);
        } else if (res.status === 500) {
          setLoading(false);
          notifyError("Something went wrong.");
        } else {
          setLoading(false);
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }

  const updateSlot = (data) => {
    let newUpdatedata = {...updateData}

    newUpdatedata['user_meeting_availability_uuid'] = data.user_meeting_availability_uuid;
    newUpdatedata['day'] = data.day;
    newUpdatedata['start_time'] = data.start_time;
    newUpdatedata['end_time'] = data.end_time;
    newUpdatedata['meeting_modes'] = [];
    newUpdatedata['action']  = 'update';
    
    setUpdateData(newUpdatedata);
    setAddTimeSlotForm(true);
  }

  const closeForm = () => {
    let newUpdatedata = {...updateData}
    newUpdatedata['day'] = selectDay.day;
    newUpdatedata['start_time'] = '';
    newUpdatedata['end_time'] = '';
    newUpdatedata['meeting_modes'] = [];
    newUpdatedata['action']  = 'add';

    setUpdateData(newUpdatedata);
    setAddTimeSlotForm(false);
  }

  return (
    <Fragment>
      {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>User Availability </Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      
        <Modal.Body>

          <div className="row">
            <div className="col-md-3 basic-list-group">
              <div className="list-group">
                {days &&
                  days.map((day, i) => ( 
                    <a href="#!" key={i} 
                      className={`${
                        selectDay.day === day.key
                          ? "list-group-item list-group-item-action active"
                          : "list-group-item list-group-item-action"
                        }`}
                      onClick={(e) => {e.preventDefault(); changeSelectedDay(day.key)}} 
                    >
                      {day.value.toUpperCase()}
                    </a> 
                  ))
                }
                                                              
              </div>
            </div>

            <div className="col-md-9">
              <h4 className="card-title">Schedule - {selectDay.day.toUpperCase()}</h4>

              {selectDay.data &&
                selectDay.data.map((row, i) => ( 
                  <div className="form-row mt-2 border-bottom" key={row.user_meeting_availability_uuid} > 

                    <div className="col mt-2 mt-sm-0">
                      {row.start_time}
                    </div>
    
                    <div className="col mt-2 mt-sm-0">
                      {row.end_time}            
                    </div>
    
                    <div className="mt-1 text-center p-1">
                        <Button
                          type="button"
                          className="btn btn-info shadow btn-xs sharp mr-1"
                          onClick={(e) =>{ updateSlot(row)}}
                        >
                          <i className="fa fa-pencil"></i>
                        </Button>

                        <Button
                            type="button"
                            className="btn btn-danger shadow btn-xs sharp"
                            onClick={(e) =>{ fnDeleteTimeSlot(row.user_meeting_availability_uuid)}}
                        >
                            <i className="fa fa-trash"></i>
                        </Button>
                    </div>
    
                  </div>
                ))
              }
              

              <div className="form-row mt-4 mb-2"> 
                <div className="col mt-2 mt-sm-0">
                    
                    <a href="#!" className="text text-success mb-2 mr-2" onClick={toggleAddSlotForm} >   
                        <i className="fa fa-plus mr-2"></i>
                        Add New Time
                    </a>
                </div>
              </div>

              {addTimeSlotForm &&
                <TimeSlotForm
                    updateData={updateData}
                    handleEvent={handleEvent}
                    closeForm={closeForm}
                />
              }

            </div>
          </div>
          
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => closeModel(false)}
            variant="danger light btn-sm"
          >
            Close
          </Button>
          
        </Modal.Footer>
    </Fragment>
  );
}

export default Availability;
