import React, { Fragment, useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import { getRoleDetail, updateRole } from "../../actions/role";
import Loader from "../../layouts/Loader";

function UpdateRoleForm(props) {
  const closeModel = (arg) => {
    props.closeModel(arg);
  };

  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useLocation();
  let role_uuid = params.state.role_uuid;

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;

  const [roleData, setRoleData] = useState({
    role_name: "",
    createdby: createdby,
    main_admin_uuid: createdby,
    user_uuid: createdby,
  });

  const [errors, setErrors] = useState({
    role_name: "",
  });

  const handleBlur = (e) => {
    const newRoledata = { ...roleData };
    let error_data = { ...errors };
    setLoading(true);

    if (e.target.name === "role_name" && e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }
    newRoledata[e.target.name] = e.target.value;
    setRoleData(newRoledata);
  };

  useEffect(() => {
    dispatch(getRoleDetail(role_uuid, createdby, token))
      .then((response) => {
        setRoleData(response.data[0]);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (roleData.role_name.length === 0) {
      error_data["role_name"] = "Role name is required.";
      setErrors(error_data);
      isError = "true";
    }

    setLoading(true);

    if (isError === "true") {
      setErrors(error_data);
      setLoading(false);
      return false;
    }

    dispatch(updateRole(roleData.role_name, role_uuid, createdby, token))
      .then((res) => {
        if (res.status === 200) {
          closeModel(false);
          notifyInfo(res.message);
          props.handleRoleEvent("added");
          setLoading(false);
          navigate("/roles", {
            state: { role_uuid: role_uuid },
          });
        } else if (res.status === 406) {
          error_data["role_name"] = res.message;
          setErrors(error_data);
          isError = "true";
        } else if (res.status === 500) {
          setLoading(false);
          notifyError("Something went wrong.");
        } else {
          setLoading(false);
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>Update Role - {roleData.role_name}</Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <form action="" onSubmit={(e) => submitHandler(e)}>
        <Modal.Body>
          <div className="form-group">
            <label className="mb-1">
              <strong>Role Name</strong>
              <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control time_field_hp"
              placeholder="Role Name"
              name="role_name"
              defaultValue={roleData.role_name}
              onChange={handleBlur}
            />
            <span className="text-danger">{errors.role_name}</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => closeModel(false)}
            className="btn btn-sm btn-danger"
            variant="danger light"
          >
            Close
          </Button>
          <Button
            type="submit"
            className="btn btn-sm btn-primary"
            variant="primary"
          >
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Fragment>
  );
}

export default UpdateRoleForm;
