import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const getExtensions = (
  user_type,
  group_per,
  group_uuid,
  role,
  permission,
  role_uuid,
  search,
  off_set,
  limits,
  orderby,
  main_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "get-extensions-list",
      {
        user_type,
        group_per,
        group_uuid,
        role,
        permission,
        role_uuid,
        search,
        off_set,
        limits,
        orderby,
        createdby,
        main_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const createExtension = (data, token) => {
  return axios
    .post(API_URL + "create-extensions", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const updateExtension = (data, token) => {
  return axios
    .post(API_URL + "update-extensions", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getExtensionDetail = (createdby, token, extuuid) => {
  return axios
    .post(
      API_URL + "get-extensions-details",
      {
        createdby,
        extuuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getCallForwardDetail = (createdby, token, extuuid) => {
  return axios
    .post(
      API_URL + "get-call-forward-details",
      {
        createdby,
        extuuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getExtensionList = (createdby, token, main_uuid) => {
  return axios
    .post(
      API_URL + "get-extensions-dropdown-list",
      {
        createdby,
        main_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getUserExtension = (user_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "get-user-extensions",
      {
        createdby,
        user_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const deleteExtension = (extension_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "delete-extensions",
      {
        extension_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getNextExtensionID = (createdby, main_admin_uuid, token) => {
  return axios
    .post(
      API_URL + "get-next-extension",
      {
        createdby,
        main_admin_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const copyExtensions = (extension_uuid, createdby, main_admin_uuid, token) => {
  return axios
    .post(
      API_URL + "copy-extension",
      {
        extension_uuid,
        createdby,
        main_admin_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

export default {
  getExtensions,
  createExtension,
  updateExtension,
  getExtensionDetail,
  getExtensionList,
  getCallForwardDetail,
  getUserExtension,
  deleteExtension,
  getNextExtensionID,
  copyExtensions,
};
