import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Table,
  Button,
  ButtonGroup,
  DropdownButton,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import PageTitle from "../../layouts/PageTitle";
import Pagination from "../../layouts/Pagination";
import { getIvrs, deleteIvr } from "../../actions/ivr";
import { checkModuleAssign } from "../../actions/comman";
import CheckModulePermisson from "../../common/CheckModulePermisson";
import PermissionCheck from "../../common/PermissionCheck";
import moment from "moment";
import Loader from "../../layouts/Loader";

function Ivrs() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;
  const module_name = "ivr";

  let module_per = CheckModulePermisson(module_name);

  let isPermission = true;
  if (module_per === "" || user_type === "admin") {
    isPermission = true;
  } else {
    isPermission = false;
  }

  let listing_per = PermissionCheck(module_name, "listing", "", "", "");
  let add_per = PermissionCheck(module_name, "add", "", "", "");

  const dispatch = useDispatch();
  let navigate = useNavigate();

  if (listing_per === "none") {
    navigate("/error-403");
  }

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }

  let search = "";
  //let limits = 10;
  let orderby = "ivr_menu_extension ASC";

  const [limits, setLimits] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [ivrs, setIvrs] = useState([]);
  const [ivrEvent, setIvrEvent] = useState("");
  const [pageCount, setpageCount] = useState(0);
  const [offSet, setOffSet] = useState(0);

  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteConfirmData, setDeleteConfirmData] = useState([]);

  if (module_per === "" || user_type === "admin") {
    useEffect(() => {
      
      setLoading(true);
      dispatch(
        getIvrs(
          user_type,
          group_per,
          group_uuid,
          search,
          offSet,
          limits,
          orderby,
          main_uuid,
          createdby,
          token
        )
      )
        .then((response) => {
          setIvrs(response.data.data);
          const total = response.data.total;
          setTotalRecords(total);
          setpageCount(Math.ceil(total / limits));
          setIvrEvent("");
          setLoading(false);
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    }, [ivrEvent, limits]);
  }

  const deleteIvrs = (ivr_menu_uuid) => {
    dispatch(
      checkModuleAssign("ivr", ivr_menu_uuid, "destination", createdby, token)
    )
      .then((response) => {
        if (response.status === 200) {
          //let message = "Are you sure to delete this file?";
          if (response.data.length > 0) {
            //let message = "Are you sure to delete this file?";
            setDeleteConfirmData(response.data);
            setDeleteModal(true);
          } else {
            confirmAlert({
              title: "",
              message: "Are you sure to delete this IVR?",
              buttons: [
                {
                  label: "Yes",
                  onClick: () => deleteIvrService(ivr_menu_uuid),
                },
                {
                  label: "No",
                },
              ],
            });
          }

          return;
        } else if (response.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const deleteIvrService = (ivr_menu_uuid) => {
    setLoading(true);
    dispatch(deleteIvr(ivr_menu_uuid, createdby, token))
      .then((response) => {
        if (response.status === 200) {
          notifyInfo(response.message);
          setIvrEvent("delete");
          setLoading(false);
          return;
        } else if (response.status === 500) {
          setLoading(false);
          notifyError("Something went wrong.");
        } else {
          setLoading(false);
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handlePageClick = async (data) => {
    setOffSet(limits * data.selected);
    setIvrEvent(Math.random);
  };

  const chnageLimit = (value) => {
    setLimits(value);
  };

  const breadCurmList = [
    { label: "Calls", link: "/", is_active: "NO" },
    { label: "Auto-Attendant", link: "", is_active: "YES" },
    { label: "Total Amount :" + totalRecords, link: "", is_active: "YES" },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
     
      <PageTitle breadCurmList={breadCurmList} />
      {isPermission ? (
        <>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Manage Auto-Attendant</h4>
                  {add_per === "yes" ? (
                    <Button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={() => navigate("/add-ivr", { replace: true })}
                    >
                      Add New Auto-Attendant
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="card-body">
                  Show
                  <ButtonGroup className="ml-2 mr-2">
                    <DropdownButton
                      as={ButtonGroup}
                      id="bg-nested-dropdown"
                      size="sm"
                      title={limits}
                      variant="custom-dropdown"
                    >
                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(10)}
                      >
                        10
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(25)}
                      >
                        25
                      </Dropdown.Item>

                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(50)}
                      >
                        50
                      </Dropdown.Item>

                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(100)}
                      >
                        100
                      </Dropdown.Item>
                    </DropdownButton>
                  </ButtonGroup>
                  entries
                  <Table responsive className="w-100">
                    <thead>
                      <tr role="row">
                        <th>ID</th>
                        <th>Name</th>
                        <th>Greeting</th>
                        {/* <th>Description</th> */}
                        {/* {listing_per === "group" || user_type === "admin" ? (
                          <th>Created By</th>
                        ) : (
                          <></>
                        )} */}
                        <th>Date Created</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {ivrs.map((ivr) => (
                        <tr key={ivr.ivr_menu_uuid}>
                          <td>{ivr.ivr_menu_extension}</td>
                          <td>
                            {PermissionCheck(
                              module_name,
                              "edit",
                              ivr.group_uuid,
                              ivr.user_created_by,
                              ivr.created_by
                            ) === "hidden" ? (
                              <> {ivr.ivr_menu_name}</>
                            ) : (
                              <Link
                                to="/update-ivr"
                                state={{
                                  ivr_menu_uuid: ivr.ivr_menu_uuid,
                                  activeTab: "general",
                                }}
                                className="text-blue"
                              >
                                {ivr.ivr_menu_name}
                              </Link>
                            )}
                          </td>
                          <td>{ivr.recording_filename}</td>
                          {/* <td>{ivr.ivr_menu_description}</td> */}
                          <td>{moment(ivr.created_at).format("MM-DD-YYYY")}</td>
                          {/* {listing_per === "group" ||
                          listing_per === "all" ||
                          user_type === "admin" ? (
                            <td>
                              {ivr.first_name} {ivr.last_name}
                            </td>
                          ) : (
                            <></>
                          )} */}
                          <td>
                            <Fragment>
                              <div className="d-flex">
                                {
                                  <Link
                                    to="/update-ivr"
                                    state={{
                                      ivr_menu_uuid: ivr.ivr_menu_uuid,
                                      activeTab: "general",
                                    }}
                                    hidden={PermissionCheck(
                                      module_name,
                                      "edit",
                                      ivr.group_uuid,
                                      ivr.user_created_by,
                                      ivr.created_by
                                    )}
                                    className="btn btn-primary shadow btn-xs sharp mr-1"
                                  >
                                    <i className="fa fa-pencil"></i>
                                  </Link>
                                }
                                <Link
                                  to="#"
                                  onClick={() => deleteIvrs(ivr.ivr_menu_uuid)}
                                  hidden={PermissionCheck(
                                    module_name,
                                    "delete",
                                    ivr.group_uuid,
                                    ivr.user_created_by,
                                    ivr.created_by
                                  )}
                                  className="btn btn-danger shadow btn-xs sharp"
                                >
                                  <i className="fa fa-trash"></i>
                                </Link>
                              </div>
                            </Fragment>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Pagination
                    pageCount={pageCount}
                    handlePageNumberClick={handlePageClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Manage Auto-Attendant</h4>
              </div>
              <div className="card-body">
                <h3>You have don't access this modules</h3>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal className="fade bd-example-modal-lg" show={deleteModal} size="md">
        <Modal.Body>
          <p>
            This auto attendant is assign to following module. Please, remove
            from that to delete.
          </p>
          {deleteConfirmData.map((row) => (
            <div key={Math.random}>
              <span>
                <b>{row.module}</b>: {row.value}
              </span>
            </div>
          ))}

          <Button
            onClick={() => setDeleteModal(false)}
            variant="primary light btn-sm pull-right"
          >
            Close
          </Button>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}

export default Ivrs;
