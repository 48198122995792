import React from "react";
import { Link, useNavigate } from "react-router-dom";
import MultiStepForm from "./MultiStepForm";

const Register = () => {
  let navigate = useNavigate();

  function handleClick() {
    navigate("/login");
    window.location.reload();
  }

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="authincation-content w-100">
            <div className="row no-gutters">
              <div className="col-xl-12">
                <div className="auth-form">
                  <h4 className="text-center mb-4">Sign up your account</h4>

                  <MultiStepForm />

                  <div className="new-account mt-3">
                    <p>
                      Already have an account?{" "}
                      <Link className="text-primary" onClick={handleClick}>
                        Sign in
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
