import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const getDestinationTypeList = (createdby, token, main_uuid, type) => {
  return axios
    .post(
      API_URL + "get-destination-type-list",
      {
        createdby,
        main_uuid,
        type,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getDestinationTypeList1 = (createdby, token, main_uuid, type) => {
  return axios
    .post(
      API_URL + "get-destination-type-list1",
      {
        createdby,
        main_uuid,
        type,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getDestinationList = (createdby, token, main_uuid, type) => {
  return axios
    .post(
      API_URL + "get-destination-list",
      {
        createdby,
        main_uuid,
        type,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getSetCallarIdNumber = (createdby, token, main_uuid, did_number) => {
  return axios
    .post(
      API_URL + "get-set-callar-id-number",
      {
        createdby,
        main_uuid,
        did_number,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getDidListReassign = (createdby, token, main_uuid, did_number) => {
  return axios
    .post(
      API_URL + "get-did-list-reassign",
      {
        createdby,
        main_uuid,
        did_number,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const ReassignCallarId = (
  createdby,
  token,
  main_uuid,
  did_number,
  extensionsData,
  status
) => {
  return axios
    .post(
      API_URL + "reassign-callar-id",
      {
        createdby,
        main_uuid,
        did_number,
        extensionsData,
        status,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getDropDownUserList = (createdby, token, main_uuid, type) => {
  return axios
    .post(
      API_URL + "dropdown/get-user-list",
      {
        createdby,
        main_uuid,
        type,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getNumberListDropdown = (
  action,
  assign_number,
  main_uuid,
  user_type,
  group_per,
  group_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "dropdown/get-number-list-dropdown",
      {
        action,
        assign_number,
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getExtensionListDropdown = (
  action,
  assign_extension,
  main_uuid,
  user_type,
  group_per,
  group_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "dropdown/get-extension-list-dropdown",
      {
        action,
        assign_extension,
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getUserListDropdown = (
  action,
  assign_user,
  main_uuid,
  user_type,
  group_per,
  group_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "dropdown/get-user-list-dropdown",
      {
        action,
        assign_user,
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getTimezone = () => {
  return axios
    .post(
      API_URL + "dropdown/get-timezone-list",
    )
    .then((response) => {
      return response.data;
    });
};

const getLanguageList = (main_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "dropdown/get-language-list",
      {
        main_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getUserAssignNumberList = (user_uuid, main_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "dropdown/get-user-assign-list",
      {
        user_uuid,
        main_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getDispositionList = (user_uuid, main_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "sms-chat/get-disposition-list",
      {
        user_uuid,
        main_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getAreaCodeList = (createdby, token) => {
  return axios
    .post(
      API_URL + "dropdown/get-area-code-list",
      {
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getSmsNotification = (main_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "sms-log/get-sms-notification",
      {
        main_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const readSmsNotification = (main_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "read-sms-notification",
      {
        main_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getCompanyAndE911Details = (user_uuid, main_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "get-company-and-e911-details",
      {
        main_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateCompanyAndPersonalInfo = (data, token) => {
  return axios
    .post(API_URL + "update-company-and-personal-info", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const updateProfilePic = (data, token) => {
  return axios
    .post(API_URL + "update-profile-pic", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const updateSkipStepAdmin = (user_uuid, main_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "update-skip-step-admin",
      {
        user_uuid,
        main_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const checkModuleAssign = (
  module_name,
  module_uuid,
  check_type,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "check-module-assign",
      {
        module_name,
        module_uuid,
        check_type,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

export default {
  getDestinationTypeList,
  getDestinationTypeList1,
  getDestinationList,
  getDropDownUserList,
  getNumberListDropdown,
  getExtensionListDropdown,
  getUserListDropdown,
  getTimezone,
  getLanguageList,
  getUserAssignNumberList,
  getDispositionList,
  getSmsNotification,
  readSmsNotification,
  getSetCallarIdNumber,
  getDidListReassign,
  ReassignCallarId,
  getCompanyAndE911Details,
  updateCompanyAndPersonalInfo,
  updateProfilePic,
  updateSkipStepAdmin,
  getAreaCodeList,
  checkModuleAssign,
};
