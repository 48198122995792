import React from "react";
import { Link } from "react-router-dom";

import { Breadcrumb } from "react-bootstrap";

const PageTitle = ({ motherMenu, activeMenu, breadCurmList }) => {

   return (
      <div className="page-titles">

         {breadCurmList ? 
            <Breadcrumb>
                  {breadCurmList && breadCurmList.map((item,index) => (

                     <li className={`${item.is_active === 'YES' ? "breadcrumb-item active" : "breadcrumb-item"}`} key={index}>
                        {item.is_active === 'NO' && <Link to={item.link} state={item.state}>{item.label}</Link>} 
                        {item.is_active === 'YES' && item.label}
                     </li>
                  ))}
            </Breadcrumb>
         : 
            <Breadcrumb>
               <Breadcrumb.Item>
                  {/* <Link to="#">{motherMenu}</Link> */}
                  {motherMenu}
               </Breadcrumb.Item>
               <Breadcrumb.Item active>
                  {activeMenu}
               </Breadcrumb.Item>
            </Breadcrumb>
         }
         
      </div>
   );
};

export default PageTitle;
