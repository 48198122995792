import React, { Fragment, useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import { updateUser, updateUserGroup } from "../../actions/user";
import Loader from "../../layouts/Loader";
import "./style.css";
import Tooltip from "rc-tooltip";
import EasyEdit, { Types } from "react-easy-edit";
import moment from "moment";
import profile from "../../../images/profile/demo_profile.png";

function GeneralInfo(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const PROFILE_URL = process.env.REACT_APP_SERVER_PROFILE_URL;

  const hiddenFileInput = useRef();

  const [loading, setLoading] = useState(false);
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const WEB_SOCKET_URL = process.env.REACT_APP_SERVER_WEB_SOCKET_URL;
    // Establishing a WebSocket connection
    const ws = new WebSocket(WEB_SOCKET_URL);

    // Set up event listeners
    ws.onopen = () => {
      console.log("WebSocket connection opened");
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed");
    };

    setSocket(ws);

    // Cleanup the WebSocket connection when the component unmounts
    return () => {
      ws.close();
    };
  }, []);

  let userData = props.userData;

  let roles = props.roles;
  let numbers = props.numbers;
  let extensions = props.extensions;
  let timeZones = props.timeZones;
  let languages = props.languages;
  let groups = props.groups;
  const dispatch = useDispatch();

  const save = (value, attribute) => {
    setLoading(true);
    const formData = new FormData();

    if (attribute === "role_uuid" && value !== userData.role_uuid) {
      const fileData = {
        type: "role_change",
        data: value,
        old_role: userData.role_uuid,
      };

      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify(fileData));
      }
    }

    if (attribute === "file") {
      formData.append("file", value);
      formData.append("fileName", value.name);
      formData.append("first_name", userData.first_name);
      formData.append("last_name", userData.last_name);
      formData.append("email", userData.email);
      formData.append("role_uuid", userData.role_uuid);
      formData.append("assign_number", userData.assign_number);
      formData.append("assign_extension", userData.assign_extension);
      formData.append("job_title", userData.job_title);
      formData.append("time_zone", userData.time_zone);
      formData.append("language", userData.language);
      formData.append("createdby", createdby);
      formData.append("main_user_uuid", main_uuid);
      formData.append("useruuid", userData.uuid);
      formData.append(attribute, value);
      
    } else {
      formData.append("file", "");
      formData.append("fileName", "");
      formData.append("first_name", userData.first_name);
      formData.append("profile_image", userData.profile_image);
      formData.append("last_name", userData.last_name);
      formData.append("email", userData.email);
      formData.append("role_uuid", userData.role_uuid);
      formData.append("assign_number", userData.assign_number);
      formData.append("assign_extension", userData.assign_extension);
      formData.append("job_title", userData.job_title);
      formData.append("time_zone", userData.time_zone);
      formData.append("language", userData.language);
      formData.append("createdby", createdby);
      formData.append("main_user_uuid", main_uuid);
      formData.append("useruuid", userData.uuid);

      formData.delete(attribute);

      formData.append(attribute, value);
      
    }

    dispatch(updateUser(formData, token))
      .then((res) => {
        if (res.status === 200) {
          notifyInfo(res.message);
          props.changeUserEvent(Math.random);
        } else if (res.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    const MAX_FILE_SIZE = 5120; // 5MB
    if (
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg" ||
      e.target.files[0].type === "image/jpeg"
    ) {
      const fileSizeKiloBytes = e.target.files[0].size / 1024;

      if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        // setErrorMsg("File size is greater than maximum limit");
        notifyError("File size not allow greater then 5MB.");
        return;
      }

      save(e.target.files[0], "file");
    } else {
      notifyError("File allow only waw and mp3.");
      return;
    }
  };

  const saveGroup = (value, attribute) => {
    setLoading(true);
    let data = {
      user_uuid: userData.uuid,
      group_uuid: value,
      createdby: createdby,
    };
    dispatch(updateUserGroup(data, token))
      .then((res) => {
        if (res.status === 200) {
          notifyInfo(res.message);
          props.changeUserEvent(Math.random);
        } else if (res.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  let profile_src = "";
  if (userData.profile_image !== "" && userData.profile_image !== null) {
    profile_src = PROFILE_URL + userData.profile_image;
  } else {
    profile_src = profile;
  }

  return (
    <Fragment>
      {loading && <Loader />}
      <div className="row">
        <div className="col-lg-6 col-md-6 col-6">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-sm form-group">
                  <label className="w-100">
                    Extension
                    <Tooltip placement="top" overlay={<span>Extension</span>}>
                      <span className="ti-info-alt ml-1"></span>
                    </Tooltip>
                  </label>
                  <EasyEdit
                    type={Types.SELECT}
                    onSave={(value) => {
                      save(value, "assign_extension");
                    }}
                    options={extensions}
                    saveButtonLabel={
                      <i className="fa fa-check" aria-hidden="true"></i>
                    }
                    saveButtonStyle="label-primary easy-edit-button rounded-lg"
                    cancelButtonLabel={
                      <i className="fa fa-times" aria-hidden="true"></i>
                    }
                    cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                    attributes={{
                      name: "assign_extension",
                      className: "form-control p-0",
                      style: { height: "35px" },
                    }}
                    placeholder="None"
                    viewAttributes={{ className: "pull-left" }}
                    value={userData.extension}
                  />
                </div>

                <div className="col-sm form-group">
                  <label className="w-100">
                    Outbound Caller ID
                    <Tooltip
                      placement="top"
                      overlay={<span>Outbound Caller ID</span>}
                    >
                      <span className="ti-info-alt ml-1"></span>
                    </Tooltip>
                  </label>
                  <EasyEdit
                    type={Types.SELECT}
                    onSave={(value) => {
                      save(value, "assign_number");
                    }}
                    options={numbers}
                    saveButtonLabel={
                      <i className="fa fa-check" aria-hidden="true"></i>
                    }
                    saveButtonStyle="label-primary easy-edit-button rounded-lg"
                    cancelButtonLabel={
                      <i className="fa fa-times" aria-hidden="true"></i>
                    }
                    cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                    attributes={{
                      name: "assign_number",
                      className: "form-control p-0",
                      style: { height: "35px" },
                    }}
                    placeholder="None"
                    viewAttributes={{ className: "pull-left" }}
                    value={userData.did_number}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm form-group">
                  <label className="w-100">
                    First Name
                    <span className="text-danger">*</span>
                    <Tooltip placement="top" overlay={<span>First Name</span>}>
                      <span className="ti-info-alt ml-1"></span>
                    </Tooltip>
                  </label>
                  <EasyEdit
                    type={Types.TEXT}
                    onSave={(value) => {
                      save(value, "first_name");
                    }}
                    saveButtonLabel={
                      <i className="fa fa-check" aria-hidden="true"></i>
                    }
                    saveButtonStyle="label-primary easy-edit-button rounded-lg"
                    cancelButtonLabel={
                      <i className="fa fa-times" aria-hidden="true"></i>
                    }
                    cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                    attributes={{
                      name: "first_name",
                      className: "form-control h-75",
                    }}
                    //viewAttributes={{className: 'fs-20'}}
                    onValidate={(value) => {
                      if (/^[a-z][a-z\s]*$/i.test(value)) {
                        return true;
                      } else {
                        return false;
                      }
                    }}
                    validationMessage="First Name is required!"
                    placeholder="None"
                    value={userData.first_name}
                  />
                </div>

                <div className="col-sm form-group">
                  <label className="w-100">
                    Last Name
                    <span className="text-danger">*</span>
                    <Tooltip placement="top" overlay={<span>Last Name</span>}>
                      <span className="ti-info-alt ml-1"></span>
                    </Tooltip>
                  </label>
                  <EasyEdit
                    type={Types.TEXT}
                    onSave={(value) => {
                      save(value, "last_name");
                    }}
                    //onCancel={cancel}
                    saveButtonLabel={
                      <i className="fa fa-check" aria-hidden="true"></i>
                    }
                    saveButtonStyle="label-primary easy-edit-button rounded-lg"
                    cancelButtonLabel={
                      <i className="fa fa-times" aria-hidden="true"></i>
                    }
                    cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                    attributes={{
                      name: "last_name",
                      className: "form-control h-75",
                    }}
                    //viewAttributes={{className: 'fs-20'}}
                    onValidate={(value) => {
                      if (/^[a-z][a-z\s]*$/i.test(value)) {
                        return true;
                      } else {
                        return false;
                      }
                    }}
                    validationMessage="Last Name is required!"
                    placeholder="None"
                    value={userData.last_name}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm form-group">
                  <label className="w-100">Profile Pic</label>

                  <div className="profile-photo">
                    <img
                      src={profile_src}
                      className="img-fluid rounded-circle img_height"
                      alt="profile"
                    />
                  </div>

                  <Tooltip
                    placement="top"
                    overlay={<span>Upload New File</span>}
                  >
                    <button
                      className="btn btn-sm btn-rounded btn-outline-success pro_pic position-relative"
                      onClick={(e) => hiddenFileInput.current.click()}
                    >
                      <i className="fa fa-upload" aria-hidden="true"></i>
                    </button>
                  </Tooltip>

                  <input
                    type="file"
                    onChange={(e) => handleChange(e)}
                    ref={hiddenFileInput}
                    className="ml-4"
                    //ref={el => (hiddenFileInput = el)}
                    //id="profile_upload"
                    accept="image/png, image/gif, image/jpeg"
                    style={{ display: "none" }} // Make the file input element invisible
                  />
                </div>

                <div className="col-sm form-group">
                  <label className="w-100">
                    Email ID
                    <span className="text-danger">*</span>
                    <Tooltip placement="top" overlay={<span>Email ID</span>}>
                      <span className="ti-info-alt ml-1"></span>
                    </Tooltip>
                  </label>
                  <EasyEdit
                    type={Types.TEXT}
                    onSave={(value) => {
                      save(value, "email");
                    }}
                    //onCancel={cancel}
                    saveButtonLabel={
                      <i className="fa fa-check" aria-hidden="true"></i>
                    }
                    saveButtonStyle="label-primary easy-edit-button rounded-lg"
                    cancelButtonLabel={
                      <i className="fa fa-times" aria-hidden="true"></i>
                    }
                    cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                    attributes={{
                      name: "last_name",
                      className: "form-control h-75",
                    }}
                    onValidate={(value) => {
                      return value ? true : false;
                      // if (/^[a-z][a-z\s]*$/i.test(value)) {
                      //   return true;
                      // } else {
                      //   return false;
                      // }
                    }}
                    validationMessage="Email is required!"
                    placeholder="None"
                    value={userData.email}
                  />
                  {/* <p>{userData.email}</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-6">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-sm form-group">
                  <label className="w-100">
                    Job Title
                    <span className="text-danger">*</span>
                    <Tooltip placement="top" overlay={<span>Job Title</span>}>
                      <span className="ti-info-alt ml-1"></span>
                    </Tooltip>
                  </label>
                  <EasyEdit
                    type={Types.TEXT}
                    onSave={(value) => {
                      save(value, "job_title");
                    }}
                    saveButtonLabel={
                      <i className="fa fa-check" aria-hidden="true"></i>
                    }
                    saveButtonStyle="label-primary easy-edit-button rounded-lg"
                    cancelButtonLabel={
                      <i className="fa fa-times" aria-hidden="true"></i>
                    }
                    cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                    attributes={{
                      name: "job_title",
                      className: "form-control h-75",
                    }}
                    //viewAttributes={{className: 'fs-20'}}
                    onValidate={(value) => {
                      return value ? true : false;
                    }}
                    validationMessage="Job title is required!"
                    placeholder="None"
                    value={userData.job_title}
                  />
                </div>

                <div className="col-sm form-group">
                  <label className="w-100">
                    Time Zone
                    <Tooltip placement="top" overlay={<span>Time Zone</span>}>
                      <span className="ti-info-alt ml-1"></span>
                    </Tooltip>
                  </label>
                  <EasyEdit
                    type={Types.SELECT}
                    onSave={(value) => {
                      save(value, "time_zone");
                    }}
                    options={timeZones}
                    saveButtonLabel={
                      <i className="fa fa-check" aria-hidden="true"></i>
                    }
                    saveButtonStyle="label-primary easy-edit-button rounded-lg"
                    cancelButtonLabel={
                      <i className="fa fa-times" aria-hidden="true"></i>
                    }
                    onValidate={(value) => {
                      return value ? true : false;
                    }}
                    cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                    attributes={{
                      name: "time_zone",
                      className: "form-control p-0",
                      style: { height: "35px" },
                    }}
                    placeholder="None"
                    viewAttributes={{ className: "pull-left" }}
                    value={userData.time_zone}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm form-group">
                  <label className="w-100">
                    Role
                    <span className="text-danger">*</span>
                    <Tooltip placement="top" overlay={<span>Role</span>}>
                      <span className="ti-info-alt ml-1"></span>
                    </Tooltip>
                  </label>
                  <EasyEdit
                    type={Types.SELECT}
                    onSave={(value) => {
                      save(value, "role_uuid");
                    }}
                    options={roles}
                    saveButtonLabel={
                      <i className="fa fa-check" aria-hidden="true"></i>
                    }
                    saveButtonStyle="label-primary easy-edit-button rounded-lg"
                    cancelButtonLabel={
                      <i className="fa fa-times" aria-hidden="true"></i>
                    }
                    cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                    attributes={{
                      name: "role_uuid",
                      className: "form-control p-0",
                      style: { height: "35px" },
                    }}
                    onValidate={(value) => {
                      return value ? true : false;
                    }}
                    placeholder="None"
                    viewAttributes={{ className: "pull-left" }}
                    value={userData.role_uuid}
                  />
                </div>

                <div className="col-sm form-group">
                  <label className="w-100">
                    User Group
                    <Tooltip placement="top" overlay={<span>User Group</span>}>
                      <span className="ti-info-alt ml-1"></span>
                    </Tooltip>
                  </label>
                  <EasyEdit
                    type={Types.SELECT}
                    onSave={(value) => {
                      saveGroup(value, "group_name");
                    }}
                    options={groups}
                    saveButtonLabel={
                      <i className="fa fa-check" aria-hidden="true"></i>
                    }
                    saveButtonStyle="label-primary easy-edit-button rounded-lg"
                    cancelButtonLabel={
                      <i className="fa fa-times" aria-hidden="true"></i>
                    }
                    cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                    attributes={{
                      name: "group_name",
                      className: "form-control p-0",
                      style: { height: "35px" },
                    }}
                    onValidate={(value) => {
                      return value ? true : false;
                    }}
                    placeholder="None"
                    viewAttributes={{ className: "pull-left" }}
                    value={userData.group_name}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm form-group">
                  <label className="w-100">
                    Language
                    <Tooltip placement="top" overlay={<span>Language</span>}>
                      <span className="ti-info-alt ml-1"></span>
                    </Tooltip>
                  </label>
                  <EasyEdit
                    type={Types.SELECT}
                    onSave={(value) => {
                      save(value, "language");
                    }}
                    options={languages}
                    saveButtonLabel={
                      <i className="fa fa-check" aria-hidden="true"></i>
                    }
                    saveButtonStyle="label-primary easy-edit-button rounded-lg"
                    cancelButtonLabel={
                      <i className="fa fa-times" aria-hidden="true"></i>
                    }
                    cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                    attributes={{
                      name: "language",
                      className: "form-control p-0",
                      style: { height: "35px" },
                    }}
                    onValidate={(value) => {
                      return value ? true : false;
                    }}
                    placeholder="None"
                    viewAttributes={{ className: "pull-left" }}
                    value={userData.language}
                  />
                </div>

                <div className="col-sm form-group">
                  <label className="w-100">
                    Date Created
                    <Tooltip
                      placement="top"
                      overlay={<span>Date Created</span>}
                    >
                      <span className="ti-info-alt ml-1"></span>
                    </Tooltip>
                  </label>
                  <p>{moment(userData.created_at).format("MM-DD-YYYY")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default GeneralInfo;
