import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import "./style.css";
import { getModules } from "../../actions/plan";
import Loader from "../../layouts/Loader";

function ModulesRows(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [modules, setModules] = useState([]);

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;

  if (props.tabName !== "webphone") {
    useEffect(() => {
      setLoading(true);
      dispatch(
        getModules(
          props.action,
          props.plan_uuid,
          createdby,
          token,
          props.tabName
        )
      )
        .then((response) => {
          setModules(response.data.data);
          props.rowData[props.tabName] = response.data.data;
          props.rowDataPass(props.rowData);
          setLoading(false);
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    }, []);
  }

  const handleChange = (tabname, i, e) => {
    var value = e.target.value;
    var type = e.target.id;
    props.rowDataa[tabname][i][type] = value;

    if (type === "status") {
      if (e.target.checked === true) {
        props.rowDataa[tabname][i][type] = "enable";
      } else {
        props.rowDataa[tabname][i][type] = "disable";
        props.rowDataa[tabname][i]["price"] = "0.00";
      }
    }
    props.onData(props.rowDataa); // Call the callback function to pass data to the parent
  };

  const table_rows = [];

  modules.forEach((row, j) => {
    table_rows.push(
      <tr key={j}>
        <td className="tbl_td_check">
          <div className="form-check mb-2">
            <input
              type="checkbox"
              className="form-check-input"
              id="status"
              checked={row.status === "enable" ? true : false}
              onChange={(e) => handleChange(props.tabName, j, e)}
            />
            <label className="form-check-label"></label>
          </div>
        </td>
        <td className="tbl_td">{row.module_name}</td>

        <td className="tbl_td">
          <div className="input-group mb-2">
            <div className="input-group-prepend">
              <span className="input-group-text">$</span>
            </div>
            <input
              type="number"
              step="0.1"
              className="form-control"
              placeholder="0.00"
              name={row.module_permission_uuid}
              value={row.price}
              id="price"
              disabled={row.status === "disable" ? true : false}
              onChange={(e) => handleChange(props.tabName, j, e)}
            />
            {/* <div className="input-group-append">
              <span className="input-group-text">.00</span>
            </div> */}
          </div>
        </td>
        <td className="tbl_td"></td>
      </tr>
    );
  });

  return (
    <div className="widget-media ps ps--active-y">
       {loading && <Loader />}
      <Table responsive className="w-100">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Price</th>
            <th></th>
          </tr>
        </thead>

        <tbody>{table_rows}</tbody>
      </Table>
    </div>
  );
}

export default ModulesRows;
