import React, { Fragment, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { insertCsvData } from "../../actions/dnsList";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import Loader from "../../layouts/Loader";

function BulkUploadForm(props) {
  const closeModel = (arg) => {
    props.closeBulkUploadModel(arg);
  };

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(1);
  const [fileName, setFileName] = useState("");

  const [fieldsName, setFieldsName] = useState({
    number: "",
    account_name: "",
    description: "",
  });

  const [errors, setErrors] = useState({
    number: "",
    description: "",
  });

  const allData = {
    filename: fileName,
    mappingfields: "",
    createdby: createdby,
    main_admin_uuid: main_uuid,
    user_uuid: createdby,
  };

  const loadNextPage = (val) => {
    setCurrentStep(val);
  };

  const handelFileName = (val) => {
    setFileName(val);
  };

  const handleBlur = (e) => {
    let error_data = { ...errors };

    if (e.target.name !== "account_name" && e.target.value.length === 0) {
     
      error_data[e.target.name] = "Select Field for " + e.target.name; 
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }

    const newData = { ...fieldsName };
    newData[e.target.name] = e.target.value;
    setFieldsName(newData);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    let error_data = { ...errors };
    let isError = "false";

    if (fieldsName.number.length === 0) {
      error_data["number"] = "Select Field for number";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      let data = { ...allData };
      data["mappingfields"] = fieldsName;
      data["filename"] = fileName;
      //call create insert csv data API
      dispatch(insertCsvData(data, token))
        .then((response) => {
          //console.log(response);
          if (response.status === 200) {
            notifyInfo(response.message);
            closeModel(false);
            props.handleDncEvent(Math.random);
            //navigate("/blocked-numbers-list", { replace: true });
          } else if (response.status === 500) {
            notifyError("Something went wrong.");
          } else {
            notifyError("Something went wrong.");
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <StepOne
            loadNextPage={loadNextPage}
            handelFileName={handelFileName}
          />
        );
      case 2:
        return (
          <StepTwo
            fileName={fileName}
            handleBlur={handleBlur}
            errors={errors}
            fieldsName={fieldsName}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Fragment>
       {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>DNC Bulk Upload</Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      {/* <form action="" onSubmit={(e) => submitHandler(e)}> */}
      <Modal.Body>
        <form
          onSubmit={(e) => e.preventDefault()}
          id="step-form-horizontal"
          className="step-form-horizontal"
        >
          <div className="row">
            <div className="col-xl-3 col-xxl-3"></div>
            <div className="col-xl-6 col-xxl-6">
              <ol className="go2441762052">
                <li
                  className={
                    currentStep < 1 ? "go2067797667" : "go767851704 step_2"
                  }
                  value="0"
                >
                  <span>1</span>
                  <p className="text-left">Upload CSV</p>
                </li>
                <li
                  className={currentStep < 2 ? "go2067797667" : "go767851704"}
                  value="1"
                >
                  <span>2</span>
                  <p className="text-left">Map Fields and Invite</p>
                </li>
              </ol>
            </div>
          </div>

          <div className="">{renderStep()}</div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => closeModel(false)} variant="danger light btn-sm">
          Close
        </Button>
        {currentStep === 2 && (
          <Button
            type="button"
            onClick={(e) => submitHandler(e)}
            variant="primary btn-sm"
          >
            Save
          </Button>
        )}
      </Modal.Footer>
      {/* </form> */}
    </Fragment>
  );
}

export default BulkUploadForm;
