import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const checkModulePermission = (roleuuid, createdby, token) => {
  return axios
    .post(
      API_URL + "permission/role-details",
      {
        roleuuid,
        createdby,
        token,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getParticularRolePermission = (data, token) => {
  return axios
    .post(API_URL + "permission/get-particular-role-permission", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      return response.data;
    });
};

export default {
  checkModulePermission,
  getParticularRolePermission,
};
