
import React, { useState,Fragment,useEffect} from "react";
import { useDispatch } from "react-redux";
import { Button,Dropdown,Modal } from "react-bootstrap";
import EasyEdit, { Types } from "react-easy-edit";
import {EditorState,convertToRaw, ContentState, Modifier} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Loader from "../../layouts/Loader";
import CreateEmailSignature from "./CreateEmailSignature";
import UpdateEmailSignature from "./UpdateEmailSignature";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import { getCompanySignatureList,updateEmailSignature,deleteEmailSignature } from "../../actions/email/emailSignature";
import { confirmAlert } from "react-confirm-alert";



function emailSignature(props) {

    const user = JSON.parse(localStorage.getItem("user"));
    const user_data = user.data.data;
    const token = user.data.token;
    const createdby = user_data.user_uuid;
    const main_uuid = user_data.main_uuid;

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [companySignatureData, setCompanySignatureData] = useState([]);
    const [signatureEvent, setSignatureEvent] = useState('');
    const [createSignatureModal, setCreateSignatureModal] = useState(false); 
    const [updateSignatureModal, setUpdateSignatureModal] = useState(false);
    const [activeSignature, setActiveSignature] = useState('');

    const [editorState, setEditorState] = useState(
        EditorState.createEmpty()
    );

    let signature_type = props.signature_type;  
    let userSignature = props.userSignature; 

    const userVariables = [
        {lable: 'First Name', value : "first_name"},
        {lable: 'Last Name', value : "last_name"},
        {lable: 'Email', value : "email"},
        {lable: 'Role Name', value : "role_name"},
        {lable: 'User Phone', value : "phone"},
        {lable: 'Job Title', value : "job_title"},
        {lable: 'Department', value : "department"},
        {lable: 'Company Name', value : "company_name"},
        {lable: 'Industry', value : "industry"},
        {lable: 'Company Dddress', value : "company_address"},
        {lable: 'Company Email', value : "company_email"},
        {lable: 'Company Phone', value : "company_phone_number"},
        {lable: 'Company Logo', value : "company_logo"},
    ];

    useEffect(() => {
      if(userSignature && userSignature !== ''){
        setActiveSignature(userSignature);

        if(userSignature.signature_text && userSignature.signature_text !== ''){
          const blocksFromHTML = htmlToDraft(userSignature.signature_text)
          const contentState = ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap
          )

          setEditorState(EditorState.createWithContent(contentState));
        }    
      }
    },[userSignature])

    useEffect(() => {
        dispatch(getCompanySignatureList(main_uuid,createdby,signature_type,token))
          .then((response) => {
            setCompanySignatureData(response.data.company_signature);
    
            if(response.data.company_signature[0]){
              
              if(!activeSignature){
                setActiveSignature(response.data.company_signature[0]);      
              }              
    
              if(response.data.company_signature[0].signature_text === ''){
                const blocksFromHTML = htmlToDraft(response.data.company_signature[0].signature_text)
                const contentState = ContentState.createFromBlockArray(
                    blocksFromHTML.contentBlocks,
                    blocksFromHTML.entityMap
                )
    
                setEditorState(EditorState.createWithContent(contentState));
              }          
    
            }            
    
            return;
          })
          .catch(() => {
            setLoading(false);
          });
    }, [signatureEvent]);

    const selectSignature = (e,signature) => {
        e.preventDefault();
    
        if(signature.signature_text){
          const blocksFromHTML = htmlToDraft(signature.signature_text)
          const contentState = ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap
          )
    
          setEditorState(EditorState.createWithContent(contentState));
        }else{
          setEditorState(EditorState.createEmpty());
        }      
    
        setActiveSignature(signature);
      }
    
      const handleEditorStateChange = (data) => {
        setEditorState(data);
      }
    
      const ShowCreateForm = (e) => {
        e.preventDefault();
        handleCreateSignatureModal(true);
      }

      const ShowUpdateForm = (e) => {
        e.preventDefault();
        handleUpdateSignatureModal(true);
      }
    
      const handleCreateSignatureModal = (value) => {
        setCreateSignatureModal(value);
      }

      const handleUpdateSignatureModal = (value) => {
        setUpdateSignatureModal(value);
      }
    
      const handleSignatureEvent = (value) => {
        setSignatureEvent(value);
      }
    
      const setVariable = (e,variable) => {
        e.preventDefault();
    
        let variable_txt = '{'+variable+'}';
    
        const newContentState = Modifier.insertText(
          editorState.getCurrentContent(), // get ContentState from EditorState
          editorState.getSelection(),
          variable_txt
        );
    
        setEditorState(EditorState.createWithContent(newContentState))
    
      }
    
    
      const saveSignature = (value = '') => {
       //e.preventDefault();
       let signature_name = activeSignature.signature_name;
        if(value !== ''){
          signature_name = value;
        }
    
        let signature_text = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    
        let signatureData = {
          signature_text : signature_text,
          signature_name : signature_name,
          email_signature_uuid : activeSignature.email_signature_uuid,
          createdby : createdby,
        }
    
        dispatch(updateEmailSignature(signatureData,token))
        .then((response) => {
    
            if (response.status === 200) {
                notifyInfo(response.message);
                setSignatureEvent(Math.random);
            }  else if (response.status === 500) {
                notifyError("Something went wrong! Try again in some time");
            } else {
                notifyError("Something went wrong! Try again in some time");
            }
            setLoading(false);        
            return;
        })
        .catch(() => {
            setLoading(false);
        });
      }
    
      const fnDeleteSignature = (e,email_signature_uuid) => {
        e.preventDefault();
    
        confirmAlert({
          title: "",
          message:
            "Are you sure to delete this signature?",
          buttons: [
            {
              label: "Yes",
              onClick: () => DeleteSignature(email_signature_uuid),
            },
            {
              label: "No",
            },
          ],
        });
    
      }
    
      const DeleteSignature = (email_signature_uuid) => {
        let data = {
          email_signature_uuid : email_signature_uuid,
          createdby : createdby
        }
    
        setLoading(true);
        
        dispatch(deleteEmailSignature(data,token))
        .then((response) => {
    
          if (response.status === 200) {
            notifyInfo(response.message);
            setSignatureEvent(Math.random);
          }  else if (response.status === 500) {
              notifyError("Something went wrong! Try again in some time");
          } else {
              notifyError("Something went wrong! Try again in some time");
          }
          
          setLoading(false);
          return;
        })
        .catch(() => {
          setLoading(false);
        });
      }

    
    return (    
        <Fragment>
            {loading && <Loader />}
            <div className="row">
            {userSignature && userSignature.is_create_signature === 'YES' &&
                <div className="col-md-4"> 

                <div className="basic-list-group">
                    <div className="list-group">
                    {userSignature && userSignature.type === "COMPANY" &&    
                        <a href="#!" key={userSignature.email_signature_uuid} className={`${
                            activeSignature.email_signature_uuid === userSignature.email_signature_uuid
                            ? "list-group-item list-group-item-action active"
                            : "list-group-item list-group-item-action"
                            }`} 
                            
                            onClick={(e)=>selectSignature(e,userSignature)}
                            >
                            {userSignature.signature_name} (default)

                        </a> 
                    }
                    {companySignatureData && 
                        companySignatureData.map((signature) => (       
                        <a href="#!" key={signature.email_signature_uuid} className={`${
                            activeSignature.email_signature_uuid === signature.email_signature_uuid
                            ? "list-group-item list-group-item-action active"
                            : "list-group-item list-group-item-action"
                            }`} 
                            
                            onClick={(e)=>selectSignature(e,signature)}
                            >
                            {signature.signature_name} 
                            {userSignature.email_signature_uuid === signature.email_signature_uuid &&  ' (default)' } 

                            {/* {signature.is_default == 'NO' &&   */}
                              <button type="button" className="btn btn-danger btn-xxs pull-right" onClick={(e) => fnDeleteSignature(e,signature.email_signature_uuid)}><i className="fa fa-trash"></i></button>                            
                            {/* } */}
                            
                        </a> 
                            
                        ))
                    }
                                            
                    </div>
                </div>
                  
                {userSignature && userSignature.is_create_signature === 'YES' && 
                  <div className="text-center">
                    <Button type="button" onClick={ShowCreateForm} className="btn-sm mt-2">Create New</Button>
                    <Button type="button" onClick={ShowUpdateForm} className="btn-info btn-sm mt-2 ml-2">Change Default Signature</Button>
                  </div>
                }              
                

                </div>
            }

                <div className="col-md-8">
                  {userSignature && userSignature.is_create_signature === 'YES' && 
                    <div className="row">
                    <div className="col-md-6"> 
                        {/* <p><b>{activeSignature.signature_name} </b></p> */}

                        <EasyEdit
                        type={Types.TEXT}
                        onSave={(value) => {
                            saveSignature(value);
                        }}
                        saveButtonLabel={
                            <i className="fa fa-check" aria-hidden="true"></i>
                        }
                        saveButtonStyle="label-primary easy-edit-button rounded-lg"
                        cancelButtonLabel={
                            <i className="fa fa-times" aria-hidden="true"></i>
                        }
                        cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                        viewAttributes ={{
                            className: "p-2 ml-2 fw-bold",
                        }}
                        attributes={{
                            name: "signature_name",
                            className: "form-control bg-white h-75",
                        }}
                        onValidate={value => {return value !== null}}
                        placeholder="Signature Name"
                        value={activeSignature.signature_name}
                        />
                        
                    </div>
                    <div className="col-md-6 text-right"> 
                        <Dropdown>
                        <Dropdown.Toggle
                            aria-expanded="false"
                            data-toggle="dropdown"
                            className="btn btn btn-outline-success btn-xs mb-2"
                            as="button"
                        >
                            Add Variables
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dropdown-menu">

                            {userVariables && 
                            userVariables.map((variable,index) => (    
                                <Dropdown.Item
                                to="#"
                                key={index}
                                onClick={(e)=>setVariable(e,variable.value)}
                                className="dropdown-item"
                                >
                                {variable.lable}
                                
                                </Dropdown.Item>
                            ))
                            }
                        
                        </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    </div>
                  }

                  {userSignature && userSignature.is_create_signature === 'NO' && 
                    <div className="row">
                      <div className="col-md-6"> 
                        <p><b>{activeSignature.signature_name} </b></p>
                      </div>
                    </div>
                  }
                
                <div className="form-group input-primary">
                    <div className="border border-primary rounded p-2" >
                        <Editor
                            editorStyle={{ minHeight: '300px' }}
                            editorState={editorState}
                            onEditorStateChange={editorState => handleEditorStateChange(editorState)}
                        />
                    </div>

                    {/* <span className="text-danger">
                            {errors.body_text}
                    </span> */}

                    {userSignature && userSignature.is_create_signature === 'YES' && 
                      <Button type="button" onClick={() => saveSignature('')} variant="primary btn-sm pull-right mt-2">Save</Button>
                    }
                </div>

            </div>

        </div>

        {/* <!-- create signature modal --> */}
        <Modal className="fade" show={createSignatureModal} size='md'>
            <CreateEmailSignature handleCreateSignatureModal={handleCreateSignatureModal} handleSignatureEvent={handleSignatureEvent} type={signature_type} />
        </Modal>

        {/* <!-- create signature modal --> */}
        <Modal className="fade" show={updateSignatureModal} size='md'>
            <UpdateEmailSignature handleUpdateSignatureModal={handleUpdateSignatureModal} handleSignatureEvent={handleSignatureEvent} type={signature_type} companySignatureData={companySignatureData} />
        </Modal>
            
    </Fragment> );

}

export default emailSignature;