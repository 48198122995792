import { SET_MESSAGE, API_FAIL } from "../types";

import Dailyservice from "../../services/calender/Daily.service";


  export const createRoom = (create_data) => (dispatch) => {
    return Dailyservice.createRoom(create_data).then(
      (data) => {
         if (data.status !== 200) {
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        } else {
          return data;
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const createAccessToken = (create_data) => (dispatch) => {
    return Dailyservice.createAccessToken(create_data).then(
      (data) => {
         if (data.status !== 200) {
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        } else {
          return data;
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

