import React, { Fragment, useState, useEffect } from "react";
import { Tab, Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../layouts/PageTitle";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import {
  createRinggroup,
  getAdminVoicemail,
  getNextRingGroupID,
} from "../../actions/ringgroups";
import GeneralFieldsForm from "./GeneralFieldsForm";
import {
  getDestinationTypeList,
  getDestinationList,
} from "../../actions/comman";
import "./style.css";
import Loader from "../../layouts/Loader";

function RinggroupForm() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const [ringgroupData, setRinggroupData] = useState({
    ring_group_name: "",
    ring_group_extension: "",
    ring_group_greeting: "",
    ring_group_strategy: "ring_all",
    ring_group_fail_destination: "",
    ring_group_fail_destination_type: "voicemail",
    ring_group_fail_destination_str: "",
    ring_group_music_on_hold: "",
    ring_group_call_timeout: "10",
    ring_group_enabled: "",
    ring_group_description: "",
    ring_group_destinations: "",
    cid_name_prefix: "",
    max_wait_time: "10",
    call_confirm: "NO",
    skip_buy_extension: "YES",
    position_announcement: "YES",
    placeholder_callback: "YES",
    play_hold_time: "YES",
    follow_me: "NO",
    createdby: createdby,
    main_admin_uuid: main_uuid,
    user_uuid: createdby,
  });

  const [extensions, setExtensions] = useState([]);
  const [destinationOptions, setDestinationOptions] = useState([]);

  const [errors, setErrors] = useState({
    ring_group_name: "",
  });

  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();


  useEffect(() => {
    setLoading(true);

    dispatch(getDestinationList(createdby, token, main_uuid, ""))
      .then((response) => {
        let groupedOptions = [];
        response.data.forEach((option) => {
          if (!groupedOptions[option.optgroup])
            groupedOptions[option.optgroup] = [];
          groupedOptions[option.optgroup].push({
            value: option.value,
            text: option.text,
          });
        });

        setDestinationOptions(groupedOptions);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(getDestinationTypeList(createdby, token, main_uuid, "extention"))
      .then((response) => {
        setExtensions(response.data);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const get_voicemial = (ext) => {
    setLoading(true);
    dispatch(getAdminVoicemail(main_uuid, token, createdby))
      .then((response) => {
        const newRinggroupdata = { ...ringgroupData };
        newRinggroupdata["ring_group_fail_destination"] =
          response.data[0].voicemail_uuid;
        newRinggroupdata["ring_group_fail_destination_str"] =
          "voicemail_" + response.data[0].voicemail_uuid;
        newRinggroupdata["ring_group_extension"] = ext;
        setRinggroupData(newRinggroupdata);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getNextRingGroupID(main_uuid, token, createdby))
      .then((response) => {
        get_voicemial(response.data.extension);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const handleBlur = (e) => {
    const newRinggroupdata = { ...ringgroupData };
    let error_data = { ...errors };

    if (e.target.name === "ring_group_fail_destination_str") {
      const answer_array = e.target.value.split("_");

      newRinggroupdata["ring_group_fail_destination_type"] = answer_array[0];
      newRinggroupdata["ring_group_fail_destination"] = answer_array[1];
    }

    if (e.target.name === "ring_group_name" && e.target.value.length === 0) {
      error_data["ring_group_name"] = "Ring group name is required.";
      setErrors(error_data);
    } else {
      error_data["ring_group_name"] = "";
      setErrors(error_data);
    }

    if (e.target.name === "ring_group_strategy" && e.target.value.length === 0) {
      error_data["ring_group_strategy"] = "Ring group strategy is required.";
      setErrors(error_data);
    } else {
      error_data["ring_group_strategy"] = "";
      setErrors(error_data);
    }

    newRinggroupdata[e.target.name] = e.target.value;
    setRinggroupData(newRinggroupdata);
  };

  const addDestinationHandler = (data) => {
    const newRinggroupdata = { ...ringgroupData };
    newRinggroupdata["ring_group_destinations"] = data;
    setRinggroupData(newRinggroupdata);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (ringgroupData.ring_group_name.length === 0) {
      error_data["ring_group_name"] = "Ring group name is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (ringgroupData.ring_group_strategy.length === 0) {
      error_data["ring_group_strategy"] = "Ring group strategy is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create user API
      dispatch(createRinggroup(ringgroupData, token))
        .then((res) => {
          if (res.status === 200) {
            notifyInfo(res.message);

            let ring_group_uuid = res.data;
            setLoading(false);
            navigate("/view-ringgroup", {
              replace: true,
              state: {
                ring_group_uuid: ring_group_uuid,
                activeTab: "advanced_options",
              },
            }); //data
          } else if (res.status === 500) {
            setLoading(false);
            notifyError("Something went wrong.");
          } else {
            setLoading(false);
            notifyError("Something went wrong.");
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const show_alert = (e) => {
    e.preventDefault();
    alert("save general information.");
  };

  const breadCurmList = [
    { label: "Calls", link: "/", is_active: "NO" },
    { label: "Ring Group", link: "/ring-groups", is_active: "NO" },
    { label: "Create Ring Group", link: "", is_active: "YES" },
  ];

  return (
    <Fragment>
      <PageTitle breadCurmList={breadCurmList} />
      {loading && <Loader />}

      <Tab.Container defaultActiveKey="general">
        <Nav as="ul" className="nav-tabs ringtabs mt-4">
          <Nav.Item as="li" key="1">
            <Nav.Link eventKey="general">
              <i className={`la la-tty mr-2`} />
              General
            </Nav.Link>
          </Nav.Item>

          <Nav.Item as="li" key="2">
            <Nav.Link onClick={(e) => show_alert(e)}>
              <i className={`la la-envelope mr-2`} />
              Advanced Options
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content className="ringgroup">
          <Tab.Pane eventKey="general" key="1" className="pt-4">
            <GeneralFieldsForm
              ringgroupData={ringgroupData}
              extensions={extensions}
              errors={errors}
              destinationOptions={destinationOptions}
              handleBlur={handleBlur}
              addDestinationHandler={addDestinationHandler}
              submitHandler={submitHandler}
            ></GeneralFieldsForm>
          </Tab.Pane>
          {/* <Tab.Pane eventKey="advanced_options" key="2" className="pt-4"> */}
          {/* <AdvanceFields ringgroupData={ringgroupData} changeRingGroupEvent={changeRingGroupEvent} /> */}
          {/* </Tab.Pane> */}
        </Tab.Content>
      </Tab.Container>
    </Fragment>
  );
}

export default RinggroupForm;
