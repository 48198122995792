import React, { useState } from "react";
import StepOne from "./Numbers/StepOne";
import StepTwo from "./Numbers/StepTwo";
import "./index.css";

const MultiStepNumber = (props) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [didData, setDidData] = useState([]);

  const handleBuyAndConfirmData = (dataCart) => {
    if (dataCart.status === "buy_and_confirm") {
      setDidData(dataCart.selected_dids);
      setCurrentStep(currentStep + 1);
    }
  };

  const handleSelectMoreNumber = (dataCart) => {
    setDidData(dataCart.selected_dids);
    setCurrentStep(currentStep - 1);
  };

  const handleRemovedNumber = (dataCart) => {
    setDidData(dataCart);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <StepOne
            didsData={didData}
            type={props.type}
            handleBuyAndConfirmData={handleBuyAndConfirmData}
          />
        );
      case 2:
        return (
          <StepTwo
            didsData={didData}
            type={props.type}
            handleSelectMoreNumber={handleSelectMoreNumber}
            handleRemovedNumber={handleRemovedNumber}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div className="row">
      <div className="col-xl-12 col-xxl-12">
        <form
          action=""
          id="step-form-horizontal"
          className="step-form-horizontal"
        >
          <div className="row">
            <div className="col-xl-3 col-xxl-3"></div>
            <div className="col-xl-6 col-xxl-6">
              <ol className="go2441762052">
                <li
                  className={
                    currentStep < 1 ? "go2067797667" : "go767851704 step_2"
                  }
                  value="0"
                >
                  <span>1</span>
                </li>
                <li
                  className={currentStep < 2 ? "go2067797667" : "go767851704"}
                  value="1"
                >
                  <span>2</span>
                </li>
              </ol>
            </div>
          </div>
          <div className="">{renderStep()}</div>

          <div className="next_pre_btn">
            {currentStep > 1 && <></>}
            {currentStep < 2 && <></>}
          </div>
        </form>
      </div>
    </div>
  );
};

export default MultiStepNumber;
