import React, { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import PasswordForm from "./PasswordForm";
import E911Form from "./E911Form";
import EmailConfigForm from "./EmailConfigForm";
import "./index.css";
import Tooltip from "rc-tooltip";
import { useNavigate } from "react-router-dom";
import BusinessNumberForm from "../../onboarding/Wizard/Numbers";

const OnboardingList = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  let navigate = useNavigate();
  const user_data = user.data.data;
  const token = user.data.token;

  if (
    user_data.is_password_done === "YES" &&
    user_data.is_email_config_done === "YES" &&
    user_data.is_e911_done === "YES" &&
    user_data.is_did_done === "YES"
  ) {
    navigate("/");
  } else {
    // navigate("/onboarding");
  }

  const closePasswordModel = (value) => {
    setPasswordModal(value);
  };

  // const closeDIDModel = (value) => {
  //   setDIDModal(value);
  // };

  const closeBusinessNumbeModel = (value) => {
    setBusinessNumberModal(value);
  };

  const closeEmailModel = (value) => {
    setEmailConfigModal(value);
  };

  const closeE911Model = (value) => {
    setE911Modal(value);
  };

  let currentStep = props.currentStep;

  let step_two_color = "widget-stat card bg-success";
  let step_third_color = "widget-stat card bg-success";
  //let step_fouth_color = "widget-stat card bg-success";

  if (currentStep >= 2) {
    step_two_color = "widget-stat card bg-primary";
  }

  if (currentStep >= 3) {
    step_third_color = "widget-stat card bg-primary";
  }

  // if (currentStep >= 4) {
  //   step_fouth_color = "widget-stat card bg-primary";
  // }

  const [passwordModal, setPasswordModal] = useState(false);
  // const [DIDModal, setDIDModal] = useState(false);
  const [businessNumberModal, setBusinessNumberModal] = useState(false);
  const [emailConfigModal, setEmailConfigModal] = useState(false);
  const [E911Modal, setE911Modal] = useState(false);

  return (
    <Fragment>
      <section className="pricewrap">
        <div className="row">
          <div className="col-lg-12 col-xxl-12">
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-sm-3 col-xxl-3">
                {currentStep > 1 ? (
                  <div className="widget-stat card bg-primary link_onboarding">
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="mr-3">
                          <i className="fa fa-lock" />
                        </span>
                        <div className="media-body text-white text-right">
                          <Tooltip
                            placement="top"
                            overlay={<span>Password Setup Done</span>}
                          >
                            <p className="mb-1">Set Password</p>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="widget-stat card bg-primary link_onboarding"
                    onClick={() => setPasswordModal(true)}
                  >
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="mr-3">
                          <i className="fa fa-lock" />
                        </span>
                        <div className="media-body text-white text-right">
                          <p className="mb-1">Set Password</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-xl-3 col-lg-3 col-sm-3 col-xxl-">
                {currentStep > 2 ? (
                  <div className="widget-stat card bg-primary link_onboarding">
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="mr-3">
                          <i className="fa fa-list-ul" />
                        </span>
                        <div className="media-body text-white text-right">
                          <Tooltip
                            placement="top"
                            overlay={<span>DID Setup Done </span>}
                          >
                            <p className="mb-1">Select DID</p>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={step_two_color}>
                    <div
                      className="card-body p-4 link_onboarding"
                      onClick={() => setBusinessNumberModal(true)}
                    >
                      <div className="media">
                        <span className="mr-3">
                          <i className="fa fa-list-ul" />
                        </span>
                        <div className="media-body text-white text-right">
                          <p className="mb-1">Set up your busuiness number</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-xl-3 col-lg-3 col-sm-3 col-xxl-3">
                {currentStep > 3 ? (
                  <div className="widget-stat card bg-primary link_onboarding">
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="mr-3">
                          <i className="fa fa-envelope" />
                        </span>
                        <div className="media-body text-white text-right">
                          <Tooltip
                            placement="top"
                            overlay={<span>Email Config Setup Done </span>}
                          >
                            <p className="mb-1">Email Config</p>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={step_third_color}>
                    <div
                      className="card-body p-4 link_onboarding"
                      onClick={() => setEmailConfigModal(true)}
                    >
                      <div className="media">
                        <span className="mr-3">
                          <i className="fa fa-envelope" />
                        </span>
                        <div className="media-body text-white text-right">
                          <p className="mb-1"> Email Config</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-xl-3 col-lg-3 col-sm-3 col-xxl-3">
                {currentStep > 4 ? (
                  <div
                    className="widget-stat card bg-success link_onboarding"
                    onClick={""}
                  >
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="mr-3">
                          <i className="fa fa-address-card" />
                        </span>
                        <div className="media-body text-white text-right">
                          <p className="mb-1"> E911</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="widget-stat card bg-success link_onboarding"
                    onClick={() => setE911Modal(true)}
                  >
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="mr-3">
                          <i className="fa fa-address-card" />
                        </span>
                        <div className="media-body text-white text-right">
                          <p className="mb-1"> E911</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        className="fade bd-example-modal-lg"
        show={passwordModal}
        user_data={user_data}
        size="lg"
      >
        <PasswordForm
          closePasswordModel={closePasswordModel}
          type={"onboarding"}
        />
      </Modal>
      {user_data.is_password_done === "YES" && (
        // <Modal className="fade bd-example-modal-lg" show={DIDModal} size="lg">
        //   <DIDForm closeDIDModel={closeDIDModel} type={"onboarding"} />
        // </Modal>

        <Modal
          className="fade bd-example-modal-xl"
          show={businessNumberModal}
          size="xl"
        >
          <BusinessNumberForm
            closeModel={closeBusinessNumbeModel}
            handleSendSmsEvent={""}
            type={"user_onboarding"}
          />
        </Modal>
      )}

      {user_data.is_password_done === "YES" &&
        user_data.is_did_done === "YES" && (
          <Modal
            className="fade bd-example-modal-lg"
            show={emailConfigModal}
            size="lg"
          >
            <EmailConfigForm
              closeEmailModel={closeEmailModel}
              user_data={user_data}
              token={token}
              handleEmailConfigEvent={""}
            />
          </Modal>
        )}

      {user_data.is_password_done === "YES" &&
        user_data.is_did_done === "YES" &&
        user_data.is_email_config_done === "YES" && (
          <Modal
            className="fade bd-example-modal-lg"
            show={E911Modal}
            size="lg"
          >
            <E911Form closeE911Model={closeE911Model} type={"onboarding"} />
          </Modal>
        )}
    </Fragment>
  );
};

export default OnboardingList;
