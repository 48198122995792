import React, { Fragment, useState, useEffect } from "react";
import { Button, Tab, Nav } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../../layouts/PageTitle";
import { getUserListDropdown } from "../../../actions/comman";
import PermissionCheck from "../../../common/PermissionCheck";
import { notifyInfo, notifyError } from "../../Toastr/Toastr";
import { updateContact, getContactDetail,getContactPersona,getContactTags } from "../../../actions/contacts";
import { getContactFormLayout } from "../../../actions/customContactLayout";
import axios from "axios";
import validator from 'validator'
import SectionOverview from "./SectionOverview";
import ContactCustomFieldForm from "./ContactCustomFieldForm";
import Loader from "../../../layouts/Loader";

function UpdateContactForm() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;

  let listing_per = PermissionCheck("did", "listing", "", "", "");

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }

  const params = useLocation();
  const contact_uuid = params.state.contact_uuid;
  const [formData, setFormData] = useState([]);

  const [users, setUsers] = useState([]);
  const [personaOptions, setPersonaOptions] = useState([]);
  //const [selectedPersona, setSelectedPersona] = useState([]);

  const [tagsOptions, setTagsOptions] = useState([]);
  //const [selectedTags, setSelectedTags] = useState([]);

  const [contactData, setContactData] = useState({
    contact_uuid: contact_uuid,
    contact_type: "Individual",
    bussiness_name: "",
    contact_title: "",
    first_name: "",
    last_name: "",
    email: "",
    work_contact_number: "",
    other_contact_number: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
    address: "",
    address2: "",
    assign_user: "",
    bussiness_site : "",
    job_title : "",
    job_industry : "",    
    facebook_url : "",
    linked_in_url : "",
    twitter_url : "",
    instagram_url : "",
    site_favicon : "",
    persona : "",
    selectedPersona : "",
    tags : "",
    selectedTags : "",
    custom_field_data : {},
    dependent_field : "NO",
    dependent_field_data: {},
    token: token,
    createdby: createdby,
    main_user_uuid: createdby,
    user_uuid: createdby,
  });

  const [errors, setErrors] = useState({
    contact_type: "",
    email: "",
    work_contact_number: "",
  });
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBlur = (e) => {
    const newContactData = { ...contactData };
    let error_data = { ...errors };

    if(e.target.name === "bussiness_site"){
      if (validator.isURL(e.target.value)) {
        getFavicon(e.target.value);
      } else {
        error_data[e.target.name] = "URL is not valid";
        setErrors(error_data);
      }      
    }

    if (
      (e.target.name === "contact_type" ||
        e.target.name === "email" ||
        e.target.name === "work_contact_number") &&
      e.target.value.length === 0
    ) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }

    newContactData[e.target.name] = e.target.value;
    setContactData(newContactData);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (contactData.contact_type.length === 0) {
      error_data["contact_type"] = "Contact type is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (contactData.email.length === 0) {
      error_data["email"] = "Email is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (contactData.work_contact_number.length === 0) {
      error_data["work_contact_number"] = "Work contact is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (contactData.assign_user.length === 0) {
      error_data["assign_user"] = "Please select assign user.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {


      //call create user API
      dispatch(updateContact(contactData))
        .then((res) => {
          
          if (res.status === 200) {
            notifyInfo(res.message);
            navigate("/contacts", { replace: true });
          } else if (res.status === 406) {
            error_data["work_contact_number"] = res.message;
            setErrors(error_data);
            isError = "true";
          } else if (res.status === 500) {
            notifyError("Something went wrong.");
          } else {
            notifyError("Something went wrong.");
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    dispatch(getContactDetail(createdby, token, contact_uuid))
      .then((response) => {

        const newarr = { ...response.data[0] };

        if(newarr.assign_to_uuid.length === 0){
          newarr["assign_to_uuid"] = createdby;
        }

        newarr["token"] = token;
        newarr["createdby"] = createdby;
        newarr["assign_user"] = newarr.assign_to_uuid;
        newarr['tags'] = "";
        newarr['selectedTags'] = "";

        let custom_field_data = {};
        response.data[0].custom_field_data.map((field) => (
          custom_field_data[field.custom_field_uuid] = field.field_value
        ))

        newarr["custom_field_data"] = custom_field_data;

        let dependent_field_data = {};
        response.data[0].dependent_field_data.map((field) => (
          dependent_field_data[field.contact_custom_dependent_field_uuid] = {
              value : field.field_value,
              main_field_uuid : field.main_field_uuid,
              contact_custom_dependent_field_uuid : field.contact_custom_dependent_field_uuid
          }
        ))

        newarr["dependent_field_data"] = dependent_field_data;

        setContactData(newarr);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getContactFormLayout(
        main_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        
        setFormData(response.data);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    dispatch(
      getUserListDropdown(
        "create",
        "",
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        setUsers(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    dispatch(
      getContactPersona(
        main_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        setPersonaOptions(response.data.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    dispatch(
      getContactTags(
        main_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        setTagsOptions(response.data.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const handlecustomFieldData = (name, value) => {
    
    const newContactData = { ...contactData };
    newContactData["custom_field_data"][name] = value;
    newContactData["custom_field"] = "YES";

    setContactData(newContactData);
  };

  const handleDependentFieldData = (data, value) => {

    let arr = {
      value : value,
      main_field_uuid : data.main_field_uuid,
      contact_custom_dependent_field_uuid : data.contact_custom_dependent_field_uuid
    }
    
    const newContactData = { ...contactData };
    newContactData["dependent_field_data"][data.contact_custom_dependent_field_uuid] = arr;
    newContactData["dependent_field"] = "YES";

    setContactData(newContactData);
  };

  const getFavicon = async (url) =>{

    try {
      const {
        data: { icons }
      } = await axios.get('https://s2.googleusercontent.com/s2/favicons?domain='+url);
      if (icons[0].src !== "") {

        const newarr = { ...contactData };

        newarr["site_favicon"] = icons[0].src;

        setContactData(newarr);

          //setFavicon(icons[0].src);
      } else {
        //throw new Error("undefined url");
      }
    } catch (error) {
      //setError(true);
    }

  }

  const handleSelectPersona = (data) => {
    
    const newContactData = { ...contactData };
    if(data){
      newContactData['persona'] = data.value;
    }else{
      newContactData['persona'] = '';
    }    

    newContactData['selectedPersona'] = data;
    setContactData(newContactData);
    //setSelectedPersona(data);
    
  }

  const handleSelectTags = (data) => {

    let values = Object.values(data).map(k => k['value']);
    
    const newContactData = { ...contactData };
    if(data){
      newContactData['tags'] = values;
    }else{
      newContactData['tags'] = '';
    }    

    newContactData['selectedTags'] = data;
    setContactData(newContactData);
    //setSelectedTags(data);
    
  }

  const breadCurmList = [
    { label: "Home", link: "/", is_active: "NO" },
    { label: "Contacts", link: "/contacts", is_active: "NO" },
    { label: "Update Contact", link: "", is_active: "YES" },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />

      <form action="" onSubmit={(e) => submitHandler(e)}>

          {formData.map((row) => (
            <span key={row.contact_form_section_uuid} className="contact-form">
             
              <Tab.Container defaultActiveKey={row.section_type === 'overview'? 'overview' : row.tabs.length > 0 ? row.tabs[0].contact_form_tab_uuid : ''}>
                <Nav as='ul' className='nav-tabs ringtabs mt-4'>
                    
                    {row.section_type === 'overview' && 
                      <>
                      <Nav.Item as='li' key="1">
                        <Nav.Link eventKey="overview">
                            {row.section_title}
                        </Nav.Link>
                      </Nav.Item>
                      
                      </>
                    }                 

                    {row.tabs.map((tab) => (
                      <Nav.Item as='li' key={tab.contact_form_tab_uuid}>
                        <Nav.Link eventKey={tab.contact_form_tab_uuid} >
                            {tab.tab_name}
                            
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                
                </Nav>
          
                <Tab.Content className='ringgroup'>
                    
                    {row.section_type === 'overview' && 
                      (
                        <Tab.Pane eventKey="overview" key="1" className="pt-4" >
                            <SectionOverview handleBlur={handleBlur} errors={errors} contactData={contactData} users={users} personaOptions={personaOptions} handleSelectPersona={handleSelectPersona} handleSelectTags={handleSelectTags} tagsOptions={tagsOptions}/>
                        </Tab.Pane>
                        
                      )
                    }

                    {row.tabs.map((tab) => ( 
                    
                      <Tab.Pane eventKey={tab.contact_form_tab_uuid} key={tab.contact_form_tab_uuid} className="pt-4" >
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-6" >
                              <div className="card">
                                  <div className="card-body">
                                    {tab.sec_one_fileds.map((field) => (

                                      <span key={field.custom_field_uuid}>
                                        <ContactCustomFieldForm  field={field} handlecustomFieldData={handlecustomFieldData} contactFiledData={contactData.custom_field_data} handleDependentFieldData={handleDependentFieldData} dependent_field_data={contactData.dependent_field_data} />
                                      </span>
                                      
                                    ))}
                                  

                                  </div>
                              </div>
                          </div>

                          <div className="col-lg-6 col-md-6 col-6" >
                              <div className="card">
                                  <div className="card-body">
                                    {tab.sec_two_fileds.map((field) => (
                                      <span key={field.custom_field_uuid}>
                                        <ContactCustomFieldForm  field={field}  handlecustomFieldData={handlecustomFieldData} contactFiledData={contactData.custom_field_data} handleDependentFieldData={handleDependentFieldData} dependent_field_data={contactData.dependent_field_data} />
                                      </span>
                                    ))}
                                    

                                  </div>
                              </div>
                          </div>
                          
                        </div>
                      </Tab.Pane>

                    ))}

                </Tab.Content>

              </Tab.Container>
            
            </span>
        ))}

        <div className="row text-right">
          <div className="col-sm">
            <Button type="submit" variant="primary btn-sm">
              Save
            </Button>
          </div>
        </div>

      </form>

      {/* <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Update Contact</h4>
              <div>
                <div className="btn-group pull-right">
                  <Button
                    type="button"
                    className="btn btn-default btn-sm"
                    onClick={() => navigate("/contacts", { replace: true })}
                  >
                    <i className="fa fa-reply" aria-hidden="true"></i> Back
                  </Button>
                </div>
              </div>
            </div>
            <div className="card-body">
              <form action="" onSubmit={(e) => submitHandler(e)}>
                <div className="row">
                  <div className="col-sm">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Contact Type</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="contact_type"
                        onChange={handleBlur}
                        value={contactData.contact_type}
                      >
                        <option value="Individual">Individual</option>
                        <option value="Business">Business</option>
                      </select>
                      <span className="text-danger">{errors.contact_type}</span>
                    </div>
                  </div>

                  {
                    contactData.contact_type === "Business"
                          ? <div className="col-sm">
                          <div className="form-group">
                            <label className="mb-1">
                            <strong>Business Name</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              ////placeholder="Business Name"
                              name="bussiness_name"
                              onChange={handleBlur}
                              value={contactData.bussiness_name}
                            />
                            <span className="text-danger">
                              {errors.bussiness_name}
                            </span>
                          </div>
                        </div>
                          : ""
                  }

                  {
                    contactData.contact_type === "Business"
                          ? <div className="col-sm">
                          <div className="form-group">
                            <label className="mb-1">
                              <strong>WebSite</strong>
                            </label>
                            <div className="input-group mb-2">
                               
                              <div className="input-group-prepend">
                                <div className="input-group-text">{contactData.site_favicon}</div>
                              </div>
                              
                              <input
                                type="text"
                                className="form-control"
                                name="bussiness_site"
                                onChange={handleBlur}
                                value={contactData.bussiness_site}
                              />
                            </div>
                            
                            <span className="text-danger">
                              {errors.bussiness_site}
                            </span>
                          </div>
                        </div>
                          : ""
                  }

                  {
                    contactData.contact_type === "Individual"
                          ? <div className="col-sm">
                          <div className="form-group">
                            <label className="mb-1">
                              <strong>Job Title</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="job_title"
                              onChange={handleBlur}
                              value={contactData.job_title}
                            />
                            <span className="text-danger">
                              {errors.job_title}
                            </span>
                          </div>
                        </div>
                          : ""
                  }

                  {
                    contactData.contact_type === "Individual"
                          ? <div className="col-sm">
                          <div className="form-group">
                            <label className="mb-1">
                              <strong>Job Industry</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="job_industry"
                              onChange={handleBlur}
                              value={contactData.job_industry}
                            />
                            <span className="text-danger">
                              {errors.job_industry}
                            </span>
                          </div>
                        </div>
                          : ""
                  }

                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Assign User</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="assign_user"
                        onChange={handleBlur}
                        value={contactData.assign_user}
                      >
                        <option value="">Select User</option>
                        {users.map((user) => (
                          <option key={user.uuid} value={user.uuid}>
                            {user.user_name}
                          </option>
                        ))}
                        <option key={createdby} value={createdby}>
                          Assign Me
                        </option>
                      </select>
                      <span className="text-danger">{errors.assign_user}</span>
                    </div>
                  </div>
                  <div className="col-sm">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Contact Title</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        //placeholder="Contact Title"
                        name="contact_title"
                        onChange={handleBlur}
                        value={contactData.contact_title}
                      />
                      <span className="text-danger">
                        {errors.contact_title}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>First Name</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        //placeholder="First Name"
                        name="first_name"
                        onChange={handleBlur}
                        value={contactData.first_name}
                      />
                      <span className="text-danger">{errors.first_name}</span>
                    </div>
                  </div>

                  <div className="col-sm">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Last Name</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        //placeholder="Last Name"
                        name="last_name"
                        onChange={handleBlur}
                        value={contactData.last_name}
                      />
                      <span className="text-danger">{errors.last_name}</span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Work Contact Number</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        //placeholder="Work Contact Number"
                        name="work_contact_number"
                        onChange={handleBlur}
                        value={contactData.work_contact_number}
                      />
                      <span className="text-danger">
                        {errors.work_contact_number}
                      </span>
                    </div>
                  </div>

                  <div className="col-sm">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Other Contact Number</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        //placeholder="Other Contact Number"
                        name="other_contact_number"
                        onChange={handleBlur}
                        value={contactData.other_contact_number}
                      />
                      <span className="text-danger">
                        {errors.other_contact_number}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Email</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        //placeholder="Email"
                        name="email"
                        onChange={handleBlur}
                        value={contactData.email}
                      />
                      <span className="text-danger">{errors.email}</span>
                    </div>
                  </div>

                  <div className="col-sm">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Persona</strong>
                      </label>

                      

                      <Select
                        className="form-control"
                        options={personaOptions}
                        name="persona"
                        placeholder="Select Persona"
                        onChange={handleSelectPersona}
                        isSearchable={true}
                        value={selectedPersona}
                      />

                      <span className="text-danger">{errors.persona}</span>
                    </div>
                  </div>

                </div>

                <div className="row">
                  <div className="col-sm">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Address</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        //placeholder="Address"
                        name="address"
                        onChange={handleBlur}
                        value={contactData.address}
                      />
                      <span className="text-danger">{errors.address}</span>
                    </div>
                  </div>

                  <div className="col-sm">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Address2</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        //placeholder="Address2"
                        name="address2"
                        onChange={handleBlur}
                        value={contactData.address2}
                      />
                      <span className="text-danger">{errors.address2}</span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Country</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        //placeholder="Country"
                        name="country"
                        onChange={handleBlur}
                        value={contactData.country}
                      />
                      <span className="text-danger">{errors.country}</span>
                    </div>
                  </div>

                  <div className="col-sm">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>State</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        //placeholder="State"
                        name="state"
                        onChange={handleBlur}
                        value={contactData.state}
                      />
                      <span className="text-danger">{errors.state}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm">
                    {" "}
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>City</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        //placeholder="City"
                        name="city"
                        onChange={handleBlur}
                        value={contactData.city}
                      />
                      <span className="text-danger">{errors.city}</span>
                    </div>
                  </div>

                  <div className="col-sm">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Zipcode</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        //placeholder="Zipcode"
                        name="zipcode"
                        onChange={handleBlur}
                        value={contactData.zipcode}
                      />
                      <span className="text-danger">{errors.zipcode}</span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>FaceBook URL</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        //placeholder="FaceBook URL"
                        name="facebook_url"
                        onChange={handleBlur}
                        value={contactData.facebook_url}
                      />
                      <span className="text-danger">{errors.facebook_url}</span>
                    </div>
                  </div>

                  <div className="col-sm">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>LinkedIn URL</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        //placeholder="LinkedIn URL"
                        name="linked_in_url"
                        onChange={handleBlur}
                        value={contactData.linked_in_url}
                      />
                      <span className="text-danger">{errors.linked_in_url}</span>
                    </div>
                  </div>
                </div>
                
                <div className="row">
                  <div className="col-sm">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Twitter URL</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        //placeholder="Twitter URL"
                        name="twitter_url"
                        onChange={handleBlur}
                        value={contactData.twitter_url}
                      />
                      <span className="text-danger">{errors.twitter_url}</span>
                    </div>
                  </div>

                  <div className="col-sm">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>InstaGram URL</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        //placeholder="InstaGram URL"
                        name="instagram_url"
                        onChange={handleBlur}
                        value={contactData.instagram_url}
                      />
                      <span className="text-danger">{errors.instagram_url}</span>
                    </div>
                  </div>
                </div>
                    
                <div className="row">
                  <div className="col-sm">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Tags</strong>
                      </label>

                      <CreatableSelect
                        className="form-control"
                        name="tags"
                        isMulti
                        onChange={handleSelectTags}
                        options={tagsOptions}
                        value={selectedTags}
                        
                      />
                      <span className="text-danger">{errors.tags}</span>
                    </div>
                  </div>
                </div>

                <div className="row text-right">
                  <div className="col-sm">
                    <Button type="submit" variant="primary" className="btn-sm">
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
    </Fragment>
  );
}

export default UpdateContactForm;
