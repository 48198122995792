import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import {
  getNotesList,
  createNote,
  deleteNote,
  updateNote,
} from "../../actions/contactsNotes";
import Loader from "../../layouts/Loader";

import "./style.css";
import { notifyInfo, notifyError } from "../../components/Toastr/Toastr";
import { confirmAlert } from "react-confirm-alert";
import ShowNoteForm from "./ShowNoteForm";
import EasyEdit, { Types } from 'react-easy-edit';

const Notes = ({
  toggleTab,
  PerfectScrollbar,
  toggleChatBox,
  isDialpadShow,
  contact,
}) => {
  let contact_uuid = contact.contact_uuid;
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const [errors, setErrors] = useState({
    note: "",
  });

  const [showNoteModal, setShowNoteModal] = useState(false);
  const [ContactsEvent, setContactsEvent] = useState("");

  const [updateNoteText, setUpdateNodeText] = useState("");
  const [contactsNotes, setContactsNotes] = useState([]);
  const [notesText, setNotesText] = useState({
    note: "",
    contact_uuid: contact_uuid,
    createdby: createdby,
    main_admin_uuid: createdby,
    user_uuid: createdby,
  });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
 
  const closeModel = (value) => {
    setShowNoteModal(value);
  };

  const handleBlur = (e) => {
    const newExtensionData = { ...notesText };
    newExtensionData[e.target.name] = e.target.value;
    setNotesText(newExtensionData);
  };

 

  function read_more(e,noteTxt) {
    e.preventDefault();
    setUpdateNodeText(noteTxt);
    setShowNoteModal(true);
  }

  const save = (value,note_uuid) => {
    setLoading(true);
    dispatch(updateNote(note_uuid, value, main_uuid, createdby, token))
      .then((response) => {
        setContactsEvent(note_uuid);
        setUpdateNodeText("");
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
    
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (!notesText.note) {
      error_data["note"] = "Please enter note.";
      setErrors(error_data);
      isError = "true";
    } else {
      error_data["note"] = "";
      setErrors(error_data);
      isError = "false";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //add notes API
      const data = { ...notesText };

      data["contact_uuid"] = contact_uuid;
      dispatch(createNote(data, token))
        .then((res) => {
          if (res.status === 200) {
            notifyInfo(res.message);
            setContactsEvent(res.data);
            const newExtensionData = { ...notesText };
            newExtensionData["note"] = "";
            setNotesText(newExtensionData);
            // navigate("/extensions", { replace: true });
          } else if (res.status === 406) {
            error_data["note"] = res.message;
            setErrors(error_data);
            isError = "true";
          } else if (res.status === 500) {
            notifyError("Something went wrong.");
          } else {
            notifyError("Something went wrong.");
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getNotesList(contact.contact_uuid, main_uuid, createdby, token))
      .then((response) => {
        //console.log(response);
        setContactsNotes(response.data);
        setContactsEvent("");
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, [contact, ContactsEvent]);

  const deleteNotes = (uuid) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this Note?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteNoteService(uuid),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteNoteService = (uuid) => {
    setLoading(true);
    dispatch(deleteNote(uuid, createdby, token))
      .then((response) => {
        if (response.status === 200) {
          notifyInfo(response.message);
          setContactsEvent(uuid);
          return;
        } else if (response.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const CustomDisplay = props => {
    const val = props.value;

   let str = val.length > 50 ? val.slice(0, 50) : val;

    return str;
  };

  return (
    <div
      className={`tab-pane fade  ${toggleTab === "notes" ? "active show" : ""}`}
      id="notes"
    >
      {loading && <Loader />}
      <div className="card mb-sm-3 mb-md-0 note_card">
        <PerfectScrollbar
          className={`card-body p-0 dz-scroll  ${
            toggleChatBox ? "ps ps--active-y" : ""
          }${isDialpadShow === "YES" ? "contacts_body_new" : "contacts_body"}`}
          id="DZ_W_Contacts_Body2"
          //style={{height: isDialpadShow == 'YES' ? '155px' : '570px'}}
        >

          <div className="HistoryIndent clearfix">

            {contactsNotes.map((note) => (
              <div key={note.note_uuid} className="position-relative">
                {/* <span> */}
                  <EasyEdit
                    type={Types.TEXTAREA}
                    onSave={value => {
                      save(value,note.note_uuid)
                    }}
                    //onCancel={cancel}
                    saveButtonLabel={<i className="fa fa-check" aria-hidden="true"></i>}
                    saveButtonStyle = 'label-primary easy-edit-button rounded-lg'
                    cancelButtonLabel={<i className="fa fa-times" aria-hidden="true"></i>}
                    cancelButtonStyle= 'label-danger easy-edit-button rounded-lg'
                    attributes={{ name: 'note', className : 'form-control h-75'}}
                    viewAttributes={{className : 'w-75 font-w600 text-dark'}}
                    onValidate={(value) => {                          
                      return value ? true : false;
                    }}
                    //validationMessage="What just happened?"
                    displayComponent={<CustomDisplay />}
                    value={note.notes}
                  />
                  {note.notes.length > 50 ? (
                    <a href="#!"
                    className="text-info"
                      onClick={(e) => read_more(e,note.notes)}
                    >
                      Read More
                    </a>
                  ) : (
                    ""
                  )}

                  <p>
                    {note.created_at}
                  </p>

                  
                {/* </span>                */}
                  <Link
                    onClick={() => deleteNotes(note.note_uuid)}
                    className="delete-btn"
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  </Link>
              </div>
            ))}

            
            
          </div>
          
          <div className="card-footer position-absolute" style={{bottom:'0'}}>
            {Object.keys(contact).length > 0 ? (
              <form action="" onSubmit={(e) => submitHandler(e)} className="">
                {/* <div className="card-header chat-list-header text-center note_header"> */}
                  <input
                    type="text"
                    className="form-control"
                    name="note"
                    placeholder="Add a note..."
                    value={notesText.note}
                    onChange={handleBlur}
                  />

                  <Button
                    type="submit"
                    className="mt-2 btn btn-primary btn-sm"
                  >
                    Add Note
                  </Button>
                  <span className="text-danger">{errors.note}</span>
                {/* </div> */}
              </form>
            ) : (
              <></>
            )}
          </div>
            

        </PerfectScrollbar>        
      </div>
      

      <Modal className="fade" show={showNoteModal}>
          <ShowNoteForm
            closeModel={closeModel}
            updateNodeText={updateNoteText}
          />
        </Modal>
    </div>
  );
};

export default Notes;
