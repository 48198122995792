import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const getContactList = (contactSearchData,token) => {
  
  return axios
    .post(
      API_URL + "get-contact-list",

      contactSearchData,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const createContact = (data,token) => {
  return axios
    .post(API_URL + "create-contact", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const updateContact = (data) => {
  return axios
    .post(API_URL + "update-contact", data, {
      headers: {
        Authorization: data.token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getContactDetail = (createdby, token, contactuuid) => {
  return axios
    .post(
      API_URL + "get-contact-details",
      {
        createdby,
        contactuuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getContactActivity = (createdby, token, contact_uuid,offSet,limits) => {
  return axios
    .post(
      API_URL + "get-contact-activity",
      {
        createdby,
        contact_uuid,
        offSet,limits,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getContactEmails = (createdby, token, contact_uuid,offSet,limits) => {
  return axios
    .post(
      API_URL + "get-contact-emails",
      {
        createdby,
        contact_uuid,
        offSet,limits,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const deleteContact = (contact_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "delete-contact",
      {
        contact_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const deleteAllContact = (contacts, createdby, token) => {
  return axios
    .post(
      API_URL + "delete-bulk-contact",
      {
        contacts,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getContactFields = (createdby, token) => {
  return axios
    .post(
      API_URL + "get-contact-field",
      {
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getContactSetting = (createdby, token) => {
  return axios
    .post(
      API_URL + "get-contact-form-setting",
      {
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const importCsv = (data, token) => {
  return axios
    .post(API_URL + "import-csv", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getMappingFields = (createdby, token, filename) => {
  return axios
    .post(
      API_URL + "get-mapping-fields",
      {
        filename,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const insertCsvData = (data) => {
  return axios
    .post(API_URL + "insert-csv-data", data, {
      headers: {
        Authorization: data.token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const mappingFields = (data) => {
  return axios
    .post(API_URL + "mapping-fields", data, {
      headers: {
        Authorization: data.token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};


const saveContactSearch = (data,token) => {
  return axios
    .post(API_URL + "save-contact-search", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getSelectedContacts = (contacts ,createdby, token) => {
  
  return axios
    .post(
      API_URL + "get-selected-contact",
      {
        contacts,
        createdby,
      },      
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateBulkContact = (data) => {
  return axios
    .post(API_URL + "update-bulk-contact", data, {
      headers: {
        Authorization: data.token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getWebphoneContact = (contactSearchData) => {
  
  return axios
    .post(
      API_URL + "get-webphone-contact",

      contactSearchData,
      {
        headers: {
          Authorization: contactSearchData.token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getContactPersona = (main_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "get-contact-persona",
      {
        main_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getContactTags = (main_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "get-contact-tags",
      {
        main_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getContactGroups = (contact_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "get-contact-groups",
      {
        contact_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateContactGroups = (data,token) => {
  return axios
    .post(API_URL + "update-contact-groups", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const contactDropdown = (data,token) => {
  return axios
    .post(API_URL + "get-dropdown-contact", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};


export default {
  getContactList,
  createContact,
  updateContact,
  getContactDetail,
  deleteContact,
  getContactFields,
  importCsv,
  getMappingFields,
  insertCsvData,
  getContactSetting,
  mappingFields,
  saveContactSearch,
  deleteAllContact,
  getSelectedContacts,
  updateBulkContact,
  getWebphoneContact,
  getContactPersona,
  getContactTags,
  getContactGroups,
  updateContactGroups,
  contactDropdown,
  getContactActivity,
  getContactEmails,
};
