import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Card, Modal, Button } from "react-bootstrap";
import { checkEmailConfig } from "../../actions/emailconfig";
import { notifyInfo, notifyError } from "../../components/Toastr/Toastr";
import { useNavigate } from "react-router-dom";
import Loader from "../../layouts/Loader";

function EmailConfigForm(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  let user_data = props.user_data;

  let token = props.token;

  const closeModel = (arg) => {
    props.closeECModel(arg);
  };

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [emailConfigData, setEmailConfigData] = useState({
    email: "",
    password: "",
    incoming_host: "",
    incoming_port: "",
    smtp_host: "",
    smtp_port: "",
    smtp_encryption: "",
    main_admin_uuid: user_data.main_uuid,
    createdby: user_data.user_uuid,
    user_uuid: user_data.user_uuid,
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
    incoming_host: "",
    incoming_port: "",
    smtp_host: "",
    smtp_port: "",
    smtp_encryption: "",
  });

  const handleBlur = (e) => {
    const newGroupData = { ...emailConfigData };
    let error_data = { ...errors };

    if (e.target.name === "email") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Email is required.";
        setErrors(error_data);
      } else {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
          error_data[e.target.name] = "Email is invalid.";
          setErrors(error_data);
        } else {
          error_data[e.target.name] = "";
          setErrors(error_data);
        }
      }
    }

    if (e.target.name === "password") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Password is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "incoming_host") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Incoming host is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "incoming_port") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Incoming port is required.";
        setErrors(error_data);
      } else {
        if (/^\d*$/.test(e.target.value)) {
          error_data[e.target.name] = "";
          setErrors(error_data);
        } else {
          error_data[e.target.name] = "Incoming port invalid.";
          setErrors(error_data);
        }
      }
    }

    if (e.target.name === "smtp_host") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "SMTP host is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    if (e.target.name === "smtp_port") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "SMTP port is required.";
        setErrors(error_data);
      } else {
        if (/^\d*$/.test(e.target.value)) {
          error_data[e.target.name] = "";
          setErrors(error_data);
        } else {
          error_data[e.target.name] = "SMTP port invalid.";
          setErrors(error_data);
        }
      }
    }

    if (e.target.name === "smtp_encryption") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "SMTP encryption is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    newGroupData[e.target.name] = e.target.value;
    setEmailConfigData(newGroupData);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);

    let error_data = { ...errors };
    let isError = "false";

    if (emailConfigData.email.length === 0) {
      error_data["email"] = "Email is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (emailConfigData.password.length === 0) {
      error_data["password"] = "Password is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (emailConfigData.incoming_host.length === 0) {
      error_data["incoming_host"] = "Incoming host is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (emailConfigData.incoming_port.length === 0) {
      error_data["incoming_port"] = "Incoming port is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (emailConfigData.smtp_host.length === 0) {
      error_data["smtp_host"] = "SMTP host is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (emailConfigData.smtp_port.length === 0) {
      error_data["smtp_port"] = "SMTP port is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (emailConfigData.smtp_encryption.length === 0) {
      error_data["smtp_encryption"] = "SMTP encryption is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create user API
      dispatch(checkEmailConfig(emailConfigData, token))
        .then((res) => {
          if (res.status === 200) {
            notifyInfo(res.message);
            closeModel(false);
            user.data.data.is_email_config_done = "YES";
            localStorage.setItem("user", JSON.stringify(user));
            navigate("/onboarding", { replace: true });
            setLoading(false);
            window.location.reload();
          } else if (res.status === 406) {
            error_data["name"] = res.message;
            setErrors(error_data);
            isError = "true";
          } else if (res.status === 500) {
            setLoading(false);
            // notifyError("Something went wrong.");
          } else {
            setLoading(false);
             notifyError("Something went wrong.");
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Fragment>
       {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>Email Configuration</Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <form action="" onSubmit={(e) => submitHandler(e)}>
          <Row>
            <Col xl="12">
              <Card className="bgl-primary">
                <Card.Header className="d-block card-header">
                  <Card.Title>Incoming Configuration</Card.Title>
                </Card.Header>
                <Card.Body className="card-body">
                  <div className="row">
                    <span className="text-danger">{errors.name}</span>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>Email ID</strong>
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          name="email"
                          id="email"
                          onChange={handleBlur}
                        />
                        <span className="text-danger">{errors.email}</span>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>Password</strong>
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          name="password"
                          id="password"
                          onChange={handleBlur}
                        />
                        <span className="text-danger">{errors.password}</span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>Incoming Host</strong>
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Incoming Host"
                          name="incoming_host"
                          id="incoming_host"
                          onChange={handleBlur}
                        />
                        <span className="text-danger">
                          {errors.incoming_host}
                        </span>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>Incoming Port</strong>
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Incoming Port"
                          name="incoming_port"
                          id="incoming_port"
                          onChange={handleBlur}
                        />
                        <span className="text-danger">
                          {errors.incoming_port}
                        </span>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col xl="12">
              <Card className="bgl-primary">
                <Card.Header className="d-block card-header">
                  <Card.Title>SMTP Details</Card.Title>
                </Card.Header>
                <Card.Body className="card-body">
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>SMTP Host</strong>
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="SMTP Host"
                          name="smtp_host"
                          id="smtp_host"
                          onChange={handleBlur}
                        />
                        <span className="text-danger">{errors.smtp_host}</span>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>SMTP Port</strong>
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="SMTP Port"
                          name="smtp_port"
                          id="smtp_port"
                          onChange={handleBlur}
                        />
                        <span className="text-danger">{errors.smtp_port}</span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>SMTP Encryption</strong>
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control"
                          name="smtp_encryption"
                          onChange={handleBlur}
                          value={emailConfigData.smtp_encryption}
                        >
                          <option value="">Select SMTP Encryption</option>
                          <option value="SSL">SSL</option>
                          <option value="TLS">TLS</option>
                        </select>

                        <span className="text-danger">
                          {errors.smtp_encryption}
                        </span>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <div className="row text-right">
            <div className="col-sm">
              <Button type="submit" variant="primary" className="btn-sm">
                Save
              </Button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Fragment>
  );
}

export default EmailConfigForm;
