import React, { Fragment, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import {
  createBlockedNumber,
  updateBlockedNumber,
} from "../../actions/blockednumbers";
import Loader from "../../layouts/Loader";

function BlockedNumberForm(props) {
  const closeModel = (arg) => {
    props.closeModel(arg);
  };

  let numberUpData = props.blockedNumberUpdate;

  let title = "Add Number to Block List";

  if (numberUpData.blocked_number_uuid !== "") {
    title = "Update Number to Block List";
  }

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({
    number: "",
    description: "",
  });

  const [numberData, setNumberData] = useState(numberUpData);

  const handleBlur = (e) => {
    const newNumberData = { ...numberData };
    let error_data = { ...errors };

    if (e.target.name === "number") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Number is required.";
        setErrors(error_data);
      } else {
        if (!/^[0-9]{7,11}$/i.test(numberData.number)) {
          error_data["number"] = "Invalid caller id number.";
          setErrors(error_data);
        } else {
          error_data[e.target.name] = "";
          setErrors(error_data);
        }
      }
    }

    if (e.target.name === "description") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Description is required.";
        setErrors(error_data);
      } else {
        error_data[e.target.name] = "";
        setErrors(error_data);
      }
    }

    newNumberData[e.target.name] = e.target.value;
    setNumberData(newNumberData);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (numberData.number.length === 0) {
      error_data["number"] = "Number is required.";
      setErrors(error_data);
      isError = "true";
    } else {
      if (!/^[0-9]{7,11}$/i.test(numberData.number)) {
        error_data["number"] = "Invalid caller id number.";
        setErrors(error_data);
        isError = "true";
      }
    }

    if (numberData.description.length === 0) {
      error_data["description"] = "Description is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      let newNumberData = { ...numberData };

      newNumberData["main_user_uuid"] = main_uuid;
      newNumberData["createdby"] = createdby;

      if (numberUpData.blocked_number_uuid === "") {
        // create bloack number API
        dispatch(createBlockedNumber(newNumberData, token))
          .then((res) => {
            closeModel(false);
            props.handleNumberEvent(Math.random());
            if (res.status === 200) {
              notifyInfo(res.message);
              navigate("/blocked-numbers-list", { replace: true });
            } else if (res.status === 406) {
              error_data["number"] = res.message;
              setErrors(error_data);
              isError = "true";
            } else if (res.status === 500) {
              notifyError("Something went wrong.");
            } else {
              notifyError("Something went wrong.");
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        // create bloack number API
        dispatch(updateBlockedNumber(newNumberData, token))
          .then((res) => {
            closeModel(false);
            props.handleNumberEvent(Math.random());
            if (res.status === 200) {
              notifyInfo(res.message);
              navigate("/blocked-numbers-list", { replace: true });
            } else if (res.status === 406) {
              error_data["number"] = res.message;
              setErrors(error_data);
              isError = "true";
            } else if (res.status === 500) {
              notifyError("Something went wrong.");
            } else {
              notifyError("Something went wrong.");
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };

  return (
    <Fragment>
        {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>{title} </Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <form action="" onSubmit={(e) => submitHandler(e)}>
        <Modal.Body>
          <div className="row">
            <div className="col-sm">
              <div className="form-group">
                <label className="mb-1">
                  <strong>Number</strong>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Number"
                  name="number"
                  id="number"
                  value={numberData.number}
                  onChange={handleBlur}
                />
                <span className="text-danger">{errors.number}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <div className="form-group">
                <label className="mb-1">
                  <strong>Description</strong>
                </label>
                <textarea
                  className="form-control"
                  name="description"
                  value={numberData.description}
                  onChange={handleBlur}
                ></textarea>

                <span className="text-danger">{errors.description}</span>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => closeModel(false)}
            variant="danger light btn-sm"
          >
            Close
          </Button>
          <Button type="submit" variant="primary btn-sm">
            Add
          </Button>
        </Modal.Footer>
      </form>
    </Fragment>
  );
}

export default BlockedNumberForm;
