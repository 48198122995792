import React, { Fragment,useState } from "react";
import { Modal,Button, } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { createCustomField } from "../../../actions/customfield";
import Loader from "../../../layouts/Loader";
import AddDependentFieldForm from "./AddDependentFieldForm";

function CustomFieldForm(props) {

    const closeModel = (arg) => {
        props.closeModel(arg);
    }

    const user = JSON.parse(localStorage.getItem("user"));
    const user_data = user.data.data;
    const token = user.data.token;
    const createdby = user_data.user_uuid;

    const tab_data = props.selectedTab;

    const [showOptionInput, setshowOptionInput] = useState(false);
    const [inputOptions, setInputOptions] = useState([
        { index: Math.random(), field_options: '' }
    ])

    const [dependentFields, setDependentFields] = useState([])

    const [customFieldData, setCustomFieldData] = useState({
        field_name: "",
        field_type: "",
        field_options: inputOptions,
        createdby: createdby,
        main_user_uuid: createdby,
        user_uuid: createdby,
        tab_uuid: tab_data.tab_uuid,
        section_uuid: tab_data.section_uuid,
        sec_type: tab_data.sec_type,
        isDependentField : 'NO',
        dependent_field : [],
    });

    const [errors, setErrors] = useState({
        field_name: "",
        field_type: "",
        field_options: "",
        answer_on_show:"",
    });   

    const [loading, setLoading] = useState(false);
  
    const dispatch = useDispatch();

    const addInputOptionField = () => {
        let newField = { index: Math.random(), field_options: '' };
    
        setInputOptions([...inputOptions, newField])
    }

    const removeInputOption = (index) => {
        
        let data = inputOptions.filter((item) => item.index !== index);
        setInputOptions(data)
    }
    

    const handleBlur = (e) => {
        const newCustomeFieldData = { ...customFieldData };
        let error_data = { ...errors };
        
        if((e.target.name === 'field_name' || e.target.name === 'field_type') && e.target.value.length === 0){
            error_data[e.target.name] = e.target.name + " is required.";
            setErrors(error_data);
        }else{

            if(e.target.name === 'field_type' && ['DROPDOWN', 'RADIO','CHECKBOX'].includes(e.target.value)){
                setshowOptionInput(true)
                
            }else if (e.target.name === 'field_type' && !['DROPDOWN', 'RADIO','CHECKBOX'].includes(e.target.value)){
                
                setshowOptionInput(false)
            }
            error_data[e.target.name] = "";
            setErrors(error_data);
        }
        newCustomeFieldData[e.target.name] = e.target.value;
        setCustomFieldData(newCustomeFieldData);  
    };

    const handleOptionChange = (index, evnt)=>{

        const nextShapes = inputOptions.map(shape => {
            if (shape.index === index) {
                shape['field_options'] = evnt.target.value;              
            } 
            return shape;
        });
        // Re-render with the new array
        setInputOptions(nextShapes);

        if(dependentFields[index]){
            let newDependentFields = {...dependentFields};

            const arr = newDependentFields[index].map(obj => {
    
                obj.field_options.map(op_obj => {
                    op_obj['field_options'] = evnt.target.value;  
                    return op_obj;
                });

                return obj;
    
            });

            //newDependentFields[index]['answer_on_show'] = evnt.target.value;
            newDependentFields[index] = arr;
            setDependentFields(newDependentFields);
        }

    }

    const submitHandler = (e) => {
        e.preventDefault();

        //console.log(dependentFields);
        //return false;

        let error_data = { ...errors };
        let isError = 'false';

        if(customFieldData.field_name.length === 0){
            error_data['field_name'] = "Field Name is required.";
            setErrors(error_data);
            isError = 'true';
        }

        if(customFieldData.field_type.length === 0){
            error_data['field_type'] = "Field Type is required.";
            setErrors(error_data);
            isError = 'true';
        }

        setLoading(true);

        if(isError === 'true'){
            setLoading(false);
            return;
        }else{

            //var option_str = '';
            const field_options = inputOptions.map((item, index) => 
               item.field_options
            );

            const newCustomeFieldData = { ...customFieldData };
            newCustomeFieldData['field_options'] = field_options;
            newCustomeFieldData['dependent_field'] = dependentFields;
            setCustomFieldData(newCustomeFieldData);

            customFieldData['field_options'] = field_options.join(',');
            customFieldData['dependent_field'] = dependentFields;

            //call create custom field API
            dispatch(createCustomField(customFieldData,token))
            .then(() => {
                closeModel(false);
                props.handleCustomFieldEvent(Math.random);
                props.handleFormEvent(Math.random);
                //navigate("/custom-fields", {replace: true})
                setLoading(false);;
            })            
            .catch(() => {
              setLoading(false);
            });
  
        }
    }
    
    const handleIsdependent = (e,option_index) => {
        const newCustomeFieldData = { ...customFieldData };
        const newDependentFields = { ...dependentFields };

        const arr = inputOptions.filter(obj => obj.index === option_index).map(obj => {
            
                return obj.field_options;  
            
        });

        if (e.target.checked) {
            
            newCustomeFieldData['isDependentField'] = 'YES';
            newDependentFields[option_index] = [{ 
                index: Math.random(), 
                option_index: option_index, 
                dependent_field_name: '' ,
                dependent_field_type: '',
                answer_on_show:  (arr[0]) ? arr[0] : "",
                is_field_option_show: 'NO',
                field_options: [],
            }]
            newDependentFields[option_index]['answer_on_show'] = (arr[0]) ? arr[0] : "";
            setDependentFields(newDependentFields);
                
        }else{
            
            newCustomeFieldData['isDependentField'] = 'NO';

            setDependentFields(current => {
                // 👇️ create a copy of the state object
                const copy = {...current};
            
                // 👇️ remove the salary key from the object
                delete copy[option_index];
            
                return copy;
              });
        }

        setCustomFieldData(newCustomeFieldData);
    }

    const handleDependentField = (e,index,dependentField_index) => {
        
        let new_arr = {...dependentFields};

        const arr = new_arr[index].map(obj => {
            if (obj.index === dependentField_index) {
                obj[e.target.name] = e.target.value;  
                
                if(e.target.name === 'dependent_field_type' && ['DROPDOWN', 'RADIO','CHECKBOX'].includes(e.target.value)){
                    obj['is_field_option_show'] = 'YES';
                    obj['field_options'] = [{ index: Math.random(), field_options: '' }];
                }else{
                    if(e.target.name === 'dependent_field_type'){
                        obj['is_field_option_show'] = 'NO';
                        obj['field_options'] = [];
                    }                    
                }

                return obj;

            } else{
                return obj;
            }
        });

        new_arr[index] = arr;

        setDependentFields(new_arr);

    }

    const removeDependentField = (e,index,dependentField_index) => {
        let new_arr = {...dependentFields};

        let arr = new_arr[index].filter(obj => obj.index !== dependentField_index);

        new_arr[index] = arr;

        setDependentFields(new_arr);
    }

    const handleDependentOptionChange = (dependentField_index,input_index,option_index,e) => {

        let new_arr = {...dependentFields}

        const arr = new_arr[input_index].map(obj => {
            if (obj.index === dependentField_index) {

                obj.field_options.map(op_obj => {
                    if (op_obj.index === option_index) {
                        op_obj['field_options'] = e.target.value;              
                    } 
                    return op_obj;
                });

                return obj;

            } else{
                return obj;
            }
        });

        new_arr[input_index] = arr;

        // Re-render with the new array
        setDependentFields(new_arr);
    }

    const addDependentInputOptionField = (field_index,dependentField_index) => {
        let newField = { index: Math.random(), field_options: '' };

        let new_arr = {...dependentFields}

        const arr = new_arr[field_index].map(obj => {
            if (obj.index === dependentField_index) {

                obj['field_options'] = [...obj['field_options'], newField]

                return obj;

            } else{
                return obj;
            }
        });


        new_arr[field_index] = arr;
    
        setDependentFields(new_arr);
    }

    const removeDependentInputOption = (dependentField_index,field_index,option_index) => {

        let new_arr = {...dependentFields}
        
        const arr = new_arr[field_index].map(obj => {
            if (obj.index === dependentField_index) {

                obj['field_options'] = obj['field_options'].filter(op_obj => op_obj.index !== option_index);

                return obj;

            } else{
                return obj;
            }
        });

        new_arr[field_index] = arr;
        // Re-render with the new array
        setDependentFields(new_arr);
    }

    const addMoreDependentField = (option_index) => {
        const newDependentFields = { ...dependentFields };

        let answer_on_show = newDependentFields[option_index][0]['answer_on_show'];

        newDependentFields[option_index] = [...newDependentFields[option_index] , { 
            index: Math.random(), 
            option_index: option_index, 
            dependent_field_name: '' ,
            dependent_field_type: '',
            answer_on_show: answer_on_show,
            is_field_option_show: 'NO',
            field_options: [],
        } ];

        setDependentFields(newDependentFields);
    } 


    return (
        <Fragment>
             {loading && <Loader />}
            <Modal.Header>
                    <Modal.Title>Add new Field</Modal.Title>
                    <Button
                        variant=""
                        className="close"
                        onClick={() => closeModel(false)}
                    >
                        <span>&times;</span>
                    </Button>
                </Modal.Header>
                <form action="" onSubmit={(e) => submitHandler(e)}>
                    <Modal.Body>

                        <div className="row">
                            <div className="col-md-5 input-primary">
                                <label className="mb-1">
                                    <strong>Field Type</strong>
                                </label>
                                <select
                                    className="form-control bg-white"
                                    name="field_type"
                                    onChange={handleBlur}
                                >
                                    <option value="">Select Type</option>
                                    <option value="TEXT">TEXT</option>
                                    <option value="DROPDOWN">DROPDOWN</option>
                                    <option value="TEXTAREA">TEXTAREA</option>
                                    <option value="RADIO">RADIO</option>
                                    <option value="CHECKBOX">CHECKBOX</option>
                                    <option value="DATE">DATE</option>

                                </select>
                                <span className="text-danger">{errors.field_type}</span>
                            </div>

                            <div className="col-md-7 input-primary">
                                <label className="mb-1">
                                    <strong>Field Name</strong>
                                </label>
                                <input
                                    type="text"
                                    className="form-control bg-white"
                                    placeholder="Field Name"
                                    name="field_name"
                                    onChange={handleBlur}
                                />
                                <span className="text-danger">{errors.field_name}</span>
                            </div>

                        </div>                        


                        <div className={showOptionInput ? `input-primary` : 'd-none input-primary'} >
                            {inputOptions.map((input) => {                            
                               return (
                                <span key={input.index} >
                                    <div className="row mt-2"  id={input.index}>
                                            
                                        <div className="col-md-9">
                                            

                                            <input
                                                name='field_options'
                                                placeholder='Option Value'
                                                className="form-control bg-white"
                                                //defaultValue={input.field_options}
                                                onChange={(e) => handleOptionChange(input.index,e)}
                                            />  
                                        </div>
                                        
                                        <div className="col-md-2">
                                            {(inputOptions.length!==1)? <button type="button" className="btn btn-sm btn-outline-danger" onClick={() => removeInputOption(input.index)}>x</button>:''}
                                        </div>                             
                                    </div> 
                                       
                                        {(customFieldData.field_type === 'DROPDOWN' || customFieldData.field_type === 'RADIO') && 
                                            <>
                                                add conditional field ? 
                                                <div className="col-md-1 form-check form-check-inline ml-2">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        //value={option}
                                                        name="isDependentField"
                                                        onChange={(e) => handleIsdependent(e,input.index)}
                                                    />
                                                </div>
                                            </>
                                        }

                                    {dependentFields[input.index] && dependentFields[input.index].map((dependentField) => {                            
                                        return (
                                            <span  key={dependentField.index} className="border-bottom">
                                                <AddDependentFieldForm  dependentField={dependentField} input_index={input.index} 
                                                    handleDependentField={handleDependentField}
                                                    removeDependentField={removeDependentField}
                                                    handleDependentOptionChange={handleDependentOptionChange}
                                                    addDependentInputOptionField={addDependentInputOptionField}
                                                    removeDependentInputOption={removeDependentInputOption}
                                                    dependentFields={dependentFields[input.index]}                                                    
                                                 />
                                            </span>
                                            
                                        ) })

                                    } {/* end dependent fields setting */}

                                    {dependentFields[input.index] &&
                                        <div className="border-bottom">
                                            <button type="button" className="btn btn-sm text-primary" onClick={(e) => addMoreDependentField(input.index)}>Add More Field</button>
                                        </div>
                                    }
                                </span>
                                )                       
                            })}

                            <button type="button" className="btn btn-sm btn-success mt-1 pull-right" onClick={addInputOptionField}>+</button>
                            <br></br>
                        </div>
                        
                        {/* {(customFieldData.field_type == 'DROPDOWN' || customFieldData.field_type == 'RADIO') && 
                            <div className="form-group input-primary">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    //value={option}
                                    name="isDependentField"
                                    onChange={(e) => handleIsdependent(e)}
                                />
                                <label className="form-check-label">Add dependent field</label>
                            </div>
                        } */}
                        

                        
                        
                    
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => closeModel(false)}
                            variant="danger light btn-sm"
                        >
                            Close
                        </Button>
                        <Button type="submit" variant="primary btn-sm">Save</Button>
                    </Modal.Footer>
                </form>
        </Fragment>
    );
}

export default CustomFieldForm;