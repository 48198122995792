import React, { Component } from "react";

/// Menu
//import MetisMenu from "metismenujs";

// class MM extends Component {
//   componentDidMount() {
//     this.$el = this.el;
//     this.mm = new MetisMenu(this.$el);
//   }
//   componentWillUnmount() {
//     // this.mm("dispose");
//   }
//   render() {
//     return (
//       <div className="mm-wrapper">
//         <ul className="metismenu" ref={(el) => (this.el = el)}>
//           {this.props.children}
//         </ul>
//       </div>
//     );
//   }
// }

class SideBarAccountManagement extends Component {
  /// Open menu
  componentDidMount() {}
  render() {
    /// Path
    // var path = window.location.pathname;
    // path = path.split("/");
    // path = path[path.length - 1];

    return <></>;
  }
}

export default SideBarAccountManagement;
