import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import { updateIvr } from "../../actions/ivr";
import "./style.css";
import Tooltip from "rc-tooltip";
import EasyEdit, { Types } from "react-easy-edit";
import Loader from "../../layouts/Loader";

function GeneralFields(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;

  let ivrData = props.ivrData;
  let recordings = props.recordings;
  let destination = props.destination;

  let timeout_array = new Array(60).fill(0).map((_, i) => {
    return { label: i, value: i };
  });
  let number_array = new Array(10).fill(1).map((_, i) => {
    return { label: i + 1, value: i + 1 };
  });

  let i = 1;

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({
    ring_group_name: "",
    ring_group_extension: "",
    ivr_menu_welcome_message: "",
  });

  const save = (value, attribute) => {
    let error_data = { ...errors };
    let isError = "false";
    setLoading(true);

    if (
      (attribute === "ivr_menu_welcome_message" ||
        attribute === "ivr_menu_greet_short") &&
      value === "file_new_upload"
    ) {
      navigate(`/upload-recording`, {
        state: {
          defaultTab: "ivr",
          type: "update_ivr",
          ivr_menu_uuid: ivrData.ivr_menu_uuid,
        },
      });
      return false;
    }

    if (attribute === "ivr_menu_welcome_message" && value.length === 0) {
      error_data["ivr_menu_welcome_message"] = "Welcome message is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (attribute === "ivr_menu_name" && value.length === 0) {
      error_data["ivr_menu_name"] = "Auto attendant name is required.";
      setErrors(error_data);
      isError = "true";
    }

    let data = { ...ivrData };
    data["token"] = token;
    data["createdby"] = createdby;
    data["main_admin_uuid"] = ivrData.main_admin_uuid;
    data["user_uuid"] = createdby;

    data[attribute] = value;
    data["attribute"] = attribute;

    if (attribute === "ivr_menu_invalid_destination" && value.length > 0) {
      const value_arr = value.split("_");

      data["ivr_menu_invalid_destination"] = value_arr[1];
      data["ivr_menu_invalid_destination_type"] = value_arr[0];
    }

    if (isError === "true") {
      return;
    } else {
      dispatch(updateIvr(data))
        .then((res) => {
          if (res.status === 200) {
            notifyInfo(res.message);
            setLoading(false);
            props.handleIvrEvent(Math.random);
          } else if (res.status === 500) {
            setLoading(false);
            notifyError("Something went wrong.");
          } else {
            setLoading(false);
            notifyError("Something went wrong.");
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const handleChange = (e, default_val) => {
    let value = "";
    if (default_val === "TRUE") {
      value = "FALSE";
    } else {
      value = "TRUE";
    }

    save(value, e.target.name);
  };

  const CustomDisplay = (props) => {
    let value = "None";

    Object.keys(destination).forEach((group) => {
      destination[group].forEach((option) => {
        if (
          props.name === "ivr_menu_exit_action" &&
          option.value === ivrData.ivr_menu_exit_action
        ) {
          value = group + "-" + option.text;
        }

        if (
          props.name === "ivr_menu_invalid_destination" &&
          option.value ===
            ivrData.ivr_menu_invalid_destination_type +
              "_" +
              ivrData.ivr_menu_invalid_destination
        ) {
          value = group + "-" + option.text;
        }
      });
    });

    return <p>{value}</p>;
  };

  const CustomEdit = (props) => {
    function inputonChange(e) {
      props.setParentValue(e.target.value);
    }

    let default_value = "";
    if (props.name === "ivr_menu_exit_action") {
      default_value = ivrData.ivr_menu_exit_action;
    }
    if (props.name === "ivr_menu_invalid_destination") {
      default_value =
        ivrData.ivr_menu_invalid_destination_type +
        "_" +
        ivrData.ivr_menu_invalid_destination;
    }

    return (
      <div>
        <select
          name={props.name}
          className="form-control p-0"
          onChange={inputonChange}
          defaultValue={default_value}
          style={{ height: "35px" }}
        >
          {Object.keys(destination).map((group, index) => {
            return (
              <optgroup key={index} label={group}>
                {destination[group].map((option) => {
                  return (
                    <option key={option.value} value={option.value}>
                      {option.text}
                    </option>
                  );
                })}
              </optgroup>
            );
          })}
        </select>
      </div>
    );
  };

  const CustomGreetings = (props) => {
    function inputonChange(e) {
      if (e.target.value === "file_new_upload") {
        navigate(`/upload-recording`, {
          state: {
            defaultTab: "ivr",
            type: "update_ivr",
            ivr_menu_uuid: ivrData.ivr_menu_uuid,
          },
        });
        return false;
      }

      props.setParentValue(e.target.value);
    }

    return (
      <div>
        <select
          name="ivr_menu_welcome_message"
          className="form-control p-0"
          onChange={inputonChange}
          defaultValue={ivrData.ivr_menu_welcome_message}
          style={{ height: "35px" }}
        >
          {recordings.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </select>
      </div>
    );
  };
  return (
    <Fragment>
      {loading && <Loader />}
      <div className="row">
        <div className="col-lg-4 col-md-4 col-4">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-sm">
                  <div className="form-group">
                    <label className="w-100">
                      Auto-Attendant ID
                      <span className="text-danger">*</span>
                      <Tooltip
                        placement="top"
                        overlay={<span>Auto-Attendant ID</span>}
                      >
                        <span className="ti-info-alt ml-1"></span>
                      </Tooltip>
                    </label>
                    <p>{ivrData.ivr_menu_extension}</p>
                  </div>
                </div>

                <div className="col-sm">
                  <div className="form-group">
                    <label className="w-100">
                      Auto-Attendant Name
                      <span className="text-danger">*</span>
                      <Tooltip
                        placement="top"
                        overlay={<span>Auto-Attendant Name</span>}
                      >
                        <span className="ti-info-alt ml-1"></span>
                      </Tooltip>
                    </label>

                    <EasyEdit
                      type={Types.TEXT}
                      onSave={(value) => {
                        save(value, "ivr_menu_name");
                      }}
                      //onCancel={cancel}
                      saveButtonLabel={
                        <i className="fa fa-check" aria-hidden="true"></i>
                      }
                      saveButtonStyle="label-primary easy-edit-button rounded-lg"
                      cancelButtonLabel={
                        <i className="fa fa-times" aria-hidden="true"></i>
                      }
                      cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                      attributes={{
                        name: "ivr_menu_name",
                        className: "form-control h-75",
                      }}
                      //viewAttributes={{className: 'fs-20'}}
                      onValidate={(value) => {
                        return value ? true : false;
                      }}
                      validationMessage="Auto-Attendant Name is required."
                      placeholder="Auto-Attendant Name"
                      value={ivrData.ivr_menu_name}
                    />
                    <span className="text-danger">{errors.ivr_menu_name}</span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm">
                  <div className="form-group">
                    <label className="w-100">
                      Welcome Message
                      <span className="text-danger">*</span>
                      <Tooltip
                        placement="top"
                        overlay={<span>Welcome Message</span>}
                      >
                        <span className="ti-info-alt ml-1"></span>
                      </Tooltip>
                    </label>

                    <EasyEdit
                      type={Types.SELECT}
                      onSave={(value) => {
                        save(value, "ivr_menu_welcome_message");
                      }}
                      //onCancel={cancel}
                      options={recordings}
                      editComponent={<CustomGreetings />}
                      saveButtonLabel={
                        <i className="fa fa-check" aria-hidden="true"></i>
                      }
                      saveButtonStyle="label-primary easy-edit-button rounded-lg"
                      cancelButtonLabel={
                        <i className="fa fa-times" aria-hidden="true"></i>
                      }
                      cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                      attributes={{
                        name: "ivr_menu_welcome_message",
                        className: "form-control p-0",
                        style: { height: "35px" },
                      }}
                      //viewAttributes={{className: 'fs-20'}}

                      placeholder="Welcome Message"
                      value={ivrData.ivr_menu_welcome_message}
                    />
                    <span className="text-danger">{errors.ivr_menu_name}</span>
                  </div>
                </div>

                <div className="col-sm">
                  <div className="form-group customToggle">
                    <label className="w-100">
                      Enable Direct Dial
                      <Tooltip
                        placement="top"
                        overlay={<span>Enable Direct Dial</span>}
                      >
                        <span className="ti-info-alt  ml-1"></span>
                      </Tooltip>
                    </label>

                    <p>
                      {ivrData.ivr_menu_direct_dial === "TRUE" ? "YES" : "NO"}
                      <span className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          name="ivr_menu_direct_dial"
                          id="ivr_menu_direct_dial"
                          checked={
                            ivrData.ivr_menu_direct_dial === "TRUE"
                              ? "checked"
                              : ""
                          }
                          onChange={(e) =>
                            handleChange(e, ivrData.ivr_menu_direct_dial)
                          }
                        />
                        <label
                          className="custom-control-label cursor-pointer"
                          htmlFor="ivr_menu_direct_dial"
                        ></label>
                      </span>
                    </p>

                    {/* <EasyEdit
                                            type={Types.SELECT}
                                            onSave={value => {
                                                save(value,'ivr_menu_direct_dial')
                                            }}
                                            //onCancel={cancel}
                                            options={direct_dial_array}
                                            saveButtonLabel={<i className="fa fa-check" aria-hidden="true"></i>}
                                            saveButtonStyle = 'label-primary easy-edit-button rounded-lg'
                                            cancelButtonLabel={<i className="fa fa-times" aria-hidden="true"></i>}
                                            cancelButtonStyle= 'label-danger easy-edit-button rounded-lg'
                                            attributes={{ name: 'ivr_menu_direct_dial', className : 'form-control p-0', style:{ height: '35px'}}}
                                            placeholder="Direct Dial"
                                            //viewAttributes={{ className:'pull-left' }}
                                            value={ivrData.ivr_menu_direct_dial}
                                        />   */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-4 col-4">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-sm">
                  <div className="form-group">
                    <label className="w-100">
                      Selection Timeout
                      <Tooltip
                        placement="top"
                        overlay={<span>Selection Timeout</span>}
                      >
                        <span className="ti-info-alt  ml-1"></span>
                      </Tooltip>
                    </label>

                    <EasyEdit
                      type={Types.SELECT}
                      onSave={(value) => {
                        save(value, "ivr_menu_timeout");
                      }}
                      //onCancel={cancel}
                      options={timeout_array}
                      saveButtonLabel={
                        <i className="fa fa-check" aria-hidden="true"></i>
                      }
                      saveButtonStyle="label-primary easy-edit-button rounded-lg"
                      cancelButtonLabel={
                        <i className="fa fa-times" aria-hidden="true"></i>
                      }
                      cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                      attributes={{
                        name: "ivr_menu_timeout",
                        className: "form-control p-0",
                        style: { height: "35px" },
                      }}
                      placeholder="None"
                      viewAttributes={{ className: "pull-left" }}
                      value={ivrData.ivr_menu_timeout}
                    />
                  </div>
                </div>

                <div className="col-sm">
                  <div className="form-group">
                    <label className="w-100">
                      Timeout Action
                      <Tooltip
                        placement="top"
                        overlay={<span>Timeout Action</span>}
                      >
                        <span className="ti-info-alt ml-1"></span>
                      </Tooltip>
                    </label>

                    <EasyEdit
                      type={Types.SELECT}
                      onSave={(value) => {
                        save(value, "ivr_menu_exit_action");
                      }}
                      //onCancel={cancel}
                      editComponent={<CustomEdit name="ivr_menu_exit_action" />}
                      displayComponent={
                        <CustomDisplay name="ivr_menu_exit_action" />
                      }
                      saveButtonLabel={
                        <i className="fa fa-check" aria-hidden="true"></i>
                      }
                      saveButtonStyle="label-primary easy-edit-button rounded-lg"
                      cancelButtonLabel={
                        <i className="fa fa-times" aria-hidden="true"></i>
                      }
                      cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                      attributes={{
                        name: "ivr_menu_exit_action",
                        className: "form-control p-0",
                        style: { height: "35px" },
                      }}
                      //viewAttributes={{className: 'fs-20'}}

                      placeholder="None"
                      value={ivrData.ivr_menu_exit_action}
                    />

                    {/* <EasyEdit
                                            type={Types.TEXT}
                                            onSave={value => {
                                                save(value,'ivr_menu_exit_action')
                                            }}
                                            //onCancel={cancel}
                                            saveButtonLabel={<i className="fa fa-check" aria-hidden="true"></i>}
                                            saveButtonStyle = 'label-primary easy-edit-button rounded-lg'
                                            cancelButtonLabel={<i className="fa fa-times" aria-hidden="true"></i>}
                                            cancelButtonStyle= 'label-danger easy-edit-button rounded-lg'
                                            attributes={{ name: 'ivr_menu_exit_action', className : 'form-control h-75'}}
                                            //viewAttributes={{className: 'fs-20'}}
                                        
                                            placeholder="Exit Action"
                                            value={ivrData.ivr_menu_exit_action}
                                        /> */}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="w-100">
                      Caller ID Name Prefix
                      <Tooltip
                        placement="top"
                        overlay={<span>Caller ID Name Prefix</span>}
                      >
                        <span className="ti-info-alt  ml-1"></span>
                      </Tooltip>
                    </label>

                    <EasyEdit
                      type={Types.TEXT}
                      onSave={(value) => {
                        save(value, "ivr_menu_cid_prefix");
                      }}
                      //onCancel={cancel}
                      saveButtonLabel={
                        <i className="fa fa-check" aria-hidden="true"></i>
                      }
                      saveButtonStyle="label-primary easy-edit-button rounded-lg"
                      cancelButtonLabel={
                        <i className="fa fa-times" aria-hidden="true"></i>
                      }
                      cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                      attributes={{
                        name: "ivr_menu_cid_prefix",
                        className: "form-control h-75",
                      }}
                      //viewAttributes={{className: 'fs-20'}}

                      placeholder="Caller ID Name Prefix"
                      value={ivrData.ivr_menu_cid_prefix}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-4 col-4">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-sm">
                  <div className="form-group">
                    <label className="w-100">
                      Max Attempts
                      <Tooltip
                        placement="top"
                        overlay={<span>Max Attempts</span>}
                      >
                        <span className="ti-info-alt ml-1"></span>
                      </Tooltip>
                    </label>

                    <EasyEdit
                      type={Types.SELECT}
                      onSave={(value) => {
                        save(value, "ivr_menu_max_attempt");
                      }}
                      //onCancel={cancel}
                      options={number_array}
                      saveButtonLabel={
                        <i className="fa fa-check" aria-hidden="true"></i>
                      }
                      saveButtonStyle="label-primary easy-edit-button rounded-lg"
                      cancelButtonLabel={
                        <i className="fa fa-times" aria-hidden="true"></i>
                      }
                      cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                      attributes={{
                        name: "ivr_menu_max_attempt",
                        className: "form-control p-0",
                        style: { height: "35px" },
                      }}
                      placeholder="Max Attempts"
                      //viewAttributes={{ className:'pull-left' }}
                      value={ivrData.ivr_menu_max_attempt}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm">
                  <div className="form-group">
                    <label className="w-100">
                      Invalid Destination
                      <Tooltip
                        placement="top"
                        overlay={<span>Invalid Destination</span>}
                      >
                        <span className="ti-info-alt ml-1"></span>
                      </Tooltip>
                    </label>

                    <EasyEdit
                      type={Types.SELECT}
                      onSave={(value) => {
                        save(value, "ivr_menu_invalid_destination");
                      }}
                      //onCancel={cancel}
                      editComponent={
                        <CustomEdit name="ivr_menu_invalid_destination" />
                      }
                      displayComponent={
                        <CustomDisplay name="ivr_menu_invalid_destination" />
                      }
                      saveButtonLabel={
                        <i className="fa fa-check" aria-hidden="true"></i>
                      }
                      saveButtonStyle="label-primary easy-edit-button rounded-lg"
                      cancelButtonLabel={
                        <i className="fa fa-times" aria-hidden="true"></i>
                      }
                      cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                      attributes={{
                        name: "ivr_menu_invalid_destination",
                        className: "form-control p-0",
                        style: { height: "35px" },
                      }}
                      //viewAttributes={{className: 'fs-20'}}

                      placeholder="None"
                      value={ivrData.ivr_menu_invalid_destination}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="col-lg-6 col-md-6 col-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group">
                                <label className="w-100">Short Geeting
                                    <Tooltip
                                        placement="top"
                                        overlay={<span>Short Geeting</span>}
                                    >
                                        <span className="ti-info-alt ml-1"></span>
                                    
                                    </Tooltip>
                                </label> 
                                <EasyEdit
                                    type={Types.SELECT}
                                    onSave={value => {
                                        save(value,'ivr_menu_greet_short')
                                    }}
                                    //onCancel={cancel}
                                    options={recordings}
                                    saveButtonLabel={<i className="fa fa-check" aria-hidden="true"></i>}
                                    saveButtonStyle = 'label-primary easy-edit-button rounded-lg'
                                    cancelButtonLabel={<i className="fa fa-times" aria-hidden="true"></i>}
                                    cancelButtonStyle= 'label-danger easy-edit-button rounded-lg'
                                    attributes={{ name: 'ivr_menu_greet_short', className : 'form-control p-0', style:{ height: '35px'}}}
                                    //viewAttributes={{className: 'fs-20'}}
                                   
                                    placeholder="Short Geeting"
                                    value={ivrData.ivr_menu_greet_short}
                                />

                            </div>
                            
                        </div>
                    </div>
                </div> */}
      </div>
    </Fragment>
  );
}

export default GeneralFields;
