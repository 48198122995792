import React from "react";


function DependentFieldForm(props) {

        let dependent_field = props.dependent_field;

        var options = dependent_field.field_options.split(',');
        let dependent_field_value = '';

        if (props.dependent_field_data[dependent_field.contact_custom_dependent_field_uuid]) {
            dependent_field_value = props.dependent_field_data[dependent_field.contact_custom_dependent_field_uuid].value;
        }
        
        return (

            <div className="form-group input-primary" >
                <label className="mb-1">
                <strong>{dependent_field.filed_name}</strong>
                </label>

                {dependent_field.field_type === 'TEXT' && 
                 <input type="text" value={dependent_field_value} className="form-control bg-white" name={dependent_field.contact_custom_dependent_field_uuid} onChange={(e) => props.handleDependentFieldData(dependent_field,e.target.value)} />
                }

                {dependent_field.field_type === 'CHECKBOX' && 

                    <span>
                        {options.map((option) => {
                            return ( <div className="form-check mb-2" key={option}>
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={dependent_field_value.includes(option)}
                                        dependent_field_value={option}
                                        name={dependent_field.contact_custom_dependent_field_uuid}
                                        onChange={(e) => props.handleDependentFieldData(dependent_field,e.target.value)}
                                    />
                                    <label className="form-check-label">{option}</label>
                                </div>
                            );
                        })}
                    </span>
                }

                {dependent_field.field_type === 'DATE' && 
                    <input type="date" value={dependent_field_value} className="form-control bg-white" name={dependent_field.contact_custom_dependent_field_uuid} onChange={(e) => props.handleDependentFieldData(dependent_field,e.target.value)} />
                }

                {dependent_field.field_type === 'DROPDOWN' && 
                    <select className="form-control bg-white" value={dependent_field_value} name={dependent_field.contact_custom_dependent_field_uuid} onChange={(e) => props.handleDependentFieldData(dependent_field,e.target.value)}> ;
                        <option value=''>Select</option>;
    
                        {options.map((option) => {
                            return (<option key={option} value={option}>{option}</option>);
                        })}
                    </select>
                }

                {dependent_field.field_type === 'TEXTAREA' && 
                    <textarea className="form-control bg-white" value={dependent_field_value} name={dependent_field.contact_custom_dependent_field_uuid} onChange={(e) => props.handleDependentFieldData(dependent_field,e.target.value)}></textarea>
                }
    
                {dependent_field.field_type === 'RADIO' &&
                    <div className="form-group mb-0">
                        {options.map((option) => {

                            return (
                                <label className="radio-inline mr-3" key={option}>
                                    <input type="radio" name={dependent_field.contact_custom_dependent_field_uuid} value={option} checked={dependent_field_value.includes(option)} 
                                        onChange={(e) => props.handleDependentFieldData(dependent_field,e.target.value)}
                                    /> {option}
                                </label>
                            );
                        })}
                    </div>
                    
                }
                
            </div>
        );
   
}

export default DependentFieldForm;