import { SET_MESSAGE, API_FAIL } from "./types";

import UseravailabilityService from "../services/useravailability.service";

export const getAvailabilityDetails =
  (user_uuid, createdby, token) => (dispatch) => {
    return UseravailabilityService.getAvailabilityDetails(
      user_uuid,
      createdby,
      token
    ).then(
      (data) => {
        if (
          data.status === 406 ||
          data.status === 500 ||
          data.status === 200
        ) {
          return data;
        } else {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const getAvailabilityTimeDetails =
  (user_availability_data_uuid, createdby, token) => (dispatch) => {
    return UseravailabilityService.getAvailabilityTimeDetails(
      user_availability_data_uuid,
      createdby,
      token
    ).then(
      (data) => {
        if (
          data.status === 406 ||
          data.status === 500 ||
          data.status === 200
        ) {
          return data;
        } else {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const updateTimeSlot = (userData) => (dispatch) => {
  return UseravailabilityService.updateTimeSlot(userData).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const deleteTimeSlot =
  (user_availability_times_uuid, createdby, token) => (dispatch) => {
    return UseravailabilityService.deleteTimeSlot(
      user_availability_times_uuid,
      createdby,
      token
    ).then(
      (data) => {
        if (
          data.status === 406 ||
          data.status === 500 ||
          data.status === 200
        ) {
          return data;
        } else {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const createTimeSlot = (userData) => (dispatch) => {
  return UseravailabilityService.createTimeSlot(userData).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const copyTimeSlot =
  (type, select_day_uuid, createdby, main_admin_uuid, token) => (dispatch) => {
    return UseravailabilityService.copyTimeSlot(
      type,
      select_day_uuid,
      createdby,
      main_admin_uuid,
      token
    ).then(
      (data) => {
        if (
          data.status === 406 ||
          data.status === 500 ||
          data.status === 200
        ) {
          return data;
        } else {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };
