import React, { Fragment, useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import { createEmailTemplate, getEmailTemplateImages } from "../../actions/emailtemplate";
import PageTitle from "../../layouts/PageTitle";
import Loader from "../../layouts/Loader";
import "grapesjs/dist/css/grapes.min.css";
import grapesjs from "grapesjs";
import grapesJSMJML from "grapesjs-mjml";
import VariableForm from "./VariableForm";
import grapesJSCKeditor from "grapesjs-plugin-ckeditor";

const TEMPLATE_IMAGE_URL = process.env.REACT_APP_SERVER_EMAIL_TEMPLATE_IMAGE_URL;
const API_URL = process.env.REACT_APP_SERVER_URL;

function CreateEmailTempForm(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const [emailTemplateData, setEmailTemplateData] = useState({
    template_name: "",
    subject: "",
    body: "",
    html_body : "",
    description: "",
    main_admin_uuid: main_uuid,
    createdby: createdby,
    user_uuid: createdby,
  });

  const [variableModal, setVariableModal] = useState(false);
  const [body, setBody] = useState("");
  const [htmlBody, setHtmlBody] = useState("");
  const [images, setImages] = useState({});

  const [errors, setErrors] = useState({
    template_name: "",
    message: "",
  });

  const closeModel = (value) => {
    setVariableModal(false);
  };

  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBlur = (e) => {
    const newGroupData = { ...emailTemplateData };
    let error_data = { ...errors };
    if (e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }
    newGroupData[e.target.name] = e.target.value;

    setEmailTemplateData(newGroupData);
  };

  useEffect(() => {
      dispatch(
          getEmailTemplateImages(createdby,token)
      )
        .then((response) => {
          let image_arr = response.data.map((image) => {
              return {
                  src: TEMPLATE_IMAGE_URL+image.image_name,
                  name: image.image_name
              }
          });
          setImages(image_arr);
          return;
        })
        .catch(() => {
          setLoading(false);
        });
  }, []);

  useEffect(() => {
    var editor = grapesjs.init({
      fromElement: true,
      container: "#grapesjs-container",
      height: "500px",
      width: "auto",
      storageManager: false,
      avoidInlineStyle: false,
      plugins: [grapesJSMJML,grapesJSCKeditor],
      pluginsOpts: {
        [grapesJSMJML]: {},
        'grapesjs-plugin-ckeditor': {}
      },
      assetManager: {
        assets: images,
        upload: API_URL + "upload-template-image",
        uploadName: 'files',
      }
    });

    editor.on("load", (editor) => {
     
    });

    editor.on("update", () => {
      setBody(editor.getHtml());
      setHtmlBody(editor.Canvas.getBody().outerHTML);
      
    });


    editor.addComponents(`
      <mjml>
      <mj-body>
      </mj-body>
    </mjml>
     `);

    return () => {
      editor.destroy();
    };
  }, [images]);

   

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";


    setLoading(true);

    if (emailTemplateData.template_name.length === 0) {
      error_data["template_name"] = "Template name is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (emailTemplateData.subject.length === 0) {
      error_data["subject"] = "Subject Type is required.";
      setErrors(error_data);
      isError = "true";
    } else {
      error_data["subject"] = "";
    }

    if (body.length === 0) {
      error_data["body"] = "Body is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create user API

      const newGroupData1 = { ...emailTemplateData };
      newGroupData1["body"] = body;
      newGroupData1["html_body"] = htmlBody;

      dispatch(createEmailTemplate(newGroupData1, token))
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            notifyInfo(res.message);
            
            navigate("/email-template", { replace: true });
            
          } else if (res.status === 406) {
            error_data["name"] = res.message;
            setErrors(error_data);
            isError = "true";
            
          } else if (res.status === 500) {
            notifyError("Something went wrong.");
            
          } else {
            notifyError("Something went wrong.");
            
          }
          setLoading(false);
          return true;
        })
        .catch(() => {
          setLoading(false);
          return true;
        });
    }
  };

  const breadCurmList = [
    { label: "Email", link: "/", is_active: "NO" },
    { label: "Email Template", link: "/email-template", is_active: "NO" },
    { label: "Create Email Template", link: "", is_active: "YES" },
  ];

  return (
    <Fragment>
      <>
        {loading && <Loader />}
        <PageTitle breadCurmList={breadCurmList} />
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Create Email Template</h4>
                <Button
                  type="button"
                  className="btn btn-default btn-sm"
                  onClick={() => navigate("/email-template", { replace: true })}
                >
                  <i className="fa fa-reply" aria-hidden="true"></i> Back
                </Button>
              </div>
              <div className="card-body">
                <form action="" onSubmit={(e) => submitHandler(e)}>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group input-primary">
                        <label className="mb-1">
                          <strong>Template Name</strong>
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control bg-white"
                          placeholder="Template Name"
                          name="template_name"
                          id="template_name"
                          onChange={handleBlur}
                        />
                        <span className="text-danger">
                          {errors.template_name}
                        </span>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-group  input-primary">
                        <label className="mb-1">
                          <strong>Subject</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control  bg-white"
                          placeholder="Subject"
                          name="subject"
                          id="subject"
                          onChange={handleBlur}
                        />
                        <span className="text-danger">
                          {errors.assign_number}
                        </span>
                      </div>
                    </div>
                    <div className="col-10"></div>
                    <div className="col-2">
                      <div className="form-group">
                        <label className="mb-1">
                          <Button
                            type="button"
                            className="btn btn-sm btn-primary mt-2"
                            onClick={() => {
                              setVariableModal(true);
                            }}
                          >
                            Insert Personalization
                          </Button>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-12 col-xxl-12">
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>Body</strong>
                          <span className="text-danger">*</span>
                        </label>
                        <div className="summernote">
                          
                          <div id="grapesjs-container"></div>
                        </div>
                        <span className="text-danger">{errors.body}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row text-right">
                    <div className="col-sm">
                      <Button
                        type="button"
                        onClick={submitHandler}
                        variant="primary"
                        className="btn-sm"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Modal className="fade" show={variableModal}>
          <VariableForm closeModel={closeModel} />
        </Modal>
      </>
    </Fragment>
  );
}

export default CreateEmailTempForm;
