
import React, { Fragment } from "react";
import "./style.css";
import Tooltip from "rc-tooltip";

function E911(props) {

    let userData = props.userData;    
    let E911Data = props.E911Data;    

    
    return (    
        <Fragment>
            <div className="row">
                <div className="col-lg-6 col-md-6 col-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm form-group">
                                    <label className="w-100">Caller ID Name
                                        <Tooltip
                                            placement="top"
                                            overlay={<span>Caller ID Name</span>}
                                        >
                                            <span className="ti-info-alt ml-1"></span>
                                        
                                        </Tooltip>
                                    </label> 
                                    <p>{E911Data.caller_id_name}</p>
                                                                      
                                </div>

                                <div className="col-sm form-group">
                                    <label className="w-100">Caller ID Number
                                        <Tooltip
                                            placement="top"
                                            overlay={<span>Caller ID Number</span>}
                                        >
                                            <span className="ti-info-alt ml-1"></span>
                                        
                                        </Tooltip>
                                    </label> 
                                    <p>{userData.caller_id_name}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm form-group">
                                    <label className="w-100">Address
                                        <Tooltip
                                            placement="top"
                                            overlay={<span>Address</span>}
                                        >
                                            <span className="ti-info-alt ml-1"></span>
                                        
                                        </Tooltip>
                                    </label> 
                                    <p>
                                        {E911Data.address_1},{E911Data.address_2},{E911Data.city},{E911Data.state},{E911Data.zip}
                                    </p>
                                                                      
                                </div>

                                
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            
    </Fragment> );

}

export default E911;