import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const getTimeConditions = (
  user_type,
  group_per,
  group_uuid,
  search,
  off_set,
  limits,
  orderby,
  main_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "get-time-condition-list",
      {
        user_type,
        group_per,
        group_uuid,
        search,
        off_set,
        limits,
        orderby,
        createdby,
        main_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const createTimecondition = (data, token) => {
  return axios
    .post(API_URL + "create-time-condition-name", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const updateTimeCondition = (data, token) => {
  return axios
    .post(API_URL + "update-time-condition", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getTimeConditionDetail = (createdby, token, time_condition_uuid) => {
  return axios
    .post(
      API_URL + "get-time-condition-details",
      {
        createdby,
        time_condition_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const deleteTimeCondition = (time_condition_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "delete-time-condition",
      {
        time_condition_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const deleteTimeSlot = (time_slot_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "delete-time-slot",
      {
        time_slot_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const deleteTimeSlotEvent = (time_slot_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "delete-time-slot-event",
      {
        time_slot_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const createTimeSlot = (data) => {
  return axios
    .post(API_URL + "create-time-slot", data, {
      headers: {
        Authorization: data.token,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const updateTimeSlot = (data) => {
  return axios
    .post(API_URL + "update-time-slot", data, {
      headers: {
        Authorization: data.token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const getTimeSlotDetail = (createdby, token, time_condition_data_uuid) => {
  return axios
    .post(
      API_URL + "get-time-slot-details",
      {
        createdby,
        time_condition_data_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getParticularTimeSlotDetail = (createdby, token, time_slot_uuid) => {
  return axios
    .post(
      API_URL + "get-particular-time-condition-details",
      {
        createdby,
        time_slot_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getTimeSlotDetailEvents = (createdby, token, time_condition_uuid) => {
  return axios
    .post(
      API_URL + "get-time-slot-details-events",
      {
        createdby,
        time_condition_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getTimeSlotParticularEvents = (
  createdby,
  token,
  time_condition_data_uuid
) => {
  return axios
    .post(
      API_URL + "get-time-slot-events-particular",
      {
        createdby,
        time_condition_data_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getBusinessHoursTimeDetails = (
  time_condition_data_uuid,
  createdby,
  token
) => {
  return axios
    .post(
      API_URL + "get-business-hours-time-details",
      {
        createdby,
        time_condition_data_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getNextTimeConditionID = (createdby, main_admin_uuid, token) => {
  return axios
    .post(
      API_URL + "get-next-time-condition-id",
      {
        createdby,
        main_admin_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateTimeSlotWeekly = (
  time_condition_time_uuid,
  field_name,
  value,
  createdby,
  main_admin_uuid,
  token
) => {
  return axios
    .post(
      API_URL + "update-time-slot-weekly",
      {
        time_condition_time_uuid,
        field_name,
        value,
        createdby,
        main_admin_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const copyTimeSlot = (
  type,
  select_day_uuid,
  createdby,
  main_admin_uuid,
  token
) => {
  return axios
    .post(
      API_URL + "time-base-routing/copy-time-slot",
      {
        type,
        select_day_uuid,
        createdby,
        main_admin_uuid,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getParticularDayTimeSlot = (createdby, token, select_day) => {
  return axios
    .post(
      API_URL + "get-particular-day-time-slot",
      {
        createdby,
        select_day,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

export default {
  getTimeConditions,
  createTimecondition,
  updateTimeCondition,
  getTimeConditionDetail,
  deleteTimeCondition,
  createTimeSlot,
  updateTimeSlot,
  deleteTimeSlot,
  deleteTimeSlotEvent,
  getTimeSlotDetail,
  getParticularTimeSlotDetail,
  getTimeSlotDetailEvents,
  getTimeSlotParticularEvents,
  getBusinessHoursTimeDetails,
  getNextTimeConditionID,
  updateTimeSlotWeekly,
  copyTimeSlot,
  getParticularDayTimeSlot,
};
