import React, { useState, useEffect } from "react";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
//import { Link } from "react-router-dom";
import { Tab, Nav } from 'react-bootstrap'

import { useDispatch } from "react-redux";

/// Components
import Notes from "./webphone/Notes";
import History from "./webphone/History";
import Info from "./webphone/Info";
import DialPad from "./webphone/DialPad";
import Chat from "./webphone/Chat";
import AutocompleteTextBox from "./webphone/AutocompleteTextBox";

import { getUserExtension } from "../actions/extensions";
//import Scripts from "./Scripts";
import "./index.css";


const WebPhone = ({ onClick, toggle, webphone }) => {
  const [toggleTab, settoggleTab] = useState(
    window.location.hash.slice(1) ? window.location.hash.slice(1) : "info"
  );

  const dataToggle = [
    { href: "#info", name: "Info" },
    { href: "#history", name: "History" },
    { href: "#notes", name: "Notes" },     
 ];

 

  const [extension, setExtension] = useState("");
  const [password, setPassword] = useState("");
  const [domainName, setDomainName] = useState("");
 
  const [loadScript, setLoadScript] = useState(false);
 
  const [contactDetail, setContactDetail] = useState({});
  const [isDialpadShow, setIsDialpadShow] = useState('NO');
  const [dialToNumber, setDialToNumber] = useState('');
  const [isExtensionListShow, setIsExtensionListShow] = useState('NO');

  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const useruuid = user_data.user_uuid;

  useEffect(() => {
    dispatch(getUserExtension(useruuid, createdby, token))
      .then((response) => {
        //setExtensionData(response.data[0]);
        setExtension(response.data[0].extension);
        setPassword(response.data[0].extension_password);
        setDomainName(response.data[0].domain_name);

        setLoadScript(true);
        return;
      })
      .catch(() => {
        
      });
  }, []);

  // function handleShowWebphone() {
  //   if (showWebphone) {
  //     setshowWebphone(false);
  //   } else {
  //     setshowWebphone(true);
  //   }
  // }

  function setSelectedContact(data) {
    setContactDetail(data);
  }

  const setdialpadStatus = (val) =>{
      if(val === 'dialpad'){
        setIsDialpadShow('YES');
      }else{
        setIsDialpadShow('NO');
      }
  } 

  const ChangeExtensionListView = (val) =>{
    
    setIsExtensionListShow(val);
  } 

  const setDialNumber = (val) => {
   
    setDialToNumber(val);
  }


  return (
    <div className={`sidebar NewTabswrap ${webphone ? "move-to-left" : ""}`} id="sidebar">
      {/* <div className="chatbox-close" onClick={() => onClick()}></div> */}
        {/* <div className="row mb-2"> */}
            {/* <div className="pr-1 col-sm-10 input-success-o input-group-lg"> */}
                {/* <input type="text" className="form-control input-rounded" name="search_contact" placeholder="Search" value="get-webphone-contact"/> */}
                <AutocompleteTextBox setSelectedContact={setSelectedContact}/>
            {/* </div> */}
            {/* <div className="pl-1 col-sm-2">
              <button type="button" className="btn btn-danger btn-sm" onClick={() => onClick()}><span>×</span></button>
            </div>
        </div> */}

              
        <div className='default-tab'>
          <Tab.Container defaultActiveKey="webphone">
            
            <Tab.Content className=''>
              
              <Tab.Pane eventKey="webphone" key="1">
                {/* <PerfectScrollbar
                    className={`card-body contacts_body p-0 dz-scroll  ${
                      toggle ? "ps ps--active-y" : ""
                    }`}
                    id="DZ_W_Contacts_Body2"
                    style={{height: '320px'}} 
                > */}
                  <div className="custom-tab-1 CallTabs">

                  {/* <video id="remoteView" height="240" autoPlay={true} _hidden="true"></video>
              <video id="selfView" height="240" autoPlay={true} muted={true} _hidden="true"></video> */}

                    
                    {/* <ul className="nav nav-tabs"> */}
                    <Nav as='ul' className='nav nav-tabs'>
                        {dataToggle.map((data, i) => (
                          <Nav.Item as='li' key={i}>
                            <Nav.Link eventKey={data.name.toLocaleLowerCase()} 
                              className={`nav-link ${
                                          toggleTab === data.name.toLocaleLowerCase()
                                            ? "active"
                                            : ""
                                      }`}
                              onClick={() =>
                                    settoggleTab(data.name.toLocaleLowerCase())
                                }
                              >
                              {data.name}
                            </Nav.Link>
                          </Nav.Item>

                        ))}
                    </Nav>

                    <div className="tab-content">
                        <Info
                            PerfectScrollbar={PerfectScrollbar}
                            toggle={toggle}
                            toggleTab={toggleTab}
                            contact={contactDetail}
                            isDialpadShow={isDialpadShow}
                            setDialNumber={setDialNumber}
                            isExtensionListShow={isExtensionListShow}
                        />
                        <History
                            PerfectScrollbar={PerfectScrollbar}
                            toggle={toggle}
                            toggleTab={toggleTab}
                            isDialpadShow={isDialpadShow}
                            contact={contactDetail}
                        />
                        <Notes
                            PerfectScrollbar={PerfectScrollbar}
                            toggle={toggle}
                            toggleTab={toggleTab}
                            isDialpadShow={isDialpadShow}
                            contact={contactDetail}
                        />
                    </div>
                  </div>
                      
                {/* </PerfectScrollbar> */}
                  
                  {/* <Draggable> */}
                    <DialPad
                      extension={extension}
                      password={password}
                      domainName={domainName}
                      loadScript={loadScript}
                      dialpadStatus={setdialpadStatus}
                      handalWebphoneClick={onClick}
                      setSelectedContact={setSelectedContact}
                      contactDetail={contactDetail}
                      isDialpadShow={isDialpadShow}
                      dialNumber={dialToNumber}
                      ChangeExtensionListView={ChangeExtensionListView}
                      isExtensionListShow={isExtensionListShow}
                    />
                  {/* </Draggable> */}
              </Tab.Pane>

              <Tab.Pane eventKey="sms" key="2">
                <Chat
                  PerfectScrollbar={PerfectScrollbar}
                  toggle={toggle}
                  contact={contactDetail}
                />
              </Tab.Pane>
             
            </Tab.Content>

            <Nav as='ul' className='nav nav-pills justify-content-end'>
              
                <Nav.Item as='li' key="1">
                  <Nav.Link eventKey="webphone">
                    <i className={`la la-tty mr-2`} />
                    CALL
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item as='li' key="2">
                  <Nav.Link eventKey="sms">
                    <i className={`la la-envelope mr-2`} />
                    SMS
                  </Nav.Link>
                </Nav.Item>
              
            </Nav>

          </Tab.Container>
        </div>

        
    </div>
  );
};

export default WebPhone;
