import React, { Fragment, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../../layouts/PageTitle";
import { importCsv } from "../../../actions/contacts";
import Loader from "../../../layouts/Loader";

function ImportContact(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;

  const [file, setFile] = useState();

  const [contactData, setContactData] = useState({
    recording_filename: "",
    // user: "",
    token: token,
    createdby: createdby,
    main_admin_uuid: createdby,
    user_uuid: createdby,
  });

  const [errors, setErrors] = useState({
    recording_name: "",
    file: "",
  });

  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBlur = (e) => {
    const newRecordingData = { ...contactData };
    let error_data = { ...errors };

    // const MIN_FILE_SIZE = 1024 // 1MB
    const MAX_FILE_SIZE = 10240; // 5MB

    if (e.target.name === "file") {
      if (
        e.target.files[0].type === "text/csv" || e.target.files[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || e.target.files[0].type === "application/vnd.ms-excel") {
        const fileSizeKiloBytes = e.target.files[0].size / 1024;
       
        if (fileSizeKiloBytes > MAX_FILE_SIZE) {
          // setErrorMsg("File size is greater than maximum limit");
          error_data[e.target.name] = "File size not allow greater then 10MB.";
          setErrors(error_data);
          return;
        }

        setFile(e.target.files[0]);
      } else {
        error_data[e.target.name] = e.target.name + " allow only waw and mp3.";
        setErrors(error_data);
        return;
      }
      // newRecordingData["file"] = e.target.files[0];
    }

    if (e.target.name === "user") {
      newRecordingData[e.target.name] = e.target.value;
    }

    

    setContactData(newRecordingData);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";
    setLoading(true);

    if (file === "undefined" || file === "") {
      error_data["file"] = "Recording file is required.";
      setErrors(error_data);
      isError = "true";
    }
    
    
    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create user API

      const formData = new FormData();
      formData.append("file", file);
      formData.append("token", contactData.token);
      formData.append("createdby", contactData.createdby);
      formData.append("main_admin_uuid", contactData.main_admin_uuid);
      formData.append("user_uuid", contactData.user_uuid);
      

      dispatch(importCsv(formData, contactData.token))
        .then((data) => {
          navigate("/mapping-fields",  {
            state: { filename: data.data.filename },
          });
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Fragment>
       {loading && <Loader />}
      <PageTitle activeMenu="Import Contact" motherMenu="Contact" />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Import Contact</h4>
              <Button
                type="button"
                className="btn btn-default btn-sm"
                onClick={() => navigate("/contacts", { replace: true })}
              >
                <i className="fa fa-reply" aria-hidden="true"></i> Back
              </Button>
            </div>
            <div className="card-body">
              <form action="" onSubmit={(e) => submitHandler(e)}>
                <div className="row">
                  {/* <div className="col-sm-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>User</strong>
                      </label>
                      <select
                        className="form-control"
                        name="user"
                        onChange={handleBlur}
                      >
                        <option selected value="disabled">
                          Select User
                        </option>
                        <option value="ivr">IVR</option>
                        <option value="moh">MoH</option>
                        <option value="vm_greeting">VM Greeting</option>
                        <option value="call_blast">Call Blast</option>
                        <option value="announcement">Announcement</option>
                      </select>
                      <span className="text-danger">
                        {errors.user}
                      </span>
                    </div>
                  </div> */}

                  <div className="col-sm">
                    {" "}
                    <div className="form-group">
                      <label
                        className="mb-1"
                        title="Allow only mp3, wav, m4a file and file size shoud be less then 10 MB."
                      >
                        <strong>File</strong>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        placeholder="Recording Password"
                        name="file"
                        onChange={handleBlur}
                      />
                      <span className="text-danger">{errors.file}</span>
                    </div>
                  </div>
                </div>

                <div className="row text-right">
                  <div className="col-sm">
                    <Button type="submit" variant="primary" className="btn-sm">
                      Upload
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ImportContact;
