import React, { Fragment, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  getTimeSlotDetail,
  deleteTimeSlot,
  getParticularTimeSlotDetail,
} from "../../actions/timeconditions";
import "./style.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import TimeSlotForm from "./TimeSlotForm";
import Loader from "../../layouts/Loader";

function TimeSlotListForm(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const time_condition_data_uuid = props.time_condition_data_uuid;
  const time_condition_uuid = props.time_condition_uuid;

  const [timeSlotList, setTimeSlotList] = useState([]);
  const [timeSlotData, setTimeSlotData] = useState([]);
  const [destinationTypeOp, setDestinationTypeOp] = useState([]);
  const [addTimeSlotModal, setAddTimeSlotModal] = useState(false);
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const closeModel = (value) => {
    setAddTimeSlotModal(value);
  };

  const handleTimeSlotEvent = (event) => {
    //setTimeSlotEvent(event);
    props.handleTimeSlotEvent(event);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getTimeSlotDetail(createdby, token, time_condition_data_uuid))
      .then((response) => {
        setTimeSlotList(response.data);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const deleteTimeSlotFunction = (time_slot_uuid) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this time slot?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteTimeSlotService(time_slot_uuid),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteTimeSlotService = (uuid) => {
    setLoading(true);
    dispatch(deleteTimeSlot(uuid, createdby, token))
      .then((response) => {
        props.handleTimeSlotEvent(uuid);
        navigate(`/update-time-condition`, {
          state: { time_condition_uuid: time_condition_uuid, replace: true },
        });
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const showUpdateForm = (useruuid) => {
    setLoading(true);
    dispatch(getParticularTimeSlotDetail(createdby, token, useruuid))
      .then((response) => {
        setTimeSlotData(response.data.time_slot_details);
        setDestinationTypeOp(response.data.destination_type_option);
        setAddTimeSlotModal(true);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <div className="d-flex">
        <table>
          <tbody>
            {timeSlotList &&
              timeSlotList.map((timeslot) => (
                <tr key={timeslot.id}>
                  <td>
                    {timeslot.from_time} to {timeslot.to_time}
                  </td>
                  <td>
                    {timeslot.route_type} - {timeslot.route_to_name}
                  </td>
                  <td>
                    {
                      // <Link
                      //   to="/update-time-condition"
                      //   state={{
                      //     time_condition_uuid: timeslot.id,
                      //   }}
                      //   className="btn btn-primary shadow btn-xs sharp mr-1"
                      // >
                      //   <i className="fa fa-pencil"></i>
                      // </Link>
                    }
                    <Button
                      to="#"
                      className="btn btn-primary shadow btn-xs sharp mr-1"
                      onClick={() => showUpdateForm(timeslot.id)}
                    >
                      <i className="fa fa-pencil"></i>
                    </Button>
                    <Link
                      to="#"
                      state={{ time_condition_uuid: time_condition_uuid }}
                      onClick={() => deleteTimeSlotFunction(timeslot.id)}
                      className="btn btn-danger shadow btn-xs sharp"
                    >
                      <i className="fa fa-trash"></i>
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Modal className="fade" show={addTimeSlotModal}>
        <TimeSlotForm
          timeSlot={timeSlotData}
          destinationTypeOption={destinationTypeOp}
          closeModel={closeModel}
          handleTimeSlotEvent={handleTimeSlotEvent}
        />
      </Modal>
    </Fragment>
  );
}

export default TimeSlotListForm;
