import { SET_MESSAGE, API_FAIL } from "../types";

import emailSignatureService from "../../services/emailSignature.service";

//emailSignature

export const getCompanySignatureList = (
  main_uuid,
  createdby,
  signature_type,
  token
) => (dispatch) => {
  return emailSignatureService.getCompanySignatureList(
    main_uuid,
    createdby,
    signature_type,
    token
  ).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


export const createEmailSignature = (
 data,
  token
) => (dispatch) => {
  return emailSignatureService.createEmailSignature(
    data,
    token
  ).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


export const updateEmailSignature = (
  data,
   token
 ) => (dispatch) => {
   return emailSignatureService.updateEmailSignature(
     data,
     token
   ).then(
     (data) => {
       if (
         data.status === 406 ||
         data.status === 500 ||
         data.status === 200
       ) {
         return data;
       } else {
         dispatch({
           type: API_FAIL,
         });
 
         dispatch({
           type: SET_MESSAGE,
           payload: data.message,
         });
 
         return Promise.reject();
       }
     },
     (error) => {
       const message =
         (error.response &&
           error.response.data &&
           error.response.data.message) ||
         error.message ||
         error.toString();
 
       dispatch({
         type: API_FAIL,
       });
 
       dispatch({
         type: SET_MESSAGE,
         payload: message,
       });
 
       return Promise.reject();
     }
   );
 };

 export const deleteEmailSignature = (
  data,
   token
 ) => (dispatch) => {
   return emailSignatureService.deleteEmailSignature(
     data,
     token
   ).then(
     (data) => {
       if (
         data.status === 406 ||
         data.status === 500 ||
         data.status === 200
       ) {
         return data;
       } else {
         dispatch({
           type: API_FAIL,
         });
 
         dispatch({
           type: SET_MESSAGE,
           payload: data.message,
         });
 
         return Promise.reject();
       }
     },
     (error) => {
       const message =
         (error.response &&
           error.response.data &&
           error.response.data.message) ||
         error.message ||
         error.toString();
 
       dispatch({
         type: API_FAIL,
       });
 
       dispatch({
         type: SET_MESSAGE,
         payload: message,
       });
 
       return Promise.reject();
     }
   );
 };


 export const assignSignatureToUser = (
  data,
   token
 ) => (dispatch) => {
   return emailSignatureService.assignSignatureToUser(
     data,
     token
   ).then(
     (data) => {
       if (
         data.status === 406 ||
         data.status === 500 ||
         data.status === 200
       ) {
         return data;
       } else {
         dispatch({
           type: API_FAIL,
         });
 
         dispatch({
           type: SET_MESSAGE,
           payload: data.message,
         });
 
         return Promise.reject();
       }
     },
     (error) => {
       const message =
         (error.response &&
           error.response.data &&
           error.response.data.message) ||
         error.message ||
         error.toString();
 
       dispatch({
         type: API_FAIL,
       });
 
       dispatch({
         type: SET_MESSAGE,
         payload: message,
       });
 
       return Promise.reject();
     }
   );
};

export const getUserEmailSignature = (
  createdby,
  user_uuid,
  token
) => (dispatch) => {
  return emailSignatureService.getUserEmailSignature(
    createdby,
    user_uuid,
    token
  ).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


export const getUserSignatureList = (
  createdby,
  user_uuid,
  token
) => (dispatch) => {
  return emailSignatureService.getUserSignatureList(
    createdby,
    user_uuid,
    token
  ).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const handleSignaturePermission = (
  data,
   token
 ) => (dispatch) => {
   return emailSignatureService.handleSignaturePermission(
     data,
     token
   ).then(
     (data) => {
       if (
         data.status === 406 ||
         data.status === 500 ||
         data.status === 200
       ) {
         return data;
       } else {
         dispatch({
           type: API_FAIL,
         });
 
         dispatch({
           type: SET_MESSAGE,
           payload: data.message,
         });
 
         return Promise.reject();
       }
     },
     (error) => {
       const message =
         (error.response &&
           error.response.data &&
           error.response.data.message) ||
         error.message ||
         error.toString();
 
       dispatch({
         type: API_FAIL,
       });
 
       dispatch({
         type: SET_MESSAGE,
         payload: message,
       });
 
       return Promise.reject();
     }
   );
};


