import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./index.css";
import { getStates, getAreaCode } from "../../../actions/number";
import { searchDid } from "../../../actions/did";
import Numbers from "./Numbers";
import Loader from "../../../layouts/Loader";

const LocalNumber = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  let number_type = "local_";

  const [stateData, setStateData] = useState([]);
  const [areaCodeData, setAreaCodeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [didAll, setDidAll] = useState([]);

  const [didData, setDidData] = useState({
    number_type: "",
    locality: "",
    area_code: "",
    limit: "21",
    prefix: "",
    state_uuid: "",
    state_name: "",
    createdby: createdby,
    main_admin_uuid: createdby,
    user_uuid: createdby,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(getStates(createdby, token))
      .then((response) => {
        if (response.status === 200) {
          searchAreaCode(response.data[0].uuid, response.data[0].state_name);
          setStateData(response.data);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const handleBlur = (e) => {
    const { options, selectedIndex } = e.target;
    const newDiddata = { ...didData };

    newDiddata["state_uuid"] = e.target.value;
    newDiddata["state_name"] = options[selectedIndex].innerHTML;
    newDiddata["set_arr_state"] = [e.target.value];

    setDidData(newDiddata);
    setLoading(true);
    dispatch(getAreaCode(createdby, token, e.target.value))
      .then((response) => {
        setAreaCodeData(response.data);
        setDidAll([]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const searchAreaCode = (state_uuid, state_name) => {
    setLoading(true);
    dispatch(getAreaCode(createdby, token, state_uuid))
      .then((response) => {
        if (response.status === 200) {
          searchDidFun(state_uuid, state_name, response.data[0].area_code);
          setAreaCodeData(response.data);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const searchDidFun = (state, state_name, area_code) => {
    const newDiddata = { ...didData };

    newDiddata["state_uuid"] = state;
    newDiddata["state_name"] = state_name;
    newDiddata["set_arr_state"] = [state];
    newDiddata["area_code"] = area_code;
    newDiddata["area_code_arr"] = [area_code];

    setDidData(newDiddata);
    setLoading(true);
    dispatch(searchDid(newDiddata, token))
      .then((response) => {
        setDidAll(response.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleBlur1 = (e) => {
    searchDidFun(didData.state_uuid, didData.state_name, e.target.value);
  };

  const handleSelectedNumberData = (dataFromFour) => {
    props.handleSelectedNumber(dataFromFour);
  };

  const handleUnSelectedNumberData = (dataFromFour) => {
    props.handleUnSelectedNumber(dataFromFour);
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <div className="col-lg-12">
        <div className="card bgl-primary">
          <div className="card-header">
            <h4 className="card-title">
              Select {props.type === "user_onboarding" ? "Number" : "Numbers"}
            </h4>
          </div>
          <div className="card-body card-body-new ">
            <div className="row">
              <div className="col-xl-2 col-xxl-2 col-lg-2">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-header pb-0 border-0">
                        <h3 className="fs-20 text-black">State</h3>
                      </div>
                      <div className="card-body">
                        <select
                          className="form-control prefix_sel"
                          name="state"
                          value={didData.set_arr_state}
                          onChange={handleBlur}
                          multiple
                        >
                          <option value="">Select State</option>
                          {stateData.map((state) => (
                            <option key={state.uuid} value={state.uuid}>
                              {state.state_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-xxl-2 col-lg-2">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-header pb-0 border-0">
                        <h3 className="fs-20 text-black">Area Code</h3>
                      </div>
                      <div className="card-body">
                        <select
                          className="form-control area_code_sel"
                          name="area_code"
                          value={didData.area_code_arr}
                          onChange={handleBlur1}
                          multiple
                        >
                          <option value="">Select Area Code</option>
                          {areaCodeData.map((areacode) => (
                            <option
                              key={areacode.area_code_uuid}
                              value={areacode.area_code}
                            >
                              {areacode.area_code}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-xxl-8 col-lg-8">
                <Numbers
                  didAll={didAll}
                  didsData={props.didsData}
                  didData={didData}
                  type={props.type}
                  number_type={number_type}
                  handleSelectedNumberData={handleSelectedNumberData}
                  handleUnSelectedNumberData={handleUnSelectedNumberData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LocalNumber;
