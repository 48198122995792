import React, { Fragment, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../layouts/PageTitle";
import Tooltip from "rc-tooltip";
import { createRecording } from "../../actions/recording";
import Loader from "../../layouts/Loader";

function RecordingForm() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const domain_name = user_data.domain_name;

  const location = useLocation();
  let defaultTab = location.state.defaultTab;

  let type = location.state.type;
  let did_uuid = location.state;
  let ivr_menu_uuid = location.state.ivr_menu_uuid;
  let extension_uuid = location.state.extension_uuid;
  let ring_group_uuid = location.state.ring_group_uuid;


  let default_tab_title = "";
  if (defaultTab === "ivr") {
    default_tab_title = "IVR";
  } else if (defaultTab === "moh") {
    default_tab_title = "MoH";
  } else if (defaultTab === "vm_greeting") {
    default_tab_title = "VM Greeting";
  } else if (defaultTab === "call_blast") {
    default_tab_title = "Voice Broad Cast";
  } else if (defaultTab === "announcement") {
    default_tab_title = "Announcement";
  }

  //const [users, setUsers] = useState([]);
  const [file, setFile] = useState();

  const [recordingData, setRecordingData] = useState({
    recording_name: "",
    recording_filename: "",
    recording_type: defaultTab,
    createdby: createdby,
    main_admin_uuid: main_uuid,
    user_uuid: createdby,
  });

  const [errors, setErrors] = useState({
    recording_name: "",
    file: "",
  });

  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBlur = (e) => {
    const newRecordingData = { ...recordingData };
    let error_data = { ...errors };

    if (
      (e.target.name === "recording_name" || e.target.name === "file") &&
      e.target.value.length === 0
    ) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }

    // const MIN_FILE_SIZE = 1024 // 1MB
    const MAX_FILE_SIZE = 10240; // 5MB

    if (e.target.name === "file") {
      //console.log(e.target.files);
      //return false;
      if (
        e.target.files[0].type === "audio/wav" ||
        e.target.files[0].type === "audio/mpeg" ||
        e.target.files[0].type === "audio/x-m4a"
      ) {
        const fileSizeKiloBytes = e.target.files[0].size / 1024;

        if (fileSizeKiloBytes > MAX_FILE_SIZE) {
          // setErrorMsg("File size is greater than maximum limit");
          error_data[e.target.name] = "File size not allow greater then 10MB.";
          setErrors(error_data);
          return;
        }

        if (defaultTab === 'moh') {
          setFile(e.target.files);
        }else{
          setFile(e.target.files[0]);
        }
        
      } else {
        error_data[e.target.name] = e.target.name + " allow only waw and mp3.";
        setErrors(error_data);
        return;
      }
      // newRecordingData["file"] = e.target.files[0];
    }

    if (e.target.name === "recording_name") {
      newRecordingData[e.target.name] = e.target.value;
    }

    if (e.target.name === "recording_type") {
      newRecordingData[e.target.name] = e.target.value;
    }

    setRecordingData(newRecordingData);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (recordingData.recording_name.length === 0) {
      error_data["recording_name"] = "Recording name is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (file === undefined) {
      error_data["file"] = "Recording file is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create user API

      const formData = new FormData();      
      
      formData.append("fileName", file.name);
      formData.append("domain_name", domain_name);
      formData.append("recording_name", recordingData.recording_name);
      formData.append("recording_type", recordingData.recording_type);
      formData.append("token", recordingData.token);
      formData.append("createdby", recordingData.createdby);
      formData.append("main_admin_uuid", recordingData.main_admin_uuid);
      formData.append("user_uuid", recordingData.user_uuid);
      if (recordingData.recording_type === 'moh') {
        for (let i = 0; i < file.length; i++) {
          formData.append('file', file[i]);
        }
      }else{
        formData.append("file", file);
      }

      dispatch(createRecording(formData, token))
        .then((res) => {
          if (res === undefined) {
            navigate("/recordings", {
              state: { defaultTab: defaultTab },
              replace: true,
            });
          }

          if (res.status !== 200) {
            error_data["recording_name"] = res.message;
            setErrors(error_data);
            isError = "true";
          } else {
            if (type === "upload_recording") {
              navigate("/recordings", {
                state: { defaultTab: defaultTab },
                replace: true,
              });
            } else if (type === "update_ivr") {
              navigate("/update-ivr", {
                state: { ivr_menu_uuid: ivr_menu_uuid, activeTab: "general" },
                replace: true,
              });
            } else if (type === "update_ext") {
              navigate("/update-extension", {
                state: { extension_uuid: extension_uuid, activeTab: "voicemail" },
                replace: true,
              });
            } else if (type === "update_ringgroup") {
              navigate("/view-ringgroup", {
                state: { ring_group_uuid: ring_group_uuid, activeTab: "advanced_options" },
                replace: true,
              });
            } else {
              navigate("/update-number", {
                state: { did_uuid: did_uuid },
                replace: true,
              });
            }
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };


  const navigate_back = () => {
    if (type === "upload_recording") {
      navigate("/recordings", {
        state: { defaultTab: defaultTab },
        replace: true,
      });
    } else if (type === "update_ivr") {
      navigate("/update-ivr", {
        state: { ivr_menu_uuid: ivr_menu_uuid, activeTab: "general" },
        replace: true,
      });
    } else if (type === "update_ext") {
      navigate("/update-extension", {
        state: { extension_uuid: extension_uuid, activeTab: "voicemail" },
        replace: true,
      });
    } else if (type === "update_ringgroup") {
      navigate("/view-ringgroup", {
        state: { ring_group_uuid: ring_group_uuid, activeTab: "advanced_options" },
        replace: true,
      });
    } else {
      navigate("/update-number", {
        state: { did_uuid: did_uuid },
        replace: true,
      });
    }
    return;
  }

  const breadCurmList = [
    { label: "Calls", link: "/", is_active: "NO" },
    { label: "Audio Files", link: "/recordings", state:{defaultTab:defaultTab}, is_active: "NO" },
    { label: "Upload New Recording", link: "", is_active: "YES" },
  ];

  return (
    <Fragment>
     {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                Upload New File to {default_tab_title}
              </h4>

              <Button
                  type="button"
                  className="btn btn-default btn-sm"
                  onClick={() =>
                    navigate_back()
                  }
                >
                  <i className="fa fa-reply" aria-hidden="true"></i> Back
                </Button>

              {/* {type == "upload_recording" ? (
                <Button
                  type="button"
                  className="btn btn-default btn-sm"
                  onClick={() =>
                    navigate("/recordings", {
                      state: { defaultTab: defaultTab },
                      replace: true,
                    })
                  }
                >
                  <i className="fa fa-reply" aria-hidden="true"></i> Back
                </Button>
              ) : (
                <Button
                  type="button"
                  className="btn btn-default btn-sm"
                  onClick={() =>
                    navigate("/update-number", {
                      state: { did_uuid: did_uuid },
                      replace: true,
                    })
                  }
                >
                  <i className="fa fa-reply" aria-hidden="true"></i> Back
                </Button>
              )} */}
            </div>
            <div className="card-body">
              <form action="" onSubmit={(e) => submitHandler(e)}>
                {/* <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Recording Type</strong>
                      </label>
                      <select
                        className="form-control"
                        name="recording_type"
                        onChange={handleBlur}
                        defaultValue={defaultTab}
                      >
                        <option value="disabled">
                          Select Recording Type
                        </option>
                        <option value="ivr">IVR</option>
                        <option value="moh">MoH</option>
                        <option value="vm_greeting">VM Greeting</option>
                        <option value="call_blast">Call Blast</option>
                        <option value="announcement">Announcement</option>
                      </select>
                      <span className="text-danger">
                        {errors.recording_type}
                      </span>
                    </div>
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-sm">
                    <div className="form-group input-primary">
                      <label className="mb-1">
                        <strong>Recording Name</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control bg-white"
                        //placeholder="Recording Name"
                        name="recording_name"
                        id="recording_name"
                        onChange={handleBlur}
                      />
                      <span className="text-danger">
                        {errors.recording_name}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm">
                    {" "}
                    <div className="form-group input-primary">
                      <label
                        className="mb-1"
                        //title="Allow only mp3, wav, m4a file and file size shoud be less then 10 MB."
                      >
                        <strong>Recording File</strong>
                        <Tooltip
                            placement="top"
                            overlay={<span>Allow only mp3, wav, m4a file and file size shoud be less then 10 MB.</span>}
                        >
                            <span className="ti-info-alt ml-1"></span>
                        
                        </Tooltip>
                        <span className="text-danger">*</span>
                      </label>
                      {(defaultTab === "moh") ?
                            <input
                            type="file"
                            className="form-control bg-white"
                            name="file"
                            onChange={handleBlur}
                            multiple
                          />
                        : 
                          <input
                            type="file"
                            className="form-control bg-white"
                            name="file"
                            onChange={handleBlur}
                          />
                      }
                      
                      <small>Allow only mp3, wav, m4a file and file size shoud be less then 10 MB.</small>
                      <span className="text-danger">{errors.file}</span>
                    </div>
                  </div>
                </div>

                <div className="row text-right">
                  <div className="col-sm">
                    <Button type="submit" variant="primary btn-sm">
                      Upload
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default RecordingForm;
