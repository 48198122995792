import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css";
import PageTitle from "../../layouts/PageTitle";
import {
  getPowerDailCampaignData,
} from "../../actions/callcampaign";
import CheckModulePermisson from "../../common/CheckModulePermisson";
import PermissionCheck from "../../common/PermissionCheck";
import Loader from "../../layouts/Loader";

function PowerDialScreen() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;
  const module_name = "roles permissions";

  let module_per = CheckModulePermisson(module_name);

  let isPermission = true;
  if (module_per === "" || user_type === "admin") {
    isPermission = true;
  } else {
    isPermission = false;
  }

  let listing_per = PermissionCheck(module_name, "listing", "", "", "");

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }

  const params = useLocation();
  const call_campaign_uuid = params.state.call_campaign_uuid;
  const call_campaign = params.state.call_campaign;

  const [powerDialEvent, setPowerDialEvent] = useState("");

  const [loading, setLoading] = useState(false);
  const [powerDailData, setPowerDailData] = useState([]);

  const dispatch = useDispatch();
  let navigate = useNavigate();

  let off_set = 0;
  let limits = 10;
  let orderby = "created_at ASC";

  if (module_per === "" || user_type === "admin") {
    useEffect(() => {
      setLoading(true);
      dispatch(
        getPowerDailCampaignData(
          main_uuid,
          user_type,
          group_per,
          group_uuid,
          call_campaign_uuid,
          off_set,
          limits,
          orderby,
          createdby,
          token
        )
      )
        .then((response) => {
          
          setPowerDailData(response.data);
          setPowerDialEvent("");
          setLoading(false);
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    }, [powerDialEvent]);
  }

  
  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle activeMenu="Power Dial Campaign" motherMenu="Call" />
      {isPermission ? (
        <>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Power Dial Campaign : {call_campaign}</h4>
                  <div>
                    <Button
                      type="button"
                      className="btn btn-default btn-sm mr-2"
                      onClick={() => navigate("/call-campaign", { replace: true })}
                    >
                      <i className="fa fa-reply" aria-hidden="true"></i> Back
                    </Button>

                    {/* <Button
                        type="button"
                        className="btn btn-sm btn-info"
                        onClick={() =>
                          navigate("/power-dial", { replace: true },{state:{
                            call_campaign_uuid:call_campaign_uuid,
                              call_campaign:call_campaign,
                          }})
                        }
                      >
                        Start PowerDial
                      </Button> */}

                      <Link
                        to="/power-dial"
                        state={{
                          call_campaign_uuid:call_campaign_uuid,
                            call_campaign:call_campaign,
                        }}
                        
                        className="btn btn-sm btn-info"
                        title="Start PowerDial"
                      >
                        Start PowerDial
                      </Link>

                  </div>

                </div>

                <div className="card-body">
                    <div className="row text-center">
                      <div className="col-4">
                        <div className="bgl-primary rounded p-3">
                          <h4 className="mb-0">0</h4>
                          <small>Live Calls</small>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="bgl-primary rounded p-3">
                          <h4 className="mb-0">{powerDailData.new}</h4>
                          <small>To Be Dialed</small>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="bgl-primary rounded p-3">
                          <h4 className="mb-0">{powerDailData.dailed}</h4>
                          <small>Dialed</small>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Power Dial Campaign : {call_campaign}</h4>
              </div>
              <div className="card-body">
                <h3>You have don't access this modules</h3>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default PowerDialScreen;
