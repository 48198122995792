import React, { Fragment, useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import TimeSlotFormEve from "./TimeSlotFormEve";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getTimeSlotDetailEvents,
  getTimeSlotParticularEvents,
  getParticularDayTimeSlot,
} from "../../actions/timeconditions";

import "./style.css";
import Loader from "../../layouts/Loader";

function EventCalendar(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;

  const params = useLocation();
  const time_condition_uuid = params.state.time_condition_uuid;

  const calendarRef = useRef();

  const [addTimeSlotModal, setAddTimeSlotModal] = useState(false);
  const [TimeSlotInEvent, setTimeSlotInEvent] = useState("");
  const [timeSlotId, setTimeSlotId] = useState({
    id: "",
    time_condition_uuid: "",
    time_condition_data_uuid: "",
    time_condition_date: "",
    from_time: "",
    to_time: "",
    route_to: "",
    route_type: "",
    schedule_type: "date",
    token: "",
    createdby: "",
    main_admin_uuid: "",
    user_uuid: "",
  });
  const [timeSlotOp, setTimeSlotOp] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [timeSlotEvents, setTimeSlotEvents] = useState([]);
  const [allTimeSlot, setAllTimeSlot] = useState([]);

  let calender_event = props.calendarEvent;

  useEffect(() => {
    setLoading(true);
    dispatch(getTimeSlotDetailEvents(createdby, token, time_condition_uuid))
      .then((response) => {
        setTimeSlotEvents(response.data);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, [TimeSlotInEvent]);

  useEffect(() => {
    const timer = setTimeout(() => {
      calendarRef.current.getApi().updateSize();
    }, 1000);
    return () => clearTimeout(timer);
  }, [calender_event]);

  const closeModel = (value) => {
    setAddTimeSlotModal(value);
  };

  const handleTimeSlotEvent = (event) => {
    setTimeSlotInEvent(event);
  };

  /**
   * when we click on event we are displaying event details
   */

  let data_eve = {};
  const eventClick = (eventClick) => {
    getDayTimeSlot(createdby, token, eventClick.event.id);
    dispatch(getTimeSlotParticularEvents(createdby, token, eventClick.event.id))
      .then((response) => {
        setTimeSlotOp(response.data.destination_type_option);
        let res_data = response.data.time_slot_details[0];
        data_eve["id"] = eventClick.event.id;
        data_eve["event_name"] = res_data.event_name;
        data_eve["from_time"] = res_data.from_time;
        data_eve["to_time"] = res_data.to_time;
        data_eve["time_condition_date"] = res_data.time_condition_date;
        data_eve["route_type"] = res_data.route_type;
        data_eve["route_to"] = res_data.route_to;

        setTimeSlotId(data_eve);
        setAddTimeSlotModal(true);

        return;
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleDateSelect = (selectInfo) => {
    getDayTimeSlot(selectInfo.startStr);
    setAddTimeSlotModal(true);
    let data_eve = { ...timeSlotId };
    data_eve["time_condition_date"] = selectInfo.startStr;
    data_eve["id"] = "";
    data_eve["event_name"] = "";
    data_eve["from_time"] = "";
    data_eve["to_time"] = "";
    data_eve["route_type"] = "";
    data_eve["route_to"] = "";

    setTimeSlotId(data_eve);
    setTimeSlotOp([]);
  };

  const today = new Date();

  const getDayTimeSlot = (select_day) => {
    dispatch(getParticularDayTimeSlot(createdby, token, select_day))
      .then((response) => {
        let arr = response.data.map((obj, i) => {
          return {
            id: obj.id,
            from_time: obj.start,
            to_time: obj.end,
          };
        });

        const filteredArray = arr.filter((element) => element);
        setAllTimeSlot(filteredArray);

        return;
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        initialView="dayGridMonth"
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={true}
        initialEvents={timeSlotEvents} // alternatively, use the `events` setting to fetch from a feed
        events={timeSlotEvents}
        select={handleDateSelect}
        //eventContent={renderEventContent} // custom render function
        eventClick={eventClick}
        //eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
        //  you can update a remote database when these fire:
        eventAdd={function () {}}
        eventChange={function () {}}
        eventRemove={function () {}}
        validRange={{
          start: today,
          end: null,
        }}
        ref={calendarRef}
      />

      <Modal className="fade" show={addTimeSlotModal}>
        <TimeSlotFormEve
          closeModel={() => closeModel()}
          id={timeSlotId}
          timeSlotOp={timeSlotOp}
          handleTimeSlotEvent={handleTimeSlotEvent}
          allTimeSlot={allTimeSlot}
        />
      </Modal>
    </Fragment>
  );
}

export default EventCalendar;
