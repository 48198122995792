import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Tooltip from "rc-tooltip";

const InboxMessage = ({ message, id, showMessage,activeEmail,checkMail,selectedMails,handleMarkStar }) => {
   
   const MessageRead = (e,data,uid,isRead) => {
      e.preventDefault();
      showMessage(data,uid,isRead);
   }

   const FnhandleMarkStar = (e,uid,isFevorite) => {
      e.preventDefault();

      let action = 'markfevorite';
      if(isFevorite){
         action = 'removefevorite'
      }
      handleMarkStar(uid,action);
   }

   const GetInitials = (data) => {

      var names = data.name.split(' ');

      let initials = names[0].substring(0, 1);
    
    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1);

    }else if(initials.length === 1){
         initials = data.name.substring(0, 2);
      }

      return initials.toUpperCase();
   }

   //console.log(message);

   return (
      <li
         className={`${
            activeEmail !== '' && activeEmail.active_email_id === message.uid
            ? "bgl-primary pl-2 pr-2 pt-2"
            : "pl-2 pr-2 pt-2" 
         }`}
      >
         <div className="timeline-panel media border-bottom">
            <div className="custom-control custom-checkbox mr-2 mt-2">
               <input
                  type="checkbox"
                  className="custom-control-input"
                  id={`checkbox0${id}`}
                  checked={selectedMails && selectedMails.selectd_mails.includes(
                     message.uid
                  )}
                  onChange={(e) =>
                     checkMail(e, message.uid)
                  }
               />
               <label
                  className="custom-control-label"
                  htmlFor={`checkbox0${id}`}
               ></label>
            </div>
               <br></br>
            <div className="">
               <Tooltip
                  placement="top"
                  overlay={message.isFevorite ? "Starred" : "Not Starred"}
               >
                  <button className="border-0 bg-transparent align-middle p-0" onClick={(e) => FnhandleMarkStar(e,message.uid,message.isFevorite)}>
                     
                     {message.isFevorite ? <i className="fa fa-star text-yellow" aria-hidden="true"></i> : <i className="fa fa-star-o" aria-hidden="true"></i>}
                  </button>
               </Tooltip>

               
            </div>
                      
            
            <button type="button" onClick={(e) => MessageRead(e,message.source,message.uid,message.isRead)} className="btn btn-warning light sharp mr-2" data-toggle="dropdown">
               <GetInitials name={message.envelope.from[0].name} />
            </button>
            
            <Link to="#" onClick={(e) => MessageRead(e,message.source,message.uid,message.isRead)} className="col-mail col-mail-2 row">
               <div className="media-body mb-2">
                  {/* <h5 className="mb-0">{message.envelope.from[0].address}</h5> */}
                  <h5 className={`${message.isRead ? "mb-0" : "mb-0 fw-bolder"}`}>
                     {message.envelope.from[0].name}
                     <small className={`${message.isRead ? "pull-right" : "pull-right fw-bolder"}`}>{moment(message.envelope.date).format("MM/DD/YYYY h:mm A")}</small>
                  </h5>
                  <small className={`${message.isRead ? "text-muted" : "fw-bolder"}`}>{message.envelope.subject}</small>
               </div>
            </Link>
            
         </div>
      </li>
      

      //       <Link to="#" onClick={() => showMessage(message.source)} className="col-mail col-mail-2 row">
      //          <div className="col-md-3">{moment(message.envelope.date).format("DD/MM/YYYY h:mm A")}</div>
      //          <div className="col-md-4">{message.envelope.from[0].address}</div>
      //          <div className="col-md-5">{message.envelope.subject}</div>
              
      //       </Link>
      //    </div>
      // </div>
   );
};

export default InboxMessage;
