import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Table,
  Button,
  ButtonGroup,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import PageTitle from "../../layouts/PageTitle";
import Pagination from "../../layouts/Pagination";
import {
  getCallCampaignList,
  deleteCallCampaign,
} from "../../actions/callcampaign";
import CheckModulePermisson from "../../common/CheckModulePermisson";
import PermissionCheck from "../../common/PermissionCheck";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import Loader from "../../layouts/Loader";

function CallCampaign() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;
  const module_name = "roles permissions";

  let module_per = CheckModulePermisson(module_name);

  let isPermission = true;
  if (module_per === "" || user_type === "admin") {
    isPermission = true;
  } else {
    isPermission = false;
  }

  let listing_per = PermissionCheck(module_name, "listing", "", "", "");
  let add_per = PermissionCheck(module_name, "add", "", "", "");

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }

  const [groupEvent, setGroupEvent] = useState("");

  const [limits, setLimits] = useState(10);
  const [loading, setLoading] = useState(false);
  const [callCampaigns, setCallCampaigns] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [offSet, setOffSet] = useState(0);

  const dispatch = useDispatch();
  let navigate = useNavigate();

  let search = "";
  //let off_set = 0;
  let status = "ACTIVE";
  //let limits = 10;
  let orderby = "created_at ASC";

  if (module_per === "" || user_type === "admin") {
    useEffect(() => {
      setLoading(true);
      dispatch(
        getCallCampaignList(
          main_uuid,
          user_type,
          group_per,
          group_uuid,
          search,
          offSet,
          status,
          limits,
          orderby,
          createdby,
          token
        )
      )
        .then((response) => {
          setCallCampaigns(response.data.data);
          const total = response.data.total;

          setpageCount(Math.ceil(total / limits));
          setGroupEvent("");
          setLoading(false);
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    }, [groupEvent, limits]);
  }

  const fnDeleteCallCamp = (uuid) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this Call Campaign?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteCallCampService(uuid),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteCallCampService = (call_campaign_uuid) => {
    setLoading(true);
    dispatch(deleteCallCampaign(call_campaign_uuid, createdby, token))
      .then((response) => {
        if (response.status === 200) {
          notifyInfo(response.message);
          setGroupEvent(call_campaign_uuid);
          return;
        } else if (response.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handlePageClick = async (data) => {
    setOffSet(limits * data.selected);
    setGroupEvent(Math.random);
  };

  const chnageLimit = (value) => {
    setLimits(value);
  };

  const breadCurmList = [
    { label: "Calls", link: "/", is_active: "NO" },
    {
      label: "Call Campaign : Total Amount " + callCampaigns.length,
      link: "",
      is_active: "YES",
    },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />
      {isPermission ? (
        <>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Manage Call Campaigns</h4>

                  {add_per === "yes" ? (
                    <Button
                      type="button"
                      className="btn btn-sm btn-default"
                      onClick={() =>
                        navigate("/create-call-campaign", { replace: true })
                      }
                    >
                      Add New Call Campaign
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="card-body">
                  Show
                  <ButtonGroup className="ml-2 mr-2">
                    <DropdownButton
                      as={ButtonGroup}
                      id="bg-nested-dropdown"
                      size="sm"
                      title={limits}
                      variant="custom-dropdown"
                    >
                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(10)}
                      >
                        10
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(25)}
                      >
                        25
                      </Dropdown.Item>

                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(50)}
                      >
                        50
                      </Dropdown.Item>

                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(100)}
                      >
                        100
                      </Dropdown.Item>
                    </DropdownButton>
                  </ButtonGroup>
                  entries
                  <Table responsive className="w-100">
                    <thead>
                      <tr role="row">
                        <th>Campaign Name</th>
                        <th>Campaigns Type</th>
                        {listing_per === "group" || user_type === "admin" ? (
                          <th>Created By</th>
                        ) : (
                          <></>
                        )}
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {callCampaigns.map((callCapm) => (
                        <tr key={callCapm.call_campaign_uuid}>
                          <td>{callCapm.campaign_name}</td>
                          <td>
                            {callCapm.campaign_type === "voice_broadcast"
                              ? "Voice Broadcast"
                              : "Power Dial"}
                          </td>
                          {listing_per === "group" ||
                          listing_per === "all" ||
                          user_type === "admin" ? (
                            <td>{callCapm.created_by_name}</td>
                          ) : (
                            <span></span>
                          )}
                          <td>
                            <Fragment>
                              <div className="d-flex">
                                {/* {
                                  callCapm.campaign_type === "power_dial"
                                  ? 
                                    <Link
                                      to="/power-dial"
                                      state={{
                                        call_campaign_uuid:
                                          callCapm.call_campaign_uuid,
                                          call_campaign:
                                          callCapm.campaign_name,
                                      }}
                                      hidden={PermissionCheck(
                                        module_name,
                                        "edit",
                                        callCapm.group_uuid,
                                        callCapm.user_created_by,
                                        callCapm.created_by
                                      )}
                                      className="btn btn-info shadow btn-xs sharp mr-1"
                                      title="Power Dial"
                                    >
                                      <i className="fa fa-paper-plane"></i>
                                    </Link>
                                  : ""
                                } */}
                                {callCapm.campaign_type === "power_dial" ? (
                                  <Link
                                    to="/power-dial-screen"
                                    state={{
                                      call_campaign_uuid:
                                        callCapm.call_campaign_uuid,
                                      call_campaign: callCapm.campaign_name,
                                    }}
                                    hidden={PermissionCheck(
                                      module_name,
                                      "edit",
                                      callCapm.group_uuid,
                                      callCapm.user_created_by,
                                      callCapm.created_by
                                    )}
                                    className="btn btn-info shadow btn-xs mr-1"
                                    title="Start Power Dial"
                                  >
                                    Start
                                  </Link>
                                ) : (
                                  ""
                                )}
                                {
                                  <Link
                                    to="/update-call-campaign"
                                    state={{
                                      call_camp_uuid:
                                        callCapm.call_campaign_uuid,
                                    }}
                                    hidden={PermissionCheck(
                                      module_name,
                                      "edit",
                                      callCapm.group_uuid,
                                      callCapm.user_created_by,
                                      callCapm.created_by
                                    )}
                                    className="btn btn-primary shadow btn-xs sharp mr-1"
                                    title="Edit"
                                  >
                                    <i className="fa fa-pencil"></i>
                                  </Link>
                                }

                                <Button
                                  to="#"
                                  className="btn btn-danger shadow btn-xs sharp"
                                  onClick={() =>
                                    fnDeleteCallCamp(
                                      callCapm.call_campaign_uuid
                                    )
                                  }
                                  hidden={PermissionCheck(
                                    module_name,
                                    "delete",
                                    callCapm.group_uuid,
                                    callCapm.user_created_by,
                                    callCapm.created_by
                                  )}
                                  title="Delete"
                                >
                                  <i className="fa fa-trash"></i>
                                </Button>
                              </div>
                            </Fragment>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Pagination
                    pageCount={pageCount}
                    handlePageNumberClick={handlePageClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Manage Call Campaigns</h4>
              </div>
              <div className="card-body">
                <h3>You have don't access this modules</h3>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default CallCampaign;
