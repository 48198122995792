import React, { Fragment, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { notifyError } from "../../Toastr/Toastr";
import { craeteUserMeetingEvent,updateUserMeetingEvent,getMeetingModes } from "../../../actions/calender/UserMeetingEvent";
import Loader from "../../../layouts/Loader";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { GoogleApiWrapper } from "google-maps-react";
const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;


function MeetingEventForm(props) {
  
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const updateData = props.updateData;
  const [loading, setLoading] = useState(false);

  const times = [];
  for (let i = 0; i < 24; i++) {
    times.push(
      `${i % 12 || 12}:00 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:15 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:30 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:45 ${i < 12 ? "AM" : "PM"}`
    );
  }

  const [EventData, setEventData] = useState({
    event_title: '',
    meeting_duration: '',
    meeting_modes : [],
    createdby : createdby,
    main_uuid : main_uuid,
    action : 'add',
  });

  const [meetingModes, setMeetingModes] = useState([]);
  const [address, setAddress] = useState('');

  const dispatch = useDispatch();

  const [errors, setErrors] = useState({
    event_title: "",
    meeting_duration: "",
    meeting_type : "",
    phone_call_type : '',
    call_number : '',
    online_meeting_type : '',
    meeting_url : '',
    google_address : ''
  });

  useEffect(() => {

    let data = {
      event_title: updateData.event_title,
      meeting_duration: updateData.meeting_duration,
      meeting_modes : [],
      action : updateData.action,
      createdby : createdby,
      main_uuid : main_uuid,
    };

    if(updateData.action === 'update'){
      data['user_meeting_event_uuid'] = updateData.user_meeting_event_uuid;

      dispatch(getMeetingModes(updateData.user_meeting_event_uuid))
        .then((res) => {
          if (res.status === 200) {
            
            let meeting_modes = {};
            let modes = [];
            
            res.data.forEach(function(row) {
              let meeting_type = row.meeting_type;
              meeting_modes[meeting_type] = {
                meeting_type : row.meeting_type,
                phone_call_type : row.phone_call_type,
                call_number : row.call_number,
                online_meeting_type : row.online_meeting_type,
                meeting_url : row.meeting_url,
                google_address : row.google_address,
              }

              if(meeting_type === 'in_person'){
                setAddress(row.google_address);
              }

              modes = [...modes, meeting_type];
            });

            data['meeting_modes'] = modes;

            setMeetingModes(meeting_modes);
          } 
        })
        .catch(() => {
          setLoading(false);
        });
    }

    setEventData(data);
  }, [updateData]);

  const close = (e) => {
    e.preventDefault();
    props.closeForm();
  }

  const handleBlur = (e) => {
    let error_data = { ...errors };

    let event_data = { ...EventData };

    if(e.target.value.length === 0){
        error_data[e.target.name] = 'Field is required.';
        setErrors(error_data);
    }else{
      error_data[e.target.name] = '';
      setErrors(error_data);
    }

    event_data[e.target.name] = e.target.value;
    setEventData(event_data);
    
  };

  const handleMeetingMode = (e,meeting_mode) => {

    if (e.target.checked) {
      let data = {
        index : Math.random,
        meeting_type : meeting_mode,
        phone_call_type : '',
        call_number : '',
        online_meeting_type : '',
        meeting_url : '',
        google_address : '',   
        address_latitude : '',
        address_logitude : '',
      }
      let meeting_data = { ...meetingModes };
      meeting_data[meeting_mode] = data;

      let event_data = { ...EventData };
      let selected_mode = event_data.meeting_modes;
      event_data['meeting_modes'] = [...selected_mode,meeting_mode];
      
      setEventData(event_data);
      setMeetingModes(meeting_data);

    }else{
      let meeting_data = {...meetingModes}
      if(meeting_mode === 'phone_call'){
        delete meeting_data.phone_call;
      }else if(meeting_mode === 'online_meeting'){
        delete meeting_data.online_meeting;
      } else if(meeting_mode === 'in_person'){
        delete meeting_data.in_person;
      }  

      let event_data = { ...EventData };
      event_data['meeting_modes'] = event_data.meeting_modes.filter(
        (arr) => arr !== meeting_mode
      );
      
      let error_data = { ...errors };
      if(event_data.meeting_modes.length === 0){
        error_data[e.target.name] = 'Field is required.';
        setErrors(error_data);
      }

      setEventData(event_data);
      setMeetingModes(meeting_data);
    }
  }

  const handleMeetingData = (e,meeting_mode) => {
    let error_data = { ...errors };

    if(e.target.value.length === 0){
      error_data[e.target.name] = 'Field is required.';
      setErrors(error_data);
    }else{
      error_data[e.target.name] = '';
      setErrors(error_data);
    }

    let meeting_data = {...meetingModes}
    meeting_data[meeting_mode][e.target.name] = e.target.value;
    setMeetingModes(meeting_data);
  } 

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (EventData.event_title.length === 0) {
      error_data["event_title"] = "Field is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (EventData.meeting_duration.length === 0) {
      error_data["meeting_duration"] = "Field is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (EventData.meeting_modes.length === 0) {
      error_data["meeting_type"] = "Field is required.";
      setErrors(error_data);
      isError = "true";
    }

    if(meetingModes.phone_call){
      if(meetingModes.phone_call.phone_call_type.length === 0){
        error_data['phone_call_type'] = 'Field is required.';
        setErrors(error_data);
        isError = "true";
      }

      if(meetingModes.phone_call.phone_call_type === 'call_to_calender_owner' && meetingModes.phone_call.call_number.length === 0){
        error_data['call_number'] = 'Field is required.';
        setErrors(error_data);
        isError = "true";
      }
    }

    if(meetingModes.online_meeting){
      if(meetingModes.online_meeting.online_meeting_type.length === 0){
        error_data['online_meeting'] = 'Field is required.';
        setErrors(error_data);
        isError = "true";
      }

      if(meetingModes.online_meeting.online_meeting_type === 'external' &&  meetingModes.online_meeting.meeting_url.length === 0){
        error_data['meeting_url'] = 'Field is required.';
        setErrors(error_data);
        isError = "true";
      }
    }

    if(meetingModes.in_person){
      if(meetingModes.in_person.google_address.length === 0){
        error_data['google_address'] = 'Field is required.';
        setErrors(error_data);
        isError = "true";
      }
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {

      let data = {...EventData}
      data['meeting_mode_data'] = meetingModes;
       
      if(EventData.action === 'update'){  

        dispatch(updateUserMeetingEvent(data,token))
        .then((res) => {
          if (res.status === 200) {
  
            props.handleEvent(Math.random);
          } else if (res.status === 500) {
            setLoading(false);
            notifyError("Something went wrong.");
          } else {
            setLoading(false);
            notifyError("Something went wrong.");
          }
        })
        .catch(() => {
          setLoading(false);
        });

      }else{
        dispatch(craeteUserMeetingEvent(data,token))
        .then((res) => {
          if (res.status === 200) {
  
            props.handleEvent(Math.random);
          } else if (res.status === 500) {
            setLoading(false);
            notifyError("Something went wrong.");
          } else {
            setLoading(false);
            notifyError("Something went wrong.");
          }
        })
        .catch(() => {
          setLoading(false);
        });
      }
      
    }
  };

  const handleAddressSelect = async (selectedAddress) => {
    try {
      
      let error_data = { ...errors };

      const newformdata = { ...meetingModes };
      

      geocodeByAddress(selectedAddress)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        meetingModes['in_person']['address_latitude'] = lat;
        meetingModes['in_person']['address_logitude'] = lng;
      });

      const results = await geocodeByAddress(selectedAddress);

      newformdata['in_person']['google_address'] = results[0].formatted_address;
      setAddress(results[0].formatted_address);

      if (newformdata.in_person.google_address.length === 0) {
        error_data["google_address"] = "Address is required.";
        setErrors(error_data);
      } else {
        error_data["google_address"] = "";
        setErrors(error_data);
      }
      
      setMeetingModes(newformdata);

    } catch (error) {
      console.error("Error selecting address:", error);
    }
  };



  return (
    <Fragment>
      {loading && <Loader />}
      <form action="" onSubmit={(e) => submitHandler(e)}>
        
        <div className="form-row mt-3"  > 

          <div className="col-md-2 mt-2 mt-sm-0">
            <div className="form-group input-primary">
              <label className="mb-1">
                <strong>Event Title</strong>
                <span className="text-danger">*</span>
              </label>

              <input
                type="text"
                className="form-control bg-white time_field_h"
                name="event_title"
                defaultValue={EventData.event_title}
                onChange={handleBlur}
              />

              <span className="text-danger">{errors.event_title}</span>
            </div>
          </div>
  
          <div className="col-md-2 mt-2 mt-sm-0">

            <div className="form-group input-primary">
              <label className="mb-1">
                <strong>Meeting Duration</strong>
                <span className="text-danger">*</span>
              </label>

              <select
                className="form-control bg-white time_field_h pb-0 pt-0"
                name="meeting_duration"
                onChange={handleBlur}
                value={EventData.meeting_duration}
              >
                <option value="30"> 30 Min </option>
                <option value="60"> 1 HR </option>
                <option value="120"> 2 HR </option>
              </select>

              <span className="text-danger">{errors.meeting_duration}</span>
            </div>                 
          </div>

          <div className="col-md-4 mt-2 mt-sm-0">
            <div className="form-group input-primary">
              <label className="mb-1">
                <strong>Meeting Type</strong>
                <span className="text-danger">*</span>
              </label>
              <div className="row">
                <div className="col-4">
                  <div className="custom-control custom-checkbox mb-3">
                    <input type="checkbox" className="custom-control-input" id="customCheckBox1" required="" 
                      checked={EventData.meeting_modes.includes('phone_call')}
                      onChange={(e) =>
                        handleMeetingMode(e,'phone_call')
                      } 
                    />
                    <label className="custom-control-label" htmlFor="customCheckBox1">Phone Call</label>
                  </div>
                </div>

                <div className="col-4">
                  <div className="custom-control custom-checkbox mb-3">
                    <input type="checkbox" className="custom-control-input" id="customCheckBox2" required="" 
                      checked={EventData.meeting_modes.includes('online_meeting')} 
                      onChange={(e) =>
                        handleMeetingMode(e,'online_meeting')
                      } 
                    />
                    <label className="custom-control-label" htmlFor="customCheckBox2">Online Meeting</label>
                  </div>
                </div>

                <div className="col-4">
                  <div className="custom-control custom-checkbox mb-3">
                    <input type="checkbox" className="custom-control-input" id="customCheckBox3" required="" 
                      checked={EventData.meeting_modes.includes('in_person')}  
                      onChange={(e) =>
                        handleMeetingMode(e,'in_person')
                      }   
                    />
                    <label className="custom-control-label" htmlFor="customCheckBox3">In Person</label>
                  </div>
                </div>
                
              </div>    
              
              <span className="text-danger">{errors.meeting_type}</span>
            </div> 
          </div>

        </div>

        {meetingModes.phone_call && 
          <div className="form-row" > 
            <div className="col-md-3 mt-2 mt-sm-0">
              <div className="form-group input-primary">
                <label className="mb-1">
                  <strong>Phone call Type</strong>
                  <span className="text-danger">*</span>
                </label>

                <select
                  className="form-control bg-white time_field_h pb-0 pt-0"
                  name="phone_call_type"
                  onChange={(e) => handleMeetingData(e,'phone_call')}
                  value={meetingModes.phone_call.phone_call_type}
                >
                  <option value="">Select</option>
                  <option value="call_to_prospect"> Call to Prospect </option>
                  <option value="call_to_calender_owner"> Call to calender Owner </option>
                </select>

                <span className="text-danger">{errors.phone_call_type}</span>
              </div>                 
            </div>

            { meetingModes.phone_call.phone_call_type === 'call_to_calender_owner' && 
              <div className="col-md-3 mt-2 mt-sm-0">

                <div className="form-group input-primary">
                  <label className="mb-1">
                    <strong>Phone Number</strong>
                    <span className="text-danger">*</span>
                  </label>

                  <input
                    type="text"
                    className="form-control bg-white time_field_h"
                    name="call_number"
                    defaultValue={meetingModes.phone_call.call_number}
                    onChange={(e) => handleMeetingData(e,'phone_call')}
                  />

                  <span className="text-danger">{errors.call_number}</span>
                </div>                 
              </div>
            }
          </div>
        }

        {meetingModes.online_meeting && 
          <div className="form-row" > 
            <div className="col-md-3 mt-2 mt-sm-0">

              <div className="form-group input-primary">
                <label className="mb-1">
                  <strong>Online Call Type</strong>
                  <span className="text-danger">*</span>
                </label>

                <select
                  className="form-control bg-white time_field_h pb-0 pt-0"
                  name="online_meeting_type"
                  onChange={(e) => handleMeetingData(e,'online_meeting')}
                  value={meetingModes.online_meeting.online_meeting_type}
                >
                  <option value="">Select</option>
                  <option value="internal"> Internal </option>
                  <option value="external"> External </option>
                </select>

                <span className="text-danger">{errors.online_meeting_type}</span>
              </div>                 
            </div>

            { meetingModes.online_meeting.online_meeting_type === 'external' && 
              <div className="col-md-3 mt-2 mt-sm-0">

                <div className="form-group input-primary">
                  <label className="mb-1">
                    <strong>Meeting URL</strong>
                    <span className="text-danger">*</span>
                  </label>

                  <input
                    type="text"
                    className="form-control bg-white time_field_h"
                    name="meeting_url"
                    defaultValue={meetingModes.online_meeting.meeting_url}
                    onChange={(e) => handleMeetingData(e,'online_meeting')}
                  />
                  <span className="text-danger">{errors.meeting_url}</span>
                </div>                 
              </div>
            }
          </div>
        }

        { meetingModes.in_person && 
          <div className="form-row" > 
            <div className="col-md-3 mt-2 mt-sm-0">

              <div className="form-group input-primary">
                <label className="mb-1">
                  <strong>Address</strong>
                  <span className="text-danger">*</span>
                </label>

                  <PlacesAutocomplete
                    value={address}
                    onChange={(value) => setAddress(value)}
                    onSelect={handleAddressSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          {...getInputProps({
                            placeholder: "Enter Address...",
                            className: "form-control bg-white",
                          })}
                          list="data"
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          <ul className="list-group cursor-pointer">
                            {suggestions.map((suggestion) => (
                              <li
                                className=""
                                {...getSuggestionItemProps(suggestion)}
                                key={suggestion.placeId}
                              >
                                {suggestion.description}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>

                <span className="text-danger">{errors.google_address}</span>
              </div>                 
            </div>
          </div>
        }
                        
        <Button type="submit" variant="primary btn-sm mr-2">
          Save
        </Button>

        <Button type="button" variant="danger btn-sm" onClick={(e) => close(e)}>
          close
        </Button>
      </form>
    </Fragment>
  );
}

//export default MeetingEventForm;

export default GoogleApiWrapper({
  apiKey: API_KEY,
})(MeetingEventForm);