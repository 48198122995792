import { SET_MESSAGE, API_FAIL,API_SUCCESS } from "./types";

import SequenceService from "../services/sequence.service";

export const getSequenceList =
  (
    user_uuid,
    main_uuid,
    search,
    off_set,
    status,
    limits,
    orderby,
    createdby,
    token
  ) =>
  (dispatch) => {
    return SequenceService.getSequenceList(
      user_uuid,
      main_uuid,
      search,
      off_set,
      status,
      limits,
      orderby,
      createdby,
      token
    ).then(
      (data) => {
        if (data.status === 406 || data.status === 500 || data.status === 200) {
          return data;
        } else {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const sequenceStatusChange =
  (sequence_uuid, status, createdby, token) => (dispatch) => {
    return SequenceService.sequenceStatusChange(
      sequence_uuid,
      status,
      createdby,
      token
    ).then(
      (data) => {
        if (data.status === 406 || data.status === 500 || data.status === 200) {
          return data;
        } else {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const sequenceDelete =
  (sequence_uuid, createdby, token) => (dispatch) => {
    return SequenceService.sequenceDelete(sequence_uuid, createdby, token).then(
      (data) => {
        if (data.status === 406 || data.status === 500 || data.status === 200) {
          return data;
        } else {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const getContactGroups =
  (main_uuid, createdby, token) => (dispatch) => {
    return SequenceService.getContactGroups(main_uuid, createdby, token).then(
      (data) => {
        if (data.status !== 200) {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }

        dispatch({
          type: API_SUCCESS,
          payload: { user: data },
        });

        return data;
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };
