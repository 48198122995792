import React, { Fragment, useState, useEffect } from "react";
import { Button, Table, Tab, Nav, Modal } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  updateCallCampaign,
  getContactGroupList,
  getCallCampaignDetail,
  getCallBlastAudioList,
  getAgentAndNumberList,
} from "../../actions/callcampaign";
import { getDispositionList } from "../../actions/comman";
import Select from "react-select";
import TimeSlotForm from "./TimeSlotForm";
import UpdateTimeSlotForm from "./UpdateTimeSlotForm";
import CreatableSelect from "react-select/creatable";
import PageTitle from "../../layouts/PageTitle";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import Loader from "../../layouts/Loader";

function UpdateCallCampaignForm() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const [updateData, setUpdateData] = useState([]);
  const [updateTimeSlotModal, setUpdateTimeSlotModal] = useState(false);

  const closeModel = (value) => {
    setAddTimeSlotModal(value);
    setUpdateTimeSlotModal(value);
  };

  const params = useLocation();
  const call_camp_uuid = params.state.call_camp_uuid;
  const [campaignType, setCampaignType] = useState("voice_broadcast");

  const [callCampData, setCallCampData] = useState({
    contact_group_uuid: "",
    operation_time: "",
    camp_data: {
      campaign_name: "",
      campaign_type: "",
      audio_file: "",
      wrap_up_time: "",
      is_recurring: "",
      contact_group_uuid: "",
      start_date: "",
      end_date: "",
      recurring_type: "",
      recurring_at: "",
      campaign_status: "",
      call_camp_from_number: "",
      timezone: "",
      from_number: "",
      operation_time: "",
    },
    call_camp_uuid: call_camp_uuid,
    main_uuid: main_uuid,
    token: token,
    createdby: createdby,
  });

  const [errors, setErrors] = useState({
    campaign_name: "",
    campaign_type: "",
    audio_file: "",
  });

  const [loading, setLoading] = useState(false);
  const [contactGroupList, setContactGroupList] = useState([]);
  const [rows, setRows] = useState([]);
  const [recuringCheck, setRecuringCheck] = useState("");
  const [addTimeSlotModal, setAddTimeSlotModal] = useState(false);
  const [daySel, setDaySel] = useState("monday");
  const [callCampGroupData, setCallCampGroupData] = useState([]);
  const [callCampOpData, setCallCampOpData] = useState([]);
  const [mondayData, setMondayData] = useState([]);
  const [tuesdayData, setTuesdayData] = useState([]);
  const [wednesdayData, setWednesdayData] = useState([]);
  const [thursdayData, setThursdayData] = useState([]);
  const [fridayData, setFridayData] = useState([]);
  const [saturdayData, setSaturdayData] = useState([]);
  const [sundayData, setSundayData] = useState([]);
  const [recuringVal, setRecuringVal] = useState("");
  const [operationUuid, setOperationUuid] = useState("");
  const [audioData, setAudioData] = useState([]);
  const [dispositionData, setDispositionData] = useState([]);
  const [dispositionVal, setDispositionVal] = useState([]);
  const [agentAndNumberData, setAgentAndNumberData] = useState([]);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const addDestinationHandler = (data) => {
    let monData = [];
    let tuesData = [];
    let wenData = [];
    let thursData = [];
    let friData = [];
    let satData = [];
    let sunData = [];

    const newTimeOpedata = [...callCampOpData, data];

    newTimeOpedata.forEach((emp, index) => {
      if (emp.day === "monday") {
        monData.push(emp);
      } else if (emp.day === "tuesday") {
        tuesData.push(emp);
      } else if (emp.day === "wednesday") {
        wenData.push(emp);
      } else if (emp.day === "thursday") {
        thursData.push(emp);
      } else if (emp.day === "friday") {
        friData.push(emp);
      } else if (emp.day === "saturday") {
        satData.push(emp);
      } else if (emp.day === "sunday") {
        sunData.push(emp);
      }
    });

    let newMon = { ...mondayData };
    newMon = monData;
    setMondayData(newMon);
    setTuesdayData(tuesData);
    setWednesdayData(wenData);
    setThursdayData(thursData);
    setFridayData(friData);
    setSaturdayData(satData);
    setSundayData(sunData);

    setCallCampOpData(newTimeOpedata);
    // const newCampaignData1 = { ...callCampData };
    // newCampaignData1["operation_time"] = newTimeOpedata;
    // setCallCampData(newCampaignData1);
  };

  const handleBlur = (e) => {
    const newGroupData = { ...callCampData };
    let error_data = { ...errors };
    if (e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }

    if (e.target.name === "recurring_type") {
      let rec_val = e.target.value;
      setRecuringVal(rec_val);
    }

    if (e.target.name === "recurring_at") {
      let rec_val1 = e.target.value;
      rec_val1 = "Every " + rec_val1 + " " + recuringVal;

      setRecuringVal(rec_val1);
    }

    if (e.target.name === "campaign_type") {
      newGroupData[e.target.name] = e.target.value;
      setCampaignType(e.target.value);
    }

    newGroupData["camp_data"][e.target.name] = e.target.value;
    setCallCampData(newGroupData);
  };

  let select_day = "";
  const handleBlur1 = React.useCallback(
    (e) => {
      select_day = e.currentTarget.id;
      setDaySel(select_day);
    },
    [""]
  );

  const UpdateDestinationHandler = (data) => {
    let select_day = data.day;
    let myArray = [];
    let myArray1 = [];

    if (select_day === "monday") {
      myArray = mondayData;
      const i = myArray.findIndex(
        (x) => x.operation_time_uuid === operationUuid
      );
      myArray[i] = data;
      setMondayData(myArray);
    }

    if (select_day === "tuesday") {
      myArray = tuesdayData;
      const i = myArray.findIndex(
        (x) => x.operation_time_uuid === operationUuid
      );
      myArray[i] = data;
      setTuesdayData(myArray);
    }

    if (select_day === "wednesday") {
      myArray = wednesdayData;
      const i = myArray.findIndex(
        (x) => x.operation_time_uuid === operationUuid
      );
      myArray[i] = data;
      setWednesdayData(myArray);
    }

    if (select_day === "thursday") {
      myArray = thursdayData;
      const i = myArray.findIndex(
        (x) => x.operation_time_uuid === operationUuid
      );
      myArray[i] = data;
      setThursdayData(myArray);
    }

    if (select_day === "friday") {
      myArray = fridayData;
      const i = myArray.findIndex(
        (x) => x.operation_time_uuid === operationUuid
      );
      myArray[i] = data;
      setFridayData(myArray);
    }

    if (select_day === "saturday") {
      myArray = saturdayData;
      const i = myArray.findIndex(
        (x) => x.operation_time_uuid === operationUuid
      );
      myArray[i] = data;
      setSaturdayData(myArray);
    }

    if (select_day === "sunday") {
      myArray = sundayData;
      const i = myArray.findIndex(
        (x) => x.operation_time_uuid === operationUuid
      );
      myArray[i] = data;
      setSundayData(myArray);
    }

    myArray1 = callCampOpData;
    const j = myArray1.findIndex(
      (y) => y.operation_time_uuid === operationUuid
    );
    myArray1[j] = data;

    setCallCampOpData(myArray1);
  };

  useEffect(() => {
    dispatch(getCallCampaignDetail(call_camp_uuid, createdby, token))
      .then((response) => {
        const newCampaignData1 = { ...callCampData };

        newCampaignData1["camp_data"] = response.data.campaign_data;
        newCampaignData1["contact_group_uuid"] =
          response.data.campaign_contact_group;
        newCampaignData1["disposition_data"] =
          response.data.campaign_disposition_time;
        newCampaignData1["operation_time"] =
          response.data.campaign_operation_time;
        setCampaignType(response.data.campaign_data.campaign_type);
        setCallCampData(newCampaignData1);
        setCallCampGroupData(response.data.campaign_contact_group);
        setCallCampOpData(response.data.campaign_operation_time);
        setDispositionVal(response.data.campaign_disposition_time);
        setRows(response.data.campaign_contact_group);
        let op_data = response.data.campaign_operation_time;

        let monData = [];
        let tuesData = [];
        let wenData = [];
        let thursData = [];
        let friData = [];
        let satData = [];
        let sunData = [];
        op_data.forEach((emp, index) => {
          if (emp.day === "monday") {
            monData.push(emp);
          } else if (emp.day === "tuesday") {
            tuesData.push(emp);
          } else if (emp.day === "wednesday") {
            wenData.push(emp);
          } else if (emp.day === "thursday") {
            thursData.push(emp);
          } else if (emp.day === "friday") {
            friData.push(emp);
          } else if (emp.day === "saturday") {
            satData.push(emp);
          } else if (emp.day === "sunday") {
            sunData.push(emp);
          }
        });

        setMondayData(monData);
        setTuesdayData(tuesData);
        setWednesdayData(wenData);
        setThursdayData(thursData);
        setFridayData(friData);
        setSaturdayData(satData);
        setSundayData(sunData);

        if (response.data.campaign_data.is_recurring === "yes") {
          
          setRecuringCheck("checked");
        } else {
          setRecuringCheck("");
        }
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    dispatch(getContactGroupList(main_uuid, createdby, token))
      .then((response) => {
        setContactGroupList(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);


  useEffect(() => {
    dispatch(getCallBlastAudioList(main_uuid, createdby, token))
      .then((response) => {
        setAudioData(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    dispatch(getDispositionList(createdby, main_uuid, createdby, token))
      .then((response) => {
        setDispositionData(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    dispatch(getAgentAndNumberList(main_uuid, createdby, token))
      .then((response) => {
        setAgentAndNumberData(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (callCampData.camp_data.campaign_name.length === 0) {
      error_data["campaign_name"] = "Campaign Name is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (callCampData.camp_data.call_camp_from_number.length === 0) {
      error_data["call_camp_from_number"] = "From number Type is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (callCampData.camp_data.campaign_type.length === 0) {
      error_data["campaign_type"] = "Campaign Type is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (callCampData.contact_group_uuid.length === 0) {
      error_data["contact_group_uuid"] = "Please select atleast one lead list.";
      setErrors(error_data);
      isError = "true";
    } else {
      error_data["contact_group_uuid"] = "";
    }

    if (callCampData.disposition_data.length === 0) {
      error_data["disposition_data"] = "Please select atleast one disposition.";
      setErrors(error_data);
      isError = "true";
    } else {
      error_data["disposition_data"] = "";
    }

    if (
      callCampData.camp_data.start_date.length === 0 &&
      campaignType === "voice_broadcast"
    ) {
      error_data["start_date"] = "Start Date Time is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (
      callCampData.camp_data.end_date.length === 0 &&
      campaignType === "voice_broadcast"
    ) {
      error_data["end_date"] = "End Date Time is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (
      callCampData.camp_data.is_recurring.length !== 0 &&
      callCampData.camp_data.is_recurring === "yes" &&
      callCampData.camp_data.recurring_type.length === 0 &&
      campaignType === "voice_broadcast"
    ) {
      error_data["recurring_type"] = "Please select recurring type.";
      setErrors(error_data);
      isError = "true";
    }

    if (
      callCampData.camp_data.is_recurring.length !== 0 &&
      callCampData.camp_data.is_recurring === "yes" &&
      callCampData.camp_data.recurring_at.length === 0 &&
      campaignType === "voice_broadcast"
    ) {
      error_data["recurring_at"] = "Please select recurring at.";
      setErrors(error_data);
      isError = "true";
    }

    if (callCampData.operation_time.length === 0) {
      error_data["operation_time"] = "Please atleast add one operation time.";
      setErrors(error_data);
      isError = "true";
    }

    // if (callCampData.timezone.length === 0) {
    //   error_data["timezone"] = "Please select timezone.";
    //   setErrors(error_data);
    //   isError = "true";
    // }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create call campaign API
      const data = { ...callCampData };
      data["contact_group_uuid"] = callCampGroupData;
      data["operation_time"] = callCampOpData;
      data["disposition_data"] = dispositionVal;
      dispatch(updateCallCampaign(data))
        .then((res) => {
          if (res.status === 200) {
            notifyInfo(res.message);
            navigate("/call-campaign", { replace: true });
          } else if (res.status === 406) {
            error_data["campaign_name"] = res.message;
            setErrors(error_data);
            isError = "true";
          } else if (res.status === 500) {
            notifyError("Something went wrong.");
          } else {
            notifyError("Something went wrong.");
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  function handleSelectCheck(data) {
    let rec_chk_val = data.target.value;
    let check_val = "";
    if (rec_chk_val !== "" && rec_chk_val === "checked") {
      setRecuringCheck("");
      check_val = "no";
    } else {
      setRecuringCheck("checked");
      check_val = "yes";
    }

    const newCampaignData = { ...callCampData };
    newCampaignData["camp_data"]["is_recurring"] = check_val;
    setCallCampData(newCampaignData);
  }

  function handleSelectLeadList(data) {
    const newCampaignData1 = { ...callCampData };
    newCampaignData1["contact_group_uuid"] = data;
    setCallCampData(newCampaignData1);
    setCallCampGroupData(data);
    setRows(data);
  }

  function tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }

  const handleDelete = (index, del_sel_day, e) => {
    setCallCampOpData([
      ...callCampOpData.filter((item) => item.operation_time_uuid !== index),
    ]);

    if (del_sel_day === "monday") {
      return setMondayData([
        ...mondayData.filter((item) => item.operation_time_uuid !== index),
      ]);
    } else if (del_sel_day === "tuesday") {
      return setTuesdayData([
        ...tuesdayData.filter((item) => item.operation_time_uuid !== index),
      ]);
    } else if (del_sel_day === "wednesday") {
      return setWednesdayData([
        ...wednesdayData.filter((item) => item.operation_time_uuid !== index),
      ]);
    } else if (del_sel_day === "thursday") {
      return setThursdayData([
        ...thursdayData.filter((item) => item.operation_time_uuid !== index),
      ]);
    } else if (del_sel_day === "friday") {
      return setFridayData([
        ...fridayData.filter((item) => item.operation_time_uuid !== index),
      ]);
    } else if (del_sel_day === "saturday") {
      return setSaturdayData([
        ...saturdayData.filter((item) => item.operation_time_uuid !== index),
      ]);
    } else if (del_sel_day === "sunday") {
      return setSundayData([
        ...sundayData.filter((item) => item.operation_time_uuid !== index),
      ]);
    }
  };

  const handleUpdate = (index, operation_uuid, e) => {
    setOperationUuid(operation_uuid);
    setUpdateData(index);
    setUpdateTimeSlotModal(index);
  };

  function handleSelectDisposition(data) {
    const newCampaignData1 = { ...callCampData };
    newCampaignData1["disposition_data"] = data;
    setCallCampData(newCampaignData1);
    setDispositionVal(data);
  }

  var seconds = [],
    i = 4,
    len = 30;
  while (++i <= len) seconds.push(i);

  var recurringTimes = [],
    j = 0,
    lenn = 12;
  while (++j <= lenn) recurringTimes.push(j);

  const breadCurmList = [
    { label: "Calls", link: "/", is_active: "NO" },
    {
      label: "Call Campaign",
      link: "/call-campaign",
      is_active: "NO",
    },
    {
      label: "Update Call Campaign " + callCampData.camp_data.campaign_name,
      link: "",
      is_active: "YES",
    },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
      
      <PageTitle breadCurmList={breadCurmList} />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                Update Call Campaign - {callCampData.camp_data.campaign_name}
              </h4>
              <Button
                type="button"
                className="btn btn-default btn-sm"
                onClick={() => navigate("/call-campaign", { replace: true })}
              >
                <i className="fa fa-reply" aria-hidden="true"></i> Back
              </Button>
            </div>
            <div className="card-body">
              <form action="" onSubmit={(e) => submitHandler(e)}>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Campaign Name</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Campaign Name"
                        name="campaign_name"
                        value={callCampData.camp_data.campaign_name}
                        onChange={handleBlur}
                      />
                      <span className="text-danger">
                        {errors.campaign_name}
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>From Number</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="call_camp_from_number"
                        value={callCampData.camp_data.call_camp_from_number}
                        onChange={handleBlur}
                      >
                        <option value="">Select From Number</option>
                        {agentAndNumberData.map((number) => (
                          <option key={number.uuid} value={number.uuid}>
                            {number.first_name !== null &&
                            number.last_name !== null
                              ? number.first_name + " " + number.last_name
                              : number.did_number}
                          </option>
                        ))}
                      </select>
                      <span className="text-danger">
                        {errors.call_camp_from_number}
                      </span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Campaign Type</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="campaign_type"
                        onChange={handleBlur}
                        value={callCampData.camp_data.campaign_type}
                      >
                        <option value="">Select Campaign Type</option>
                        <option value="voice_broadcast">Voice Broadcast</option>
                        <option value="power_dial">Power Dial</option>
                      </select>
                      <span className="text-danger">
                        {errors.campaign_type}
                      </span>
                    </div>
                  </div>
                  <div
                    className="col-4"
                    style={{
                      display:
                        campaignType === "voice_broadcast" ? "block" : "none",
                    }}
                  >
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Audio File</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="audio_file"
                        value={callCampData.camp_data.audio_file}
                        onChange={handleBlur}
                      >
                        <option value="">Select Audio File</option>
                        {audioData.map((file) => (
                          <option
                            key={file.recording_uuid}
                            value={file.recording_uuid}
                          >
                            {file.recording_name}
                          </option>
                        ))}
                      </select>
                      <span className="text-danger">{errors.audio_file}</span>
                    </div>
                  </div>
                  <div
                    className={
                      "col-" + (campaignType === "power_dial" ? "4" : "6")
                    }
                    style={{
                      display: campaignType === "power_dial" ? "block" : "none",
                    }}
                  >
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Wrap Up Time</strong>
                        <span className="text-danger">*</span>
                      </label>

                      <select
                        className="form-control"
                        name="wrap_up_time"
                        id="wrap_up_time"
                        onChange={handleBlur}
                        value={callCampData.camp_data.wrap_up_time}
                      >
                        <option value="">Select Wrap Up Time</option>
                        {seconds.map((i) => (
                          <option value={i}>{i}</option>
                        ))}
                      </select>

                      <span className="text-danger">{errors.wrap_up_time}</span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <div className="form-group mt-2">
                      <label className="mb-1">
                        <strong> Add Lead List</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <Select
                        className="form-control"
                        options={contactGroupList}
                        name="contact_group_uuid"
                        placeholder="Select List"
                        value={callCampGroupData}
                        onChange={handleSelectLeadList}
                        isSearchable={true}
                        isMulti
                      />

                      <span className="text-danger">
                        {errors.contact_group_uuid}
                      </span>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="card-body">
                      <label className="mb-1">
                        <strong> Lead List</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <Table responsive className="w-100">
                        <thead>
                          <tr role="row">
                            <td>Name</td>
                            <td>No. of contacts</td>
                          </tr>
                        </thead>
                        <tbody>
                          {rows.map((row, index) => (
                            <tr key={index}>
                              <td>{row.label}</td>
                              <td>{row.count}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <div className="form-group mt-2">
                      <label className="mb-1">
                        <strong> Add Disposition</strong>
                        <span className="text-danger">*</span>
                      </label>

                      <CreatableSelect
                        className="form-control"
                        name="disposition_data"
                        isMulti
                        onChange={handleSelectDisposition}
                        options={dispositionData}
                        value={dispositionVal}
                      />

                      <span className="text-danger">
                        {errors.disposition_data}
                      </span>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="card-body">
                      <label className="mb-1">
                        <strong> Disposition List</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <Table responsive className="w-100">
                        <thead>
                          <tr role="row">
                            <td>Name</td>
                            <td></td>
                          </tr>
                        </thead>
                        <tbody>
                          {dispositionVal.map((dis, index) => (
                            <tr key={index}>
                              <td>{dis.value}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display:
                      campaignType === "voice_broadcast" ? "block" : "none",
                  }}
                >
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="form-group col-xl-12 p-0">
                        <label className="text-black font-w500 mb-3">
                          Start Date
                        </label>
                        <span className="text-danger">*</span>
                        <input
                          type="date"
                          name="start_date"
                          value={callCampData.camp_data.start_date}
                          onChange={handleBlur}
                          className="form-control"
                        />
                        <span className="text-danger">{errors.start_date}</span>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="form-group col-xl-12 p-0">
                        <label className="text-black font-w500 mb-3">
                          End Date
                        </label>
                        <span className="text-danger">*</span>
                        <input
                          type="date"
                          name="end_date"
                          value={callCampData.camp_data.end_date}
                          onChange={handleBlur}
                          className="form-control"
                        />
                        <span className="text-danger">{errors.end_date}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-3">
                      <div className="form-group">
                        <label className="w-100 mb-1">
                          <strong>Recurring</strong>
                        </label>
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="is_recurring"
                            onChange={handleSelectCheck}
                            value={recuringCheck}
                            checked={recuringCheck}
                          />
                          <span className="slider round"> </span>
                        </label>
                      </div>
                    </div>
                    <div
                      className="col-xl-3"
                      style={{
                        display:
                          callCampData.camp_data.is_recurring === "yes"
                            ? "block"
                            : "none",
                      }}
                    >
                      <div className="form-group">
                        <label className="w-100 mb-1">
                          <strong></strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          readOnly
                          value="Every "
                        />
                      </div>
                    </div>
                    <div
                      className="col-xl-3"
                      style={{
                        display:
                          callCampData.camp_data.is_recurring === "yes"
                            ? "block"
                            : "none",
                      }}
                    >
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>Recurring At</strong>
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control"
                          name="recurring_at"
                          onChange={handleBlur}
                          value={callCampData.camp_data.recurring_at}
                        >
                          <option value="">Select Recurring At</option>
                          {recurringTimes.map((k) => (
                            <option value={k}>{k}</option>
                          ))}
                        </select>
                        <span className="text-danger">
                          {errors.recurring_at}
                        </span>
                      </div>
                    </div>
                    <div
                      className="col-xl-3"
                      style={{
                        display:
                          callCampData.camp_data.is_recurring === "yes"
                            ? "block"
                            : "none",
                      }}
                    >
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>Recurring Type</strong>
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control"
                          name="recurring_type"
                          onChange={handleBlur}
                          value={callCampData.camp_data.recurring_type}
                        >
                          <option value="">Select Recurring Type</option>
                          <option value="day">Day</option>
                          <option value="week">Week</option>
                          <option value="month">Month</option>
                          <option value="year">Year</option>
                        </select>
                        <span className="text-danger">
                          {errors.recurring_type}
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* <div className="row">
                  <div className="col-xl-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Time Zone</strong>
                      </label>
                      <select
                        className="form-control"
                        name="timezone"
                        onChange={handleBlur}
                        value={callCampData.camp_data.timezone}
                      >
                        <option value="">Select Time Zone</option>
                        {timeZoneData.map((time) => (
                          <option
                            key={time.time_zone_uuid}
                            value={time.time_zone_uuid}
                          >
                            {time.timezone_name}
                          </option>
                        ))}
                      </select>
                      <span className="text-danger">{errors.timezone}</span>
                    </div>
                  </div>
                  <div className="col-xl-6"></div>
                </div> */}
                </div>
                <div className="row">
                  <div className="card-body">
                    <label className="mb-1">
                      <strong> Campaign Operation Time</strong>
                      <span className="text-danger">*</span>
                    </label>
                  </div>

                  <div className="default-tab m-3 w-100">
                    <Tab.Container defaultActiveKey="monday">
                      <Nav as="ul" className="nav-tabs">
                        <Nav.Item as="li">
                          <Nav.Link
                            id="sunday"
                            eventKey="sunday"
                            onClick={handleBlur1}
                          >
                            <i className={`la la-clock mr-2`} />
                            Sun
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                          <Nav.Link
                            id="monday"
                            eventKey="monday"
                            onClick={handleBlur1}
                          >
                            <i className={`la la-clock mr-2`} />
                            Mon
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                          <Nav.Link
                            id="tuesday"
                            eventKey="tuesday"
                            onClick={handleBlur1}
                          >
                            <i className={`la la-clock mr-2`} />
                            Tue
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                          <Nav.Link
                            id="wednesday"
                            eventKey="wednesday"
                            onClick={handleBlur1}
                          >
                            <i className={`la la-clock mr-2`} />
                            Wed
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                          <Nav.Link
                            id="thursday"
                            eventKey="thursday"
                            onClick={handleBlur1}
                          >
                            <i className={`la la-clock mr-2`} />
                            Thurs
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                          <Nav.Link
                            id="friday"
                            eventKey="friday"
                            onClick={handleBlur1}
                          >
                            <i className={`la la-clock mr-2`} />
                            Fri
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                          <Nav.Link
                            id="saturday"
                            eventKey="saturday"
                            onClick={handleBlur1}
                          >
                            <i className={`la la-clock mr-2`} />
                            sat
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>

                      <Tab.Content className="pt-12">
                        <Tab.Pane eventKey="sunday">
                          <div className="row mt-4">
                            <div className="col-12">
                              <h4 className="card-title">Sunday</h4>

                              <Table responsive className="w-100">
                                <thead>
                                  {sundayData.map((name) => (
                                    <tr
                                      role="row"
                                      key={name.operation_time_uuid}
                                    >
                                      <td>
                                        {tConvert(name.start_time)} To{" "}
                                        {tConvert(name.end_time)}
                                      </td>
                                      <td>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-danger light pull-right mr-2"
                                          onClick={(e) =>
                                            handleDelete(
                                              name.operation_time_uuid,
                                              name.day,
                                              e
                                            )
                                          }
                                        >
                                          Delete
                                        </span>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-info light pull-right mr-2"
                                          onClick={(e) =>
                                            handleUpdate(
                                              name,
                                              name.operation_time_uuid,
                                              e
                                            )
                                          }
                                        >
                                          Edit
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </thead>
                                <tbody>
                                  <div
                                    className="col-sm-6 pull-left"
                                    title="Add New Time"
                                  >
                                    <span
                                      role="button"
                                      style={{ cursor: "pointer" }}
                                      className="badge badge-primary light mr-2"
                                      onClick={() => setAddTimeSlotModal(true)}
                                    >
                                      <i
                                        className="fa fa-plus-circle mr-2"
                                        aria-hidden="true"
                                      />
                                      Add New Time
                                    </span>
                                  </div>
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="monday">
                          <div className="row mt-4">
                            <div className="col-12">
                              <h4 className="card-title">Monday</h4>

                              <Table responsive className="w-100">
                                <thead>
                                  {mondayData.map((name) => (
                                    <tr
                                      role="row"
                                      key={name.operation_time_uuid}
                                    >
                                      <td>
                                        {tConvert(name.start_time)} To{" "}
                                        {tConvert(name.end_time)}
                                      </td>
                                      <td>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-danger light pull-right mr-2"
                                          onClick={(e) =>
                                            handleDelete(
                                              name.operation_time_uuid,
                                              name.day,
                                              e
                                            )
                                          }
                                        >
                                          Delete
                                        </span>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-info light pull-right mr-2"
                                          onClick={(e) =>
                                            handleUpdate(
                                              name,
                                              name.operation_time_uuid,
                                              e
                                            )
                                          }
                                        >
                                          Edit
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </thead>
                                <tbody>
                                  <div
                                    className="col-sm-6 pull-left"
                                    title="Add New Time"
                                  >
                                    <span
                                      role="button"
                                      style={{ cursor: "pointer" }}
                                      className="badge badge-primary light mr-2"
                                      onClick={() => setAddTimeSlotModal(true)}
                                    >
                                      <i
                                        className="fa fa-plus-circle mr-2"
                                        aria-hidden="true"
                                      />
                                      Add New Time
                                    </span>
                                  </div>
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="tuesday">
                          <div className="row mt-4">
                            <div className="col-12">
                              <h4 className="card-title">Tuesday</h4>

                              <Table responsive className="w-100">
                                <thead>
                                  {tuesdayData.map((name) => (
                                    <tr
                                      role="row"
                                      key={name.operation_time_uuid}
                                    >
                                      <td>
                                        {tConvert(name.start_time)} To{" "}
                                        {tConvert(name.end_time)}
                                      </td>
                                      <td>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-danger light pull-right mr-2"
                                          onClick={(e) =>
                                            handleDelete(
                                              name.operation_time_uuid,
                                              name.day,
                                              e
                                            )
                                          }
                                        >
                                          Delete
                                        </span>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-info light pull-right mr-2"
                                          onClick={(e) =>
                                            handleUpdate(
                                              name,
                                              name.operation_time_uuid,
                                              e
                                            )
                                          }
                                        >
                                          Edit
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </thead>
                                <tbody>
                                  <div
                                    className="col-sm-6 pull-left"
                                    title="Add New Time"
                                  >
                                    <span
                                      role="button"
                                      style={{ cursor: "pointer" }}
                                      className="badge badge-primary light mr-2"
                                      onClick={() => setAddTimeSlotModal(true)}
                                    >
                                      <i
                                        className="fa fa-plus-circle mr-2"
                                        aria-hidden="true"
                                      />
                                      Add New Time
                                    </span>
                                  </div>
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="wednesday">
                          <div className="row mt-4">
                            <div className="col-12">
                              <h4 className="card-title">Wednesday</h4>

                              <Table responsive className="w-100">
                                <thead>
                                  {wednesdayData.map((name) => (
                                    <tr
                                      role="row"
                                      key={name.operation_time_uuid}
                                    >
                                      <td>
                                        {tConvert(name.start_time)} To{" "}
                                        {tConvert(name.end_time)}
                                      </td>
                                      <td>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-danger light pull-right mr-2"
                                          onClick={(e) =>
                                            handleDelete(
                                              name.operation_time_uuid,
                                              name.day,
                                              e
                                            )
                                          }
                                        >
                                          Delete
                                        </span>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-info light pull-right mr-2"
                                          onClick={(e) =>
                                            handleUpdate(
                                              name,
                                              name.operation_time_uuid,
                                              e
                                            )
                                          }
                                        >
                                          Edit
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </thead>
                                <tbody>
                                  <div
                                    className="col-sm-6 pull-left"
                                    title="Add New Time"
                                  >
                                    <span
                                      role="button"
                                      style={{ cursor: "pointer" }}
                                      className="badge badge-primary light mr-2"
                                      onClick={() => setAddTimeSlotModal(true)}
                                    >
                                      <i
                                        className="fa fa-plus-circle mr-2"
                                        aria-hidden="true"
                                      />
                                      Add New Time
                                    </span>
                                  </div>
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="thursday">
                          <div className="row mt-4">
                            <div className="col-12">
                              <h4 className="card-title">Thursday</h4>

                              <Table responsive className="w-100">
                                <thead>
                                  {thursdayData.map((name) => (
                                    <tr
                                      role="row"
                                      key={name.operation_time_uuid}
                                    >
                                      <td>
                                        {tConvert(name.start_time)} To{" "}
                                        {tConvert(name.end_time)}
                                      </td>
                                      <td>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-danger light pull-right mr-2"
                                          onClick={(e) =>
                                            handleDelete(
                                              name.operation_time_uuid,
                                              name.day,
                                              e
                                            )
                                          }
                                        >
                                          Delete
                                        </span>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-info light pull-right mr-2"
                                          onClick={(e) =>
                                            handleUpdate(
                                              name,
                                              name.operation_time_uuid,
                                              e
                                            )
                                          }
                                        >
                                          Edit
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </thead>
                                <tbody>
                                  <div
                                    className="col-sm-6 pull-left"
                                    title="Add New Time"
                                  >
                                    <span
                                      role="button"
                                      style={{ cursor: "pointer" }}
                                      className="badge badge-primary light mr-2"
                                      onClick={() => setAddTimeSlotModal(true)}
                                    >
                                      <i
                                        className="fa fa-plus-circle mr-2"
                                        aria-hidden="true"
                                      />
                                      Add New Time
                                    </span>
                                  </div>
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="friday">
                          <div className="row mt-4">
                            <div className="col-12">
                              <h4 className="card-title">Friday</h4>

                              <Table responsive className="w-100">
                                <thead>
                                  {fridayData.map((name) => (
                                    <tr
                                      role="row"
                                      key={name.operation_time_uuid}
                                    >
                                      <td>
                                        {tConvert(name.start_time)} To{" "}
                                        {tConvert(name.end_time)}
                                      </td>
                                      <td>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-danger light pull-right mr-2"
                                          onClick={(e) =>
                                            handleDelete(
                                              name.operation_time_uuid,
                                              name.day,
                                              e
                                            )
                                          }
                                        >
                                          Delete
                                        </span>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-info light pull-right mr-2"
                                          onClick={(e) =>
                                            handleUpdate(
                                              name,
                                              name.operation_time_uuid,
                                              e
                                            )
                                          }
                                        >
                                          Edit
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </thead>
                                <tbody>
                                  <div
                                    className="col-sm-6 pull-left"
                                    title="Add New Time"
                                  >
                                    <span
                                      role="button"
                                      style={{ cursor: "pointer" }}
                                      className="badge badge-primary light mr-2"
                                      onClick={() => setAddTimeSlotModal(true)}
                                    >
                                      <i
                                        className="fa fa-plus-circle mr-2"
                                        aria-hidden="true"
                                      />
                                      Add New Time
                                    </span>
                                  </div>
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="saturday">
                          <div className="row mt-4">
                            <div className="col-12">
                              <h4 className="card-title">Saturday</h4>

                              <Table responsive className="w-100">
                                <thead>
                                  {saturdayData.map((name) => (
                                    <tr
                                      role="row"
                                      key={name.operation_time_uuid}
                                    >
                                      <td>
                                        {tConvert(name.start_time)} To{" "}
                                        {tConvert(name.end_time)}
                                      </td>
                                      <td>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-danger light pull-right mr-2"
                                          onClick={(e) =>
                                            handleDelete(
                                              name.operation_time_uuid,
                                              name.day,
                                              e
                                            )
                                          }
                                        >
                                          Delete
                                        </span>
                                        <span
                                          role="button"
                                          style={{ cursor: "pointer" }}
                                          className="badge badge-info light pull-right mr-2"
                                          onClick={(e) =>
                                            handleUpdate(
                                              name,
                                              name.operation_time_uuid,
                                              e
                                            )
                                          }
                                        >
                                          Edit
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </thead>
                                <tbody>
                                  <div
                                    className="col-sm-6 pull-left"
                                    title="Add New Time"
                                  >
                                    <span
                                      role="button"
                                      style={{ cursor: "pointer" }}
                                      className="badge badge-primary light mr-2"
                                      onClick={() => setAddTimeSlotModal(true)}
                                    >
                                      <i
                                        className="fa fa-plus-circle mr-2"
                                        aria-hidden="true"
                                      />
                                      Add New Time
                                    </span>
                                  </div>
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </Tab.Pane>

                        <span className="text-danger">
                          {errors.operation_time}
                        </span>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 text-right">
                    <Button type="submit" variant="primary" className="btn-sm">
                      Update
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal className="fade" show={addTimeSlotModal}>
        <TimeSlotForm
          closeModel={closeModel}
          day={daySel}
          onAddDestination={addDestinationHandler}
        />
      </Modal>
      <Modal className="fade" show={updateTimeSlotModal}>
        <UpdateTimeSlotForm
          closeModel={closeModel}
          day={daySel}
          updateData={updateData}
          onUpdateDestination={UpdateDestinationHandler}
        />
      </Modal>
    </Fragment>
  );
}

export default UpdateCallCampaignForm;
