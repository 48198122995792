import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Table,
  Modal,
  Button,
  ButtonGroup,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import PageTitle from "../../layouts/PageTitle";
import Pagination from "../../layouts/Pagination";
import {
  getTimeConditions,
  deleteTimeCondition,
  getNextTimeConditionID,
} from "../../actions/timeconditions";
import FlashMessage from "../../layouts/FlashMessage";
import TimeconditionForm from "./TimeconditionForm";
import CheckModulePermisson from "../../common/CheckModulePermisson";
import PermissionCheck from "../../common/PermissionCheck";
import Loader from "../../layouts/Loader";

function TimeConditions() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;
  const module_name = "time conditions";

  let module_per = CheckModulePermisson(module_name);

  let isPermission = true;
  if (module_per === "" || user_type === "admin") {
    isPermission = true;
  } else {
    isPermission = false;
  }

  let listing_per = PermissionCheck(module_name, "listing", "", "", "");
  let add_per = PermissionCheck(module_name, "add", "", "", "");

  const dispatch = useDispatch();
  let navigate = useNavigate();

  if (listing_per === "none") {
    navigate("/error-403");
  }

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }

  const [limits, setLimits] = useState(10);
  const [addTimeConditionModal, setAddTimeConditionModal] = useState(false);
  const [timeconditions, setTimeconditions] = useState([]);
  const [timeconditionEvent, setTimeconditionEvent] = useState("");
  const [nextTimeBaseRoutingId, setNextTimeBaseRoutingId] = useState("");
  const [timeconditionsTotal, setTimeconditionsTotal] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [offSet, setOffSet] = useState(0);

  const [loading, setLoading] = useState(false);

  let search = "";
  //let limits = 10;
  let orderby = "t.time_condition_name ASC";

  const closeModel = (value) => {
    setAddTimeConditionModal(value);
  };

  if (module_per === "" || user_type === "admin") {
    useEffect(() => {
      setLoading(true);
      dispatch(
        getTimeConditions(
          user_type,
          group_per,
          group_uuid,
          search,
          offSet,
          limits,
          orderby,
          main_uuid,
          createdby,
          token
        )
      )
        .then((response) => {
          setTimeconditions(response.data.data);
          const total = response.data.total;
          setTimeconditionsTotal(total);
          setpageCount(Math.ceil(total / limits));
          setTimeconditionEvent("");
          setLoading(false);
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    }, [timeconditionEvent, limits]);
  }

  const deleteTimeConditionBtn = (time_condition_uuid) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this Time-Based Routing",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteTimeconditionService(time_condition_uuid),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteTimeconditionService = (time_condition_uuid) => {
    setLoading(true);
    dispatch(deleteTimeCondition(time_condition_uuid, createdby, token))
      .then((response) => {
        if (response.status === 200) {
          notifyInfo(response.message);
          setLoading(false);
          setTimeconditionEvent("delete");
          return;
        } else if (response.status === 500) {
          setLoading(false);
          notifyError("Something went wrong.");
        } else {
          setLoading(false);
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handlePageClick = async (data) => {
    setOffSet(limits * data.selected);
    setTimeconditionEvent("update");
  };

  useEffect(() => {
    dispatch(getNextTimeConditionID(createdby, main_uuid, token))
      .then((response) => {
        setNextTimeBaseRoutingId(response.data.time_condition_id);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const chnageLimit = (value) => {
    setLimits(value);
  };

  const breadCurmList = [
    { label: "Calls", link: "/", is_active: "NO" },
    { label: "Time-Based Routing", link: "/business-hours", is_active: "NO" },
    {
      label: "Total Amount : " + timeconditionsTotal,
      link: "",
      is_active: "YES",
    },
  ];

  return (
    <Fragment>
      <FlashMessage />
      {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />

      {isPermission ? (
        <>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Time-Based Routing</h4>
                  {add_per === "yes" ? (
                    <Button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={() => setAddTimeConditionModal(true)}
                    >
                      Add New Time-Based Routing
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="card-body">
                  Show
                  <ButtonGroup className="ml-2 mr-2">
                    <DropdownButton
                      as={ButtonGroup}
                      id="bg-nested-dropdown"
                      size="sm"
                      title={limits}
                      variant="custom-dropdown"
                    >
                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(10)}
                      >
                        10
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(25)}
                      >
                        25
                      </Dropdown.Item>

                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(50)}
                      >
                        50
                      </Dropdown.Item>

                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(100)}
                      >
                        100
                      </Dropdown.Item>
                    </DropdownButton>
                  </ButtonGroup>
                  entries
                  <Table responsive className="w-100">
                    <thead>
                      <tr role="row">
                        <th>ID</th>
                        <th>Name</th>

                        {listing_per === "group" || user_type === "admin" ? (
                          <th>Created By</th>
                        ) : (
                          <></>
                        )}
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {timeconditions &&
                        timeconditions.map((timecondition, i) => (
                          <tr key={timecondition.time_condition_uuid}>
                            <td>
                              {PermissionCheck(
                                module_name,
                                "edit",
                                timecondition.group_uuid,
                                timecondition.user_created_by,
                                timecondition.created_by
                              ) === "hidden" ? (
                                <>{timecondition.time_condition_name}</>
                              ) : (
                                <>
                                  <Link
                                    to="/update-business-hours"
                                    state={{
                                      time_condition_uuid:
                                        timecondition.time_condition_uuid,
                                    }}
                                    className="text text-info"
                                  >
                                    {timecondition.time_condition_id}
                                  </Link>
                                </>
                              )}
                            </td>
                            <td>
                              {PermissionCheck(
                                module_name,
                                "edit",
                                timecondition.group_uuid,
                                timecondition.user_created_by,
                                timecondition.created_by
                              ) === "hidden" ? (
                                <> {timecondition.time_condition_name}</>
                              ) : (
                                <>
                                  <Link
                                    to="/update-business-hours"
                                    state={{
                                      time_condition_uuid:
                                        timecondition.time_condition_uuid,
                                    }}
                                    className="text text-info"
                                  >
                                    {timecondition.time_condition_name}
                                  </Link>
                                </>
                              )}
                            </td>

                            {listing_per === "group" ||
                            listing_per === "all" ||
                            user_type === "admin" ? (
                              <td>
                                {timecondition.first_name}{" "}
                                {timecondition.last_name}
                              </td>
                            ) : (
                              <></>
                            )}
                            <td>
                              <Fragment>
                                <div className="d-flex">
                                  {
                                    <Link
                                      to="/update-business-hours"
                                      state={{
                                        time_condition_uuid:
                                          timecondition.time_condition_uuid,
                                      }}
                                      hidden={PermissionCheck(
                                        module_name,
                                        "edit",
                                        timecondition.group_uuid,
                                        timecondition.user_created_by,
                                        timecondition.created_by
                                      )}
                                      className="btn btn-primary shadow btn-xs sharp mr-1"
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </Link>
                                  }
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      deleteTimeConditionBtn(
                                        timecondition.time_condition_uuid
                                      )
                                    }
                                    hidden={PermissionCheck(
                                      module_name,
                                      "delete",
                                      timecondition.group_uuid,
                                      timecondition.user_created_by,
                                      timecondition.created_by
                                    )}
                                    className="btn btn-danger shadow btn-xs sharp"
                                  >
                                    <i className="fa fa-trash"></i>
                                  </Link>
                                </div>
                              </Fragment>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  <Pagination
                    pageCount={pageCount}
                    handlePageNumberClick={handlePageClick}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <!-- add role Modal --> */}
          <Modal className="fade" show={addTimeConditionModal}>
            <TimeconditionForm
              timeRoutingId={nextTimeBaseRoutingId}
              closeModel={closeModel}
            />
          </Modal>
        </>
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Manage Roles</h4>
              </div>
              <div className="card-body">
                <h3>You have don't access this modules</h3>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default TimeConditions;
