import React, { Fragment, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
 import { createTab } from "../../../actions/customContactLayout";

function TabForm(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const section_uuid = props.selectedSection;

  const [errors, setErrors] = useState({
    tab_name: "",
  });  

  const [tabData, setTabData] = useState({
    tab_name: "",
    section_uuid : section_uuid,
    createdby: createdby,
    main_user_uuid: main_uuid,
    user_uuid: createdby,
});

  const dispatch = useDispatch();

  const handleBlur = (e) => {
    const newTabData = { ...tabData };
    let error_data = { ...errors };
    
    if(e.target.name === 'tab_name'  && e.target.value.length === 0){
        error_data[e.target.name] = "Tab name is required.";
        setErrors(error_data);
    }else{
        error_data[e.target.name] = "";
        setErrors(error_data);
    }
    newTabData[e.target.name] = e.target.value;
    setTabData(newTabData);  
  };

  const closeModel = (arg) => {
    props.closeTabModel(arg);
  }
  
  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";
    
    if(tabData.tab_name.length === 0){
        error_data['tab_name'] = "Tab name is required.";
        setErrors(error_data);
        isError = "true";
    }

    if(isError === "true"){
      return;
    }else{

      //call create custom tab API
      dispatch(createTab(tabData,token))
      .then(() => {
          closeModel(false);
          props.handleFormEvent(Math.random);
      })
      .catch(() => {
        
      });
    }
   
  }

  return (
    <Fragment>

      <Modal.Header>
        <Modal.Title>Add New Tab</Modal.Title>
        <Button
            variant=""
            className="close"
            onClick={() => closeModel(false)}
        >
            <span>&times;</span>
        </Button>
      </Modal.Header>

      <form action="" onSubmit={(e) => submitHandler(e)}>
        <Modal.Body>

          <div className="form-group input-primary">
              <label className="mb-1">
                  <strong>Tab Name</strong>
              </label>
              <input
                  type="text"
                  className="form-control bg-white"
                  name="tab_name"
                  onChange={handleBlur}
              />
              <span className="text-danger">{errors.tab_name}</span>
          </div>

        </Modal.Body>
        <Modal.Footer>
            <Button
                onClick={() => closeModel(false)}
                variant="danger light btn-sm"
            >
                Close
            </Button>
            <Button type="submit" variant="primary btn-sm">Save</Button>
        </Modal.Footer>
      </form>
    </Fragment>
  );
}

export default TabForm;
