import React, { Fragment } from "react";
import EventCalendar from "./EventCalendar";

function CalendarView(props) {
  
  return (
    <Fragment>
         
        <EventCalendar calendarEvent={props.calendarEvent} />

    </Fragment>
  );
}

export default CalendarView;
