import React, { Fragment, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  createTimeSlot,
  updateTimeSlot,
  deleteTimeSlotEvent,
} from "../../actions/timeconditions";
import { getDestinationTypeList } from "../../actions/ivr";
import { confirmAlert } from "react-confirm-alert";
import "./style.css";
import moment from "moment";
import Loader from "../../layouts/Loader";

function TimeSlotFormEve(props) {
  // let allTimeSlot = props.allTimeSlot;
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const params = useLocation();
  const time_condition_uuid = params.state.time_condition_uuid;
  const [timeslotData, setTimeslotData] = useState({
    id: props.id.id,
    time_condition_uuid: time_condition_uuid,
    time_condition_data_uuid: props.id.id,
    event_name: props.id.event_name,
    time_condition_date: props.id.time_condition_date,
    from_time: props.id.from_time,
    to_time: props.id.to_time,
    route_to: props.id.route_to,
    route_type: props.id.route_type,
    schedule_type: "date",
    token: token,
    createdby: createdby,
    main_admin_uuid: createdby,
    user_uuid: createdby,
  });

  const allTimeSlot = props.allTimeSlot;
  const closeModel = (arg) => {
    props.closeModel(arg);
  };

  const [destinationType, setDestinationType] = useState(props.timeSlotOp);
  let title = "";
  let action = "";

  if (props.id.id === "") {
    title = "Create New Time Slot";
    action = "create";
  } else {
    action = "update";
    title = "Update Time Slot";
  }

  const [errors, setErrors] = useState({
    time_slot_time: "",
    event_name: "",
    time_condition_data_uuid: "",
    from_time: "",
    to_time: "",
    route_to: "",
    route_type: "",
  });

  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBlur = (e) => {
    const newTimeslotData = { ...timeslotData };
    let error_data = { ...errors };

    if (e.target.name === "event_name" && e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }

    if (e.target.name === "from_time" && e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }

    if (e.target.name === "to_time" && e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }

    if (e.target.name === "route_type" && e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }

    if (e.target.name === "route_type" && e.target.value.length !== 0) {
      const main_uuid = user_data.main_uuid;

      dispatch(
        getDestinationTypeList(createdby, token, main_uuid, e.target.value)
      )
        .then((response) => {
          setDestinationType(response.data);
          newTimeslotData["route_to"] = response.data[0].value;
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    }

    if (e.target.name === "route_to" && e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }

    newTimeslotData[e.target.name] = e.target.value;
    setTimeslotData(newTimeslotData);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (timeslotData.event_name.length === 0) {
      error_data["event_name"] = "Event Name is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (timeslotData.from_time.length === 0) {
      error_data["from_time"] = "From time is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (timeslotData.to_time.length === 0) {
      error_data["to_time"] = "To time is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (timeslotData.route_type.length === 0) {
      error_data["route_type"] = "Route type is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (timeslotData.route_to.length === 0) {
      error_data["route_to"] = "Route to is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create time slot API

      if (
        isTimeSlotAvailable(timeslotData.from_time, timeslotData.to_time, "")
      ) {
        error_data["time_slot_time"] = "";
        setErrors(error_data);

        if (action === "create") {
          dispatch(createTimeSlot(timeslotData))
            .then((data) => {
              closeModel(false);
              props.handleTimeSlotEvent(data.data);
              setLoading(false);
              navigate(`/update-business-hours`, {
                state: { time_condition_uuid: time_condition_uuid },
              });
            })
            .catch(() => {
              setLoading(false);
            });
        } else {
          dispatch(updateTimeSlot(timeslotData))
            .then((data) => {
              closeModel(false);
              props.handleTimeSlotEvent(data.data);
              setLoading(false);
              navigate(`/update-business-hours`, {
                state: { time_condition_uuid: time_condition_uuid },
              });
            })
            .catch(() => {
              setLoading(false);
            });
        }
      } else {
        error_data["time_slot_time"] = "Time slot is not available";
        setErrors(error_data);
      }
    }
  };

  const deleteTimeSlotEventBtn = (time_condition_data_uuid) => {
    closeModel(false);
    confirmAlert({
      title: "",
      message: "Are you sure to delete this time condition",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteTimeSlotService(time_condition_data_uuid),
        },
        {
          label: "No",
        },
      ],
    });
    return false;
  };

  const deleteTimeSlotService = (time_slot_uuid) => {
    setLoading(true);
    dispatch(deleteTimeSlotEvent(time_slot_uuid, createdby, token))
      .then((response) => {
        props.handleTimeSlotEvent(time_slot_uuid);
        setLoading(false);
        navigate(`/update-business-hours`, {
          state: { time_condition_uuid: time_condition_uuid },
        });
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const isTimeSlotAvailable = (from_time, to_time, id = "") => {
   
    let time_array = allTimeSlot;

    if (id !== "") {
      time_array = time_array.filter((element) => element.id !== id);
    }

    for (const slot of time_array) {
      if (
        (from_time >= slot.from_time && from_time < slot.to_time) ||
        (to_time > slot.from_time && to_time <= slot.to_time)
      ) {
        return false; // Time slot is already occupied
      }
    }
    return true; // Time slot is available
  };

  const times = [];
  for (let i = 0; i < 24; i++) {
    times.push(
      `${i % 12 || 12}:00 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:15 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:30 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:45 ${i < 12 ? "AM" : "PM"}`
    );
  }

  function conDateFormate(time) {

    const moment24 = moment(time, "HH:mm");

    return moment24.format("h:mm A");
  }

  return (
    <Fragment>
      {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <form action="" onSubmit={(e) => submitHandler(e)}>
        <Modal.Body>
          <div className="row">
            <div className="col-sm">
              <div className="form-group">
                <label className="mb-1">
                  <strong>Event Name</strong>
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control time_field_hp"
                  //placeholder="Event name"
                  name="event_name"
                  defaultValue={timeslotData.event_name}
                  onChange={handleBlur}
                />
                <span className="text-danger">{errors.event_name}</span>
              </div>
            </div>
            <div className="col-sm">
              <div className="form-group">
                <label className="mb-1">
                  <strong>Date</strong>
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control time_field_hp"
                  //placeholder="Date"
                  name="time_condition_date"
                  defaultValue={timeslotData.time_condition_date}
                  onChange={handleBlur}
                  readOnly
                />
                <span className="text-danger">
                  {errors.time_condition_date}
                </span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm">
              <div className="form-group">
                <label className="mb-1">
                  <strong>From Time</strong>
                  <span className="text-danger">*</span>
                </label>

                <select
                  className="form-control bg-white time_field_hp"
                  name="from_time"
                  // onChange={(e) => handleToFrom(e, data1)}
                  onChange={handleBlur}
                  value={conDateFormate(timeslotData.from_time)}
                  id="from_time"
                >
                  <option value="">Select To Time</option>
                  {times &&
                    times.map((time) => (
                      <option key={time} value={time}>
                        {time}
                      </option>
                    ))}
                </select>
                {/* <input
                  type="time"
                  className="form-control"
                  //placeholder="From Time"
                  name="from_time"
                  defaultValue={timeslotData.from_time}
                  onChange={handleBlur}
                /> */}
                <span className="text-danger">{errors.from_time}</span>
              </div>
            </div>
            <div className="col-sm">
              <div className="form-group">
                <label className="mb-1">
                  <strong>To Time</strong>
                  <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control bg-white time_field_hp"
                  name="to_time"
                  // onChange={(e) => handleToFrom(e, data1)}
                  onChange={handleBlur}
                  value={conDateFormate(timeslotData.to_time)}
                  id="to_time"
                >
                  <option value="">Select To Time</option>
                  {times &&
                    times.map((time) => (
                      <option key={time} value={time}>
                        {time}
                      </option>
                    ))}
                </select>
                {/* <input
                  type="time"
                  className="form-control"
                  //placeholder="To Time"
                  name="to_time"
                  defaultValue={timeslotData.to_time}
                  onChange={handleBlur}
                /> */}
                <span className="text-danger">{errors.to_time}</span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <label>Route Type</label>
              <span className="text-danger">*</span>
              <select
                className="form-control time_field_hp"
                name="route_type"
                onChange={handleBlur}
                defaultValue={timeslotData.route_type}
              >
                <option value="">Select Route Type</option>
                <option value="ring-group">Ring Group</option>
                <option value="extention">Extention</option>
                <option value="did">Did</option>
                <option value="voicemail">Voicemail</option>
              </select>
              <span className="text-danger">{errors.route_type}</span>
            </div>
            <div className="col">
              <label>Route To</label>
              <span className="text-danger">*</span>
              <select
                className="form-control time_field_hp"
                name="route_to"
                onChange={handleBlur}
                defaultValue={timeslotData.route_to}
              >
                {destinationType &&
                  destinationType.map((number) => (
                    <option key={number.value} value={number.value}>
                      {number.text}
                    </option>
                  ))}
              </select>
              <span className="text-danger">{errors.route_to}</span>
            </div>
          </div>
          <span className="text-danger">{errors.time_slot_time}</span>
        </Modal.Body>
        <Modal.Footer>
          {action === "update" ? (
            <Link
              to="#"
              state={{ time_condition_uuid: time_condition_uuid }}
              onClick={() => deleteTimeSlotEventBtn(props.id.id)}
              className="btn btn-danger shadow btn-sm"
            >
              Remove Time Slot
            </Link>
          ) : (
            ""
          )}
          <Button
            onClick={() => closeModel(false)}
            variant="danger light btn-sm"
          >
            Close
          </Button>
          <Button type="submit" name="submit" variant="primary btn-sm">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Fragment>
  );
}

export default TimeSlotFormEve;
