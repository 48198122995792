import React, { Fragment, useState, useEffect } from "react";
import { Button,Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { notifyError,notifyInfo } from "../../Toastr/Toastr";
import { updateTask } from "../../../actions/Tasks";
import { contactDropdown } from "../../../actions/contacts";
import { getUserListDropdown } from "../../../actions/comman";
import PermissionCheck from "../../../common/PermissionCheck";
import Loader from "../../../layouts/Loader";

function TaskUpdateForm(props) {
  
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const user_type = user_data.role;
  let listing_per = PermissionCheck("contact", "listing", "", "", "");

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
      group_uuid = user_data.group_id;
  }

  const selectedTask = props.selectedTask;
  
  const [loading, setLoading] = useState(false);
  const [contactOptions, setContactOptions] = useState([]);

  const times = [];
  for (let i = 0; i < 24; i++) {
    times.push(
      `${i % 12 || 12}:00 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:15 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:30 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:45 ${i < 12 ? "AM" : "PM"}`
    );
  }

  const [users, setUsers] = useState([]);
  
  const [taskData, setTaskData] = useState({
    contact_task_uuid : selectedTask.contact_task_uuid,
    task_title : selectedTask.task_title,
    task_date : selectedTask.task_date,
    task_time : selectedTask.task_time,
    contact_uuid : selectedTask.contact_uuid,
    assign_user : selectedTask.assign_user,
    description : selectedTask.description,
    status : selectedTask.status,
    createdby : createdby,
    main_uuid : main_uuid,
  });

  const dispatch = useDispatch();

  const [taskErrors, setTaskErrors] = useState({
    task_title: "",
    task_date: "",
    task_time: "",
    description: "",
    assign_user: "",
  });

  useEffect(() => {
    let data = {
      createdby : createdby,
      main_uuid : main_uuid,
      group_uuid : group_uuid,
      user_type : user_type,
      group_per : group_per,
    }

    dispatch(
      contactDropdown(
        data,
        token
      )
    )
    .then((response) => {
      //
      let contacts = [];
      response.data.data.map((contact, index) => {
          contacts[index] = {label:'<'+contact.first_name+' '+contact.last_name+'>'+contact.email, value: contact.contact_uuid}
          return true;
      })

      setContactOptions(contacts);
      return;
    })
    .catch(() => {
      setLoading(false);
    });
   
  }, []);

  useEffect(() => {
    dispatch(
      getUserListDropdown(
        "create",
        "",
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        setUsers(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const closeModel = (value) => {
    props.closeTaskUpdateModel(value);
  }

  const handleBlurTask = (e) => {
    let error_data = { ...taskErrors };

    let task_data = { ...taskData };

    if(e.target.value.length === 0){
        error_data[e.target.name] = 'Field is required.';
        setTaskErrors(error_data);
    }else{
      error_data[e.target.name] = '';
      setTaskErrors(error_data);
    }

    task_data[e.target.name] = e.target.value;
    setTaskData(task_data);
  }

  const submitTask = (e) => {
    e.preventDefault();

    let error_data = { ...taskErrors };
    let isError = "false";

    setLoading(true);

    if (taskData.task_title.length === 0) {
      error_data["task_title"] = "Task is required.";
      setTaskErrors(error_data);
      isError = "true";
    }

    if (taskData.task_date && taskData.task_date.length === 0) {
      error_data["task_date"] = "Task date is required.";
      setTaskErrors(error_data);
      isError = "true";
    }

    if (taskData.task_time && taskData.task_time.length === 0) {
      error_data["task_time"] = "Task time is required.";
      setTaskErrors(error_data);
      isError = "true";
    }

    if (taskData.description.length === 0) {
      error_data["description"] = "Description is required.";
      setTaskErrors(error_data);
      isError = "true";
    }

    if (taskData.assign_user.length === 0) {
      error_data["assign_user"] = "Assign User is required.";
      setTaskErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {

      dispatch(updateTask(taskData,token))
      .then((res) => {
        closeModel(false);
        props.handleTaskEvent(Math.random());
  
        if (res.status === 200) {
          notifyInfo(res.message);
          closeModel(false);
        } else if (res.status === 406) {
          error_data["number"] = res.message;
          setTaskErrors(error_data);
        } else if (res.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
      
    }
  }


  return (
    <Fragment>
      {loading && <Loader />}
      
      <Modal.Header>
        <Modal.Title>Update Task : {selectedTask.task_title}</Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      
        <Modal.Body>

          <form action="" onSubmit={(e) => submitTask(e)}>

            <div className="row mt-3">
              <div className="form-group input-primary">
                <label className="mb-1">
                  <strong>Title</strong>
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control bg-white h-75"
                  name="task_title"
                  value={taskData.task_title}
                  onChange={handleBlurTask}
                />
                <span className="text-danger">{taskErrors.task_title}</span>
              </div>
            </div>

            <div className="row">
              <div className="col-sm">
                <div className="form-group input-primary">
                  <label className="mb-1">
                    <strong>Task Time</strong>
                    <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control bg-white"
                    name="task_time"
                    onChange={handleBlurTask}
                    value={taskData.task_time}
                  >
                    <option value="">Select Time</option>
                    {times &&
                      times.map((time) => (
                        <option key={time} value={time}>
                          {time}
                        </option>
                      ))}
                  </select>
                  <span className="text-danger">{taskErrors.task_time}</span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group input-primary">
                <label className="mb-1">
                  <strong>Contact</strong>
                  <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control bg-white h-75"
                  name="contact_uuid"
                  onChange={handleBlurTask}
                  value={taskData.contact_uuid}
                >
                  <option value="">Select Contact</option>
                  {contactOptions &&
                    contactOptions.map((contact) => (
                      <option key={contact.value} value={contact.value}>
                        {contact.label}
                      </option>
                    ))}
                </select>
                <span className="text-danger">{taskErrors.contact_uuid}</span>
              </div>
            </div>

            <div className="row">
              <div className="col-sm">
                <div className="form-group input-primary">
                  <label className="mb-1">
                    <strong>Assign User</strong>
                  </label>
                  <select
                    className="form-control bg-white"
                    name="assign_user"
                    onChange={handleBlurTask}
                    value={taskData.assign_user}
                  >
                    <option value="">Select User</option>
                    {users.map((user) => (
                      <option key={user.uuid} value={user.uuid}>
                        {user.user_name}
                      </option>
                    ))}
                    <option key={createdby} value={createdby}>
                      Assign Me
                    </option>
                  </select>
                  <span className="text-danger">{taskErrors.assign_user}</span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group input-primary">
                <label className="mb-1">
                  <strong>Description</strong>
                </label>
                <input type="text" name="description" value={taskData.description} onChange={handleBlurTask} className="form-control bg-white" />
                {/* <span className="text-danger">{errors.description}</span> */}
              </div>
            </div>

            <Button type="submit" variant="primary btn-sm mr-2">
              Save
            </Button>

            <Button type="button" variant="danger btn-sm" onClick={(e) => closeModel(false)}>
              close
            </Button>
          </form>
                       
        </Modal.Body>      

        {/* <Modal.Footer>             
          
        </Modal.Footer>  */}
      {/* </form> */}
    </Fragment>
  );
}

export default TaskUpdateForm;
