
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


// const Toastr = () => {
   const notifyTopRight = (msg) => {
      toast.success(msg, {
         position: "top-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
      });
   };

   const notifyBottomRight = (msg) => {
      toast.warn(msg, {
         position: "bottom-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
      });
   };

   const notifyBottomLeft = (msg) => {
      toast.success(msg, {
         position: "bottom-left",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
      });
   };

   const notifyTopLeft = (msg) => {
      toast.warn(msg, {
         position: "bottom-left",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
      });
   };

   const notifyTopFullWidth = (msg) => {
      toast.warn(msg, {
         position: "top-center",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
      });
   };

   const notifyBottomFullWidth = (msg) => {
      toast.warn(msg, {
         position: "bottom-center",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
      });
   };

   const notifyTopCenter = (msg) => {
      toast.warn(msg, {
         position: "top-center",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
      });
   };

   const notifyBottomCenter = (msg) => {
      toast.warn(msg, {
         position: "bottom-center",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
      });
   };
   const notifyInfo = (msg) => {
      toast.info(msg, {
         position: "top-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
      });
   };
   const notifyWarning = (msg) => {
      toast.warn(msg, {
         position: "top-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
      });
   };

   const notifyError = (msg) => {
      toast.error(msg, {
         position: "top-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
      });
   };
   
// };

export { notifyInfo,notifyError,notifyWarning,
   notifyBottomCenter,
   notifyTopCenter,
   notifyBottomFullWidth,
   notifyTopFullWidth,
   notifyTopLeft,
   notifyBottomLeft,
   notifyBottomRight,
   notifyTopRight
};

