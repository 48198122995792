import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import { getUserDetail } from "../../../actions/user";
import ChangePassword from "./ChangePassword";
import AdminProfile from "./AdminProfile";
import E911 from "./E911";
import CompanyProfile from "./CompanyProfile";
import CompanySignature from "./companySignature";
import EmailConfigForm from "./EmailConfigForm";
import SelfSignature from "./selfSignature";

const AppProfile = () => {
  const [activeToggle, setActiveToggle] = useState("setting");

  const dispatch = useDispatch();

  const [userData, setUserData] = useState([]);
  const [formEvent, setUserEvent] = useState([]);

  const [formData, setFormData] = useState({
    e911_data: {
      caller_id_name: "",
      address_1: "",
      address_2: "",
      city: "",
      state: "",
      zip: "",
      caller_id_number_uuid: "",
      house_number: "",
      street_name: "",
    },
    company_data: {
      company_uuid: "",
      user_uuid: "",
      company_name: "",
      industry: "",
      company_type: "",
      company_address: "",
      locality: "",
      city: "",
      state: "",
      postal_code: "",
      company_email: "",
      company_phone_number: "",
      company_address2: "",
      company_logo: "",
    },
  });

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;

  const createdby = user_data.user_uuid;

  useEffect(() => {
    dispatch(getUserDetail(createdby, createdby, token))
      .then((response) => {
        setUserData(response.data.user);
        const newformdata = { ...formData };
        if (response.data.user_e911.length !== 0) {
          newformdata.e911_data.user_e911_uuid =
            response.data.user_e911.user_e911_uuid;
          newformdata.e911_data.address_1 = response.data.user_e911.address_1;
          newformdata.e911_data.address_2 = response.data.user_e911.address_2;
          newformdata.e911_data.caller_id_name =
            response.data.user_e911.caller_id_name;
          newformdata.e911_data.city = response.data.user_e911.city;
          newformdata.e911_data.state = response.data.user_e911.state;
          newformdata.e911_data.zip = response.data.user_e911.zip;
          newformdata.e911_data.caller_id_number =
            response.data.user_e911.caller_id_number;
          newformdata.e911_data.user_type = response.data.user_e911.user_type;
          newformdata.e911_data.house_number =
            response.data.user_e911.house_number;
          newformdata.e911_data.street_name =
            response.data.user_e911.street_name;
          newformdata.e911_data.caller_id_number_uuid =
            response.data.user_e911.caller_id_number_uuid;
        }

        if (response.data.company_data.length !== 0) {
          newformdata.company_data.company_uuid =
            response.data.company_data.company_uuid;
          newformdata.company_data.company_name =
            response.data.company_data.company_name;
          newformdata.company_data.company_address =
            response.data.company_data.company_address;
          newformdata.company_data.locality =
            response.data.company_data.locality;
          newformdata.company_data.city = response.data.company_data.city;
          newformdata.company_data.state = response.data.company_data.state;
          newformdata.company_data.postal_code =
            response.data.company_data.postal_code;
          newformdata.company_data.company_email =
            response.data.company_data.company_email;
          newformdata.company_data.company_phone_number =
            response.data.company_data.company_phone_number;
          newformdata.company_data.company_address2 =
            response.data.company_data.company_address2;
          newformdata.company_data.company_logo =
            response.data.company_data.company_logo;
        }

        setFormData(newformdata);

        return;
      })
      .catch(() => {
        //setLoading(false);
      });
  }, [formEvent]);

  const handleE911Data = (dataFromE911) => {
   
    setFormData(dataFromE911);
  };

  const handleE911Event = (val) => {
    setUserEvent(val);
  };

  const handleCompanyData = (dataFromCompany) => {
    setFormData(dataFromCompany);
  };

  const handleCompanyEvent = (val) => {
    setUserEvent(val);
  };


  let username = user_data.first_name + " " + user_data.last_name;
  const breadCurmList = [
    { label: "Home", link: "/", is_active: "NO" },
    { label: "Profile", link: "/app-profile", is_active: "NO" },
    { label: username, link: "", is_active: "YES" },
  ];

  return (
    <Fragment>
      <PageTitle breadCurmList={breadCurmList} />

      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="profile-info">
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0">
                      {userData.first_name} {userData.last_name}
                    </h4>
                    <p>{userData.role}</p>
                  </div>
                  <div className="profile-email px-2 pt-2">
                    <h4 className="text-muted mb-0">{userData.email}</h4>
                    <p>Email</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <Link
                        to="#profile-settings"
                        data-toggle="tab"
                        onClick={() => setActiveToggle("setting")}
                        className={`nav-link ${
                          activeToggle === "setting" ? "active show" : ""
                        }`}
                      >
                        Profile
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#change-password"
                        data-toggle="tab"
                        onClick={() => setActiveToggle("changePassword")}
                        className={`nav-link ${
                          activeToggle === "changePassword" ? "active show" : ""
                        }`}
                      >
                        Change Password
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#e911"
                        data-toggle="tab"
                        onClick={() => setActiveToggle("e911")}
                        className={`nav-link ${
                          activeToggle === "e911" ? "active show" : ""
                        }`}
                      >
                        E911
                      </Link>
                    </li>

                    
                    
                    {user_data.role === "admin" && (
                        <li className="nav-item">
                          <Link
                            to="#company-profile"
                            data-toggle="tab"
                            onClick={() => setActiveToggle("companyProfile")}
                            className={`nav-link ${
                              activeToggle === "companyProfile"
                                ? "active show"
                                : ""
                            }`}
                          >
                            Company Profile
                          </Link>
                        </li>
                    )}

                    <li className="nav-item">
                      <Link
                        to="#email-signature"
                        data-toggle="tab"
                        onClick={() => setActiveToggle("emailSignature")}
                        className={`nav-link ${
                          activeToggle === "emailSignature"
                            ? "active show"
                            : ""
                        }`}
                      >
                        Self Email Signature
                      </Link>
                    </li>
                    
                    {user_data.role === "admin" ? (
                        <li className="nav-item">
                          <Link
                            to="#companySignature"
                            data-toggle="tab"
                            onClick={() => setActiveToggle("companySignature")}
                            className={`nav-link ${
                              activeToggle === "companySignature"
                                ? "active show"
                                : ""
                            }`}
                          >
                            Company Signature
                          </Link>
                        </li>
                    ) : (
                      <></>
                    )}

                    <li className="nav-item">
                      <Link
                        to="#"
                        data-toggle="tab"
                        onClick={() => setActiveToggle("emailConfig")}
                        className={`nav-link ${
                          activeToggle === "emailConfig"
                            ? "active show"
                            : ""
                        }`}
                      >
                        Email Configuration
                      </Link>
                    </li>

                  </ul>
                  <div className="tab-content">
                    <div
                      id="profile-settings"
                      className={`tab-pane fade ${
                        activeToggle === "setting" ? "active show" : ""
                      }`}
                    >
                      <div className="pt-3">
                        <AdminProfile userData={userData} />
                      </div>
                    </div>

                    <div
                      id="change-password"
                      className={`tab-pane fade ${
                        activeToggle === "changePassword" ? "active show" : ""
                      }`}
                    >
                      <ChangePassword />
                    </div>

                    <div
                      id="e911"
                      className={`tab-pane fade ${
                        activeToggle === "e911" ? "active show" : ""
                      }`}
                    >
                      <E911
                        formData1={formData}
                        handleE911Data={handleE911Data}
                        handleE911Event={handleE911Event}
                      />
                    </div>

                    <div
                      id="company-profile"
                      className={`tab-pane fade ${
                        activeToggle === "companyProfile" ? "active show" : ""
                      }`}
                    >
                      <CompanyProfile
                        formData1={formData}
                        handleCompanySaveData={handleCompanyData}
                        handleCompanyEvent={handleCompanyEvent}
                      />
                    </div>

                    <div
                      id="emailSignature"
                      className={`tab-pane fade ${
                        activeToggle === "emailSignature" ? "active show" : ""
                      }`}
                    >
                      <SelfSignature />
                    </div>

                    <div
                      id="companySignature"
                      className={`tab-pane fade ${
                        activeToggle === "companySignature" ? "active show" : ""
                      }`}
                    >
                      <CompanySignature />
                    </div>


                    <div
                      id="emailConfig"
                      className={`tab-pane fade ${
                        activeToggle === "emailConfig" ? "active show" : ""
                      }`}
                    >
                      <EmailConfigForm />
                    </div>
                    

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AppProfile;
