import React, { Fragment } from "react";
import { withResizeDetector } from "react-resize-detector";

/// Components
import Markup from "./jsx";

/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";

import "./css/style.css";


function App({ width }) {
  const body = document.querySelector("body");

  body.setAttribute("data-typography", "poppins");
  body.setAttribute("data-theme-version", "light");
  body.setAttribute("data-layout", "vertical");
  body.setAttribute("data-nav-headerbg", "color_1");
  //body.setAttribute("data-headerbg", "color_16");
  body.setAttribute("data-headerbg", "color_3");
  body.setAttribute("data-sibebarbg", "color_1");
  body.setAttribute("data-primary", "color_1");
  body.setAttribute("data-sidebar-position", "static");
  body.setAttribute("data-header-position", "fixed");
  body.setAttribute("data-container", "wide");
  body.setAttribute("direction", "ltr");

  //color_8 -> blue light

  //   body.setAttribute("data-typography", "poppins");
  //  body.setAttribute("data-theme-version", "light");
  //  body.setAttribute("data-layout", "vertical");
  //  body.setAttribute("data-nav-headerbg", "color_8");
  //  body.setAttribute("data-headerbg", "color_8");
  //  body.setAttribute("data-sibebarbg", "color_1");
  //  body.setAttribute("data-primary", "color_8");
  //  body.setAttribute("data-sidebar-position", "static");
  //  body.setAttribute("data-header-position", "fixed");
  //  body.setAttribute("data-container", "wide");
  //  body.setAttribute("direction", "ltr");

  width > 767 && width < 1200
    ? body.setAttribute("data-sidebar-style", "overlay")
    : width < 768
    ? body.setAttribute("data-sidebar-style", "overlay")
    : body.setAttribute("data-sidebar-style", "full");

    //body.setAttribute("data-sidebar-style", "modern");
    //body.setAttribute("data-sidebar-style", "mini");
    //body.setAttribute("data-sidebar-style", "full");

  return (
    <Fragment>
      {/* <div id="preloader">
          <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
          </div>
      </div> */}
      <Markup showProfileSideBar={width <= 1300 ? false : false} />
    </Fragment>
  );
}

export default withResizeDetector(App);
//export default App;
