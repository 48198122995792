import React, { Fragment, useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  updateSmsTemplate,
  getSmsTemplateDetail,
  getContactFieldsList,
} from "../../actions/smstemplate";
import PageTitle from "../../layouts/PageTitle";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import Loader from "../../layouts/Loader";

function UpdateSmsTemplateForm(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const textboxRef = useRef(null);

  const params = useLocation();
  const sms_temp_uuid = params.state.sms_temp_uuid;

  const [smsTempData, setSmsTempData] = useState({
    sms_temp_uuid: "",
    name: "",
    message: "",
    message_type: "",
    file: "",
    token: token,
    main_admin_uuid: main_uuid,
    createdby: createdby,
    user_uuid: createdby,
  });

  const [errors, setErrors] = useState({
    name: "",
    assign_user_data: "",
    group_owner: "",
  });

  const [loading, setLoading] = useState(false);
  const [fieldsData, setFieldsData] = useState([]);
  const [messageVal, setMessageVal] = useState("");
  const [file, setFile] = useState();
  const [selectedOption, setSelectedOption] = useState("");

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBlur = (e) => {
    const newGroupData = { ...smsTempData };
    let error_data = { ...errors };
    if (e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = "";
      setErrors(error_data);
    }

    if (e.target.name === "message") {
      setMessageVal(e.target.value);
    }

    // const MIN_FILE_SIZE = 1024 // 1MB
    const MAX_FILE_SIZE = 2048; // 5MB

    if (e.target.name === "file") {
      const fileSizeKiloBytes = e.target.files[0].size / 1024;

      if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        // setErrorMsg("File size is greater than maximum limit");
        error_data[e.target.name] = "File size not allow greater then 2MB.";
        setErrors(error_data);
        return;
      }

      setFile(e.target.files[0]);
    }

    newGroupData[e.target.name] = e.target.value;
    setSmsTempData(newGroupData);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getSmsTemplateDetail(createdby, token, sms_temp_uuid))
      .then((response) => {
        setLoading(false);
        setSmsTempData(response.data);
        setMessageVal(response.data.message);
        setSelectedOption(response.data.message_type);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(getContactFieldsList(createdby, token))
      .then((response) => {
        setLoading(false);
        setFieldsData(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (smsTempData.name.length === 0) {
      error_data["name"] = "Name is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (smsTempData.message_type.length === 0) {
      error_data["message_type"] = "Message Type is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (
      smsTempData.message_type === "mms" &&
      file === undefined &&
      smsTempData.file_name.length === 0
    ) {
      error_data["file"] = "Recording file is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (smsTempData.message.length === 0) {
      error_data["message"] = "message is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call update sms tempplate API

      const formData = new FormData();
      let file_name = "";
      if (selectedOption === "sms") {
        file_name = "";
      } else {
        if (file === undefined) {
          file_name = "";
          formData.append("old_file_name", smsTempData.file_name);
        } else {
          file_name = file.name;
          formData.append("old_file_name", smsTempData.file_name);
        }
      }

      formData.append("sms_temp_uuid", smsTempData.sms_temp_uuid);
      formData.append("file", file);
      formData.append("file_name", file_name);
      formData.append("name", smsTempData.name);
      formData.append("message_type", selectedOption);
      formData.append("message", smsTempData.message);
      formData.append("createdby", createdby);
      formData.append("main_admin_uuid", main_uuid);
      formData.append("user_uuid", createdby);
     

      dispatch(updateSmsTemplate(formData, token))
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            notifyInfo(res.message);
            navigate("/sms-template", { replace: true });
          } else if (res.status === 406) {
            error_data["name"] = res.message;
            setErrors(error_data);
            isError = "true";
          } else if (res.status === 500) {
            notifyError("Something went wrong.");
          } else {
            notifyError("Something went wrong.");
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  function handleSelect(data) {
    const textboxValue = textboxRef.current.value;
    // let msg_val = textboxValue + " " + data.target.value;
    let msg_val = textboxValue +"  {" + data.target.value + "}";
    setMessageVal(msg_val);
    const newGroupData1 = { ...smsTempData };
    newGroupData1["message"] = msg_val;
    setSmsTempData(newGroupData1);
  }

  function handleSelectType(data) {
    setSelectedOption(data.target.value);
    const newGroupData1 = { ...smsTempData };
    newGroupData1["message_type"] = data.target.value;
    setSmsTempData(newGroupData1);
  }

  function toTitleCase(str) {
    const titleCase = str
      .toLowerCase()
      .split("_")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");

    return titleCase;
  }

  const breadCurmList = [
    { label: "SMS", link: "/", is_active: "NO" },
    { label: "SMS Template", link: "/sms-template", is_active: "NO" },
    {
      label: "Update SMS Template : " + smsTempData.name,
      link: "",
      is_active: "YES",
    },
  ];

  return (
    <Fragment>
      {loading && <Loader />}

      <PageTitle breadCurmList={breadCurmList} />

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                Update SMS Template - {smsTempData.name}
              </h4>
              <Button
                type="button"
                className="btn btn-default btn-sm"
                onClick={() => navigate("/sms-template", { replace: true })}
              >
                <i className="fa fa-reply" aria-hidden="true"></i> Back
              </Button>
            </div>
            <div className="card-body">
              <form action="" onSubmit={(e) => submitHandler(e)}>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Name</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        name="name"
                        id="name"
                        value={smsTempData.name}
                        onChange={handleBlur}
                      />
                      <span className="text-danger">{errors.name}</span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Variable</strong>
                      </label>
                      <select
                        className="form-control"
                        name="variable"
                        onChange={handleSelect}
                      >
                        <option value="">Select Variable</option>
                        {fieldsData.map((user) => (
                          <option key={user} value={user}>
                            {toTitleCase(user)}
                          </option>
                        ))}
                      </select>
                      <span className="text-danger">
                        {errors.assign_number}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Message Type</strong>
                      </label>
                      <select
                        className="form-control"
                        name="message_type"
                        value={smsTempData.message_type}
                        onChange={handleSelectType}
                      >
                        <option value="">Select Message Type</option>
                        <option value="sms">SMS</option>
                        <option value="mms">MMS</option>
                      </select>
                      <span className="text-danger">{errors.message_type}</span>
                    </div>
                  </div>
                  <div
                    className="col-6"
                    style={{
                      display: selectedOption === "mms" ? "block" : "none",
                    }}
                  >
                    <div className="form-group mt-2">
                      <label
                        className="mb-1"
                        title="File size shoud be less then 2 MB."
                      >
                        <strong>Replace File</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name="file"
                        onChange={handleBlur}
                      />
                      <span>File Name:- {smsTempData.file_name}</span>
                      <span className="text-danger">{errors.file}</span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">
                        <strong>Message</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Message"
                        name="message"
                        ref={textboxRef}
                        value={messageVal}
                        onChange={handleBlur}
                      >
                        {smsTempData.message}
                      </textarea>
                      <span className="text-danger">{errors.message}</span>
                    </div>
                  </div>
                </div>

                <div className="row text-right">
                  <div className="col-sm">
                    <Button type="submit" variant="primary" className="btn-sm">
                      Update
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default UpdateSmsTemplateForm;
