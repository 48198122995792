
import React,{Fragment,useState,useEffect} from "react";
import { Modal,Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Loader from "../../../layouts/Loader";
import CreatableSelect from "react-select/creatable";
import PermissionCheck from "../../../common/PermissionCheck";
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import { contactDropdown } from "../../../actions/contacts";
import { SendEmail } from "../../../actions/email/emails";
import {EditorState,convertToRaw, ContentState} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import moment from "moment";

function ReplyMail(props) {

    const user = JSON.parse(localStorage.getItem("user"));
    const user_data = user.data.data;
    const token = user.data.token;
    const createdby = user_data.user_uuid;
    const main_uuid = user_data.main_uuid;
    const user_type = user_data.role;
    let listing_per = PermissionCheck("contact", "listing", "", "", "");

    let group_per = listing_per;
    let group_uuid = "";
    if (listing_per === "group") {
        group_uuid = user_data.group_id;
    }

    const oldEmailData = props.emailData;
    const mailType = props.mailType;

    let to_emails = "";
    let to_email = '';
    let body_text = '';
    let email_subject = '';
    let email_attachments = [];

    if(mailType === 'REPLY'){
        if(oldEmailData.replyTo){
            to_emails = { value: oldEmailData.replyTo.value[0].address, label: oldEmailData.replyTo.value[0].address };
            to_email = oldEmailData.replyTo.value[0].address;
        }else{
            to_emails = { value: oldEmailData.from.value[0].address, label: oldEmailData.from.value[0].address };
            to_email = oldEmailData.from.value[0].address;
        }
    }

    if(mailType === 'REPLY'){
        let body_contant = oldEmailData.html ? oldEmailData.html : oldEmailData.text;
        let email_date = moment(oldEmailData.date).format('ddd, DD MMM YYYY [at] HH:mm');
        body_text = ' <br> On '+email_date+', <'+to_email+'> wrote : <blockquote>'+body_contant+'</blockquote>';

        email_subject = 'Re: '+oldEmailData.subject;
    }

    if(mailType === 'FORWARD'){

        let body_contant = oldEmailData.html ? oldEmailData.html : oldEmailData.text;
        let email_date = moment(oldEmailData.date).format('ddd, DD MMM YYYY [at] HH:mm');

        body_text = `\n\n ---------- Forwarded message ---------\n\n From: ${oldEmailData.from.value[0].address}\n` +
            `Date: ${email_date}\n` +
            `Subject: ${oldEmailData.subject}\n` +
            `To: ${oldEmailData.to.value[0].address}\n\n` +
            `${body_contant}`;

        email_subject = 'Fwd: '+oldEmailData.subject;

        if(oldEmailData.attachments){
            oldEmailData.attachments.map((file,index) => {
                email_attachments[index] = {file_name:file.filename,content:file.content};
                return true;
            })            
        }
    }
    

    const [loading, setLoading] = useState(false);
    const [isCc, setIsCc] = useState(false);
    const [isBcc, setIsBcc] = useState(false);
    const [ToContactOptions, setToContactOptions] = useState([]);
    const [contactOptions, setContactOptions] = useState([]);
    const [attachments, setAttachments] = useState([]);
    // const [editorState, setEditorState] = useState(
    //     EditorState.createEmpty()
    // );

    const [editorState, setEditorState] = useState(() => {
        const blocksFromHTML = htmlToDraft(body_text)
        const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        )
    
        return EditorState.createWithContent(contentState)
    });

    const [toEmailValue,setToEmailValue] = useState(to_emails);
    const [mailData,setMailData] = useState({
        to_mail : [to_email],
        cc_mail : '',
        bcc_mail : '',
        subject : email_subject,
        body_text : body_text,
        forward_attachment : email_attachments,
    });

    const [errors,setErrors] = useState({
        to_mail : '',
        subject : '',
        body_text : '',
    });

    const dispatch = useDispatch();

    const closeModel = (value) => {
        props.handleReplyMailModal(value);
    }
   
    useEffect(() => {
        let data = {
            createdby : createdby,
            main_uuid : main_uuid,
            group_uuid : group_uuid,
            user_type : user_type,
            group_per : group_per,
        }
        dispatch(
            contactDropdown(data,token)
        )
          .then((response) => {
            //
            let contacts = [];
            let toConatcts = [];
            response.data.data.map((contact, index) => {
                toConatcts[index] = {label:'<'+contact.first_name+' '+contact.last_name+'>'+contact.email, value: contact.contact_uuid}
                contacts[index] = {label:'<'+contact.first_name+' '+contact.last_name+'>'+contact.email, value: contact.email}
                return true;
            })

            setContactOptions(contacts);
            setToContactOptions(toConatcts);
            return;
          })
          .catch(() => {
            setLoading(false);
          });
    }, []);

    const toggleCc = () => {
        if(isCc){
            setIsCc(false);
        }else{
            setIsCc(true);
        }
    }

    const toggleBcc = () => {
        if(isBcc){
            setIsBcc(false);
        }else{
            setIsBcc(true);
        }
    }

    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file }, status) => { 
        if (status === 'done') { 

            setAttachments([...attachments,file]);
        }
    } 


    const handleBlur = (e) => {

        let new_mailData = {...mailData};
        let error_data = {...errors};


        if (e.target.value.length === 0) {            
            error_data[e.target.name] = 'Field is required.';
        } else {
            error_data[e.target.name] = '';
        }

        new_mailData[e.target.name] = e.target.value;
        setMailData(new_mailData);
        setErrors(error_data);
    }

    const handleSelectTo = (data) => {
        let values = Object.values(data).map(k => k['value']);
        
        let new_mailData = {...mailData};
        new_mailData['to_mail'] = values;

        setMailData(new_mailData);
        setToEmailValue(data);
    }

    const handleSelectCc = (data) => {
        let values = Object.values(data).map(k => k['value']);
        
        let new_mailData = {...mailData};
        new_mailData['cc_mail'] = values;

        setMailData(new_mailData);
    }

    const handleSelectBcc = (data) => {
        let values = Object.values(data).map(k => k['value']);
        
        let new_mailData = {...mailData};
        new_mailData['bcc_mail'] = values;

        setMailData(new_mailData);
    }

    const submitHandler = (e) =>{
        e.preventDefault();

        let error_data = {...errors};
        let isError = "false";

        setLoading(true);

        let body_html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      
        if (mailData.to_mail.length === 0) {
            error_data["to_mail"] = "Field is required.";
            setErrors(error_data);
            isError = "true";
        }

        if (mailData.subject.length === 0) {
            error_data["subject"] = "Field is required.";
            setErrors(error_data);
            isError = "true";
        }

        if (body_html.length === 0) {
            error_data["body_text"] = "Field is required.";
            setErrors(error_data);
            isError = "true";
        }

        if (isError === "true") {
            setLoading(false);
            return;
        } else {

            const formData = new FormData();
            formData.append('to_mail', mailData.to_mail);
            formData.append('cc_mail', mailData.cc_mail);
            formData.append('bcc_mail', mailData.bcc_mail);
            formData.append('subject', mailData.subject);
            formData.append('body_text', body_html);
            formData.append('main_uuid', main_uuid);
            formData.append('createdby', createdby);
            //formData.append('forwardAttachments', mailData.forward_attachment);

            let forwardAttachments = mailData.forward_attachment;

            for (let i = 0; i < forwardAttachments.length; i++) {
                
                const buffer = Buffer.from(forwardAttachments[i].content);
                const blob = new Blob([buffer]);
 
                formData.append('forwardAttachments', blob, forwardAttachments[i].file_name); 
            }

            for (let i = 0; i < attachments.length; i++) {
                formData.append("files", attachments[i]);
            }

            dispatch(
                SendEmail(formData,token)
            )
            .then((response) => {
                setLoading(false);
                closeModel(false);
                return;
            })
            .catch(() => {
                setLoading(false);
            });
        }
    }

    return (
        <Fragment>
            {loading && <Loader />}
            <form action="" onSubmit={(e) => submitHandler(e)}>

                <Modal.Header>
                    <Modal.Title>Reply To Email</Modal.Title>
                    <Button type="submit" variant="primary btn-sm mr-5">Send Email</Button>
                    <Button
                        variant=""
                        className="close"
                        onClick={() => closeModel(false)}
                    >
                        <span>&times;</span>
                    </Button>
                </Modal.Header>
            
                <Modal.Body>
                
                <div className="form-group input-primary">
                    <label className="mb-1">
                        <strong>To:</strong>
                    </label>

                    <CreatableSelect
                        className="form-control bg-white border-primary"
                        name="to_mail"
                        isClearable
                        isMulti
                        onChange={handleSelectTo}
                        options={ToContactOptions}
                        value={toEmailValue}
                      /> 

                    {/* <input type="text" className="form-control bg-transparent" placeholder=" To:"  name="to_mail" onChange={handleBlur}/> */}
                    
                    <a href="#!" className="text-info pull-right" onClick={toggleBcc}>Bcc</a>
                    <a href="#!" className="text-info pull-right mr-3" onClick={toggleCc}>Cc</a>

                    <span className="text-danger">
                          {errors.to_mail}
                    </span>
                </div>
                
                {isCc && 
                    <div className="form-group input-primary">
                        <label className="mb-1">
                            <strong>Cc:</strong>
                        </label>
                        <CreatableSelect
                            className="form-control bg-white border-primary"
                            name="cc_mail"
                            isClearable
                            isMulti
                            onChange={handleSelectCc}
                            options={contactOptions}
                            //value={selectedPersona}
                        /> 

                    </div>
                }

                {isBcc && 
                    <div className="form-group input-primary">
                        <label className="mb-1">
                            <strong>Bcc:</strong>
                        </label>
                        <CreatableSelect
                            className="form-control bg-white border-primary"
                            name="bcc_mail"
                            isClearable
                            isMulti
                            onChange={handleSelectBcc}
                            options={contactOptions}
                            //value={selectedPersona}
                        /> 

                    </div>
                }

                <div className="form-group input-primary">
                    <input type="text" className="form-control bg-transparent" placeholder=" Subject:" name="subject" onChange={handleBlur} value={mailData.subject} />
                    <span className="text-danger">
                          {errors.subject}
                    </span>
                </div>

                <div className="form-group input-primary">
                <div className="border border-primary">
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={editorState => setEditorState(editorState)}
                        //wrapperClassName="demo-wrapper"
                        //editorClassName="demo-editor"
                    />
                </div>

                    {/* <textarea id="email-compose-editor" className="textarea_editor form-control bg-transparent" onChange={handleBlur} name="body_text" value={mailData.body_text} rows="15" placeholder="Enter text ...">
                        
                    </textarea> */}
                    <span className="text-danger">
                          {errors.body_text}
                    </span>
                </div>

                <h5 className="mb-4"><i className="fa fa-paperclip"></i> Attatchment</h5>

                <Dropzone
                    //getUploadParams={getUploadParams}
                    onChangeStatus={handleChangeStatus}
                    //onSubmit={handleFileSubmit}
                    //accept="image/*,audio/*,video/*"
                    styles={{
                        dropzone: {
                           minHeight: 200,
                           maxHeight: 250,
                           width: "100%",
                           backgroundColor: "#f2f4fa",
                           border: "1px dashed #DDDFE1",
                           overflow: "hidden",
                        },
                        inputLabel: {
                           color: "#7e7e7e",
                           fontSize: "18px",
                           fontWeight: "normal",
                           backgroundColor: "#f2f4fa",
                        },
                     }}
                />
                
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        onClick={() => closeModel(false)}
                        variant="danger light btn-sm"
                    >
                        Close
                    </Button>
                    <Button type="submit" variant="primary btn-sm">Send Email</Button>
                </Modal.Footer>
            </form>
        </Fragment>
    );
}

export default ReplyMail;