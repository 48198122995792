import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import EasyEdit, { Types } from "react-easy-edit";
import validator from "validator";
import Select from "react-select";
import "./style.css";
//import { getContactGroups } from "../../actions/contacts";
import { getContactGroupList } from "../../actions/callcampaign";
import Loader from "../../layouts/Loader";

function SectionOverview(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const dispatch = useDispatch();
  //let navigate = useNavigate();

  const dialContact = props.dialContact;
  const [loading, setLoading] = useState(false);
  const [contactGroups, setContactGroups] = useState([]);
  const [contactGroupList, setContactGroupList] = useState([]);

  const save = (value, attribute) => {
    props.save(contactGroups, attribute);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getContactGroupList(main_uuid, createdby, token))
      .then((response) => {
        setContactGroupList(response.data);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });

    if (dialContact.group_uuid !== "" && dialContact.group_uuid) {
      let selected_groups = dialContact.group_uuid.map((group_uuid) => {
        let arr = group_uuid.split(",");
        return { label: arr[1], value: arr[0] };
      });
      setContactGroups(selected_groups);
    }
  }, []);

  const sendEmail = (event) => {
    //alert('send email');
    event.preventDefault();
  };

  const showPhone = (event) => {
    event.preventDefault();
    //window.showWebphone(phoneNumber);
    //JSchangeDialpadStatus(phone_status,caller);
  };

  const CustomDisplay = (props) => {
    const val = props.value;
    return <div>{val}</div>;
  };

  const CustomEdit = (props) => {
    function inputonChange(val) {
      //let data = {...val}
      setContactGroups(val);
      //console.log(data);
      props.setParentValue("group");
    }

    return (
      <div>
        <Select
          className="bg-white"
          options={contactGroupList}
          name="contact_group_uuid"
          placeholder="Select List"
          onChange={inputonChange}
          style={{ height: "35px", width: "100%" }}
          value={contactGroups}
          isSearchable={true}
          tags={true}
          isMulti
        />
      </div>
    );
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <div className="row">
        <div className="col-lg-6 col-md-6 col-6">
          <div className="card">
            <div className="card-body">
              <div className="h6">
                <EasyEdit
                  type={Types.TEXT}
                  onSave={(value) => {
                    save(value, "contact_title");
                  }}
                  //onCancel={cancel}
                  saveButtonLabel={
                    <i className="fa fa-check" aria-hidden="true"></i>
                  }
                  saveButtonStyle="label-primary easy-edit-button rounded-lg"
                  cancelButtonLabel={
                    <i className="fa fa-times" aria-hidden="true"></i>
                  }
                  cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                  attributes={{
                    name: "contact_title",
                    className: "form-control h-75",
                  }}
                  viewAttributes={{ className: "fs-20" }}
                  onValidate={(value) => {
                    return value ? true : false;
                  }}
                  //validationMessage="What just happened?"
                  placeholder="Edit Contact Title"
                  value={dialContact.contact_title}
                />
              </div>

              <div className="h6">
                {dialContact.contact_type === "Individual" ? (
                  <EasyEdit
                    type={Types.TEXT}
                    onSave={(value) => {
                      save(value, "job_title");
                    }}
                    //onCancel={cancel}
                    saveButtonLabel={
                      <i className="fa fa-check" aria-hidden="true"></i>
                    }
                    saveButtonStyle="label-primary easy-edit-button rounded-lg"
                    cancelButtonLabel={
                      <i className="fa fa-times" aria-hidden="true"></i>
                    }
                    cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                    attributes={{
                      name: "job_title",
                      className: "form-control h-75",
                    }}
                    onValidate={(value) => {
                      return value ? true : false;
                    }}
                    //validationMessage="What just happened?"
                    placeholder="Job Title"
                    value={dialContact.job_title}
                  />
                ) : (
                  ""
                )}
              </div>

              <div className="h6">
                {dialContact.contact_type === "Business" ? (
                  <EasyEdit
                    type={Types.TEXT}
                    onSave={(value) => {
                      save(value, "bussiness_name");
                    }}
                    //onCancel={cancel}
                    saveButtonLabel={
                      <i className="fa fa-check" aria-hidden="true"></i>
                    }
                    saveButtonStyle="label-primary easy-edit-button rounded-lg"
                    cancelButtonLabel={
                      <i className="fa fa-times" aria-hidden="true"></i>
                    }
                    cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                    attributes={{
                      name: "bussiness_name",
                      className: "form-control h-75",
                    }}
                    onValidate={(value) => {
                      return value ? true : false;
                    }}
                    //validationMessage="What just happened?"
                    placeholder="Bussiness Name"
                    value={dialContact.bussiness_name}
                  />
                ) : (
                  ""
                )}
              </div>

              <div className="h6">
                <EasyEdit
                  type={Types.TEXT}
                  value={dialContact.first_name + " " + dialContact.last_name}
                  onSave={(value) => {
                    save(value, "first_name");
                  }}
                  displayComponent={<CustomDisplay />}
                  saveButtonLabel={
                    <i className="fa fa-check" aria-hidden="true"></i>
                  }
                  saveButtonStyle="label-primary easy-edit-button rounded-lg"
                  cancelButtonLabel={
                    <i className="fa fa-times" aria-hidden="true"></i>
                  }
                  cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                  attributes={{
                    name: "first_name",
                    className: "form-control h-75",
                  }}
                  placeholder="Contact Name"
                />
              </div>

              <div className="h6">
                <EasyEdit
                  type={Types.TEXT}
                  onSave={(value) => {
                    save(value, "email");
                  }}
                  //onCancel={cancel}
                  saveButtonLabel={
                    <i className="fa fa-check" aria-hidden="true"></i>
                  }
                  saveButtonStyle="label-primary easy-edit-button rounded-lg"
                  cancelButtonLabel={
                    <i className="fa fa-times" aria-hidden="true"></i>
                  }
                  cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                  attributes={{ name: "email", className: "form-control h-75" }}
                  viewAttributes={{ className: "pull-left" }}
                  onValidate={(value) => {
                    return value ? true : false;
                  }}
                  //validationMessage="What just happened?"
                  placeholder="Email"
                  value={dialContact.email}
                />
                {dialContact.email && (
                  <a href="#!" onClick={sendEmail} className="ml-1 text-info">
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                  </a>
                )}
              </div>

              <div className="h6">
                <EasyEdit
                  type={Types.TEXT}
                  onSave={(value) => {
                    save(value, "work_contact_number");
                  }}
                  //onCancel={cancel}
                  saveButtonLabel={
                    <i className="fa fa-check" aria-hidden="true"></i>
                  }
                  saveButtonStyle="label-primary easy-edit-button rounded-lg"
                  cancelButtonLabel={
                    <i className="fa fa-times" aria-hidden="true"></i>
                  }
                  cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                  attributes={{
                    name: "work_contact_number",
                    className: "form-control h-75",
                  }}
                  viewAttributes={{ className: "pull-left" }}
                  onValidate={(value) => {
                    return value ? true : false;
                  }}
                  //validationMessage="What just happened?"
                  placeholder="Work Contact Number"
                  value={dialContact.work_contact_number}
                />
                (Main)
                {dialContact.work_contact_number && (
                  <a href="#!" onClick={showPhone} className="ml-1 text-info">
                    <i className="fa fa-phone" aria-hidden="true"></i>
                  </a>
                )}
                {dialContact.work_contact_number && (
                  <a href="#!" onClick={showPhone} className="ml-1 text-info">
                    <i className="fa fa-comments" aria-hidden="true"></i>
                  </a>
                )}
              </div>

              <div className="h6">
                <EasyEdit
                  type={Types.TEXT}
                  onSave={(value) => {
                    save(value, "address");
                  }}
                  //onCancel={cancel}
                  saveButtonLabel={
                    <i className="fa fa-check" aria-hidden="true"></i>
                  }
                  saveButtonStyle="label-primary easy-edit-button rounded-lg"
                  cancelButtonLabel={
                    <i className="fa fa-times" aria-hidden="true"></i>
                  }
                  cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                  attributes={{
                    name: "address",
                    className: "form-control h-75",
                  }}
                  //viewAttributes={{ class:'pull-left' }}
                  //validationMessage="What just happened?"
                  placeholder="Address"
                  value={dialContact.address}
                />
              </div>

              <div className="h6">
                <EasyEdit
                  type={Types.TEXT}
                  onSave={(value) => {
                    save(value, "country");
                  }}
                  //onCancel={cancel}
                  saveButtonLabel={
                    <i className="fa fa-check" aria-hidden="true"></i>
                  }
                  saveButtonStyle="label-primary easy-edit-button rounded-lg"
                  cancelButtonLabel={
                    <i className="fa fa-times" aria-hidden="true"></i>
                  }
                  cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                  attributes={{
                    name: "country",
                    className: "form-control h-75",
                  }}
                  //viewAttributes={{ class:'pull-left' }}
                  //validationMessage="What just happened?"
                  placeholder="Country"
                  value={dialContact.country}
                />
              </div>
              <div className="h6">
                <EasyEdit
                  type={Types.TEXT}
                  onSave={(value) => {
                    save(value, "city");
                  }}
                  //onCancel={cancel}
                  saveButtonLabel={
                    <i className="fa fa-check" aria-hidden="true"></i>
                  }
                  saveButtonStyle="label-primary easy-edit-button rounded-lg"
                  cancelButtonLabel={
                    <i className="fa fa-times" aria-hidden="true"></i>
                  }
                  cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                  attributes={{ name: "city", className: "form-control h-75" }}
                  //viewAttributes={{ class:'pull-left' }}
                  //validationMessage="What just happened?"
                  placeholder="City"
                  value={dialContact.city}
                />
              </div>
              <div className="h6">
                <EasyEdit
                  type={Types.TEXT}
                  onSave={(value) => {
                    save(value, "facebook_url");
                  }}
                  //onCancel={cancel}
                  saveButtonLabel={
                    <i className="fa fa-check" aria-hidden="true"></i>
                  }
                  saveButtonStyle="label-primary easy-edit-button rounded-lg"
                  cancelButtonLabel={
                    <i className="fa fa-times" aria-hidden="true"></i>
                  }
                  cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                  attributes={{
                    name: "facebook_url",
                    className: "form-control h-75",
                  }}
                  //viewAttributes={{ class:'pull-left' }}
                  //validationMessage="What just happened?"
                  placeholder="Facebook Url"
                  value={dialContact.facebook_url}
                />
              </div>
              <div className="h6">
                <EasyEdit
                  type={Types.TEXT}
                  onSave={(value) => {
                    save(value, "instagram_url");
                  }}
                  //onCancel={cancel}
                  saveButtonLabel={
                    <i className="fa fa-check" aria-hidden="true"></i>
                  }
                  saveButtonStyle="label-primary easy-edit-button rounded-lg"
                  cancelButtonLabel={
                    <i className="fa fa-times" aria-hidden="true"></i>
                  }
                  cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                  attributes={{
                    name: "instagram_url",
                    className: "form-control h-75",
                  }}
                  //viewAttributes={{ class:'pull-left' }}
                  //validationMessage="What just happened?"
                  placeholder="Instagram Url"
                  value={dialContact.instagram_url}
                />
              </div>
              <div className="h6">
                <EasyEdit
                  type={Types.TEXT}
                  onSave={(value) => {
                    save(value, "contact_group");
                  }}
                  editComponent={<CustomEdit />}
                  saveButtonLabel={
                    <i className="fa fa-check" aria-hidden="true"></i>
                  }
                  saveButtonStyle="label-primary easy-edit-button rounded-lg"
                  cancelButtonLabel={
                    <i className="fa fa-times" aria-hidden="true"></i>
                  }
                  cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                  attributes={{
                    name: "contact_group",
                    className: "form-control bg-white h-75",
                  }}
                  //viewAttributes={{ class:'pull-left' }}
                  //displayComponent={<GroupCustomDisplay groups={dialContact.group_name} />}
                  //validationMessage="What just happened?"
                  placeholder="Contact Group"
                  value={
                    dialContact.group_name
                      ? dialContact.group_name.join(",")
                      : ""
                  }
                />
              </div>
              <div className="h6"> Persona: {dialContact.persona} </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-6">
          <div className="card">
            <div className="card-body">
              <div className="h6">Contact Type: {dialContact.contact_type}</div>

              <div className="h6">
                {dialContact.contact_type === "Individual" ? (
                  <EasyEdit
                    type={Types.TEXT}
                    onSave={(value) => {
                      save(value, "job_industry");
                    }}
                    //onCancel={cancel}
                    saveButtonLabel={
                      <i className="fa fa-check" aria-hidden="true"></i>
                    }
                    saveButtonStyle="label-primary easy-edit-button rounded-lg"
                    cancelButtonLabel={
                      <i className="fa fa-times" aria-hidden="true"></i>
                    }
                    cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                    attributes={{
                      name: "job_industry",
                      className: "form-control h-75",
                    }}
                    onValidate={(value) => {
                      return value ? true : false;
                    }}
                    //validationMessage="What just happened?"
                    placeholder="Job Industry"
                    value={dialContact.job_industry}
                  />
                ) : (
                  ""
                )}
              </div>

              <div className="h6">
                {dialContact.contact_type === "Business" ? (
                  <span>
                    <EasyEdit
                      type={Types.TEXT}
                      value={dialContact.bussiness_site}
                      onSave={(value) => {
                        save(value, "bussiness_site");
                      }}
                      saveButtonLabel={
                        <i className="fa fa-check" aria-hidden="true"></i>
                      }
                      saveButtonStyle="label-primary easy-edit-button rounded-lg"
                      cancelButtonLabel={
                        <i className="fa fa-times" aria-hidden="true"></i>
                      }
                      cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                      viewAttributes={{ className: "pull-left mr-2" }}
                      onValidate={(value) => {
                        return validator.isURL(value) ? true : false;
                      }}
                      validationMessage="Enter Valid URL!"
                      placeholder="Website"
                      attributes={{
                        name: "bussiness_site",
                        className: "form-control h-75",
                      }}
                    />
                    {dialContact.bussiness_site && (
                      <a
                        href={dialContact.bussiness_site}
                        target="_blank"
                        className="text-info"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-globe" aria-hidden="true"></i>
                      </a>
                    )}
                  </span>
                ) : (
                  ""
                )}
              </div>

              {/* <div className="h6">Last Name</div> */}
              <div className="h6">Contact Owener: </div>
              <div className="h6">
                <EasyEdit
                  type={Types.TEXT}
                  onSave={(value) => {
                    save(value, "other_contact_number");
                  }}
                  //onCancel={cancel}
                  saveButtonLabel={
                    <i className="fa fa-check" aria-hidden="true"></i>
                  }
                  saveButtonStyle="label-primary easy-edit-button rounded-lg"
                  cancelButtonLabel={
                    <i className="fa fa-times" aria-hidden="true"></i>
                  }
                  cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                  attributes={{
                    name: "other_contact_number",
                    className: "form-control h-75",
                  }}
                  viewAttributes={{ className: "pull-left" }}
                  placeholder="(Other Contact)"
                  onValidate={(value) => {
                    return value ? true : false;
                  }}
                  //validationMessage="What just happened?"
                  value={dialContact.other_contact_number}
                />
                {dialContact.other_contact_number ? "(Cell)" : ""}
              </div>
              <br></br>
              <div className="h6">
                <EasyEdit
                  type={Types.TEXT}
                  onSave={(value) => {
                    save(value, "address2");
                  }}
                  //onCancel={cancel}
                  saveButtonLabel={
                    <i className="fa fa-check" aria-hidden="true"></i>
                  }
                  saveButtonStyle="label-primary easy-edit-button rounded-lg"
                  cancelButtonLabel={
                    <i className="fa fa-times" aria-hidden="true"></i>
                  }
                  cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                  attributes={{
                    name: "address2",
                    className: "form-control h-75",
                  }}
                  //viewAttributes={{ class:'pull-left' }}
                  //validationMessage="What just happened?"
                  placeholder="Address2"
                  value={dialContact.address2}
                />
              </div>

              <div className="h6">
                <EasyEdit
                  type={Types.TEXT}
                  onSave={(value) => {
                    save(value, "state");
                  }}
                  //onCancel={cancel}
                  saveButtonLabel={
                    <i className="fa fa-check" aria-hidden="true"></i>
                  }
                  saveButtonStyle="label-primary easy-edit-button rounded-lg"
                  cancelButtonLabel={
                    <i className="fa fa-times" aria-hidden="true"></i>
                  }
                  cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                  attributes={{ name: "state", className: "form-control h-75" }}
                  //viewAttributes={{ class:'pull-left' }}
                  //validationMessage="What just happened?"
                  placeholder="State"
                  value={dialContact.state}
                />
              </div>

              <div className="h6">
                <EasyEdit
                  type={Types.TEXT}
                  onSave={(value) => {
                    save(value, "zipcode");
                  }}
                  //onCancel={cancel}
                  saveButtonLabel={
                    <i className="fa fa-check" aria-hidden="true"></i>
                  }
                  saveButtonStyle="label-primary easy-edit-button rounded-lg"
                  cancelButtonLabel={
                    <i className="fa fa-times" aria-hidden="true"></i>
                  }
                  cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                  attributes={{
                    name: "zipcode",
                    className: "form-control h-75",
                  }}
                  //viewAttributes={{ class:'pull-left' }}
                  //validationMessage="What just happened?"
                  placeholder="Zipcode"
                  value={dialContact.zipcode}
                />
              </div>

              <div className="h6">
                <EasyEdit
                  type={Types.TEXT}
                  onSave={(value) => {
                    save(value, "linked_in_url");
                  }}
                  //onCancel={cancel}
                  saveButtonLabel={
                    <i className="fa fa-check" aria-hidden="true"></i>
                  }
                  saveButtonStyle="label-primary easy-edit-button rounded-lg"
                  cancelButtonLabel={
                    <i className="fa fa-times" aria-hidden="true"></i>
                  }
                  cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                  attributes={{
                    name: "linked_in_url",
                    className: "form-control h-75",
                  }}
                  //viewAttributes={{ class:'pull-left' }}
                  //validationMessage="What just happened?"
                  placeholder="LinkedIn Url"
                  value={dialContact.linked_in_url}
                />
              </div>

              <div className="h6">
                <EasyEdit
                  type={Types.TEXT}
                  onSave={(value) => {
                    save(value, "twitter_url");
                  }}
                  //onCancel={cancel}
                  saveButtonLabel={
                    <i className="fa fa-check" aria-hidden="true"></i>
                  }
                  saveButtonStyle="label-primary easy-edit-button rounded-lg"
                  cancelButtonLabel={
                    <i className="fa fa-times" aria-hidden="true"></i>
                  }
                  cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                  attributes={{
                    name: "twitter_url",
                    className: "form-control h-75",
                  }}
                  //viewAttributes={{ class:'pull-left' }}
                  //validationMessage="What just happened?"
                  placeholder="Twitter Url"
                  value={dialContact.twitter_url}
                />
              </div>

              {/* <div className="h6">Tabs</div> */}
              <div className="h6">Modified Date: {dialContact.updated_at}</div>
              <div className="h6">Date Created: {dialContact.created_at}</div>
              <div className="h6">
                Outreach Status: {dialContact.outreach_status}
              </div>
              {/* Last Activity Date:  */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SectionOverview;
