import React from "react";
import { Tab, Card, Nav} from "react-bootstrap";

const Cart = (props) => {
  
  const tabChange = (tab_name) => {

    let final_price = 0;
    const newformdata = { ...props.formData };
    if (tab_name === props.formData.plan_data.type) {
      newformdata.cart_data.sub_total = parseFloat(
        props.formData.cart_data.sub_total
      ).toFixed(2);
      newformdata.cart_data.grand_total = parseFloat(
        props.formData.cart_data.grand_total
      ).toFixed(2);
    } else if (tab_name === "yearly") {
      final_price =
        parseFloat(props.formData.plan_data.plan_price) *
        parseFloat(props.formData.plan_data.total_users) *
        11;

      let total =
        parseFloat(props.formData.plan_data.plan_price) *
        parseFloat(props.formData.plan_data.total_users);
      newformdata.cart_data.total = parseFloat(total).toFixed(2);
      newformdata.cart_data.sub_total = parseFloat(final_price).toFixed(2);

      newformdata.cart_data.grand_total = parseFloat(final_price).toFixed(2);
      newformdata.cart_data.months = 11;
    } else if (tab_name === "quarterly") {
      final_price =
        parseFloat(props.formData.plan_data.plan_price) *
        parseFloat(props.formData.plan_data.total_users) *
        parseFloat(3);
      let total =
        parseFloat(props.formData.plan_data.plan_price) *
        parseFloat(props.formData.plan_data.total_users);
      newformdata.cart_data.total = parseFloat(total).toFixed(2);
      newformdata.plan_data.final_price = parseFloat(final_price).toFixed(2);
      newformdata.cart_data.sub_total = parseFloat(final_price).toFixed(2);
      newformdata.cart_data.grand_total = parseFloat(final_price).toFixed(2);
      newformdata.cart_data.months = 1;
    }

    newformdata.plan_data.type = tab_name;
    props.handleCartData(newformdata);
  };

  return (
    <section>
      <Card className="cart_backgournd mt-2">
        <h2 className="text-center mt-3">
          {props.formData.plan_data.plan_name}
        </h2>
        {/* <small className="text-center">
          ${props.formData.plan_data.plan_price} /user/months
        </small> */}
        <h5 className="text-center">
          ${props.formData.plan_data.plan_price} /user/months
        </h5>
        <hr />

        <Tab.Container defaultActiveKey={props.formData.plan_data.type}>
          <Nav as="ul" className="nav-pills justify-content-center">
            <Nav.Item as="li" key="quarterly">
              <Nav.Link
                eventKey="quarterly"
                onClick={() => tabChange("quarterly")}
                className="p-1 pl-3 pr-3"
              >
                Quarterly
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="yearly">
              <Nav.Link
                eventKey="yearly"
                onClick={() => tabChange("yearly")}
                className="p-1 pl-3 pr-3"
              >
                Annual
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content className="pt-4">
            <Tab.Pane eventKey={"quarterly"} key={"quarterly"}>
              <ul className="list-group mb-3">
                <li className="list-group-item d-flex justify-content-between lh-condensed">
                  <div>
                    <h6 className="text-center">
                      {props.formData.plan_data.total_users} user(s)
                      <span>
                        <i className="mdi mdi-close"></i>
                      </span>
                      ${props.formData.plan_data.plan_price}
                    </h6>
                  </div>

                  <h5 className="text-center">
                    ${props.formData.cart_data.total}
                  </h5>
                </li>
                <li className="list-group-item d-flex justify-content-between lh-condensed">
                  <div>
                    <h6 className="text-center">
                      ${props.formData.cart_data.total}
                      <span>
                        <i className="mdi mdi-close"></i>
                      </span>
                      {props.formData.cart_data.months} month(s)
                    </h6>
                  </div>

                  <h5 className="text-center">
                    ${props.formData.cart_data.sub_total}
                  </h5>
                </li>
                <hr className="hr_class" />

                {/* <li className="list-group-item d-flex justify-content-between active">
                                 <div className="text-white">
                                    <h6 className="my-0 text-white">
                                       Promo code
                                    </h6>
                                    <small>EXAMPLECODE</small>
                                 </div>
                                 <span className="text-white">-$5</span>
                              </li> */}
                <li className="list-group-item d-flex justify-content-between">
                  <h4>Grand Total (USD)</h4>
                  <h4>${props.formData.cart_data.grand_total}</h4>
                </li>
              </ul>
            </Tab.Pane>
            <Tab.Pane eventKey={"yearly"} key={"yearly"}>
              <ul className="list-group mb-3">
                <li className="list-group-item d-flex justify-content-between lh-condensed">
                  <div>
                    <h6 className="text-center">
                      {props.formData.plan_data.total_users} user(s)
                      <span>
                        <i className="mdi mdi-close"></i>
                      </span>
                      ${props.formData.plan_data.plan_price}
                    </h6>
                  </div>

                  <h5 className="text-center">
                    ${props.formData.cart_data.total}
                  </h5>
                </li>
                <li className="list-group-item d-flex justify-content-between lh-condensed">
                  <div>
                    <h6 className="text-center">
                      ${props.formData.cart_data.total}
                      <span>
                        <i className="mdi mdi-close"></i>
                      </span>
                      {props.formData.cart_data.months} month(s)
                    </h6>
                  </div>

                  <h5 className="text-center">
                    ${props.formData.cart_data.sub_total}
                  </h5>
                </li>
                <hr className="hr_class" />
                <li className="list-group-item d-flex justify-content-between">
                  <h4>Grand Total (USD)</h4>
                  <h4>${props.formData.cart_data.grand_total}</h4>
                </li>
              </ul>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Card>
    </section>
  );
};

export default Cart;
