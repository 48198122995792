import React, { Fragment, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { notifyError } from "../../Toastr/Toastr";
import { craeteUserMeetingAvailability,updateUserMeetingAvailability } from "../../../actions/calender/UserMeetingAvailability";
import Loader from "../../../layouts/Loader";

function TimeSlotForm(props) {
  
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const updateData = props.updateData;
  const [loading, setLoading] = useState(false);

  const times = [];
  for (let i = 0; i < 24; i++) {
    times.push(
      `${i % 12 || 12}:00 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:15 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:30 ${i < 12 ? "AM" : "PM"}`,
      `${i % 12 || 12}:45 ${i < 12 ? "AM" : "PM"}`
    );
  }

  const [timeSlotData, setTimeSlotData] = useState({
    day: '',
    start_time: '',
    end_time: '',
    createdby : createdby,
    main_uuid : main_uuid,
    action : 'add',
  });

  const dispatch = useDispatch();

  const [errors, setErrors] = useState({
    start_time: "",
    end_time: "",
  });

  useEffect(() => {

    let data = {
      day: updateData.day,
      start_time: updateData.start_time,
      end_time: updateData.end_time,
      createdby : createdby,
      main_uuid : main_uuid,
      action : updateData.action,
    };

    if(updateData.action === 'update'){
       data['user_meeting_availability_uuid'] = updateData.user_meeting_availability_uuid;
    }

    setTimeSlotData(data);
  }, [updateData]);

  const close = (e) => {
    e.preventDefault();
    props.closeForm();
  }

  const handleBlur = (e) => {
    let error_data = { ...errors };

    let time_data = { ...timeSlotData };

    if(e.target.value.length === 0){
        error_data[e.target.name] = 'Field is required.';
        setErrors(error_data);
    }else{
      error_data[e.target.name] = '';
      setErrors(error_data);
    }

    time_data[e.target.name] = e.target.value;
    setTimeSlotData(time_data);
    
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (timeSlotData.start_time.length === 0) {
      error_data["start_time"] = "Field is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (timeSlotData.end_time.length === 0) {
      error_data["end_time"] = "Field is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {

      let data = {...timeSlotData}

      if(timeSlotData.action === 'update'){

        dispatch(updateUserMeetingAvailability(data,token))
        .then((res) => {
          if (res.status === 200) {
  
            props.handleEvent(Math.random);
          } else if (res.status === 500) {
            setLoading(false);
            notifyError("Something went wrong.");
          } else {
            setLoading(false);
            notifyError("Something went wrong.");
          }
        })
        .catch(() => {
          setLoading(false);
        });

      }else{
        
        dispatch(craeteUserMeetingAvailability(data,token))
        .then((res) => {
          if (res.status === 200) {
  
            props.handleEvent(Math.random);
          } else if (res.status === 500) {
            setLoading(false);
            notifyError("Something went wrong.");
          } else {
            setLoading(false);
            notifyError("Something went wrong.");
          }
        })
        .catch(() => {
          setLoading(false);
        });
      }
      
    }
  };


  return (
    <Fragment>
      {loading && <Loader />}
      <form action="" onSubmit={(e) => submitHandler(e)}>
        
        <div className="form-row mt-3"  > 

          <div className="col mt-2 mt-sm-0">

            <div className="form-group input-primary">
              <label className="mb-1">
                <strong>From Time</strong>
                <span className="text-danger">*</span>
              </label>

              <select
                className="form-control bg-white time_field_h pb-0 pt-0"
                name="start_time"
                onChange={handleBlur}
                value={timeSlotData.start_time}
              >
                <option value="">Select From Time</option>
                {times &&
                  times.map((number) => (
                    <option key={number} value={number}>
                      {number}
                    </option>
                  ))}
              </select>

              <span className="text-danger">{errors.start_time}</span>
            </div>
          </div>
    
          <div className="col mt-2 mt-sm-0">
            <div className="form-group input-primary">
              <label className="mb-1">
                <strong>To Time</strong>
                <span className="text-danger">*</span>
              </label>

              <select
                className="form-control bg-white time_field_h pb-0 pt-0"
                name="end_time"
                onChange={handleBlur}
                value={timeSlotData.end_time}
              >
                <option value="">Select To Time</option>
                {times &&
                  times.map((number) => (
                    <option key={number} value={number}>
                      {number}
                    </option>
                  ))}
              </select>

              <span className="text-danger">{errors.end_time}</span>
            </div>
        
          </div>
  
        </div>

                        
        <Button type="submit" variant="primary btn-sm mr-2">
          Save
        </Button>

        <Button type="button" variant="danger btn-sm" onClick={(e) => close(e)}>
          close
        </Button>
      </form>
    </Fragment>
  );
}

export default TimeSlotForm;

