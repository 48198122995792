import { SET_MESSAGE, API_FAIL } from "./types";

import RoleService from "../services/role.service";

export const addRole = (rolename, token, createdby,main_admin_uuid) => (dispatch) => {
  return RoleService.addRole(rolename, token, createdby,main_admin_uuid).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getRoles = (
  main_uuid,
  user_type,
  group_per,
  group_uuid,
  search,
  off_set,
  status,
  limits,
  orderby,
  role_uuid,
  createdby,
  token
) => (dispatch) => {
  return RoleService.getRoles(
    main_uuid,
    user_type,
    group_per,
    group_uuid,
    search,
    off_set,
    status,
    limits,
    orderby,
    role_uuid,
    createdby,
    token
  ).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const deleteRoles = (uuid, createdby, token) => (dispatch) => {
  return RoleService.deleteRoles(uuid, createdby, token).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getRolePermissions = (role_uuid, createdby, token, tab_name) => (
  dispatch
) => {
  return RoleService.getRolePermissions(
    role_uuid,
    createdby,
    token,
    tab_name
  ).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getRoleName = (role_uuid, createdby, token) => (dispatch) => {
  return RoleService.getRoleName(role_uuid, createdby, token).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const updateModulePermission = (
  type,
  action,
  value,
  module_permission_uuid,
  createdby,
  token
) => (dispatch) => {
  return RoleService.updateModulePermission(
    type,
    action,
    value,
    module_permission_uuid,
    createdby,
    token
  ).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getRoleDetail = (roleuuid, createdby, token) => (dispatch) => {
  return RoleService.getRoleDetail(roleuuid, createdby, token).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const updateRole = (rolename, role_uuid, createdby, token) => (
  dispatch
) => {
  return RoleService.updateRole(rolename, role_uuid, createdby, token).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getTab = (role_uuid, createdby, token) => (dispatch) => {
  return RoleService.getTab(role_uuid, createdby, token).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const updateTabPermission = (
  status,
  slug,
  role_uuid,
  createdby,
  token
) => (dispatch) => {
  return RoleService.updateTabPermission(
    status,
    slug,
    role_uuid,
    createdby,
    token
  ).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const updatePermission = (
  tabData,
  permissionData,
  role_uuid,
  createdby,
  token
) => (dispatch) => {
  return RoleService.updatePermission(
    tabData,
    permissionData,
    role_uuid,
    createdby,
    token
  ).then(
    (data) => {
      if (data === 500) {
        return data;
      } else if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const copyRoleAndPermission = (rolename, roleuuid, token, createdby,main_user_uuid) => (
  dispatch
) => {
  return RoleService.copyRoleAndPermission(
    rolename,
    roleuuid,
    createdby,
    main_user_uuid
  ).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getRoleList = (main_uuid,createdby, token) => (dispatch) => {
  return RoleService.getRoleList(main_uuid,createdby, token).then(
    (data) => {
      if (
        data.status === 406 ||
        data.status === 500 ||
        data.status === 200
      ) {
        return data;
      } else {
        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.reject();
      } 
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: API_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
