import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Table, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import PageTitle from "../../layouts/PageTitle";
import { getUsersList } from "../../actions/group";
import Loader from "../../layouts/Loader";

function Users(props) {
  const [UserEvent, setUserEvent] = useState("");

  const params = useLocation();
  let group_uuid = params.state.group_uuid;
  let group_name = params.state.group_name;

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [groupOwnerData, setGroupOwnerData] = useState([]);

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  let search = "";
  let off_set = 0;
  let status = "ACTIVE";
  let limits = 10;
  let orderby = "first_name ASC";
  let group_owner = "Group Owner";
  // let group_id = props.group_id;

  useEffect(() => {
    setLoading(true);
    dispatch(
      getUsersList(
        group_uuid,
        search,
        off_set,
        status,
        limits,
        orderby,
        createdby,
        token
      )
    )
      .then((response) => {
        setUsers(response.data.data.assign_user_data);
        setGroupOwnerData(response.data.data.group_owner_data);
        setUserEvent("");
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, [UserEvent]);

  const breadCurmList = [
    { label: "Roles & Permissions", link: "/roles", is_active: "NO" },
    { label: "Groups", link: "/groups", is_active: "NO" },
    { label: "Group Assign Users", link: "", is_active: "YES" },
  ];

  let str = " : ";
  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Group Users List - {group_name}</h4>
              <div>
                <Link
                  to="/update-group"
                  state={{
                    group_uuid: group_uuid,
                  }}
                  className="btn btn-sm btn-primary mr-2"
                >
                  Edit
                </Link>

                <Button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() => navigate("/groups", { replace: true })}
                >
                  Back
                </Button>
              </div>
            </div>

            <div className="card-body">
              <Table responsive className="w-100">
                <div id="example_wrapper" className="dataTables_wrapper">
                  <table id="example" className="display w-100 dataTable">
                    <thead>
                      <tr role="row">
                        <th>Name</th>
                        <th>Email</th>
                        <th>Status</th>
                      </tr>
                    </thead>

                    <tbody>
                      {groupOwnerData.map((groupowuser) => (
                        <tr key={user.uuid}>
                          <td>
                            <Link
                              to="/user-update"
                              state={{ uuid: groupowuser.uuid }}
                              className="text-success"
                            >
                              {groupowuser.first_name} {groupowuser.last_name} -{" "}
                              {group_owner}{" "}
                              {groupowuser.extension !== null
                                ? str + groupowuser.extension
                                : ""}
                            </Link>
                          </td>
                          <td>{groupowuser.email}</td>
                          <td>{groupowuser.status}</td>
                        </tr>
                      ))}
                      {users.map((user) => (
                        <tr key={user.uuid}>
                          <td>
                            <Link
                              to="/user-update"
                              state={{ uuid: user.uuid }}
                              className="text-success"
                            >
                              {user.first_name} {user.last_name}{" "}
                              {user.extension !== null
                                ? str + user.extension
                                : ""}
                            </Link>
                          </td>
                          <td>{user.email}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Table>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- add role Modal --> */}
    </Fragment>
  );
}

export default Users;
