import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Card, Accordion } from "react-bootstrap";
import { getTab } from "../../actions/plan";
import { useDispatch } from "react-redux";
import ModulesRows from "./ModulesRows";
import Loader from "../../layouts/Loader";

const UiAccordion = (props) => {
  const dispatch = useDispatch();
  
  const [tabData, setTabData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [activeDefault, setActiveDefault] = useState(0);
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;

  const [RoleEvent, setRoleEvent] = useState("");
  const [rowDataa, setRowDataa] = useState([]);

  const handleRoleEvent = (event) => {
    setRoleEvent(event);
  };

  let plan_uuid = "";
  if (props.action === "update") {
    plan_uuid = props.planData.plan_uuid;
  }

  useEffect(() => {
    setLoading(true);
    dispatch(getTab(createdby, token))
      .then((response) => {
        setTabData(response.data);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, [RoleEvent]);

  const rowDataPass = (index) => {
    props.modulePriceData(index);
    //setModuledData(index);
    setRowDataa(index);
  };

  let rowData = {};

  const handleDataFromChild = (data) => {
    props.calFinalPrice(data);
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <Row>
        {/* <!-- Column starts --> */}
        <Col xl="12">
          <Accordion
            className="accordion accordion-primary"
            defaultActiveKey="0"
          >
            {tabData.map((d, i) => (
              <div
                className="accordion__item"
                key={i}
                style={d.slug === "web-phone" ? { pointerEvents: "none" } : {}}
              >
                <div className="row">
                  <div className="col-12">
                    <Accordion.Toggle
                      as={Card.Text}
                      eventKey={`${i}`}
                      className={`accordion__header accordion__header rounded-lg ${
                        activeDefault === i ? "" : "collapsed"
                      }`}
                      onClick={() =>
                        setActiveDefault(activeDefault === i ? -1 : i)
                      }
                    >
                      <span className="accordion__header--text">
                        {d.tab_name}
                      </span>

                      {d.slug !== "web-phone" ? (
                        <span className="accordion__header--indicator"></span>
                      ) : (
                        <></>
                      )}
                    </Accordion.Toggle>
                  </div>
                </div>

                {d.slug !== "web-phone" ? (
                  <Accordion.Collapse eventKey={`${i}`}>
                    <div className="accordion__body--text">
                      <ModulesRows
                        tabName={d.slug}
                        tabPermissions={tabData}
                        handleRoleEvent={handleRoleEvent}
                        i={i}
                        rowDataPass={rowDataPass}
                        rowData={rowData}
                        rowDataa={rowDataa}
                        onData={handleDataFromChild}
                        action={props.action}
                        plan_uuid={plan_uuid}
                      />
                    </div>
                  </Accordion.Collapse>
                ) : (
                  ''
                )}
              </div>
            ))}
          </Accordion>
        </Col>
        {/* <!-- Column ends --> */}
      </Row>{" "}
    </Fragment>
  );
};

export default UiAccordion;
