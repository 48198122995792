import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  Button,
  ButtonGroup,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import PageTitle from "../../../layouts/PageTitle";
import CheckModulePermisson from "../../../common/CheckModulePermisson";
import PermissionCheck from "../../../common/PermissionCheck";
import {
  getSequenceList,
  sequenceStatusChange,
  sequenceDelete,
} from "../../../actions/sequence";
import Loader from "../../../layouts/Loader";
import Pagination from "../../../layouts/Pagination";
import { getUserListDropdown } from "../../../actions/comman";
import "./style.css";
import { notifyInfo, notifyError } from "../../Toastr/Toastr";
import moment from "moment";

function Sequence() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;
  const module_name = "sequence";

  let module_per = CheckModulePermisson(module_name);
  let listing_per = PermissionCheck(module_name, "listing", "", "", "");

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }

  let isPermission = true;
  if (module_per === "" || user_type === "admin") {
    isPermission = true;
  } else {
    isPermission = false;
  }

  //let limits = 10;
  let orderby = "c.created_at DESC";

  const [sequenceEvent, setSequenceEvent] = useState("");

  const [offSet, setOffSet] = useState(1);
  const [totalSequence, setTotalSequence] = useState(0);
  const [limits, setLimits] = useState(10);
  const [sequenceData, setsequenceData] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [users, setUsers] = useState([]);
  const [userUuid, setUserUuid] = useState("");

  const [search, setSearch] = useState("");

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  if (module_per === "" || user_type === "admin") {
    useEffect(() => {
      setLoading(true);

      dispatch(
        getSequenceList(
          userUuid,
          main_uuid,
          search,
          offSet,
          '',
          limits,
          orderby,
          createdby,
          token
        )
      )
        .then((response) => {
          setsequenceData(response.data.data);
          const total = response.data.total;
          setTotalSequence(total);
          setpageCount(Math.ceil(total / limits));
          setSequenceEvent("");

          setLoading(false);
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    }, [sequenceEvent, limits]);
  }

  const handlePageClick = async (data) => {
    setOffSet(limits * data.selected);
    setSequenceEvent(Math.random());
  };

  useEffect(() => {
    dispatch(
      getUserListDropdown(
        "create",
        "",
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        setUsers(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const fnSequenceStatuChange = (uuid, status) => {
    let st = "";
    if (status === "started") {
      st = "pause";
      status = "stoped";
    } else {
      st = "started";
      status = "started";
    }

    confirmAlert({
      title: "",
      message: "Are you sure to this sequence " + st + "?",
      buttons: [
        {
          label: "Yes",
          onClick: () => sequenceStatuChangeService(uuid, status),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const sequenceStatuChangeService = (uuid, status) => {
    setLoading(true);

    dispatch(sequenceStatusChange(uuid, status, createdby, token))
      .then((response) => {
        if (response.status === 200) {
          notifyInfo(response.message);
          setSequenceEvent(Math.random);
          return;
        } else if (response.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fnSequenceDelete = (uuid) => {
    confirmAlert({
      title: "",
      message: "Are you sure to this delete sequence?",
      buttons: [
        {
          label: "Yes",
          onClick: () => SequenceDeleteService(uuid),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const SequenceDeleteService = (uuid) => {
    setLoading(true);

    dispatch(sequenceDelete(uuid, createdby, token))
      .then((response) => {
        if (response.status === 200) {
          notifyInfo(response.message);
          setSequenceEvent(Math.random);
          return;
        } else if (response.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleChangeFilter = (e) => {
    setUserUuid(e.target.value);
    setSequenceEvent(Math.random);
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
    setSequenceEvent(Math.random);
  };

  const chnageLimit = (value) => {
    setLimits(value);
  };

  const breadCurmList = [
    { label: "Home", link: "/", is_active: "NO" },
    {
      label: "Sequence : Total Amount " + totalSequence,
      link: "",
      is_active: "YES",
    },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
      <div className="row">
        <div className="col-6">
          <PageTitle breadCurmList={breadCurmList} />
        </div>
      </div>

      {isPermission ? (
        <>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title w-50">Sequence</h4>
                  <div className="input-group input-success filter_box">
                    <select
                      className="form-control mr-1"
                      name="user_uuid"
                      onChange={(e) => handleChangeFilter(e)}
                      value={userUuid}
                    >
                      <option value="">All</option>
                      {users.map((user) => (
                        <option key={user.uuid} value={user.uuid}>
                          {user.user_name}
                        </option>
                      ))}
                      <option key={createdby} value={createdby}>
                        Owner
                      </option>
                    </select>
                  </div>
                  <div className="input-group input-success search_box">
                    <input
                      className="form-control text-primary"
                      type="search"
                      placeholder="Search Here"
                      aria-label="Search"
                      onChange={(e) => handleChange(e)}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text text-primary">
                        <svg
                          width={20}
                          height={20}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                            fill="#A4A4A4"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <Button
                    type="button"
                    className="btn btn btn-primary ml-2"
                    onClick={() => navigate("/add-sequence", { replace: true })}
                  >
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </Button>
                </div>
                <div className="card-body">
                  Show
                  <ButtonGroup className="ml-2 mr-2">
                    <DropdownButton
                      as={ButtonGroup}
                      id="bg-nested-dropdown"
                      size="sm"
                      title={limits}
                      variant="custom-dropdown"
                    >
                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(10)}
                      >
                        10
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(25)}
                      >
                        25
                      </Dropdown.Item>

                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(50)}
                      >
                        50
                      </Dropdown.Item>

                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(100)}
                      >
                        100
                      </Dropdown.Item>
                    </DropdownButton>
                  </ButtonGroup>
                  entries
                  <Table responsive className="w-100">
                    <thead>
                      <tr role="row">
                        {/* <th>
                          <DropdownButton
                            title=""
                            id="bg-nested-dropdown"
                            size="sm"
                            variant="btn btn-outline-light"
                          >
                            <Dropdown.Item
                              eventKey="1"
                              href="#"
                              // onClick={() => SelectAll("page")}
                            >
                              Select This Page
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="2"
                              href="#"
                              // onClick={() => SelectAll("all")}
                            >
                              Select All
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="3"
                              href="#"
                              // onClick={() => DeSelectAll()}
                            >
                              DeSelect All
                            </Dropdown.Item>
                          </DropdownButton>
                        </th> */}
                        <th>Name</th>
                        <th>Steps</th>
                        <th>Success Rate</th>
                        <th>Created By</th>
                        <th>Date Modified</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {sequenceData.map((sequence) => (
                        <tr key={sequence.sequence_uuid}>
                          {/* <td>
                            <div className="form-check mb-2">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={selectedContacts.selectd_contacts.includes(
                                  contact.contact_uuid
                                )}
                                onChange={(e) =>
                                  checkContact(e, contact.contact_uuid)
                                }
                              />
                              <label className="form-check-label"></label>
                            </div>
                          </td> */}
                          <td>{sequence.title}</td>
                          <td>{sequence.sequence_status.steps}</td>
                          <td>
                            {sequence.sequence_status.success_rate != null
                              ? sequence.sequence_status.success_rate + "%"
                              : ""}
                          </td>

                          <td>
                            {sequence.user.first_name +
                              " " +
                              sequence.user.last_name}
                          </td>
                          <td>
                            {moment(sequence.updatedAt).format(
                              "MM:DD:YYYY, h:mm a"
                            )}
                          </td>
                          <td>
                            {" "}
                            <Button
                              to="#"
                              className="btn btn-info btn-xs"
                              onClick={() =>
                                fnSequenceStatuChange(
                                  sequence.sequence_uuid,
                                  sequence.status
                                )
                              }
                              hidden={PermissionCheck(
                                module_name,
                                "delete",
                                sequence.group_uuid,
                                sequence.user_created_by,
                                sequence.created_by
                              )}
                            >
                              {sequence.status === "started"
                                ? "Pause"
                                : "Start"}
                            </Button>
                            {sequence.status === "stoped" ? (
                              <Button
                                to="#"
                                className="btn btn-info btn-xs ml-2"
                                onClick={() =>
                                  fnSequenceDelete(sequence.sequence_uuid)
                                }
                                hidden={PermissionCheck(
                                  module_name,
                                  "delete",
                                  sequence.group_uuid,
                                  sequence.user_created_by,
                                  sequence.created_by
                                )}
                              >
                                Delete
                              </Button>
                            ) : (
                              <></>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Pagination
                    pageCount={pageCount}
                    handlePageNumberClick={handlePageClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Manage Sequence</h4>
              </div>
              <div className="card-body">
                <h3>You don't have access to this modules</h3>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Sequence;
