import React, { Fragment, useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from "../../layouts/Loader";
import { getRoleList } from "../../actions/role";
import { createUser, updateUser } from "../../actions/user";
import { notifyInfo, notifyError } from "../Toastr/Toastr";

function UserForm(props) {
  const closeModel = (arg) => {
    props.closeModel(arg);
  };

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    role_uuid: "",
    job_title: "",
    main_user_uuid: main_uuid,
    createdby: createdby,
  });

  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    role_uuid: "",
    job_title: "",
  });

  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBlur = (e) => {
    const newUserData = { ...userData };
    let error_data = { ...errors };

    if (e.target.name === "first_name") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "First Name is required.";
      } else {
        if (/^[a-z][a-z\s]*$/i.test(e.target.value)) {
          error_data[e.target.name] = "";
        } else {
          error_data[e.target.name] = "First Name is invalid.";
        }
      }
      newUserData[e.target.name] = e.target.value;
      setErrors(error_data);
    }

    if (e.target.name === "last_name") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Last Name is required.";
      } else {
        if (/^[a-z][a-z\s]*$/i.test(e.target.value)) {
          error_data[e.target.name] = "";
        } else {
          error_data[e.target.name] = "Last Name is invalid.";
        }
      }
      newUserData[e.target.name] = e.target.value;
      setErrors(error_data);
    }

    if (e.target.name === "email") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "First Name is required.";
      } else {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
          error_data[e.target.name] = "Email is invalid.";
        } else {
          error_data[e.target.name] = "";
        }
      }
      newUserData[e.target.name] = e.target.value;
      setErrors(error_data);
    }

    if (e.target.name === "role_uuid") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Last Name is required.";
      } else {
        error_data[e.target.name] = "";
      }
      newUserData[e.target.name] = e.target.value;
      setErrors(error_data);
    }

    if (e.target.name === "job_title") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Job title is required.";
      } else {
        error_data[e.target.name] = "";
      }
      newUserData[e.target.name] = e.target.value;
      setErrors(error_data);
    }

    setUserData(newUserData);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (userData.first_name === "") {
      error_data["first_name"] = "First name is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (userData.last_name === "") {
      error_data["last_name"] = "Last name is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (userData.email === "") {
      error_data["email"] = "Email is required.";
      setErrors(error_data);
      isError = "true";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userData.email)
    ) {
      error_data["email"] = "Invalid email address.";
      setErrors(error_data);
      isError = "true";
    }

    if (userData.role_uuid === "") {
      error_data["role_uuid"] = "Please select role.";
      setErrors(error_data);
      isError = "true";
    }

    if (userData.job_title === "") {
      error_data["job_title"] = "Job title is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create user API

      if (props.user) {
        dispatch(createUser(userData, token))
          .then((res) => {
            props.handleUserEvent("added");

            if (res.status === 200) {
              closeModel(false);
              notifyInfo(res.message);
              navigate("/users", { replace: true });
            } else if (res.status === 406) {
              error_data["email"] = res.message;
              setErrors(error_data);
              isError = "true";
            } else if (res.status === 500) {
              notifyError("Something went wrong.");
            } else {
              notifyError("Something went wrong.");
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        const data = { ...userData };
        data["useruuid"] = props.user.uuid;
        dispatch(updateUser(data))
          .then(() => {
            closeModel(false);
            props.handleUserEvent("updated");
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    setLoading(true);
    dispatch(getRoleList(main_uuid, createdby, token))
      .then((response) => {
        setRoles(response.data);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Fragment>
      {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>Invite New User</Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <form action="" onSubmit={(e) => submitHandler(e)}>
        <Modal.Body>
          <div className="row">
            <div className="col-sm">
              <div className="form-group input-primary">
                <label className="mb-1">
                  <strong>First Name</strong>
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control bg-white"
                  placeholder="First Name"
                  name="first_name"
                  id="first_name"
                  value={userData.first_name}
                  onChange={handleBlur}
                />
                <span className="text-danger">{errors.first_name}</span>
              </div>
            </div>

            <div className="col-sm">
              <div className="form-group input-primary">
                <label className="mb-1">
                  <strong>Last Name</strong>
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control bg-white"
                  placeholder="Last Name"
                  name="last_name"
                  id="last_name"
                  value={userData.last_name}
                  onChange={handleBlur}
                />
                <span className="text-danger">{errors.last_name}</span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm">
              <div className="form-group input-primary">
                <label className="mb-1">
                  <strong>Email</strong>
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  className="form-control bg-white"
                  placeholder="hello@example.com"
                  name="email"
                  value={userData.email}
                  onChange={handleBlur}
                />
                <span className="text-danger">{errors.email}</span>
              </div>
            </div>

            <div className="col-sm">
              <div className="form-group input-primary">
                <label className="mb-1">
                  <strong>Role</strong>
                  <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control bg-white"
                  value={userData.role_uuid}
                  name="role_uuid"
                  onChange={handleBlur}
                >
                  <option value="">Select Role</option>
                  {roles.map((role) => (
                    <option key={role.role_uuid} value={role.role_uuid}>
                      {role.role_name}
                    </option>
                  ))}
                </select>
                <span className="text-danger">{errors.role_uuid}</span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm">
              <div className="form-group input-primary">
                <label className="mb-1">
                  <strong>Job Title</strong>
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control bg-white"
                  name="job_title"
                  value={userData.job_title}
                  onChange={handleBlur}
                />
                <span className="text-danger">{errors.job_title}</span>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => closeModel(false)}
            variant="danger light btn-sm"
          >
            Close
          </Button>
          <Button type="submit" variant="primary btn-sm">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Fragment>
  );
}

export default UserForm;
