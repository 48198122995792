import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Table,
  Button,
  ButtonGroup,
  DropdownButton,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import PageTitle from "../../layouts/PageTitle";
import Loader from "../../layouts/Loader";
import Pagination from "../../layouts/Pagination";
import { getRinggroups, deleteRingGroup } from "../../actions/ringgroups";
import { checkModuleAssign } from "../../actions/comman";
import FlashMessage from "../../layouts/FlashMessage";
import CheckModulePermisson from "../../common/CheckModulePermisson";
import PermissionCheck from "../../common/PermissionCheck";
import Tooltip from "rc-tooltip";

function RingGroups() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;
  const module_name = "ring group";

  let module_per = CheckModulePermisson(module_name);

  let isPermission = true;
  if (module_per === "" || user_type === "admin") {
    isPermission = true;
  } else {
    isPermission = false;
  }

  const dispatch = useDispatch();
  let navigate = useNavigate();

  let listing_per = PermissionCheck(module_name, "listing", "", "", "");
  let add_per = PermissionCheck(module_name, "add", "", "", "");

  if (listing_per === "none") {
    navigate("/error-403");
  }

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }

  let search = "";
  let orderby = "ring_group_extension ASC";

  const [limits, setLimits] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [ringgroups, setRinggroups] = useState([]);
  const [ringgroupEvent, setRinggroupEvent] = useState("");
  const [pageCount, setpageCount] = useState(0);
  const [offSet, setOffSet] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteConfirmData, setDeleteConfirmData] = useState([]);

  const [loading, setLoading] = useState(false);

  if (module_per === "" || user_type === "admin") {
    useEffect(() => {
      setLoading(true);
      dispatch(
        getRinggroups(
          user_type,
          group_per,
          group_uuid,
          search,
          offSet,
          limits,
          orderby,
          main_uuid,
          createdby,
          token
        )
      )
        .then((response) => {
          setRinggroups(response.data.data);
          const total = response.data.total;
          setTotalRecords(total);

          setpageCount(Math.ceil(total / limits));
          setRinggroupEvent("");
          setLoading(false);
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    }, [ringgroupEvent, limits]);
  }

  const deleteRingGroups = (ring_group_uuid) => {
    setLoading(true);
    dispatch(
      checkModuleAssign(
        "ring_group",
        ring_group_uuid,
        "destination",
        createdby,
        token
      )
    )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            setDeleteConfirmData(response.data);
            setDeleteModal(true);
            setLoading(false);
          } else {
            setLoading(false);
            confirmAlert({
              title: "",
              message: "Are you sure to delete this ring group?",
              buttons: [
                {
                  label: "Yes",
                  onClick: () => deleteRingGroupService(ring_group_uuid),
                },
                {
                  label: "No",
                },
              ],
            });
          }

          return;
        } else if (response.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const deleteRingGroupService = (ring_group_uuid) => {
    setLoading(true);
    dispatch(deleteRingGroup(ring_group_uuid, createdby, token))
      .then((response) => {
        if (response.status === 200) {
          notifyInfo(response.message);
          setRinggroupEvent("delete");
          setLoading(false);
          return;
        } else if (response.status === 500) {
          setLoading(false);
          notifyError("Something went wrong.");
        } else {
          setLoading(false);
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handlePageClick = async (data) => {
    setOffSet(limits * data.selected);
    setRinggroupEvent(Math.random);
  };

  const CustomDisplay = (props) => {
    let ringgroup = props.ringgroup;

    if (ringgroup.ring_group_fail_destination_type === "ivr") {
      return "Auto Attendent - " + ringgroup.ivr_menu_name;
    } else if (ringgroup.ring_group_fail_destination_type === "extention") {
      return "Extention - " + ringgroup.extension;
    } else if (ringgroup.ring_group_fail_destination_type === "ring-group") {
      return "Ring Group - " + ringgroup.ring_destination;
    } else if (ringgroup.ring_group_fail_destination_type === "did") {
      return "Inbound Number - " + ringgroup.did_number;
    } else if (ringgroup.ring_group_fail_destination_type === "voicemail") {
      return "Voicemail - " + ringgroup.voicemail_id;
    } else if (ringgroup.ring_group_fail_destination_type === "time_condition") {
      return "Time Condition - " + ringgroup.time_condition_name;
    } else {
      return "None";
    }
  };

  const Extensions = (props) => {
    let ringgroup_destinations = props.ringgroup_destinations;

    if (ringgroup_destinations.length > 0) {
      return (
        <div>
          {ringgroup_destinations.map((destination, index) => {
            return (
              <div key={index}>
                {destination.destination_type === "extension"
                  ? destination.extension
                  : destination.destination_number}
              </div>
            );
          })}
        </div>
      );
    } else {
      return "None";
    }
  };

  const chnageLimit = (value) => {
    setLimits(value);
  };

  const breadCurmList = [
    { label: "Calls", link: "/", is_active: "NO" },
    { label: "RingGroups", link: "", is_active: "YES" },
    { label: "Total Amount :" + totalRecords, link: "", is_active: "YES" },
  ];

  return (
    <Fragment>
      <FlashMessage />
      {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />

      {isPermission ? (
        <>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Manage Ring Groups</h4>
                  {add_per === "yes" ? (
                    <Button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={() =>
                        navigate("/add-ringgroup", { replace: true })
                      }
                    >
                      Add New Ring Group
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="card-body">
                  Show
                  <ButtonGroup className="ml-2 mr-2">
                    <DropdownButton
                      as={ButtonGroup}
                      id="bg-nested-dropdown"
                      size="sm"
                      title={limits}
                      variant="custom-dropdown"
                    >
                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(10)}
                      >
                        10
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(25)}
                      >
                        25
                      </Dropdown.Item>

                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(50)}
                      >
                        50
                      </Dropdown.Item>

                      <Dropdown.Item
                        eventKey="1"
                        href="#"
                        onClick={() => chnageLimit(100)}
                      >
                        100
                      </Dropdown.Item>
                    </DropdownButton>
                  </ButtonGroup>
                  entries
                  <Table responsive className="w-100">
                    <thead>
                      <tr role="row">
                        <th>ID</th>
                        <th>Name</th>
                        <th>Strategy</th>
                        <th>Extensions</th>
                        <th>FailOver</th>
                        {listing_per === "group" || user_type === "admin" ? (
                          <th>Created By</th>
                        ) : (
                          <></>
                        )}
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {ringgroups.map((ringgroup) => (
                        <tr key={ringgroup.ring_group_uuid}>
                          <td>{ringgroup.ring_group_extension}</td>
                          <td>
                            <Link
                              to="/view-ringgroup"
                              state={{
                                ring_group_uuid: ringgroup.ring_group_uuid,
                                activeTab: "general",
                              }}
                              className="text-blue"
                            >
                              {ringgroup.ring_group_name}
                            </Link>
                          </td>

                          <td>
                            {ringgroup.ring_group_strategy.replace(/_/g, " ")}
                          </td>
                          <td>
                            <Tooltip
                              placement="top"
                              overlay={
                                <Extensions
                                  ringgroup_destinations={
                                    ringgroup.ringgroup_destination_data
                                  }
                                />
                              }
                            >
                              <span className="text-blue font-weight-bold">
                                {ringgroup.extensions}
                              </span>
                            </Tooltip>
                          </td>
                          <td>
                            <CustomDisplay ringgroup={ringgroup} />
                          </td>
                          {listing_per === "group" ||
                          listing_per === "all" ||
                          user_type === "admin" ? (
                            <td>
                              {ringgroup.first_name} {ringgroup.last_name}
                            </td>
                          ) : (
                            <></>
                          )}
                          <td>
                            <Fragment>
                              <div className="d-flex">
                                {
                                  <Link
                                    to="/view-ringgroup"
                                    state={{
                                      ring_group_uuid:
                                        ringgroup.ring_group_uuid,
                                      activeTab: "general",
                                    }}
                                    hidden={PermissionCheck(
                                      module_name,
                                      "edit",
                                      ringgroup.group_uuid,
                                      ringgroup.user_created_by,
                                      ringgroup.created_by
                                    )}
                                    className="btn btn-primary shadow btn-xs sharp mr-1"
                                  >
                                    <i className="fa fa-pencil"></i>
                                  </Link>
                                }
                                <Link
                                  to="#"
                                  onClick={() =>
                                    deleteRingGroups(ringgroup.ring_group_uuid)
                                  }
                                  hidden={PermissionCheck(
                                    module_name,
                                    "delete",
                                    ringgroup.group_uuid,
                                    ringgroup.user_created_by,
                                    ringgroup.created_by
                                  )}
                                  className="btn btn-danger shadow btn-xs sharp"
                                >
                                  <i className="fa fa-trash"></i>
                                </Link>
                              </div>
                            </Fragment>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Pagination
                    pageCount={pageCount}
                    handlePageNumberClick={handlePageClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Manage RInfGroups</h4>
              </div>
              <div className="card-body">
                <h3>You have don't access this modules</h3>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal className="fade bd-example-modal-lg" show={deleteModal} size="md">
        <Modal.Body>
          <p>
            This ring group is assign to following module. Please, remove from
            that to delete.
          </p>
          {deleteConfirmData.map((row) => (
            <div key={Math.random}>
              <span>
                <b>{row.module}</b>: {row.value}
              </span>
            </div>
          ))}

          <Button
            onClick={() => setDeleteModal(false)}
            variant="primary light btn-sm pull-right"
          >
            Close
          </Button>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}

export default RingGroups;
