import React, { Fragment,useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Table } from "react-bootstrap";

import { getHistorySmsLog } from "../../actions/sms";

const Sms = ({ contact }) => {

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  
  const dispatch = useDispatch();
  const [contactsSms, setContactsSms] = useState([]);

  useEffect(() => {
    dispatch(
      getHistorySmsLog(contact.contact_uuid, main_uuid, createdby, token)
    )
      .then((response) => {
        setContactsSms(response.data);
        return;
      })
      .catch(() => {
      });
  }, [contact]);


  return (

    <Fragment>

      <div className="card">
        <div className="card-body">
          <Table responsive className="w-100">
            <thead>
              <tr role="row">
                <th>From Number</th>
                <th>Message Text</th>
                <th>Date Created</th>
              </tr>
            </thead>

            {contactsSms.map((sms) => (
                <tbody key={sms.sms_campaign_log_uuid}>
                  <tr role="row">
                    <td>{sms.from_number}</td>
                    <td>{sms.message_text}</td>

                    <td>{sms.date_time}</td>
                  </tr>
                </tbody>
            ))}

          </Table>

        </div>
    </div>
    
  </Fragment> 

  );
};

export default Sms;
