import React, {Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Table } from "react-bootstrap";
import {
  getContactEmails
} from "../../actions/contacts";
import Pagination from "../../layouts/Pagination";
import Loader from "../../layouts/Loader";

const Emails = ({contact}) => {

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;

  const [contactEmails, setContactEmails] = useState([]);
  const [emailEvent, setEmailEvent] = useState(false);
  
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [pageCount, setpageCount] = useState(0);
  const [offSet, setOffSet] = useState(0);
  const limits = 10;
  
  useEffect(() => {
    setLoading(true);
    dispatch(
      getContactEmails(
        createdby,
        token,
        contact.contact_uuid,
        offSet,
        limits,
      )
    )
    .then((response) => {
      
      setContactEmails(response.data.data);

      const total = response.data.total;

      setpageCount(Math.ceil(total / limits));

      setLoading(false);
      return;
    })
    .catch(() => {
      setLoading(false);
    });

  }, [contact,emailEvent]);

  const handlePageClick = async (data) => {

    setOffSet(limits * data.selected);
    setEmailEvent(Math.random());
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <div className="card">
        <div className="card-body">
        
          <Table responsive className="w-100">
            <thead>
              <tr role="row">
                <th>DateTime</th>
                <th>Description</th>
                <th>From Email</th>      
              </tr>
            </thead>

            {contactEmails && contactEmails.map((row) => (
                <tbody key={row.contact_activity_uuid}>
                  <tr role="row">                    
                    <td>{row.datetime} </td>
                    <td>{row.description} </td>
                    {row.activity_type === 'EMAIL' && <td>{row.log_from_email} </td>}
                    {row.activity_type === 'EMAIL_CAMPAIGN' && <td>{row.campaign_from_email} </td>}                    
                  </tr>
                </tbody>
            ))}

          </Table>

          <Pagination
            pageCount={pageCount}
            handlePageNumberClick={handlePageClick}
          />
        </div>
      </div>

    </Fragment>    
  );
};

export default Emails;
