import React from "react";
import OnboardingList from "./OnboardingList";

const StepThree = (props) => {
  return (
    <section>
      <OnboardingList
        handleThreeData={props.handleThreeData}
        currentStep={props.currentStep}
        stepData={props.stepData}
      />
    </section>
  );
};

export default StepThree;
