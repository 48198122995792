import React, { Fragment, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../layouts/PageTitle";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import { getDidRouting, updateDIDRouting } from "../../actions/did";
import { getDestinationTypeList } from "../../actions/comman";
import "./style.css";
import Loader from "../../layouts/Loader";

function DidRoute() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;

  const params = useLocation();
  const did_uuid = params.state.did_uuid;

  const [loading, setLoading] = useState(false);

  const [didRoute, setDidRoute] = useState({
    did_uuid: did_uuid,
    did_action_type: "",
    did_action_uuid: "",
    createdby: createdby,
    main_uuid: user_data.main_uuid,
  });

  const [errors, setErrors] = useState({
    did_action_type: "",
    did_action_uuid: "",
  });

  const [options, setOptions] = useState([]);
  const [did, setDid] = useState("");

  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDidRouting(createdby, did_uuid, token))
      .then((response) => {
        if (response.data !== "") {
          getoptions(response.data[0].did_action_type);
          setDid(response.data[0].did_number);

          let new_data = { ...didRoute };
          new_data["did_action_type"] = response.data[0].did_action_type;
          new_data["did_action_uuid"] = response.data[0].did_action_uuid;

          setDidRoute(new_data);
        }

        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const getoptions = (type) => {
    const main_uuid = user_data.main_uuid;

    dispatch(getDestinationTypeList(createdby, token, main_uuid, type))
      .then((response) => {
        setOptions(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (didRoute.did_action_type.length === 0) {
      error_data["area_code"] = "DID action type is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (didRoute.did_action_uuid.length === 0) {
      error_data["area_code"] = "DID action is required.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create user API
      dispatch(updateDIDRouting(didRoute,token))
        .then((response) => {
          if (response.status === 200) {
            notifyInfo(response.message);
            navigate("/did", { replace: true });
          } else if (response.status === 500) {
            notifyError("Something went wrong.");
          } else {
            notifyError("Something went wrong.");
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const handleChange = (e) => {
    let error_data = { ...errors };
    if (e.target.name === "did_action_type") {
      getoptions(e.target.value);
    }

    if (e.target.value.length === 0) {
      error_data[e.target.name] = e.target.name + " is required.";
      setErrors(error_data);
    } else {
      error_data[e.target.name] = " ";
      setErrors(error_data);
    }

    let new_data = { ...didRoute };
    new_data[e.target.name] = e.target.value;

    setDidRoute(new_data);
    return;
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle activeMenu="DID Routing" motherMenu="DID" />
      <div className="row">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">DID Routing : {did}</h4>
            <Button
              type="button"
              className="btn btn-default btn-sm"
              onClick={() => navigate("/did", { replace: true })}
            >
              <i className="fa fa-reply" aria-hidden="true"></i> Back
            </Button>
          </div>
          <div className="card-body">
            <form action="" onSubmit={(e) => submitHandler(e)}>
              <div className="row">
                <div className="col-sm text-center">
                  <b>DID : {did}</b>
                </div>
              </div>

              <div className="row">
                <div className="col-sm text-center mt-2 mb-2">
                  <b>
                    <i class="fa fa-arrow-down" aria-hidden="true"></i>
                  </b>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-4"></div>
                <div className="col-sm-4 text-center">
                  <div className="form-group ">
                    <label>Destination Type</label>
                    <select
                      className="form-control"
                      name="did_action_type"
                      onChange={(e) => handleChange(e)}
                      value={didRoute.did_action_type}
                    >
                      <option value="">Select Destination Type</option>
                      <option value="ivr">IVR</option>
                      <option value="extention">Extention</option>
                      <option value="did">Did</option>
                      <option value="voicemail">Voicemail</option>
                      <option value="time_condition">Time Condition</option>
                    </select>
                    <span className="text-danger">
                      {errors.did_action_type}
                    </span>
                  </div>
                </div>
                <div className="col-sm-4"></div>
              </div>

              <div className="row">
                <div className="col-sm text-center mt-2 mb-2">
                  <b>
                    <i class="fa fa-arrow-down" aria-hidden="true"></i>
                  </b>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-4"></div>
                <div className="col-sm-4 text-center">
                  <div className="form-group">
                    <label>Destination</label>
                    <select
                      className="form-control"
                      name="did_action_uuid"
                      onChange={(e) => handleChange(e)}
                      value={didRoute.did_action_uuid}
                    >
                      <option value="">Select Destination</option>
                      {options &&
                        options.map((number) => (
                          <option key={number.value} value={number.value}>
                            {number.text}
                          </option>
                        ))}
                    </select>
                    <span className="text-danger">
                      {errors.did_action_uuid}
                    </span>
                  </div>
                </div>
                <div className="col-sm-4"></div>
              </div>

              <div className="row text-right">
                <div className="col-sm">
                  <Button type="submit" variant="primary" className="btn-sm">
                    Save
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default DidRoute;
