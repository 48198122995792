import React,{useState,useEffect} from "react";

function FlashMessage(props) {

    const [message, setMessage] = useState();
    const [type, setType] = useState('success');

    let msg = localStorage.getItem("FlashMessage");
    let messageType = localStorage.getItem("messageType");

    useEffect(() => {
    
        if(msg && msg !== ""){
            setType(messageType);
            setMessage(msg);      
        }else{
            setType('');
        setMessage('');
        
        }
    })

    return (
        <div className="form-group">
            {type === 'success' &&

                <div className="alert alert-primary alert-dismissible fade show" role="alert">
                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="mr-2"><circle cx="12" cy="12" r="10"></circle><path d="M8 14s1.5 2 4 2 4-2 4-2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
                        {message}
                    <button type="button" className="close h-100" data-dismiss="alert" aria-label="Close"><span><i className="mdi mdi-close"></i></span></button>
                </div>

            }

            {type === 'error' &&

            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="mr-2"><circle cx="12" cy="12" r="10"></circle><path d="M8 14s1.5 2 4 2 4-2 4-2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
                    {message}
                <button type="button" className="close h-100" data-dismiss="alert" aria-label="Close"><span><i className="mdi mdi-close"></i></span></button>
            </div>

            }
        </div>
    );
    
}

export default FlashMessage;