import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getNumberMappingFields } from "../../actions/blockednumbers";

function StepTwo(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;

  const dispatch = useDispatch();

  let filename = props.fileName;
  let errors = props.errors;

  const [headerName, setHeaderName] = useState([]);

  useEffect(() => {
    dispatch(getNumberMappingFields(createdby, token, filename))
      .then((response) => {
        setHeaderName(response.data.csvheader);
        return;
      })
      .catch(() => {
        //setLoading(false);
      });
  }, []);

  return (
    <Fragment>
      <div className="row pl-5 pr-5">
        <div className="col-sm">
          <div className="form-group">
            <label className="mb-1">
              <strong>Number</strong>
            </label>
          </div>
        </div>
        <div className="col-sm">
          <div className="form-group">
            <select
              className="form-control"
              name={"number"}
              onChange={props.handleBlur}
            >
              <option value="">Select CSV Field</option>
              {headerName &&
                headerName.map((names, i) => (
                  <option key={names} value={i}>
                    {names}
                  </option>
                ))}
            </select>
            <span className="text-danger">{errors.number}</span>
          </div>
        </div>
      </div>

      <div className="row pl-5 pr-5">
        <div className="col-sm">
          <div className="form-group">
            <label className="mb-1">
              <strong>Description</strong>
            </label>
          </div>
        </div>
        <div className="col-sm">
          <div className="form-group">
            <select
              className="form-control"
              name={"description"}
              onChange={props.handleBlur}
            >
              <option value="">Select CSV Field</option>
              {headerName &&
                headerName.map((names, i) => (
                  <option key={names} value={i}>
                    {names}
                  </option>
                ))}
            </select>
            <span className="text-danger">{errors.description}</span>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default StepTwo;
