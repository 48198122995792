import React, { Fragment, useState, useEffect } from "react";
import { Tab, Nav } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../layouts/PageTitle";
import Loader from "../../layouts/Loader";
import { notifyInfo, notifyError } from "../Toastr/Toastr";
import GeneralInfo from "./GeneralInfo";
import E911 from "./E911";
import ViewEmailSignature from "../emailSignature/ViewEmailSignature";
import EmailConfigForm from "../AppsMenu/AppProfile/EmailConfigForm";
import "./style.css";
import {
  getUserDetail,
  updateUserStatus,
  updateReInvite,
} from "../../actions/user";
import { getGroupList } from "../../actions/group";
import {
  getNumberListDropdown,
  getExtensionListDropdown,
  getLanguageList,
  getTimezone,
} from "../../actions/comman";
import {
  getAvailabilityDetails,
  updateTimeSlot,
  deleteTimeSlot,
} from "../../actions/useravailability";
import Availability from "./Availability";
import { getRoleList } from "../../actions/role";
import PermissionCheck from "../../common/PermissionCheck";
import EasyEdit, { Types } from "react-easy-edit";
import Tooltip from "rc-tooltip";
import { confirmAlert } from "react-confirm-alert";

function ViewUser() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;

  let listing_per = PermissionCheck("did", "listing", "", "", "");

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }

  const params = useLocation();
  const useruuid = params.state.useruuid;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [e911Data, setE911Data] = useState({});
  const [roles, setRoles] = useState([]);
  const [numbers, setNumbers] = useState([]);
  const [extensions, setExtensions] = useState([]);
  const [timeZones, setTimeZones] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [userEvent, setUserEvent] = useState("");

  const [groups, setGroups] = useState([]);
  //const [extensionData, setExtensionData] = useState({});
  const extensionData = {};
  const [timeconditionsday, setTimeconditionsday] = useState([]);

  const [timeSlotOption, setTimeSlotOption] = useState({
    user_availability_times_uuid: "",
    extension_uuid: "",
    user_availability_data_uuid: "",
    from_time: "",
    to_time: "",
    route_to: "",
    route_type: "",
    route_data: "",
    route_to_name: "",
  });

  const [defaultDay, setDefaultDay] = useState("");

  const changeUserEvent = (val) => {
    setUserEvent(val);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getUserDetail(useruuid, createdby, token))
      .then((response) => {
        setUserData(response.data.user);
        setE911Data(response.data.user_e911);

        if (response.data.user.assign_extension !== "") {
          dispatch(getAvailabilityDetails(useruuid, createdby, token))
            .then((response1) => {
              setTimeconditionsday(response1.data.availability_data);

              if (
                response1.data.availability_data[0] !== undefined &&
                defaultDay === ""
              ) {
                setDefaultDay(
                  response1.data.availability_data[0]
                    .user_availability_data_uuid
                );
              }
              setLoading(false);
              return;
            })
            .catch(() => {
              setLoading(false);
            });
        }
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, [userEvent]);

  useEffect(() => {
    setLoading(true);
    dispatch(getRoleList(main_uuid, createdby, token))
      .then((response) => {
        let arr = response.data.map((obj, i) => {
          return { label: obj.role_name, value: obj.role_uuid };
        });
        setRoles(arr);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(getTimezone(main_uuid, createdby, token))
      .then((response) => {
        let arr = response.data.data.map((obj, i) => {
          return { label: obj.timezone_name, value: obj.time_zone_uuid };
        });
        setTimeZones(arr);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(getLanguageList(main_uuid, createdby, token))
      .then((response) => {
        let arr = response.data.data.map((obj, i) => {
          return { label: obj.language, value: obj.language };
        });
        setLanguages(arr);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getNumberListDropdown(
        "update",
        useruuid,
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        setNumbers(response.data);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getExtensionListDropdown(
        "update",
        userData.assign_extension,
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        createdby,
        token
      )
    )
      .then((response) => {
        let arr = response.data.map((obj, i) => {
          return { label: obj.extension, value: obj.extension_uuid };
        });
        setExtensions(arr);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    let search = "";
    let off_set = 0;
    let status = "ACTIVE";
    let limits = "show_all";
    let orderby = "created_at ASC";
    setLoading(true);

    dispatch(
      getGroupList(
        main_uuid,
        user_type,
        group_per,
        group_uuid,
        search,
        off_set,
        status,
        limits,
        orderby,
        createdby,
        token
      )
    )
      .then((response) => {
        let arr = response.data.data.map((obj, i) => {
          return { label: obj.name, value: obj.uuid };
        });

        setGroups(arr);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const changeExtensionEvent = (value) => {
    //setExtensionEvent(value);
    setUserEvent(value);
  };

  //Availibility Functions
  const saveTimeSlot = (data) => {
    //call saveTimeSlot API
    setLoading(true);
    dispatch(updateTimeSlot(data))
      .then((response) => {
        if (response.status === 200) {
          notifyInfo(response.message);
          setDefaultDay(data.user_availability_data_uuid);
          changeExtensionEvent(Math.random);
        } else if (response.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const deleteAvailTimeSlot = (data, user_availability_data_uuid) => {
    //call deleteAvailTimeSlot API
    setLoading(true);
    dispatch(deleteTimeSlot(data, createdby, token))
      .then((response) => {
        if (response.status === 200) {
          notifyInfo(response.message);
          setDefaultDay(user_availability_data_uuid);
          changeExtensionEvent(Math.random);
        } else if (response.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const createAvailTimeSlot = (timeData) => {
    setDefaultDay(timeData.user_availability_data_uuid);
  };

  const saveOption = (data) => {
    setTimeSlotOption(data);
  };

  const save = (value, attribute) => {
    let userDataNew = { ...userData };

    userDataNew[attribute] = value;
    userDataNew["createdby"] = createdby;
    setLoading(true);

    dispatch(updateUserStatus(userDataNew, token))
      .then((res) => {
        if (res.status === 200) {
          notifyInfo(res.message);
          changeUserEvent(Math.random);
        } else if (res.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const breadCurmList = [
    { label: "Calls", link: "/", is_active: "NO" },
    { label: "Users", link: "/users", is_active: "NO" },
    {
      label: userData.first_name + " " + userData.last_name,
      link: "",
      is_active: "YES",
    },
  ];

  let status = "";
  if (userData.status === "ACTIVE" || userData.status === "INACTIVE") {
    status = "Yes";
  } else {
    status = "No";
  }

  let statusOption = [
    {
      value: "ACTIVE",
      label: "ACTIVE",
    },
    {
      value: "INACTIVE",
      label: "INACTIVE",
    },
  ];

  const reInviteUser = (email) => {
    confirmAlert({
      title: "",
      message: "Are you sure you want to re-invite this User?",
      buttons: [
        {
          label: "Yes",
          onClick: () => reInviteUserService(email),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const reInviteUserService = (email) => {
    setLoading(true);
    let data = {
      email: email,
      createdby: createdby,
    };
    dispatch(updateReInvite(data, token))
      .then((response) => {
        if (response.status === 200) {
         
          notifyInfo(response.message);
          setLoading(false);
          setUserEvent(Math.random);
          return;
        } else if (response.status === 500) {
          setLoading(false);
          notifyError("Something went wrong.");
        } else {
          setLoading(false);
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />

      <Tab.Container defaultActiveKey="general">
        <Nav as="ul" className="nav-tabs ringtabs mt-4">
          <Nav.Item as="li" key="1">
            <Nav.Link eventKey="general">
              <i className={`la la-tty mr-2`} />
              General
            </Nav.Link>
          </Nav.Item>

          <Nav.Item as="li" key="2">
            <Nav.Link eventKey="availability">
              <i className={`la la-envelope mr-2`} />
              Availability
            </Nav.Link>
          </Nav.Item>

          <Nav.Item as="li" key="3">
            <Nav.Link eventKey="e911">
              <i className={`la la-envelope mr-2`} />
              E911
            </Nav.Link>
          </Nav.Item>

          <Nav.Item as="li" key="4">
            <Nav.Link eventKey="email_signature">
              <i className={`fa fa-list-alt mr-2`} />
              Email Signature
            </Nav.Link>
          </Nav.Item>

          <Nav.Item as="li" key="4">
            <Nav.Link eventKey="email_configuration">
              <i className={`fa fa-list-alt mr-2`} />
              Email Configuration
            </Nav.Link>
          </Nav.Item>

          <Nav.Item as="li" className="ml-auto">
            <span
              className={
                userData.status === "ACTIVE"
                  ? "text-green font-w600 pt-2 mr-5"
                  : userData.status === "INACTIVE"
                  ? "text-red font-w600 pt-2 mr-5"
                  : "text-warning font-w600 pt-2 mr-5"
              }
            >
              {status === "Yes" ? (
                <EasyEdit
                  type={Types.SELECT}
                  onSave={(value) => {
                    save(value, "status");
                  }}
                  options={statusOption}
                  saveButtonLabel={
                    <i className="fa fa-check" aria-hidden="true"></i>
                  }
                  saveButtonStyle="label-primary easy-edit-button rounded-lg"
                  cancelButtonLabel={
                    <i className="fa fa-times" aria-hidden="true"></i>
                  }
                  cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                  attributes={{
                    name: "language",
                    className: "form-control pt-0 pb-0",
                    style: { height: "35px" },
                  }}
                  placeholder="None"
                  viewAttributes={{ className: "pull-left" }}
                  value={userData.status}
                />
              ) : status === "INVITED" ? (
                <>{userData.status}</>
              ) : (
                <>
                  {" "}
                  <Tooltip
                    placement="top"
                    overlay={<span>Re-Invite User</span>}
                  >
                    <button
                      className="btn btn-sm btn-outline-info mr-2"
                      onClick={(e) => reInviteUser(userData.email)}
                    >
                      Re-Invite
                    </button>
                  </Tooltip>
                  ({userData.status})
                </>
              )}
            </span>
          </Nav.Item>
        </Nav>

        <Tab.Content className="ringgroup">
          <Tab.Pane eventKey="general" key="1" className="pt-4">
            <GeneralInfo
              userData={userData}
              roles={roles}
              numbers={numbers}
              extensions={extensions}
              timeZones={timeZones}
              languages={languages}
              changeUserEvent={changeUserEvent}
              groups={groups}
            ></GeneralInfo>
          </Tab.Pane>
          <Tab.Pane eventKey="availability" key="2" className="pt-4">
            <Availability
              extensionData={extensionData}
              saveTimeSlot={saveTimeSlot}
              deleteTimeSlot={deleteAvailTimeSlot}
              changeExtensionEvent={changeExtensionEvent}
              timeconditionsday={timeconditionsday}
              timeSlotOption={timeSlotOption}
              saveOption={saveOption}
              defaultDay={defaultDay}
              createAvailTimeSlot={createAvailTimeSlot}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="e911" key="3" className="pt-4">
            <E911 userData={userData} E911Data={e911Data} />
          </Tab.Pane>

          <Tab.Pane eventKey="email_signature" key="4" className="pt-4">
            <ViewEmailSignature signature_type='SELF' userData={userData} />
          </Tab.Pane>

          <Tab.Pane eventKey="email_configuration" key="4" className="pt-4">
            <EmailConfigForm userData={userData} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Fragment>
  );
}

export default ViewUser;
