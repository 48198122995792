import React, { Fragment, useState } from "react";
import Header from "./Header";
import SideBarAccountManagement from "./SideBarAccountManagement";
/// Css
import "./index.css";

const JobieNav = ({ title, showProfileSideBar, showProfile }) => {
  const [toggle, setToggle] = useState("");


  const onClick = (name) => setToggle(toggle === name ? "" : name);
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;

 
  const token = user.data.token;

  let tab_value = localStorage.getItem("tab_value");

  if (!tab_value) {
    tab_value = "communication";
  }

  return (
    <Fragment>
      <SideBarAccountManagement />

      <Header
        onNote={() => onClick("chatbox")}
        onNotification={() => onClick("notification")}
        onProfile={() => onClick("profile")}
        toggle={toggle}
        title={title}
        onBox={() => onClick("box")}
        showProfileSideBar={showProfileSideBar}
        showProfile={showProfile}
        user_data={user_data}
        token={token}
      />
    </Fragment>
  );
};

export default JobieNav;
