import React, { useEffect, useState } from "react";
import { getContactGroups } from "../../../../actions/sequence";
import Loader from "../../../../layouts/Loader";
import { useDispatch } from "react-redux";

const StepOne = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const [loading, setLoading] = useState(false);
  const [contactGroups, setContactGroups] = useState([]);
  const dispatch = useDispatch();

  const handleBlur = (e) => {
    const newformdata = { ...props.formData };
    const errorsformdata = { ...props.errors };

    if (e.target.name === "sequence_name") {
      if (e.target.value.length === 0) {
        errorsformdata.sequence_data.sequence_name =
          "Sequence name is required.";
      } else {
        errorsformdata.sequence_data.sequence_name = "";
      }
      newformdata.sequence_data.sequence_name = e.target.value;
    }

    props.handleOneData(newformdata);
    props.handleOneError(errorsformdata);
  };

  useEffect(() => {
    setLoading(true);

    dispatch(getContactGroups(main_uuid, createdby, token))
      .then((response) => {
        setContactGroups(response.data);
        setLoading(false);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  function handleSelect(e) {
    const newformdata = { ...props.formData };
    newformdata.sequence_data.group_uuid = e.target.value;
    props.handleOneData(newformdata);
  }

  return (
    <section>
      {/* {loading && <Loader />} */}
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <form action="" onSubmit={(e) => ""}>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group input-primary">
                      <label className="mb-1">
                        <strong>Sequence Name</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control bg-white"
                        placeholder="Sequence Name"
                        name="sequence_name"
                        id="sequence_name"
                        value={props.formData.sequence_data.sequence_name}
                        onChange={handleBlur}
                      />
                      <span className="text-danger">
                        {props.errors.sequence_data.sequence_name}
                      </span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group input-primary">
                      <label className="mb-1">
                        <strong>Select Group</strong>
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control bg-white"
                        name="group_uuid"
                        onChange={handleSelect}
                      >
                        <option value="">Select Group</option>
                        {contactGroups.map((user) => (
                          <option key={user.uuid} value={user.uuid}>
                            {user.group_name}
                          </option>
                        ))}
                      </select>
                      <span className="text-danger">
                        {props.errors.sequence_data.group_uuid}
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepOne;
