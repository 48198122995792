import React from "react";
import EasyEdit, { Types } from 'react-easy-edit';

function ContactCustomField(props) {

        let field = props.field;
        let value = '';
        let dependent_value = '';
        let optionsObjects = '';

        if(field.field_type === 'CHECKBOX' || field.field_type === 'DROPDOWN' || field.field_type === 'RADIO'){
            const optionsArray = field.field_options.split(',');
            optionsObjects = optionsArray.map((option, index) => ({
                label: option,
                value: option
              }));
        }

        if (props.contactFiledData[field.custom_field_uuid]) {
            
            value = props.contactFiledData[field.custom_field_uuid].field_value;
            dependent_value = props.contactFiledData[field.custom_field_uuid].dependent_value;
        }

        const save = (value,attribute,field_type) => {
            // console.log(value);
            // console.log(attribute);
            props.save(value,attribute,field_type);
        }

        const CustomDisplay = (props) => {

            return (
                <>
                    <i className="fa fa-level-up mr-2 text-muted" aria-hidden="true" style={{transform: "rotate(90deg)"}}></i>
                    <small className="fw-bold text-muted">{props.filed_name}</small> : {props.value} 
                </>
            );
        }   
        
        return (

            <div className="form-group input-primary col-md-12" >
                <label className="mb-1">
                     <strong>{field.field_name}</strong> : {/*{value} */}

                     {field.field_type === 'TEXT' && 
                        <EasyEdit
                            type={Types.TEXT}
                            onSave={value => {
                            save(value,field.custom_field_uuid,'custom_field')
                            }}
                            //onCancel={cancel}
                            saveButtonLabel={<i className="fa fa-check" aria-hidden="true"></i>}
                            saveButtonStyle = 'label-primary easy-edit-button rounded-lg'
                            cancelButtonLabel={<i className="fa fa-times" aria-hidden="true"></i>}
                            cancelButtonStyle= 'label-danger easy-edit-button rounded-lg'
                            attributes={{ name: field.custom_field_uuid, className : 'form-control h-75'}}
                            viewAttributes={{className: 'fs-20'}}
                            onValidate={(value) => {                          
                            return value ? true : false;
                            }}
                            //validationMessage="What just happened?"
                            placeholder={field.field_name}
                            value={value}
                        />
                    }

                    {field.field_type === 'CHECKBOX' && 
                        <EasyEdit
                            type="checkbox"
                            options={optionsObjects}
                            onSave={value => {
                            save(value,field.custom_field_uuid,'custom_field')
                            }}
                            //onCancel={cancel}
                            saveButtonLabel={<i className="fa fa-check" aria-hidden="true"></i>}
                            saveButtonStyle = 'label-primary easy-edit-button rounded-lg'
                            cancelButtonLabel={<i className="fa fa-times" aria-hidden="true"></i>}
                            cancelButtonStyle= 'label-danger easy-edit-button rounded-lg'
                            attributes={{ name: field.custom_field_uuid, className : 'mr-2'}}
                            viewAttributes={{className: 'fs-20'}}
                            onValidate={(value) => {                          
                            return value ? true : false;
                            }}
                            //validationMessage="What just happened?"
                            placeholder={field.field_name}
                            value={value}
                        />
                    }

                    {field.field_type === 'DATE' && 
                        <EasyEdit
                            type="date"
                            onSave={value => {
                            save(value,field.custom_field_uuid,'custom_field')
                            }}
                            //onCancel={cancel}
                            saveButtonLabel={<i className="fa fa-check" aria-hidden="true"></i>}
                            saveButtonStyle = 'label-primary easy-edit-button rounded-lg'
                            cancelButtonLabel={<i className="fa fa-times" aria-hidden="true"></i>}
                            cancelButtonStyle= 'label-danger easy-edit-button rounded-lg'
                            attributes={{ name: field.custom_field_uuid, className : 'form-control h-75'}}
                            viewAttributes={{className: 'fs-20'}}
                            onValidate={(value) => {                          
                            return value ? true : false;
                            }}
                            //validationMessage="What just happened?"
                            placeholder={field.field_name}
                            value={value}
                        />
                    }

                    {field.field_type === 'DROPDOWN' && 
                        <EasyEdit
                            type="select"
                            options={optionsObjects}
                            onSave={value => {
                            save(value,field.custom_field_uuid,'custom_field')
                            }}
                            //onCancel={cancel}
                            saveButtonLabel={<i className="fa fa-check" aria-hidden="true"></i>}
                            saveButtonStyle = 'label-primary easy-edit-button rounded-lg'
                            cancelButtonLabel={<i className="fa fa-times" aria-hidden="true"></i>}
                            cancelButtonStyle= 'label-danger easy-edit-button rounded-lg'
                            attributes={{ name: field.custom_field_uuid, className : 'form-control pt-1 pb-1 h-100'}}
                            viewAttributes={{className: 'fs-20'}}
                            onValidate={(value) => {                          
                            return value ? true : false;
                            }}
                            //validationMessage="What just happened?"
                            placeholder={field.field_name}
                            value={value}
                        />
                    }

                    {field.field_type === 'TEXTAREA' && 
                        <EasyEdit
                            type={Types.TEXTAREA}
                            onSave={value => {
                            save(value,field.custom_field_uuid,'custom_field')
                            }}
                            //onCancel={cancel}
                            saveButtonLabel={<i className="fa fa-check" aria-hidden="true"></i>}
                            saveButtonStyle = 'label-primary easy-edit-button rounded-lg'
                            cancelButtonLabel={<i className="fa fa-times" aria-hidden="true"></i>}
                            cancelButtonStyle= 'label-danger easy-edit-button rounded-lg'
                            attributes={{ name: field.custom_field_uuid, className : 'form-control h-75'}}
                            viewAttributes={{className: 'fs-20'}}
                            onValidate={(value) => {                          
                            return value ? true : false;
                            }}
                            //validationMessage="What just happened?"
                            placeholder={field.field_name}
                            value={value}
                        />
                    }

                    {field.field_type === 'RADIO' && 
                        <EasyEdit
                            type="radio"
                            options={optionsObjects}
                            onSave={value => {
                            save(value,field.custom_field_uuid,'custom_field')
                            }}
                            //onCancel={cancel}
                            saveButtonLabel={<i className="fa fa-check" aria-hidden="true"></i>}
                            saveButtonStyle = 'label-primary easy-edit-button rounded-lg'
                            cancelButtonLabel={<i className="fa fa-times" aria-hidden="true"></i>}
                            cancelButtonStyle= 'label-danger easy-edit-button rounded-lg'
                            attributes={{ name: field.custom_field_uuid, className : ''}}
                            viewAttributes={{className: 'fs-20'}}
                            onValidate={(value) => {                          
                            return value ? true : false;
                            }}
                            //validationMessage="What just happened?"
                            placeholder={field.field_name}
                            value={value}
                        />
                    }

                </label>

                {(field.is_dependent_available === 'YES' ) && 
                    <>
                    {field.dependent_fields.map((dependent_field) => {
                        return (<div key={dependent_field.contact_custom_dependent_field_uuid}>
                            {value === dependent_field.answer_on_show && 
                                <label className="mb-1 text-muted ml-3">
                                    {/* <i className="fa fa-level-up mr-2" aria-hidden="true" style={{transform: "rotate(90deg)"}}></i> */}
                                    {/* <strong>{dependent_field.filed_name}</strong> :  */}

                                    {dependent_field.field_type === 'TEXT' && 
                                        <EasyEdit
                                            type={Types.TEXT}
                                            onSave={value => {
                                                let data = {};
                                                data['contact_custom_dependent_field_uuid'] = dependent_field.contact_custom_dependent_field_uuid;
                                                data['main_field_uuid'] = dependent_field.main_field_uuid;

                                                save(value,data,'dependent_field')
                                            }}
                                            //onCancel={cancel}
                                            saveButtonLabel={<i className="fa fa-check" aria-hidden="true"></i>}
                                            saveButtonStyle = 'label-primary easy-edit-button rounded-lg'
                                            cancelButtonLabel={<i className="fa fa-times" aria-hidden="true"></i>}
                                            cancelButtonStyle= 'label-danger easy-edit-button rounded-lg'
                                            attributes={{ name: dependent_field.contact_custom_dependent_field_uuid, className : 'form-control h-75'}}
                                            viewAttributes={{className: 'fs-20 '}}
                                            displayComponent={<CustomDisplay filed_name={dependent_field.filed_name} value={dependent_value} />}
                                            // onValidate={(value) => {                          
                                            // return value ? true : false;
                                            // }}
                                            //validationMessage="What just happened?"
                                            placeholder={dependent_field.filed_name}
                                            value={dependent_value}
                                        />
                                    }

                                    {dependent_field.field_type === 'CHECKBOX' && 
                                        <EasyEdit
                                            type="checkbox"
                                            options={optionsObjects}
                                            onSave={value => {
                                                let data = {};
                                                data['contact_custom_dependent_field_uuid'] = dependent_field.contact_custom_dependent_field_uuid;
                                                data['main_field_uuid'] = dependent_field.main_field_uuid;

                                                save(value,data,'dependent_field')
                                                //save(value,dependent_field.contact_custom_dependent_field_uuid,'dependent_field')
                                            }}
                                            //onCancel={cancel}
                                            saveButtonLabel={<i className="fa fa-check" aria-hidden="true"></i>}
                                            saveButtonStyle = 'label-primary easy-edit-button rounded-lg'
                                            cancelButtonLabel={<i className="fa fa-times" aria-hidden="true"></i>}
                                            cancelButtonStyle= 'label-danger easy-edit-button rounded-lg'
                                            attributes={{ name: dependent_field.contact_custom_dependent_field_uuid, className : 'mr-2'}}
                                            viewAttributes={{className: 'fs-20'}}
                                            displayComponent={<CustomDisplay filed_name={dependent_field.filed_name} value={dependent_value} />}
                                            // onValidate={(value) => {                          
                                            // return value ? true : false;
                                            // }}
                                            //validationMessage="What just happened?"
                                            placeholder={dependent_field.filed_name}
                                            value={value}
                                        />
                                    }

                                    {dependent_field.field_type === 'DATE' && 
                                        <EasyEdit
                                            type="date"
                                            onSave={value => {
                                                let data = {};
                                                data['contact_custom_dependent_field_uuid'] = dependent_field.contact_custom_dependent_field_uuid;
                                                data['main_field_uuid'] = dependent_field.main_field_uuid;

                                                save(value,data,'dependent_field')
                                                //save(value,dependent_field.contact_custom_dependent_field_uuid,'dependent_field')
                                            }}
                                            //onCancel={cancel}
                                            saveButtonLabel={<i className="fa fa-check" aria-hidden="true"></i>}
                                            saveButtonStyle = 'label-primary easy-edit-button rounded-lg'
                                            cancelButtonLabel={<i className="fa fa-times" aria-hidden="true"></i>}
                                            cancelButtonStyle= 'label-danger easy-edit-button rounded-lg'
                                            attributes={{ name: dependent_field.contact_custom_dependent_field_uuid, className : 'form-control h-75'}}
                                            viewAttributes={{className: 'fs-20'}}
                                            displayComponent={<CustomDisplay filed_name={dependent_field.filed_name} value={dependent_value} />}
                                            // onValidate={(value) => {                          
                                            // return value ? true : false;
                                            // }}
                                            //validationMessage="What just happened?"
                                            placeholder={dependent_field.filed_name}
                                            value={value}
                                        />
                                    }

                                    {dependent_field.field_type === 'DROPDOWN' && 
                                        <EasyEdit
                                            type="select"
                                            options={optionsObjects}
                                            onSave={value => {
                                                let data = {};
                                                data['contact_custom_dependent_field_uuid'] = dependent_field.contact_custom_dependent_field_uuid;
                                                data['main_field_uuid'] = dependent_field.main_field_uuid;

                                                save(value,data,'dependent_field')
                                                //save(value,dependent_field.contact_custom_dependent_field_uuid,'dependent_field')
                                            }}
                                            //onCancel={cancel}
                                            saveButtonLabel={<i className="fa fa-check" aria-hidden="true"></i>}
                                            saveButtonStyle = 'label-primary easy-edit-button rounded-lg'
                                            cancelButtonLabel={<i className="fa fa-times" aria-hidden="true"></i>}
                                            cancelButtonStyle= 'label-danger easy-edit-button rounded-lg'
                                            attributes={{ name: dependent_field.contact_custom_dependent_field_uuid, className : 'form-control pt-1 pb-1 h-100'}}
                                            viewAttributes={{className: 'fs-20'}}
                                            displayComponent={<CustomDisplay filed_name={dependent_field.filed_name} value={dependent_value} />}
                                            // onValidate={(value) => {                          
                                            // return value ? true : false;
                                            // }}
                                            //validationMessage="What just happened?"
                                            placeholder={dependent_field.filed_name}
                                            value={value}
                                        />
                                    }

                                    {dependent_field.field_type === 'TEXTAREA' && 
                                        <EasyEdit
                                            type={Types.TEXTAREA}
                                            onSave={value => {
                                                let data = {};
                                                data['contact_custom_dependent_field_uuid'] = dependent_field.contact_custom_dependent_field_uuid;
                                                data['main_field_uuid'] = dependent_field.main_field_uuid;

                                                save(value,data,'dependent_field')
                                                //save(value,dependent_field.contact_custom_dependent_field_uuid,'dependent_field')
                                            }}
                                            //onCancel={cancel}
                                            saveButtonLabel={<i className="fa fa-check" aria-hidden="true"></i>}
                                            saveButtonStyle = 'label-primary easy-edit-button rounded-lg'
                                            cancelButtonLabel={<i className="fa fa-times" aria-hidden="true"></i>}
                                            cancelButtonStyle= 'label-danger easy-edit-button rounded-lg'
                                            attributes={{ name: dependent_field.contact_custom_dependent_field_uuid, className : 'form-control h-75'}}
                                            viewAttributes={{className: 'fs-20'}}
                                            displayComponent={<CustomDisplay filed_name={dependent_field.filed_name} value={dependent_value} />}
                                            // onValidate={(value) => {                          
                                            // return value ? true : false;
                                            // }}
                                            //validationMessage="What just happened?"
                                            placeholder={dependent_field.filed_name}
                                            value={value}
                                        />
                                    }

                                    {dependent_field.field_type === 'RADIO' && 
                                        <EasyEdit
                                            type="radio"
                                            options={optionsObjects}
                                            onSave={value => {
                                                let data = {};
                                                data['contact_custom_dependent_field_uuid'] = dependent_field.contact_custom_dependent_field_uuid;
                                                data['main_field_uuid'] = dependent_field.main_field_uuid;

                                                save(value,data,'dependent_field')
                                                //save(value,dependent_field.contact_custom_dependent_field_uuid,'dependent_field')
                                            }}
                                            //onCancel={cancel}
                                            saveButtonLabel={<i className="fa fa-check" aria-hidden="true"></i>}
                                            saveButtonStyle = 'label-primary easy-edit-button rounded-lg'
                                            cancelButtonLabel={<i className="fa fa-times" aria-hidden="true"></i>}
                                            cancelButtonStyle= 'label-danger easy-edit-button rounded-lg'
                                            attributes={{ name: dependent_field.contact_custom_dependent_field_uuid, className : ''}}
                                            viewAttributes={{className: 'fs-20'}}
                                            displayComponent={<CustomDisplay filed_name={dependent_field.filed_name} value={dependent_value} />}
                                            // onValidate={(value) => {                          
                                            // return value ? true : false;
                                            // }}
                                            //validationMessage="What just happened?"
                                            placeholder={dependent_field.filed_name}
                                            value={value}
                                        />
                                    }

                                </label>
                            }
                            
                        </div>)
                    })}
                    </>
                }

            </div>
        );
   
}

export default ContactCustomField;