import { SET_MESSAGE, API_FAIL } from "./types";

import TasksService from "../services/tasks.service";

export const getTasksList =
  (
    contact_uuid,
    main_uuid,
    createdby,
    token
  ) =>
  (dispatch) => {
    return TasksService.getTasksList(
      contact_uuid,
      main_uuid,
      createdby,
      token
    ).then(
      (data) => {
        if (
          data.status === 406 ||
          data.status === 500 ||
          data.status === 200
        ) {
          return data;
        } else {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

  export const createTask = (task_data,token) => (dispatch) => {
    return TasksService.createTask(task_data,token).then(
      (data) => {
        if (data.status === 406) {
          return data;
        } else if (data.status !== 200) {
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        } else {
          return data;
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const updateTask = (task_data, token) => (dispatch) => {
    return TasksService.updateTask(task_data, token).then(
      (data) => {
        if (data.status === 406) {
          return data;
        } else if (data.status !== 200) {
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        } else {
          return data;
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const deleteTask = (contact_task_uuid, createdby, token) => (dispatch) => {
    return TasksService.deleteTask(contact_task_uuid, createdby, token).then(
      (data) => {
        if (data.status === 406) {
          return data;
        } else if (data.status !== 200) {
          dispatch({
            type: API_FAIL,
          });
  
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
  
          return Promise.reject();
        } else {
          return data;
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: API_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const getAllTasks =
  (
    createdby,
    token
  ) =>
  (dispatch) => {
    return TasksService.getAllTasks(
      createdby,
      token
    ).then(
      (data) => {
        if (
          data.status === 406 ||
          data.status === 500 ||
          data.status === 200
        ) {
          return data;
        } else {
          dispatch({
            type: API_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });

          return Promise.reject();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: API_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

  
