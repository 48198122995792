import React, { Fragment, useState, useEffect } from "react";
import "./index.css";

const Numbers = (props) => {
  let number_type = props.number_type;

  let didAll = props.didAll;

  let prefix = "";
  let show_contect = "";
  if (props.didData !== undefined) {
    let did_data = props.didData;
    if (number_type === "toll_free_") {
      prefix = did_data.prefix;
      if (prefix === undefined || prefix === "") {
        show_contect = "Prefix";
      } else {
        show_contect = "Prefix - " + prefix;
      }
    } else {
      let state_name = did_data.state_name;
      let area_code = did_data.area_code;
      if (area_code === undefined) {
        show_contect = state_name;
      } else {
        show_contect = state_name + " - " + area_code;
      }
    }
  }

  const [numberSelected, setNumberSelected] = useState([]);

  useEffect(() => {
    let arr = props.didsData.map((did) => did.phone_number);
    setNumberSelected(arr);
  }, [props.didsData]);

  const handleChange = (event, did) => {
    if (event.target.checked) {
      props.handleSelectedNumberData(did);
    } else {
      props.handleUnSelectedNumberData(did);
    }
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="toolbar mb-4" role="toolbar">
                <h3 className="fs-20 text-black">
                  Select {props.type === "user_onboarding" ? "Number" : "Numbers"} ({show_contect} )
                </h3>
              </div>

              {didAll.length > 0 && (
                <div className="row">
                  {didAll.map((did, i) => (
                    <div
                      className="col-4"
                      key={did.phone_number + "_" + number_type + "_" + i}
                    >
                      <div className="custom-control custom-checkbox mr-4">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={did.phone_number + "_" + number_type + "_" + i}
                          checked={numberSelected.includes(did.phone_number)}
                          onChange={(e) => {
                            handleChange(e, did);
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={
                            did.phone_number + "_" + number_type + "_" + i
                          }
                        />
                        <label> {did.phone_number}</label>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Numbers;
