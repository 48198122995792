import React, { Fragment, useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import "./index.css";
import LocalNumber from "./LocalNumber";
import TollFree from "./TollFree";
import SelectedNumbers from "./SelectedNumbers";

const BuyNumber = (props) => {
  const [didsData, setDidsData] = useState(props.didsData);

  const tabData = [
    {
      name: "Toll Free",
      icon: "toll_free",
    },
    {
      name: "Local Number",
      icon: "did",
    },
  ];

  const tabChange = (tab_name) => {};

  const handleSelectedNumber = (dataTollFree) => {
    if (props.type === "user_onboarding") {
      let newCampaignData1 = { ...didsData };
      newCampaignData1 = [...didsData, dataTollFree];
      setDidsData(newCampaignData1);
    } else {
      let newCampaignData1 = { ...didsData };
      newCampaignData1 = [...didsData, dataTollFree];
      setDidsData(newCampaignData1);
    }
  };

  const handleBuyAndConfirmData = (dataTollFree) => {
    props.handleBuyAndConfirmData(dataTollFree);
  };

  const handleRemovedNumber = (removeNum) => {
    
    setDidsData(removeNum);
  };

  const handleUnSelectedNumber = (dataTollFree) => {
    const filterDid = didsData.filter((didss) => {
      return didss.phone_number !== dataTollFree.phone_number;
    });
    setDidsData(filterDid);
  };

  return (
    <Fragment>
      <section className="pricewrap">
        <Tab.Container defaultActiveKey={"toll_free"}>
          <Nav as="ul" className="nav-pills mb-2 light">
            {tabData.map((data, i) => (
              <Nav.Item as="li" key={i} onClick={() => tabChange(data.icon)}>
                <Nav.Link eventKey={data.icon}>{data.name}</Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <Tab.Content className="pt-4">
            {tabData.map((data, i) => (
              <Tab.Pane eventKey={data.icon} key={i}>
                <div className="row" key={i}>
                  {data.icon === "toll_free" ? (
                    <TollFree
                      didsData={didsData}
                      type={props.type}
                      handleSelectedNumber={handleSelectedNumber}
                      handleUnSelectedNumber={handleUnSelectedNumber}
                    />
                  ) : (
                    <LocalNumber
                      didsData={didsData}
                      type={props.type}
                      handleSelectedNumber={handleSelectedNumber}
                      handleUnSelectedNumber={handleUnSelectedNumber}
                    />
                  )}
                </div>
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Tab.Container>
        <SelectedNumbers
          didsData={didsData}
          type={props.type}
          handleBuyAndConfirmData={handleBuyAndConfirmData}
          handleRemovedNumber={handleRemovedNumber}
        />
      </section>
    </Fragment>
  );
};

export default BuyNumber;
