import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;

const getTasksList = (contact_uuid, main_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "get-tasks-list",
      {
        contact_uuid,
        main_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const createTask = (task_data, token) => {
  return axios
    .post(API_URL + "create-task", task_data, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const updateTask = (task_data, token) => {
  return axios
    .post(
      API_URL + "update-task",
      task_data,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      // if (response.data.status == '200') {

      // }
      return response.data;
    });
};

const deleteTask = (contact_task_uuid, createdby, token) => {
  return axios
    .post(
      API_URL + "delete-task",
      {
        contact_task_uuid,
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getAllTasks = (createdby, token) => {
  return axios
    .post(
      API_URL + "get-all-tasks",
      {
        createdby,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

export default {
  getTasksList,
  createTask,
  deleteTask,
  updateTask,
  getAllTasks,
};
