import React, { Fragment, useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { getRoleList } from "../../actions/role";
import { updateUserRole } from "../../actions/user";
import { notifyInfo } from "../Toastr/Toastr";
import Loader from "../../layouts/Loader";

function UserUpdateForm(props) {
  const closeModel = (arg) => {
    props.closeModel(arg);
  };

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const [userData, setUserData] = useState(props.user.user);

  const [errors, setErrors] = useState({
    role_uuid: "",
  });

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleBlur = (e) => {
    const newUserData = { ...userData };
    let error_data = { ...errors };

    if (e.target.name === "role_uuid") {
      if (e.target.value.length === 0) {
        error_data[e.target.name] = "Please select role.";
      } else {
        error_data[e.target.name] = "";
      }
      newUserData[e.target.name] = e.target.value;
      setErrors(error_data);
    }

    setUserData(newUserData);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error_data = { ...errors };
    let isError = "false";

    setLoading(true);

    if (userData.role_uuid === "") {
      error_data["role_uuid"] = "Please select role.";
      setErrors(error_data);
      isError = "true";
    }

    if (isError === "true") {
      setLoading(false);
      return;
    } else {
      //call create user API

      const data = { ...userData };

      data["useruuid"] = props.user.user.uuid;
      data["main_user_uuid"] = main_uuid;
      data["createdby"] = createdby;

      dispatch(updateUserRole(data, token))
        .then((response) => {
          closeModel(false);
          notifyInfo(response.message);
          props.handleUserEvent("updated");
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    dispatch(getRoleList(createdby, token))
      .then((response) => {
        setRoles(response.data);
        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Fragment>
      {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>User update role</Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <form action="" onSubmit={(e) => submitHandler(e)}>
        <Modal.Body>
          <div className="row">
            <div className="col-sm">
              <div className="form-group input-primary">
                <label className="mb-1">
                  <strong>Role</strong>
                  <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control bg-white"
                  value={props.user.user.role_uuid}
                  name="role_uuid"
                  onChange={handleBlur}
                >
                  <option value="">Select Role</option>
                  {roles.map((role) => (
                    <option key={role.role_uuid} value={role.role_uuid}>
                      {role.role_name}
                    </option>
                  ))}
                </select>
                <span className="text-danger">{errors.role_uuid}</span>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => closeModel(false)}
            variant="danger light btn-sm"
          >
            Close
          </Button>
          <Button type="submit" variant="primary btn-sm">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Fragment>
  );
}

export default UserUpdateForm;
