import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { Button, Card, Tab, Nav, Table, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import PageTitle from "../../layouts/PageTitle";
import Loader from "../../layouts/Loader";
import Pagination from "../../layouts/Pagination";
import {
  getRecordings,
  deleteRecording,
  updateRecording,
} from "../../actions/recording";
import { checkModuleAssign } from "../../actions/comman";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./style.css";
import CheckModulePermisson from "../../common/CheckModulePermisson";
import PermissionCheck from "../../common/PermissionCheck";
import { notifyError } from "../Toastr/Toastr";
import EasyEdit, { Types } from "react-easy-edit";
import Tooltip from "rc-tooltip";
import Moh from "./Moh";

const RECORDING_JS_URL = process.env.REACT_APP_SERVER_RECORDING_URL;

function Recording() {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;
  const user_type = user_data.role;
  const module_name = "audio files";
  const domain_name = user_data.domain_name;

  const location = useLocation();
  let defaultTab = "ivr";

  if (location.state) {
    defaultTab = location.state.defaultTab;
  }

  const hiddenFileInput = useRef([]);

  let module_per = CheckModulePermisson(module_name);

  let isPermission = true;
  if (module_per === "" || user_type === "admin") {
    isPermission = true;
  } else {
    isPermission = false;
  }

  let listing_per = PermissionCheck(module_name, "listing", "", "", "");
  let add_per = PermissionCheck(module_name, "add", "", "", "");

  const dispatch = useDispatch();
  let navigate = useNavigate();

  if (listing_per === "none") {
    navigate("/error-403");
  }

  let group_per = listing_per;
  let group_uuid = "";
  if (listing_per === "group") {
    group_uuid = user_data.group_id;
  }

  let search = "";
  //let off_set = 1;
  let limits = 10;
  let orderby = "r.created_at DESC";

  const [recordings, setRecordings] = useState([]);
  const [recordingEvent, setRecordingEvent] = useState("");
  const [pageCount, setpageCount] = useState(0);
  const [offSet, setOffSet] = useState(0);
 
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteConfirmData, setDeleteConfirmData] = useState([]);
  const [activeTab, setActiveTab] = useState(defaultTab);

  const [loading, setLoading] = useState(false);

  //let audioSong = "https://assets.coderrocketfuel.com/pomodoro-times-up.mp3";

  if (module_per === "" || user_type === "admin") {
    useEffect(() => {
      setLoading(true);
      dispatch(
        getRecordings(
          user_type,
          group_per,
          group_uuid,
          activeTab,
          search,
          offSet,
          limits,
          orderby,
          main_uuid,
          createdby,
          token
        )
      )
        .then((response) => {
          setRecordings(response.data.data);
          const total = response.data.total;
          setpageCount(Math.ceil(total / limits));
          setRecordingEvent("");
          setLoading(false);
          return;
        })
        .catch(() => {
          setLoading(false);
        });
    }, [recordingEvent]);
  }
  const handlePageClick = async (data) => {
  
    setOffSet(limits * data.selected);
    setRecordingEvent("update");
  };

  const deleteRecordingE = (uuid) => {
    setLoading(true);
    dispatch(
      checkModuleAssign("recording", uuid, "recording", createdby, token)
    )
      .then((response) => {
        if (response.status === 200) {
          //let message = "Are you sure to delete this file?";
          if (response.data.length > 0) {
            //let message = "Are you sure to delete this file?";
            setDeleteConfirmData(response.data);
            setDeleteModal(true);
            setLoading(false);
          } else {
            setLoading(false);
            confirmAlert({
              title: "",
              message: "Are you sure to delete this file?",
              buttons: [
                {
                  label: "Yes",
                  onClick: () => deleteRecordingService(uuid),
                },
                {
                  label: "No",
                },
              ],
            });
          }

          return;
        } else if (response.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const deleteRecordingService = (uuid) => {
    setLoading(true);
    dispatch(deleteRecording(uuid, createdby, token))
      .then((response) => {
        if (response.status === 200) {
          setRecordingEvent("delete");
          navigate("/recordings", {
            state: { defaultTab: activeTab },
            replace: true,
          });
          return;
        } else if (response.status === 500) {
          notifyError("Something went wrong.");
        } else {
          notifyError("Something went wrong.");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleBlur = (e) => {
    const type = e.currentTarget.id;
    setActiveTab(type);
    setRecordingEvent(Math.random);
  };

  const save = (value, attribute) => {
    setLoading(true);
    const formData = new FormData();

    if (attribute === "file") {
      // console.log(value);
      // return false;

      formData.append("recording_uuid", value.recording_uuid);
      formData.append("recording_name", value.recording_name);
      formData.append("recording_type", value.type);
      formData.append("token", token);
      formData.append("createdby", createdby);
      formData.append("main_admin_uuid", main_uuid);
      formData.append("user_uuid", createdby);
      formData.append("file", value.file);
      formData.append("fileName", value.file.name);
     
    } else {
      formData.append("file", "");
      formData.append("fileName", "");
      formData.append("recording_uuid", attribute.recording_uuid);
      formData.append("recording_name", value);
      formData.append("recording_type", attribute.type);
      formData.append("token", token);
      formData.append("createdby", createdby);
      formData.append("main_admin_uuid", main_uuid);
      formData.append("user_uuid", createdby);
     
    }

    dispatch(updateRecording(formData, token))
      .then((res) => {
        if (res.status !== 200) {
          notifyError(res.message);
        } else {
          setRecordingEvent(Math.random);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleClick = (event, recording_uuid) => {
    hiddenFileInput.current[recording_uuid].click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (e, recording) => {
    const MAX_FILE_SIZE = 10240; // 5MB
    if (
      e.target.files[0].type === "audio/wav" ||
      e.target.files[0].type === "audio/mpeg" ||
      e.target.files[0].type === "audio/x-m4a"
    ) {
      const fileSizeKiloBytes = e.target.files[0].size / 1024;

      if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        // setErrorMsg("File size is greater than maximum limit");
        notifyError("File size not allow greater then 10MB.");
        return;
      }

      let recordingData = {
        recording_name: recording.recording_name,
        file: e.target.files[0],
        type: recording.type,
        recording_uuid: recording.recording_uuid,
      };

      save(recordingData, "file");
    } else {
      notifyError("File allow only waw and mp3.");
      return;
    }
  };

  const CustomDisplay = (props) => {
    if (props.recording.recording_name !== "") {
      return (
        <Tooltip placement="top" overlay={<span>Click To Edit</span>}>
          <p>{props.recording.recording_name} </p>
        </Tooltip>
      );
    } else {
      return <p>Click To Edit</p>;
    }
  };

  const breadCurmList = [
    { label: "Calls", link: "/", is_active: "NO" },
    { label: "Audio Files", link: "/recordings", is_active: "YES" },
  ];

  return (
    <Fragment>
      {loading && <Loader />}
      <PageTitle breadCurmList={breadCurmList} />
      {isPermission ? (
        <Card>
          <Card.Body>
            <div className="default-tab">
              <Tab.Container defaultActiveKey={activeTab}>
                <Nav as="ul" className="nav-tabs">
                  <Nav.Item as="li">
                    <Nav.Link id="ivr" eventKey="ivr" onClick={handleBlur}>
                      <i className={`la la-clock mr-2 text-primary`} />
                      IVR
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item as="li">
                    <Nav.Link id="moh" eventKey="moh" onClick={handleBlur}>
                      <i className={`la la-file-sound-o mr-2 text-primary`} />
                      MoH
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item as="li">
                    <Nav.Link
                      id="vm_greeting"
                      eventKey="vm_greeting"
                      onClick={handleBlur}
                    >
                      <i className={`la la-file-o mr-2 text-primary`} />
                      VM Greeting
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item as="li">
                    <Nav.Link
                      id="call_blast"
                      eventKey="call_blast"
                      onClick={handleBlur}
                    >
                      <i className={`la la-life-saver mr-2 text-primary`} />
                      Voice BroadCast
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item as="li">
                    <Nav.Link
                      id="announcement"
                      eventKey="announcement"
                      onClick={handleBlur}
                    >
                      <i className={`la la-rebel mr-2 text-primary`} />
                      Announcement
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item as="li" className="ml-auto">
                    {add_per === "yes" ? (
                      <Button
                        type="button"
                        className="btn btn-primary pull-right btn-sm"
                        onClick={() =>
                          navigate("/upload-recording", {
                            state: {
                              defaultTab: activeTab,
                              type: "upload_recording",
                              did_uuid: "",
                            },
                            replace: true,
                          })
                        }
                      >
                        Upload New File
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Nav.Item>
                </Nav>

                <Tab.Content className="pt-12">
                  <Tab.Pane eventKey="ivr">
                    <div className="row mt-4">
                      <div className="col-12">
                        <div className="card-header">
                          <h4 className="card-title">IVR</h4>
                        </div>
                        <Table responsive className="w-100">
                          <thead>
                            <tr role="row">
                              <th>Recording Name</th>
                              <th>File Play</th>
                              <th>File Name</th>
                              <th>Created Date</th>

                              {/* {listing_per === "group" ||
                                  user_type === "admin" ? (
                                    <th>Created By</th>
                                  ) : (
                                    <></>
                                  )} */}

                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {recordings.map((recording) => (
                              <tr key={recording.recording_uuid}>
                                <td>
                                  <EasyEdit
                                    type={Types.TEXT}
                                    onSave={(value) => {
                                      save(value, recording);
                                    }}
                                    //editComponent={<CustomEdit recording={recording} />}
                                    displayComponent={
                                      <CustomDisplay recording={recording} />
                                    }
                                    saveButtonLabel={
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                    }
                                    saveButtonStyle="label-primary easy-edit-button rounded-lg"
                                    cancelButtonLabel={
                                      <i
                                        className="fa fa-times"
                                        aria-hidden="true"
                                      ></i>
                                    }
                                    cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                                    attributes={{
                                      name: "recording_name",
                                      className: "form-control h-75",
                                    }}
                                    viewAttributes={{ className: "fs-20" }}
                                    onValidate={(value) => {
                                      return value ? true : false;
                                    }}
                                    // placeholder="Route and Destination"
                                    value={recording.recording_name}
                                  />
                                </td>
                                <td>
                                  <audio
                                    src={
                                      RECORDING_JS_URL +
                                      domain_name +
                                      "/" +
                                      recording.type +
                                      "/" +
                                      recording.recording_filename
                                    }
                                    controls
                                  />
                                  {add_per === "yes" ? (
                                    <Tooltip
                                      placement="top"
                                      overlay={<span>Upload New File</span>}
                                    >
                                      <button
                                        className="btn btn-sm btn-rounded btn-outline-success ml-2 position-relative"
                                        onClick={(e) =>
                                          handleClick(
                                            e,
                                            recording.recording_uuid
                                          )
                                        }
                                        style={{ top: "-18px" }}
                                      >
                                        <i
                                          className="fa fa-upload"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                    </Tooltip>
                                  ) : (
                                    <></>
                                  )}

                                  <input
                                    type="file"
                                    onChange={(e) => handleChange(e, recording)}
                                    //ref={hiddenFileInput}
                                    ref={(el) =>
                                      (hiddenFileInput.current[
                                        recording.recording_uuid
                                      ] = el)
                                    }
                                    id={recording.recording_uuid}
                                    style={{ display: "none" }} // Make the file input element invisible
                                  />
                                </td>
                                <td>{recording.recording_filename}</td>
                                <td>
                                  {moment(recording.created_at).format(
                                    "MM-DD-YYYY"
                                  )}
                                </td>
                                {/* {listing_per === "group" ||
                                    listing_per === "all" ||
                                    user_type === "admin" ? (
                                      <td>
                                        {recording.first_name}{" "}
                                        {recording.last_name}
                                      </td>
                                    ) : (
                                      <></>
                                    )} */}

                                <td>
                                  <Fragment>
                                    <div className="d-flex">
                                      <Link
                                        to="#"
                                        onClick={() =>
                                          deleteRecordingE(
                                            recording.recording_uuid
                                          )
                                        }
                                        hidden={PermissionCheck(
                                          module_name,
                                          "delete",
                                          recording.group_uuid,
                                          recording.user_created_by,
                                          recording.created_by
                                        )}
                                        className="btn btn-danger shadow btn-xs sharp"
                                      >
                                        <i className="fa fa-trash"></i>
                                      </Link>
                                    </div>
                                  </Fragment>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>

                        <Pagination
                          pageCount={pageCount}
                          handlePageNumberClick={handlePageClick}
                        />
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="moh">
                    <Moh
                      recordings={recordings}
                      deleteRecordingE={deleteRecordingE}
                      save={save}
                      handlePageClick={handlePageClick}
                      pageCount={pageCount}
                    />
                  </Tab.Pane>

                  <Tab.Pane eventKey="vm_greeting">
                    <div className="row mt-4">
                      <div className="col-12">
                        <div className="card-header">
                          <h4 className="card-title">VM Greeting</h4>
                        </div>

                        <Table responsive className="w-100">
                          <thead>
                            <tr role="row">
                              <th>Recording Name</th>
                              <th>File Play</th>
                              <th>File Name</th>
                              <th>Created Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {recordings.map((recording) => (
                              <tr key={recording.recording_uuid}>
                                <td>
                                  <EasyEdit
                                    type={Types.TEXT}
                                    onSave={(value) => {
                                      save(value, recording);
                                    }}
                                    //editComponent={<CustomEdit recording={recording} />}
                                    displayComponent={
                                      <CustomDisplay recording={recording} />
                                    }
                                    saveButtonLabel={
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                    }
                                    saveButtonStyle="label-primary easy-edit-button rounded-lg"
                                    cancelButtonLabel={
                                      <i
                                        className="fa fa-times"
                                        aria-hidden="true"
                                      ></i>
                                    }
                                    cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                                    attributes={{
                                      name: "recording_name",
                                      className: "form-control h-75",
                                    }}
                                    viewAttributes={{ className: "fs-20" }}
                                    onValidate={(value) => {
                                      return value ? true : false;
                                    }}
                                    // placeholder="Route and Destination"
                                    value={recording.recording_name}
                                  />
                                </td>
                                <td>
                                  <audio
                                    src={
                                      RECORDING_JS_URL +
                                      domain_name +
                                      "/" +
                                      recording.type +
                                      "/" +
                                      recording.recording_filename
                                    }
                                    controls
                                  />

                                  <Tooltip
                                    placement="top"
                                    overlay={<span>Upload New File</span>}
                                  >
                                    <button
                                      className="btn btn-sm btn-rounded btn-outline-success ml-2 position-relative"
                                      onClick={(e) =>
                                        handleClick(e, recording.recording_uuid)
                                      }
                                      style={{ top: "-18px" }}
                                    >
                                      <i
                                        className="fa fa-upload"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </Tooltip>

                                  <input
                                    type="file"
                                    onChange={(e) => handleChange(e, recording)}
                                    //ref={hiddenFileInput}
                                    ref={(el) =>
                                      (hiddenFileInput.current[
                                        recording.recording_uuid
                                      ] = el)
                                    }
                                    id={recording.recording_uuid}
                                    style={{ display: "none" }} // Make the file input element invisible
                                  />
                                </td>
                                <td>{recording.recording_filename}</td>
                                <td>
                                  {moment(recording.created_at).format(
                                    "MM-DD-YYYY"
                                  )}
                                </td>
                                <td>
                                  <Fragment>
                                    <div className="d-flex">
                                      <Link
                                        to="#"
                                        onClick={() =>
                                          deleteRecordingE(
                                            recording.recording_uuid
                                          )
                                        }
                                        className="btn btn-danger shadow btn-xs sharp"
                                      >
                                        <i className="fa fa-trash"></i>
                                      </Link>
                                    </div>
                                  </Fragment>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>

                        <Pagination
                          pageCount={pageCount}
                          handlePageNumberClick={handlePageClick}
                        />
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="call_blast">
                    <div className="row mt-4">
                      <div className="col-12">
                        <div className="card-header">
                          <h4 className="card-title">Voice Broad Cast</h4>
                        </div>

                        <Table responsive className="w-100">
                          <thead>
                            <tr role="row">
                              <th>Recording Name</th>
                              <th>File Play</th>
                              <th>File Name</th>
                              <th>Created Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {recordings.map((recording) => (
                              <tr key={recording.recording_uuid}>
                                <td>
                                  <EasyEdit
                                    type={Types.TEXT}
                                    onSave={(value) => {
                                      save(value, recording);
                                    }}
                                    //editComponent={<CustomEdit recording={recording} />}
                                    displayComponent={
                                      <CustomDisplay recording={recording} />
                                    }
                                    saveButtonLabel={
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                    }
                                    saveButtonStyle="label-primary easy-edit-button rounded-lg"
                                    cancelButtonLabel={
                                      <i
                                        className="fa fa-times"
                                        aria-hidden="true"
                                      ></i>
                                    }
                                    cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                                    attributes={{
                                      name: "recording_name",
                                      className: "form-control h-75",
                                    }}
                                    viewAttributes={{ className: "fs-20" }}
                                    onValidate={(value) => {
                                      return value ? true : false;
                                    }}
                                    // placeholder="Route and Destination"
                                    value={recording.recording_name}
                                  />
                                </td>
                                <td>
                                  <audio
                                    src={
                                      RECORDING_JS_URL +
                                      domain_name +
                                      "/" +
                                      recording.type +
                                      "/" +
                                      recording.recording_filename
                                    }
                                    controls
                                  />
                                  <Tooltip
                                    placement="top"
                                    overlay={<span>Upload New File</span>}
                                  >
                                    <button
                                      className="btn btn-sm btn-rounded btn-outline-success ml-2 position-relative"
                                      onClick={(e) =>
                                        handleClick(e, recording.recording_uuid)
                                      }
                                      style={{ top: "-18px" }}
                                    >
                                      <i
                                        className="fa fa-upload"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </Tooltip>

                                  <input
                                    type="file"
                                    onChange={(e) => handleChange(e, recording)}
                                    //ref={hiddenFileInput}
                                    ref={(el) =>
                                      (hiddenFileInput.current[
                                        recording.recording_uuid
                                      ] = el)
                                    }
                                    id={recording.recording_uuid}
                                    style={{ display: "none" }} // Make the file input element invisible
                                  />
                                </td>
                                <td>{recording.recording_filename}</td>
                                <td>
                                  {moment(recording.created_at).format(
                                    "MM-DD-YYYY"
                                  )}
                                </td>
                                <td>
                                  <Fragment>
                                    <div className="d-flex">
                                      <Link
                                        to="#"
                                        onClick={() =>
                                          deleteRecordingE(
                                            recording.recording_uuid
                                          )
                                        }
                                        className="btn btn-danger shadow btn-xs sharp"
                                      >
                                        <i className="fa fa-trash"></i>
                                      </Link>
                                    </div>
                                  </Fragment>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>

                        <Pagination
                          pageCount={pageCount}
                          handlePageNumberClick={handlePageClick}
                        />
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="announcement">
                    <div className="row">
                      <div className="col-12">
                        <div className="card-header">
                          <h4 className="card-title">Announcement</h4>
                        </div>

                        <Table responsive className="w-100">
                          <thead>
                            <tr role="row">
                              <th>Recording Name</th>
                              <th>File Play</th>
                              <th>File Name</th>
                              <th>Created Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {recordings.map((recording) => (
                              <tr key={recording.recording_uuid}>
                                <td>
                                  <EasyEdit
                                    type={Types.TEXT}
                                    onSave={(value) => {
                                      save(value, recording);
                                    }}
                                    //editComponent={<CustomEdit recording={recording} />}
                                    displayComponent={
                                      <CustomDisplay recording={recording} />
                                    }
                                    saveButtonLabel={
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                    }
                                    saveButtonStyle="label-primary easy-edit-button rounded-lg"
                                    cancelButtonLabel={
                                      <i
                                        className="fa fa-times"
                                        aria-hidden="true"
                                      ></i>
                                    }
                                    cancelButtonStyle="label-danger easy-edit-button rounded-lg"
                                    attributes={{
                                      name: "recording_name",
                                      className: "form-control h-75",
                                    }}
                                    viewAttributes={{ className: "fs-20" }}
                                    onValidate={(value) => {
                                      return value ? true : false;
                                    }}
                                    // placeholder="Route and Destination"
                                    value={recording.recording_name}
                                  />
                                </td>
                                <td>
                                  <audio
                                    src={
                                      RECORDING_JS_URL +
                                      domain_name +
                                      "/" +
                                      recording.type +
                                      "/" +
                                      recording.recording_filename
                                    }
                                    controls
                                  />
                                  <Tooltip
                                    placement="top"
                                    overlay={<span>Upload New File</span>}
                                  >
                                    <button
                                      className="btn btn-sm btn-rounded btn-outline-success ml-2 position-relative"
                                      onClick={(e) =>
                                        handleClick(e, recording.recording_uuid)
                                      }
                                      style={{ top: "-18px" }}
                                    >
                                      <i
                                        className="fa fa-upload"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </Tooltip>

                                  <input
                                    type="file"
                                    onChange={(e) => handleChange(e, recording)}
                                    //ref={hiddenFileInput}
                                    ref={(el) =>
                                      (hiddenFileInput.current[
                                        recording.recording_uuid
                                      ] = el)
                                    }
                                    id={recording.recording_uuid}
                                    style={{ display: "none" }} // Make the file input element invisible
                                  />
                                </td>
                                <td>{recording.recording_filename}</td>
                                <td>
                                  {moment(recording.created_at).format(
                                    "MM-DD-YYYY"
                                  )}
                                </td>
                                <td>
                                  <Fragment>
                                    <div className="d-flex">
                                      <Link
                                        to="#"
                                        onClick={() =>
                                          deleteRecordingE(
                                            recording.recording_uuid
                                          )
                                        }
                                        className="btn btn-danger shadow btn-xs sharp"
                                      >
                                        <i className="fa fa-trash"></i>
                                      </Link>
                                    </div>
                                  </Fragment>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>

                        <Pagination
                          pageCount={pageCount}
                          handlePageNumberClick={handlePageClick}
                        />
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Card.Body>
        </Card>
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Manage Audio Files</h4>
              </div>
              <div className="card-body">
                <h3>You have don't access this modules</h3>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal className="fade bd-example-modal-lg" show={deleteModal} size="md">
        <Modal.Body>
          <p>
            This recording is assign to following module. Please, remove from
            that to delete.
          </p>
          {deleteConfirmData.map((row) => (
            <div key={Math.random}>
              <span>
                <b>{row.module}</b>: {row.value}
              </span>
            </div>
          ))}

          <Button
            onClick={() => setDeleteModal(false)}
            variant="primary light btn-sm pull-right"
          >
            Close
          </Button>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}

export default Recording;
