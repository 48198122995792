import React, { Fragment, useState } from "react";
import { Table } from "react-bootstrap";
import "./style.css";
import Tooltip from "rc-tooltip";
import PerfectScrollbar from "react-perfect-scrollbar";

function GeneralFieldsForm(props) {
  let ringgroupData = props.ringgroupData;
  let extensions = props.extensions;
  let destinations = props.ringgroupData.ring_group_destinations;
  let destinationOptions = props.destinationOptions;

  const [ringGroupDestination, setRingGroupDestination] = useState({
    destination_type: "",
    destination: "",
    destination_str: "",
    skill_set: "",
    extension: "",
    number: "",
  });

  const [draggedRowIndex, setDraggedRowIndex] = useState(null);

  let errors = props.errors;
  let number_array = new Array(12).fill(1).map((_, i) => {
    return { label: (i + 1) * 5, value: (i + 1) * 5 };
  });
  let skill_set_array = new Array(10).fill(0).map((_, i) => {
    return { label: i, value: i };
  });

  const [destErrors, setDestErrors] = useState({
    extension: "",
    did: "",
  });

  const RingStrategyList = [
    { label: "Ring All", value: "ring_all" },
    { label: "Linear", value: "linear" },
    { label: "Round Robin", value: "round_robin" },
    { label: "Fewest Calls", value: "fewest_calls" },
    { label: "Skill Based", value: "skill_based" },
  ];

  const handleChange = (e) => {
    props.handleBlur(e);
  };

  const handleChangeDestination = (e) => {
    let data = { ...ringGroupDestination };
    let error_data = { ...destErrors };

    let last_data = destinations.slice(-1);
    let order = 1;

    if (last_data && last_data.length > 0) {
      order = parseInt(last_data[0].destination_order) + parseInt(1);
    }

    if (e.target.name === "skill_set") {
      data["skill_set"] = e.target.value;
    } else {
      if (e.target.name === "extension") {
        data["destination_str"] = e.target.options[e.target.selectedIndex].text;
        data["destination_type"] = e.target.name;
        data["destination"] = e.target.value;
      } else {
        data = [];

        data["destination_type"] = e.target.name;
        data["destination"] = e.target.value;
        data["destination_str"] = e.target.value;
        data["skill_set"] = "";
      }

      if (e.target.value.length === 0) {
        if (e.target.name === "extension") {
          error_data["extension"] = "Destination is required.";
        } else {
          error_data["did"] = "Destination is required.";
        }
      } else {
        error_data["extension"] = "";
        error_data["did"] = "";
      }

      if (checkUniqueDestination(destinations, e.target.value)) {
        if (e.target.name === "extension") {
          error_data["extension"] = "Destination is already used.";
        } else {
          error_data["did"] = "Destination is already used.";
        }
      } else {
        error_data["extension"] = "";
        error_data["did"] = "";
      }

      if (e.target.name === "number") {
        if (/^\d{9,11}$/.test(e.target.value)) {
          error_data["did"] = "";
        } else {
          error_data["did"] = "Number is invalid(9 to 11 digits allow).";
        }
      }

      data[e.target.name] = e.target.value;
    }

    data["destination_order"] = order;
    data["ring_timeout"] = 20;
    setRingGroupDestination(data);

    setDestErrors(error_data);
  };

  const saveDestination = () => {
    let isError = "false";
    let error_data = { ...destErrors };

    if (ringGroupDestination.destination.length === 0) {
      if (ringGroupDestination.destination_type === "extension") {
        error_data["extension"] = "Destination is required.";
      } else {
        error_data["did"] = "Destination is required.";
      }
      isError = "true";
    }

    if (
      checkUniqueDestination(destinations, ringGroupDestination.destination)
    ) {
      if (ringGroupDestination.destination_type === "extension") {
        error_data["extension"] = "Destination is already used..";
      } else {
        error_data["did"] = "Destination is already used..";
      }
      isError = "true";
    }

    if (ringGroupDestination.destination_type === "number") {
      if (/^\d{9,11}$/.test(ringGroupDestination.destination)) {
        error_data["did"] = "";
      } else {
        error_data["did"] = "Number is invalid(9 to 11 digits allow).";
        isError = "true";
      }
    }


    if (isError === "true") {
      setDestErrors(error_data);
      return false;
    }

    let new_row = {
      index: Math.random(),
      destination_type: ringGroupDestination.destination_type,
      destination: ringGroupDestination.destination,
      destination_str: ringGroupDestination.destination_str,
      skill_set: ringGroupDestination.skill_set,
      destination_order: ringGroupDestination.destination_order,
      ring_timeout: ringGroupDestination.ring_timeout,
    };

    let new_arr = [...destinations, new_row];

    props.addDestinationHandler(new_arr);

    let data = [];
    data["destination_type"] = "";
    data["destination"] = "";
    data["destination_str"] = "";
    data["skill_set"] = "";
    data["destination_order"] = "";
    data["ring_timeout"] = "";
    data["extension"] = "";
    data["number"] = "";

    setRingGroupDestination(data);
  };

  const fnDeleteRingGroupDestination = (id) => {
    let new_arr = destinations.filter(function (destination, index) {
      return index !== id;
    });

    let new_data = [];
    new_arr.forEach(async (row, index) => {
      let data = { ...row };
      data["destination_order"] = index + 1;
      new_data.push(data);
    });

    props.addDestinationHandler(new_data);
  };

  const checkUniqueDestination = (array, value) => {
    if (array !== "") {
      return array.some(function (el) {
        return el.destination === value;
      });
    } else {
      return false;
    }
  };

  const handleDragStart = (e, index) => {
    setDraggedRowIndex(index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault(); // Necessary for allowing a drop
  };

  const handleDrop = (e, index) => {
    e.preventDefault();

    const draggedRow = destinations[draggedRowIndex];
    const remainingRows = destinations.filter((_, i) => i !== draggedRowIndex);
    const updatedRows = [
      ...remainingRows.slice(0, index),
      draggedRow,
      ...remainingRows.slice(index),
    ];

    let new_arr = [];
    updatedRows.forEach(async (row, index) => {
      let data = { ...row };
      data["destination_order"] = index + 1;
      new_arr.push(data);
    });

    props.addDestinationHandler(new_arr);
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-4 col-md-4 col-6">
          <div className="card">
            <div className="card-body">
              <div className="form-group">
                <label>Ring Group ID</label>
                <p>{ringgroupData.ring_group_extension}</p>
              </div>
              <div className="form-group input-primary">
                <label>
                  Ring Group Name<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="ring_group_name"
                  value={ringgroupData.ring_group_name}
                  className="form-control bg-white"
                  onChange={handleChange}
                />
                <span className="text-danger">{errors.ring_group_name}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-4 col-6">
          <div className="card">
            <div className="card-body">
              <div className="form-group input-primary">
                <label>
                  Ring Strategy<span className="text-danger">*</span>
                </label>

                <select
                  name="ring_group_strategy"
                  className="form-control bg-white"
                  onChange={handleChange}
                  defaultValue={ringgroupData.ring_group_strategy}
                >
                  {RingStrategyList.map((val) => (
                    <option key={val.value} value={val.value}>
                      {val.label}
                    </option>
                  ))}
                </select>
                <span className="text-danger">
                  {errors.ring_group_strategy}
                </span>
              </div>

              <div className="form-group input-primary">
                <label className="w-100">
                  CID Name Prefix
                  <Tooltip
                    placement="top"
                    overlay={<span>CID Name Prefix</span>}
                  >
                    <span className="ti-info-alt ml-1"></span>
                  </Tooltip>
                </label>

                <input
                  type="text"
                  name="cid_name_prefix"
                  value={ringgroupData.cid_name_prefix}
                  className="form-control bg-white"
                  onChange={handleChange}
                />
                {/* <span className="text-danger">
                                    {errors.cid_name_prefix}
                                </span>  */}
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-4 col-6">
          <div className="card">
            <div className="card-body">
              <div className="form-group input-primary">
                <label className="w-100">
                  Max Caller Wait Time
                  <Tooltip
                    placement="top"
                    overlay={<span>Max Caller Wait Time</span>}
                  >
                    <span className="ti-info-alt  ml-1"></span>
                  </Tooltip>
                </label>

                <select
                  name="max_wait_time"
                  className="form-control bg-white"
                  onChange={handleChange}
                  defaultValue={ringgroupData.max_wait_time}
                >
                  {number_array.map((number) => (
                    <option key={number.value} value={number.value}>
                      {number.value}
                    </option>
                  ))}
                </select>
                {/* <span className="text-danger">
                                    {errors.max_wait_time}
                                </span>   */}
              </div>

              <div className="form-group input-primary">
                <label className="w-100">
                  Failover Destination After Max Wait Time
                  <Tooltip
                    placement="top"
                    overlay={
                      <span>Failover Destination After Max Wait Time</span>
                    }
                  >
                    <span className="ti-info-alt ml-1"></span>
                  </Tooltip>
                </label>

                <select
                  name="ring_group_fail_destination_str"
                  className="form-control bg-white"
                  onChange={handleChange}
                  value={ringgroupData.ring_group_fail_destination_str}
                >
                  {Object.keys(destinationOptions).map((group, index) => {
                    return (
                      <optgroup key={index} label={group}>
                        {destinationOptions[group].map((option) => {
                          return (
                            <option key={option.value} value={option.value}>
                              {option.text}
                            </option>
                          );
                        })}
                      </optgroup>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-8 col-md-8 col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Extension List</h4>
            </div>
            <div className="card-body p-0">
              <PerfectScrollbar
                className={`p-0 dz-scroll "ps ps--active-y`}
                id="DZ_W_Contacts_Body1"
                style={{ height: "307px" }}
              >
                <Table responsive className="w-100">
                  <thead>
                    <tr role="row">
                      <th className="border-top border-primary">Seq.</th>
                      <th className="border-top border-primary">Type</th>
                      <th className="border-top border-primary">Ext/Number</th>
                      <th className="border-top border-primary">Skill Set</th>
                      <th className="border-top border-primary">
                        Ring Timeout
                      </th>
                      <th className="border-top border-primary"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {destinations &&
                      destinations.map((destination, index) => (
                        <tr
                          key={index}
                          draggable
                          onDragStart={(e) => handleDragStart(e, index)}
                          onDragOver={(e) => handleDragOver(e, index)}
                          onDrop={(e) => handleDrop(e, index)}
                          style={{ cursor: "grabbing" }}
                        >
                          <th>{destination.destination_order}</th>
                          <td>{destination.destination_type}</td>
                          <td>{destination.destination_str}</td>
                          <td>{destination.skill_set}</td>
                          <td>{destination.ring_timeout}</td>
                          {/* <td>{destination.call_timeout}</td> */}
                          <td>
                            <Fragment>
                              <div className="d-flex">
                                <button
                                  onClick={() =>
                                    fnDeleteRingGroupDestination(index)
                                  }
                                  className="btn float-left ml-3 pb-1 trash-btn btn-danger btn-sm"
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </div>
                            </Fragment>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </PerfectScrollbar>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-4 col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7 pr-0">
                  <div className="form-group mb-4 clearfix mt-4 input-primary">
                    <label className="w-100">Add Extension </label>
                    <select
                      className="form-control height45 float-left bg-white"
                      name="extension"
                      onChange={handleChangeDestination}
                      value={ringGroupDestination.extension}
                    >
                      <option value="">Select Extension</option>
                      {extensions &&
                        extensions.map((extension) => (
                          <option key={extension.value} value={extension.value}>
                            {extension.text}
                          </option>
                        ))}
                    </select>
                    <span className="text-danger">{destErrors.extension}</span>
                  </div>
                </div>

                <div className="col-3 pr-0">
                  <div className="form-group mb-4 clearfix mt-4 input-primary">
                    <label className="w-100">Skill Set </label>
                    <select
                      className="form-control height45 bg-white"
                      name="skill_set"
                      onChange={handleChangeDestination}
                      value={ringGroupDestination.skill_set}
                    >
                      <option value="">Select Skill Set</option>
                      {skill_set_array &&
                        skill_set_array.map((arr) => (
                          <option key={arr.value} value={arr.value}>
                            {arr.label}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="col-2 form-group mb-4 clearfix mt-2 input-primary">
                  <button
                    className="btn btn-primary trash-btn mr-2 mt-5"
                    onClick={saveDestination}
                  >
                    <span className="ti-plus"></span>
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="col-10 pr-0">
                  <div className="form-group clearfix input-primary">
                    <label className="w-100">Add External number </label>
                    <input
                      type="text"
                      name="number"
                      onChange={handleChangeDestination}
                      className="form-control height45 float-left bg-white"
                      value={ringGroupDestination.number}
                    />
                    {/* <button className="btn ml-3 trash-btn btn-primary" onClick={saveDestination}>
                                            <span className="ti-plus"></span>
                                        </button>	 */}
                    <span className="text-danger">{destErrors.did}</span>
                  </div>
                </div>

                <div className="col-2 form-group mb-4 clearfix mt-2 input-primary">
                  <button
                    className="btn btn-primary trash-btn mr-2 mt-4"
                    onClick={saveDestination}
                  >
                    <span className="ti-plus"></span>
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="col-sm">
                  <span className="text-danger">{/* {errors} */}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12 col-md-12 col-12">
          <button
            type="button"
            className="btn btn-sm btn-primary pull-right"
            onClick={(e) => props.submitHandler(e)}
          >
            Submit
          </button>
        </div>
      </div>
    </Fragment>
  );
}

export default GeneralFieldsForm;
