import React from "react";

function FieldSection(props) {

    return props.contactFormSection.map((val, idx) => {

        return (
            <div className="card border-2 pb-0" key={val.contact_form_section_uuid}>
                <div className="card-header border-0 pb-0">
                    <h4 className="card-title">{val.section_title}</h4>
                    <div>
                        <button type="button" className="btn btn-sm btn-danger"><i className="fa fa-trash"></i></button>
                    </div>
                </div>
                <div className="card-body">
                    <ul className="list-group list-group-flush">
                        {props.contactFieldOrder.map((field) => {
                            return ( <li draggable className="list-group-item d-flex px-0 justify-content-between" key={field.contact_field_order_uuid}>{field.contact_field}</li>);
                        })}
                        
                    </ul>
                </div>
            </div>
        );

    });

    

}

export default FieldSection;