import React, { Fragment, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { importNumberCsv } from "../../actions/blockednumbers";

function StepOne(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;
  const main_uuid = user_data.main_uuid;

  const hiddenFileInput = useRef();

  const dispatch = useDispatch();

  const [errors, setErrors] = useState({
    file: "",
  });

  const [data, setData] = useState({
    file: "",
    token: token,
    createdby: createdby,
    main_admin_uuid: main_uuid,
    user_uuid: createdby,
  });

  const handleChange = (e) => {
    const newData = { ...data };
    let error_data = { ...errors };

    const MAX_FILE_SIZE = 10240; // 10MB

    if (
      e.target.files[0].type === "text/csv" ||
      e.target.files[0].type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      e.target.files[0].type === "application/vnd.ms-excel"
    ) {
      const fileSizeKiloBytes = e.target.files[0].size / 1024;
      

      if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        // setErrorMsg("File size is greater than maximum limit");
        error_data[e.target.name] = "File size not allow greater then 10MB.";
        setErrors(error_data);
        return;
      }

      newData[e.target.name] = e.target.files[0];
      setData(newData);

      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("token", newData.token);
      formData.append("createdby", newData.createdby);
      formData.append("main_admin_uuid", newData.main_admin_uuid);
      formData.append("user_uuid", newData.user_uuid);
      
      dispatch(importNumberCsv(formData, newData.token))
        .then((data) => {
          props.handelFileName(data.data.filename);
          props.loadNextPage(2);
        })
        .catch(() => {
          //setLoading(false);
        });
    } else {
      error_data[e.target.name] = e.target.name + " allow only csv and xls.";
      setErrors(error_data);
      return;
    }
  };

  return (
    <Fragment>
      <div className="row text-center">
        <div className="col-sm"></div>
        <div className="col-sm" style={{ textAlign: "center !important" }}>
          <button
            className="btn text-info"
            onClick={(e) => hiddenFileInput.current.click()}
          >
            Upload Csv
          </button>

          <input
            type="file"
            onChange={(e) => handleChange(e)}
            ref={hiddenFileInput}
            name="file"
            //ref={el => (hiddenFileInput = el)}
            //id="profile_upload"
            //accept="image/png, image/gif, image/jpeg"
            style={{ display: "none" }} // Make the file input element invisible
          />
          <span className="text-danger">{errors.file}</span>
        </div>
        <div className="col-sm"></div>
      </div>

      <div className="row text-center">
        <div className="col-sm" style={{ textAlign: "center !important" }}>
          <p>The csv must have Number and Description.</p>
        </div>
      </div>
    </Fragment>
  );
}

export default StepOne;
