import React, { Fragment, useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Loader from "../../layouts/Loader";
import { getContactFieldsList } from "../../actions/emailtemplate";

function VariableForm(props) {
  const closeModel = (arg) => {
    props.closeModel(arg);
  };

  const user = JSON.parse(localStorage.getItem("user"));
  const user_data = user.data.data;
  const token = user.data.token;
  const createdby = user_data.user_uuid;

  const [loading, setLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const dispatch = useDispatch();

  const [fieldsData, setFieldsData] = useState([]);

  useEffect(() => {
    // setLoading(true);
    dispatch(getContactFieldsList(createdby, token))
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setFieldsData(response.data.contacts_fields);
        }

        return;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  function toTitleCase(str) {
    const titleCase = str
      .toLowerCase()
      .split("_")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");

    return titleCase;
  }

  function handleSelect(data) {
    let data1 = "{" + data + "}";
    navigator.clipboard.writeText(data1);
    setIsCopied(true);
    // props.handleSelect(data1);
  }

  return (
    <Fragment>
      {loading && <Loader />}
      <Modal.Header>
        <Modal.Title>Set Insert Personalization</Modal.Title>
        <Button variant="" className="close" onClick={() => closeModel(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>

      <Modal.Body>
        <div className="row">
          <div className="col-sm">
            <div className="form-group input-primary">
              <label className="mb-1">
                <strong>Contacts</strong>
              </label>
              {isCopied && <p>Personalization copied to clipboard!</p>}
              {fieldsData.map((data, i) => (
                <>
                  <p className="ml-4 mr-1" key={i}>
                    {" "}
                    {toTitleCase(data)}
                    <i
                      class="fa fa-copy font-18 align-middle ml-4 cursor-pointer"
                      onClick={() => handleSelect(data)}
                    >
                      {/* <span className="ml-1"><b>Copy</b></span> */}
                    </i>
                  </p>
                </>
              ))}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Fragment>
  );
}

export default VariableForm;
